import { isAfter, parse } from "date-fns";
import { TFunction } from "i18next";
import { dateFormat } from "utils/helpers";
import * as yup from "yup";
import {
  MoneyRangeTransactions,
  MonthlyLiabilitiesInput,
} from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.legalEntityLiabilities";

export const liabilitiesCheckboxNames: Array<keyof MonthlyLiabilitiesInput> = [
  "staff",
  "rent",
  "materialCosts",
  "insurance",
  "other",
];

const liabilitiesCheckboxValidationSchema = (
  t: TFunction,
  checkboxName: string
) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeTransactions>()
      .oneOf(
        Object.values(MoneyRangeTransactions),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup
          .mixed<MoneyRangeTransactions>()
          .required(t(`${checkboxName}.range.required`)),
      })
      .label(t(`${checkboxName}.range.label`)),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeTransactions) =>
          money === MoneyRangeTransactions.MORE_THAN_50000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(`${checkboxName}.value.minimum`),
            (value) => (value ?? 0) > 50000
          )
          .required(t(`${checkboxName}.value.required`)),
      })
      .label(t(`${checkboxName}.value.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const liabilitiesValidationSchema = (t: TFunction) => {
  return yup.object({
    financialStatus: yup.object({
      monthlyLiabilities: yup
        .object({
          staff: liabilitiesCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.monthlyLiabilities.staff`
          ),
          rent: liabilitiesCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.monthlyLiabilities.rent`
          ),
          materialCosts: liabilitiesCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.monthlyLiabilities.materialCosts`
          ),
          insurance: liabilitiesCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.monthlyLiabilities.insurance`
          ),
          other: liabilitiesCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.monthlyLiabilities.other`
          ),
        })
        .test(
          "liabilitiesCheckboxGroup",
          "",
          ({ staff, rent, materialCosts, insurance, other }, testContext) => {
            if (
              staff.range ||
              rent.range ||
              materialCosts.range ||
              insurance.range ||
              other.range
            ) {
              return true;
            }

            return testContext.createError({
              message: t(`${translationPrefix}.liabilitiesCheckboxGroup`),
              path: `${testContext.path}.liabilitiesCheckboxGroup`,
            });
          }
        ),
      monthlyLiquidity: yup.object({
        range: yup
          .mixed<MoneyRangeTransactions>()
          .oneOf(
            Object.values(MoneyRangeTransactions),
            t(`${translationPrefix}.fields.monthlyLiquidity.range.required`)
          )
          .label(t(`${translationPrefix}.fields.monthlyLiquidity.range.label`))
          .required(
            t(`${translationPrefix}.fields.monthlyLiquidity.range.required`)
          ),
        value: yup
          .mixed()
          .transform((value) =>
            typeof value !== "number"
              ? Number(value.replace(/[.,\s]/g, ""))
              : value
          )
          .when("range", {
            is: (money: MoneyRangeTransactions) =>
              money === MoneyRangeTransactions.MORE_THAN_50000,
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .test(
                "minimum",
                t(`${translationPrefix}.fields.monthlyLiquidity.value.minimum`),
                (value) => (value ?? 0) > 50000
              )
              .required(
                t(`${translationPrefix}.fields.monthlyLiquidity.value.required`)
              ),
          })
          .label(t(`${translationPrefix}.fields.monthlyLiquidity.value.label`)),
      }),
      reserve: yup.object({
        needsReserve: yup
          .string()
          .required(t(`${translationPrefix}.fields.reserve.required`))
          .label(t(`${translationPrefix}.fields.reserve.valueLabel`)),
        value: yup
          .mixed()
          .transform((value) =>
            typeof value !== "number"
              ? Number(value.replace(/[.,\s]/g, ""))
              : value
          )
          .when("needsReserve", {
            is: (answer: "yes" | "no") => answer === "yes",
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .test(
                "required",
                t(`${translationPrefix}.fields.reserve.value.required`),
                (value) => Boolean(value ?? 0)
              ),
          })
          .label(t(`${translationPrefix}.fields.reserve.value.label`)),
        date: yup
          .string()
          .when("needsReserve", {
            is: (answer: "yes" | "no") => answer === "yes",
            then: yup
              .string()
              .test(
                "is-valid-date-of-reserve",
                "Bitte geben Sie ein Datum in der Zukunft im Format tt.mm.yyyy an.",
                (value) => {
                  if (!value) return true;

                  try {
                    const date = parse(value, "d.M.y", new Date());
                    const OLDEST_DATE = new Date();

                    const matchDateFormat = value.match(dateFormat) !== null;

                    return isAfter(date, OLDEST_DATE) && matchDateFormat;
                  } catch (_e) {
                    return false;
                  }
                }
              ),
          })
          .label(t(`${translationPrefix}.fields.reserve.date.label`)),
      }),
    }),
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  liabilitiesValidationSchema(t);
