import { TFunction } from "i18next";
import * as yup from "yup";
import {
  MoneyRangeLarge,
  MoneyRangeSmall,
} from "../../../../../../generated/globalTypes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const largeRangeCheckboxValidationSchema = (
  t: TFunction,
  checkboxName: string
) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeLarge>()
      .oneOf(
        Object.values(MoneyRangeLarge),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup.mixed<MoneyRangeLarge>().required(),
      })
      .label(t(`${checkboxName}.range.label`)),
    value: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when("range", {
        is: (money: MoneyRangeLarge) =>
          money === MoneyRangeLarge.MORE_THAN_1000000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(`${checkboxName}.value.minimum`),
            (value) => (value ?? 0) > 1000000
          )
          .required(t(`${checkboxName}.value.required`)),
      })
      .label(t(`${checkboxName}.value.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const largeRangeCheckboxMonetaryInputValidationSchema = (
  t: TFunction,
  checkboxName: string
) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeLarge>()
      .oneOf(
        Object.values(MoneyRangeLarge),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup.mixed<MoneyRangeLarge>().required(),
      })
      .label(t(`${checkboxName}.range.label`)),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeLarge) =>
          money === MoneyRangeLarge.MORE_THAN_1000000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test("minimum", t(`${checkboxName}.value.minimum`), (value) => {
            return (value ?? 0) > 1000000;
          })
          .required(t(`${checkboxName}.value.required`)),
      })
      .label(t(`${checkboxName}.value.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const smallRangeCheckboxValidationSchema = (
  t: TFunction,
  checkboxName: string
) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeSmall>()
      .oneOf(
        Object.values(MoneyRangeSmall),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup.mixed<MoneyRangeSmall>().required(),
      })
      .label(t(`${checkboxName}.range.label`)),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeSmall) =>
          money === MoneyRangeSmall.MORE_THAN_5000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(`${checkboxName}.value.minimum`),
            (value) => (value ?? 0) > 5000
          )
          .required(t(`${checkboxName}.value.required`)),
      })
      .label(t(`${checkboxName}.value.label`)),
  });

export type SmallRange = yup.InferType<
  ReturnType<typeof smallRangeCheckboxValidationSchema>
>;
export type MediumRange = yup.InferType<
  ReturnType<typeof largeRangeCheckboxValidationSchema>
>;

export const validatePostalCodeGermany = (
  value: string | undefined
): boolean => {
  if (value?.length !== 5) {
    return false;
  }

  // Valid German postal codes: 01001 to 99998
  const numericValue = parseInt(value, 10);
  if (!numericValue || numericValue < 1001 || numericValue > 99998) {
    return false;
  }
  return true;
};
