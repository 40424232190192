import { isAfter, isBefore, parse } from "date-fns";
import { TFunction } from "i18next";
import { loadavg } from "os";
import { dateFormat } from "utils/helpers";
import * as yup from "yup";
import { TransparencyRegisterObligationOptionEnum } from "../actions";

const translationPrefix = "q-ai.opportunities.form.businessActivity";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup.object({
    businessActivity: yup.object({
      registrationNumber: yup
        .string()
        .required(t(`${translationPrefix}.fields.registrationNumber.required`))
        .label(t(`${translationPrefix}.fields.registrationNumber.label`)),
      authorityOffice: yup
        .string()
        .required(t(`${translationPrefix}.fields.authorityOffice.required`))
        .label(t(`${translationPrefix}.fields.authorityOffice.label`)),
      dateOfFoundation: yup
        .string()
        .required(t(`${translationPrefix}.fields.dateOfFoundation.required`))
        .test("is-valid-date-of-foundation", "", (value, testContext) => {
          // True so it can delegate to required()
          if (!value) return true;

          const date = parse(value, "d.M.y", new Date());
          const OLDEST_DATE = new Date(1900, 0, 1);
          const LATEST_DATE = new Date();

          const doesNotMatchFormat = value.match(dateFormat) === null;

          const isInvalidDate =
            isBefore(date, OLDEST_DATE) ||
            new Date(date).toString() === "Invalid Date" ||
            doesNotMatchFormat;

          if (isInvalidDate) {
            return testContext.createError({
              message: t(
                `${translationPrefix}.fields.dateOfFoundation.notAValidDate`
              ),
              type: "dateOfFoundation",
              path: "businessActivity.dateOfFoundation",
            });
          }

          if (isAfter(date, LATEST_DATE)) {
            return testContext.createError({
              message: t(
                `${translationPrefix}.fields.dateOfFoundation.noFutureDate`
              ),
              type: "dateOfFoundation",
              path: "businessActivity.dateOfFoundation",
            });
          }

          return true;
        })
        .label(t(`${translationPrefix}.fields.dateOfFoundation.label`)),
      businessPurpose: yup
        .string()
        .test(
          "len",
          t(`${translationPrefix}.fields.businessPurpose.error`),
          (val) => (val ? val.length <= 200 : true)
        )
        .label(t(`${translationPrefix}.fields.businessPurpose.label`))
        .required(t(`${translationPrefix}.fields.businessPurpose.required`)),
      cashIntensive: yup
        .string()
        .required(t(`${translationPrefix}.fields.cashIntensive.required`)),
      specialTransactions: yup
        .string()
        .required(
          t(`${translationPrefix}.fields.specialTransactions.required`)
        ),
      dutyDisclosure: yup
        .string()
        .required(t(`${translationPrefix}.fields.dutyDisclosure.required`)),
      governmentVerified: yup
        .string()
        .required(t(`${translationPrefix}.fields.governmentVerified.required`)),
      publicCompany: yup
        .string()
        .required(t(`${translationPrefix}.fields.publicCompany.required`)),
      privateAssetManagement: yup
        .string()
        .required(
          t(`${translationPrefix}.fields.privateAssetManagement.required`)
        ),
      publiclyTraceable: yup
        .string()
        .required(t(`${translationPrefix}.fields.publiclyTraceable.required`)),
      transparencyRegisterObligation: yup
        .string()
        .required(
          t(
            `${translationPrefix}.fields.transparencyRegisterObligation.required`
          )
        ),
      transparencyRegisterObligationOption: yup
        .mixed<TransparencyRegisterObligationOptionEnum>()
        .when("transparencyRegisterObligation", {
          is: "yes",
          then: yup
            .mixed<TransparencyRegisterObligationOptionEnum>()
            .oneOf(Object.values(TransparencyRegisterObligationOptionEnum))
            .required(
              t(
                `${translationPrefix}.fields.transparencyRegisterObligationOption.required`
              )
            ),
        }),
    }),
  });

export type BusinessActivity = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export function validate(businessActivityData: BusinessActivity): any {
  const t = (k: any) => k;
  return validationSchema(t).validateSync(businessActivityData);
}
