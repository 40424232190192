import { Icon, Stack, TableProps } from "@finvia/money-ui";

import { Persons } from "./AddPersonsPage.types";
import { AdditionalPersonProps } from ".";
import { TFunction } from "i18next";
import { ManageAdditionalPerson } from "./components/ManageAdditionalPerson";

export const buildPersons = (
  persons: Array<AdditionalPersonProps>
): Persons => {
  if (persons === undefined) {
    return [];
  }

  return persons.map((person) => ({
    id: person.id || "",
    name: `${person.personalData?.firstName} ${person.personalData?.familyName}`,
    isLegalRepresentative: person.isLegalRepresentative === "yes",
    isBeneficialOwner: person.beneficialOwner?.isBeneficialOwner === "yes",
  }));
};

export const tableHeader = (t: TFunction): TableProps["headers"] => {
  const langPrefix = "components.AddPersonTable";

  return [
    {
      field: "name",
      content: (
        <Stack gutter="kilo" direction="row" alignItems="center">
          <Icon name="user" size="bit" />
          {t(`${langPrefix}.name`)}
        </Stack>
      ),
    },
    {
      field: "legalRepresentative",
      content: (
        <Stack gutter="kilo" direction="row" alignItems="center">
          <Icon name="people" size="kilo" />
          {t(`${langPrefix}.isLegalRepresentative`)}
        </Stack>
      ),
    },
    {
      field: "beneficialOwner",
      content: (
        <Stack gutter="kilo" direction="row" alignItems="center">
          <Icon name="pie-chart" size="bit" />
          {t(`${langPrefix}.isBeneficialOwner`)}
        </Stack>
      ),
    },
    {
      field: "manageAdditionalPerson",
      content: "",
    },
  ];
};

export const tableRows = (rows: Persons, t: TFunction): TableProps["rows"] =>
  rows.map((person) => ({
    name: { content: person.name },
    legalRepresentative: {
      content: person.isLegalRepresentative ? t("form.yes") : t("form.no"),
    },
    beneficialOwner: {
      content: person.isBeneficialOwner ? t("form.yes") : t("form.no"),
    },
    manageAdditionalPerson: {
      content: person.id !== "me" && <ManageAdditionalPerson person={person} />,
    },
  }));
