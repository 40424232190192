import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import {
  PersonalDataForm as PersonalDataFormType,
  validationSchema,
} from "./PersonalData.schema";
import {
  Button,
  Heading,
  SelectInput,
  TextInput,
  Icon,
  Grid,
  ChoiceGroup,
} from "@finvia/money-ui";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import FormControl from "../../../../../../components/FormControl/FormControl";
import * as S from "./PersonalDataForm.styled";
import { salutations } from "utils/options/salutations";
import { professionalTitles } from "utils/options/professionalTitles";
import { countries } from "utils/options/countries";

const translationPrefix = "q-ai.opportunities.form.personalData";

const MAX_SECONDARY_CITIZENSHIPS = 2;

const PersonalDataForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
  isLegalEntity,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<PersonalDataFormType>(
      {
        personalData:
          state.alternativeInvestments.regulatoryData?.personData?.personalData,
        citizenship: {
          ...state.alternativeInvestments.regulatoryData?.personData
            ?.citizenship,
        },
      },
      {
        personalData: {
          firstName: "",
          familyName: "",
          birthName: "",
          dateOfBirth: "",
          salutation: undefined,
          professionalTitle: undefined,
          cityOfBirth: "",
          countryOfBirth: undefined,
        },
        citizenship: {
          primary: undefined,
          thirdWorldNational: "",
          secondary: [],
        },
      }
    )
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (personalData: PersonalDataFormType) => {
      dispatch(updateProfile(personalData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<PersonalDataFormType>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={validationSchema(t, isLegalEntity)}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics={`2.${isLegalEntity ? "4_le" : "2_np"}_personal_data`}
    >
      {(formMethods) => {
        const {
          register,
          control,
          watch,
          formState: { errors },
        } = formMethods;

        const primaryCitizenship = formMethods.watch("citizenship.primary");
        const secondaryCitizenships = watch("citizenship.secondary");

        const disableAddMoreButton = () => {
          if (!primaryCitizenship) {
            return true;
          } else if (
            secondaryCitizenships?.length &&
            secondaryCitizenships[0]?.value === ""
          ) {
            return true;
          } else if (errors.citizenship?.secondary?.length) {
            return true;
          }

          return false;
        };

        // We depend on formMethods thus we can't call it in the beginning of the component
        /* eslint-disable-next-line react-hooks/rules-of-hooks */
        const { fields, append, remove } = useFieldArray({
          control,
          name: "citizenship.secondary",
        });

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid columns={{ sm: 1, md: 2 }} rowsGap="tera" columnsGap="yotta">
              <S.FullRow>
                <Heading as="h2" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.personalData.headline`)}
                </Heading>
              </S.FullRow>

              <SelectInput
                ref={register}
                name="personalData.salutation"
                label={t(
                  `${translationPrefix}.fields.personalData.salutation.label`
                )}
                required
                value={defaultData.personalData?.salutation}
                options={salutations(t)}
                errorMessage={errors.personalData?.salutation?.message}
              />
              <SelectInput
                ref={register}
                name="personalData.professionalTitle"
                label={t(
                  `${translationPrefix}.fields.personalData.professionalTitle.label`
                )}
                clearSelectionLabel={t("form.clearSelection")}
                onSelectChange={(option) => {
                  // this prevents to ignore empty value when user clears the selection
                  const newValue =
                    option.value !== "" ? option.value : undefined;

                  formMethods.setValue(
                    "personalData.professionalTitle",
                    newValue
                  );
                }}
                value={defaultData.personalData?.professionalTitle}
                options={professionalTitles(t)}
                errorMessage={errors.personalData?.professionalTitle?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="personalData.firstName"
                required
                label={t(
                  `${translationPrefix}.fields.personalData.firstName.label`
                )}
                errorMessage={errors.personalData?.firstName?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="personalData.familyName"
                required
                label={t(
                  `${translationPrefix}.fields.personalData.familyName.label`
                )}
                errorMessage={errors.personalData?.familyName?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="personalData.birthName"
                label={t(
                  `${translationPrefix}.fields.personalData.birthName.label`
                )}
                errorMessage={errors.personalData?.birthName?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="personalData.dateOfBirth"
                required
                label={t(
                  `${translationPrefix}.fields.personalData.dateOfBirth.label`
                )}
                placeholder="TT.MM.JJJJ"
                errorMessage={errors.personalData?.dateOfBirth?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="personalData.cityOfBirth"
                required
                label={t(
                  `${translationPrefix}.fields.personalData.cityOfBirth.label`
                )}
                errorMessage={errors.personalData?.cityOfBirth?.message}
              />
              <SelectInput
                ref={register}
                name="personalData.countryOfBirth"
                label={t(
                  `${translationPrefix}.fields.personalData.countryOfBirth.label`
                )}
                required
                value={defaultData.personalData?.countryOfBirth}
                options={countries(t)}
                errorMessage={errors.personalData?.countryOfBirth?.message}
                searchable
              />

              <S.FullRow>
                <Heading as="h2" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.citizenship.headline`)}
                </Heading>
              </S.FullRow>

              <SelectInput
                ref={register}
                name="citizenship.primary"
                label={t(
                  `${translationPrefix}.fields.citizenship.primary_citizenship.label`
                )}
                required
                value={defaultData.citizenship?.primary}
                options={countries(t)}
                errorMessage={errors.citizenship?.primary?.message}
                searchable
                testId="citizenship.primary"
              />

              {fields.map((field, index) => {
                let errorMEssage;

                // TODO: make this function more generic
                const filteredOptions = () => {
                  if (index === 1) {
                    return countries(t).filter(
                      (option) =>
                        primaryCitizenship !== option.value &&
                        option.value !== fields[0].value
                    );
                  }
                  return countries(t).filter(
                    (option) => primaryCitizenship !== option.value
                  );
                };

                if (errors.citizenship?.secondary?.length) {
                  errorMEssage =
                    errors.citizenship.secondary[index]?.value?.message;
                }

                return (
                  <S.ExtraFieldWrapper key={field.id}>
                    <Controller // controller prevents render problems caused by useFieldArray
                      control={control}
                      name={`citizenship.secondary[${index}].value`}
                      defaultValue={field.value ? field.value : ""}
                      as={
                        <SelectInput
                          name={`citizenship.secondary[${index}].value`}
                          label={t(
                            `${translationPrefix}.fields.citizenship.secondary_citizenship.label`
                          )}
                          options={filteredOptions()}
                          errorMessage={errorMEssage}
                          searchable
                          required
                          testId={`citizenship.secondary[${index}]`}
                        />
                      }
                    />
                    <S.RemoveIconWrapper
                      data-testid={`citizenship.secondary[${index}].delete`}
                      onClick={() => remove(index)}
                    >
                      <Icon name="trash" color="petrol.600" />
                    </S.RemoveIconWrapper>
                  </S.ExtraFieldWrapper>
                );
              })}

              {fields.length < MAX_SECONDARY_CITIZENSHIPS && (
                <S.FullRow>
                  <Button
                    variant="link"
                    onClick={() => append({ value: "" })}
                    disabled={disableAddMoreButton()}
                    testId="add_secondary_citizenship"
                  >
                    {t(
                      `${translationPrefix}.fields.citizenship.add_secondary_citizenship`
                    )}
                  </Button>
                </S.FullRow>
              )}

              {!isLegalEntity && (
                <>
                  <S.FullRow>
                    <Heading as="h2" size={{ sm: 3, lg: 4 }} font="sans">
                      {t(
                        `${translationPrefix}.fields.citizenship.thirdWorldNational.title`
                      )}
                    </Heading>
                  </S.FullRow>
                  <S.FullRow>
                    <ChoiceGroup
                      required
                      name="citizenship.thirdWorldNational"
                      label={t(
                        `${translationPrefix}.fields.citizenship.thirdWorldNational.subTitle`
                      )}
                      errorMessage={
                        errors.citizenship?.thirdWorldNational?.message
                      }
                    >
                      <Grid columns={{ sm: 3, lg: 8 }}>
                        <ChoiceGroup.Radio
                          ref={register}
                          name="citizenship.thirdWorldNational"
                          value="yes"
                          label={t("form.yes")}
                        />
                        <ChoiceGroup.Radio
                          ref={register}
                          name="citizenship.thirdWorldNational"
                          value="no"
                          label={t("form.no")}
                        />
                      </Grid>
                    </ChoiceGroup>
                  </S.FullRow>
                </>
              )}
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default PersonalDataForm;
