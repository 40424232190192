import { InfoBox, Stack, TimelineStepper } from "@finvia/money-ui";
import { useTranslation } from "react-i18next";
import { IdentificationStatusProps } from "./IdentificationStepperWrappertypes";
import {
  generateSteps,
  getInfoBoxMessage,
} from "./utils/IdentificationStepperWrapper";
import { isDefined } from "utils/helpers";

const IdentificationStepperWrapper: React.FC<IdentificationStatusProps> = ({
  accumulatedSolarisIdentStatus,
  legalIdentificationStatus,
  failedIdentificationMessage,
}) => {
  const { t } = useTranslation();

  const steps = generateSteps({ t, accumulatedSolarisIdentStatus });

  const infoBoxMessage = getInfoBoxMessage({
    t,
    accumulatedSolarisIdentStatus,
    legalIdentificationStatus,
    failedIdentificationMessage,
  });

  return (
    <Stack gutter="giga">
      <TimelineStepper steps={steps} />
      {isDefined<string>(infoBoxMessage) && (
        <InfoBox
          icon="info"
          variant={
            steps.some((step) => step.status === "failed") ? "danger" : "info"
          }
        >
          {infoBoxMessage}
        </InfoBox>
      )}
    </Stack>
  );
};

export default IdentificationStepperWrapper;
