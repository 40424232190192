import { gql } from "@apollo/client";

const InvestorProfile = gql`
  query InvestorProfile {
    profile {
      investorProfile {
        goals
        horizon
        riskBearingCapacity
        relevantAssetClasses
      }
    }
  }
`;

export default InvestorProfile;
