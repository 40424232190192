import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { MoneyRangeTransactions } from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import {
  Box,
  Checkbox,
  ChoiceGroup,
  Grid,
  Heading,
  SelectInput,
  Stack,
  Text,
  TextInput,
} from "@finvia/money-ui";
import { buildOptions } from "utils/options/buildObject";
import { MonetaryInput } from "../../MonetaryInput";

import { liabilitiesCheckboxNames } from "./Liabilities.schema";
import { LiabilitiesProps, validationSchema } from ".";
import * as S from "./Liabilities.styled";

const translationPrefix = "q-ai.opportunities.form.legalEntityLiabilities";

const LiabilitiesForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);
  const i18nFieldsPrefix = `${translationPrefix}.fields`;

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={1}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector(({ alternativeInvestments }) => {
    const legalEntityData =
      alternativeInvestments?.regulatoryData?.legalEntityData;

    return formDefaultsDeep<LiabilitiesProps>(
      {
        financialStatus: {
          monthlyLiabilities:
            legalEntityData?.financialStatus?.monthlyLiabilities,
          monthlyLiquidity: legalEntityData?.financialStatus?.monthlyLiquidity,
          reserve: legalEntityData?.financialStatus?.reserve,
        },
      },
      {
        financialStatus: {
          monthlyLiabilities: {
            staff: {
              checkbox:
                !!legalEntityData?.financialStatus?.monthlyLiabilities?.staff
                  ?.range,
              range: undefined,
              value: "",
            },
            rent: {
              checkbox:
                !!legalEntityData?.financialStatus?.monthlyLiabilities?.rent
                  ?.range,
              range: undefined,
              value: "",
            },
            materialCosts: {
              checkbox:
                !!legalEntityData?.financialStatus?.monthlyLiabilities
                  ?.materialCosts?.range,
              range: undefined,
              value: "",
            },
            insurance: {
              checkbox:
                !!legalEntityData?.financialStatus?.monthlyLiabilities
                  ?.insurance?.range,
              range: undefined,
              value: "",
            },
            other: {
              checkbox:
                !!legalEntityData?.financialStatus?.monthlyLiabilities?.other
                  ?.range,
              range: undefined,
              value: "",
            },
          },
          monthlyLiquidity: {
            range: undefined,
            value: "",
          },
          reserve: {
            needsReserve: undefined,
            value: "",
          },
        },
      }
    );
  });

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (financialStatusData: LiabilitiesProps) => {
      dispatch(updateLegalEntityProfile(financialStatusData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<LiabilitiesProps>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.14_le_liabilities"
    >
      {(formMethods) => {
        const { watch, errors, register } = formMethods;
        const watchMonthlyLiquidityRange = watch(
          `financialStatus.monthlyLiquidity.range`
        );
        const needsReserve = watch("financialStatus.reserve.needsReserve");

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Stack gutter="mega">
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.label`)}
              </Heading>

              {liabilitiesCheckboxNames.map((checkbox) => {
                const checkboxName = `financialStatus.monthlyLiabilities.${checkbox}.checkbox`;
                const watchRange = watch(
                  `financialStatus.monthlyLiabilities.${checkbox}.range`
                );
                const watchCheckbox = watch(checkboxName);

                return (
                  <Stack gutter="mega" key={checkbox}>
                    <Checkbox
                      ref={register}
                      name={checkboxName}
                      label={t(
                        `${i18nFieldsPrefix}.monthlyLiabilities.${checkbox}.range.label`
                      )}
                    />

                    {watchCheckbox && (
                      <Grid
                        columns={{ sm: 1, md: 2 }}
                        rowsGap={{ sm: "mega" }}
                        columnsGap={{ md: "yotta" }}
                      >
                        <SelectInput
                          required
                          ref={formMethods.register}
                          name={`financialStatus.monthlyLiabilities.${checkbox}.range`}
                          label={t(
                            `${i18nFieldsPrefix}.monthlyLiabilities.${checkbox}.range.label`
                          )}
                          options={buildOptions<unknown>({
                            lang: i18n,
                            key: `${i18nFieldsPrefix}.monthlyLiabilities.${checkbox}.range.values`,
                          })}
                          value={
                            defaultData.financialStatus?.monthlyLiabilities?.[
                              checkbox
                            ]?.range
                          }
                          errorMessage={
                            errors.financialStatus?.monthlyLiabilities?.[
                              checkbox
                            ]?.range?.message
                          }
                        />

                        {watchRange ===
                          MoneyRangeTransactions.MORE_THAN_50000 && (
                          <MonetaryInput
                            ref={register}
                            required
                            name={`financialStatus.monthlyLiabilities.${checkbox}.value`}
                            label={t(
                              `${i18nFieldsPrefix}.monthlyLiabilities.${checkbox}.value.label`
                            )}
                            errorMessage={
                              errors.financialStatus?.monthlyLiabilities?.[
                                checkbox
                              ]?.value?.message
                            }
                          />
                        )}
                      </Grid>
                    )}
                  </Stack>
                );
              })}

              {(errors as any).financialStatus?.monthlyLiabilities
                ?.liabilitiesCheckboxGroup?.message && (
                <Text variant="error" size="1">
                  {
                    (errors as any).financialStatus?.monthlyLiabilities
                      ?.liabilitiesCheckboxGroup?.message
                  }
                </Text>
              )}
            </Stack>

            <Stack gutter="mega">
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.liquidityLabel`)}
              </Heading>

              <S.Wrapper>
                <SelectInput
                  required
                  ref={formMethods.register}
                  name={`financialStatus.monthlyLiquidity.range`}
                  label={t(`${i18nFieldsPrefix}.monthlyLiquidity.range.label`)}
                  options={buildOptions<unknown>({
                    lang: i18n,
                    key: `${i18nFieldsPrefix}.monthlyLiquidity.range.values`,
                  })}
                  value={defaultData.financialStatus?.monthlyLiquidity?.range}
                  errorMessage={
                    errors.financialStatus?.monthlyLiquidity?.range?.message
                  }
                />

                {watchMonthlyLiquidityRange ===
                  MoneyRangeTransactions.MORE_THAN_50000 && (
                  <MonetaryInput
                    ref={register}
                    required
                    name="financialStatus.monthlyLiquidity.value"
                    label={t(
                      `${i18nFieldsPrefix}.monthlyLiquidity.value.label`
                    )}
                    errorMessage={
                      errors.financialStatus?.monthlyLiquidity?.value?.message
                    }
                  />
                )}
              </S.Wrapper>
            </Stack>

            <Stack gutter="mega">
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.reserveLabel`)}
              </Heading>

              <ChoiceGroup
                name="financialStatus.reserve.needsReserve"
                label={t(`${translationPrefix}.fields.reserve.label`)}
                required
                errorMessage={
                  errors.financialStatus?.reserve?.needsReserve?.message
                }
              >
                <Stack direction="row" alignItems="flex-start">
                  <Box width="100px">
                    <ChoiceGroup.Radio
                      ref={register}
                      name="financialStatus.reserve.needsReserve"
                      label={t("form.yes")}
                      value="yes"
                    />
                  </Box>
                  <Box width="100px">
                    <ChoiceGroup.Radio
                      ref={register}
                      name="financialStatus.reserve.needsReserve"
                      label={t("form.no")}
                      value="no"
                    />
                  </Box>
                </Stack>
              </ChoiceGroup>

              {needsReserve === "yes" && (
                <Grid
                  columns={{ sm: 1, md: 2 }}
                  rowsGap={{ sm: "mega" }}
                  columnsGap={{ md: "yotta" }}
                >
                  <MonetaryInput
                    required
                    ref={register}
                    name="financialStatus.reserve.value"
                    label={t(`${i18nFieldsPrefix}.reserve.value.label`)}
                    errorMessage={
                      errors.financialStatus?.reserve?.value?.message
                    }
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="financialStatus.reserve.date"
                    label={t(`${translationPrefix}.fields.reserve.date.label`)}
                    errorMessage={
                      errors.financialStatus?.reserve?.date?.message
                    }
                  />
                </Grid>
              )}
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default LiabilitiesForm;
