import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "./userActions";

interface State {
  email?: string;
  groups?: string[];
  id?: string;
  personId?: string | null;
}

const initialState: State = {};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.email = payload?.account?.email;
      state.groups = payload?.account?.groups;
      state.id = payload?.account?.id;
      state.personId = payload?.id;
    }),
});

export default userSlice;
