import { AnyAction, unwrapResult } from "@reduxjs/toolkit";
import { deleteDocument } from "models/documents";
import { AppDispatch } from "store";
import { Document, HandleDocumentDelete } from "../UploadField.types";

export const deleteDocumentsFromStore = async <
  UpdateAction extends (args: any) => AnyAction
>(
  dispatch: AppDispatch,
  id: string,
  updateAction: UpdateAction,
  extraProps?: { [x: string]: any }
): Promise<void> => {
  await dispatch(deleteDocument({ id })).then(unwrapResult);

  const payload = extraProps
    ? {
        changedDocuments: [{ documentId: id, type: "delete" }],
        ...extraProps,
      }
    : [{ documentId: id, type: "delete" }];

  dispatch(updateAction(payload));
};

export const handleDeleteDocumentGeneric = async <
  UpdateAction extends (args: any) => AnyAction
>({
  document,
  documents,
  setValueReference,
  dispatch,
  updateAction,
  setError,
  name,
  formName,
  extraProps,
}: HandleDocumentDelete<UpdateAction>): Promise<Document[] | void> => {
  if (document.id === null || document.id === undefined) {
    return console.error(
      `@${formName}.handleDeleteDocument: missing document id. Unable to delete the file.`,
      document
    );
  }

  try {
    const id = document.id;
    await deleteDocumentsFromStore<UpdateAction>(
      dispatch,
      id,
      updateAction,
      extraProps
    );

    const filteredDocuments = documents.filter(
      (currentDocument) => currentDocument.id !== id
    );

    setValueReference(name, filteredDocuments, {
      shouldValidate: true,
    });

    return filteredDocuments;
  } catch (e: any) {
    setError(e);
  }
};
