import { EmptyObject } from "types/types";
import {
  Currency,
  InvestorClassificationUnderSupervision,
  InvestorType,
} from "../../../../generated/globalTypes";
import {
  FundDeclarationsForm,
  ProfessionalInvestorClassification,
  ProfessionalInvestorClassificationUnderSupervision,
} from "../FundDeclarations/fundDeclarationsValidations";
import {
  CompanyTypesKeys,
  FormFundDeclarationsData,
  FormFundSigningData,
  FundDetailsData,
  GraphQlFundDeclarationsData,
  GraphQlFundDetailsData,
  GraphQlFundSigningData,
  GraphQlProfessionalInvestorClassificationData,
  PossibleCompanyTypes,
  SigningCurrency,
} from "./mapAIFundsToGraphQLTypes";

export const mapFundSigningData = (
  signingData: FormFundSigningData | undefined
): GraphQlFundSigningData | EmptyObject => {
  if (!signingData) return {};

  return {
    isAcquisitionOfShareholdingConfirmed:
      signingData.confirmsAcquisitionOfShareholding,
    isRiskOfAcquisitionConfirmed: signingData.confirmsRiskOfAcquisition,
    isTrustAndAdministrationContractConfirmed:
      signingData.confirmsTrustAndAdministrationContract,
    isActingOnOwnBehalf: signingData.confirmsActingOnOwnBehalf,
    isStatementsAndDisclosuresConfirmed:
      signingData.confirmsStatementsAndDisclosures,
    isDataProtectionConfirmed: signingData.confirmsDataProtection,
  };
};

const chooseCurrency = (signingCurrency: SigningCurrency): Currency => {
  if (!signingCurrency) return Currency.EUR;

  const currency = signingCurrency.toUpperCase() as keyof typeof Currency;

  return Currency[currency];
};

export const mapFundDetailsData = (
  signingAmountData: FundDetailsData
): GraphQlFundDetailsData | EmptyObject => {
  if (!signingAmountData) return {};

  return {
    signingAmount: {
      value: signingAmountData.signingAmount,
      currency: chooseCurrency(signingAmountData.signingCurrency),
    },
    sourceOfFunds: signingAmountData.sourceOfFunds,
    sourceOfFundsDetail: signingAmountData.sourceOfFundsDetail,
  };
};

const possibleCompanyTypes: PossibleCompanyTypes = [
  [
    "creditInstitution",
    InvestorClassificationUnderSupervision.CREDIT_INSTITUTION,
  ],
  ["investingFirm", InvestorClassificationUnderSupervision.INVESTING_FIRM],
  [
    "financialInstitution",
    InvestorClassificationUnderSupervision.FINANCIAL_INSTITUTION,
  ],
  [
    "insuranceCompany",
    InvestorClassificationUnderSupervision.INSURANCE_COMPANY,
  ],
  [
    "collectiveInvestmentOrganism",
    InvestorClassificationUnderSupervision.COLLECTIVE_INVESTMENT_ORGANISM,
  ],
  ["pensionFund", InvestorClassificationUnderSupervision.PENSION_FUND],
  ["merchants", InvestorClassificationUnderSupervision.MERCHANTS],
  [
    "otherInstitutionalInvestor",
    InvestorClassificationUnderSupervision.OTHER_INSTITUTIONAL_INVESTOR,
  ],
];

const companyTypesReducer =
  (
    professionalInvestorUnderSupervision: ProfessionalInvestorClassificationUnderSupervision
  ) =>
  (
    acc: InvestorClassificationUnderSupervision[],
    [possibleCompanyType, companyType]: [
      CompanyTypesKeys,
      InvestorClassificationUnderSupervision
    ]
  ) => {
    if (professionalInvestorUnderSupervision[possibleCompanyType]) {
      acc.push(companyType);
    }

    return acc;
  };

const mapUnderSupervisionCompanyType = (
  professionalInvestorUnderSupervision:
    | ProfessionalInvestorClassificationUnderSupervision
    | undefined
    | null
): InvestorClassificationUnderSupervision[] | null => {
  if (!professionalInvestorUnderSupervision) return null;

  return possibleCompanyTypes.reduce(
    companyTypesReducer(professionalInvestorUnderSupervision),
    []
  );
};

const mapFundDeclarationsSemiProfessionalInvestor = (
  semiProfessionalInvestor: FundDeclarationsForm["declarations"]["semiProfessionalInvestor"]
): GraphQlFundDeclarationsData["semiProfessionalInvestor"] => ({
  isSemiProfessionalInvestor:
    semiProfessionalInvestor.isSemiProfessionalInvestor,
  isSufficientExperienceAndKnowledgeConfirmed:
    semiProfessionalInvestor.isSufficientExperienceAndKnowledgeConfirmed,
  experienceAndKnowledgeType:
    semiProfessionalInvestor.experienceAndKnowledgeType,
  experienceAndKnowledgeAppendix:
    semiProfessionalInvestor.experienceAndKnowledgeAppendix,
  isEducationAndProfessionalStatusConfirmed:
    semiProfessionalInvestor.isEducationAndProfessionalStatusConfirmed,
  educationAndProfessionalStatusType:
    semiProfessionalInvestor.educationAndProfessionalStatusType,
  educationAndProfessionalStatusAppendix:
    semiProfessionalInvestor.educationAndProfessionalStatusAppendix,
  isAlignedRiskProfileConfirmed:
    semiProfessionalInvestor.isAlignedRiskProfileConfirmed,
  isAlignedFinancialSituationConfirmed:
    semiProfessionalInvestor.isAlignedFinancialSituationConfirmed,
  isSufficientIncomeConfirmed:
    semiProfessionalInvestor.isSufficientIncomeConfirmed,
  isSufficientFinancialAssetsConfirmed:
    semiProfessionalInvestor.isSufficientFinancialAssetsConfirmed,
});

const mapProfessionalInvestorClassification = (
  professionalInvestorClassification: ProfessionalInvestorClassification
): GraphQlProfessionalInvestorClassificationData => ({
  isConfirmedFinancialInstruments:
    professionalInvestorClassification.financialInstruments.isConfirmed,
  financialInstruments: {
    otherInstitutionalInvestor:
      professionalInvestorClassification.financialInstruments
        .otherInstitutionalInvestor,
  },
  isConfirmedUnderSupervision:
    professionalInvestorClassification.underSupervision?.isConfirmed,
  underSupervisionCompanyType: mapUnderSupervisionCompanyType(
    professionalInvestorClassification.underSupervision
  ),
  isConfirmedGovernmentFunction:
    professionalInvestorClassification.governmentFunction.isConfirmed,
  governmentFunction: {
    companyAsGovernment:
      professionalInvestorClassification.governmentFunction.companyAsGovernment,
  },
  isConfirmedSecurizationOfLiabilities:
    professionalInvestorClassification.securizationOfLiabilities.isConfirmed,
  securizationOfLiabilities: {
    companyAsSecurizationOfLiabilities:
      professionalInvestorClassification.securizationOfLiabilities
        .companyAsSecurizationOfLiabilities,
  },
  isConfirmedlargeCompany:
    professionalInvestorClassification.largeCompany.isConfirmed,
  largeCompany: {
    balanceSheetTotal:
      professionalInvestorClassification.largeCompany.balanceSheetTotal,
    netSales: professionalInvestorClassification.largeCompany.netSales,
    ownFunds: professionalInvestorClassification.largeCompany.ownFunds,
  },
  isConfirmedExtensiveExperience:
    professionalInvestorClassification.extensiveExperience.isConfirmed,
  extensiveExperience: {
    tenTransactionsInTheLastFourQuarters:
      professionalInvestorClassification.extensiveExperience
        .tenTransactionsInTheLastFourQuarters,
    tenTransactionsInTheLastFourQuartersExample:
      professionalInvestorClassification.extensiveExperience
        .tenTransactionsInTheLastFourQuartersExample,
    moreThan500k:
      professionalInvestorClassification.extensiveExperience.moreThan500k,
    jobInCapitalMarket:
      professionalInvestorClassification.extensiveExperience.jobInCapitalMarket,
    jobInCapitalMarketDetails:
      professionalInvestorClassification.extensiveExperience
        .jobInCapitalMarketDetails,
  },
});

const mapFundDeclarationsProfessionalInvestor = (
  professionalInvestor: FundDeclarationsForm["declarations"]["professionalInvestor"]
): GraphQlFundDeclarationsData["professionalInvestor"] => {
  if (!professionalInvestor) return null;

  return {
    isProfessionalInvestor: professionalInvestor.isProfessionalInvestor,
    isSufficientExperienceAndKnowledgeConfirmed:
      professionalInvestor.isSufficientExperienceAndKnowledgeConfirmed,
    isRelevantFinancialPortfolioConfirmed:
      professionalInvestor.isRelevantFinancialPortfolioConfirmed,
    isRelevantMarketExperienceConfirmed:
      professionalInvestor.isRelevantMarketExperienceConfirmed,
    isRelevantMarketJobConfirmed:
      professionalInvestor.isRelevantMarketJobConfirmed,
    isConfirmedProfessionalInvestorClassification:
      professionalInvestor.professionalInvestorClassification?.isConfirmed,
    professionalInvestorClassification: professionalInvestor
      ?.professionalInvestorClassification?.isConfirmed
      ? mapProfessionalInvestorClassification(
          professionalInvestor.professionalInvestorClassification
        )
      : null,
  };
};

export const mapFundDeclarationsData = (
  declarationsData: FormFundDeclarationsData | undefined
): GraphQlFundDeclarationsData | EmptyObject => {
  if (!declarationsData) return {};

  const { investorType, professionalInvestor, semiProfessionalInvestor } =
    declarationsData;

  const result: GraphQlFundDeclarationsData = {
    investorType,
    semiProfessionalInvestor: null,
    professionalInvestor: null,
  };

  if (investorType === InvestorType.SEMI_PROFESSIONAL) {
    result.semiProfessionalInvestor =
      mapFundDeclarationsSemiProfessionalInvestor(semiProfessionalInvestor);
  } else if (investorType === InvestorType.PROFESSIONAL) {
    result.professionalInvestor =
      mapFundDeclarationsProfessionalInvestor(professionalInvestor);
  }

  return result;
};
