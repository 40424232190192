/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from "i18next";
import * as yup from "yup";
import countries from "../../../../../../data/countries";
import {
  LegalEntityCRSStatus,
  LegalEntityCRSStatusOtherCharacteristic,
  LegalEntityFATCAStatus,
} from "../../../../../../generated/globalTypes";
import { baseRequiredValidation } from "../../../../../../genericValidations/DocumentValidations";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

export const residencyValidationSchema = (t: TFunction) =>
  yup
    .object({
      country: yup
        .string()
        .oneOf(
          countries,
          t(`${translationPrefix}.fields.taxResidency.fields.country.required`)
        )
        .required()
        .label(
          t(`${translationPrefix}.fields.taxResidency.fields.country.label`)
        ),
      taxId: yup
        .string()
        .when("country", {
          is: (country: string) => country === "DE",
          then: yup
            .string()
            .matches(
              /^[0-9]{10,13}$/,
              t(`${translationPrefix}.fields.taxResidency.fields.taxId.format`)
            ),
        })
        .label(t(`${translationPrefix}.fields.taxResidency.fields.taxId.label`))
        .test(
          "taxId",
          t(`${translationPrefix}.fields.taxResidency.fields.taxId.required`),
          function (item, testContext) {
            const isFirstTaxId =
              parseInt(testContext.path.split("[")[1].split("]")[0], 10) === 0;

            if (!isFirstTaxId) {
              return true;
            }

            return isFirstTaxId && !!item;
          }
        )
        .required(
          t(
            `${translationPrefix}.fields.taxResidency.fields.taxId.secondRequired`
          )
        ),
      localTaxOffice: yup
        .string()
        .nullable()
        .when("country", {
          is: "DE",
          then: yup
            .string()
            .required(
              t(
                `${translationPrefix}.fields.taxResidency.fields.localTaxOffice.required`
              )
            )
            .label(
              t(
                `${translationPrefix}.fields.taxResidency.fields.localTaxOffice.label`
              )
            ),
        })
        .label(
          t(
            `${translationPrefix}.fields.taxResidency.fields.localTaxOffice.label`
          )
        )
        .test(
          "taxId",
          t(
            `${translationPrefix}.fields.taxResidency.fields.localTaxOffice.required`
          ),
          function (item, testContext) {
            const isFirstTaxId =
              parseInt(testContext.path.split("[")[1].split("]")[0], 10) === 0;

            if (isFirstTaxId) {
              return true;
            }

            return !isFirstTaxId && !!item;
          }
        ),
      residencyCertificate: yup
        .array(baseRequiredValidation())
        .nullable()
        .when("country", {
          is: (val: string) => val !== "DE",
          then: yup
            .array(baseRequiredValidation())
            .min(
              1,
              t(
                `${translationPrefix}.fields.taxResidency.fields.residencyCertificate.required`
              )
            )
            .max(1)
            .required(
              t(
                `${translationPrefix}.fields.taxResidency.fields.residencyCertificate.required`
              )
            ),
        }),
    })
    .required();

const taxInformationValidationSchema = (t: TFunction) =>
  yup
    .object({
      fatca: yup.object({
        status: yup
          .mixed<LegalEntityFATCAStatus>()
          .required(
            t(`${translationPrefix}.fields.fatca.fields.status.required`)
          )
          .oneOf(
            Object.values(LegalEntityFATCAStatus),
            t(`${translationPrefix}.fields.fatca.fields.status.required`)
          )
          .label(t(`${translationPrefix}.fields.fatca.fields.status.label`)),
        statusCertificate: yup
          .array(baseRequiredValidation())
          .min(
            1,
            t(
              `${translationPrefix}.fields.fatca.fields.statusCertificate.required`
            )
          )
          .max(1)
          .required(),
        relevantCriterion: yup
          .string()
          .label(
            t(`${translationPrefix}.fields.fatca.fields.relevantCriterion`)
          )
          .when("status", {
            is: (status: LegalEntityFATCAStatus) =>
              [
                LegalEntityFATCAStatus.PERSON_USA_NO_SPECIFIC,
                LegalEntityFATCAStatus.FATCA_COMPLIANT_FOREIGN_FINANCIAL_INSTITUTION,
                LegalEntityFATCAStatus.FATCA_COMPLIANT_FOREIGN_FINANCIAL_INSTITUTION,
              ].includes(status),
            then: yup
              .string()
              .label(
                t(
                  `${translationPrefix}.fields.fatca.fields.relevantCriterion.label`
                )
              )
              .required(
                t(
                  `${translationPrefix}.fields.fatca.fields.relevantCriterion.required`
                )
              ),
          }),
      }),
      residencies: yup.array(residencyValidationSchema(t)),
      crs: yup.object({
        status: yup
          .mixed<LegalEntityCRSStatus[]>()
          .required(t(`${translationPrefix}.fields.crs.fields.status.required`))
          .label(t(`${translationPrefix}.fields.crs.fields.status.label`)),
        statusCertificate: yup.array(baseRequiredValidation()).when("status", {
          is: (status: LegalEntityCRSStatus[]) =>
            status?.includes(
              LegalEntityCRSStatus.NON_REPORTING_FINANCIAL_INSTITUTE
            ),
          then: yup
            .array(baseRequiredValidation())
            .min(
              1,
              t(
                `${translationPrefix}.fields.crs.fields.statusCertificate.required`
              )
            )
            .max(
              1,
              t(
                `${translationPrefix}.fields.crs.fields.statusCertificate.required`
              )
            )
            .label(
              t(
                `${translationPrefix}.fields.crs.fields.statusCertificate.label`
              )
            )
            .required(
              t(
                `${translationPrefix}.fields.crs.fields.statusCertificate.required`
              )
            ),
        }),
        otherCharacteristic: yup
          .mixed<LegalEntityCRSStatusOtherCharacteristic>()
          .test(
            "otherCharacteristic",
            t(
              `${translationPrefix}.fields.crs.fields.otherCharacteristic.required`
            ),
            function (item, testContext) {
              const isPassiveNFE =
                testContext.parent.status === LegalEntityCRSStatus.PASSIVE_NFE;

              const isNonParticipatingCountry =
                testContext.parent.status ===
                LegalEntityCRSStatus.NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE;

              const error = this.createError({
                message: t(
                  `${translationPrefix}.fields.crs.fields.otherCharacteristic.required`
                ),
                path: "taxInformation.crs.otherCharacteristic",
              });

              if ((isPassiveNFE || isNonParticipatingCountry) && !item) {
                return error;
              }

              return true;
            }
          )
          .label(
            t(
              `${translationPrefix}.fields.crs.fields.otherCharacteristic.label`
            )
          ),
      }),
    })
    .required();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup.object({
    taxInformation: taxInformationValidationSchema(t),
  });

export type TaxInformation = yup.InferType<ReturnType<typeof validationSchema>>;
