import { TFunction } from "i18next";
import * as yup from "yup";
import { LegalForm } from "../../../../../../generated/globalTypes";
import {
  DocumentCategoryNames,
  DocumentsUpload,
  DocumentUploadStore,
} from "./DocumentsUpload.types";
import { documentCategoriesToRender } from "./utils/utils";

type ValidationSchema = {
  t: TFunction;
  registrationType?: LegalForm;
};

const translationPrefix = "q-ai.opportunities.form.legalEntityDocumentsUpload";

export const validationSchema = ({
  t,
  registrationType,
}: ValidationSchema): yup.SchemaOf<DocumentUploadStore> => {
  const docsCategories = documentCategoriesToRender(registrationType);

  const shouldNotValidate = (field: DocumentCategoryNames): boolean =>
    docsCategories[field] === false;

  return yup.object({
    documents: yup.object({
      currentTradeRegister: yup.object({
        documents: yup
          .mixed()
          .test(
            "currentTradeRegister",
            t(`${translationPrefix}.fields.currentTradeRegister.required`),
            (file) => {
              if (shouldNotValidate("currentTradeRegister")) {
                return true;
              }

              return file && file.length > 0;
            }
          ),
      }),
      shareholdersListNonAg: yup.object({
        documents: yup
          .mixed()
          .test(
            "shareholdersListNonAg",
            t(`${translationPrefix}.fields.shareholdersListNonAg.required`),
            (file) => {
              if (shouldNotValidate("shareholdersListNonAg")) {
                return true;
              }

              return file && file.length > 0;
            }
          ),
      }),
      shareholdersListAg: yup.object({
        documents: yup
          .mixed()
          .test(
            "shareholdersListAg",
            t(`${translationPrefix}.fields.shareholdersListAg.required`),
            (file) => {
              if (shouldNotValidate("shareholdersListAg")) {
                return true;
              }

              return file && file.length > 0;
            }
          ),
      }),
      shareholdersContract: yup.object({
        documents: yup
          .mixed()
          .test(
            "shareholdersContract",
            t(`${translationPrefix}.fields.shareholdersContract.required`),
            (file) => {
              if (shouldNotValidate("shareholdersContract")) {
                return true;
              }

              return file && file.length > 0;
            }
          ),
      }),
      incorperation: yup.object({
        documents: yup
          .mixed()
          .test(
            "incorperation",
            t(`${translationPrefix}.fields.incorperation.required`),
            (file) => {
              if (shouldNotValidate("incorperation")) {
                return true;
              }

              return file && file.length > 0;
            }
          ),
      }),
    }),
  });
};
