import { DownloadButton, DownloadButtonProps, Stack } from "@finvia/money-ui";
import { unwrapResult } from "@reduxjs/toolkit";
import { download, getFileDownloadUrl } from "models/documents";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  DocumentDisplayProps,
  FundDocumentsDisplayProps,
} from "./FundDocumentsDisplay.types";
import { ButtonWrapper } from "./FundDocumentsDisplay.styled";

const DocumentDisplay: React.FC<DocumentDisplayProps> = ({
  document,
  additionalText,
}) => {
  // we give the following a default value because in case the component is not wrapped in a form, this deconstruction will prompt errors
  const { setValue, register, unregister } = useFormContext() || {};
  const dispatch = useDispatch();
  const { id, displayName, downloadName, name, asyncDocument, url, mimetype } =
    document;

  const [documentStatus, setDocumentStatus] = useState<
    DownloadButtonProps["status"]
  >(asyncDocument ? "loading" : "available");

  useEffect(() => {
    if (!register || !unregister) {
      return;
    }

    // We need to manually connect the field to the form
    register(name);
    return () => {
      unregister(name);
    };
  }, [name, register, unregister]);

  useEffect(() => {
    if (id || url) {
      setDocumentStatus("available");
    }
  }, [id, url]);

  const onClick = useCallback(() => {
    if (displayName && setValue) {
      setValue(name, true, { shouldValidate: true });
    }
    if (url) {
      download(url, downloadName, mimetype);
      setDocumentStatus("downloaded");
    }
    if (id) {
      dispatch(getFileDownloadUrl({ id: id }))
        .then(unwrapResult)
        .then((res) => {
          if (res) {
            download(res.downloadUrl, downloadName, mimetype);
            setDocumentStatus("downloaded");
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  }, [setValue, dispatch, downloadName, name, mimetype, displayName, id, url]);

  return (
    <DownloadButton
      key={document.name}
      displayName={document.displayName}
      status={documentStatus}
      onClick={document.id || document.url ? onClick : undefined}
      fileURL={document?.url}
      description={additionalText}
    />
  );
};

const FundDocumentsDisplay: React.FC<FundDocumentsDisplayProps> = ({
  documents,
  additionalText,
}) => (
  <Stack gutter="giga" direction="row" justifyContent="center">
    {documents.map((document) => (
      <ButtonWrapper key={document.name} columns={documents.length}>
        <DocumentDisplay
          document={document}
          key={document.name}
          additionalText={additionalText}
        />
      </ButtonWrapper>
    ))}
  </Stack>
);

export default FundDocumentsDisplay;
