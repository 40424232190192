import { TFunction } from "i18next";

const translatedOptions = (
  tOptionsPrefix: string,
  t: TFunction,
  tNamespace?: string | undefined
) =>
  t((tNamespace ? `${tNamespace}:` : "") + tOptionsPrefix, {
    returnObjects: true,
  });

const translatedOptionsKeys = (
  tOptionsPrefix: string,
  t: TFunction,
  tNamespace?: string | undefined
) => {
  const options = translatedOptions(tOptionsPrefix, t, tNamespace);
  return options ? Object.keys(options) : [];
};

export const fieldOptions = (
  tOptionsPrefix: string,
  t: TFunction,
  tNamespace?: string | undefined
): string[] => {
  const otionsKeys = translatedOptionsKeys(tOptionsPrefix, t, tNamespace);
  return otionsKeys.length > 0 ? otionsKeys : [];
};

type Option = { text: string; value: string };

export const getSelectOptionsFromTranslation = (
  tOptionsPrefix: string,
  t: TFunction,
  tNamespace?: string | undefined
): Option[] =>
  fieldOptions(tOptionsPrefix, t, tNamespace).map((opt) => ({
    text: t(`${tOptionsPrefix}.${opt}`),
    value: opt,
  }));
