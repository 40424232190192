import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Currency } from "../../../../generated/globalTypes";
import { currencySymbolsDictionary, currencyFormatter } from "utils/helpers";
import {
  Heading,
  Text,
  Stack,
  Ribbon,
  Tag,
  TextInput,
  Button,
  Modal,
  Box,
} from "@finvia/money-ui";
import { useDispatch, useSelector } from "react-redux";
import { addWishListFund } from "../../funds/actions";
import {
  isPlannedFund,
  mapAdditionalTagsList,
  mapStatusToTagVariant,
} from "./FundCard.utils";
import * as S from "./FundCard.styled";
import type {
  FundCardProps,
  HandleCommitmentInput,
  TagsList,
} from "./FundCard.types";
import { FundCardFooterProps } from "features/alternative-investments/funds/components/FundCardFooter";
import { unwrapResult } from "@reduxjs/toolkit";
import { fundCurrencyTranslation } from "utils/fundCurrencyTranslation";

const translationPrefixWishlist =
  "components.alternativeInvestmentFund.wishlist";

const FundCard: React.FC<FundCardProps> = ({ model: fund, fundCardFooter }) => {
  const {
    fundManagerImage,
    assetClass,
    name,
    shortDescription,
    status,
    minimumCommitmentAmount,
    currency,
    slug,
    additionalTagsList,
    type,
    targetFundSize,
    region,
    // TODO: fix this https://github.com/FINVIA/mono/issues/7871
    // eslint-disable-next-line
  } = fund.attributes!;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user, isClient } = useSelector((state) => ({
    user: state.user,
    isClient: state.alternativeInvestments.mandateContractData.isComplete,
  }));
  const [commitmentValue, setCommitmentValue] = useState<number | undefined>(0);
  const [commitmentError, setCommitmentError] = useState<boolean>(false);
  const [showIntention, setShowIntention] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [commitmentSubmitError, setCommitmentSubmitError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const fundCurrency = currency.toUpperCase() as keyof typeof Currency;
  const formattedSlug = slug?.replace(/-/g, "_");
  const trackingLabel = `funds_${formattedSlug}_potential_commitment_submit`;
  const isAbleToClaimInterest = isPlannedFund(status) && isClient;
  const modalStateKey = commitmentSubmitError ? "error" : "success";
  const email = "service@finvia.fo";

  const additionalTags = additionalTagsList
    ? mapAdditionalTagsList(additionalTagsList)
    : [];

  const tagsList: TagsList = [
    {
      label: t(`components.alternativeInvestmentFund.status.${status}`),
      variant: mapStatusToTagVariant[status],
    },
    { label: t(`components.alternativeInvestmentFund.fundType.${type}`) },
    ...additionalTags,
  ];

  useEffect(() => {
    const hasNoCommittedValue = commitmentValue && Number(commitmentValue) <= 0;

    if (hasNoCommittedValue) {
      return setCommitmentError(true);
    }

    setCommitmentError(false);
  }, [commitmentValue]);

  const submitCommitment = async () => {
    if (!slug) {
      return console.error(`@Planned Fund: missing slug for ${name}`);
    }

    if (!user.personId) {
      return console.error("@Planned Fund: missing user id", user);
    }

    if (!commitmentValue) {
      return console.error("@Planned Fund: missing commitment value");
    }

    try {
      await dispatch(
        addWishListFund({
          slug,
          personId: user.personId,
          potentialCommitment: {
            value: Number(commitmentValue),
            currency: Currency[fundCurrency],
          },
        })
      ).then(unwrapResult);

      setModalOpen(true);
      setShowIntention(false);
      setCommitmentValue(undefined);

      if (inputRef.current) {
        inputRef.current.value = "";
      }
    } catch {
      setCommitmentSubmitError(true);
      setModalOpen(true);
    }
  };

  const handleCommitmentInput: HandleCommitmentInput = (value) => {
    if (!inputRef.current) {
      return;
    }

    const rawValue = Number(value.replace(/[.,\s]/g, ""));

    if (isNaN(rawValue)) {
      inputRef.current.value = "";
      return setCommitmentError(true);
    }

    setCommitmentValue(rawValue);
    inputRef.current.value = currencyFormatter(Currency.EUR, rawValue);
  };

  const commitmentErrorMessage = commitmentError
    ? "Ungültiger Wert, bitte geben Sie den richtigen Wert ein" //temporary, used google translate
    : "";

  const informationList = [
    {
      title: t(
        "components.alternativeInvestmentFund.minimumInvestmentAmountShorterVersion"
      ),
      information: t(fundCurrencyTranslation(currency), {
        value: minimumCommitmentAmount,
      }),
    },
    {
      title: t(
        "components.alternativeInvestmentFund.targetAmountShorterVersion"
      ),
      information: t(fundCurrencyTranslation(currency), {
        value: targetFundSize,
      }),
    },
    {
      title: t("components.alternativeInvestmentFund.region.title"),
      information: t(`components.alternativeInvestmentFund.region.${region}`),
    },
  ];

  return (
    <>
      <S.Container>
        {isPlannedFund(status) && (
          <Ribbon backgroundColor="gold.500" align="left">
            {t(`components.alternativeInvestmentFund.availableSoon`)}
          </Ribbon>
        )}
        <S.TopSection>
          <Stack gutter="mega">
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <S.Logo
                src={fundManagerImage?.data?.attributes?.url}
                alt="fund-card-logo"
              />
              <S.AssetClass>
                {t(
                  `components.alternativeInvestmentFund.assetClass.${assetClass}`
                )}
              </S.AssetClass>
            </Stack>
            <Stack gutter="mega">
              <Heading as="h2" size={{ sm: 5, lg: 6 }}>
                {name}
              </Heading>
              <Text>{shortDescription}</Text>
            </Stack>
          </Stack>
        </S.TopSection>
        <S.TagsWrapper>
          <Stack gutter="bit" direction="row">
            {tagsList.map(
              (tag) =>
                tag?.label && (
                  <Tag
                    key={tag.label}
                    label={tag.label}
                    variant={tag.variant}
                  />
                )
            )}
          </Stack>
        </S.TagsWrapper>
        <S.Body>
          <S.InformationList>
            {informationList.map((item) => (
              <S.InformationItem key={item.title}>
                <Stack gutter="byte">
                  <Text size="2">{item.title}</Text>
                  <S.Info>
                    <Text size="4">{item.information}</Text>
                  </S.Info>
                </Stack>
              </S.InformationItem>
            ))}
          </S.InformationList>

          <div>
            <S.Hr />

            {React.cloneElement<FundCardFooterProps>(fundCardFooter, {
              showIntentionForm: setShowIntention,
              isClient,
            })}
          </div>
        </S.Body>

        {isAbleToClaimInterest && (
          <S.Intention visible={showIntention}>
            <S.CloseIntention onClick={() => setShowIntention(false)}>
              x
            </S.CloseIntention>
            <Stack gutter="mega">
              <S.Wrapper>
                <TextInput crossOrigin
                  name={`fund-${slug}-intention`}
                  label={t(`${translationPrefixWishlist}.form.label`)}
                  placeholder={currencyFormatter(
                    Currency.EUR,
                    minimumCommitmentAmount
                  )}
                  decorator={{
                    type: "text",
                    value: currencySymbolsDictionary[fundCurrency],
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleCommitmentInput(event.currentTarget.value)
                  }
                  errorMessage={commitmentErrorMessage}
                  ref={inputRef}
                />
              </S.Wrapper>
              <S.Hr />
              <S.Wrapper>
                <Button
                  stretch
                  onClick={submitCommitment}
                  disabled={!commitmentValue}
                  dataAttributes={{
                    "data-analytics": trackingLabel,
                  }}
                >
                  {t(`${translationPrefixWishlist}.form.confirm`)}
                </Button>
              </S.Wrapper>
            </Stack>
          </S.Intention>
        )}
      </S.Container>

      {isAbleToClaimInterest && (
        <Modal disableBackdropClose isOpen={isModalOpen} id="modal-intention">
          <Box width="30vw">
            <Stack gutter="mega" alignItems="center">
              <Heading textTransform="uppercase" align="center">
                {t(`${translationPrefixWishlist}.modal.${modalStateKey}.title`)}
              </Heading>
              <Text align="center">
                {commitmentSubmitError ? (
                  <Trans
                    i18nKey={`${translationPrefixWishlist}.modal.error.body`}
                    values={{ email }}
                    components={{
                      Link: <a href={`mailto: ${email}`}>an uns</a>,
                    }}
                  />
                ) : (
                  t(`${translationPrefixWishlist}.modal.success.body`)
                )}
              </Text>
              <Button
                variant="ghost"
                onClick={() => {
                  setModalOpen(false);
                }}
                dataAttributes={{
                  "data-analytics": `funds_${formattedSlug}_potential_commitment_submit_${modalStateKey}`,
                }}
              >
                {t(
                  `${translationPrefixWishlist}.modal.${modalStateKey}.button`
                )}
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FundCard;
