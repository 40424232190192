export const radioQuestions = [
  "cashIntensive",
  "specialTransactions",
  "dutyDisclosure",
  "governmentVerified",
  "publicCompany",
  "privateAssetManagement",
  "publiclyTraceable",
  "transparencyRegisterObligation",
];
