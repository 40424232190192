import { TFunction } from "i18next";
import * as yup from "yup";
import address, {
  Address,
  optionalAddress,
} from "../../common/GenericValidations/address";

import { ContactDataProps } from ".";

const translationPrefix = "q-ai.opportunities.form.contactData";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup.object({
    legalEntityContactData: yup.object({
      legalAddress: address(t),
      hasSeparateContactAddress: yup
        .boolean()
        .label(
          t(`${translationPrefix}.fields.hasSeparateContactAddress.label`)
        ),
      // Bug regarding optional address and its validations is fixed, but some re-render and race conditions are still happening.
      contactAddress: yup.mixed<Address>().when("hasSeparateContactAddress", {
        is: false,
        then: optionalAddress(t),
        otherwise: address(t),
      }),
    }),
  });
