import { TableProps } from "@finvia/money-ui";
import { css } from "styled-components";

const headerCustomStyles = css`
  ${({ theme }) => css`
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
    &:last-of-type {
      justify-content: end;
    }
  `};
`;

const rowCustomStyle = css`
  ${({ theme }) => css`
    &:last-of-type {
      border-bottom: none;
    }
    ${theme.utils.viewport.md`
      grid-template-columns: 1fr 1fr 1fr 0.5fr;
    `}
  `};
`;

const cellCustomStyles = css`
  ${({ theme }) => css`
    justify-content: space-between;
    &:last-of-type {
      border-bottom: none;
    }
    ${theme.utils.viewport.md`
      justify-content: start;
      &:last-of-type {
        justify-content: end;
      }
    `};
  `};
`;

export const customTableStyles: TableProps["customStyles"] = {
  header: headerCustomStyles,
  row: rowCustomStyle,
  cell: cellCustomStyles,
};
