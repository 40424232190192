const removeLeadingZeroFromDEPhoneNumber = (
  phoneNumber: string | undefined
): string | undefined => {
  if (!phoneNumber) return;

  const countryCode = phoneNumber.slice(0, 2);
  const isDENumber = countryCode === "49";
  const hasLeadingZero = phoneNumber[2] === "0";
  const shouldRemoveLeadingZero = isDENumber && hasLeadingZero;

  return shouldRemoveLeadingZero
    ? countryCode + phoneNumber.slice(3, phoneNumber.length)
    : phoneNumber;
};

export default removeLeadingZeroFromDEPhoneNumber;
