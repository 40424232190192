import { StyleProps } from "@finvia/money-ui/dist/esm/types";
import styled, { css } from "styled-components";

const container = () => css`
  width: 100%;
  padding: 0;
  margin: 0;
`;

const itemContainer = () => css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0;
  position: relative;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const textWrapper = ({ theme }: StyleProps) => css`
  margin-left: ${theme.tokens.sizes.spacings.giga};
`;

type IconBulletStyleProps = {
  lastStep?: boolean;
  firstStep?: boolean;
} & StyleProps;

const iconBullet = ({ theme }: IconBulletStyleProps) => css`
  display: flex;
  align-items: center;
  margin-right: ${theme.tokens.sizes.spacings.giga};

  &:before {
    content: "";
    position: absolute;
    bottom: 0px;
    top: 0;
    width: 100%;
    border-left: 1.5px solid ${theme.tokens.colors.petrol[600]};
  }
`;

const iconBulletTop = ({
  firstStep,
}: {
  firstStep?: boolean;
  lastStep?: boolean;
}) =>
  firstStep &&
  css`
    &:before {
      bottom: 0px;
      top: 35%;
    }
  `;

const iconBulletBottom = ({ lastStep }: IconBulletStyleProps) =>
  lastStep &&
  css`
    &:before {
      bottom: 35%;
      top: 0;
    }
  `;

const lineStyles = ({ theme }: StyleProps) => css`
  height: 1px;
  width: 20px;
  background-color: ${theme.tokens.colors.petrol[600]};
`;

const bulletStyles = ({ theme }: StyleProps) => css`
  background-color: ${theme.tokens.colors.petrol[600]};
  border-radius: 50%;
  height: 5px;
  width: 5px;
`;

export const Container = styled.ul(container);
export const ItemContainer = styled.li(itemContainer);
export const IconBullet = styled.div<IconBulletStyleProps>(
  iconBullet,
  iconBulletTop,
  iconBulletBottom
);
export const Line = styled.div<StyleProps>(lineStyles);
export const Bullet = styled.div<StyleProps>(bulletStyles);
export const TextWrapper = styled.div<StyleProps>(textWrapper);
