import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const baseFundButton = () => css`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding-top: ${theme.tokens.sizes.spacings.mega};

    border: none;
    background-color: transparent;

    color: ${theme.tokens.colors.petrol[600]};
    text-decoration: none;

    font-family: ${theme.tokens.typography.fontFamily.sans};
    font-weight: ${theme.tokens.typography.weights.medium};
    font-size: ${theme.tokens.typography.fontSizes[2]};

    gap: ${theme.tokens.sizes.spacings.byte};
    transition: color 0.3s linear;

    &:hover {
      cursor: pointer;
      color: ${theme.tokens.colors.petrol[400]};
    }

    &:disabled {
      color ${theme.tokens.colors.neutral[500]};
      cursor: not-allowed;
    }
  `}
`;

export const FundButton = styled.button(baseFundButton);

export const FundLink = styled(Link)(baseFundButton);
