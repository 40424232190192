import { Option } from "@finvia/money-ui";
import { TFunction } from "i18next";

export type Country =
  | "DE"
  | "AT"
  | "CH"
  | "AF"
  | "EG"
  | "AX"
  | "AL"
  | "DZ"
  | "AS"
  | "VI"
  | "UM"
  | "AD"
  | "AO"
  | "AI"
  | "AQ"
  | "AG"
  | "GQ"
  | "AR"
  | "AM"
  | "AW"
  | "AC"
  | "AZ"
  | "ET"
  | "QO"
  | "AU"
  | "BS"
  | "BH"
  | "BD"
  | "BB"
  | "BY"
  | "BE"
  | "BZ"
  | "BJ"
  | "BM"
  | "BT"
  | "BO"
  | "BQ"
  | "BA"
  | "BW"
  | "BV"
  | "BR"
  | "VG"
  | "IO"
  | "BN"
  | "BG"
  | "BF"
  | "BI"
  | "CV"
  | "EA"
  | "CL"
  | "CN"
  | "CP"
  | "CK"
  | "CR"
  | "CW"
  | "DK"
  | "DE"
  | "DG"
  | "DM"
  | "DO"
  | "DJ"
  | "EC"
  | "SV"
  | "CI"
  | "ER"
  | "EE"
  | "EU"
  | "EZ"
  | "FK"
  | "FO"
  | "FJ"
  | "FI"
  | "FR"
  | "GF"
  | "PF"
  | "TF"
  | "GA"
  | "GM"
  | "GE"
  | "GH"
  | "GI"
  | "GD"
  | "GR"
  | "GL"
  | "GP"
  | "GU"
  | "GT"
  | "GG"
  | "GN"
  | "GW"
  | "GY"
  | "HT"
  | "HM"
  | "HN"
  | "HK"
  | "IN"
  | "ID"
  | "IQ"
  | "IR"
  | "IE"
  | "IS"
  | "IM"
  | "IL"
  | "IT"
  | "JM"
  | "JP"
  | "YE"
  | "JE"
  | "JO"
  | "KY"
  | "KH"
  | "CM"
  | "CA"
  | "IC"
  | "KZ"
  | "QA"
  | "KE"
  | "KG"
  | "KI"
  | "CC"
  | "CO"
  | "KM"
  | "CD"
  | "CG"
  | "XK"
  | "HR"
  | "CU"
  | "KW"
  | "LA"
  | "LS"
  | "LV"
  | "LB"
  | "LR"
  | "LY"
  | "LI"
  | "LT"
  | "LU"
  | "MO"
  | "MG"
  | "MW"
  | "MY"
  | "MV"
  | "ML"
  | "MT"
  | "MA"
  | "MH"
  | "MQ"
  | "MR"
  | "MU"
  | "YT"
  | "MX"
  | "FM"
  | "MC"
  | "MN"
  | "ME"
  | "MS"
  | "MZ"
  | "MM"
  | "NA"
  | "NR"
  | "NP"
  | "NC"
  | "NZ"
  | "NI"
  | "NL"
  | "NE"
  | "NG"
  | "NU"
  | "KP"
  | "MP"
  | "MK"
  | "NF"
  | "NO"
  | "OM"
  | "AT"
  | "TL"
  | "PK"
  | "PS"
  | "PW"
  | "PA"
  | "PG"
  | "PY"
  | "PE"
  | "PH"
  | "PN"
  | "PL"
  | "PT"
  | "PR"
  | "MD"
  | "RE"
  | "RW"
  | "RO"
  | "RU"
  | "SB"
  | "ZM"
  | "WS"
  | "SM"
  | "ST"
  | "SA"
  | "SE"
  | "CH"
  | "SN"
  | "RS"
  | "SC"
  | "SL"
  | "ZW"
  | "SG"
  | "SX"
  | "SK"
  | "SI"
  | "SO"
  | "ES"
  | "SJ"
  | "LK"
  | "BL"
  | "SH"
  | "KN"
  | "LC"
  | "MF"
  | "PM"
  | "VC"
  | "ZA"
  | "SD"
  | "GS"
  | "KR"
  | "SS"
  | "SR"
  | "SZ"
  | "SY"
  | "TJ"
  | "TW"
  | "TZ"
  | "TH"
  | "TG"
  | "TK"
  | "TO"
  | "TT"
  | "TA"
  | "TD"
  | "CZ"
  | "TN"
  | "TR"
  | "TM"
  | "TC"
  | "TV"
  | "UG"
  | "UA"
  | "HU"
  | "UY"
  | "UZ"
  | "VU"
  | "VA"
  | "VE"
  | "AE"
  | "US"
  | "GB"
  | "VN"
  | "WF"
  | "CX"
  | "EH"
  | "CF"
  | "CY";

export type Option2<Value extends string = string> = {
  value: Value;
  text: string;
  id?: string | number | undefined;
  icon?: any;
  iconColor?: any;
  onRemoveItem?: ((index: number) => void) | undefined;
  onEditItem?: ((index: number) => void) | undefined;
};

export const countries = (t: TFunction): Option2<Country>[] => [
  { value: "DE", icon: "de", text: t(`options.countries.DE`), id: "DACH_DE" },
  { value: "AT", icon: "at", text: t(`options.countries.AT`), id: "DACH_AT" },
  { value: "CH", icon: "ch", text: t(`options.countries.CH`), id: "DACH_CH" },
  { value: "AF", icon: "af", text: t(`options.countries.AF`) },
  { value: "EG", icon: "eg", text: t(`options.countries.EG`) },
  { value: "AX", icon: "ax", text: t(`options.countries.AX`) },
  { value: "AL", icon: "al", text: t(`options.countries.AL`) },
  { value: "DZ", icon: "dz", text: t(`options.countries.DZ`) },
  { value: "AS", icon: "as", text: t(`options.countries.AS`) },
  { value: "VI", icon: "vi", text: t(`options.countries.VI`) },
  { value: "UM", icon: "um", text: t(`options.countries.UM`) },
  { value: "AD", icon: "ad", text: t(`options.countries.AD`) },
  { value: "AO", icon: "ao", text: t(`options.countries.AO`) },
  { value: "AI", icon: "ai", text: t(`options.countries.AI`) },
  { value: "AQ", icon: "aq", text: t(`options.countries.AQ`) },
  { value: "AG", icon: "ag", text: t(`options.countries.AG`) },
  { value: "GQ", icon: "gq", text: t(`options.countries.GQ`) },
  { value: "AR", icon: "ar", text: t(`options.countries.AR`) },
  { value: "AM", icon: "am", text: t(`options.countries.AM`) },
  { value: "AW", icon: "aw", text: t(`options.countries.AW`) },
  { value: "AC", icon: "ac", text: t(`options.countries.AC`) },
  { value: "AZ", icon: "az", text: t(`options.countries.AZ`) },
  { value: "ET", icon: "et", text: t(`options.countries.ET`) },
  { value: "QO", icon: "xx", text: t(`options.countries.QO`) },
  { value: "AU", icon: "au", text: t(`options.countries.AU`) },
  { value: "BS", icon: "bs", text: t(`options.countries.BS`) },
  { value: "BH", icon: "bh", text: t(`options.countries.BH`) },
  { value: "BD", icon: "bd", text: t(`options.countries.BD`) },
  { value: "BB", icon: "bb", text: t(`options.countries.BB`) },
  { value: "BY", icon: "by", text: t(`options.countries.BY`) },
  { value: "BE", icon: "be", text: t(`options.countries.BE`) },
  { value: "BZ", icon: "bz", text: t(`options.countries.BZ`) },
  { value: "BJ", icon: "bj", text: t(`options.countries.BJ`) },
  { value: "BM", icon: "bm", text: t(`options.countries.BM`) },
  { value: "BT", icon: "bt", text: t(`options.countries.BT`) },
  { value: "BO", icon: "bo", text: t(`options.countries.BO`) },
  { value: "BQ", icon: "bq", text: t(`options.countries.BQ`) },
  { value: "BA", icon: "ba", text: t(`options.countries.BA`) },
  { value: "BW", icon: "bw", text: t(`options.countries.BW`) },
  { value: "BV", icon: "bv", text: t(`options.countries.BV`) },
  { value: "BR", icon: "br", text: t(`options.countries.BR`) },
  { value: "VG", icon: "vg", text: t(`options.countries.VG`) },
  { value: "IO", icon: "io", text: t(`options.countries.IO`) },
  { value: "BN", icon: "bn", text: t(`options.countries.BN`) },
  { value: "BG", icon: "bg", text: t(`options.countries.BG`) },
  { value: "BF", icon: "bf", text: t(`options.countries.BF`) },
  { value: "BI", icon: "bi", text: t(`options.countries.BI`) },
  { value: "CV", icon: "cv", text: t(`options.countries.CV`) },
  { value: "EA", icon: "ea", text: t(`options.countries.EA`) },
  { value: "CL", icon: "cl", text: t(`options.countries.CL`) },
  { value: "CN", icon: "cn", text: t(`options.countries.CN`) },
  { value: "CP", icon: "cp", text: t(`options.countries.CP`) },
  { value: "CK", icon: "ck", text: t(`options.countries.CK`) },
  { value: "CR", icon: "cr", text: t(`options.countries.CR`) },
  { value: "CW", icon: "cw", text: t(`options.countries.CW`) },
  { value: "DK", icon: "dk", text: t(`options.countries.DK`) },
  { value: "DE", icon: "de", text: t(`options.countries.DE`) },
  { value: "DG", icon: "dg", text: t(`options.countries.DG`) },
  { value: "DM", icon: "dm", text: t(`options.countries.DM`) },
  { value: "DO", icon: "do", text: t(`options.countries.DO`) },
  { value: "DJ", icon: "dj", text: t(`options.countries.DJ`) },
  { value: "EC", icon: "ec", text: t(`options.countries.EC`) },
  { value: "SV", icon: "sv", text: t(`options.countries.SV`) },
  { value: "CI", icon: "ci", text: t(`options.countries.CI`) },
  { value: "ER", icon: "er", text: t(`options.countries.ER`) },
  { value: "EE", icon: "ee", text: t(`options.countries.EE`) },
  { value: "EU", icon: "eu", text: t(`options.countries.EU`) },
  { value: "EZ", icon: "xx", text: t(`options.countries.EZ`) },
  { value: "FK", icon: "fk", text: t(`options.countries.FK`) },
  { value: "FO", icon: "fo", text: t(`options.countries.FO`) },
  { value: "FJ", icon: "fj", text: t(`options.countries.FJ`) },
  { value: "FI", icon: "fi", text: t(`options.countries.FI`) },
  { value: "FR", icon: "fr", text: t(`options.countries.FR`) },
  { value: "GF", icon: "gf", text: t(`options.countries.GF`) },
  { value: "PF", icon: "pf", text: t(`options.countries.PF`) },
  { value: "TF", icon: "tf", text: t(`options.countries.TF`) },
  { value: "GA", icon: "ga", text: t(`options.countries.GA`) },
  { value: "GM", icon: "gm", text: t(`options.countries.GM`) },
  { value: "GE", icon: "ge", text: t(`options.countries.GE`) },
  { value: "GH", icon: "gh", text: t(`options.countries.GH`) },
  { value: "GI", icon: "gi", text: t(`options.countries.GI`) },
  { value: "GD", icon: "gd", text: t(`options.countries.GD`) },
  { value: "GR", icon: "gr", text: t(`options.countries.GR`) },
  { value: "GL", icon: "gl", text: t(`options.countries.GL`) },
  { value: "GP", icon: "gp", text: t(`options.countries.GP`) },
  { value: "GU", icon: "gu", text: t(`options.countries.GU`) },
  { value: "GT", icon: "gt", text: t(`options.countries.GT`) },
  { value: "GG", icon: "gg", text: t(`options.countries.GG`) },
  { value: "GN", icon: "gn", text: t(`options.countries.GN`) },
  { value: "GW", icon: "gw", text: t(`options.countries.GW`) },
  { value: "GY", icon: "gy", text: t(`options.countries.GY`) },
  { value: "HT", icon: "ht", text: t(`options.countries.HT`) },
  { value: "HM", icon: "hm", text: t(`options.countries.HM`) },
  { value: "HN", icon: "hn", text: t(`options.countries.HN`) },
  { value: "HK", icon: "hk", text: t(`options.countries.HK`) },
  { value: "IN", icon: "in", text: t(`options.countries.IN`) },
  { value: "ID", icon: "id", text: t(`options.countries.ID`) },
  { value: "IQ", icon: "iq", text: t(`options.countries.IQ`) },
  { value: "IR", icon: "ir", text: t(`options.countries.IR`) },
  { value: "IE", icon: "ie", text: t(`options.countries.IE`) },
  { value: "IS", icon: "is", text: t(`options.countries.IS`) },
  { value: "IM", icon: "im", text: t(`options.countries.IM`) },
  { value: "IL", icon: "il", text: t(`options.countries.IL`) },
  { value: "IT", icon: "it", text: t(`options.countries.IT`) },
  { value: "JM", icon: "jm", text: t(`options.countries.JM`) },
  { value: "JP", icon: "jp", text: t(`options.countries.JP`) },
  { value: "YE", icon: "ye", text: t(`options.countries.YE`) },
  { value: "JE", icon: "je", text: t(`options.countries.JE`) },
  { value: "JO", icon: "jo", text: t(`options.countries.JO`) },
  { value: "KY", icon: "ky", text: t(`options.countries.KY`) },
  { value: "KH", icon: "kh", text: t(`options.countries.KH`) },
  { value: "CM", icon: "cm", text: t(`options.countries.CM`) },
  { value: "CA", icon: "ca", text: t(`options.countries.CA`) },
  { value: "IC", icon: "ic", text: t(`options.countries.IC`) },
  { value: "KZ", icon: "kz", text: t(`options.countries.KZ`) },
  { value: "QA", icon: "qa", text: t(`options.countries.QA`) },
  { value: "KE", icon: "ke", text: t(`options.countries.KE`) },
  { value: "KG", icon: "kg", text: t(`options.countries.KG`) },
  { value: "KI", icon: "ki", text: t(`options.countries.KI`) },
  { value: "CC", icon: "cc", text: t(`options.countries.CC`) },
  { value: "CO", icon: "co", text: t(`options.countries.CO`) },
  { value: "KM", icon: "km", text: t(`options.countries.KM`) },
  { value: "CD", icon: "cd", text: t(`options.countries.CD`) },
  { value: "CG", icon: "cg", text: t(`options.countries.CG`) },
  { value: "XK", icon: "xk", text: t(`options.countries.XK`) },
  { value: "HR", icon: "hr", text: t(`options.countries.HR`) },
  { value: "CU", icon: "cu", text: t(`options.countries.CU`) },
  { value: "KW", icon: "kw", text: t(`options.countries.KW`) },
  { value: "LA", icon: "la", text: t(`options.countries.LA`) },
  { value: "LS", icon: "ls", text: t(`options.countries.LS`) },
  { value: "LV", icon: "lv", text: t(`options.countries.LV`) },
  { value: "LB", icon: "lb", text: t(`options.countries.LB`) },
  { value: "LR", icon: "lr", text: t(`options.countries.LR`) },
  { value: "LY", icon: "ly", text: t(`options.countries.LY`) },
  { value: "LI", icon: "li", text: t(`options.countries.LI`) },
  { value: "LT", icon: "lt", text: t(`options.countries.LT`) },
  { value: "LU", icon: "lu", text: t(`options.countries.LU`) },
  { value: "MO", icon: "mo", text: t(`options.countries.MO`) },
  { value: "MG", icon: "mg", text: t(`options.countries.MG`) },
  { value: "MW", icon: "mw", text: t(`options.countries.MW`) },
  { value: "MY", icon: "my", text: t(`options.countries.MY`) },
  { value: "MV", icon: "mv", text: t(`options.countries.MV`) },
  { value: "ML", icon: "ml", text: t(`options.countries.ML`) },
  { value: "MT", icon: "mt", text: t(`options.countries.MT`) },
  { value: "MA", icon: "ma", text: t(`options.countries.MA`) },
  { value: "MH", icon: "mh", text: t(`options.countries.MH`) },
  { value: "MQ", icon: "mq", text: t(`options.countries.MQ`) },
  { value: "MR", icon: "mr", text: t(`options.countries.MR`) },
  { value: "MU", icon: "mu", text: t(`options.countries.MU`) },
  { value: "YT", icon: "yt", text: t(`options.countries.YT`) },
  { value: "MX", icon: "mx", text: t(`options.countries.MX`) },
  { value: "FM", icon: "fm", text: t(`options.countries.FM`) },
  { value: "MC", icon: "mc", text: t(`options.countries.MC`) },
  { value: "MN", icon: "mn", text: t(`options.countries.MN`) },
  { value: "ME", icon: "me", text: t(`options.countries.ME`) },
  { value: "MS", icon: "ms", text: t(`options.countries.MS`) },
  { value: "MZ", icon: "mz", text: t(`options.countries.MZ`) },
  { value: "MM", icon: "mm", text: t(`options.countries.MM`) },
  { value: "NA", icon: "na", text: t(`options.countries.NA`) },
  { value: "NR", icon: "nr", text: t(`options.countries.NR`) },
  { value: "NP", icon: "np", text: t(`options.countries.NP`) },
  { value: "NC", icon: "nc", text: t(`options.countries.NC`) },
  { value: "NZ", icon: "nz", text: t(`options.countries.NZ`) },
  { value: "NI", icon: "ni", text: t(`options.countries.NI`) },
  { value: "NL", icon: "nl", text: t(`options.countries.NL`) },
  { value: "NE", icon: "ne", text: t(`options.countries.NE`) },
  { value: "NG", icon: "ng", text: t(`options.countries.NG`) },
  { value: "NU", icon: "nu", text: t(`options.countries.NU`) },
  { value: "KP", icon: "kp", text: t(`options.countries.KP`) },
  { value: "MP", icon: "mp", text: t(`options.countries.MP`) },
  { value: "MK", icon: "mk", text: t(`options.countries.MK`) },
  { value: "NF", icon: "nf", text: t(`options.countries.NF`) },
  { value: "NO", icon: "no", text: t(`options.countries.NO`) },
  { value: "OM", icon: "om", text: t(`options.countries.OM`) },
  { value: "AT", icon: "at", text: t(`options.countries.AT`) },
  { value: "TL", icon: "tl", text: t(`options.countries.TL`) },
  { value: "PK", icon: "pk", text: t(`options.countries.PK`) },
  { value: "PS", icon: "ps", text: t(`options.countries.PS`) },
  { value: "PW", icon: "pw", text: t(`options.countries.PW`) },
  { value: "PA", icon: "pa", text: t(`options.countries.PA`) },
  { value: "PG", icon: "pg", text: t(`options.countries.PG`) },
  { value: "PY", icon: "py", text: t(`options.countries.PY`) },
  { value: "PE", icon: "pe", text: t(`options.countries.PE`) },
  { value: "PH", icon: "ph", text: t(`options.countries.PH`) },
  { value: "PN", icon: "pn", text: t(`options.countries.PN`) },
  { value: "PL", icon: "pl", text: t(`options.countries.PL`) },
  { value: "PT", icon: "pt", text: t(`options.countries.PT`) },
  { value: "PR", icon: "pr", text: t(`options.countries.PR`) },
  { value: "MD", icon: "md", text: t(`options.countries.MD`) },
  { value: "RE", icon: "re", text: t(`options.countries.RE`) },
  { value: "RW", icon: "rw", text: t(`options.countries.RW`) },
  { value: "RO", icon: "ro", text: t(`options.countries.RO`) },
  { value: "RU", icon: "ru", text: t(`options.countries.RU`) },
  { value: "SB", icon: "sb", text: t(`options.countries.SB`) },
  { value: "ZM", icon: "zm", text: t(`options.countries.ZM`) },
  { value: "WS", icon: "ws", text: t(`options.countries.WS`) },
  { value: "SM", icon: "sm", text: t(`options.countries.SM`) },
  { value: "ST", icon: "st", text: t(`options.countries.ST`) },
  { value: "SA", icon: "sa", text: t(`options.countries.SA`) },
  { value: "SE", icon: "se", text: t(`options.countries.SE`) },
  { value: "CH", icon: "ch", text: t(`options.countries.CH`) },
  { value: "SN", icon: "sn", text: t(`options.countries.SN`) },
  { value: "RS", icon: "rs", text: t(`options.countries.RS`) },
  { value: "SC", icon: "sc", text: t(`options.countries.SC`) },
  { value: "SL", icon: "sl", text: t(`options.countries.SL`) },
  { value: "ZW", icon: "zw", text: t(`options.countries.ZW`) },
  { value: "SG", icon: "sg", text: t(`options.countries.SG`) },
  { value: "SX", icon: "sx", text: t(`options.countries.SX`) },
  { value: "SK", icon: "sk", text: t(`options.countries.SK`) },
  { value: "SI", icon: "si", text: t(`options.countries.SI`) },
  { value: "SO", icon: "so", text: t(`options.countries.SO`) },
  { value: "ES", icon: "es", text: t(`options.countries.ES`) },
  { value: "SJ", icon: "sj", text: t(`options.countries.SJ`) },
  { value: "LK", icon: "lk", text: t(`options.countries.LK`) },
  { value: "BL", icon: "bl", text: t(`options.countries.BL`) },
  { value: "SH", icon: "sh", text: t(`options.countries.SH`) },
  { value: "KN", icon: "kn", text: t(`options.countries.KN`) },
  { value: "LC", icon: "lc", text: t(`options.countries.LC`) },
  { value: "MF", icon: "mf", text: t(`options.countries.MF`) },
  { value: "PM", icon: "pm", text: t(`options.countries.PM`) },
  { value: "VC", icon: "vc", text: t(`options.countries.VC`) },
  { value: "ZA", icon: "za", text: t(`options.countries.ZA`) },
  { value: "SD", icon: "sd", text: t(`options.countries.SD`) },
  { value: "GS", icon: "gs", text: t(`options.countries.GS`) },
  { value: "KR", icon: "kr", text: t(`options.countries.KR`) },
  { value: "SS", icon: "ss", text: t(`options.countries.SS`) },
  { value: "SR", icon: "sr", text: t(`options.countries.SR`) },
  { value: "SZ", icon: "sz", text: t(`options.countries.SZ`) },
  { value: "SY", icon: "sy", text: t(`options.countries.SY`) },
  { value: "TJ", icon: "tj", text: t(`options.countries.TJ`) },
  { value: "TW", icon: "tw", text: t(`options.countries.TW`) },
  { value: "TZ", icon: "tz", text: t(`options.countries.TZ`) },
  { value: "TH", icon: "th", text: t(`options.countries.TH`) },
  { value: "TG", icon: "tg", text: t(`options.countries.TG`) },
  { value: "TK", icon: "tk", text: t(`options.countries.TK`) },
  { value: "TO", icon: "to", text: t(`options.countries.TO`) },
  { value: "TT", icon: "tt", text: t(`options.countries.TT`) },
  { value: "TA", icon: "ta", text: t(`options.countries.TA`) },
  { value: "TD", icon: "td", text: t(`options.countries.TD`) },
  { value: "CZ", icon: "cz", text: t(`options.countries.CZ`) },
  { value: "TN", icon: "tn", text: t(`options.countries.TN`) },
  { value: "TR", icon: "tr", text: t(`options.countries.TR`) },
  { value: "TM", icon: "tm", text: t(`options.countries.TM`) },
  { value: "TC", icon: "tc", text: t(`options.countries.TC`) },
  { value: "TV", icon: "tv", text: t(`options.countries.TV`) },
  { value: "UG", icon: "ug", text: t(`options.countries.UG`) },
  { value: "UA", icon: "ua", text: t(`options.countries.UA`) },
  { value: "HU", icon: "hu", text: t(`options.countries.HU`) },
  { value: "UY", icon: "uy", text: t(`options.countries.UY`) },
  { value: "UZ", icon: "uz", text: t(`options.countries.UZ`) },
  { value: "VU", icon: "vu", text: t(`options.countries.VU`) },
  { value: "VA", icon: "va", text: t(`options.countries.VA`) },
  { value: "VE", icon: "ve", text: t(`options.countries.VE`) },
  { value: "AE", icon: "ae", text: t(`options.countries.AE`) },
  { value: "US", icon: "us", text: t(`options.countries.US`) },
  { value: "GB", icon: "gb", text: t(`options.countries.GB`) },
  { value: "VN", icon: "vn", text: t(`options.countries.VN`) },
  { value: "WF", icon: "wf", text: t(`options.countries.WF`) },
  { value: "CX", icon: "cx", text: t(`options.countries.CX`) },
  { value: "EH", icon: "eh", text: t(`options.countries.EH`) },
  { value: "CF", icon: "cf", text: t(`options.countries.CF`) },
  { value: "CY", icon: "cy", text: t(`options.countries.CY`) },
];
