import styled, { css } from "styled-components";

const notFound = () => css`
  ${({ theme: { tokens } }) => css`
    background-color: ${tokens.colors.white[500]};
    padding: ${tokens.sizes.spacings.giga};
  `};
`;

export const NotFoundWrapper = styled.div(notFound);
