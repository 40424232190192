import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { compact, find } from "lodash";
import { FundFragment } from "../../../generated/FundFragment";
import { FormDefaultData } from "../../../utils/graphQLPartial";
import {
  addWishListFund,
  getAIFundContract,
  getAIFundContractDocumentSet,
  getAIFundContractDocumentSubscriptionDocumentDraft,
  getAIFundContractDocumentTrustAndAdministrationContract,
  getFundBySlug,
  getFundContractSigningData,
  getFundsCollection,
  signAIFundContract,
  upsertAlternativeInvestmentsFundContract,
} from "./actions";
import { Contract } from "./fundValidations";

interface State {
  isLoading: boolean;
  data: FormDefaultData<Contract>;
  funds: EntityState<FundFragment>;
}

export const adapters = {
  // a fund will always have a slug
  Funds: createEntityAdapter<FundFragment>({
    selectId: ({ attributes }) => attributes?.slug || "",
  }),
};

const initialState: State = {
  isLoading: false,
  funds: adapters.Funds.getInitialState(),
  data: {
    declarations: {},
    signing: {},
  },
};

const slice = createSlice({
  name: "funds",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getFundsCollection.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFundsCollection.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.funds = adapters.Funds.addMany(state.funds, compact(payload));
      })
      .addCase(getFundBySlug.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFundBySlug.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.funds = adapters.Funds.addMany(
          state.funds,
          compact(payload?.data)
        );
      })
      .addCase(getFundContractSigningData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = {
          ...state.data,
          ...payload,
        };
      })
      .addCase(signAIFundContract.fulfilled, (state, { payload }) => {
        state.data = {
          ...state.data,
          ...payload,
        };
      })
      .addCase(getAIFundContract.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAIFundContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = {
          ...state.data,
          ...payload,
        };
      })
      .addCase(
        upsertAlternativeInvestmentsFundContract.fulfilled,
        (state, { payload }) => {
          state.data = {
            ...state.data,
            ...payload,
          };
        }
      )
      .addCase(getAIFundContractDocumentSet.fulfilled, (state, { payload }) => {
        state.data = {
          ...state.data,
          ...payload,
        };
      })
      .addCase(
        getAIFundContractDocumentTrustAndAdministrationContract.fulfilled,
        (state, { payload }) => {
          state.data = {
            ...state.data,
            ...payload,
          };
        }
      )
      .addCase(
        getAIFundContractDocumentSubscriptionDocumentDraft.fulfilled,
        (state, { payload }) => {
          state.data = {
            ...state.data,
            ...payload,
          };
        }
      )
      .addCase(addWishListFund.fulfilled, (state, { payload }) => {
        const upsertedFund = find(
          state.funds.entities,
          (fund) => fund?.attributes?.slug === payload.slug
        );

        if (!upsertedFund) {
          return;
        }

        state.funds = adapters.Funds.upsertOne(state.funds, {
          ...upsertedFund,
          wishlisted: true,
        } as FundFragment);
      }),
});

export const { selectById: fundBySlug } = adapters.Funds.getSelectors();

export default slice;
