import {
  FundFragment_attributes_trackRecord,
  FundFragment_attributes_trackRecord_standardFundTable,
} from "generated/FundFragment";
import {
  ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY,
  ENUM_COMPONENTGENERICTEXTTRACKRECORD_PROFILE,
} from "generated/globalTypes";

export const mockedStandardFundTableData: FundFragment_attributes_trackRecord_standardFundTable =
  {
    fundGeneration: "Generation 1",
    vintageYear: "1990",
    fundVolume: "10000",
    invested: "3381",
    investedRealized: "13900",
    totalValue: "12087",
    __typename: "ComponentGenericTextStandardFundTable",
  };

export const mockedTrackRecord: FundFragment_attributes_trackRecord = {
  profile: ENUM_COMPONENTGENERICTEXTTRACKRECORD_PROFILE.standarFund,
  images: {
    __typename: "UploadFileRelationResponseCollection",
    data: [
      {
        __typename: "UploadFileEntity",
        attributes: {
          url: "/uploads/GLENDOWER_29021d5330",
          __typename: "UploadFile",
        },
      },
      {
        __typename: "UploadFileEntity",
        attributes: {
          url: "/uploads/GLENDOWER_704df4e8a3",
          __typename: "UploadFile",
        },
      },
      {
        __typename: "UploadFileEntity",
        attributes: {
          url: "/uploads/FINVIA_d3e24e0ca4",
          __typename: "UploadFile",
        },
      },
    ],
  },
  standardFundTable: [
    mockedStandardFundTableData,
    mockedStandardFundTableData,
    mockedStandardFundTableData,
  ],
  selectionFundTable: null,
  tableFooter:
    "Quelle: Genstar, H.I.G, KKR, TA, CapVest, Glendower; Stand: Q2 2022; Anmerkung: Performance-Kennzahlen aufgrund des jungen Fondsalters ggf.",
  __typename: "ComponentGenericTextTrackRecord",
  imagesTitle: "Images Title",
  tableTitle: "Table Title",
};

export const mockedCurrency = ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.eur;
