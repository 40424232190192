import BankAccountSelection from "features/alternative-investments/funds/BankAccountSelection/BankAccountSelection";
import { Dashboard } from "features/alternative-investments/funds/Dashboard";
import IdentificationLoader from "features/alternative-investments/questionnaires/mandateContract/legalEntity/IdentificationLoader/IdentificationLoader";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { isDefined } from "utils/helpers";
import Layout from "../components/Layout";
import AIQuestionnaireRoot from "../features/alternative-investments";
import useCompletedForms from "../features/alternative-investments/components/useCompletedForms";
import FundDeclarations from "../features/alternative-investments/funds/FundDeclarations/FundDeclarations";
import FundDetailsPage from "../features/alternative-investments/funds/FundDetailsPage/FundDetailsPage";
import FundExternalSigningSuccess from "../features/alternative-investments/funds/FundExternalSigningSuccess/FundExternalSigningSuccess";
import FundSigning from "../features/alternative-investments/funds/FundSigning/FundSigning";
import FundSigningNotDigital from "../features/alternative-investments/funds/FundSigningNotDigital/FundSigningNotDigital";
import FundSigningSuccess from "../features/alternative-investments/funds/FundSigningSuccess/FundSigningSuccess";
import AIContractType from "../features/alternative-investments/questionnaires/contractAndRegulatoryData";
import AIContractAndRegulatoryDataLegalEntityQuestionnaire from "../features/alternative-investments/questionnaires/contractAndRegulatoryData/legalEntity";
import AIContractAndRegulatoryDataNaturalPersonQuestionnaire from "../features/alternative-investments/questionnaires/contractAndRegulatoryData/naturalPerson";
import AIInvestorProfileQuestionnaire from "../features/alternative-investments/questionnaires/investorProfile";
import AIMandateContractLegalEntityQuestionnaire from "../features/alternative-investments/questionnaires/mandateContract/legalEntity";
import AIMandateContractNaturalPersonQuestionnaire from "../features/alternative-investments/questionnaires/mandateContract/naturalPerson";
import { typedUseSelector } from "../store";
import { ROUTES } from "./routes";

const Router: React.FC = () => {
  const completedForms = useCompletedForms();

  const rootRoute = completedForms
    ? ROUTES.funds.root
    : ROUTES.questionnaires.ai.root;

  const { investorProfileDone, isRegulatoryDataTouched } = typedUseSelector(
    (state) => {
      const { alternativeInvestments, user } = state;

      return {
        userGroups: user.groups,
        investorProfileDone:
          alternativeInvestments.investmentOpportunities.isComplete,
        isRegulatoryDataTouched: isDefined(
          alternativeInvestments.regulatoryData.personData.personalData
            ?.dateOfBirth
        ),
      };
    }
  );

  const investmentOpportunitiesPath = `${ROUTES.questionnaires.ai.opportunities}/5`;

  return (
    <Switch>
      <Route>
        <Layout>
          <Switch>
            <Route
              path={ROUTES.questionnaires.ai.root}
              component={AIQuestionnaireRoot}
              exact
            />
            <Route
              path={ROUTES.questionnaires.ai.opportunities}
              component={AIInvestorProfileQuestionnaire}
            />
            <Route
              path={ROUTES.questionnaires.ai.regulatory.naturalPerson}
              component={AIContractAndRegulatoryDataNaturalPersonQuestionnaire}
            />
            <Route
              path={ROUTES.questionnaires.ai.regulatory.legalEntity.root}
              component={AIContractAndRegulatoryDataLegalEntityQuestionnaire}
            />
            <Route
              path={ROUTES.questionnaires.ai.mandate.identificationLoader}
              component={IdentificationLoader}
            />
            {investorProfileDone && (
              <Route
                path={ROUTES.questionnaires.ai.regulatory.root}
                component={AIContractType}
              />
            )}
            <Route
              path={ROUTES.questionnaires.ai.mandate.naturalPerson}
              component={AIMandateContractNaturalPersonQuestionnaire}
            />
            <Route
              path={ROUTES.questionnaires.ai.mandate.legalEntity}
              component={AIMandateContractLegalEntityQuestionnaire}
            />
            <Route path={ROUTES.funds.root} component={Dashboard} exact />
            <Route
              path={ROUTES.funds.details.bankAccountSelection}
              component={BankAccountSelection}
            />
            <Route
              path={ROUTES.funds.details.declarations}
              component={FundDeclarations}
            />
            <Route
              path={ROUTES.funds.details.signing}
              component={FundSigning}
            />
            <Route
              path={ROUTES.funds.details.signingNotDigital}
              component={FundSigningNotDigital}
            />
            <Route
              path={ROUTES.funds.details.signingSuccessful}
              component={FundSigningSuccess}
            />
            <Route
              path={ROUTES.funds.details.externalSigningSuccessful}
              component={FundExternalSigningSuccess}
            />
            <Route
              path={ROUTES.funds.details.root}
              component={FundDetailsPage}
            />
            <Route
              render={() =>
                investorProfileDone && !isRegulatoryDataTouched ? (
                  <Redirect to={investmentOpportunitiesPath} />
                ) : (
                  <Redirect to={rootRoute} />
                )
              }
            />
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};

export default Router;
