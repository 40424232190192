import { Option } from "@finvia/money-ui";
import { TFunction } from "i18next";
import {
  BranchOfIndustry,
  FACTAStatus,
  LegalEntityFunctionaryRole,
  PEPRelationship,
  PEPRole,
  PEPStatus,
} from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData.fields";

export const BranchOfIndustryOptions = (t: TFunction): Option[] =>
  Object.values(BranchOfIndustry).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.employment.status.values.${v}`),
  }));

export const LegalEntityFunctionaryRoleOptions = (t: TFunction): Option[] =>
  Object.values(LegalEntityFunctionaryRole).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.employment.role.values.${v}`),
  }));

export const PEPStatusOptions = (t: TFunction): Option[] =>
  Object.values(PEPStatus).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.employment.pep.status.values.${v}`),
  }));

export const PEPRelationshipOptions = (t: TFunction): Option[] =>
  Object.values(PEPRelationship).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.employment.pep.relationship.values.${v}`),
  }));

export const PEPRoleOptions = (t: TFunction): Option[] =>
  Object.values(PEPRole).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.employment.pep.role.values.${v}`),
  }));

export const FACTAStatusOptions = (t: TFunction): Option[] =>
  Object.values(FACTAStatus).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.taxInfo.facta.status.values.${v}`),
  }));
