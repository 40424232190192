import { Stack } from "@finvia/money-ui";
import Manager from "./Manager/Manager";
import InvestmentExample from "./InvestmentExample/InvestmentExample";
import InvestmentPotential from "./InvestmentPotential/InvestmentPotential";
import { useTranslation } from "react-i18next";
import { Container } from "./Profile.styled";
import useCompletedForms from "../../useCompletedForms";
import { mockedInvestmentExamples, mockedManager } from "./utils";
import { ProfileProps } from "./Profile.types";
import BlockDialog from "../BlockDialog/BlockDialog";

const Profile = ({ fund, funds }: ProfileProps): React.ReactElement => {
  const areFormsCompleted = useCompletedForms();
  const fundAttributes = fund.attributes;
  const shouldShowBlurredMockedData =
    areFormsCompleted === false && !!fundAttributes?.profile?.manager;

  const hasInvestmentExamples =
    fundAttributes?.profile?.investmentExamples?.length !== 0;
  const hasInvestmentPotential =
    fundAttributes?.profile?.investmentPotential?.length !== 0;

  return (
    <Container>
      <Stack gutter="tera">
        {fundAttributes?.currency && (
          <Manager
            manager={
              shouldShowBlurredMockedData
                ? mockedManager
                : fundAttributes?.profile?.manager
            }
            fundCurrency={fundAttributes?.currency}
          />
        )}
        {hasInvestmentExamples && (
          <InvestmentExample
            investmentExamples={
              shouldShowBlurredMockedData
                ? mockedInvestmentExamples
                : fundAttributes?.profile?.investmentExamples
            }
          />
        )}
        {hasInvestmentPotential && (
          <InvestmentPotential
            investmentPotential={
              shouldShowBlurredMockedData
                ? null
                : fundAttributes?.profile?.investmentPotential
            }
            funds={funds}
          />
        )}
      </Stack>
      {!areFormsCompleted && <BlockDialog />}
    </Container>
  );
};

export default Profile;
