import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import {
  BranchOfIndustry,
  DocumentCategory,
  EmploymentStatus,
  FACTAStatus,
} from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import {
  updateEmploymentAndPersonalIrsDocumentData,
  updateProfile,
} from "../../actions";
import {
  EmploymentAndTax,
  validationSchema,
} from "./employmentAndTaxDataValidations";
import PEPFields from "./PEPFields";
import ResidencyFields from "./ResidencyFields";
import { Grid, Heading, SelectInput, Stack, Text } from "@finvia/money-ui";
import { selectData } from "./helper";
import {
  Document,
  HandleDocumentDelete,
  HandleDocumentUpload,
} from "./EmploymentAndTaxForm.types";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import {
  handleDeleteDocumentGeneric,
  handleDocumentUploadGeneric,
  mapDocumentStatusToUpload,
  UploadField,
} from "features/alternative-investments/components/UploadField";
import { ActionDocChangePayload } from "types/types";
import styled from "styled-components";

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData";

// TODO: Replace with proper FullRow from Grid component when it exists
export const FullRow = styled.div`
  grid-column: 1 / -1;
`;

const EmploymentAndTaxForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const defaultData = typedUseSelector((store) => {
    const { taxInfo, employment } =
      store.alternativeInvestments.regulatoryData?.personData || {};

    return formDefaultsDeep<EmploymentAndTax>(
      {
        employment,
        taxInfo: {
          facta: {
            status: taxInfo?.facta?.status,
            irsDocuments: taxInfo?.facta?.irsDocuments,
          },
          residencies: taxInfo?.residencies?.length
            ? taxInfo?.residencies
            : [{ country: "", taxId: "", localTaxOffice: "" }],
        },
      },
      {
        employment: {
          country: "DE",
          status: "",
          branchOfIndustry: "",
          sector: "",
          pep: {
            status: "",
            role: "",
            relationship: "",
          },
        },
        taxInfo: {
          facta: {
            status: "",
            irsDocuments: [],
          },
        },
      }
    );
  });

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data: EmploymentAndTax) => {
      dispatch(updateProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const [irsDocuments, setIrsDocuments] = useState<Document[] | undefined>(
    defaultData.taxInfo?.facta?.irsDocuments
  );

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    setValueReference
  ) => {
    const filteredDocuments =
      await handleDeleteDocumentGeneric<ActionDocChangePayload>({
        document,
        documents,
        setValueReference,
        dispatch,
        updateAction: updateEmploymentAndPersonalIrsDocumentData,
        setError,
        name: "taxInfo.facta.irsDocuments",
        formName: "EmploymentAndTaxForm",
      });

    if (irsDocuments && Array.isArray(filteredDocuments)) {
      setIrsDocuments([...filteredDocuments]);
    }
  };

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    setValueReference
  ) => {
    const uploadedDocuments =
      await handleDocumentUploadGeneric<ActionDocChangePayload>({
        documents,
        file,
        setValueReference,
        dispatch,
        updateAction: updateEmploymentAndPersonalIrsDocumentData,
        category: DocumentCategory.IRS,
        setError,
        name: "taxInfo.facta.irsDocuments",
        formName: "EmploymentAndTaxForm",
      });

    if (irsDocuments && Array.isArray(uploadedDocuments)) {
      setIrsDocuments(uploadedDocuments);
    }
  };

  return (
    <FormControl<EmploymentAndTax>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.4_np_employment_and_tax"
    >
      {(formMethods) => {
        /* Same as 2_PersonalDataForm */
        const { errors, register, control, setValue } = formMethods;

        const employmentStatus = formMethods.watch("employment.status");

        const pepStatus = formMethods.watch("employment.pep.status");

        const facta = formMethods.watch("taxInfo.facta.status");
        const needsIRSDocs = [
          FACTAStatus.YES,
          FACTAStatus.NO_BUT_US_CITIZENSHIP,
        ].includes(facta);

        let noBranchSelection = false;

        switch (employmentStatus) {
          case employmentStatus === EmploymentStatus.DIPLOMAT:
            formMethods.setValue(
              "employment.branchOfIndustry",
              BranchOfIndustry.PUBLIC_SERVICE
            );
            noBranchSelection = true;
            break;
          case employmentStatus === EmploymentStatus.PRIVATE_INDIVIDUAL:
          case employmentStatus === EmploymentStatus.STUDENT:
            formMethods.setValue(
              "employment.branchOfIndustry",
              BranchOfIndustry.NOT_SPECIFIED
            );
            noBranchSelection = true;
            break;
        }

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
          >
            <Stack gutter="exa">
              <Stack gutter="giga">
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.employment.headline`)}
                </Heading>
                <Grid
                  columns={{ sm: 1, md: 2 }}
                  rowsGap="giga"
                  columnsGap="yotta"
                >
                  {selectData({
                    defaultData,
                    errors,
                    t,
                    translationPrefix,
                    noBranchSelection,
                  }).map((select) => (
                    <SelectInput
                      key={select.name}
                      options={select.options}
                      name={select.name}
                      label={select.label}
                      required={select.required}
                      ref={register}
                      {...(select.defaultData && {
                        value: select.defaultData,
                      })}
                      errorMessage={select.errorMessage}
                      disabled={select.disabled}
                      helperText={select.helperText}
                    />
                  ))}
                  <PEPFields<EmploymentAndTax>
                    pep={pepStatus}
                    formMethods={formMethods}
                    schema={memoizedSchema}
                    translationPrefix={translationPrefix}
                    defaultData={defaultData}
                    register={register}
                  />
                </Grid>
              </Stack>

              <Stack gutter="giga">
                <Text size={{ sm: 3, lg: 4 }}>
                  {t(`${translationPrefix}.tax.headline`)}
                </Text>
                <Grid
                  columns={{ sm: 1, md: 2 }}
                  rowsGap="giga"
                  columnsGap="yotta"
                >
                  <ResidencyFields validationSchema={memoizedSchema} />
                  <SelectInput
                    options={getSelectOptionsFromTranslation(
                      `${translationPrefix}.fields.taxInfo.facta.status.values`,
                      t
                    )}
                    name="taxInfo.facta.status"
                    label={t(
                      `${translationPrefix}.fields.taxInfo.facta.status.label`
                    )}
                    required
                    ref={register}
                    {...(defaultData.taxInfo?.facta?.status && {
                      value: defaultData.taxInfo?.facta?.status,
                    })}
                    errorMessage={errors.taxInfo?.facta?.status?.message}
                    helperText={t(
                      `${translationPrefix}.fields.taxInfo.facta.status.tooltip`
                    )}
                  />
                  {needsIRSDocs && (
                    <UploadField
                      name="taxInfo.facta.irsDocuments"
                      onDelete={(document, documents) => {
                        handleDeleteDocument(document, documents, setValue);
                      }}
                      onUpload={(documents, file) => {
                        handleDocumentUpload(documents, file, setValue);
                      }}
                      documentsList={mapDocumentStatusToUpload(irsDocuments)}
                      label={t(
                        `${translationPrefix}.fields.taxInfo.facta.irsDocuments.label`
                      )}
                      helperText={t(
                        `${translationPrefix}.fields.taxInfo.facta.irsDocuments.tooltip`
                      )}
                      inputErrorMessage={
                        errors.taxInfo?.facta?.irsDocuments?.message
                      }
                      barErrorMessage={t("form.fileUpload.failed")}
                      barSuccessMessage={t("form.fileUpload.succeeded")}
                      formMethods={formMethods}
                    />
                  )}
                </Grid>
              </Stack>
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default EmploymentAndTaxForm;
