import { SerializedError } from "@reduxjs/toolkit";
import { useState } from "react";
import { UseFormMethods, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  DocumentCategory,
  LegalEntityCRSStatus,
  LegalEntityCRSStatusOtherCharacteristic,
} from "../../../../../../generated/globalTypes";
import { Grid, Heading, SelectInput } from "@finvia/money-ui";
import { HandleDocumentUpload, Document } from "./";
import {
  UploadField,
  handleDocumentUploadGeneric,
  handleDeleteDocumentGeneric,
} from "../../../../components/UploadField";
import { updateTaxInformationCrsStatusDocuments } from "../actions";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { ActionDocChangePayload } from "types/types";
import { CRSFieldsProps, HandleDocumentDelete } from "./TaxInformation.types";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

export default function CRSFields({
  formMethods,
  defaultData,
}: CRSFieldsProps): JSX.Element {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const crsStatus = useWatch({
    control: formMethods.control,
    name: "taxInformation.crs.status",
  }) as LegalEntityCRSStatus[];

  const crsStatusOtherCharacteristic = useWatch({
    control: formMethods.control,
    name: "taxInformation.crs.otherCharacteristic",
  }) as LegalEntityCRSStatusOtherCharacteristic;

  const showStatusCertificate =
    (crsStatus &&
      crsStatus.includes(
        LegalEntityCRSStatus.NON_REPORTING_FINANCIAL_INSTITUTE
      )) ||
    crsStatusOtherCharacteristic ===
      LegalEntityCRSStatusOtherCharacteristic.ANOTHER_FINANCIAL_INSTITUTION;

  const showOtherCharacteristic =
    crsStatus?.includes(
      LegalEntityCRSStatus.NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE
    ) || crsStatus?.includes(LegalEntityCRSStatus.PASSIVE_NFE);

  const [currentDocumentsList, setCurrentDocumentsList] = useState<Document[]>(
    defaultData?.statusCertificate ?? []
  );

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    setValueReference
  ) => {
    const uploadedDocuments =
      await handleDocumentUploadGeneric<ActionDocChangePayload>({
        documents,
        file,
        setValueReference,
        dispatch,
        updateAction: updateTaxInformationCrsStatusDocuments,
        category: DocumentCategory.CRS_STATUS_CERTIFICATE,
        setError,
        name: "taxInformation.crs.statusCertificate",
        formName: "TaxInformationForm",
      });

    if (uploadedDocuments && Array.isArray(uploadedDocuments)) {
      setCurrentDocumentsList([...currentDocumentsList, ...uploadedDocuments]);
    }
  };

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    name,
    setValueReference
  ) => {
    const filteredDocuments =
      await handleDeleteDocumentGeneric<ActionDocChangePayload>({
        document,
        documents,
        setValueReference,
        dispatch,
        updateAction: updateTaxInformationCrsStatusDocuments,
        setError,
        name,
        formName: "TaxInformationForm",
      });

    if (filteredDocuments) {
      setCurrentDocumentsList([...filteredDocuments]);
    }
  };

  const watchCRSStatus = formMethods.watch("taxInformation.crs.status");

  if (watchCRSStatus) {
    formMethods.trigger("taxInformation.crs.otherCharacteristic");
  }

  return (
    <>
      <Grid columnsGap="yotta" rowsGap="giga" columns={1}>
        <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
          {t(`${translationPrefix}.fields.crs.title`)}
        </Heading>
      </Grid>
      <Grid columnsGap="yotta" rowsGap="giga" columns={{ sm: 1, md: 2 }}>
        <SelectInput
          ref={formMethods.register}
          name="taxInformation.crs.status"
          label={t(`${translationPrefix}.fields.crs.fields.status.label`)}
          required
          {...(defaultData?.status?.[0] && {
            value: defaultData?.status?.[0],
          })}
          options={getSelectOptionsFromTranslation(
            `${translationPrefix}.fields.crs.fields.status.values`,
            t
          )}
          errorMessage={
            formMethods.errors.taxInformation?.crs?.status?.[0]?.message
          }
          helperText={t(
            `${translationPrefix}.fields.crs.fields.status.tooltip`
          )}
        />
        {showOtherCharacteristic && (
          <SelectInput
            ref={formMethods.register}
            name="taxInformation.crs.otherCharacteristic"
            label={t(
              `${translationPrefix}.fields.crs.fields.otherCharacteristic.label`
            )}
            required
            {...(defaultData?.otherCharacteristic && {
              value: defaultData?.otherCharacteristic,
            })}
            options={getSelectOptionsFromTranslation(
              crsStatus?.includes(LegalEntityCRSStatus.PASSIVE_NFE)
                ? `${translationPrefix}.fields.crs.fields.otherCharacteristic.passiveNFEValues`
                : `${translationPrefix}.fields.crs.fields.otherCharacteristic.financialInstituteValues`,
              t
            )}
            errorMessage={
              formMethods.errors.taxInformation?.crs?.otherCharacteristic
                ?.message
            }
            helperText={t(
              `${translationPrefix}.fields.crs.fields.otherCharacteristic.tooltip`
            )}
          />
        )}
        {showStatusCertificate && (
          <UploadField
            name="taxInformation.crs.statusCertificate"
            onUpload={(documentslist, file) => {
              handleDocumentUpload(documentslist, file, formMethods.setValue);
            }}
            onDelete={(document, documentslist) => {
              handleDeleteDocument(
                document,
                documentslist,
                "taxInformation.crs.statusCertificate",
                formMethods.setValue
              );
            }}
            documentsList={currentDocumentsList}
            label={t(
              `${translationPrefix}.fields.crs.fields.statusCertificate.label`
            )}
            helperText={t(
              `${translationPrefix}.fields.crs.fields.statusCertificate.tooltip`
            )}
            inputErrorMessage={undefined}
            barErrorMessage={t("form.fileUpload.failed")}
            barSuccessMessage={t("form.fileUpload.succeeded")}
            formMethods={formMethods}
            disabled={currentDocumentsList?.length === 1}
          />
        )}
      </Grid>
    </>
  );
}
