import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Icon, IconCommonProps } from "@finvia/money-ui";
import * as S from "./ProcessSteps.styled";
interface Step {
  icon: IconCommonProps["name"];
  name: string;
}
interface ProcessStepsProps {
  steps?: Step[];
}

const translationPrefix = "q-ai.funds.external.processSteps";

export const defaultSteps: Step[] = [
  {
    icon: "checklist",
    name: "request",
  },
  {
    icon: "email",
    name: "emailContact",
  },
  {
    icon: "sign",
    name: "sign",
  },
  {
    icon: "send",
    name: "sendDocs",
  },
  {
    icon: "check",
    name: "checks",
  },
];

const ProcessSteps: React.FC<ProcessStepsProps> = ({
  steps = defaultSteps,
}) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      {steps.map((step, index) => {
        const firstStep = index === 0;
        const lastStep = index === steps.length - 1;

        return (
          <S.ItemContainer key={`${step.name}-${step.icon}`}>
            <S.IconBullet id="bullet" firstStep={firstStep} lastStep={lastStep}>
              <S.Line />
              <S.Bullet />
            </S.IconBullet>
            <Icon name={step.icon} size="giga" />
            <S.TextWrapper>
              <Text align="left">{t(`${translationPrefix}.${step.name}`)}</Text>
            </S.TextWrapper>
          </S.ItemContainer>
        );
      })}
    </S.Container>
  );
};

export default ProcessSteps;
