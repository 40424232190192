import Moment from "moment";
import Numeral from "numeral";

export const LANGUAGE_KEY = "de-DE";

/* eslint-disable func-names */
export default function deDE(
  moment: typeof Moment,
  numeral: typeof Numeral
): void {
  numeral.register("locale", LANGUAGE_KEY, {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    abbreviations: {
      thousand: "k",
      million: "Mio.",
      billion: "Mrd.",
      trillion: "t",
    },
    ordinal: function (_number) {
      return ".";
    },
    currency: {
      symbol: "€",
    },
  });

  function processRelativeTime(
    number: number,
    withoutSuffix: boolean,
    key:
      | "s"
      | "ss"
      | "m"
      | "mm"
      | "h"
      | "hh"
      | "d"
      | "dd"
      | "w"
      | "ww"
      | "M"
      | "MM"
      | "y"
      | "yy",
    _isFuture: boolean
  ) {
    const format = {
      s: ["eine Sekunde", "einer Sekunde"],
      ss: [`${number} Sekunde`, `${number} Sekunden`],
      m: ["eine Minute", "einer Minute"],
      mm: [`${number} Minute`, `${number} Minuten`],
      h: ["eine Stunde", "einer Stunde"],
      hh: [`${number} Stunde`, `${number} Stunden`],
      d: ["ein Tag", "einem Tag"],
      dd: [`${number} Tage`, `${number} Tagen`],
      w: ["eine Woche", "einer Woche"],
      ww: [`${number} Woche`, `${number} Wochen`],
      M: ["ein Monat", "einem Monat"],
      MM: [`${number} Monate`, `${number} Monaten`],
      y: ["ein Jahr", "einem Jahr"],
      yy: [`${number} Jahre`, `${number} Jahren`],
    };
    return withoutSuffix ? format[key][0] : format[key][1];
  }

  moment.locale(LANGUAGE_KEY, {
    months:
      "Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember".split(
        "_"
      ),
    monthsShort:
      "Jan._Feb._März_Apr._Mai_Juni_Juli_Aug._Sep._Okt._Nov._Dez.".split("_"),
    monthsParseExact: true,
    weekdays:
      "Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag".split("_"),
    weekdaysShort: "So._Mo._Di._Mi._Do._Fr._Sa.".split("_"),
    weekdaysMin: "So_Mo_Di_Mi_Do_Fr_Sa".split("_"),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD.MM.YYYY",
      LL: "D. MMMM YYYY",
      LLL: "D. MMMM YYYY HH:mm",
      LLLL: "dddd, D. MMMM YYYY HH:mm",
    },
    calendar: {
      sameDay: "[heute um] LT [Uhr]",
      sameElse: "L",
      nextDay: "[morgen um] LT [Uhr]",
      nextWeek: "dddd [um] LT [Uhr]",
      lastDay: "[gestern um] LT [Uhr]",
      lastWeek: "[letzten] dddd [um] LT [Uhr]",
    },
    relativeTime: {
      future: "in %s",
      past: "vor %s",
      s: processRelativeTime,
      ss: processRelativeTime,
      m: processRelativeTime,
      mm: processRelativeTime,
      h: processRelativeTime,
      hh: processRelativeTime,
      d: processRelativeTime,
      dd: processRelativeTime,
      w: processRelativeTime,
      ww: processRelativeTime,
      M: processRelativeTime,
      MM: processRelativeTime,
      y: processRelativeTime,
      yy: processRelativeTime,
    },
    dayOfMonthOrdinalParse: /\d{1,2}\./,
    ordinal: () => "%d.",
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
  });
}
