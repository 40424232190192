/* eslint-disable filenames/match-exported */
import { Grid, Stack, SelectInput, Heading } from "@finvia/money-ui";
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import {
  DocumentCategory,
  FACTAStatus,
  PEPStatus,
} from "../../../../../../generated/globalTypes";
import {
  Document,
  handleDeleteDocumentGeneric,
  handleDocumentUploadGeneric,
  UploadField,
} from "../../../../components/UploadField";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateEmploymentAndPersonalIrsDocumentData } from "../../actions";
import { updateLegalEntityProfile } from "../actions";
import { EmploymentLegalEntity, validationSchema } from "./Employment.schema";
import PEPFields from "./components/PEPFields";
import ResidencyFields from "./components/ResidencyFields";
import {
  FACTAStatusOptions,
  LegalEntityFunctionaryRoleOptions,
  PEPStatusOptions,
} from "./Employment.utils";
import { countries } from "utils/options/countries";
import { FormInput } from "utils/formInputTypes";
import {
  HandleDocumentDeleteType,
  HandleDocumentUploadType,
} from "./Employment.types";
import { ActionDocChangePayload } from "types/types";

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData";

const Employment: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="user"
          title={t("sidebar.legalEntityContactPerson.title")}
          description={t("sidebar.legalEntityContactPerson.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);

  const defaultData = typedUseSelector(
    (store): FormInput<EmploymentLegalEntity> => {
      const { employment, profile } =
        store.alternativeInvestments.regulatoryData?.legalEntityData || {};

      return formDefaultsDeep<EmploymentLegalEntity>(
        {
          employment,
          taxInfo: {
            facta: {
              status: profile?.taxInfo?.facta?.status,
              irsDocuments: profile?.taxInfo?.facta?.irsDocuments,
            },
            residencies: profile?.taxInfo?.residencies?.length
              ? profile?.taxInfo?.residencies
              : undefined,
          },
        },
        {
          employment: {
            role: undefined,
            country: "DE",
            pep: {
              status: undefined,
              role: undefined,
              relationship: undefined,
            },
          },
          taxInfo: {
            facta: {
              status: undefined,
              irsDocuments: [],
            },
            residencies: [{ country: undefined, taxId: "" }],
          },
        }
      );
    }
  );

  const [currentDocumentsList, setCurrentDocumentsList] = useState<Document[]>(
    defaultData?.taxInfo?.facta?.irsDocuments ?? []
  );

  const handleDocumentUpload: HandleDocumentUploadType = async (
    documents,
    file,
    setValueReference
  ) => {
    const uploadedDocuments =
      await handleDocumentUploadGeneric<ActionDocChangePayload>({
        documents,
        file,
        setValueReference,
        dispatch,
        updateAction: updateEmploymentAndPersonalIrsDocumentData,
        category: DocumentCategory.IRS,
        setError,
        name: "taxInfo.facta.irsDocuments",
        formName: "EmploymentForm",
      });
    if (uploadedDocuments && Array.isArray(uploadedDocuments)) {
      setCurrentDocumentsList([...currentDocumentsList, ...uploadedDocuments]);
    }
  };

  const handleDeleteDocument: HandleDocumentDeleteType = async (
    document,
    documents,
    name,
    setValueReference
  ) => {
    const filteredDocuments =
      await handleDeleteDocumentGeneric<ActionDocChangePayload>({
        document,
        documents,
        setValueReference,
        dispatch,
        updateAction: updateEmploymentAndPersonalIrsDocumentData,
        setError,
        name,
        formName: "EmploymentForm",
      });

    if (filteredDocuments) {
      setCurrentDocumentsList([...filteredDocuments]);
    }
  };

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (legalEntityData: EmploymentLegalEntity) => {
      dispatch(updateLegalEntityProfile(legalEntityData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<EmploymentLegalEntity>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.6_le_employment"
    >
      {(formMethods) => {
        const { register, errors, formState } = formMethods;
        const pepStatus = formMethods.watch(
          "employment.pep.status"
        ) as PEPStatus;

        const facta = formMethods.watch("taxInfo.facta.status") as FACTAStatus;
        const needsIRSDocs =
          facta === FACTAStatus.YES ||
          facta === FACTAStatus.NO_BUT_US_CITIZENSHIP;

        return (
          <FormContent
            title={t(`${translationPrefix}.titleLegalEntity`)}
            isDirty={formState.isDirty}
            isSubmitSuccessful={formState.isSubmitSuccessful}
            error={error}
          >
            <Stack gutter={"giga"}>
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.employment.headline`)}
              </Heading>

              <Grid
                columns={{ sm: 1, lg: 2 }}
                columnsGap={"exa"}
                rowsGap={"giga"}
              >
                <SelectInput
                  required
                  ref={register}
                  label={t(`${translationPrefix}.fields.employment.role.label`)}
                  value={defaultData.employment?.role}
                  name="employment.role"
                  options={LegalEntityFunctionaryRoleOptions(t)}
                  errorMessage={errors.employment?.role?.message}
                />

                <SelectInput
                  required
                  ref={register}
                  label={t(
                    `${translationPrefix}.fields.employment.country.label`
                  )}
                  value={defaultData.employment?.country}
                  name="employment.country"
                  options={countries(t)}
                  errorMessage={errors.employment?.country?.message}
                />

                <SelectInput
                  required
                  ref={register}
                  label={t(
                    `${translationPrefix}.fields.employment.pep.status.label`
                  )}
                  value={defaultData.employment?.pep?.status}
                  name="employment.pep.status"
                  options={PEPStatusOptions(t)}
                  errorMessage={errors.employment?.pep?.status?.message}
                />

                <span />

                <PEPFields
                  pep={pepStatus}
                  formMethods={formMethods}
                  schema={memoizedSchema}
                  translationPrefix={translationPrefix}
                  defaultData={defaultData}
                />
              </Grid>
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.tax.headline`)}
              </Heading>
              <ResidencyFields />
              <Grid
                columns={{ sm: 1, lg: 2 }}
                columnsGap={"exa"}
                rowsGap={"giga"}
              >
                <SelectInput
                  required
                  ref={register}
                  label={t(
                    `${translationPrefix}.fields.taxInfo.facta.status.label`
                  )}
                  value={defaultData.taxInfo?.facta?.status}
                  name="taxInfo.facta.status"
                  options={FACTAStatusOptions(t)}
                  helperText={t(
                    `${translationPrefix}.fields.taxInfo.facta.status.tooltip`
                  )}
                  errorMessage={errors.taxInfo?.facta?.status?.message}
                />
                {needsIRSDocs && (
                  <div>
                    <UploadField
                      name="taxInfo.facta.irsDocuments"
                      formMethods={formMethods}
                      label={t(
                        `${translationPrefix}.fields.taxInfo.facta.irsDocuments.label`
                      )}
                      helperText={t(
                        `${translationPrefix}.fields.taxInfo.facta.irsDocuments.tooltip`
                      )}
                      documentsList={currentDocumentsList}
                      inputErrorMessage={
                        errors.taxInfo?.facta?.irsDocuments?.message
                      }
                      barSuccessMessage={t("form.fileUpload.succeeded")}
                      barErrorMessage={
                        errors.taxInfo?.facta?.irsDocuments?.message
                      }
                      onDelete={(document, documentslist) => {
                        handleDeleteDocument(
                          document,
                          documentslist,
                          "taxInfo.facta.irsDocuments",
                          formMethods.setValue
                        );
                      }}
                      onUpload={(documentslist, file) => {
                        handleDocumentUpload(
                          documentslist,
                          file,
                          formMethods.setValue,
                          formMethods.trigger
                        );
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default Employment;
