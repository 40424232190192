import { TFunction } from "i18next";
import * as yup from "yup";
import {
  MoneyRangeTransactions,
  RevenueInput,
} from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.financialStatus.revenue";

export const revenueCheckboxNames: Array<keyof RevenueInput> = [
  "commercialActivities",
  "rent",
  "fixedAssets",
  "others",
];

const revenueCheckboxValidationSchema = (t: TFunction, checkboxName: string) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeTransactions>()
      .oneOf(
        Object.values(MoneyRangeTransactions),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup
          .mixed<MoneyRangeTransactions>()
          .required(t(`${checkboxName}.range.required`)),
      })
      .label(t(`${checkboxName}.range.label`)),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeTransactions) =>
          money === MoneyRangeTransactions.MORE_THAN_50000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(`${checkboxName}.value.minimum`),
            (value) => (value ?? 0) > 50000
          )
          .required(t(`${checkboxName}.value.required`)),
      })
      .label(t(`${checkboxName}.value.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const revenueValidationSchema = (t: TFunction) => {
  return yup
    .object({
      financialStatus: yup.object({
        revenue: yup.object({
          commercialActivities: revenueCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.commercialActivities`
          ),
          rent: revenueCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.rent`
          ),
          fixedAssets: revenueCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.fixedAssets`
          ),
          others: revenueCheckboxValidationSchema(
            t,
            `${translationPrefix}.fields.others`
          ),
        }),
      }),
    })
    .test(
      "revenueCheckboxGroup",
      "",
      ({ financialStatus: { revenue } }, testContext) => {
        if (
          revenue.commercialActivities.range ||
          revenue.rent.range ||
          revenue.fixedAssets.range ||
          revenue.others.range
        ) {
          return true;
        }

        return testContext.createError({
          message: t(`${translationPrefix}.revenueCheckboxGroup`),
          path: `${testContext.path}.revenueCheckboxGroup`,
        });
      }
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  revenueValidationSchema(t).required();

export type Revenue = yup.InferType<ReturnType<typeof validationSchema>>;

export function validate(revenue: Revenue): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(revenue);
}
