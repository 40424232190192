import { Stack, Heading, Text, Box } from "@finvia/money-ui";
import { TitleCardProps } from "./TitleCard.types";

const TitleCard: React.FC<TitleCardProps> = ({ title, subTitle }) => (
  <Box width="100%" display="flex" justifyContent="center">
    <Box maxWidth={730}>
      <Stack gutter="mega" alignItems="center">
        <Heading
          size={{ sm: 6, md: 7 }}
          textTransform="uppercase"
          data-testid="title"
          as="h1"
          align="center"
        >
          {title}
        </Heading>
        {subTitle && <Text align="center">{subTitle}</Text>}
      </Stack>
    </Box>
  </Box>
);

export default TitleCard;
