/* eslint-disable import/prefer-default-export */
import {
  GetLegalEntityIdentificationStatus,
  GetLegalEntityIdentificationStatus_legalEntityIdentificationStatus,
} from "../../../../../generated/GetLegalEntityIdentificationStatus";
import {
  StartIdentificationForLegalEntity,
  StartIdentificationForLegalEntity_startIdentificationForLegalEntity_identificationStatus,
} from "../../../../../generated/StartIdentificationForLegalEntity";
import { START_LEGAL_IDENTIFICATION_PROCESS } from "../../../../../graphql/mutations/Identification";
import { GET_LEGAL_ENTITY_IDENTIFICATION_STATUS } from "../../../../../graphql/queries/Identification";
import { typedCreateAsyncThunk } from "../../../../../store/typedCreateAsyncThunk";

export const startLegalEntityIdentificationProcess = typedCreateAsyncThunk(
  "mandateContract/startLegalEntityIdentificationProcess",
  async (
    _,
    { extra }
  ): Promise<
    | StartIdentificationForLegalEntity_startIdentificationForLegalEntity_identificationStatus
    | undefined
  > => {
    const result = await extra.client.mutate<StartIdentificationForLegalEntity>(
      {
        mutation: START_LEGAL_IDENTIFICATION_PROCESS,
      }
    );

    return result.data?.startIdentificationForLegalEntity?.identificationStatus;
  }
);

export const getLegalEntityIdentificationStatus = typedCreateAsyncThunk(
  "mandateContract/getLegalEntityIdentificationStatus",
  async (
    _,
    { extra }
  ): Promise<
    | GetLegalEntityIdentificationStatus_legalEntityIdentificationStatus
    | undefined
  > => {
    const result = await extra.client.query<GetLegalEntityIdentificationStatus>(
      {
        query: GET_LEGAL_ENTITY_IDENTIFICATION_STATUS,
      }
    );

    return result.data.legalEntityIdentificationStatus;
  }
);
