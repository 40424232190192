import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import {
  TransparencyRegisterObligationOptionEnum,
  updateLegalEntityProfile,
} from "../actions";
import {
  BusinessActivity,
  validationSchema,
} from "./BusinessActivityValidations";
import {
  ChoiceGroup,
  Grid,
  Heading,
  SelectInput,
  TextAreaInput,
  TextInput,
} from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import * as S from "./BusinessActivityForm.styled";
import { radioQuestions } from "./utils";

const translationPrefix = "q-ai.opportunities.form.businessActivity";

const BusinessActivityForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const onSubmit = useCallback(
    async (businessActivityData: BusinessActivity) => {
      dispatch(updateLegalEntityProfile(businessActivityData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const defaultData = typedUseSelector((store) => {
    const { businessActivity } =
      store.alternativeInvestments.regulatoryData?.legalEntityData || {};

    return formDefaultsDeep<BusinessActivity>(
      {
        businessActivity,
      },
      {
        businessActivity: {
          registrationNumber: "",
          authorityOffice: "",
          dateOfFoundation: "",
          cashIntensive: "",
          specialTransactions: "",
          dutyDisclosure: "",
          governmentVerified: "",
          publicCompany: "",
          privateAssetManagement: "",
          publiclyTraceable: "",
          transparencyRegisterObligation: "",
          transparencyRegisterObligationOption: "",
        },
      }
    );
  });

  return (
    <FormControl<BusinessActivity>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      reValidationMode="onChange"
      dataAnalytics="2.10_le_business_activity"
    >
      {(formMethods) => {
        const { watch, register, errors } = formMethods;
        const isObligedForTransparencyRegister = watch(
          "businessActivity.transparencyRegisterObligation"
        );

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid columnsGap="yotta" rowsGap="giga" columns={{ sm: 1, md: 2 }}>
              <TextInput crossOrigin
                required
                name={`businessActivity.registrationNumber`}
                label={t(
                  `${translationPrefix}.fields.registrationNumber.label`
                )}
                defaultValue={
                  defaultData?.businessActivity?.registrationNumber || ""
                }
                ref={register}
                errorMessage={
                  errors?.businessActivity?.registrationNumber?.message ??
                  undefined
                }
                helperText={t(
                  `${translationPrefix}.fields.registrationNumber.tooltip`
                )}
              />
              <SelectInput
                name={`businessActivity.authorityOffice`}
                label={t(`${translationPrefix}.fields.authorityOffice.label`)}
                required
                options={getSelectOptionsFromTranslation(
                  `${translationPrefix}.fields.authorityOffice.values`,
                  t
                )}
                value={
                  defaultData.businessActivity?.authorityOffice ?? undefined
                }
                ref={formMethods.register}
                searchable
                helperText={t(
                  `${translationPrefix}.fields.authorityOffice.tooltip`
                )}
              />
              <TextInput crossOrigin
                required
                name={`businessActivity.dateOfFoundation`}
                label={t(`${translationPrefix}.fields.dateOfFoundation.label`)}
                defaultValue={
                  defaultData?.businessActivity?.dateOfFoundation || ""
                }
                ref={register}
                errorMessage={
                  errors?.businessActivity?.dateOfFoundation?.message ??
                  undefined
                }
                helperText={t(
                  `${translationPrefix}.fields.dateOfFoundation.tooltip`
                )}
              />
            </Grid>
            <Grid gap="giga" columns={1}>
              <TextAreaInput
                crossOrigin
                required
                name={`businessActivity.businessPurpose`}
                label={t(`${translationPrefix}.fields.businessPurpose.label`)}
                defaultValue={
                  defaultData?.businessActivity?.businessPurpose || ""
                }
                ref={register}
                errorMessage={
                  errors?.businessActivity?.businessPurpose?.message ??
                  undefined
                }
                helperText={t(
                  `${translationPrefix}.fields.businessPurpose.tooltip`
                )}
              />
            </Grid>
            <Grid gap="giga" columns={1}>
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.activityInformation.headline`)}
              </Heading>
            </Grid>
            <Grid rowsGap="giga" columns={1}>
              {radioQuestions.map((name) => (
                <ChoiceGroup
                  key={name}
                  required
                  name={`businessActivity.${name}`}
                  label={t(`${translationPrefix}.fields.${name}.label`)}
                  errorMessage={
                    errors &&
                    (errors as any)[`businessActivity.${name}`]?.message
                  }
                >
                  <Grid columns={{ sm: 3, lg: 8 }} gap="mega">
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name={`businessActivity.${name}`}
                      value="yes"
                      label={t("form.yes")}
                    />
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name={`businessActivity.${name}`}
                      value="no"
                      label={t("form.no")}
                    />
                  </Grid>
                </ChoiceGroup>
              ))}

              {isObligedForTransparencyRegister === "yes" && (
                <S.DependantRadiowrapper>
                  <ChoiceGroup
                    required
                    name="businessActivity.transparencyRegisterObligationOption"
                    label={t(
                      `${translationPrefix}.fields.transparencyRegisterObligation.label`
                    )}
                    hideLabel
                    errorMessage={
                      errors &&
                      (errors as any)[
                        "businessActivity.transparencyRegisterObligationOption"
                      ]?.message
                    }
                  >
                    <Grid gap="mega">
                      <ChoiceGroup.Radio
                        ref={formMethods.register}
                        name="businessActivity.transparencyRegisterObligationOption"
                        value={
                          TransparencyRegisterObligationOptionEnum.EXTRACT_ATTACHMENT
                        }
                        label={t(
                          `${translationPrefix}.fields.transparencyRegisterObligation.conditionalOptions.extractAttachment`
                        )}
                      />
                      <ChoiceGroup.Radio
                        ref={formMethods.register}
                        name="businessActivity.transparencyRegisterObligationOption"
                        value={
                          TransparencyRegisterObligationOptionEnum.NOT_OBLIGED
                        }
                        label={t(
                          `${translationPrefix}.fields.transparencyRegisterObligation.conditionalOptions.notObliged`
                        )}
                      />
                    </Grid>
                  </ChoiceGroup>
                </S.DependantRadiowrapper>
              )}
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default BusinessActivityForm;
