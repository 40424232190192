import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import FullRow from "../../../../../../components/InputGrid/FullRow";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import removeLeadingZeroFromDEPhoneNumber from "../../../../../../utils/removeLeadingZeroFromDEPhoneNumber";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import {
  ContactDataForm as ContactDataFormType,
  validationSchema,
} from "./contactData.schema";
import {
  Heading,
  Checkbox,
  Grid,
  TextInput,
  Telephone,
  countryCodes,
  SelectInput,
} from "@finvia/money-ui";
import { countries } from "utils/options/countries";

const translationPrefix = "q-ai.opportunities.form.contactData";

const ContactDataForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
  isLegalEntity,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const defaultData = typedUseSelector((state) => {
    const { personData } = state.alternativeInvestments.regulatoryData;

    return formDefaultsDeep<ContactDataFormType>(
      {
        contactData: {
          ...personData?.contactData,
          emailAddress: personData?.contactData?.emailAddress,
          phoneNumber: personData?.contactData?.phoneNumber || "+49",
        },
        contactAddress: {
          country: "DE",
          ...personData?.contactAddress,
        },
        legalAddress: {
          country: "DE",
          ...personData?.legalAddress,
        },
        hasSeparateContactAddress:
          personData?.contactAddress === undefined ||
          personData?.contactAddress?.streetName === ""
            ? false
            : true,
      },
      {}
    );
  });

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (contactData: ContactDataFormType) => {
      const emptyContactAddress = {
        streetName: "",
        streetNumber: "",
        addition: "",
        postalCode: "",
        city: "",
        country: "DE",
      };

      const cleanedUpData = {
        ...contactData,
        contactData: {
          emailAddress: contactData.contactData?.emailAddress,
          phoneNumber: removeLeadingZeroFromDEPhoneNumber(
            contactData.contactData?.phoneNumber
          ),
        },
        contactAddress:
          contactData.hasSeparateContactAddress === true
            ? contactData.contactAddress
            : emptyContactAddress,
      };

      dispatch(
        updateProfile({
          ...cleanedUpData,
        })
      )
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<ContactDataFormType>
      dontShowErrors
      defaultValues={defaultData}
      onBack={previousStep}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics={`2.${isLegalEntity ? "4_le" : "3_np"}_contact_data`}
    >
      {(formMethods) => {
        const {
          register,
          watch,
          formState: { errors, isDirty, isSubmitSuccessful },
        } = formMethods;

        let watchDifferentAddress = watch("hasSeparateContactAddress", false);
        // TODO: For some reason on page reload this boolean value is parsed as "" when it should be true
        if (
          typeof watchDifferentAddress === "string" &&
          watchDifferentAddress === ""
        ) {
          watchDifferentAddress = true;
        }

        formMethods.trigger("hasSeparateContactAddress");

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={isDirty}
            isSubmitSuccessful={isSubmitSuccessful}
          >
            <Grid
              columns={{ sm: 1, md: 2 }}
              justifyItems="stretch"
              gap="giga"
              columnsGap="yotta"
            >
              <FullRow>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.contactData.headline`)}
                </Heading>
              </FullRow>

              <Telephone
                options={countryCodes}
                ref={register}
                name="contactData.phoneNumber"
                required
                label={t(`${translationPrefix}.fields.phoneNumber.label`)}
                errorMessage={errors.contactData?.phoneNumber?.message}
                value={defaultData.contactData?.phoneNumber}
              />

              <TextInput crossOrigin
                ref={register}
                name="contactData.emailAddress"
                required
                label={t(`${translationPrefix}.fields.emailAddress.label`)}
                readOnly
                errorMessage={errors.contactData?.emailAddress?.message}
              />

              <FullRow>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.legalAddress.headline`)}
                </Heading>
              </FullRow>

              <TextInput crossOrigin
                ref={register}
                name="legalAddress.streetName"
                required
                label={t(`${translationPrefix}.fields.street.label`)}
                errorMessage={errors.legalAddress?.streetName?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="legalAddress.streetNumber"
                required
                label={t(`${translationPrefix}.fields.houseNumber.label`)}
                errorMessage={errors.legalAddress?.streetNumber?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="legalAddress.addition"
                label={t(`${translationPrefix}.fields.additionalAddress.label`)}
                errorMessage={errors.legalAddress?.addition?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="legalAddress.postalCode"
                required
                label={t(`${translationPrefix}.fields.postalCode.label`)}
                errorMessage={errors.legalAddress?.postalCode?.message}
              />
              <TextInput crossOrigin
                ref={register}
                name="legalAddress.city"
                required
                label={t(`${translationPrefix}.fields.city.label`)}
                errorMessage={errors.legalAddress?.city?.message}
              />
              <SelectInput
                ref={register}
                name="legalAddress.country"
                label={t(`${translationPrefix}.fields.country.label`)}
                required
                value={defaultData.legalAddress?.country}
                options={countries(t)}
                errorMessage={errors.legalAddress?.country?.message}
                searchable
              />

              {!isLegalEntity && (
                <FullRow>
                  <Checkbox
                    label={t(
                      `${translationPrefix}.fields.hasSeparateContactAddress.label`
                    )}
                    name="hasSeparateContactAddress"
                    id="hasSeparateContactAddress"
                    ref={formMethods.register}
                  />
                </FullRow>
              )}

              {watchDifferentAddress === true && (
                <>
                  <FullRow>
                    <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                      {t(`${translationPrefix}.contactAddress.headline`)}
                    </Heading>
                  </FullRow>
                  <TextInput crossOrigin
                    ref={register}
                    name="contactAddress.streetName"
                    required
                    label={t(`${translationPrefix}.fields.street.label`)}
                    errorMessage={errors.contactAddress?.streetName?.message}
                  />
                  <TextInput crossOrigin
                    ref={register}
                    name="contactAddress.streetNumber"
                    required
                    label={t(`${translationPrefix}.fields.houseNumber.label`)}
                    errorMessage={errors.contactAddress?.streetNumber?.message}
                  />
                  <TextInput crossOrigin
                    ref={register}
                    name="contactAddress.addition"
                    label={t(
                      `${translationPrefix}.fields.additionalAddress.label`
                    )}
                    errorMessage={errors.contactAddress?.addition?.message}
                  />
                  <TextInput crossOrigin
                    ref={register}
                    name="contactAddress.postalCode"
                    required
                    label={t(`${translationPrefix}.fields.postalCode.label`)}
                    errorMessage={errors.contactAddress?.postalCode?.message}
                  />
                  <TextInput crossOrigin
                    ref={register}
                    name="contactAddress.city"
                    required
                    label={t(`${translationPrefix}.fields.city.label`)}
                    errorMessage={errors.contactAddress?.city?.message}
                  />
                  <SelectInput
                    ref={register}
                    name="contactAddress.country"
                    label={t(`${translationPrefix}.fields.country.label`)}
                    required
                    value={defaultData.contactAddress?.country}
                    options={countries(t)}
                    errorMessage={errors.contactAddress?.country?.message}
                    searchable
                  />
                </>
              )}
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default ContactDataForm;
