import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1660px;
    overflow: hidden;

    ${theme.utils.viewport.sm` 
      height: 1400px;
    `}
  `};
`;

export const Iframe = styled.iframe`
  height: 1880px;
  border: 0;
`;
