import { Grid } from "@material-ui/core";
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import FullRow from "../../../../../../components/InputGrid/FullRow";
import InputGrid from "../../../../../../components/InputGrid/InputGrid";
import { FormContent } from "../../../../../../components/FormContent";
import { MoneyRangeSmall } from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import { incomeCheckboxNames, validationSchema } from "./IncomeForm.schema";
import {
  Checkbox,
  Heading,
  SelectInput,
  Stack,
  Text,
  Tooltip,
} from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "../../../../../../utils/options/getSelectOptionsFromTranslation";
import { IncomeFormType } from "./IncomeForm.types";
import * as S from "./IncomeForm.styled";
import { MonetaryInput } from "../../MonetaryInput";

const translationPrefix = "q-ai.opportunities.form.income";

const IncomeForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} lg={12}>
          <SidebarContentBlock
            iconType="info"
            iconSize="byte"
            iconColor="petrol.600"
            title={t("sidebar.sensitiveDataExplanation.title")}
            description={t("sidebar.sensitiveDataExplanation.description")}
          />
        </Grid>
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) => {
    const income =
      state.alternativeInvestments?.regulatoryData?.personData?.regulatoryInfo
        ?.income;

    return formDefaultsDeep<IncomeFormType>(
      {
        income: income,
      },
      {
        income: {
          employment: {
            checkbox: !!income?.employment?.range,
            range: undefined,
            value: "",
          },
          selfEmployment: {
            checkbox: !!income?.selfEmployment?.range,
            range: undefined,
            value: "",
          },
          renting: {
            checkbox: !!income?.renting?.range,
            range: undefined,
            value: "",
          },
          capitalGains: {
            checkbox: !!income?.capitalGains?.range,
            range: undefined,
            value: "",
          },
          other: {
            checkbox: !!income?.other?.range,
            range: undefined,
            value: "",
          },
        },
      }
    );
  });

  const cleanUpDataFromUnselectedCheckboxes = (data: IncomeFormType) => {
    if (!data.income) return;

    const incomeKeys = Object.keys(data.income) as (keyof typeof data.income)[];

    return incomeKeys.reduce((cleanedUpData, currentFieldName) => {
      const isChecked = data?.income?.[currentFieldName]?.checkbox;
      const field = data?.income?.[currentFieldName];

      return {
        ...cleanedUpData,
        [currentFieldName]: {
          checkbox: isChecked,
          range: isChecked ? field?.range : null,
          value: isChecked ? field?.value : null,
        },
      };
    }, {});
  };

  const onSubmit = useCallback(
    async (data: IncomeFormType) => {
      const cleanedUpData = {
        income: cleanUpDataFromUnselectedCheckboxes(data),
      };

      dispatch(updateProfile({ regulatoryInfo: cleanedUpData }))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<IncomeFormType>
      dontShowErrors
      onBack={previousStep}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics="2.6_np_income"
    >
      {(formMethods) => {
        const { watch, errors } = formMethods;

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <InputGrid defaultColumnGap>
              <FullRow>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.subTitle`)}
                </Heading>
              </FullRow>
              <FullRow>
                <Stack direction="row" alignItems="center">
                  <Text>{t(`${translationPrefix}.regularNetIncome`)}</Text>
                  <Tooltip
                    label={t(`${translationPrefix}.regularNetIncomeTooltip`)}
                    id={t(`${translationPrefix}.regularNetIncomeTooltip`)}
                  >
                    <S.TooltipWrapper>i</S.TooltipWrapper>
                  </Tooltip>
                </Stack>
              </FullRow>

              {incomeCheckboxNames.map((checkbox) => {
                const checkboxName = `income.${checkbox}.checkbox`;
                const watchRange = watch(`income.${checkbox}.range`);
                const watchCheckbox = watch(checkboxName);

                return (
                  <FullRow key={checkbox}>
                    <InputGrid gap="15px" defaultColumnGap>
                      <FullRow>
                        <Checkbox
                          name={checkboxName}
                          label={t(
                            `${translationPrefix}.fields.income.${checkbox}.range.label`
                          )}
                          ref={formMethods.register}
                        />
                      </FullRow>

                      {watchCheckbox && (
                        <>
                          <SelectInput
                            name={`income.${checkbox}.range`}
                            label={t(
                              `${translationPrefix}.fields.income.${checkbox}.range.label`
                            )}
                            required
                            options={getSelectOptionsFromTranslation(
                              `${translationPrefix}.fields.income.${checkbox}.range.values`,
                              t
                            )}
                            value={
                              defaultData.income?.[checkbox]?.range ?? undefined
                            }
                            ref={formMethods.register}
                          />

                          {watchRange === MoneyRangeSmall.MORE_THAN_5000 && (
                            <MonetaryInput
                              name={`income.${checkbox}.value`}
                              label={t(
                                `${translationPrefix}.fields.income.${checkbox}.value.label`
                              )}
                              required
                              ref={formMethods.register}
                              errorMessage={
                                errors?.income?.[checkbox]?.value?.message ??
                                undefined
                              }
                            />
                          )}
                        </>
                      )}
                    </InputGrid>
                  </FullRow>
                );
              })}
              {errors.income && (
                <Text variant="error" size="1">
                  {get(errors, "income.incomeCheckboxGroup.message")}
                </Text>
              )}
            </InputGrid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default IncomeForm;
