import formatDate from "date-fns/format";
import parseDate from "date-fns/parse";

/* 
  Packing/Unpacking is useful only when using the useFieldArray with an array of a primitive values (string, number, etc.) 
  the useFieldArray requires the values to iterate to be an object so it can add an id (for Re-ordering, Deletion, etc.)
*/
export function unpackFieldArray<T>(
  array: Array<{ value?: T | null } | undefined> | undefined | null
): T[] {
  if (!array) {
    return [];
  }
  return (array.filter((item) => !!item) as Array<{ value: T }>).map(
    (item) => item.value
  );
}

export function packFieldArray<T>(
  array: T[] | undefined | null
): Array<{ value: T }> {
  if (!array) {
    return [];
  }
  return array.map((item) => ({ value: item }));
}

export function filterOptions(options: string[], filter?: string[]): string[] {
  if (!filter) {
    return options;
  }

  return options.filter((option) => !filter.includes(option));
}

export function mapGraphQlDateToForm(
  dateString: string | null | undefined
): string | undefined {
  if (!dateString) return undefined;

  // prevents values coming from the BE with format dd.MM.yyyy to fail.
  const currentDateMask = dateString.includes(".")
    ? "dd.MM.yyyy"
    : "yyyy-MM-dd";
  const parsedDate = parseDate(dateString, currentDateMask, new Date());

  return formatDate(parsedDate, "dd.MM.yyyy");
}

export function mapFormDateToGraphQl(
  dateString: string | null | undefined
): string | undefined {
  if (!dateString) return undefined;

  const parsedDate = parseDate(dateString, "dd.MM.yyyy", new Date());
  return formatDate(parsedDate, "yyyy-MM-dd");
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function extractFromObjtToArray<T>(filteredKey: string, obj: any): T[] {
  return Object.keys(obj)
    .filter((key: string) => key.includes(filteredKey))
    .map((key: string) => obj[key] !== undefined && obj[key]);
}
