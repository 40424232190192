import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import {
  ExperienceAssetClassesForm,
  experienceAssetsCheckboxNames,
  validationSchema,
} from "./ExperienceAssets.schema";
import { ContractType } from "generated/globalTypes";
import {
  Text,
  Grid,
  Checkbox,
  Stack,
  SelectInput,
  Box,
} from "@finvia/money-ui";
import { SelectFieldsContainer } from "./ExperienceAssets.styled";
import {
  AverageInvestmentVolumePerYearOptions,
  AverageTransactionsPerYearOptions,
  cleanFormData,
  ExperienceInYearsOptions,
  getMappedAssetClasses,
} from "./ExperienceAssets.utils";

const translationPrefix = "q-ai.opportunities.form.experienceAssets";

const ExperienceAssets: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="user"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityContactPerson.title")}
          description={t("sidebar.legalEntityContactPerson.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const [defaultData, isLegalEntity] = typedUseSelector((state) => {
    return [
      formDefaultsDeep<ExperienceAssetClassesForm>(
        {
          assetClassesExperience:
            state.alternativeInvestments.regulatoryData?.personData
              ?.regulatoryInfo?.assetClassesExperience,
        },
        {
          assetClassesExperience: getMappedAssetClasses(null),
        }
      ),
      state.alternativeInvestments.regulatoryData.personData.contractType ===
        ContractType.LEGAL_ENTITY,
    ];
  });

  const onSubmit = useCallback(
    async (data: ExperienceAssetClassesForm) => {
      dispatch(
        updateProfile({
          regulatoryInfo: cleanFormData(data),
        })
      )
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<ExperienceAssetClassesForm>
      dontShowErrors
      onBack={previousStep}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics={`2.${
        isLegalEntity ? "7_le" : "8_np"
      }_experience_asset_classes`}
    >
      {(formMethods) => {
        const { watch, errors, register } = formMethods;
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Stack gutter={"giga"}>
              <Box>
                <Text size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.subTitle`)}
                </Text>
              </Box>
              {experienceAssetsCheckboxNames.map((checkbox) => {
                const checkboxName = `assetClassesExperience.${checkbox}.checkbox`;
                const watchCheckbox = watch(
                  checkboxName,
                  defaultData?.assetClassesExperience?.[checkbox]?.checkbox
                );

                return (
                  <Stack key={checkboxName}>
                    <Checkbox
                      data-test-id={checkboxName}
                      ref={register}
                      name={checkboxName}
                      label={t(
                        `${translationPrefix}.fields.experience.assetClasses.${checkbox}.label`
                      )}
                      value={checkbox}
                      helperText={t(
                        `${translationPrefix}.fields.experience.assetClasses.${checkbox}.tooltip`
                      )}
                      helperTextLocation="bottom-end"
                    />
                    {watchCheckbox && (
                      <SelectFieldsContainer
                        columns={{ sm: 1, md: "1fr 2fr 2fr" }}
                        columnsGap="exa"
                        rowsGap="mega"
                      >
                        <SelectInput
                          required
                          ref={register}
                          label={t(
                            `${translationPrefix}.fields.experience.assetClasses.${checkbox}.experienceInYears.label`
                          )}
                          value={
                            defaultData.assetClassesExperience?.[checkbox]
                              ?.experienceInYears || ""
                          }
                          name={`assetClassesExperience.${checkbox}.experienceInYears`}
                          options={ExperienceInYearsOptions(t)}
                        />

                        <SelectInput
                          required
                          ref={register}
                          value={
                            defaultData.assetClassesExperience?.[checkbox]
                              ?.averageNumberOfTransactionsPerYear || ""
                          }
                          label={t(
                            `${translationPrefix}.fields.experience.assetClasses.${checkbox}.averageNumberOfTransactionsPerYear.label`
                          )}
                          name={`assetClassesExperience.${checkbox}.averageNumberOfTransactionsPerYear`}
                          options={AverageTransactionsPerYearOptions(t)}
                        />

                        <SelectInput
                          required
                          ref={register}
                          value={
                            defaultData.assetClassesExperience?.[checkbox]
                              ?.averageInvestmentVolumePerYear || ""
                          }
                          label={t(
                            `${translationPrefix}.fields.experience.assetClasses.${checkbox}.averageInvestmentVolumePerYear.label`
                          )}
                          name={`assetClassesExperience.${checkbox}.averageInvestmentVolumePerYear`}
                          options={AverageInvestmentVolumePerYearOptions(t)}
                        />
                      </SelectFieldsContainer>
                    )}
                  </Stack>
                );
              })}
              <Text variant="error" size="1">
                {errors.assetClassesExperience &&
                  get(
                    errors,
                    "assetClassesExperience.experienceFormCheckBoxGroup.message"
                  )}
              </Text>
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default ExperienceAssets;
