import { Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const backdrop = ({
  theme: {
    tokens: { zIndex, sizes },
  },
}: {
  theme: Theme;
}) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(9px);
  z-index: ${zIndex[1]};
  align-items: baseline;
  padding-top: ${sizes.spacings.peta};
`;

const modal = ({ theme }: { theme: Theme }) => css`
  ${theme.utils.viewport.md`
    width: 380px;
  `};
`;

const infoContainer = ({ theme }: { theme: Theme }) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.sizes.spacings.tera};

  & a {
    text-decoration: none;
  }
`;

export const Modal = styled.div<{ theme: Theme }>(modal);
export const Backdrop = styled.div<{ theme: Theme }>(backdrop);
export const InfoContainer = styled.div<{ theme: Theme }>(infoContainer);
