import { gql } from "@apollo/client";
import {
  Address,
  AssetClassExperience,
  BankAccount,
} from "../fragments/Fragments";

const RegulatoryData = gql`
  ${Address}
  ${AssetClassExperience}
  ${BankAccount}
  query RegulatoryData {
    profile {
      id
      account {
        email
      }
      contracts {
        aiMandate {
          contractType
        }
      }
      personalData {
        salutation
        professionalTitle
        firstName
        familyName
        birthName
        dateOfBirth
        countryOfBirth
        cityOfBirth
        primaryCitizenship
        additionalCitizenships
        isThirdCountryNational
      }
      contactData {
        phoneNumber
        emailAddress
        contactAddress {
          ...Address
        }
        legalAddress {
          ...Address
        }
      }
      employment {
        status
        country
        sector
        branchOfIndustry
      }
      bankAccounts {
        reference {
          ...BankAccount
        }
        usdCurrency {
          ...BankAccount
        }
      }
      regulatoryInfo {
        pep {
          relationship
          role
          status
        }
        taxInfo {
          facta {
            status
            irsDocuments {
              id
              filename
              mimetype
            }
          }
          residencies {
            country
            taxId
            localTaxOffice
          }
        }
        netWorth {
          total {
            range
            value {
              currency
              value
            }
          }
          cash {
            range
            value {
              currency
              value
            }
          }
          securities {
            range
            value {
              currency
              value
            }
          }
          selfUsedProperties {
            range
            value {
              currency
              value
            }
          }
          realEstate {
            range
            value {
              currency
              value
            }
          }
          movableProperty {
            range
            value {
              currency
              value
            }
          }
          insuranceClaims {
            range
            value {
              currency
              value
            }
          }
          other {
            range
            value {
              currency
              value
            }
          }
          restrictions {
            type
            value {
              currency
              value
            }
          }
        }
        wealthOrigin
        wealthOriginDetail
        liabilities {
          annualLiabilities {
            range
            value {
              currency
              value
            }
          }
          rent {
            range
            value {
              currency
              value
            }
          }
          living {
            range
            value {
              currency
              value
            }
          }
          loan {
            range
            value {
              currency
              value
            }
          }
          alimony {
            range
            value {
              currency
              value
            }
          }
          insurance {
            range
            value {
              currency
              value
            }
          }
        }
        availableMonthlyLiquidity {
          range
          value {
            currency
            value
          }
        }
        savings {
          areMinimumShortTermReservesRequired
          minimumShortTermReserve {
            currency
            value
          }
          date
        }
        income {
          employment {
            range
            value {
              currency
              value
            }
          }
          selfEmployment {
            range
            value {
              currency
              value
            }
          }
          renting {
            range
            value {
              currency
              value
            }
          }
          capitalGains {
            range
            value {
              currency
              value
            }
          }
          other {
            range
            value {
              currency
              value
            }
          }
        }
        experience {
          consultingFreeOrderExecution
          investmentConsulting
          wealthManagement
          currencies {
            foreignCurrencies
            loanBasedForeignCurrencies
          }
          assetClasses {
            privateEquity {
              ...AssetClassExperience
            }
            privateDebt {
              ...AssetClassExperience
            }
            realEstate {
              ...AssetClassExperience
            }
            infrastructure {
              ...AssetClassExperience
            }
            securities {
              ...AssetClassExperience
            }
            bonds {
              ...AssetClassExperience
            }
            gold {
              ...AssetClassExperience
            }
            moneyMarket {
              ...AssetClassExperience
            }
            derivatives {
              ...AssetClassExperience
            }
            other {
              ...AssetClassExperience
            }
            currencyFutures {
              ...AssetClassExperience
            }
            hedgeFunds {
              ...AssetClassExperience
            }
            certificates {
              ...AssetClassExperience
            }
          }
        }
      }
    }
  }
`;

export default RegulatoryData;
