import { unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NaturalPersonIdentificationStatus } from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { TitleCard } from "../../../../../../components/TitleCard";
import { getLegalEntityIdentificationStatus } from "../actions";
import { Button, InfoBox, Stack } from "@finvia/money-ui";
import { IdentificationTable, PersonRecord } from "../IdentificationTable";
import { Form } from "./RepresentativesIdentificationList.styled";

const translationPrefix = "q-ai.mandate.form.identificationList";

const RepresentativesIdentificationList: React.FC<StepComponentProps> = ({
  nextStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<PersonRecord[]>([]);

  const [me, additionalLegalRepresentatives] = typedUseSelector((store) => {
    const {
      isLegalRepresentative,
      isBeneficialOwner,
      legalEntityAdditionalPersons,
    } = store.alternativeInvestments.regulatoryData?.legalEntityData || {};
    const { personalData } =
      store.alternativeInvestments.regulatoryData?.personData;

    return [
      {
        id: "me",
        name: `${personalData?.firstName} ${personalData?.familyName}`,
        isLegalRepresentative: !!isLegalRepresentative,
        isBeneficialOwner: isBeneficialOwner === "yes",
        identificationStatus:
          NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING,
      },
      legalEntityAdditionalPersons,
    ];
  });

  const legalEntityIdentificationStatus = useCallback(
    () =>
      dispatch(getLegalEntityIdentificationStatus())
        .then(unwrapResult)
        .then((identificationStatusData) => {
          const responseMe = identificationStatusData?.legalRepresentative;

          const generateTableData = [
            {
              ...me,
              currentUser: true,
              identificationStatus: responseMe?.status,
            },
            ...[...(additionalLegalRepresentatives || [])].map(
              (legalRepresentative) => {
                const responsePerson =
                  identificationStatusData?.additionalLegalRepresentatives.find(
                    (filteredPerson) =>
                      filteredPerson.id === legalRepresentative.id
                  );

                const isLegalRepresentative =
                  legalRepresentative.isLegalRepresentative === "yes";
                const isBeneficialOwner =
                  legalRepresentative.beneficialOwner?.isBeneficialOwner ===
                  "yes";
                const isIdentificationStatusSuccessful =
                  responseMe?.status ===
                    NaturalPersonIdentificationStatus.IDENTIFICATION_SUCCESSFUL ||
                  responseMe?.status ===
                    NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL;

                const identificationStatus = () => {
                  const additionalRepData =
                    identificationStatusData?.additionalLegalRepresentatives.filter(
                      (user) => user.id === legalRepresentative.id
                    );

                  if (!isLegalRepresentative && isBeneficialOwner) {
                    return "NOT_NECESSARY" as const;
                  } else if (additionalRepData?.length) {
                    return additionalRepData[0].status;
                  }

                  return responseMe?.status;
                };

                return {
                  id: legalRepresentative.id || "",
                  name: `${legalRepresentative.personalData?.firstName} ${legalRepresentative.personalData?.familyName}`,
                  isLegalRepresentative,
                  isBeneficialOwner,
                  identificationURL: responsePerson?.url,
                  isReadyForIdentification: isIdentificationStatusSuccessful,
                  identificationStatus: identificationStatus(),
                };
              }
            ),
          ];

          setTableData(generateTableData);
        })
        .catch((e) => {
          console.log(e);
        }),
    [additionalLegalRepresentatives, dispatch, me]
  );

  useEffect(() => {
    if (tableData.length > 0) return;
    legalEntityIdentificationStatus();
  }, [legalEntityIdentificationStatus, tableData]);

  const [didAllRepresentativesIdentify, didAnyIdentificationFailed] = useMemo<
    [boolean, boolean]
  >(() => {
    const allRepresentativesIdentify = tableData.every(
      (person) =>
        person.identificationStatus ===
          NaturalPersonIdentificationStatus.IDENTIFICATION_SUCCESSFUL ||
        person.identificationStatus ===
          NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL ||
        person.identificationStatus === "NOT_NECESSARY"
    );

    const anyIdentificationFailed = tableData.some(
      (person) =>
        person.identificationStatus ===
        NaturalPersonIdentificationStatus.IDENTIFICATION_FAILED
    );
    return [allRepresentativesIdentify, anyIdentificationFailed];
  }, [tableData]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    nextStep();
  }

  return (
    <Stack gutter="giga">
      <TitleCard
        title={t(`${translationPrefix}.title`)}
        subTitle={t(`${translationPrefix}.subTitle`)}
      />
      <IdentificationTable tableData={tableData} t={t} nextStep={nextStep} />
      {didAnyIdentificationFailed ? (
        <InfoBox icon="error" variant="danger">
          {t(`${translationPrefix}.failed`)}
        </InfoBox>
      ) : (
        <InfoBox icon="info">{t(`${translationPrefix}.info`)}</InfoBox>
      )}
      {didAllRepresentativesIdentify && (
        <Form onSubmit={onSubmit}>
          <Button
            type="submit"
            dataAttributes={{
              "data-analytics": "3.4_le_representatives_identification_list",
            }}
          >
            {t(`form.continue`)}
          </Button>
        </Form>
      )}
    </Stack>
  );
};

export default RepresentativesIdentificationList;
