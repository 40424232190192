import { ContractType } from "generated/globalTypes";
import { TFunction } from "i18next";

const translationDocumentsPrefix = (contractType: ContractType) =>
  `q-ai.opportunities.form.contractType.contractType.${contractType}.necessary_documents.documents`;

export const generateNecessaryDocumentsList = ({
  t,
  contractType,
  documentsNames,
}: {
  t: TFunction;
  contractType: ContractType;
  documentsNames: string[];
}): string[] =>
  documentsNames.map((documentName) =>
    t(`${translationDocumentsPrefix(contractType)}.${documentName}`)
  );
