import { DocumentCategory, LegalForm } from "../generated/globalTypes";

export const shouldRenderCurrentTradeRegisterUpload = (
  registrationType?: LegalForm
): boolean => {
  return (
    registrationType === LegalForm.GMBH ||
    registrationType === LegalForm.UG ||
    registrationType === LegalForm.AG ||
    registrationType === LegalForm.EG ||
    registrationType === LegalForm.EK ||
    registrationType === LegalForm.KG ||
    registrationType === LegalForm.OHG ||
    registrationType === LegalForm.PART_G
  );
};

export const shouldRenderShareholdersListNonAG = (
  registrationType?: LegalForm
): boolean => {
  return (
    registrationType === LegalForm.GMBH || registrationType === LegalForm.UG
  );
};

export const shouldRenderShareholdersAGUpload = (
  registrationType?: LegalForm
): boolean => {
  return registrationType === LegalForm.AG;
};

export const shouldRenderFurtherShareholdersUpload = (
  registrationType?: LegalForm
): boolean => {
  return (
    registrationType === LegalForm.GMBH ||
    registrationType === LegalForm.GMBH_IN_GRUNDUNG ||
    registrationType === LegalForm.UG ||
    registrationType === LegalForm.UG_IN_GRUNDUNG ||
    registrationType === LegalForm.AG
  );
};

export const shouldRenderShareHoldersContractUpload = (
  registrationType?: LegalForm
): boolean => {
  return (
    registrationType === LegalForm.GMBH_IN_GRUNDUNG ||
    registrationType === LegalForm.UG_IN_GRUNDUNG ||
    registrationType === LegalForm.GBR
  );
};

export const shouldRenderIncorperationDocUpload = (
  registrationType?: LegalForm
): boolean => {
  return (
    registrationType === LegalForm.GMBH_IN_GRUNDUNG ||
    registrationType === LegalForm.UG_IN_GRUNDUNG
  );
};

export const renderOtherDocuments = (registrationType?: LegalForm): boolean => {
  return (
    registrationType === LegalForm.GMBH ||
    registrationType === LegalForm.GMBH_IN_GRUNDUNG ||
    registrationType === LegalForm.UG ||
    registrationType === LegalForm.UG_IN_GRUNDUNG ||
    registrationType === LegalForm.AG ||
    registrationType === LegalForm.EG ||
    registrationType === LegalForm.EK ||
    registrationType === LegalForm.GBR ||
    registrationType === LegalForm.KG ||
    registrationType === LegalForm.OHG ||
    registrationType === LegalForm.PART_G
  );
};

export const fieldNameToDocumentCategory = (
  fieldName: string
): DocumentCategory => {
  return DocumentCategory[
    `LEGAL_ENTITY_${fieldName
      .split(/(?=[A-Z])/)
      .join("_")
      .toUpperCase()}` as DocumentCategory
  ];
};
