import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, Icon, Commons, DownloadButton } from "@finvia/money-ui";
import FundBox from "./FundBox/FundBox";
import * as S from "./Overview.styled";
import { OverviewProps, Status, TStatus } from "./Overview.types";
import { boxesInfo, maps } from "./utils";
import { getKeyFromValue } from "utils/getKeyFromValue";

const Overview: React.FC<OverviewProps> = ({ fund }) => {
  const { t } = useTranslation();
  const fundAttributes = fund.attributes;

  const numberOfAvailableBoxes = boxesInfo({ t, fund }).filter(
    ({ title, subtitle }) => title && subtitle
  ).length;

  const isUrlAvailable =
    fundAttributes?.fundSummary?.link ??
    fundAttributes?.fundSummary?.file?.data?.attributes?.url;
  const documentStatus: TStatus = isUrlAvailable
    ? Status.available
    : Status.loading;
  const region = fundAttributes?.region;
  return (
    <Stack gutter="giga">
      {fundAttributes?.fundSummary && (
        <S.DocumentWrapper>
          <DownloadButton
            displayName={t("components.alternativeInvestmentFund.fundSummary")}
            fileURL={
              fundAttributes?.fundSummary?.link ??
              fundAttributes?.fundSummary.file?.data?.attributes?.url
            }
            status={documentStatus}
          />
        </S.DocumentWrapper>
      )}
      <S.FundBoxesWrapper numberOfBoxes={numberOfAvailableBoxes}>
        {boxesInfo({ t, fund }).map(
          ({ title, subtitle }, index, array) =>
            title &&
            subtitle && (
              <FundBox key={title + subtitle}>
                <S.Title
                  {...(fundAttributes?.status &&
                    array.length - 1 === index && {
                      status: fundAttributes?.status,
                    })}
                >
                  {subtitle}
                </S.Title>
                <S.Subtitle>{title}</S.Subtitle>
              </FundBox>
            )
        )}
      </S.FundBoxesWrapper>
      {fundAttributes?.spotlights?.map(
        (spotlight) =>
          spotlight?.key &&
          spotlight.value && (
            <FundBox key={spotlight.key}>
              <S.Title>{spotlight.key}</S.Title>
              <S.Subtitle maxWidth="85%">{spotlight.value}</S.Subtitle>
            </FundBox>
          )
      )}
      <S.RegionAndIndustriesWrapper>
        <S.RegionWrapper>
          <S.Region>
            {t("components.alternativeInvestmentFund.region.title")}
          </S.Region>
          <S.RegionName>
            {t(
              `components.alternativeInvestmentFund.region.${fundAttributes?.region}`
            )}
          </S.RegionName>
          {region && <S.MapContainer src={maps[region]} alt={region} />}
        </S.RegionWrapper>
        <S.IndustriesWrapper>
          <S.IndustryName>
            {t(`components.alternativeInvestmentFund.sectorFocus`)}
          </S.IndustryName>
          <S.IndustriesContainer>
            {fundAttributes?.industries?.map((industry) => {
              const key = getKeyFromValue(industry?.icon, Commons);
              return (
                industry?.value &&
                industry.icon && (
                  <S.Industry key={key}>
                    <Icon name={key} />
                    <S.IndustryName>{industry.value}</S.IndustryName>
                  </S.Industry>
                )
              );
            })}
          </S.IndustriesContainer>
        </S.IndustriesWrapper>
      </S.RegionAndIndustriesWrapper>
    </Stack>
  );
};

export default Overview;
