import { Heading, Stack, Text } from "@finvia/money-ui";
import React from "react";
import { FundFragment_attributes_fundManagerImage } from "../../../../../generated/FundFragment";
import * as S from "./FundDetailsHeader.styled";
import { useTranslation } from "react-i18next";

interface FundDetailsHeaderProps {
  fundName?: string;
  fundDescription?: string;
  fundManagerImageUrl?: FundFragment_attributes_fundManagerImage;
  fundAssetClass?: string;
}

const FundDetailsHeader: React.FC<FundDetailsHeaderProps> = ({
  fundName,
  fundDescription,
  fundManagerImageUrl,
  children,
  fundAssetClass,
}) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <Stack
        alignItems="center"
        justifyContent="center"
        gutter={{ sm: "giga", lg: "tera" }}
      >
        <Stack
          gutter="mega"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <S.Logo
              src={fundManagerImageUrl?.data?.attributes?.url}
              alt={
                fundManagerImageUrl?.data?.attributes?.alternativeText ||
                undefined
              }
            />
            <S.AssetClass>
              {t(
                `components.alternativeInvestmentFund.assetClass.${fundAssetClass}`
              )}
            </S.AssetClass>
          </Stack>

          <Heading as="h1" align="center" size={{ sm: 6, md: 7 }}>
            {fundName}
          </Heading>
        </Stack>
        <S.TextWrapper>
          <Text align="center">{fundDescription}</Text>
        </S.TextWrapper>
        {children}
      </Stack>
    </S.Wrapper>
  );
};

export default FundDetailsHeader;
