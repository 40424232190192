import { TableProps, Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const headerCustomStyles = css`
  ${({ theme }) => css`
    justify-content: space-between;

    & > :last-child {
      border: none;
    }

    ${theme.utils.viewport.md`
      grid-template-columns: repeat(6, 1fr);

      & > :not(:last-child) {
        padding-right: ${theme.tokens.sizes.spacings.kilo};
      }
    `};
  `}
`;

const headCustomStyles = css`
  ${({ theme }) => css`
    font-weight: ${theme.tokens.typography.weights.book};
    color: ${theme.tokens.colors.neutral[750]};
    text-align: start;
    justify-content: start;

    &:not(:first-of-type) {
      text-align: end;
      justify-content: end;
    }
  `}
`;

const rowCustomStyles = css`
  ${({ theme }) => css`
    grid-template-rows: initial;
    padding: 0 ${theme.tokens.sizes.spacings.kilo};
    background: ${theme.tokens.colors.neutral[300]};
    border-radius: ${theme.tokens.sizes.radii.bit};
    margin-bottom: ${theme.tokens.sizes.spacings.mega};

    &:last-child {
      margin-bottom: 0;
      border: none;
    }

    ${theme.utils.viewport.md`
      grid-template-columns: repeat(6, 1fr);
      justify-content: space-between;
      padding: 0;
      background: initial;

      & > :first-child {
        max-width: 100px;
        word-break: break-word;
        hyphens: auto;
      }

      & > :not(:last-child) {
        padding-right: ${theme.tokens.sizes.spacings.kilo};
      }
    `};

    ${theme.utils.viewport.lg`
      & > :first-child {
        max-width: initial;
        word-break: initial;
        hyphens: initial;
      }
    `};
  `}
`;

const cellCustomStyles = css`
  ${({ theme }) => css`
    justify-content: space-between;
    border-bottom: none;

    ${theme.utils.viewport.md`
      justify-content: start;

      &:not(:first-of-type) {
        text-align: end;
        justify-content: end;
      }
    `};
  `}
`;

export const customTableStyles: TableProps["customStyles"] = {
  header: headerCustomStyles,
  head: headCustomStyles,
  row: rowCustomStyles,
  cell: cellCustomStyles,
};

const tableWrapper = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: ${theme.tokens.colors.white[500]};
  border-radius: ${theme.tokens.sizes.radii.kilo};

  ${theme.utils.viewport.md`
    padding: ${theme.tokens.sizes.spacings.giga};
  `};
`;

export const TableWrapper = styled.span<{ theme: Theme }>(tableWrapper);

const imageWrapper = () => css`
  width: 100%;
  height: auto;
`;

const footer = ({ theme }: { theme: Theme }) => css`
  align-self: center;
  font-size: ${theme.tokens.typography.fontSizes[1]};
  text-align: left;

  ${theme.utils.viewport.md`
    width: 60%;
  `};
`;

export const Footer = styled.span<{ theme: Theme }>(footer);
export const ImageWrapper = styled.img(imageWrapper);
