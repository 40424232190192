import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../components/FormContent";
import { ContractType } from "../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../store";
import formDefaultsDeep from "../../../../utils/formDefaultsDeep";
import { QuestionnaireLayout } from "../../components/QuestionnaireLayout";
import { ROUTES } from "../../../../routes/routes";
import { upsertMandateContract } from "./actions";
import {
  ContractTypeForm,
  validationSchema,
} from "./contractTypeFormValidations";
import { ChoiceGroup, InfoBox, Stack, Tooltip } from "@finvia/money-ui";
import NecessaryDocuments from "./NecessaryDocuments/NecessaryDocuments";
import { generateNecessaryDocumentsList } from "./utils/generateNecessaryDocumentsList";
import { InfoBoxWrapper } from "./index.styled";

const translationPrefix = "q-ai.opportunities.form.contractType";

const ContractAndRegulatoryData: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const documentsListNP = generateNecessaryDocumentsList({
    t,
    contractType: ContractType.NATURAL_PERSON,
    documentsNames: ["tax_number", "identification_document"],
  });

  const documentsListLE = generateNecessaryDocumentsList({
    t,
    contractType: ContractType.LEGAL_ENTITY,
    documentsNames: ["commercial_register", "shareholders_list", "w8_form"],
  });

  const [error, setError] = useState<SerializedError | undefined>(undefined);

  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<ContractTypeForm>({
      contractType:
        state.alternativeInvestments.regulatoryData?.personData.contractType,
    })
  );

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const previousStep = useCallback(
    () => history.push(ROUTES.questionnaires.ai.root),
    [history]
  );

  const onSubmit = useCallback(
    async (contractType: ContractTypeForm) => {
      dispatch(upsertMandateContract(contractType))
        .then(unwrapResult)
        .then(() => {
          if (contractType.contractType === ContractType.NATURAL_PERSON) {
            history.push(
              `${ROUTES.questionnaires.ai.regulatory.naturalPerson}/2`
            );
          } else {
            history.push(
              `${ROUTES.questionnaires.ai.regulatory.legalEntity.root}/2`
            );
          }
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, history]
  );

  // Since Enum values order isn't guaranteed, in places where order matters we should order values ourselves and not rely on the "Object.value(enum)"
  return (
    <QuestionnaireLayout
      stepGroups={[
        {
          label: "Vertragstyp",
          selected: true,
          steps: [],
        },
        {
          label: "Vertragspartner",
          steps: [],
        },
        {
          label: "Regulatorik",
          steps: [],
        },
        {
          label: "Referenzkonto",
          steps: [],
        },
      ]}
    >
      <FormControl<ContractTypeForm>
        backLabel={t("form.back")}
        defaultValues={defaultData}
        onBack={previousStep}
        onSubmit={onSubmit}
        submitLabel={t("form.continue")}
        validationSchema={memoizedSchema}
        validationMode="onChange"
        dataAnalytics="2.1_choose_type_of_contract"
      >
        {({ register, formState, watch }) => {
          const isContractTypeSelected = watch("contractType") !== null;
          const isLegalEntity =
            watch("contractType") &&
            watch("contractType") === ContractType.LEGAL_ENTITY;
          return (
            <FormContent
              title={t(`${translationPrefix}.title`)}
              subTitle={t(`${translationPrefix}.subTitle`)}
              isDirty={formState.isDirty}
              isSubmitSuccessful={formState.isSubmitSuccessful}
              error={error}
            >
              <ChoiceGroup name="contractType" label="">
                <Stack
                  direction="row"
                  gutter={{ sm: "giga", md: "peta" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <ChoiceGroup.Card
                    key={ContractType.NATURAL_PERSON}
                    value={ContractType.NATURAL_PERSON}
                    name="contractType"
                    data-testid={`contractType_${ContractType.NATURAL_PERSON}`}
                    label={t(
                      `${translationPrefix}.contractType.${ContractType.NATURAL_PERSON}.label`
                    )}
                    description={t(
                      `${translationPrefix}.contractType.${ContractType.NATURAL_PERSON}.description`
                    )}
                    disabled={false}
                    icon="client"
                    ref={register}
                  />

                  <ChoiceGroup.Card
                    key={ContractType.LEGAL_ENTITY}
                    value={ContractType.LEGAL_ENTITY}
                    name="contractType"
                    data-testid={`contractType_${ContractType.LEGAL_ENTITY}`}
                    label={t(
                      `${translationPrefix}.contractType.${ContractType.LEGAL_ENTITY}.label`
                    )}
                    description={t(
                      `${translationPrefix}.contractType.${ContractType.LEGAL_ENTITY}.description`
                    )}
                    icon="company"
                    ref={register}
                  />
                </Stack>
              </ChoiceGroup>
              {isContractTypeSelected && (
                <InfoBoxWrapper isLegalEntity={isLegalEntity}>
                  <InfoBox>
                    <Stack gutter="byte">
                      <NecessaryDocuments
                        title={t(
                          `${translationPrefix}.contractType.${ContractType.NATURAL_PERSON}.necessary_documents.title`
                        )}
                        documents={documentsListNP}
                      />
                      {isLegalEntity && (
                        <NecessaryDocuments
                          title={t(
                            `${translationPrefix}.contractType.${ContractType.LEGAL_ENTITY}.necessary_documents.title`
                          )}
                          documents={documentsListLE}
                        />
                      )}
                    </Stack>
                  </InfoBox>
                </InfoBoxWrapper>
              )}
            </FormContent>
          );
        }}
      </FormControl>
    </QuestionnaireLayout>
  );
};

export default withTranslation()(ContractAndRegulatoryData);
