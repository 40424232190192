import { AnyAction, SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import {
  AccumulatedIdentificationStatus,
  LegalEntityIdentificationStatus,
  LegalForm,
} from "../../../../../../generated/globalTypes";
import { DocumentChange } from "../../../../../../models/documents";
import { typedUseSelector } from "../../../../../../store";
import { fieldNameToDocumentCategory } from "../../../../../../utils/documentUpload";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import {
  updateDocumentsData,
  updateLegalEntityProfile,
} from "../../../contractAndRegulatoryData/legalEntity/actions";
import { translationPrefix as legalEntityDocumentUploadTranslationPrefix } from "../../../contractAndRegulatoryData/legalEntity/DocumentsUpload/DocumentsUploadForm";
import {
  IdentificationStatusForm,
  validationSchema,
} from "./IdentificationStatusValidation";
import IdentificationStepperWrapper from "./IdentificationStepperWrapper/IdentificationStepperWrapper";
import {
  handleDeleteDocumentGeneric,
  handleDocumentUploadGeneric,
  UploadField,
  Document,
} from "features/alternative-investments/components/UploadField";
import { mapDocumentStatusToUpload } from "../../../contractAndRegulatoryData/legalEntity/DocumentsUpload/utils/utils";
import {
  DocumentCategoryNames,
  HandleDocumentDelete,
  HandleDocumentUpload,
  LegalEntityDocumentCategory,
} from "./IdentificationStatus.types";
import { Grid } from "@finvia/money-ui";

export const translationPrefix = "q-ai.mandate.form.identificationStatus";

const IdentificationStatus: React.FC<StepComponentProps> = ({ nextStep }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<SerializedError | undefined>(undefined);

  const dispatch = useDispatch();

  const defaultData = typedUseSelector((store) => {
    const legalEntityIdentificationStatus =
      store.alternativeInvestments.regulatoryData.legalEntityData.documents ||
      {};

    const accumulatedSolarisIdentStatus =
      store.alternativeInvestments.mandateContractData.data.legalEntity
        .accumulatedIdentificationStatus;

    const legalIdentificationStatus =
      store.alternativeInvestments.mandateContractData.data.legalEntity
        .legalEntityIdentificationStatus;

    const failedIdentificationMessage =
      store.alternativeInvestments.mandateContractData.data.legalEntity
        .failedIdentificationMessage;

    return formDefaultsDeep<IdentificationStatusForm>(
      {
        legalEntityIdentificationStatus: {
          ...legalEntityIdentificationStatus,
          accumulatedSolarisIdentStatus,
          legalIdentificationStatus,
          failedIdentificationMessage,
        },
      },
      {
        legalEntityIdentificationStatus: {
          accumulatedSolarisIdentStatus: "",
          legalIdentificationStatus: "",
          failedIdentificationMessage: undefined,
          currentTradeRegister: undefined,
          shareholdersListNonAg: undefined,
          shareholdersListAg: undefined,
          furtherShareholders: undefined,
          shareholdersContract: undefined,
          incorperation: undefined,
          others: undefined,
        },
      }
    );
  });

  const registrationType: LegalForm | undefined = typedUseSelector((store) => {
    return (
      store.alternativeInvestments.regulatoryData?.legalEntityData
        ?.legalEntityData?.registrationType || undefined
    );
  });

  const memoizedSchema = useMemo(
    () => validationSchema(t, defaultData.legalEntityIdentificationStatus),
    [defaultData.legalEntityIdentificationStatus, t]
  );

  const onSubmit = useCallback(
    async (documentsUploadData: IdentificationStatusForm) => {
      dispatch(
        updateLegalEntityProfile({
          documents: {
            currentTradeRegister:
              documentsUploadData.legalEntityIdentificationStatus
                ?.currentTradeRegister,
            shareholdersListNonAg:
              documentsUploadData.legalEntityIdentificationStatus
                ?.shareholdersListNonAg,
            incorperation:
              documentsUploadData.legalEntityIdentificationStatus
                ?.incorperation,
            shareholdersContract:
              documentsUploadData.legalEntityIdentificationStatus
                ?.shareholdersContract,
            shareholdersListAg:
              documentsUploadData.legalEntityIdentificationStatus
                ?.shareholdersListAg,
            furtherShareholders:
              documentsUploadData.legalEntityIdentificationStatus
                ?.furtherShareholders,
            others: documentsUploadData.legalEntityIdentificationStatus?.others,
          },
        })
      )
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const fields: {
    [key: string]: { show: boolean; category: DocumentCategoryNames };
  } = {
    currentTradeRegister: {
      show: defaultData?.legalEntityIdentificationStatus?.currentTradeRegister
        ?.isMissing,
      category: LegalEntityDocumentCategory.LEGAL_ENTITY_CURRENT_TRADE_REGISTER,
    },
    shareholdersListNonAg: {
      show: defaultData?.legalEntityIdentificationStatus?.shareholdersListNonAg
        ?.isMissing,
      category:
        LegalEntityDocumentCategory.LEGAL_ENTITY_SHAREHOLDERS_LIST_NON_AG,
    },
    incorperation: {
      show: defaultData?.legalEntityIdentificationStatus?.incorperation
        ?.isMissing,
      category: LegalEntityDocumentCategory.LEGAL_ENTITY_INCORPERATION,
    },
    shareholdersContract: {
      show: defaultData?.legalEntityIdentificationStatus?.shareholdersContract
        ?.isMissing,
      category: LegalEntityDocumentCategory.LEGAL_ENTITY_SHAREHOLDERS_CONTRACT,
    },
    shareholdersListAg: {
      show: defaultData?.legalEntityIdentificationStatus?.shareholdersListAg
        ?.isMissing,
      category: LegalEntityDocumentCategory.LEGAL_ENTITY_SHAREHOLDERS_LIST_AG,
    },
    furtherShareholders: {
      show: defaultData?.legalEntityIdentificationStatus?.furtherShareholders
        ?.isMissing,
      category: LegalEntityDocumentCategory.LEGAL_ENTITY_FURTHER_SHAREHOLDERS,
    },
    others: {
      show: defaultData?.legalEntityIdentificationStatus?.others?.isMissing,
      category: LegalEntityDocumentCategory.LEGAL_ENTITY_OTHERS,
    },
  };

  const [currentDocumentsList, setCurrentDocumentsList] = useState<
    | {
        documents?: {
          [k in DocumentCategoryNames]?: { documents: Document[] | undefined };
        };
      }
    | undefined
  >({ documents: undefined });

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    category,
    name,
    setValueReference
  ) => {
    const filteredDocuments = await handleDeleteDocumentGeneric<
      (payload: {
        changedDocuments: DocumentChange[];
        documentCategory: DocumentCategoryNames;
      }) => AnyAction
    >({
      document,
      documents,
      setValueReference,
      dispatch,
      updateAction: updateDocumentsData,
      setError,
      name: name,
      formName: "IdentificationStatus",
      extraProps: { documentCategory: category },
    }).then((res) => res);

    setCurrentDocumentsList({
      documents: {
        ...currentDocumentsList?.documents,
        [category]: { documents: filteredDocuments },
      },
    });
  };

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    category,
    name,
    setValueReference
  ) => {
    const documentCategory = fieldNameToDocumentCategory(category);

    const doc = await handleDocumentUploadGeneric({
      documents,
      file,
      setValueReference,
      dispatch,
      updateAction: updateDocumentsData,
      category: documentCategory,
      setError,
      name,
      formName: "IdentificationStatus",
      extraProps: { documentCategory: category },
    }).then((res) => res);

    setCurrentDocumentsList({
      documents: {
        ...currentDocumentsList?.documents,
        [category]: {
          documents: doc,
        },
      },
    });
  };

  return (
    <FormControl
      submitLabel={t("form.continue")}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      dontShowErrors
      validationSchema={memoizedSchema}
      dataAnalytics="3.4_le_identification_status"
    >
      {(formMethods) => {
        // casting because watch returns string or unknown
        const legalIdentificationStatus = formMethods.watch(
          "legalEntityIdentificationStatus.legalIdentificationStatus"
        ) as LegalEntityIdentificationStatus;

        // TODO don't register on every render
        formMethods.register(
          "legalEntityIdentificationStatus.legalIdentificationStatus"
        );

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
          >
            <IdentificationStepperWrapper
              name="legalEntityIdentificationStatus.accumulatedSolarisIdentStatus"
              accumulatedSolarisIdentStatus={
                defaultData?.legalEntityIdentificationStatus
                  ?.accumulatedSolarisIdentStatus as AccumulatedIdentificationStatus
              }
              legalIdentificationStatus={
                defaultData?.legalEntityIdentificationStatus
                  ?.legalIdentificationStatus as LegalEntityIdentificationStatus
              }
              failedIdentificationMessage={
                defaultData.legalEntityIdentificationStatus
                  ?.failedIdentificationMessage
              }
            />
            {legalIdentificationStatus ===
              LegalEntityIdentificationStatus.INFORMATION_REQUIRED && (
              <Grid columns={{ sm: 1, md: 2 }} gap={{ sm: "mega", md: "tera" }}>
                {Object.keys(fields).map((fieldName: string) => {
                  const inputName = `legalEntityIdentificationStatus.${fieldName}.documents`;
                  return (
                    fields[fieldName].show && (
                      <UploadField
                        key={inputName}
                        name={inputName}
                        onDelete={(document, documents) => {
                          handleDeleteDocument(
                            document,
                            documents,
                            fieldName as DocumentCategoryNames,
                            inputName,
                            formMethods.setValue
                          );
                        }}
                        onUpload={(documents, file) => {
                          handleDocumentUpload(
                            documents,
                            file,
                            fieldName as DocumentCategoryNames,
                            inputName,
                            formMethods.setValue
                          );
                        }}
                        documentsList={
                          mapDocumentStatusToUpload(
                            currentDocumentsList?.documents?.[
                              fieldName as DocumentCategoryNames
                            ]?.documents
                          ) || []
                        }
                        label={t(
                          `${legalEntityDocumentUploadTranslationPrefix}.fields.${fieldName}.label`
                        )}
                        helperText={t(
                          `${legalEntityDocumentUploadTranslationPrefix}.fields.${fieldName}.tooltip.${registrationType}`
                        )}
                        inputErrorMessage={undefined}
                        barErrorMessage={t("form.fileUpload.failed")}
                        barSuccessMessage={t("form.fileUpload.succeeded")}
                        formMethods={formMethods}
                        defaultValue={
                          mapDocumentStatusToUpload(
                            currentDocumentsList?.documents?.[
                              fieldName as DocumentCategoryNames
                            ]?.documents
                          ) || []
                        }
                      />
                    )
                  );
                })}
              </Grid>
            )}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default IdentificationStatus;
