import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../components/FormControl/FormControl";
import { Checkbox, Grid } from "@finvia/money-ui";
import { FormContent } from "../../../../../components/FormContent";
import { InvestmentGoal } from "../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../store";
import formDefaultsDeep from "../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../components/QuestionnaireController";
import { updateProfile } from "../actions";
import { validationSchema, GoalsProps } from ".";

const translationPrefix = "q-ai.opportunities.form.goals";

const investmentGoals: InvestmentGoal[] = [
  InvestmentGoal.ASSET_ACCUMULATION,
  InvestmentGoal.FAMILY_PRECAUTION,
  InvestmentGoal.LIQUIDITY_SUPPLY,
  InvestmentGoal.PRECAUTION,
  InvestmentGoal.SHORT_TERM_PROFIT,
  InvestmentGoal.CONTINUITY,
  InvestmentGoal.OTHERS,
];

const GoalsForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<GoalsProps>({
      goals: state.alternativeInvestments.investmentOpportunities.data?.goals,
    })
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data: GoalsProps) => {
      dispatch(updateProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<GoalsProps>
      backLabel={t("form.back")}
      defaultValues={defaultData}
      onBack={previousStep}
      onSubmit={onSubmit}
      submitLabel={t("form.continue")}
      validationSchema={validationSchema(t)}
      validationMode="onChange"
      dataAnalytics="1.1_investment_goals"
    >
      {(formMethods) => (
        <FormContent
          title={t(`${translationPrefix}.title`)}
          subTitle={t(`${translationPrefix}.subTitle`)}
          isDirty={formMethods.formState.isDirty}
          isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          error={error}
        >
          <Grid
            columns={{ sm: 1, md: 2, lg: 3 }}
            gap={{ sm: "kilo", md: "giga" }}
          >
            {investmentGoals.map((goal) => (
              <Checkbox
                key={goal}
                value={goal}
                ref={formMethods.register}
                name="goals"
                label={t(`${translationPrefix}.goals.${goal}.label`)}
                helperText={t(`${translationPrefix}.goals.${goal}.tooltip`)}
              />
            ))}
          </Grid>
        </FormContent>
      )}
    </FormControl>
  );
};

export default GoalsForm;
