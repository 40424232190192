import { DocumentStatus, LegalForm } from "generated/globalTypes";
import {
  renderOtherDocuments,
  shouldRenderCurrentTradeRegisterUpload,
  shouldRenderFurtherShareholdersUpload,
  shouldRenderIncorperationDocUpload,
  shouldRenderShareholdersAGUpload,
  shouldRenderShareHoldersContractUpload,
  shouldRenderShareholdersListNonAG,
} from "utils/documentUpload";
import {
  DocumentCategoryNames,
  Document,
  DocumentUpload,
} from "../DocumentsUpload.types";

export const inputNameToDocumentCategory = (
  name: string
): DocumentCategoryNames =>
  name.replaceAll("documents", "").replaceAll(".", "") as DocumentCategoryNames;

export const fromFileListToFile = (file: FileList): File[] =>
  Array.from(file).map((docfile: File) => docfile);

export const revalidateForm = (
  trigger: (name?: string | string[] | undefined) => Promise<boolean>,
  inputName: string
): Promise<boolean> => trigger(inputName);

export const documentCategoriesToRender = (
  registrationType: LegalForm | undefined
): Record<DocumentCategoryNames, boolean> => ({
  currentTradeRegister:
    shouldRenderCurrentTradeRegisterUpload(registrationType),
  shareholdersListNonAg: shouldRenderShareholdersListNonAG(registrationType),
  shareholdersListAg: shouldRenderShareholdersAGUpload(registrationType),
  furtherShareholders: shouldRenderFurtherShareholdersUpload(registrationType),
  shareholdersContract:
    shouldRenderShareHoldersContractUpload(registrationType),
  incorperation: shouldRenderIncorperationDocUpload(registrationType),
  others: renderOtherDocuments(registrationType),
});

export const mapDocumentStatusToUpload = (
  documents: Document[] | undefined | null
): DocumentUpload[] | null =>
  documents?.map((document) => {
    const isPartOfDocumentstatus =
      document.status === DocumentStatus.ARCHIVED ||
      document.status === DocumentStatus.CREATED ||
      document.status === DocumentStatus.DRAFT;

    return {
      ...document,
      status: isPartOfDocumentstatus ? "done" : document.status,
    } as DocumentUpload;
  }) ?? null;
