import { Stack, ChoiceGroup, Box, Grid } from "@finvia/money-ui";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InvestorType as InvestorTypeEnum } from "generated/globalTypes";

import { FundDeclarationsForm } from "../../fundDeclarationsValidations";
import SemiProfessional from "./SemiProfessional";
import Professional from "./Professional";

const translationPrefix = "q-ai.opportunities.form.fundDeclarations";

const InvestorType = (): JSX.Element => {
  const { errors, watch, register } = useFormContext<FundDeclarationsForm>();
  const { t } = useTranslation();
  const radioLabel = (key: string): string =>
    t(`${translationPrefix}.fields.investorType.radioFields.${key}.label`);

  const investorWatch = watch("declarations.investorType");

  return (
    <Stack alignItems="center" gutter="mega">
      <Box alignItems="center">
        <ChoiceGroup
          required
          name="declarations.investorType"
          label={t(`${translationPrefix}.fields.investorType.label`)}
          errorMessage={errors.declarations?.investorType?.message}
        >
          <Grid columns={{ sm: 1, md: 2 }} gap="mega">
            <ChoiceGroup.Radio
              ref={register}
              name="declarations.investorType"
              label={radioLabel(InvestorTypeEnum.SEMI_PROFESSIONAL)}
              value={InvestorTypeEnum.SEMI_PROFESSIONAL}
            />
            <ChoiceGroup.Radio
              ref={register}
              name="declarations.investorType"
              label={radioLabel(InvestorTypeEnum.PROFESSIONAL)}
              value={InvestorTypeEnum.PROFESSIONAL}
            />
          </Grid>
        </ChoiceGroup>
      </Box>

      {investorWatch === InvestorTypeEnum.SEMI_PROFESSIONAL ? (
        <SemiProfessional />
      ) : (
        <Professional />
      )}
    </Stack>
  );
};

export default InvestorType;
