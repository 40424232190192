import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import { validationSchema } from "./BankAccounts.schema";
import {
  Heading,
  InfoBox,
  TextInput,
  Grid,
  Box,
  Checkbox,
  Tooltip,
  Stack,
} from "@finvia/money-ui";
import { IconWrapper } from "../../naturalPerson/BankAccount/BankAccounts.styles";

import { BankAccountsProps } from ".";

const translationPrefix = "q-ai.opportunities.form.legalBankAccounts";

const BankAccounts: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={1}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) => {
    const bankAccounts =
      state.alternativeInvestments?.regulatoryData?.legalEntityData
        ?.bankAccounts;

    return formDefaultsDeep<BankAccountsProps>(
      {
        bankAccounts: {
          ...bankAccounts,
          accountHolderCompany:
            state.alternativeInvestments.regulatoryData?.legalEntityData
              ?.legalEntityData?.companyName,
          usdAccountHolderCompany:
            state.alternativeInvestments.regulatoryData?.legalEntityData
              ?.legalEntityData?.companyName,
        },
      },
      {
        bankAccounts: {
          accountHolderCompany: "",
          iban: "",
          bic: "",
          nameOfBank: "",
          cityOfBank: "",
          usdCheckbox: false,
          usdAccountHolderCompany: "",
          usdIban: "",
          usdBic: "",
          usdNameOfBank: "",
          usdCityOfBank: "",
        },
      }
    );
  });

  const onSubmit = useCallback(
    async (data: BankAccountsProps) => {
      dispatch(updateLegalEntityProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const inputLabel = (name: string) =>
    t(`${translationPrefix}.fields.bankAccounts.${name}.label`);

  return (
    <FormControl<BankAccountsProps>
      dontShowErrors
      onBack={previousStep}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics="2.17_le_bank_accounts"
    >
      {(formMethods) => {
        const { watch, register, errors } = formMethods;

        const watchUsdChecked = watch("bankAccounts.usdCheckbox");

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Heading as="h2" size={{ sm: 3, lg: 4 }} font="sans">
              {t(`${translationPrefix}.headline`)}
            </Heading>

            <Grid columns={{ sm: 1, md: 2 }} rowsGap="mega" gap="peta">
              <TextInput crossOrigin
                required
                readOnly
                ref={register}
                name="bankAccounts.accountHolderCompany"
                label={inputLabel("accountHolderCompany")}
              />
              <TextInput crossOrigin
                required
                ref={register}
                name="bankAccounts.iban"
                label={inputLabel("iban")}
                errorMessage={errors?.bankAccounts?.iban?.message}
              />
              <TextInput crossOrigin
                required
                ref={register}
                name="bankAccounts.bic"
                label={inputLabel("bic")}
                errorMessage={errors?.bankAccounts?.bic?.message}
              />

              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.nameOfBank"
                label={inputLabel("nameOfBank")}
              />

              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.cityOfBank"
                label={inputLabel("cityOfBank")}
              />
            </Grid>

            <InfoBox>{t(`${translationPrefix}.infoBoxText`)}</InfoBox>

            <Box width="100%" display="flex" justifyContent="flex-start">
              <Checkbox
                ref={register}
                name="bankAccounts.usdCheckbox"
                label={inputLabel("usdCheckbox")}
              />
            </Box>

            {watchUsdChecked && (
              <Stack gutter="tera">
                <Stack direction="row" justifyContent="flex-start">
                  <Heading as="h2" size={{ sm: 3, lg: 4 }} font="sans">
                    {t(`${translationPrefix}.usdAccount.title`)}
                  </Heading>
                  <Tooltip
                    id="page-title"
                    label={t(`${translationPrefix}.usdAccount.tooltip`)}
                    placement="top"
                    gap={10}
                  >
                    <IconWrapper>i</IconWrapper>
                  </Tooltip>
                </Stack>
                <Grid columns={{ sm: 1, md: 2 }} rowsGap="mega" gap="peta">
                  <TextInput crossOrigin
                    required
                    readOnly
                    ref={register}
                    name="bankAccounts.usdAccountHolderCompany"
                    label={inputLabel("usdAccountHolderCompany")}
                  />

                  <TextInput crossOrigin
                    required
                    ref={register}
                    name="bankAccounts.usdIban"
                    label={inputLabel("usdIban")}
                    errorMessage={errors?.bankAccounts?.usdIban?.message}
                  />

                  <TextInput crossOrigin
                    required
                    ref={register}
                    name="bankAccounts.usdBic"
                    label={inputLabel("usdBic")}
                    errorMessage={errors?.bankAccounts?.usdBic?.message}
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdNameOfBank"
                    label={inputLabel("usdNameOfBank")}
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdCityOfBank"
                    label={inputLabel("usdCityOfBank")}
                  />
                </Grid>
              </Stack>
            )}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default BankAccounts;
