import styled, { css } from "styled-components";

type CardWrapperStyleProps = { position: "left" | "right" };

const cardWrapper = ({ position }: CardWrapperStyleProps) => css`
  width: 100%;
  width: fit-content;

  ${({ theme }) =>
    theme.utils.viewport.md`
    justify-self: ${position};
  `}
`;

type InfoBoxWrapperStyleProps = { isLegalEntity?: boolean };

const infoBoxWrapper = ({ isLegalEntity }: InfoBoxWrapperStyleProps) => css`
  width: 100%;
  ${({ theme }) =>
    theme.utils.viewport.md`
    width: ${isLegalEntity ? "630px" : "430px"};
  `}
`;

export const CardWrapper = styled.div<CardWrapperStyleProps>(cardWrapper);
export const InfoBoxWrapper =
  styled.div<InfoBoxWrapperStyleProps>(infoBoxWrapper);
