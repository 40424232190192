import { TimelineStepperProps } from "@finvia/money-ui";
import { TFunction } from "i18next";
import {
  AccumulatedIdentificationStatus,
  LegalEntityIdentificationStatus,
} from "../../../../../../../../generated/globalTypes";
import { IdentificationStatusProps } from "../IdentificationStepperWrappertypes";

export const translationPrefix = "q-ai.mandate.form.identificationStatus";

export const getInfoBoxMessage = ({
  t,
  accumulatedSolarisIdentStatus,
  legalIdentificationStatus,
  failedIdentificationMessage,
}: Omit<IdentificationStatusProps, "name"> & { t: TFunction }): string => {
  const hasMissingDocuments =
    legalIdentificationStatus ===
    LegalEntityIdentificationStatus.INFORMATION_REQUIRED;

  if (failedIdentificationMessage !== undefined) {
    return failedIdentificationMessage;
  }

  switch (accumulatedSolarisIdentStatus) {
    case AccumulatedIdentificationStatus.CREATED:
    case AccumulatedIdentificationStatus.PENDING:
      return hasMissingDocuments
        ? t(`${translationPrefix}.info.missingDocuments`)
        : "";
    case AccumulatedIdentificationStatus.SUCCESSFUL:
      return "";
    case AccumulatedIdentificationStatus.EXPIRED:
    case AccumulatedIdentificationStatus.FAILED:
      return t(`${translationPrefix}.info.failed`);
    default:
      return t(`${translationPrefix}.info.pending`);
  }
};

export const generateSteps = ({
  t,
  accumulatedSolarisIdentStatus,
}: {
  t: TFunction;
  accumulatedSolarisIdentStatus: IdentificationStatusProps["accumulatedSolarisIdentStatus"];
}): TimelineStepperProps["steps"] => {
  const isStatusFailed =
    accumulatedSolarisIdentStatus === AccumulatedIdentificationStatus.FAILED ||
    accumulatedSolarisIdentStatus === AccumulatedIdentificationStatus.EXPIRED;

  const steps: TimelineStepperProps["steps"] = [
    {
      label: t(`${translationPrefix}.steps.created`),
      status: "inProgress",
    },
    {
      label: t(`${translationPrefix}.steps.inProgress`),
      status: "idle",
    },
    {
      label: isStatusFailed
        ? t(`${translationPrefix}.steps.failed`)
        : t(`${translationPrefix}.steps.done`),
      status: "idle",
    },
  ];

  switch (accumulatedSolarisIdentStatus) {
    case AccumulatedIdentificationStatus.CREATED:
    case AccumulatedIdentificationStatus.PENDING:
      steps[0].status = "success";
      steps[1].status = "inProgress";
      break;
    case AccumulatedIdentificationStatus.SUCCESSFUL:
      steps.forEach((_, index) => {
        steps[index].status = "success";
      });
      break;
    case AccumulatedIdentificationStatus.EXPIRED:
    case AccumulatedIdentificationStatus.FAILED:
      steps.forEach((_, index) => {
        steps[index].status = "failed";
      });
      break;
  }

  return steps;
};
