import { Stack, Text, Heading } from "@finvia/money-ui";
import { useTranslation } from "react-i18next";

import { NotFoundWrapper } from "./NotFound.styled";

const NotFound = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <NotFoundWrapper>
      <Stack gutter="giga">
        <Heading align="center" size={7}>
          404
        </Heading>
        <Text>{t("components.alternativeInvestmentFund.notFound")}</Text>
      </Stack>
    </NotFoundWrapper>
  );
};

export default NotFound;
