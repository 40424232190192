import { isAfter, parse } from "date-fns";
import { TFunction } from "i18next";
import { dateFormat } from "utils/helpers";
import * as yup from "yup";
import {
  MoneyRangeLiabilities,
  MoneyRangeSmall,
} from "../../../../../../generated/globalTypes";
import { smallRangeCheckboxValidationSchema } from "../../common/GenericValidations/helpers";

const translationPrefix = "q-ai.opportunities.form.liabilities";

export const liabilityCheckboxNames = [
  "rent",
  "living",
  "loan",
  "alimony",
  "insurance",
] as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const liabilitiesValidationSchema = (t: TFunction) => {
  return yup
    .object({
      annualLiabilities: yup.object({
        range: yup
          .mixed<MoneyRangeLiabilities>()
          .required(
            t(`${translationPrefix}.fields.liabilities.total.range.required`)
          )
          .oneOf(
            Object.values(MoneyRangeLiabilities),
            t(`${translationPrefix}.fields.liabilities.total.range.required`)
          )
          .label(
            t(`${translationPrefix}.fields.liabilities.total.range.label`)
          ),
        value: yup
          .mixed()
          .transform((value) =>
            typeof value !== "number"
              ? Number(value.replace(/[.,\s]/g, ""))
              : value
          )
          .when("range", {
            is: (money: MoneyRangeLiabilities) =>
              money === MoneyRangeLiabilities.MORE_THAN_500000,
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .test(
                "minimum",
                t(
                  `${translationPrefix}.fields.liabilities.total.value.minimum`
                ),
                (value) => (value ?? 0) > 500000
              )
              .required(
                t(
                  `${translationPrefix}.fields.liabilities.total.value.required`
                )
              ),
          })
          .label(
            t(`${translationPrefix}.fields.liabilities.total.value.label`)
          ),
      }),
      rent: smallRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.liabilities.rent`
      ),
      living: smallRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.liabilities.living`
      ),
      loan: smallRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.liabilities.loan`
      ),
      alimony: smallRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.liabilities.alimony`
      ),
      insurance: smallRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.liabilities.insurance`
      ),
    })
    .test("liabilitiesPerMonthCheckboxGroup", "", (obj, testContext) => {
      if (
        obj.rent.range ||
        obj.living.range ||
        obj.loan.range ||
        obj.alimony.range ||
        obj.insurance.range
      ) {
        return true;
      }

      return testContext.createError({
        message: t(`${translationPrefix}.liabilitiesPerMonthCheckboxGroup`),
        path: `${testContext.path}.liabilitiesPerMonthCheckboxGroup`,
      });
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const availableMonthlyLiquidityValidationSchema = (t: TFunction) => {
  return yup.object({
    range: yup
      .mixed<MoneyRangeSmall>()
      .oneOf(
        Object.values(MoneyRangeSmall),
        t(
          `${translationPrefix}.fields.availableMonthlyLiquidity.range.required`
        )
      )
      .label(
        t(`${translationPrefix}.fields.availableMonthlyLiquidity.range.label`)
      )
      .required(
        t(
          `${translationPrefix}.fields.availableMonthlyLiquidity.range.required`
        )
      ),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeSmall) =>
          money === MoneyRangeSmall.MORE_THAN_5000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(
              `${translationPrefix}.fields.availableMonthlyLiquidity.value.minimum`
            ),
            (value) => (value ?? 0) > 5000
          )
          .required(
            t(
              `${translationPrefix}.fields.availableMonthlyLiquidity.value.required`
            )
          ),
      })
      .label(
        t(`${translationPrefix}.fields.availableMonthlyLiquidity.value.label`)
      ),
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const savingsValidationSchema = (t: TFunction) => {
  return yup.object({
    minimumShortTermReserve: yup.object({
      exists: yup
        .string()
        .required(
          t(
            `${translationPrefix}.fields.savings.minimumShortTermReserve.exists.required`
          )
        )
        .label(
          t(
            `${translationPrefix}.fields.savings.minimumShortTermReserve.exists.title`
          )
        ),
      value: yup
        .mixed()
        .transform((value) =>
          typeof value !== "number"
            ? Number(value.replace(/[.,\s]/g, ""))
            : value
        )
        .when("exists", {
          is: "yes",
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(
              t(
                `${translationPrefix}.fields.savings.minimumShortTermReserve.value.required`
              )
            ),
        })
        .label(
          t(
            `${translationPrefix}.fields.savings.minimumShortTermReserve.value.label`
          )
        ),
      date: yup
        .string()
        .when("exists", {
          is: (answer: "yes" | "no") => answer === "yes",
          then: yup.string().test(
            "is-valid-date-of-reserve",
            // eslint-disable-next-line no-template-curly-in-string
            "Bitte geben Sie ein Datum in der Zukunft im Format tt.mm.yyyy an.",
            (value) => {
              if (!value) return true;
              try {
                const date = parse(value, "d.M.y", new Date());
                const OLDEST_DATE = new Date();

                const matchDateFormat = value.match(dateFormat) !== null;

                return isAfter(date, OLDEST_DATE) && matchDateFormat;
              } catch (_e) {
                return false;
              }
            }
          ),
        })
        .label(
          t(
            `${translationPrefix}.fields.savings.minimumShortTermReserve.date.label`
          )
        ),
    }),
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      liabilities: liabilitiesValidationSchema(t),
      savings: savingsValidationSchema(t),
      availableMonthlyLiquidity: availableMonthlyLiquidityValidationSchema(t),
    })
    .required();
