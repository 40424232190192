import { TFunction } from "i18next";
import * as yup from "yup";

const translationPrefix = "q-ai.opportunities.form.beneficialOwner";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (
  t: TFunction,
  additionalPersonsCompanyEquityPercentage: number
) =>
  yup.object({
    isBeneficialOwner: yup
      .string()
      .required(
        t(
          `${translationPrefix}.fields.legalEntityData.isBeneficialOwner.required`
        )
      )
      .oneOf(["yes", "no"]),
    companyEquityPercentage: yup.string().when("isBeneficialOwner", {
      is: "yes",
      then: yup
        .string()
        .test(
          "companyEquityPercentage",
          t(
            `${translationPrefix}.fields.legalEntityData.companyEquityPercentage.required`
          ),
          (value, testContext) => {
            if (!value) return false;

            const number = parseFloat(value.split(" ")[0]);

            if (number < 25) {
              return testContext.createError({
                message: t(
                  `${translationPrefix}.fields.legalEntityData.companyEquityPercentage.required`
                ),
                type: "invalidCompanyEquityPercentage",
                path: "companyEquityPercentage",
              });
            }

            if (number + additionalPersonsCompanyEquityPercentage > 100) {
              return testContext.createError({
                message: t(
                  `${translationPrefix}.fields.beneficialOwner.companyEquityPercentage.maximumPct`
                ),
                type: "companyEquityPercentageExceedsMaximum",
                path: "companyEquityPercentage",
              });
            }

            return true;
          }
        )
        .required(),
    }),
  });

export type BeneficialOwnerDataForm = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export function validate(
  legalEntityData: BeneficialOwnerDataForm,
  additionalPersonsCompanyEquityPercentage: number
): any {
  const t = (k: any) => k;
  return validationSchema(
    t,
    additionalPersonsCompanyEquityPercentage
  ).validateSync(legalEntityData);
}
