import { makeStyles } from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import React from "react";
import {
  Box,
  Icon,
  useViewportSize,
  Text,
  Heading,
  Stack,
  Grid,
} from "@finvia/money-ui";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    padding: 16,
  },
  popoverPaper: {
    borderRadius: 0,
    overflowX: "unset",
    overflowY: "unset",
    // This creates the arrow
    "&::before": {
      content: '""',
      position: "absolute",
      marginRight: "-0.71em",
      top: -8,
      right: 13,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      transform: "translate(-50%, 50%) rotate(135deg)",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) 50px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
}));

const CenteredGrid = styled(Grid)`
  align-items: center;
`;

const IconCircle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.tokens.colors.petrol[600]};
  cursor: pointer;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: ${({ theme }) => theme.tokens.colors.petrol[500]};
  }
`;

const PopoverContent = styled(Stack)`
  padding: 16px;
`;

export type UserProps = {
  actionLabel: string;
  username: string | undefined | null;
};

const User: React.FC<UserProps> = ({ username, actionLabel }) => {
  const { logout, user } = useAuth0();
  const classes = useStyles();
  const viewPortSize = useViewportSize();
  const smUp = viewPortSize !== "sm";

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const onClickUserIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    if (window?.posthog?.reset && _.isFunction(window.posthog.reset)) {
      window.posthog.reset();
    }

    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  return (
    <Box>
      <CenteredGrid columns={{ sm: "30px 1fr" }} gap="mega">
        <IconCircle onClick={onClickUserIcon}>
          <Icon name="user" size="bit" color="white.500" />
        </IconCircle>
        <Popover
          id={open ? "user-popover" : undefined}
          open={open}
          anchorEl={anchorEl}
          onClose={onClosePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{ paper: classes.popoverPaper }}
        >
          <PopoverContent gutter="bit">
            <Heading font="sans" size={3}>
              {username ?? user?.name}
            </Heading>
            <Text size={3}>
              <Box onClick={onLogoutClick}>
                <u>{actionLabel}</u>
              </Box>
            </Text>
          </PopoverContent>
        </Popover>

        {smUp && (
          <Stack gutter="bit">
            <Heading font="sans" size={2}>
              {username ?? user?.name}
            </Heading>
            <Text size={2}>
              <Box onClick={onLogoutClick}>
                <u>{actionLabel}</u>
              </Box>
            </Text>
          </Stack>
        )}
      </CenteredGrid>
    </Box>
  );
};

export default User;
