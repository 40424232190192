import { TFunction } from "i18next";
import * as yup from "yup";

const translationPrefix = "q-ai.opportunities.form.bankAccounts";

const BankAccounts = (t: TFunction) =>
  yup.object({
    accountHolderFirstName: yup
      .string()
      .required(
        t(
          `${translationPrefix}.fields.bankAccounts.accountHolderFirstName.required`
        )
      )
      .label(
        t(
          `${translationPrefix}.fields.bankAccounts.accountHolderFirstName.label`
        )
      ),
    accountHolderFamilyName: yup
      .string()
      .required(
        t(
          `${translationPrefix}.fields.bankAccounts.accountHolderFamilyName.required`
        )
      )
      .label(
        t(
          `${translationPrefix}.fields.bankAccounts.accountHolderFamilyName.label`
        )
      ),
    iban: yup
      .string()
      .min(15, t(`${translationPrefix}.fields.bankAccounts.iban.charLength`))
      .required(t(`${translationPrefix}.fields.bankAccounts.iban.required`))
      .label(t(`${translationPrefix}.fields.bankAccounts.iban.label`)),
    bic: yup
      .string()
      .test(
        "len",
        t(`${translationPrefix}.fields.bankAccounts.bic.charLength`),
        (value) =>
          (value?.length && value?.length >= 8 && value?.length <= 11) || false
      )
      .required(t(`${translationPrefix}.fields.bankAccounts.bic.required`))
      .label(t(`${translationPrefix}.fields.bankAccounts.bic.label`)),
    nameOfBank: yup
      .string()
      .label(t(`${translationPrefix}.fields.bankAccounts.nameOfBank.label`)),
    cityOfBank: yup
      .string()
      .label(t(`${translationPrefix}.fields.bankAccounts.cityOfBank.label`)),
    usdCheckbox: yup.boolean(),
    usdAccountHolderFirstName: yup
      .string()
      .when("usdCheckbox", {
        is: true,
        then: yup
          .string()
          .required(
            t(
              `${translationPrefix}.fields.bankAccounts.usdAccountHolderFirstName.required`
            )
          ),
      })
      .label(
        t(
          `${translationPrefix}.fields.bankAccounts.usdAccountHolderFirstName.label`
        )
      ),
    usdAccountHolderFamilyName: yup
      .string()
      .when("usdCheckbox", {
        is: true,
        then: yup
          .string()
          .required(
            t(
              `${translationPrefix}.fields.bankAccounts.usdAccountHolderFamilyName.required`
            )
          ),
      })
      .label(
        t(
          `${translationPrefix}.fields.bankAccounts.usdAccountHolderFamilyName.label`
        )
      ),
    usdIban: yup
      .string()
      .when("usdCheckbox", {
        is: true,
        then: yup
          .string()
          .min(
            15,
            t(`${translationPrefix}.fields.bankAccounts.usdIban.charLength`)
          )
          .required(
            t(`${translationPrefix}.fields.bankAccounts.usdIban.required`)
          ),
      })
      .label(t(`${translationPrefix}.fields.bankAccounts.usdIban.label`)),
    usdBic: yup
      .string()
      .when("usdCheckbox", {
        is: true,
        then: yup
          .string()
          .test(
            "len",
            t(`${translationPrefix}.fields.bankAccounts.usdBic.charLength`),
            (value) =>
              (value?.length && value?.length >= 8 && value?.length <= 11) ||
              false
          )
          .required(
            t(`${translationPrefix}.fields.bankAccounts.usdBic.required`)
          ),
      })
      .label(t(`${translationPrefix}.fields.bankAccounts.usdBic.label`)),
    usdNameOfBank: yup
      .string()
      .when("usdCheckbox", {
        is: true,
        then: yup.string(),
      })
      .label(t(`${translationPrefix}.fields.bankAccounts.usdNameOfBank.label`)),
    usdCityOfBank: yup
      .string()
      .when("usdCheckbox", {
        is: true,
        then: yup.string(),
      })
      .label(t(`${translationPrefix}.fields.bankAccounts.usdCityOfBank.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      bankAccounts: BankAccounts(t),
    })
    .required();
