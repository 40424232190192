import styled from "styled-components";

export const ExtraFieldWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 20px;
`;

export const RemoveIconWrapper = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 26px;
`;

export const FullRow = styled.div`
  grid-column: 1 / -1;
`;
