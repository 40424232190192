import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import bulletPoint from "../../../../assets/icons/bulletpoint.svg";
import {
  FundFragment_attributes_highlights,
  FundFragment_attributes_highlightVideo,
} from "../../../../generated/FundFragment";
import { Heading, Text, Stack } from "@finvia/money-ui";
import { VideoHighlight } from "./VideoHighlight";

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "40px",
    "& li": {
      paddingLeft: "25px",
      position: "relative",
    },
    "& li::before": {
      content: `""`,
      position: "absolute",
      top: "2px",
      left: 0,
      height: "16px",
      width: "16px",
      background: `url(${bulletPoint}) no-repeat center`,
      backgroundSize: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "initial",
      gap: "32px",
      "& li:nth-child(2n + 1)": {
        marginRight: "0px",
      },
      "& li:nth-child(2n)": {
        marginLeft: "0px",
      },
    },
  },
}));

interface Props {
  highlights: FundFragment_attributes_highlights[];
  highlightVideo: FundFragment_attributes_highlightVideo | null;
}

const Highlights: React.FC<Props> = ({ highlights = [], highlightVideo }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stack gutter="tera">
      <VideoHighlight videoContent={highlightVideo} />
      <Heading as="h2" size={{ sm: 5, lg: 6 }}>
        {t("components.alternativeInvestmentFund.highlights.title")}
      </Heading>
      <ul className={classes.list}>
        {highlights.map((item) => (
          <li key={item.id}>
            <Text>{item.text}</Text>
          </li>
        ))}
      </ul>
    </Stack>
  );
};

export default Highlights;
