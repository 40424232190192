enum Positions {
  beforebegin = "beforebegin",
  afterbegin = "afterbegin",
  beforeend = "beforeend",
  afterend = "afterend",
}

type InsertPosition = keyof typeof Positions;

type Script = {
  name: string;
  src: string;
  async?: boolean;
  position?: InsertPosition;
  attributes?: {
    [key: string]: string | undefined;
  };
};

export type ScriptConfig = Script[];

const setAttributes = (
  attributes: Script["attributes"],
  element: HTMLScriptElement
): void => {
  if (!attributes) {
    return;
  }

  Object.keys(attributes).forEach((attribute) => {
    const attributeValue = attributes[attribute];

    if (!attributeValue) {
      return;
    }

    element.setAttribute(attribute, attributeValue);
  });
};

export const createScript = ({
  name,
  src,
  async,
  position = Positions.beforeend,
  attributes,
}: Script): HTMLScriptElement => {
  const tag = document.createElement("script");

  if (async) {
    tag.async = async;
  }

  tag.src = src;

  setAttributes(attributes, tag);

  tag.setAttribute("data-injected-name", name);
  tag.setAttribute("data-position", position);

  return tag;
};

export const createScriptList = (config: ScriptConfig): Element[] =>
  config.map((script) => createScript(script));

const scriptInjection = (config: ScriptConfig): void =>
  createScriptList(config).forEach((script) =>
    document.body.insertAdjacentElement(
      script.getAttribute("data-position") as InsertPosition,
      script
    )
  );

export default scriptInjection;
