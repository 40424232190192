import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LegalRepresentationType as LegalRepresentationEnum } from "../../../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../../../store";
import { FundDocumentsDisplay } from "../../../../../../funds/components/FundDocumentsDisplay";
import { Text, Stack, ChoiceGroup, Grid, Box } from "@finvia/money-ui";
import TooltipInfo from "features/alternative-investments/components/TooltipInfo";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const LegalRepresentative = (): JSX.Element => {
  const { t } = useTranslation();
  const { watch, register } = useFormContext();

  const powerOfAttorney = typedUseSelector((state) => {
    return state.alternativeInvestments.regulatoryData.legalEntityData
      .powerOfAttorney;
  });

  const legalRepresentationType = watch("legalRepresentationType");

  return (
    <Stack gutter="mega">
      <Stack direction="row" alignItems="center" gutter="byte">
        <Box width="100%">
          <Stack direction="row" gutter="bit">
            <Text>
              {t(`${translationPrefix}.fields.legalRepresentationType.label`)}
            </Text>
            <TooltipInfo
              label={t(
                `${translationPrefix}.fields.legalRepresentationType.tooltip`
              )}
            />
          </Stack>
        </Box>

        <ChoiceGroup
          required
          name="legalRepresentationType"
          label={t(`${translationPrefix}.fields.legalRepresentationType.label`)}
          hideLabel
        >
          <Grid columns={{ sm: 1, md: 2 }} gap="mega">
            <ChoiceGroup.Radio
              ref={register}
              name="legalRepresentationType"
              value={LegalRepresentationEnum.SOLE}
              label={t(
                `${translationPrefix}.fields.legalRepresentationType.values.SOLE`
              )}
            />
            <ChoiceGroup.Radio
              ref={register}
              name="legalRepresentationType"
              value={LegalRepresentationEnum.JOINT}
              label={t(
                `${translationPrefix}.fields.legalRepresentationType.values.JOINT`
              )}
            />
          </Grid>
        </ChoiceGroup>
      </Stack>

      {legalRepresentationType === LegalRepresentationEnum.JOINT && (
        <FundDocumentsDisplay
          documents={[
            {
              id: t(
                `${translationPrefix}.fields.legalRepresentationType.download.downloadName`
              ).split(".")[0],
              name: "hasReceivedProcurationDocument",
              displayName: t(
                `${translationPrefix}.fields.legalRepresentationType.download.displayName`
              ),
              downloadName: t(
                `${translationPrefix}.fields.legalRepresentationType.download.downloadName`
              ),
              asyncDocument: true,
              mimetype: "application/pdf",
              url: powerOfAttorney?.url || undefined,
            },
          ]}
          additionalText={t(
            `${translationPrefix}.fields.legalRepresentationType.download.additionalText`
          )}
        />
      )}
    </Stack>
  );
};

export default LegalRepresentative;
