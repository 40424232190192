import { typedUseSelector } from "store";
import { AddPersonsPageProps, Persons } from "./AddPersonsPage.types";
import { buildPersons } from "./AddPersonsPage.utils";
import { AdditionalPersonProps } from ".";

export const usePersons = (): [Persons, boolean] => {
  const [me, additionalPeople] = typedUseSelector(
    ({ alternativeInvestments: { regulatoryData } }) => {
      const {
        isLegalRepresentative,
        isBeneficialOwner,
        legalEntityAdditionalPersons,
      } = regulatoryData?.legalEntityData || {};
      const { personalData } = regulatoryData?.personData;

      return [
        {
          id: "me",
          name: `${personalData?.firstName} ${personalData?.familyName}`,
          isLegalRepresentative: isLegalRepresentative === "yes",
          isBeneficialOwner: isBeneficialOwner === "yes",
        },
        legalEntityAdditionalPersons,
      ];
    }
  );

  const additionalPersonsList = buildPersons(
    additionalPeople as Array<AdditionalPersonProps>
  );

  const hasMoreThanOneLR = [me, ...additionalPersonsList].some(
    (data, index) => index !== 0 && data.isLegalRepresentative
  );

  return [[me, ...additionalPersonsList], hasMoreThanOneLR];
};

export const useDefaultData = (): AddPersonsPageProps =>
  typedUseSelector((store) => {
    const { legalRepresentationType, hasReceivedProcurationDocument } =
      store.alternativeInvestments.regulatoryData?.legalEntityData || {};

    return {
      legalRepresentationType,
      hasReceivedProcurationDocument,
    };
  });
