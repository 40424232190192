import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingWrapper from "../../../../components/LoadingWrapper/LoadingWrapper";
import FundDetails from "../../components/FundDetails/FundDetails";
import { InvestmentOpportunityFormStep } from "../../formSteps";
import { ROUTES } from "../../../../routes/routes";
import { getFundBySlug, getFundsCollection } from "../actions";
import { fundBySlug } from "../slice";
import { Card, Heading, Icon, Stack } from "@finvia/money-ui";

const FundDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { fund, funds } = useSelector((state) => {
    return {
      fund: fundBySlug(
        state.alternativeInvestments.alternativeInvestmentFunds.funds,
        slug
      ),
      funds: state.alternativeInvestments.alternativeInvestmentFunds.funds,
    };
  });

  useEffect(() => {
    dispatch(getFundBySlug({ slug }));
  }, [dispatch, slug]);

  useEffect(() => {
    if (funds?.ids.length >= 1) {
      setLoading(false);
      return;
    }

    dispatch(getFundsCollection());
  }, [dispatch, funds?.ids.length]);

  return (
    <LoadingWrapper
      title={t("loadingScreen.title")}
      message={t("loadingScreen.message")}
      isLoading={loading}
    >
      {fund ? (
        <FundDetails
          fund={fund}
          funds={funds}
          returnLink={`${ROUTES.questionnaires.ai.opportunities}/${InvestmentOpportunityFormStep.Opportunities}`}
        />
      ) : (
        <Card
          paddingTopBottom={{ sm: "giga", md: "peta" }}
          paddingLeftRight={{ sm: "kilo", md: "peta" }}
        >
          <Stack gutter="mega" alignItems="center">
            <Icon color="red.500" size="peta" name="error" />
            <Heading>404</Heading>
            <Heading as="h2" size={6} font="sans">
              {t("components.alternativeInvestmentFund.notFound")}
            </Heading>
          </Stack>
        </Card>
      )}
    </LoadingWrapper>
  );
};

export default FundDetailsPage;
