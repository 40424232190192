import { useTranslation } from "react-i18next";
import ResidencyFields from "./ResidencyFields";
import { Heading, Stack } from "@finvia/money-ui";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const TaxInformation = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack gutter="mega">
      <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
        {t(`${translationPrefix}.taxLabel`)}
      </Heading>
      <ResidencyFields />
    </Stack>
  );
};

export default TaxInformation;
