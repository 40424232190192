import { gql } from "@apollo/client";

export const GET_NATURAL_PERSON_IDENTIFICATION_STATUS = gql`
  query GetNaturalPersonIdentificationStatus {
    naturalPersonIdentificationStatus
  }
`;

export const GET_LEGAL_ENTITY_IDENTIFICATION_STATUS = gql`
  query GetLegalEntityIdentificationStatus {
    legalEntityIdentificationStatus {
      legalRepresentative {
        id
        url
        status
      }
      additionalLegalRepresentatives {
        id
        url
        status
      }
    }
  }
`;
