import { ChoiceGroup, Grid, Box, Text, InfoBox } from "@finvia/money-ui";
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import { validationSchema, LegalRepresentativeDataForm } from ".";

const translationPrefix = "q-ai.opportunities.form.legalRepresentative";

const LegalRepresentativeForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="people"
          iconSize="mega"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityRepresentative.title")}
          description={t("sidebar.legalEntityRepresentative.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<LegalRepresentativeDataForm>(
      {
        isLegalRepresentative:
          state.alternativeInvestments.regulatoryData?.legalEntityData
            ?.isLegalRepresentative,
      },
      {
        isLegalRepresentative: undefined,
      }
    )
  );

  const dispatch = useDispatch();
  const onSubmit = async (legalEntityData: LegalRepresentativeDataForm) => {
    if (legalEntityData.isLegalRepresentative === "no") {
      return;
    }

    dispatch(updateLegalEntityProfile(legalEntityData))
      .then(unwrapResult)
      .then(() => {
        nextStep();
      })
      .catch((e: SerializedError) => {
        setError(e);
      });
  };

  return (
    <FormControl<LegalRepresentativeDataForm>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={validationSchema(t)}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      dataAnalytics="2.2_le_legal_representative"
    >
      {({ formState, register, watch }) => {
        const isNotLegalRepresentative =
          watch("isLegalRepresentative") === "no";

        const email = "service@finvia.fo";

        return (
          <>
            <FormContent
              title={t(`${translationPrefix}.title`)}
              subTitle={t(`${translationPrefix}.subTitle`)}
              error={error}
              isDirty={formState.isDirty}
              isSubmitSuccessful={formState.isSubmitSuccessful}
              disableDisclaimer
            >
              <ChoiceGroup
                name="isLegalRepresentative"
                label={t(`${translationPrefix}.subTitle`)}
                required
                hideLabel
              >
                <Box width="100%" display="flex" justifyContent="center">
                  <Box width={{ sm: "100%", md: "50%" }}>
                    <Grid
                      columns={{ sm: 1, md: 2 }}
                      justifyItems="center"
                      gap={{ sm: "mega", md: "peta" }}
                    >
                      <ChoiceGroup.Button
                        label={t(
                          `${translationPrefix}.fields.legalEntityData.isRepresentative.yes`
                        )}
                        value="yes"
                        ref={register}
                      />
                      <ChoiceGroup.Button
                        label={t(
                          `${translationPrefix}.fields.legalEntityData.isRepresentative.no`
                        )}
                        value="no"
                        ref={register}
                      />
                    </Grid>
                  </Box>
                </Box>
              </ChoiceGroup>
            </FormContent>

            {isNotLegalRepresentative && (
              <InfoBox variant="danger">
                <Text size={2} variant="error">
                  <Trans
                    i18nKey={`${translationPrefix}.fields.legalEntityData.isRepresentative.validations.mustBeLegalRepresentative`}
                    values={{ email }}
                    components={{
                      Link: <a href={`mailto: ${email}`}>{email}</a>,
                    }}
                  />
                </Text>
              </InfoBox>
            )}
          </>
        );
      }}
    </FormControl>
  );
};

export default LegalRepresentativeForm;
