import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { get } from "lodash";
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  Heading,
  Text,
  Grid,
  Checkbox,
  Stack,
  SelectInput,
  Box,
  TextAreaInput,
} from "@finvia/money-ui";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import {
  MoneyRangeLarge,
  WealthOrigin,
  WealthRestrictionType,
} from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";

import { buildOptions } from "utils/options/buildObject";
import { WealthAssessmentProps, FormData } from "./WealthAssessment.types";
import {
  cleanUpDataFromUnselectedCheckboxes,
  wealthAssessmentCheckboxNames,
} from "./WealthAssessment.utils";
import { validationSchema } from "./WealthAssessment.schema";
import { MonetaryInput } from "../../MonetaryInput";

const translationPrefix = "q-ai.opportunities.form.wealthAssessment";

const WealthAssessment: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const netWorthLang = `${translationPrefix}.fields.netWorth`;

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="info"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.sensitiveDataExplanation.title")}
          description={t("sidebar.sensitiveDataExplanation.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) => {
    const netWorth =
      state.alternativeInvestments.regulatoryData?.personData?.regulatoryInfo
        ?.netWorth;

    return formDefaultsDeep<FormData>(
      {
        netWorth,
        wealthOrigin:
          state.alternativeInvestments.regulatoryData?.personData
            ?.regulatoryInfo?.wealthOrigin,
        wealthOriginDetail:
          state.alternativeInvestments.regulatoryData?.personData
            ?.regulatoryInfo?.wealthOriginDetail,
      },
      {
        netWorth: {
          total: {
            range: "",
            value: "",
          },
          cash: {
            checkbox: !!netWorth?.cash?.range,
            range: "",
            value: "",
          },
          securities: {
            checkbox: !!netWorth?.securities?.range,
            range: "",
            value: "",
          },
          selfUsedProperties: {
            checkbox: !!netWorth?.selfUsedProperties?.range,
            range: "",
            value: "",
          },
          realEstate: {
            checkbox: !!netWorth?.realEstate?.range,
            range: "",
            value: "",
          },
          movableProperty: {
            checkbox: !!netWorth?.movableProperty?.range,
            range: "",
            value: "",
          },
          insuranceClaims: {
            checkbox: !!netWorth?.insuranceClaims?.range,
            range: "",
            value: "",
          },
          other: {
            checkbox: !!netWorth?.other?.range,
            range: "",
            value: "",
          },
          restrictions: {
            type: "",
            value: "",
          },
        },
        wealthOrigin: "",
        wealthOriginDetail: "",
      }
    );
  });

  const onSubmit = useCallback(
    async (data: WealthAssessmentProps) => {
      const cleanedUpData = {
        ...data,
        netWorth: cleanUpDataFromUnselectedCheckboxes(data.netWorth),
      };

      dispatch(updateProfile({ regulatoryInfo: cleanedUpData }))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const restrictionsPrefix = `${translationPrefix}.fields.netWorth.restrictions`;
  const totalRangePrefix = `${translationPrefix}.fields.netWorth.total.range`;
  const wealthOriginPrefix = `${translationPrefix}.fields.wealthOrigin`;

  return (
    <FormControl<WealthAssessmentProps>
      onSubmit={onSubmit}
      onBack={previousStep}
      defaultValues={defaultData}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dontShowErrors
      dataAnalytics="2.5_np_wealth_assessment"
    >
      {(formMethods) => {
        const { watch, errors, trigger } = formMethods;
        const watchTotal = watch(
          "netWorth.total.range",
          defaultData?.netWorth?.total?.range
        );
        const watchRestrictions = watch(
          "netWorth.restrictions.type",
          defaultData?.netWorth?.restrictions?.type
        );
        const watchWealthOrigin =
          watch(`wealthOrigin`) ?? defaultData?.wealthOrigin;

        const rangeTotalIsLargerThan1000000 =
          watchTotal === MoneyRangeLarge.MORE_THAN_1000000;

        const hasNoRestriction =
          watchRestrictions !== WealthRestrictionType.NO_RESTRICTIONS;

        if (
          rangeTotalIsLargerThan1000000 &&
          defaultData?.netWorth?.total?.value
        ) {
          trigger("netWorth.total.value");
        }

        if (hasNoRestriction && defaultData?.netWorth?.restrictions?.value) {
          trigger("netWorth.restrictions.value");
        }

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Stack gutter="mega">
              <Stack gutter="mega">
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.headline`)}
                </Heading>

                <Text>{t(`${translationPrefix}.financialAssets`)}</Text>
              </Stack>
              <Stack gutter="mega">
                {wealthAssessmentCheckboxNames.map((checkbox) => {
                  const checkboxName = `netWorth.${checkbox}.checkbox`;
                  const watchCheckbox = watch(
                    checkboxName,
                    !!defaultData.netWorth?.[checkbox]?.range
                  );
                  const watchRange = watch(
                    `netWorth.${checkbox}.range`,
                    defaultData.netWorth?.[checkbox]?.range
                  );

                  const checkboxRangeIsGreaterThan1000000 =
                    watchRange === MoneyRangeLarge.MORE_THAN_1000000;

                  const tooltip = t(`${netWorthLang}.${checkbox}.tooltip`, {
                    defaultValue: undefined,
                  });

                  if (
                    checkboxRangeIsGreaterThan1000000 &&
                    defaultData?.netWorth?.[checkbox]?.value
                  ) {
                    trigger(`netWorth.${checkbox}.value`);
                  }

                  return (
                    <Box width="100%" key={`${checkbox}-key`}>
                      <Stack gutter="mega">
                        <Checkbox
                          key={checkbox}
                          name={checkboxName}
                          label={t(`${netWorthLang}.${checkbox}.range.label`)}
                          ref={formMethods.register}
                          helperText={tooltip}
                        />

                        {watchCheckbox && (
                          <Stack
                            gutter="mega"
                            direction={{ sm: "column", md: "row" }}
                            justifyContent="space-between"
                          >
                            <Box width={{ sm: "100%", md: "45%" }}>
                              <SelectInput
                                options={buildOptions<unknown>({
                                  lang: i18n,
                                  key: `${netWorthLang}.${checkbox}.range.values`,
                                })}
                                name={`netWorth.${checkbox}.range`}
                                label={t(
                                  `${netWorthLang}.${checkbox}.range.label`
                                )}
                                value={defaultData.netWorth?.[checkbox]?.range}
                                required
                                ref={formMethods.register}
                              />
                            </Box>

                            {checkboxRangeIsGreaterThan1000000 && (
                              <Box width={{ sm: "100%", md: "45%" }}>
                                <MonetaryInput
                                  ref={formMethods.register}
                                  name={`netWorth.${checkbox}.value`}
                                  label={t(
                                    `${netWorthLang}.${checkbox}.value.label`
                                  )}
                                  errorMessage={
                                    formMethods.errors?.netWorth?.[checkbox]
                                      ?.value?.message ?? undefined
                                  }
                                />
                              </Box>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </Box>
                  );
                })}
              </Stack>
            </Stack>

            <Stack gutter="mega">
              {errors && (
                <Text variant="error" size="1">
                  {get(errors, "netWorth.networthCheckboxGroup.message")}
                </Text>
              )}

              <Stack gutter="mega">
                <Stack
                  direction={{ sm: "column", md: "row" }}
                  justifyContent="space-between"
                >
                  <Box width={{ sm: "100%", md: "45%" }}>
                    <SelectInput
                      options={buildOptions<WealthRestrictionType>({
                        lang: i18n,
                        key: `${translationPrefix}.fields.netWorth.restrictions.type.values`,
                      })}
                      name="netWorth.restrictions.type"
                      label={t(`${restrictionsPrefix}.type.label`)}
                      value={defaultData?.netWorth?.restrictions?.type}
                      helperText={t(`${restrictionsPrefix}.tooltip`)}
                      required
                      ref={formMethods.register}
                    />
                  </Box>

                  {watchRestrictions && hasNoRestriction && (
                    <Box width={{ sm: "100%", md: "45%" }}>
                      <MonetaryInput
                        ref={formMethods.register}
                        name={`netWorth.restrictions.value`}
                        label={t(`${netWorthLang}.restrictions.value.label`)}
                        errorMessage={
                          formMethods.errors?.netWorth?.restrictions?.value
                            ?.message ?? undefined
                        }
                      />
                    </Box>
                  )}
                </Stack>

                <Stack
                  direction={{ sm: "column", md: "row" }}
                  justifyContent="space-between"
                >
                  <Box width={{ sm: "100%", md: "45%" }}>
                    <SelectInput
                      options={buildOptions<MoneyRangeLarge>({
                        lang: i18n,
                        key: `${translationPrefix}.fields.netWorth.total.range.values`,
                      })}
                      name="netWorth.total.range"
                      label={t(`${totalRangePrefix}.label`)}
                      value={defaultData?.netWorth?.total?.range}
                      helperText={t(`${totalRangePrefix}.tooltip`)}
                      required
                      ref={formMethods.register}
                    />
                  </Box>

                  {rangeTotalIsLargerThan1000000 && (
                    <Box width={{ sm: "100%", md: "45%" }}>
                      <MonetaryInput
                        ref={formMethods.register}
                        name={`netWorth.total.value`}
                        label={t(`${netWorthLang}.total.value.label`)}
                        errorMessage={
                          formMethods.errors?.netWorth?.total?.value?.message ??
                          undefined
                        }
                      />
                    </Box>
                  )}
                </Stack>

                <Box width={{ sm: "100%", md: "45%" }}>
                  <SelectInput
                    options={buildOptions<WealthOrigin>({
                      lang: i18n,
                      key: `${translationPrefix}.fields.wealthOrigin.values`,
                    })}
                    name="wealthOrigin"
                    label={t(`${wealthOriginPrefix}.label`)}
                    value={defaultData?.wealthOrigin}
                    helperText={t(`${wealthOriginPrefix}.tooltip`)}
                    required
                    ref={formMethods.register}
                  />
                </Box>
                <Box width="100%">
                  {watchWealthOrigin && (
                    <Grid gap="giga" columns={1}>
                      <TextAreaInput
                        required
                        crossOrigin
                        ref={formMethods.register}
                        name="wealthOriginDetail"
                        label={t(
                          `${wealthOriginPrefix}.wealthOriginDescription.${watchWealthOrigin}.label`
                        )}
                        placeholder={t(
                          `${wealthOriginPrefix}.wealthOriginDescription.${watchWealthOrigin}.example`
                        )}
                        helperText={t(
                          `${wealthOriginPrefix}.wealthOriginDescription.${watchWealthOrigin}.descriptiveText`
                        )}
                        defaultValue={defaultData?.wealthOriginDetail}
                      />
                    </Grid>
                  )}
                </Box>
              </Stack>
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default WealthAssessment;
