import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import {
  AccumulatedIdentificationStatus,
  ContractType,
  LegalEntityIdentificationStatus,
} from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";

import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { upsertAlternativeInvestmentsMandateContract } from "../../actions";
import { ROUTES } from "../../../../../../routes/routes";
import { Checkbox, Stack, Text, Modal, Box } from "@finvia/money-ui";

import { AdditionalText, CheckboxWrapper } from "./Declarations.styled";
import { DeclarationProps, DeclarationKeys, validationSchema } from ".";

const translationPrefix = "q-ai.opportunities.form.declaration";

const Declaration: React.FC<StepComponentProps> = ({
  previousStep,
  nextStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultData = typedUseSelector((state) => {
    return formDefaultsDeep<DeclarationProps>(
      {
        declaration:
          state.alternativeInvestments.mandateContractData.data.naturalPerson
            .declaration,
      },
      {
        declaration: {
          hasReceivedBasicInformationOnInvestments: false,
          isActingOnOwnBehalf: false,
          hasConfirmedInformationCorrectAndComplete: false,
          hasAcknowledgedSustainabilityClause: false,
        },
      }
    );
  });

  const isIdentificationSuccessful = typedUseSelector(
    ({ alternativeInvestments: { mandateContractData } }) => {
      const {
        data: { legalEntity },
      } = mandateContractData;

      const hasIdentificationStatus =
        legalEntity.legalEntityIdentificationStatus ===
          LegalEntityIdentificationStatus.IDENTIFICATION_SUCCESSFUL ||
        legalEntity.legalEntityIdentificationStatus ===
          LegalEntityIdentificationStatus.CREATED;

      if (
        hasIdentificationStatus ||
        legalEntity.accumulatedIdentificationStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL
      ) {
        return true;
      }

      return false;
    }
  );

  const contractType = typedUseSelector(
    (state) =>
      state.alternativeInvestments.regulatoryData.personData.contractType
  );

  const onSubmit = useCallback(
    async (data: DeclarationProps) => {
      dispatch(upsertAlternativeInvestmentsMandateContract(data))
        .then(unwrapResult)
        .then(() => {
          if (contractType === ContractType.NATURAL_PERSON) {
            nextStep();
            return;
          }

          if (isIdentificationSuccessful) {
            nextStep();
          } else {
            history.push(ROUTES.questionnaires.ai.mandate.identificationLoader);
          }
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, history, contractType, nextStep, isIdentificationSuccessful]
  );

  const fields: Array<DeclarationKeys> = [
    "hasReceivedBasicInformationOnInvestments",
    "isActingOnOwnBehalf",
    "hasConfirmedInformationCorrectAndComplete",
    "hasAcknowledgedSustainabilityClause",
  ];

  const modalText = t(
    `${translationPrefix}.checkboxes.hasAcknowledgedSustainabilityClause.modal.cta`
  );

  const modalParagraphs: string[] = t(
    `${translationPrefix}.checkboxes.hasAcknowledgedSustainabilityClause.modal.paragraphs`,
    { returnObjects: true }
  );

  return (
    <>
      <Modal
        showCloseIcon
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        id="sustainability-preferences-modal"
      >
        <Box width={{ sm: "100%", md: "70vh" }}>
          <Stack gutter="mega">
            {modalParagraphs.map((p) => (
              <Text color="neutral.750">{p}</Text>
            ))}
          </Stack>
        </Box>
      </Modal>

      <FormControl<DeclarationProps>
        onBack={previousStep}
        dontShowErrors
        defaultValues={defaultData}
        onSubmit={onSubmit}
        validationSchema={memoizedSchema}
        submitLabel={t("onboarding.form.continue")}
        backLabel={t("onboarding.form.back")}
        validationMode="onChange"
        reValidationMode="onChange"
        dataAnalytics={`3.1_${
          contractType === ContractType.LEGAL_ENTITY ? "le" : "np"
        }_declaration_mandate_agreement`}
      >
        {(formMethods) => {
          return (
            <FormContent
              title={t(`${translationPrefix}.title`)}
              subTitle={t(`${translationPrefix}.description`)}
              error={error}
              isDirty={formMethods.formState.isDirty}
              isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            >
              {fields.map((field) => {
                return (
                  <CheckboxWrapper key={field}>
                    <Stack>
                      <Checkbox
                        ref={formMethods.register}
                        name={`declaration.${field}`}
                        label={t(
                          `${translationPrefix}.checkboxes.${field}.label`
                        )}
                      />

                      <AdditionalText>
                        <Stack>
                          <Text>
                            {field === "hasAcknowledgedSustainabilityClause" ? (
                              <Trans
                                i18nKey={`${translationPrefix}.checkboxes.hasAcknowledgedSustainabilityClause.description`}
                                values={{ modalText }}
                                components={{
                                  Link: (
                                    <a
                                      href="#sustainability-preferences-modal"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setIsOpen(true);
                                      }}
                                    >
                                      {modalText}
                                    </a>
                                  ),
                                }}
                              />
                            ) : (
                              t(
                                `${translationPrefix}.checkboxes.${field}.description`
                              )
                            )}
                          </Text>

                          {formMethods.errors.declaration?.[field] && (
                            <Text size={1} variant="error">
                              {formMethods.errors.declaration[field]?.message}
                            </Text>
                          )}
                        </Stack>
                      </AdditionalText>
                    </Stack>
                  </CheckboxWrapper>
                );
              })}
            </FormContent>
          );
        }}
      </FormControl>
    </>
  );
};

export default Declaration;
