import { gql } from "@apollo/client";
import {
  Address,
  AssetClassExperience,
  Document,
  LegalEntityBankAccount,
} from "../fragments/Fragments";
import NaturalPersonRegulatoryData from "../fragments/NaturalPersonRegulatoryData";

export const LegalEntitiesQuery = gql`
  ${Address}
  ${LegalEntityBankAccount}
  ${Document}
  ${AssetClassExperience}
  ${NaturalPersonRegulatoryData}
  query LegalEntitiesQuery {
    # profile {
    #   contracts {
    #     aiMandate {
    #       contractType
    #     }
    #   }
    #   personalData {
    #     salutation
    #     professionalTitle
    #     firstName
    #     familyName
    #     birthName
    #     dateOfBirth
    #     cityOfBirth
    #     countryOfBirth
    #     primaryCitizenship
    #     additionalCitizenships
    #     isThirdCountryNational
    #   }
    #   regulatoryInfo {
    #     # pep {
    #     #   relationship
    #     #   role
    #     #   status
    #     # }
    #     # taxInfo {
    #     #   fatca {
    #     #     status
    #     #     irsDocuments {
    #     #       id
    #     #       filename
    #     #       mimetype
    #     #     }
    #     #   }
    #     #   residencies {
    #     #     country
    #     #     taxId
    #     #     localTaxOffice
    #     #   }
    #     # }
    #     # netWorth {
    #     #   total {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   cash {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   securities {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   selfUsedProperties {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   realEstate {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   movableProperty {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   insuranceClaims {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   other {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   restrictions {
    #     #     type
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     # }
    #     # wealthOrigin
    #     # liabilities {
    #     #   annualLiabilities {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   rent {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   living {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   loan {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   alimony {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   insurance {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     # }
    #     # availableMonthlyLiquidity {
    #     #   range
    #     #   value {
    #     #     currency
    #     #     value
    #     #   }
    #     # }
    #     # savings {
    #     #   areMinimumShortTermReservesRequired
    #     #   minimumShortTermReserve {
    #     #     currency
    #     #     value
    #     #   }
    #     #   date
    #     # }
    #     # income {
    #     #   employment {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   selfEmployment {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   renting {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   capitalGains {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     #   other {
    #     #     range
    #     #     value {
    #     #       currency
    #     #       value
    #     #     }
    #     #   }
    #     # }
    #     experience {
    #       # consultingFreeOrderExecution
    #       # investmentConsulting
    #       # wealthManagement
    #       # currencies {
    #       #   foreignCurrencies
    #       #   loanBasedForeignCurrencies
    #       # }
    #       assetClasses {
    #         privateEquity {
    #           ...AssetClassExperience
    #         }
    #         privateDebt {
    #           ...AssetClassExperience
    #         }
    #         realEstate {
    #           ...AssetClassExperience
    #         }
    #         infrastructure {
    #           ...AssetClassExperience
    #         }
    #         securities {
    #           ...AssetClassExperience
    #         }
    #         bonds {
    #           ...AssetClassExperience
    #         }
    #         gold {
    #           ...AssetClassExperience
    #         }
    #         moneyMarket {
    #           ...AssetClassExperience
    #         }
    #         derivatives {
    #           ...AssetClassExperience
    #         }
    #         other {
    #           ...AssetClassExperience
    #         }
    #         currencyFutures {
    #           ...AssetClassExperience
    #         }
    #         hedgeFunds {
    #           ...AssetClassExperience
    #         }
    #         certificates {
    #           ...AssetClassExperience
    #         }
    #       }
    #     }
    #   }
    # }
    profile {
      ...NaturalPersonRegulatoryData
    }
    alternativeInvestmentMandateContract {
      data {
        attributes {
          powerOfAttorney {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    legalEntity {
      id
      isLegalRepresentative
      isBeneficialOwner
      companyEquityPercentage
      functionaryRole
      countryOfOperations
      registration {
        businessPurpose
        companyName
        dateOfFoundation
        legalForm
        registrationNumber
        registryCourt
      }
      wealthAssessment {
        netCapital
        exactNetCapital
        wealthOrigin
        wealthOriginDetail
        financialAssets {
          liquidity {
            range
            value {
              currency
              value
            }
          }
          securities {
            range
            value {
              currency
              value
            }
          }
          selfUsedProperties {
            range
            value {
              currency
              value
            }
          }
          movableProperty {
            range
            value {
              currency
              value
            }
          }
          other {
            range
            value {
              currency
              value
            }
          }
        }
        totalAssets
        sales
        ownFunds
      }
      businessActivity {
        doesPrivateAssetManagement
        doesSpecialTransactions
        isCashIntensive
        isDutyDisclosure
        isPublicCompany
        isPubliclyTraceable
        isVerifiedByGovernment
        naceCode
        transparencyRegisterObligation
      }
      legalEntityContactData {
        contactAddress {
          ...Address
        }
        legalAddress {
          ...Address
        }
      }
      financialStatus {
        netAssets {
          range
          value {
            currency
            value
          }
        }
        assets {
          liquidity {
            range
            value {
              currency
              value
            }
          }
          stock {
            range
            value {
              currency
              value
            }
          }
          realEstate {
            range
            value {
              currency
              value
            }
          }
          movableAssets {
            range
            value {
              currency
              value
            }
          }
          other {
            range
            value {
              currency
              value
            }
          }
        }
        restrictions {
          type
          value {
            currency
            value
          }
        }
        balanceSheet {
          sum
          assets
          sales
        }
        revenue {
          commercialActivities {
            range
            value {
              currency
              value
            }
          }
          rent {
            range
            value {
              currency
              value
            }
          }
          fixedAssets {
            range
            value {
              currency
              value
            }
          }
          others {
            range
            value {
              currency
              value
            }
          }
        }
        monthlyLiabilities {
          staff {
            range
            value {
              currency
              value
            }
          }
          rent {
            range
            value {
              currency
              value
            }
          }
          materialCosts {
            range
            value {
              currency
              value
            }
          }
          insurance {
            range
            value {
              currency
              value
            }
          }
          other {
            range
            value {
              currency
              value
            }
          }
        }
        monthlyLiquidity {
          range
          value {
            currency
            value
          }
        }
        reserve {
          needsReserve
          value {
            currency
            value
          }
          date
        }
      }
      documents {
        currentTradeRegister {
          ...Document
        }
        shareholdersListNonAg {
          ...Document
        }
        shareholdersListAg {
          ...Document
        }
        furtherShareholders {
          ...Document
        }
        shareholdersContract {
          ...Document
        }
        incorperation {
          ...Document
        }
        others {
          ...Document
        }
      }
      taxInfo {
        residencies {
          country
          taxId
          localTaxOffice
          residencyCertificate {
            ...Document
          }
        }
        fatca {
          status
          statusCertificate {
            ...Document
          }
          relevantCriterion
        }
        crs {
          status
          statusCertificate {
            ...Document
          }
          otherCharacteristic
        }
      }
      bankAccounts {
        reference {
          ...LegalEntityBankAccount
        }
        usdCurrency {
          ...LegalEntityBankAccount
        }
      }
      legalRepresentationType
      legalEntityAdditionalPersons {
        id
        personalData {
          salutation
          professionalTitle
          firstName
          familyName
          birthName
          dateOfBirth
          cityOfBirth
          countryOfBirth
          primaryCitizenship
          additionalCitizenships
        }
        contactData {
          emailAddress
          phoneNumber
          legalAddress {
            ...Address
          }
        }
        taxResidencies {
          country
          taxId
        }
        isLegalRepresentative
        isBeneficialOwner
        companyEquityPercentage
        functionaryRole
        wealthOrigin
        wealthOriginDetail
        identityDocument {
          number
          expirationDate
          documents {
            ...Document
          }
        }
      }
    }
  }
`;

export const MandateContractDocumentQuery = gql`
  ${Document}
  query GetAIMandateContractDocument2 {
    profile {
      contracts {
        aiMandate {
          draft {
            ...Document
          }
        }
      }
    }
  }
`;
