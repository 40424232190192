import { TFunction } from "i18next";
import * as yup from "yup";

const translationPrefix = "q-ai.opportunities.form.businessIndustry";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup.object({
    businessIndustry: yup.object({
      naceCode: yup
        .string()
        .required(t(`${translationPrefix}.fields.businessIndustry.required`)),
    }),
  });

export type BusinessIndustry = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export function validate(businessIndustryData: BusinessIndustry): any {
  const t = (k: any) => k;
  return validationSchema(t).validateSync(businessIndustryData);
}
