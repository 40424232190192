import styled, { css } from "styled-components";

export const TooltipWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    background-color: ${theme.tokens.colors.gold[500]};
    border-radius: ${theme.tokens.sizes.radii.circle};

    color: ${theme.tokens.colors.white[500]};
    font-size: ${theme.tokens.typography.fontSizes[0]};
  `}
`;
