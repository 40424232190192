import styled, { css } from "styled-components";

const wrapper = () => css`
  ${({ theme }) => css`
    width: 80vw;
    height: 90vh;

    & > div {
      padding: 0;
      margin: 0;
    }

    ${theme.utils.viewport.md`
      width: 70vw;
      max-width: 900px;
      height: 80vh;
    `}
  `};
`;

export const Wrapper = styled.div(wrapper);
