import { gql } from "@apollo/client";

export const START_NATURAL_IDENTIFICATION_PROCESS = gql`
  mutation StartNaturalPersonIdentificationProcess {
    startNaturalPersonIdentificationProcess {
      code
      success
      message
      url
    }
  }
`;

export const START_LEGAL_IDENTIFICATION_PROCESS = gql`
  mutation StartIdentificationForLegalEntity {
    startIdentificationForLegalEntity {
      code
      identificationStatus {
        accumulatedIdentificationStatus
        legalEntityIdentificationStatus
        legalRepresentative {
          id
          status
          url
        }
        additionalLegalRepresentatives {
          id
          status
          url
        }
      }
      message
      success
    }
  }
`;
