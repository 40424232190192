/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const UpdateProfile = gql`
  mutation UpdateProfile($input: ProfileInput!) {
    updateProfile(input: $input) {
      code
      success
      message
    }
  }
`;

export const UpdateLegalEntityProfile = gql`
  mutation UpdateLegalEntityProfile($input: LegalEntityInput!) {
    updateLegalEntityProfile(input: $input) {
      code
      success
      message
      legalEntityId
    }
  }
`;

export const UpsertLegalEntityAdditionalPerson = gql`
  mutation UpsertLegalEntityAdditionalPerson(
    $legalEntityId: String!
    $input: LegalEntityAdditionalPersonInput!
  ) {
    upsertLegalEntityAdditionalPerson(
      legalEntityId: $legalEntityId
      input: $input
    ) {
      code
      success
      message
      data
    }
  }
`;

export const DeleteLegalEntityAdditionalPerson = gql`
  mutation DeleteLegalEntityAdditionalPerson(
    $legalEntityId: String!
    $personId: String!
  ) {
    deleteLegalEntityAdditionalPerson(
      legalEntityId: $legalEntityId
      personId: $personId
    ) {
      code
      success
      message
    }
  }
`;

export const UpdateIdentityDocument = gql`
  mutation UpdateIdentityDocument(
    $personId: String!
    $identityDocument: IdentityDocumentInput!
  ) {
    updateIdentityDocument(
      personId: $personId
      identityDocument: $identityDocument
    ) {
      code
      success
      message
    }
  }
`;

export const AddWishListFund = gql`
  mutation AddWishListFund(
    $wishListFund: AddWishListFundInput!
    $personId: String!
  ) {
    addWishListFund(wishListFund: $wishListFund, personId: $personId) {
      code
      success
      message
    }
  }
`;
