import { DocumentCategory } from "generated/globalTypes";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateIdentityDocumentsData } from "../../actions";
import { Grid, SelectInput, Stack, TextInput, Heading } from "@finvia/money-ui";
import { buildOptions } from "utils/options/buildObject";
import {
  UploadField,
  handleDocumentUploadGeneric,
  Document,
  handleDeleteDocumentGeneric,
} from "features/alternative-investments/components/UploadField";
import { HandleDocumentUpload } from "../../DocumentsUpload/DocumentsUpload.types";
import { inputNameToDocumentCategory } from "../../DocumentsUpload/utils/utils";
import { AdditionalPersonProps } from "../AddPersonForm.types";
import { IdentityDocumentsProps, HandleDocumentDelete } from ".";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const IdentityDocuments: React.FC<IdentityDocumentsProps> = ({
  isLegalRepresentative,
  defaultData,
  setError,
}) => {
  const formMethods = useFormContext<AdditionalPersonProps>();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [currentDocumentsList, setCurrentDocumentsList] = useState<Document[]>(
    defaultData.identityDocument?.documents ?? []
  );

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    name,
    setValueReference
  ) => {
    try {
      const filteredDocuments = await handleDeleteDocumentGeneric<
        typeof updateIdentityDocumentsData
      >({
        document,
        documents,
        setValueReference,
        dispatch,
        updateAction: updateIdentityDocumentsData,
        setError,
        name,
        formName: "AddPersonForm",
      });

      if (filteredDocuments) {
        setCurrentDocumentsList([...filteredDocuments]);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    name,
    setValueReference
  ) => {
    const documentCategoryName = inputNameToDocumentCategory(name);

    try {
      const doc = await handleDocumentUploadGeneric({
        documents,
        file,
        setValueReference,
        dispatch,
        updateAction: updateIdentityDocumentsData,
        categoryName: documentCategoryName,
        category: DocumentCategory.LEGAL_ENTITY_IDENTITY_DOCUMENT,
        setError,
        name: "identityDocument.documents",
        formName: "AddPersonForm",
      });

      if (doc) {
        setCurrentDocumentsList(doc);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  return (
    <Stack gutter="tera">
      {!isLegalRepresentative && (
        <Stack gutter="mega">
          <Heading size="5" align="left">
            {t(`${translationPrefix}.identityDocument`)}
          </Heading>

          <Grid
            columns={{ sm: 1, md: 2 }}
            columnsGap={{ md: "yotta" }}
            rowsGap="mega"
          >
            <TextInput crossOrigin
              required
              ref={formMethods.register}
              name="identityDocument.number"
              label={t(
                `${translationPrefix}.fields.beneficialOwner.identityNumber.label`
              )}
              errorMessage={
                formMethods.errors?.identityDocument?.number?.message
              }
            />

            <TextInput crossOrigin
              required
              ref={formMethods.register}
              name="identityDocument.expirationDate"
              label={t(
                `${translationPrefix}.fields.beneficialOwner.identityExpirationDate.label`
              )}
              placeholder="TT.MM.JJJJ"
              errorMessage={
                formMethods.errors?.identityDocument?.expirationDate?.message
              }
            />

            <UploadField
              required
              name="identityDocument.documents"
              onDelete={(document, documents) => {
                handleDeleteDocument(
                  document,
                  documents,
                  "identityDocument.documents",
                  formMethods.setValue
                );
              }}
              onUpload={(documents, file) => {
                handleDocumentUpload(
                  documents,
                  file,
                  DocumentCategory.LEGAL_ENTITY_IDENTITY_DOCUMENT,
                  formMethods.setValue
                );
              }}
              documentsList={currentDocumentsList}
              label={t(
                `${translationPrefix}.fields.beneficialOwner.identityDocument.label`
              )}
              helperText={t(
                `${translationPrefix}.fields.beneficialOwner.identityDocument.tooltip`
              )}
              barErrorMessage={t("form.fileUpload.failed")}
              barSuccessMessage={t("form.fileUpload.succeeded")}
              formMethods={formMethods}
              inputErrorMessage={
                //@ts-expect-error: message exist
                formMethods.errors?.identityDocument?.documents?.message
              }
            />
          </Grid>
        </Stack>
      )}

      <Stack gutter="mega">
        <Heading size="5" align="left">
          {t(`${translationPrefix}.regulatoryData`)}
        </Heading>

        <Grid
          columns={{ sm: 1, md: 2 }}
          columnsGap={{ md: "yotta" }}
          rowsGap="mega"
        >
          <SelectInput
            required
            ref={formMethods.register}
            name="wealthOrigin"
            label={t(`${translationPrefix}.fields.wealthOrigin.label`)}
            options={buildOptions({
              lang: i18n,
              key: `${translationPrefix}.fields.wealthOrigin.values`,
            })}
            value={defaultData.wealthOrigin}
            errorMessage={formMethods.errors.wealthOrigin && undefined}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default IdentityDocuments;
