import { TableProps } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const headerCustomStyles = css`
  ${({ theme }) => css`
    ${theme.utils.viewport.md`
      grid-template-columns: 1fr 180px 200px 160px;
      gap: ${theme.tokens.sizes.spacings.kilo};
    `};

    ${theme.utils.viewport.lg`
      gap: ${theme.tokens.sizes.spacings.tera};
    `};

    & > :nth-child(2),
    & > :nth-child(3) {
      justify-content: start;
    }
  `}
`;

const headCustomStyles = css`
  ${() => css`
    align-items: center;

    &:last-of-type {
      justify-content: center;
    }
  `}
`;

const rowCustomStyles = css`
  ${({ theme }) => css`
    ${theme.utils.viewport.md`
      grid-template-columns: 1fr 180px 200px 160px;
      gap: ${theme.tokens.sizes.spacings.kilo};

      ${theme.utils.viewport.lg`
      gap: ${theme.tokens.sizes.spacings.tera};
    `};

      & > :nth-child(2),
      & > :nth-child(3) {
        justify-content: start;
      }

      & > :last-of-type {
        justify-content: center;
      }
    `};
  `}
`;

export const customTableStyles: TableProps["customStyles"] = {
  header: headerCustomStyles,
  head: headCustomStyles,
  row: rowCustomStyles,
};

const idNowLogo = () => css`
  width: 60px;
  height: auto;
`;

export const IdNowLogoStyle = styled.img(idNowLogo);
