import { ProfileInput } from "../../../../generated/globalTypes";
import {
  InvestorProfile,
  InvestorProfile_profile_investorProfile,
} from "../../../../generated/InvestorProfile";
import {
  UpdateProfile,
  UpdateProfileVariables,
} from "../../../../generated/UpdateProfile";
import { UpdateProfile as updateProfileMutation } from "../../../../graphql/mutations/Profile";
import InvestorProfileQuery from "../../../../graphql/queries/InvestorProfile";
import { typedCreateAsyncThunk } from "../../../../store/typedCreateAsyncThunk";
import { FormDefaultData } from "../../../../utils/graphQLPartial";
import { InvestorProfile as InvestorProfileForm } from "./investorProfileValidations";

const mapProfileFromGraphQlToForm = (
  investorProfile?: InvestorProfile_profile_investorProfile | null
): FormDefaultData<InvestorProfileForm> => {
  return {
    goals: investorProfile?.goals,
    horizon: investorProfile?.horizon,
    relevantAssetClasses: investorProfile?.relevantAssetClasses,
    riskBearingCapacity: investorProfile?.riskBearingCapacity,
  };
};

export const getInvestorProfile = typedCreateAsyncThunk(
  "investmentOpportunities/getInvestorProfile",
  async (_, { extra }): Promise<FormDefaultData<InvestorProfileForm>> => {
    const result = await extra.client.query<InvestorProfile>({
      query: InvestorProfileQuery,
    });

    return mapProfileFromGraphQlToForm(result.data.profile?.investorProfile);
  }
);

type UpdateProfilePayload = InvestorProfileForm;

export const mapProfileFromFormToGraphQl = (
  formData?: InvestorProfileForm | null
): ProfileInput => {
  return {
    investorProfile: {
      goals: formData?.goals,
      horizon: formData?.horizon,
      relevantAssetClasses: formData?.relevantAssetClasses,
      riskBearingCapacity: formData?.riskBearingCapacity,
    },
  };
};

export const updateProfile = typedCreateAsyncThunk(
  "investmentOpportunities/updateProfile",
  async (
    payload: UpdateProfilePayload,
    { extra }
  ): Promise<UpdateProfilePayload> => {
    const input = mapProfileFromFormToGraphQl(payload);
    await extra.client.mutate<UpdateProfile, UpdateProfileVariables>({
      mutation: updateProfileMutation,
      variables: { input },
    });

    return payload;
  }
);
