import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../../store";
import formDefaultsDeep from "../../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../../actions";
import {
  BusinessIndustry,
  validationSchema,
} from "../BusinessIndustryTypeValidations";
import BusinessIndustryNode from "../components/BusinessIndustryNode";
import {
  getParentsFromCode,
  IndustryNode,
  industryTypes,
  naceCodeToString,
} from "../components/industryTypes";
import { Button, Grid, InfoBox, Stack, TextInput } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const translationPrefix = "q-ai.opportunities.form.businessIndustry";
const investmentCompanyCode = "K 64.20" as const;

const nodesWrapper = () => css`
  width: 100%;
`;

export const NodesWrapper = styled.div(nodesWrapper);

const BusinessIndustryForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const [nodesToExpandOnSearch, setNodesToExpandOnSearch] = useState<string[]>(
    []
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);

  const onSubmit = useCallback(
    async (businessIndustryData: BusinessIndustry) => {
      dispatch(updateLegalEntityProfile(businessIndustryData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const defaultData = typedUseSelector((store) => {
    const { businessIndustry } =
      store.alternativeInvestments.regulatoryData?.legalEntityData || {};

    return formDefaultsDeep<BusinessIndustry>(
      {
        businessIndustry,
      },
      {
        businessIndustry: {
          naceCode: undefined,
        },
      }
    );
  });

  const treeCodesToExpand = new Map();
  interface PopulateTreeCodesToExpand {
    query: string;
    industryTypesList: IndustryNode[];
    activeCode?: string;
  }
  const populateTreeCodesToExpand = ({
    query,
    industryTypesList,
    activeCode,
  }: PopulateTreeCodesToExpand) =>
    industryTypesList.forEach((currentIndustryType) => {
      const naceCode = naceCodeToString(currentIndustryType);
      const queryRegex = new RegExp(query, "i");
      const isHighlighted = queryRegex.test(currentIndustryType.name);
      const isChildFromHighlighted =
        activeCode && naceCode.includes(activeCode);
      const isHighlightedOrItsChildren =
        isHighlighted || isChildFromHighlighted;

      if (currentIndustryType.children !== undefined) {
        const args: PopulateTreeCodesToExpand = {
          industryTypesList: currentIndustryType.children,
          query,
          ...(isHighlightedOrItsChildren && { activeCode: naceCode }),
        };

        populateTreeCodesToExpand(args);
      }

      if (isHighlightedOrItsChildren) {
        treeCodesToExpand.set(naceCode, true);
      }

      if (isHighlighted) {
        getParentsFromCode(naceCode).forEach((code) => {
          treeCodesToExpand.set(code, true);
        });
      }
    });

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    populateTreeCodesToExpand({
      query: event.target.value,
      industryTypesList: industryTypes,
    });

    setNodesToExpandOnSearch([...treeCodesToExpand.keys()]);
    setSearchQuery(event.target.value);
  };

  const debouncedChangeHandler = debounce(changeHandler, 300);

  return (
    <FormControl<BusinessIndustry>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      reValidationMode="onChange"
      dataAnalytics="2.10.2_le_business_industry"
    >
      {(formMethods) => {
        const naceCode = formMethods.watch(
          "businessIndustry.naceCode"
        ) as string;

        const requiredError = formMethods.errors.businessIndustry?.naceCode;

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error || requiredError}
            customErrorMessage={requiredError?.message}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Stack direction="column" gutter="byte">
              <InfoBox icon="info">
                <Stack>
                  {t(`${translationPrefix}.infoMessage`)}
                  <Button
                    onClick={() => {
                      formMethods.setValue(
                        "businessIndustry.naceCode",
                        investmentCompanyCode,
                        { shouldValidate: true }
                      );
                      // scroll submit button into view (with space underneath)
                      document
                        .querySelector(".MuiPaper-root .MuiGrid-root")
                        ?.scrollIntoView({
                          block: "end",
                          inline: "nearest",
                        });
                    }}
                    variant="link"
                  >
                    {t(`${translationPrefix}.investmentCompany`)}
                  </Button>
                </Stack>
              </InfoBox>
              <TextInput crossOrigin
                name={"businessIndustry.search"}
                label={""}
                placeholder="Suchen"
                onChange={debouncedChangeHandler}
                decorator={{ type: "icon", value: "search", position: "left" }}
              />
              <NodesWrapper>
                <Controller
                  control={formMethods.control}
                  name="businessIndustry.naceCode"
                  defaultValue={defaultData.businessIndustry?.naceCode}
                  render={() => (
                    <BusinessIndustryNode
                      nodes={industryTypes}
                      onSelect={(code) => {
                        formMethods.setValue(
                          "businessIndustry.naceCode",
                          code,
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                      parentsFromActiveNode={
                        naceCode ? getParentsFromCode(naceCode) : []
                      }
                      nodesToExpandOnSearch={nodesToExpandOnSearch}
                      searchQuery={searchQuery}
                    />
                  )}
                />
              </NodesWrapper>
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default BusinessIndustryForm;
