import { Option } from "@finvia/money-ui";
import { countries } from "utils/options/countries";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { SelectData, SelectDataReturn } from "./EmploymentAndTaxForm.types";

export const selectData = ({
  defaultData,
  t,
  errors,
  translationPrefix,
  noBranchSelection,
}: SelectData): SelectDataReturn[] => [
  {
    options: getSelectOptionsFromTranslation(
      `${translationPrefix}.fields.employment.status.values`,
      t
    ),
    name: "employment.status",
    label: t(`${translationPrefix}.fields.employment.status.label`),
    required: true,
    defaultData: defaultData.employment?.status,
    errorMessage: errors.employment?.status?.message,
  },
  {
    options: countries(t),
    name: "employment.country",
    label: t(`${translationPrefix}.fields.employment.country.label`),
    required: true,
    defaultData: defaultData.employment?.country,
    errorMessage: errors.employment?.country?.message,
  },
  {
    options: getSelectOptionsFromTranslation(
      `${translationPrefix}.fields.employment.branchOfIndustry.values`,
      t
    ),
    name: "employment.branchOfIndustry",
    label: t(`${translationPrefix}.fields.employment.branchOfIndustry.label`),
    required: true,
    defaultData: defaultData.employment?.branchOfIndustry,
    errorMessage: errors.employment?.branchOfIndustry?.message,
    disabled: noBranchSelection,
  },
  {
    options: getSelectOptionsFromTranslation(
      `${translationPrefix}.fields.employment.pep.status.values`,
      t
    ),
    name: "employment.pep.status",
    label: t(`${translationPrefix}.fields.employment.pep.status.label`),
    required: true,
    defaultData: defaultData.employment?.pep?.status,
    errorMessage: errors.employment?.pep?.status?.message,
    helperText: t(`${translationPrefix}.fields.employment.pep.status.tooltip`),
  },
  {
    options: getSelectOptionsFromTranslation(
      `${translationPrefix}.fields.employment.sector.values`,
      t
    ),
    name: "employment.sector",
    label: t(`${translationPrefix}.fields.employment.sector.label`),
    required: true,
    defaultData: defaultData.employment?.sector,
    errorMessage: errors.employment?.sector?.message,
  },
];

export const filterCountries = ({
  countriesToRemove,
  countriesList,
}: {
  countriesToRemove: (string | undefined)[];
  countriesList: Option[];
}): Option[] => {
  return countriesList.filter(
    (option) => countriesToRemove.includes(option.value) === false
  );
};
