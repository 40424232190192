import { useTranslation } from "react-i18next";

import { Heading, Stack, Text } from "@finvia/money-ui";
import { managerData } from "./utils";
import * as S from "./Manager.styled";
import { ManagerProps } from "./Manager.types";

const tPrefix = "components.alternativeInvestmentFund.profile";

const Manager = ({
  manager,
  fundCurrency,
}: ManagerProps): React.ReactElement | null => {
  const { t } = useTranslation();

  if (!manager) {
    console.error(`@Manager does not exist in the profile section`);
    return null;
  }

  return (
    <Stack gutter="giga">
      <Heading as="h2" size={{ sm: 5, lg: 6 }}>
        {t(`${tPrefix}.firstSubSection.title`)}
      </Heading>
      <S.ManagerDataWrapper>
        {managerData({ manager, prefix: tPrefix, t, fundCurrency }).map(
          (data) =>
            data.value && (
              <S.ManagerData key={data.value}>
                <Text size="3">{data.key}</Text>
                <S.ManagerValue>{data.value}</S.ManagerValue>
              </S.ManagerData>
            )
        )}
      </S.ManagerDataWrapper>
    </Stack>
  );
};

export default Manager;
