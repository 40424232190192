import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: ${theme.tokens.colors.neutral[300]};
    padding: ${theme.tokens.sizes.spacings.giga}
      ${theme.tokens.sizes.spacings.kilo};
  `}
`;
