import { Box, makeStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { hasQueryOnString } from "../../../../../../../utils/hasQueryOnString";
import BoldQueryOnString from "../../../../../components/BoldQueryOnString";
import { IndustryNode, naceCodeToString } from "./industryTypes";
import LastNode from "./LastNode";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./StyledAccordion";
import { Text, theme } from "@finvia/money-ui";

const useStyles = makeStyles(() => ({
  selectedParent: {
    backgroundColor: theme.tokens.colors.petrol[200],
    border: `1px solid ${theme.tokens.colors.petrol[200]}`,
  },
  selectedCategory: {
    backgroundColor: theme.tokens.colors.petrol[200],
    border: `1px solid ${theme.tokens.colors.petrol[200]}`,
    "&:hover": {
      backgroundColor: theme.tokens.colors.petrol[200],
      border: `1px solid ${theme.tokens.colors.petrol[200]}`,
    },
  },
}));

interface Props {
  nodes: IndustryNode[] | [];
  onSelect: (code: string) => void;
  activeIndex?: number;
  parentsFromActiveNode: string[];
  nodesToExpandOnSearch: string[];
  searchQuery: string;
}

const BusinessIndustryNode: React.FC<Props> = ({
  nodes,
  onSelect,
  parentsFromActiveNode,
  activeIndex = 0,
  nodesToExpandOnSearch,
  searchQuery,
}) => {
  const classes = useStyles();
  const hasActiveParents = parentsFromActiveNode.length > 0;
  const activeParentCode = hasActiveParents
    ? parentsFromActiveNode[activeIndex]
    : "";
  const [activeNaceCode, setActiveNaceCode] = useState(activeParentCode);

  const isActiveSearch = searchQuery !== "";

  useEffect(() => {
    setActiveNaceCode(activeParentCode);
  }, [parentsFromActiveNode, activeIndex, hasActiveParents, activeParentCode]);

  if (!nodes.length) return <></>;

  return (
    <>
      {nodes.map((node, index) => {
        const { name, children } = node;
        const nodeNaceCode = naceCodeToString(node);
        const isSelected = nodeNaceCode === parentsFromActiveNode[activeIndex];
        const isActive = nodeNaceCode === activeNaceCode;
        const hasSearchedQuery = hasQueryOnString({
          str: name,
          query: searchQuery,
        });
        const isFromSearchedTree =
          nodesToExpandOnSearch.includes(nodeNaceCode) || hasSearchedQuery;

        const shouldHideNodeOnSearch =
          isActive === false && isActiveSearch && isFromSearchedTree === false;
        if (shouldHideNodeOnSearch) {
          return <Fragment key={`${nodeNaceCode}-${name}-${index}`}></Fragment>;
        }

        if (children === undefined || !children.length) {
          return (
            <LastNode
              name={name}
              naceCode={nodeNaceCode}
              onSelect={onSelect}
              isActiveSearch={isActiveSearch}
              isActiveOnSearch={hasSearchedQuery}
              isSelected={isSelected}
              searchQuery={searchQuery}
              key={`${nodeNaceCode}-${name}-${index}`}
            />
          );
        }

        // it goes deeper in the tree on each iteration
        const childrenIndex = activeIndex + 1;
        return (
          <Accordion
            square
            expanded={isActive || isFromSearchedTree}
            id={nodeNaceCode}
            key={`${nodeNaceCode}-${name}-${index}`}
          >
            <AccordionSummary
              onClick={() => {
                setActiveNaceCode((active) => {
                  return nodeNaceCode === active ? "" : nodeNaceCode;
                });
              }}
              className={isSelected ? classes.selectedParent : undefined}
            >
              <Text as="span">
                {hasSearchedQuery && isActiveSearch
                  ? BoldQueryOnString({ string: name, query: searchQuery })
                  : name}
              </Text>
            </AccordionSummary>
            {(isActive || isActiveSearch) && (
              <AccordionDetails>
                <Box display="flex" flexDirection="column">
                  <BusinessIndustryNode
                    nodes={children}
                    onSelect={onSelect}
                    parentsFromActiveNode={parentsFromActiveNode}
                    activeIndex={childrenIndex}
                    nodesToExpandOnSearch={nodesToExpandOnSearch}
                    searchQuery={searchQuery}
                  />
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </>
  );
};

export default BusinessIndustryNode;
