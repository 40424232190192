import { TFunction } from "i18next";
import * as yup from "yup";
import { WealthOrigin } from "../../../../../../generated/globalTypes";
import { ExperienceCurrenciesProps } from "./ExperienceCurrencies.types";

const translationPrefix = "q-ai.opportunities.form.experienceCurrency";

export const experienceCurrenciesValidation = (): yup.SchemaOf<
  ExperienceCurrenciesProps["currenciesExperience"]
> =>
  yup.object({
    foreignCurrencies: yup.boolean(),
    loanBasedForeignCurrencies: yup.boolean(),
    investmentConsulting: yup.boolean(),
    consultingFreeOrderExecution: yup.boolean(),
    wealthManagement: yup.boolean(),
  });

export const wealthOriginValidation = (
  t: TFunction
): yup.SchemaOf<ExperienceCurrenciesProps["wealthOrigin"]> =>
  yup
    .mixed()
    .oneOf(
      Object.values(WealthOrigin),
      t(`${translationPrefix}.fields.wealthOrigin.required`)
    )
    .required(t(`${translationPrefix}.fields.wealthOrigin.required`))
    .label(t(`${translationPrefix}.fields.wealthOrigin.label`));

export const validation = (
  t: TFunction,
  isLegalEntity?: boolean
): yup.SchemaOf<ExperienceCurrenciesProps> =>
  yup
    .object({
      currenciesExperience: experienceCurrenciesValidation(),
      wealthOrigin: isLegalEntity
        ? wealthOriginValidation(t).required()
        : wealthOriginValidation(t).notRequired(),
    })
    .required();
