import { TFunction } from "i18next";
import * as yup from "yup";
import { LegalRepresentativeDataForm } from ".";

const translationPrefix =
  "q-ai.opportunities.form.legalRepresentative.fields.legalEntityData";

export const validationSchema = (
  t: TFunction
): yup.SchemaOf<LegalRepresentativeDataForm> =>
  yup.object().shape({
    isLegalRepresentative: yup
      .string()
      .required(t(`${translationPrefix}.isRepresentative.required`))
      .oneOf(["yes"]),
  });
