import { Button, Modal, theme } from "@finvia/money-ui";
import React, { useState } from "react";
import { InlineWidget } from "react-calendly";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../routes/routes";
import { Wrapper } from "./CalendlyButton.styled";
import { tracking } from "utils/tracking";

const CalendlyButton: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          setIsOpen(true);
          tracking.triggerEvent("calendly_request");
        }}
      >
        {t("sidebar.questions.buttonText")}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        id="calendly"
        padding="bit"
      >
        <Wrapper>
          <InlineWidget
            url={ROUTES.external.finviaAppointments}
            styles={{
              height: "100%",
              width: "100%",
            }}
            pageSettings={{
              backgroundColor: theme.tokens.colors.neutral[300],
              textColor: theme.tokens.colors.neutral[750],
              primaryColor: theme.tokens.colors.petrol[600],
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
            }}
          />
        </Wrapper>
      </Modal>
    </>
  );
};

export default CalendlyButton;
