import {
  FundInvestmentEducationAndProfessionalStatus,
  FundInvestmentExperienceAndKnowledge,
} from "generated/globalTypes";
import { SemiProfessionalFields } from "./SemiProfessional.types";

export const semiProfessionalFieldsConfig: SemiProfessionalFields = {
  isSufficientExperienceAndKnowledgeConfirmed: {
    key: "experienceAndKnowledgeType",
    radio: {
      name: "declarations.semiProfessionalInvestor.experienceAndKnowledgeType",
      options: [
        FundInvestmentExperienceAndKnowledge.DEFAULT,
        FundInvestmentExperienceAndKnowledge.CUSTOM,
      ],
    },
    textarea: {
      name: "declarations.semiProfessionalInvestor.experienceAndKnowledgeAppendix",
    },
  },
  isEducationAndProfessionalStatusConfirmed: {
    key: "educationAndProfessionalStatusType",
    radio: {
      name: "declarations.semiProfessionalInvestor.educationAndProfessionalStatusType",
      options: [
        FundInvestmentEducationAndProfessionalStatus.DEFAULT,
        FundInvestmentEducationAndProfessionalStatus.CUSTOM,
      ],
    },
    textarea: {
      name: "declarations.semiProfessionalInvestor.educationAndProfessionalStatusAppendix",
    },
  },
};
