import { Text } from "@finvia/money-ui";
import * as S from "./UnorderedList.styled";

interface Props {
  listItems: string[];
}

const UnorderedList: React.FC<Props> = ({ listItems }) => {
  return (
    <S.UnorderedList>
      {listItems.map((item) => (
        <S.ListItem key={item}>
          <Text>{item}</Text>
        </S.ListItem>
      ))}
    </S.UnorderedList>
  );
};

export default UnorderedList;
