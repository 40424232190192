import { makeStyles } from "@material-ui/core";
import React from "react";

interface InputGridProps {
  columnSize?: string;
  justify?: string;
  align?: string;
  gap?: string;
  columnGap?: string;
  columnNumber?: number;
  defaultColumnGap?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: (props: InputGridProps) => ({
    display: "grid",
    width: "100%",
    gridTemplateColumns: "minmax(0, 1fr)",
    gap: props.gap,
    columnGap: props.columnGap ?? props.gap,
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: `repeat(${props.columnNumber}, minmax(0, ${props.columnSize}));`,
      justifyContent: props.justify,
      alignItems: props.align,
    },
  }),
}));

const InputGrid: React.FC<InputGridProps> = ({
  children,
  columnSize = "1fr",
  justify = "space-between",
  align = "flex-start",
  gap = "25px",
  columnNumber = 2,
  defaultColumnGap,
  columnGap,
}) => {
  const classes = useStyles({
    columnSize,
    justify,
    align,
    columnNumber,
    gap,
    columnGap: defaultColumnGap ? "86px" : columnGap,
  });

  return <div className={classes.root}>{children}</div>;
};

export default InputGrid;
