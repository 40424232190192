import styled, { css } from "styled-components";

const wrapperStyles = () => css`
  ${({ theme }) => css`
    padding-left: ${theme.tokens.sizes.spacings.tera};
  `};
`;

const checkboxWrapperStyles = () => css`
  ${({ theme }) => css`
    width: 100%;
    box-sizing: border-box;
    background-color: ${theme.tokens.colors.white[500]};
    padding: ${theme.tokens.sizes.spacings.giga};
    border: ${theme.tokens.sizes.borderWidth.byte} solid
      ${theme.tokens.colors.petrol[300]};
  `};
`;

export const AdditionalText = styled.div(wrapperStyles);
export const CheckboxWrapper = styled.div(checkboxWrapperStyles);
