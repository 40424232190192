import styled, { css } from "styled-components";

const dependantRadiowrapper = () => css`
  ${({ theme }) => css`
    ${theme.utils.viewport.md` 
      margin-left: ${theme.tokens.sizes.spacings.exa};
    `}
  `};
`;

export const DependantRadiowrapper = styled.div(dependantRadiowrapper);
