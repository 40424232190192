import { Button, Icon, Stack } from "@finvia/money-ui";
import { NaturalPersonIdentificationStatus } from "generated/globalTypes";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { copyTextToClipboard } from "utils/helpers";
import { IdentificationStatusTableCellProps } from "./IdentificationStatusTableCell.types";
import * as S from "./IdentificationStatusTableCell.styled";

const translationPrefix = "components.AddPersonTable";

const IdentificationStatusTableCell: React.FC<
  IdentificationStatusTableCellProps
> = ({ value, nextStep }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { t } = useTranslation();

  const copyIdentificationLinkToClipboard = (url: string | undefined) => {
    if (url) {
      copyTextToClipboard(url);
    }
    setIsTooltipOpen(true);
    setTimeout(() => setIsTooltipOpen(false), 2000);
  };

  if (value.identificationStatus === "NOT_NECESSARY") {
    return (
      <S.NotNecessary>{t(`${translationPrefix}.notNecessary`)}</S.NotNecessary>
    );
  }

  switch (value.identificationStatus) {
    case NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL:
    case NaturalPersonIdentificationStatus.IDENTIFICATION_SUCCESSFUL:
      return <Icon name="done" size="byte" />;
    case NaturalPersonIdentificationStatus.IDENTIFICATION_FAILED:
      return <Icon name="error" size="byte" color="red.500" />;
    case NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING:
    case NaturalPersonIdentificationStatus.IDENTIFICATION_NOT_ATTEMPTED:
      if (value.currentUser) {
        return (
          <Button variant="link" onClick={() => nextStep()}>
            {t(`${translationPrefix}.identifizierungStarten`)}
          </Button>
        );
      } else {
        return (
          <S.Wrapper>
            <S.Tooltip isOpen={isTooltipOpen}>
              {`${
                value.isReadyForIdentification
                  ? t(`${translationPrefix}.identLinkCopied`)
                  : t(`${translationPrefix}.linkNotReady`)
              }`}
              <S.Caret />
            </S.Tooltip>
            <Button
              variant="link"
              onClick={() =>
                copyIdentificationLinkToClipboard(value?.identificationURL)
              }
              disabled={!value.isReadyForIdentification}
            >
              <Stack direction="row">
                {t(`${translationPrefix}.identLinkCopy`)}
                <Icon
                  name="copy"
                  color={
                    value.isReadyForIdentification
                      ? "petrol.600"
                      : "neutral.500"
                  }
                  size="bit"
                />
              </Stack>
            </Button>
          </S.Wrapper>
        );
      }
    default:
      return <Icon name="error" size="byte" color="red.500" />;
  }
};

export default IdentificationStatusTableCell;
