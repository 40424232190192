import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router-dom";

interface Props {
  isSubmitted: boolean;
  isDirty: boolean;
}

const NavigationDisclaimer: React.FC<Props> = ({ isSubmitted, isDirty }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.onbeforeunload = () => {
      // We decided to go with the browser default here because it's the fastest route
      return isDirty ? t("form.navigationDisclaimer") : null;
    };
    return () => {
      window.onbeforeunload = null;
    };
  });
  return (
    <Prompt
      message={() => {
        const disclaimer =
          t("form.navigationDisclaimer") ||
          "ADD DISCLAIMER UNDER `form.navigationDisclaimer`";
        return isDirty && !isSubmitted ? disclaimer : true;
      }}
    />
  );
};

export default NavigationDisclaimer;
