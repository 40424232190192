import { Dispatch, SetStateAction } from "react";
import { ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY } from "../../../../generated/globalTypes";
import FormsCompleteHeader from "features/alternative-investments/components/FundDetails/FundDetailsHeaders/FormsCompleteHeader";
import FundNotAvailableHeader from "../../components/FundDetails/FundDetailsHeaders/FundNotAvailableHeader";
import MandateContractNotSignedHeader from "../../components/FundDetails/FundDetailsHeaders/MandateContractNotSignedHeader";

interface FundDetailsHeaderContentProps {
  isFundClosed: boolean;
  completedForms: boolean;
  amount: number;
  currency: ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY;
  setAmount: Dispatch<SetStateAction<number>>;
  onSubmit: () => void;
}

export const FundDetailsHeaderContent: (
  props: FundDetailsHeaderContentProps
) => JSX.Element = (props) => {
  if (props.isFundClosed) {
    return <FundNotAvailableHeader />;
  }

  if (!props.isFundClosed && props.completedForms) {
    return (
      <FormsCompleteHeader
        amount={props.amount}
        currency={props.currency}
        setAmount={props.setAmount}
        onSubmit={props.onSubmit}
      />
    );
  }

  return <MandateContractNotSignedHeader />;
};
