/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from "i18next";
import * as yup from "yup";
import countries from "../../../../../../data/countries";
import {
  BranchOfIndustry,
  FACTAStatus,
  LegalEntityFunctionaryRole,
  PEPRelationship,
  PEPRole,
  PEPStatus,
} from "../../../../../../generated/globalTypes";
import { baseRequiredValidation } from "../../../../../../genericValidations/DocumentValidations";

export const selfEmployedBranches = Object.values(BranchOfIndustry).filter(
  (branch) => branch !== BranchOfIndustry.PUBLIC_SERVICE
);

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData";

export const employmentValidationSchema = (t: TFunction) =>
  yup
    .object({
      role: yup
        .mixed<LegalEntityFunctionaryRole>()
        .required(t(`${translationPrefix}.fields.employment.role.required`))
        .oneOf(
          Object.values(LegalEntityFunctionaryRole),
          t(`${translationPrefix}.fields.employment.role.required`)
        )
        .label(t(`${translationPrefix}.fields.employment.role.label`)),
      country: yup
        .string()
        .oneOf(
          countries,
          t(`${translationPrefix}.fields.employment.country.required`)
        )
        .required(t(`${translationPrefix}.fields.employment.country.required`))
        .label(t(`${translationPrefix}.fields.employment.country.label`)),
      pep: yup.object({
        status: yup
          .mixed<PEPStatus>()
          .oneOf(
            Object.values(PEPStatus),
            t(`${translationPrefix}.fields.employment.pep.status.required`)
          )
          .label(t(`${translationPrefix}.fields.employment.pep.status.label`)),
        role: yup
          .mixed<PEPRole>()
          .oneOf(
            Object.values(PEPRole),
            t(`${translationPrefix}.fields.employment.pep.role.required`)
          )
          .when("pep.status", {
            is: (status: PEPStatus) =>
              status === PEPStatus.YES_FAMILY_MEMBER ||
              status === PEPStatus.YES_ME,
            then: yup
              .mixed<PEPRole>()
              .oneOf(
                Object.values(PEPRole),
                t(`${translationPrefix}.fields.employment.pep.role.required`)
              )
              .required(
                t(`${translationPrefix}.fields.employment.pep.role.required`)
              ),
          })
          .label(t(`${translationPrefix}.fields.employment.pep.role.label`)),
        relationship: yup
          .mixed<PEPRelationship>()
          .oneOf(
            Object.values(PEPRelationship),
            t(
              `${translationPrefix}.fields.employment.pep.relationship.required`
            )
          )
          .when("pep.status", {
            is: (status: PEPStatus) => status === PEPStatus.YES_FAMILY_MEMBER,
            then: yup
              .mixed<PEPRelationship>()
              .oneOf(
                Object.values(PEPRelationship),
                t(
                  `${translationPrefix}.fields.employment.pep.relationship.required`
                )
              )
              .required(
                t(
                  `${translationPrefix}.fields.employment.pep.relationship.required`
                )
              ),
          })
          .label(
            t(`${translationPrefix}.fields.employment.pep.relationship.label`)
          ),
      }),
    })
    .required();

export const residencyValidationSchema = (t: TFunction) =>
  yup
    .object({
      country: yup
        .string()
        .oneOf(
          countries,
          t(`${translationPrefix}.fields.taxInfo.residencies.country.required`)
        )
        .required(
          t(`${translationPrefix}.fields.taxInfo.residencies.country.required`)
        )
        .label(
          t(`${translationPrefix}.fields.taxInfo.residencies.country.label`)
        ),
      taxId: yup
        .string()
        .when("country", {
          is: (country: string) => country === "DE",
          then: yup
            .string()
            .matches(
              /^[0-9]{11}$/,
              t(`${translationPrefix}.fields.taxInfo.residencies.taxId.format`)
            ),
        })
        .required(
          t(`${translationPrefix}.fields.taxInfo.residencies.taxId.required`)
        )
        .label(
          t(`${translationPrefix}.fields.taxInfo.residencies.taxId.label`)
        ),
    })
    .required();

export const taxInfoValidationSchema = (t: TFunction) =>
  yup
    .object({
      facta: yup.object({
        status: yup
          .mixed<FACTAStatus>()
          .required(
            t(`${translationPrefix}.fields.taxInfo.facta.status.required`)
          )
          .oneOf(
            Object.values(FACTAStatus),
            t(`${translationPrefix}.fields.taxInfo.facta.status.required`)
          )
          .label(t(`${translationPrefix}.fields.taxInfo.facta.status.label`)),
        irsDocuments: yup.mixed().when("status", {
          is: (status: FACTAStatus) =>
            status === FACTAStatus.NO_BUT_US_CITIZENSHIP ||
            status === FACTAStatus.YES,
          then: yup
            .array(baseRequiredValidation())
            .nullable()
            .min(
              1,
              t(
                `${translationPrefix}.fields.taxInfo.facta.irsDocuments.required`
              )
            )
            .label(
              t(`${translationPrefix}.fields.taxInfo.facta.irsDocuments.label`)
            ),
        }),
      }),
      residencies: yup
        .array(residencyValidationSchema(t))
        .min(1)
        .label(t(`${translationPrefix}.fields.taxInfo.residencies`)),
    })
    .required();

export const validationSchema = (t: TFunction) =>
  yup
    .object({
      employment: employmentValidationSchema(t),
      taxInfo: taxInfoValidationSchema(t),
    })
    .required();

export type Employment = yup.InferType<
  ReturnType<typeof employmentValidationSchema>
>;

export type Residency = yup.InferType<
  ReturnType<typeof residencyValidationSchema>
>;

export type TaxInfo = yup.InferType<ReturnType<typeof taxInfoValidationSchema>>;

export interface EmploymentLegalEntity {
  employment?: Employment;
  taxInfo?: TaxInfo;
}

export function validate(
  employmentLegalEntity: EmploymentLegalEntity
): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(employmentLegalEntity);
}
