import { NetWorth, WealthAssessmentProps } from "./WealthAssessment.types";
import { MoneyRangeLarge, WealthRestrictionType } from "generated/globalTypes";

export const wealthAssessmentCheckboxNames: Array<
  keyof Omit<NetWorth, "restrictions">
> = [
  "cash",
  "securities",
  "selfUsedProperties",
  "realEstate",
  "movableProperty",
  "insuranceClaims",
  "other",
];

export const cleanUpDataFromUnselectedCheckboxes = (
  netWorth: WealthAssessmentProps["netWorth"]
): Record<string, string> => {
  if (!netWorth) {
    return {};
  }

  const netWorthKeys = Object.keys(netWorth) as (keyof typeof netWorth)[];

  return netWorthKeys.reduce((cleanedUpData, currentFieldName) => {
    const field = netWorth?.[currentFieldName] as Record<string, unknown>;
    const hasCheckboxProp = field?.hasOwnProperty("checkbox");
    const isChecked = field?.checkbox;

    const hasRestrictionType =
      field?.type === WealthRestrictionType.NO_RESTRICTIONS;

    return {
      ...cleanedUpData,
      [currentFieldName]: hasCheckboxProp
        ? {
            checkbox: isChecked,
            range: isChecked ? field?.range : null,
            value:
              isChecked && field?.range === MoneyRangeLarge.MORE_THAN_1000000
                ? field?.value
                : null,
          }
        : { ...field, ...(hasRestrictionType && { value: null }) },
    };
  }, {});
};
