import { TFunction } from "i18next";
import * as yup from "yup";

const translationPrefix = "q-ai.opportunities.form.declaration";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const declarationValidationSchema = (t: TFunction) => {
  return yup.object({
    hasReceivedBasicInformationOnInvestments: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.checkboxes.hasReceivedBasicInformationOnInvestments.required`
        )
      ),
    isActingOnOwnBehalf: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(`${translationPrefix}.checkboxes.isActingOnOwnBehalf.required`)
      ),
    hasConfirmedInformationCorrectAndComplete: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.checkboxes.hasConfirmedInformationCorrectAndComplete.required`
        )
      ),
    hasAcknowledgedSustainabilityClause: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.checkboxes.hasAcknowledgedSustainabilityClause.required`
        )
      ),
  });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      declaration: declarationValidationSchema(t),
    })
    .required();
