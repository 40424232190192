import { FundFragment_attributes_profile } from "generated/FundFragment";

export const mockedManager:
  | FundFragment_attributes_profile["manager"]
  | undefined = {
  __typename: "ComponentGenericManager",
  AUM: "AUM",
  foundationDate: "foundationDate",
  locations: "locations",
  team: "team",
  investedSum: null,
  investedFundsCount: null,
  fundGenerations: null,
};

export const mockedInvestmentExamples:
  | FundFragment_attributes_profile["investmentExamples"]
  | undefined = [
  {
    __typename: "ComponentGenericInvestmentExamples",
    company: {
      __typename: "UploadFileEntityResponse",
      data: {
        __typename: "UploadFileEntity",
        attributes: { __typename: "UploadFile", url: "company" },
      },
    },
    mutipleGross: "3,5x (Fonds 5); 3,5x (Fonds 5)",
    companyOverview: "Deutscher Pharma-Generika Hersteller",
    valueAdd:
      "Produktentwicklung, Internationalisierung, gezielte „Buy-and-Build“ Strategie, Implementierung „best practises“, Stärkung des Management Teams",
  },
  {
    __typename: "ComponentGenericInvestmentExamples",
    company: {
      __typename: "UploadFileEntityResponse",
      data: {
        __typename: "UploadFileEntity",
        attributes: { __typename: "UploadFile", url: "company" },
      },
    },
    mutipleGross: "3,5x (Fonds 5); 3,5x (Fonds 5)",
    companyOverview: "Deutscher Pharma-Generika Hersteller",
    valueAdd:
      "Produktentwicklung, Internationalisierung, gezielte „Buy-and-Build“ Strategie, Implementierung „best practises“, Stärkung des Management Teams",
  },
  {
    __typename: "ComponentGenericInvestmentExamples",
    company: {
      __typename: "UploadFileEntityResponse",
      data: {
        __typename: "UploadFileEntity",
        attributes: { __typename: "UploadFile", url: "company" },
      },
    },
    mutipleGross: "3,5x (Fonds 5); 3,5x (Fonds 5)",
    companyOverview: "Deutscher Pharma-Generika Hersteller",
    valueAdd:
      "Produktentwicklung, Internationalisierung, gezielte „Buy-and-Build“ Strategie, Implementierung „best practises“, Stärkung des Management Teams",
  },
];
