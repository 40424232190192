import { Box, Stack, Text } from "@finvia/money-ui";
import { ReactElement } from "react";
import { Document } from "./NecessaryDocuments.styled";

const NecessaryDocuments = ({
  title,
  documents,
}: {
  title: string;
  documents: string[];
}): ReactElement => (
  <Box>
    <Stack gutter="bit">
      <Text>{title}</Text>
      <Stack alignItems="center" direction="row">
        {documents.map((document) => (
          <Document key={document}>
            <Text as="strong" color="neutral.800">
              {document}
            </Text>
          </Document>
        ))}
      </Stack>
    </Stack>
  </Box>
);

export default NecessaryDocuments;
