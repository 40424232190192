import { TextInput } from "@finvia/money-ui";
import { Currency } from "generated/globalTypes";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { currencyFormatter, currencySymbolsDictionary } from "utils/helpers";
import { MonetaryInputProps } from "./MonetaryInput.types";

/**
 * TODO:
 * Maybe refactor the component and try to use useController
 * to update the value without the need of the useRef and useImperativeHandle
 */
const MonetaryInput = forwardRef<HTMLInputElement, MonetaryInputProps>(
  (
    { decorator = Currency.EUR, onChangeCallback, ...props },
    ref
  ): JSX.Element => {
    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    useEffect(() => {
      if (!innerRef.current) {
        return;
      }

      const value = innerRef.current.value;

      if (!value) {
        return;
      }

      innerRef.current.value = currencyFormatter(Currency.EUR, Number(value));
    }, []);

    const onChange = (event: any) => {
      if (!innerRef.current) {
        return;
      }

      const value = event.target.value;
      const rawValue = Number(value.replace(/[.,\s]/g, ""));

      if (isNaN(rawValue)) {
        return (innerRef.current.value = currencyFormatter(
          Currency.EUR,
          value.replace(/\D+/g, "")
        ));
      }

      innerRef.current.value = currencyFormatter(Currency.EUR, rawValue);

      if (onChangeCallback) {
        onChangeCallback(rawValue);
      }
    };

    return (
      <TextInput crossOrigin
        ref={innerRef}
        decorator={{
          type: "text",
          value: currencySymbolsDictionary[decorator],
          position: "right",
        }}
        onChange={(event) => onChange(event)}
        {...props}
      />
    );
  }
);

export default MonetaryInput;
