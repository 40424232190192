import { gql } from "@apollo/client";

export const CreateUploadUrls = gql`
  mutation CreateUploadUrls($files: CreateUploadUrlsInput!) {
    createUploadUrls(input: $files) {
      code
      success
      message
      documents {
        id
        uploadUrl
      }
    }
  }
`;

export const CreateDownloadUrl = gql`
  mutation CreateDownloadUrl($files: CreateDownloadUrlInput!) {
    createDownloadUrl(input: $files) {
      code
      success
      message
      downloadUrl
    }
  }
`;

export const DeleteDocument = gql`
  mutation DeleteDocument($id: DeleteDocumentInput!) {
    deleteDocument(input: $id) {
      code
      success
      message
    }
  }
`;
