import type { FormInput } from "@finvia/component-library";
import {
  cloneDeep as ld_cloneDeep,
  defaultsDeep as ld_defaultsDeep,
} from "lodash";
import { FormDefaultData } from "./graphQLPartial";
/**
 * Apply default values to an object (immutable, #first is cloned using lodash.cloneDeep)
 * @param first The object to fill up with default values if a value evaluates to undefined
 * @param objs The default values to apply if first[key] evaluates to undefined
 * @see lodash.defaultsDeep
 */
const formDefaultsDeep = <T>(
  first: FormInput<FormDefaultData<T>>,
  ...objs: FormInput<T>[]
): FormInput<T> => ld_defaultsDeep(ld_cloneDeep(first), ...objs);

export default formDefaultsDeep;
