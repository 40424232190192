import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../components/FormControl/FormControl";
import { ChoiceGroup, Grid, InfoBox, Stack, Text } from "@finvia/money-ui";
import { FormContent } from "../../../../../components/FormContent";
import { InvestmentHorizon } from "../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../store";
import formDefaultsDeep from "../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../components/QuestionnaireController";
import { updateProfile } from "../actions";
import {
  HorizonForm as HorizonFormType,
  validationSchema,
} from "./horizonValidations";

const translationPrefix = "q-ai.opportunities.form.horizon";

const investmentHorizons: InvestmentHorizon[] = [
  InvestmentHorizon.SHORT_TERM,
  InvestmentHorizon.MID_TERM,
  InvestmentHorizon.LONG_TERM,
];

const invalidHorizons: InvestmentHorizon[] = [
  InvestmentHorizon.SHORT_TERM,
  InvestmentHorizon.MID_TERM,
];

const HorizonForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<HorizonFormType>({
      horizon:
        state.alternativeInvestments.investmentOpportunities.data?.horizon,
    })
  );
  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data: HorizonFormType) => {
      dispatch(updateProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<HorizonFormType>
      backLabel={t("form.back")}
      defaultValues={defaultData}
      onBack={previousStep}
      onSubmit={onSubmit}
      submitLabel={t("form.continue")}
      validationSchema={memoizedSchema}
      validationMode="onChange"
      dataAnalytics="1.2_investment_horizon"
    >
      {(formMethods) => {
        const { watch, register } = formMethods;
        const selectedHorizon = watch("horizon");

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
          >
            <>
              <ChoiceGroup
                label="investmentHorizon"
                name="investmentHorizon"
                hideLabel
              >
                <Grid gap="mega" columns={{ sm: 1, md: 3 }}>
                  {investmentHorizons.map((horizon) => (
                    <Stack gutter="mega" alignItems="center" key={horizon}>
                      <ChoiceGroup.Button
                        ref={register}
                        name="horizon"
                        value={horizon}
                        label={t(
                          `${translationPrefix}.horizons.${horizon}.label`
                        )}
                      />
                      <Text align="center">
                        {t(
                          `${translationPrefix}.horizons.${horizon}.description`
                        )}
                      </Text>
                    </Stack>
                  ))}
                </Grid>
              </ChoiceGroup>

              {selectedHorizon && invalidHorizons.includes(selectedHorizon) && (
                <InfoBox icon="error" variant="danger">
                  {t(
                    `${translationPrefix}.validations.warning_should_be_long_term`
                  )}
                </InfoBox>
              )}
            </>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default HorizonForm;
