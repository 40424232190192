import { Icon, SelectInput, TextInput } from "@finvia/money-ui";
import { useTranslation } from "react-i18next";
import * as S from "./ResidencyField.styled";
import { UploadField } from "features/alternative-investments/components/UploadField";
import { ResidencyFieldProps } from "../TaxInformation.types";
import { Controller } from "react-hook-form";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

const ResidencyField: React.FC<ResidencyFieldProps> = ({
  index,
  formMethods,
  item,
  onRemove,
  onUpload,
  onDelete,
  documents,
  countriesList,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <S.ExtraFieldWrapper>
        <Controller
          name={`taxInformation.residencies[${index}].country`}
          control={formMethods.control}
          defaultValue={item.country}
          as={
            <SelectInput
              ref={formMethods.register}
              name={`taxInformation.residencies[${index}].country`}
              label={t(
                `${translationPrefix}.fields.taxResidency.fields.country.second`
              )}
              required
              {...(item.country && {
                value: item.country,
              })}
              options={countriesList}
              searchable
              errorMessage={
                formMethods.errors.taxInformation?.residencies?.[index]?.country
                  ?.message
              }
            />
          }
        />
        <S.RemoveIconWrapper
          data-testid={`taxInfo.residencies[${index}].delete`}
          onClick={onRemove}
        >
          <Icon name="trash" color="petrol.600" />
        </S.RemoveIconWrapper>
      </S.ExtraFieldWrapper>
      <Controller
        name={`taxInformation.residencies[${index}].taxId`}
        control={formMethods.control}
        defaultValue={item.taxId}
        as={
          <TextInput crossOrigin
            ref={formMethods.register}
            name={`taxInformation.residencies[${index}].taxId`}
            required
            label={t(
              `${translationPrefix}.fields.taxResidency.fields.taxId.label`
            )}
            errorMessage={
              formMethods.errors?.taxInformation?.residencies?.[index]?.taxId
                ?.message
            }
            defaultValue={item.taxId}
            helperText={t(
              `${translationPrefix}.fields.taxResidency.fields.taxId.secondTooltip`
            )}
          />
        }
      />
      <Controller
        name={`taxInformation.residencies[${index}].localTaxOffice`}
        control={formMethods.control}
        defaultValue={item.localTaxOffice ?? ""}
        as={
          <TextInput crossOrigin
            ref={formMethods.register}
            name={`taxInformation.residencies[${index}].localTaxOffice`}
            required
            label={t(
              `${translationPrefix}.fields.taxResidency.fields.localTaxOffice.label`
            )}
            errorMessage={
              formMethods.errors?.taxInformation?.residencies?.[index]
                ?.localTaxOffice?.message
            }
            defaultValue={item.localTaxOffice ?? ""}
          />
        }
      />
      <Controller
        name={`taxInformation.residencies[${index}].residencyCertificate`}
        control={formMethods.control}
        defaultValue={
          documents?.[
            `taxInformation.residencies[${index}].residencyCertificate`
          ] ?? []
        }
        as={
          <UploadField
            name={`taxInformation.residencies[${index}].residencyCertificate`}
            onUpload={(documentslist, file) => {
              onUpload(documentslist, file, formMethods.setValue, index);
            }}
            onDelete={(document, documentslist) => {
              onDelete(
                document,
                documentslist,
                `taxInformation.residencies[${index}].residencyCertificate`,
                formMethods.setValue,
                index
              );
            }}
            documentsList={
              documents?.[
                `taxInformation.residencies[${index}].residencyCertificate`
              ] ?? []
            }
            label={t(
              `${translationPrefix}.fields.taxResidency.fields.residencyCertificate.label`
            )}
            helperText={t(
              `${translationPrefix}.fields.taxResidency.fields.residencyCertificate.tooltip`
            )}
            inputErrorMessage={
              formMethods.errors?.taxInformation?.residencies?.[index]
                ?.residencyCertificate?.message
            }
            barErrorMessage={t("form.fileUpload.failed")}
            barSuccessMessage={t("form.fileUpload.succeeded")}
            formMethods={formMethods}
            disabled={
              documents?.[
                `taxInformation.residencies[${index}].residencyCertificate`
              ]?.length === 1
            }
          />
        }
      />
    </>
  );
};

export default ResidencyField;
