import { Grid } from "@finvia/money-ui";
import React from "react";
import { DownloadButton } from "@finvia/money-ui";
import { Container } from "./Documents.styled";
import { Document } from "../FundDetails";
import BlockDialog from "../BlockDialog/BlockDialog";

interface Props {
  documents: Record<string, Document>;
  displayDialog: boolean;
  onClick: (document: Document) => void;
}
const Documents: React.FC<Props> = ({
  documents,
  displayDialog = true,
  onClick,
}) => {
  const documentsKeys = Object.keys(documents);

  return (
    <Container id="fund-details__documents-section">
      <Grid columns={1} rowsGap="mega">
        {documentsKeys.map((documentName) => (
          <DownloadButton
            key={documents[documentName].name}
            displayName={documents[documentName].name ?? ""}
            status={documents[documentName].status}
            onClick={() => onClick(documents[documentName])}
            fileURL={
              documents[documentName].link ??
              documents[documentName].file?.data?.attributes?.url
            }
            disabled={displayDialog}
          />
        ))}
      </Grid>
      {displayDialog && <BlockDialog />}
    </Container>
  );
};

export default Documents;
