import styled, { css } from "styled-components";

const videoStyles = () => css`
  width: 100%;
  height: 250px;
  object-fit: cover;
`;

type OverlayStyleProps = {
  visible: boolean;
};

const overlayStyles = ({ visible }: OverlayStyleProps) => css`
  transition: opacity 0.3s ease-in-out;
  opacity: ${visible ? 1 : 0};
  pointer-events: ${visible ? "inherit" : "none"};
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const playButtonStyles = () => css`
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &::before {
    display: block;
    content: " ";
    border: 4px solid white;
    border-radius: 50%;
    width: 75px;
    height: 75px;
  }

  &::after {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 8px);
    content: " ";
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 25px solid white;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledVideo = styled.video(videoStyles);
export const Overlay = styled.div<OverlayStyleProps>(overlayStyles);
export const PlayButton = styled.span(playButtonStyles);
