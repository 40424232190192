import { Box, Icon, Stack, Text } from "@finvia/money-ui";
import TooltipInfo from "features/alternative-investments/components/TooltipInfo";
import { LegalEntityRegulatoryDataFormStep } from "features/alternative-investments/formSteps";
import { useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { Wrapper } from "./AddPerson.styled";

const AddPerson = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToAddPerson = () =>
    history.push(
      generatePath(ROUTES.questionnaires.ai.regulatory.legalEntity.addPerson, {
        id: LegalEntityRegulatoryDataFormStep.AddLegalEntityPersons,
      })
    );

  return (
    <Wrapper onClick={goToAddPerson}>
      <Stack
        gutter="giga"
        direction={{ sm: "column", lg: "row" }}
        alignItems="center"
      >
        <Icon size="mega" name="add" />
        <Box>
          <Stack direction="row" gutter="bit" justifyContent="center">
            <Text align="center">{t("components.addPersonButton.label")}</Text>
            <TooltipInfo label={t("components.addPersonButton.tooltip")} />
          </Stack>
        </Box>
      </Stack>
    </Wrapper>
  );
};

export default AddPerson;
