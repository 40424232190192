import { Heading, Text, Stack, Box, Grid } from "@finvia/money-ui";
import type { VideoHighlightProps } from "./VideoHighlight.types";
import Video from "../Video/Video";

const VideoHighlight = ({
  videoContent,
}: VideoHighlightProps): JSX.Element | null => {
  if (videoContent === null) {
    return null;
  }

  return (
    <Grid columns={{ sm: 1, md: 2 }} gap={{ sm: "mega", md: "tera" }}>
      <Video cover={videoContent.cover} video={videoContent.video} />
      <Box display="flex" alignItems="center">
        <Stack gutter="mega">
          <Heading as="h2" size={{ sm: 5, lg: 6 }}>
            {videoContent.title}
          </Heading>
          <Text>{videoContent.description}</Text>
        </Stack>
      </Box>
    </Grid>
  );
};

export default VideoHighlight;
