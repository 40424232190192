/* eslint-disable filenames/match-exported */
import { gql } from "@apollo/client";

export const FUND_FRAGMENT = gql`
  fragment FundFragment on AlternativeInvestmentFundEntity {
    id
    attributes {
      slug
      status
      name
      minimumCommitmentAmount
      order
      fundManagerImage {
        data {
          attributes {
            alternativeText
            url
          }
        }
      }
      successMessage
      assetClass
      shortDescription
      status
      targetFundSize
      strategy
      type
      currency
      investmentPeriod
      estimatedClosing
      firstClosing
      lastClosing
      investmentType
      legalStructure
      region
      typeOfUse
      leverageLimit
      targetReturn
      lifetime
      showAcquisitionOfShareholdingFile
      showRiskOfAcquisitionFile
      showTrustAndAdministrationContractFile
      additionalTagsList {
        additionalTag
      }
      spotlights {
        key
        value
      }
      industries {
        icon
        value
      }
      highlights {
        id
        text
      }
      trackRecord {
        profile
        images {
          data {
            attributes {
              url
            }
          }
        }
        standardFundTable {
          fundGeneration
          vintageYear
          fundVolume
          invested
          investedRealized
          totalValue
        }
        selectionFundTable {
          fundID
        }
        tableFooter
        imagesTitle
        tableTitle
      }
      legalTraits(pagination: { limit: -1 }) {
        id
        key
        value
      }
      documents {
        id
        name
        link
        file {
          data {
            attributes {
              url
            }
          }
        }
      }
      additionalDocument {
        data {
          id
          attributes {
            url
          }
        }
      }
      trustAndAdministrationDocument {
        data {
          id
          attributes {
            url
          }
        }
      }
      fundSummary {
        id
        name
        link
        file {
          data {
            attributes {
              url
            }
          }
        }
      }
      highlightVideo {
        id
        title
        description
        cover {
          data {
            attributes {
              url
            }
          }
        }
        video {
          data {
            attributes {
              url
            }
          }
        }
      }
      profile {
        manager {
          AUM
          foundationDate
          locations
          investedSum
          investedFundsCount
          fundGenerations
          team
        }
        investmentExamples {
          company {
            data {
              attributes {
                url
              }
            }
          }
          mutipleGross
          companyOverview
          valueAdd
        }
        investmentPotential {
          fundID
        }
      }
    }
  }
`;

export const GetFundsCollection = gql`
  query FundsCollection {
    alternativeInvestmentFunds(sort: "order:asc", pagination: { limit: -1 }) {
      data {
        ...FundFragment
      }
    }
    profile {
      wishListFunds {
        potentialCommitment {
          currency
          value
        }
        slug
      }
    }
  }
  ${FUND_FRAGMENT}
`;

export const GetFundBySlug = gql`
  query FundsBySlug($slug: String!) {
    alternativeInvestmentFunds(filters: { slug: { eq: $slug } }) {
      data {
        ...FundFragment
      }
    }
  }
  ${FUND_FRAGMENT}
`;
