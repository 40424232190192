import React from "react";
import { useTranslation } from "react-i18next";
import CheckboxPanel from "../../../components/CheckboxPanel";
import { Stack } from "@finvia/money-ui";

interface FundCheckboxesProps {
  checkboxes: {
    name: string;
    defaultValue: boolean | undefined;
    translationPrefix: string;
    terms?: string[];
  }[];
}

const FundCheckboxes: React.FC<FundCheckboxesProps> = ({ checkboxes }) => {
  const { t } = useTranslation();

  return (
    <Stack gutter="giga">
      {checkboxes.map((checkbox) => (
        <CheckboxPanel
          key={checkbox.name}
          label={t(`${checkbox.translationPrefix}.label`)}
          name={`signing.${checkbox.name}`}
          defaultValue={checkbox.defaultValue}
          description={t(`${checkbox.translationPrefix}.description`)}
          terms={checkbox.terms}
        />
      ))}
    </Stack>
  );
};

export default FundCheckboxes;
