import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import InputGrid from "../../../../../../components/InputGrid/InputGrid";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { LegalForm } from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import { AnyAction, SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { validationSchema } from "./DocumentsUpload.schema";
import {
  updateDocumentsData,
  updateEmploymentAndPersonalIrsDocumentData,
  updateLegalEntityProfile,
} from "../actions";
import {
  DocumentCategoryNames,
  DocumentUploadStore,
  HandleDocumentUpload,
  HandleDocumentDelete,
} from "./DocumentsUpload.types";
import {
  documentCategoriesToRender,
  inputNameToDocumentCategory,
} from "./utils/utils";
import { fieldNameToDocumentCategory } from "utils/documentUpload";
import { DocumentChange } from "models/documents";
import {
  handleDeleteDocumentGeneric,
  handleDocumentUploadGeneric,
  mapDocumentStatusToUpload,
  UploadField,
} from "features/alternative-investments/components/UploadField";

export const translationPrefix =
  "q-ai.opportunities.form.legalEntityDocumentsUpload";

const DocumentsUploadForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);
  const registrationType: LegalForm | undefined = typedUseSelector((store) => {
    const result =
      store.alternativeInvestments.regulatoryData?.legalEntityData
        ?.legalEntityData?.registrationType;

    if (result) {
      return result;
    } else {
      if (!error)
        setError({
          name: "test",
          message: t(`${translationPrefix}.registrationTypeError`),
        });
      return undefined;
    }
  });

  useEffect(() => {
    setSidebarOverride(
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} lg={12}>
          <SidebarContentBlock
            iconType="asset-management"
            iconSize="byte"
            iconColor="petrol.600"
            title={t("sidebar.LegalEntityDetails.title")}
            description={t("sidebar.LegalEntityDetails.description")}
          />
        </Grid>
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData?.legalEntityData.documents ||
      {}
  );

  const [currentDocumentsList, setCurrentDocumentsList] = useState<
    DocumentUploadStore | undefined
  >({ documents: defaultData });

  const dispatch = useDispatch();
  const onSubmit = async () => {
    dispatch(
      updateLegalEntityProfile({ documents: currentDocumentsList?.documents })
    )
      .then(unwrapResult)
      .then(() => {
        nextStep();
      })
      .catch((e: SerializedError) => {
        setError(e);
      });
  };

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    name,
    setValueReference
  ) => {
    const documentCategory = inputNameToDocumentCategory(name);

    const filteredDocuments = await handleDeleteDocumentGeneric<
      (payload: {
        changedDocuments: DocumentChange[];
        documentCategory: DocumentCategoryNames;
      }) => AnyAction
    >({
      document,
      documents,
      setValueReference,
      dispatch,
      updateAction: updateDocumentsData,
      setError,
      name: name,
      formName: "DocumentUploadForm",
      extraProps: { documentCategory: documentCategory },
    }).then((res) => res);

    setCurrentDocumentsList({
      documents: {
        ...currentDocumentsList?.documents,
        [documentCategory]: { documents: filteredDocuments },
      },
    });
  };

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    name,
    setValueReference
  ) => {
    const documentCategoryName = inputNameToDocumentCategory(name);
    const documentCategory = fieldNameToDocumentCategory(
      inputNameToDocumentCategory(name)
    );

    const doc = await handleDocumentUploadGeneric({
      documents,
      file,
      setValueReference,
      dispatch,
      updateAction: updateEmploymentAndPersonalIrsDocumentData,
      categoryName: documentCategoryName,
      category: documentCategory,
      setError,
      name: `documents.${documentCategoryName}.documents`,
      formName: "DocumentsUploadForm",
    }).then((res) => res);

    setCurrentDocumentsList({
      documents: {
        ...currentDocumentsList?.documents,
        [documentCategoryName]: {
          documents: doc,
        },
      },
    });
  };

  const categories = documentCategoriesToRender(registrationType);

  const docsToRender = Object.keys(categories).filter(
    (doc) => categories[doc as DocumentCategoryNames]
  ) as DocumentCategoryNames[];

  return (
    <FormControl<DocumentUploadStore>
      dontShowErrors
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={validationSchema({ t, registrationType })}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      reValidationMode="onChange"
    >
      {(formMethods) => {
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
            customErrorMessage={error && error.message}
          >
            <InputGrid defaultColumnGap align="flex-start">
              {docsToRender.map((documentCategory) => {
                return (
                  <UploadField
                    key={`documents.${documentCategory}.documents`}
                    name={`documents.${documentCategory}.documents`}
                    onDelete={(document, documents) => {
                      handleDeleteDocument(
                        document,
                        documents,
                        `documents.${documentCategory}.documents`,
                        formMethods.setValue
                      );
                    }}
                    onUpload={(documents, file) => {
                      handleDocumentUpload(
                        documents,
                        file,
                        `documents.${documentCategory}.documents`,
                        formMethods.setValue
                      );
                    }}
                    documentsList={mapDocumentStatusToUpload(
                      currentDocumentsList?.documents?.[documentCategory]
                        ?.documents
                    )}
                    label={t(
                      `${translationPrefix}.fields.${documentCategory}.label`
                    )}
                    helperText={t(
                      `${translationPrefix}.fields.${documentCategory}.tooltip.${registrationType}`
                    )}
                    inputErrorMessage={
                      formMethods.formState.errors.documents?.hasOwnProperty(
                        documentCategory
                      )
                        ? t(
                            `${translationPrefix}.fields.${documentCategory}.required`
                          )
                        : undefined
                    }
                    barErrorMessage={t("form.fileUpload.failed")}
                    barSuccessMessage={t("form.fileUpload.succeeded")}
                    formMethods={formMethods}
                    defaultValue={mapDocumentStatusToUpload(
                      currentDocumentsList?.documents?.[documentCategory]
                        ?.documents
                    )}
                  />
                );
              })}
            </InputGrid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default DocumentsUploadForm;
