import { FundFragment_attributes_profile } from "generated/FundFragment";
import { ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY } from "generated/globalTypes";
import { TFunction } from "i18next";
import { containsOnlyNumbers } from "utils/containsOnlyNumbers";
import { fundCurrencyTranslation } from "utils/fundCurrencyTranslation";

export const managerData = ({
  manager,
  prefix,
  t,
  fundCurrency,
}: {
  manager: FundFragment_attributes_profile["manager"];
  prefix: string;
  t: TFunction;
  fundCurrency: ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY;
}): { key: string; value: string | null | undefined }[] => {
  const formatValueIfOnlyNumbers = (value: string | undefined | null) =>
    containsOnlyNumbers(value)
      ? t(fundCurrencyTranslation(fundCurrency), {
          value: value,
        })
      : value;

  return [
    {
      key: t(`${prefix}.firstSubSection.AUM`),
      value: manager?.AUM,
    },
    {
      key: t(`${prefix}.firstSubSection.foundationDate`),
      value: manager?.foundationDate,
    },
    {
      key: t(`${prefix}.firstSubSection.locations`),
      value: manager?.locations,
    },
    {
      key: t(`${prefix}.firstSubSection.team`),
      value: manager?.team,
    },
    {
      key: t(`${prefix}.firstSubSection.investedSum`),
      value: formatValueIfOnlyNumbers(manager?.investedSum),
    },
    {
      key: t(`${prefix}.firstSubSection.investedFundsCount`),
      value: manager?.investedFundsCount,
    },
    {
      key: t(`${prefix}.firstSubSection.fundGenerations`),
      value: manager?.fundGenerations,
    },
  ];
};
