import React, { useEffect, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import config from "../../../config";
import AddPersonForm from "../questionnaires/contractAndRegulatoryData/legalEntity/AddPersonsPage/AddPersonForm";
import { ROUTES } from "../../../routes/routes";
import { QuestionnaireLayout } from "./QuestionnaireLayout";

export interface StepComponentProps {
  nextStep: () => void;
  previousStep: () => void;
  isLegalEntity?: boolean;
}

export type Step = {
  id: number;
  component: React.ElementType<StepComponentProps>;
  overridePreviousStepValidityCheck?: boolean;
};
export type StepGroup = {
  label: string;
  selected?: boolean;
  steps: Step[];
};

interface QuestionnaireControllerProps {
  atMostAccessibleStep: number;
  stepGroups: StepGroup[];
  disableLayoutOnSteps?: number[];
  hideStepperOnSteps?: number[];
  isComplete?: boolean;
  onBack?: () => void;
  onComplete?: () => void;
}

const findNextStep = (
  steps: Step[],
  currentStepId: number
): Step | undefined => {
  return steps.reduce<Step | undefined>((left, right) => {
    const leftPossible = left && left.id > currentStepId;
    const rightPossible = right && right.id > currentStepId;

    if (!leftPossible && rightPossible) return right;
    if (leftPossible && !rightPossible) return left;

    if (!leftPossible && !rightPossible) return undefined;

    // leftPossible and rightPossible are not undefined at this point
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return left!.id > right!.id ? right : left;
  }, undefined);
};
const findPreviousStep = (
  steps: Step[],
  currentStepId: number
): Step | undefined => {
  return steps.reduce<Step | undefined>((left, right) => {
    const leftPossible = left && left.id < currentStepId;
    const rightPossible = right && right.id < currentStepId;

    if (!leftPossible && rightPossible) return right;
    if (leftPossible && !rightPossible) return left;

    if (!leftPossible && !rightPossible) return undefined;

    // leftPossible and rightPossible are not undefined at this point
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return left!.id < right!.id ? right : left;
  }, undefined);
};

const QuestionnaireController: React.FC<QuestionnaireControllerProps> = ({
  atMostAccessibleStep,
  stepGroups,
  disableLayoutOnSteps,
  hideStepperOnSteps,
  isComplete,
  onBack,
  onComplete,
}) => {
  const { path } = useRouteMatch();
  const { location } = useHistory();
  const allSteps: Step[] = useMemo(
    () => stepGroups.flatMap((sg) => sg.steps),
    [stepGroups]
  );
  const isLegalEntity = path.includes("legalEntity");

  useEffect(() => {
    if ((location?.state as any)?.scrollToTop) {
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [location.state]);

  return (
    <Switch>
      <Route
        path={[
          ROUTES.questionnaires.ai.regulatory.legalEntity.addPerson,
          ROUTES.questionnaires.ai.regulatory.legalEntity.editPerson,
        ]}
        render={({ match, history }) => {
          const pathId = Number(match.params.id);
          if (
            !config.featureFlags.stepperFreelyAccessible &&
            pathId > atMostAccessibleStep &&
            !isComplete
          ) {
            return <Redirect to={path} />;
          }

          return (
            <QuestionnaireLayout
              disableLayoutOnSteps={disableLayoutOnSteps}
              hideStepperOnSteps={hideStepperOnSteps}
              stepGroups={stepGroups}
              onSelectStepGroup={(stepGroupIndex) => {
                const firstStepInGroup = stepGroups[stepGroupIndex].steps[0];
                if (!firstStepInGroup)
                  throw new Error(
                    `Empty group at index ${stepGroupIndex} in list of steps: ${JSON.stringify(
                      allSteps
                    )}`
                  );
                if (
                  !config.featureFlags.stepperFreelyAccessible &&
                  atMostAccessibleStep < firstStepInGroup.id
                )
                  return;

                history.push(`${path}/${firstStepInGroup.id}`);
              }}
            >
              <AddPersonForm />
            </QuestionnaireLayout>
          );
        }}
      />
      <Route
        path={`${path}/:id`}
        render={({ match, history }) => {
          const pathId = Number(match.params.id);

          const step = allSteps.find(({ id }) => id === pathId);
          if (!step) {
            throw new Error(
              `Could not find step ${pathId} in list of defined steps: ${JSON.stringify(
                allSteps
              )}`
            );
          }

          if (
            !config.featureFlags.stepperFreelyAccessible &&
            pathId > atMostAccessibleStep &&
            !isComplete &&
            !step.overridePreviousStepValidityCheck
          ) {
            return <Redirect to={path} />;
          }

          const previousStep = findPreviousStep(allSteps, pathId);
          let gotoPreviousStepTrigger: () => void;
          if (previousStep) {
            gotoPreviousStepTrigger = () =>
              window.setTimeout(
                () =>
                  history.push(`${path}/${previousStep.id}`, {
                    scrollToTop: true,
                  }),
                0
              );
          } else {
            gotoPreviousStepTrigger = () =>
              window.setTimeout(() => onBack && onBack(), 0);
          }

          const nextStep = findNextStep(allSteps, pathId);
          let gotoNextStepTrigger: () => void;
          if (nextStep) {
            gotoNextStepTrigger = () =>
              window.setTimeout(
                () =>
                  history.push(`${path}/${nextStep.id}`, { scrollToTop: true }),
                0
              );
          } else {
            gotoNextStepTrigger = () =>
              window.setTimeout(() => onComplete && onComplete(), 0);
          }

          const Component = step.component;

          return (
            <QuestionnaireLayout
              disableLayoutOnSteps={disableLayoutOnSteps}
              hideStepperOnSteps={hideStepperOnSteps}
              stepGroups={stepGroups}
              onSelectStepGroup={(stepGroupIndex) => {
                const firstStepInGroup = stepGroups[stepGroupIndex].steps[0];
                if (!firstStepInGroup)
                  throw new Error(
                    `Empty group at index ${stepGroupIndex} in list of steps: ${JSON.stringify(
                      allSteps
                    )}`
                  );
                if (
                  !config.featureFlags.stepperFreelyAccessible &&
                  atMostAccessibleStep < firstStepInGroup.id
                )
                  return;

                history.push(`${path}/${firstStepInGroup.id}`);
              }}
            >
              <Component
                {...{
                  nextStep: gotoNextStepTrigger,
                  previousStep: gotoPreviousStepTrigger,
                  isLegalEntity,
                }}
              />
            </QuestionnaireLayout>
          );
        }}
      />

      <Route>
        <Redirect to={`${path}/${atMostAccessibleStep}`} />
      </Route>
    </Switch>
  );
};

export default QuestionnaireController;
