export const getKeyFromValue = <T extends string, TEnumValue extends string>(
  value: string | undefined | null,
  enumVariable: { [key in T]: TEnumValue }
): keyof typeof enumVariable => {
  const indexOfValue = Object.values(enumVariable).indexOf(
    value as unknown as T
  );

  return Object.keys(enumVariable)[indexOfValue] as keyof typeof enumVariable;
};
