import { Theme } from "@finvia/money-ui";
import styled, { css, keyframes } from "styled-components";

const notNecessary = ({ theme }: { theme: Theme }) => css`
  color: ${theme.tokens.colors.neutral[500]};
`;

const wrapper = () => css`
  position: relative;
  width: 100%;
`;

const tooltipAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

type TooltipStyleProps = {
  theme: Theme;
  isOpen: boolean;
};

const tooltipHidden = ({ theme }: TooltipStyleProps) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  width: 150px;
  height: 25px;
  z-index: ${theme.tokens.zIndex[2]};
  box-shadow: 0 2px 14px 0 rgb(0 0 0 / 20%);
  padding: ${theme.tokens.sizes.spacings.byte}
    ${theme.tokens.sizes.spacings.kilo};
  border-radius: ${theme.tokens.sizes.radii.byte};
  background: ${theme.tokens.colors.white[500]};
  color: ${theme.tokens.colors.petrol[600]};
  animation: ${tooltipAnimation} 0.2s linear;
  display: none;
`;

const tooltipShow = ({ isOpen }: TooltipStyleProps) =>
  isOpen &&
  css`
    display: flex;
  `;

const caret = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  content: "";
  border-bottom-color: ${theme.tokens.colors.white[500]};
  top: 25px;
  left: calc(50% - 5px px);
  transform: rotate(180deg);
`;

export const NotNecessary = styled.span<{ theme: Theme }>(notNecessary);
export const Wrapper = styled.div(wrapper);
export const Tooltip = styled.div<TooltipStyleProps>(
  tooltipHidden,
  tooltipShow
);
export const Caret = styled.div<{ theme: Theme }>(caret);
