import { SelectInput, TextInput, Stack, Icon, Grid } from "@finvia/money-ui";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { countries } from "utils/options/countries";
import { EmploymentLegalEntity, Residency } from "../Employment.schema";
import { TEMPDeleteIcon } from "../Employment.styles";

interface ResidencyFieldProps {
  index: number;
  lastItem: boolean;
  item: Partial<Residency>;
  onRemove: () => void;
}

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData.fields";

const ResidencyField: React.FC<ResidencyFieldProps> = ({
  index,
  item,
  onRemove,
}) => {
  const { t } = useTranslation();
  const { register, errors, getValues } =
    useFormContext<EmploymentLegalEntity>();

  const additionalResidency = index !== 0;

  return (
    <>
      <SelectInput
        ref={register}
        name={`taxInfo.residencies[${index}].country`}
        key={`taxInfo.residencies[${index}].country`}
        label={t(
          `${translationPrefix}.taxInfo.residencies.country.${
            additionalResidency ? "second" : "label"
          }`
        )}
        required
        value={item.country}
        options={countries(t)}
        errorMessage={errors.taxInfo?.residencies?.[index]?.country?.message}
        searchable
        testId="residency.primary"
      />
      {/* TODO: #6465. Fix when #6463 is merged. */}
      <Grid columns={{ sm: "1fr 25px" }} columnsGap={"mega"}>
        <TextInput crossOrigin
          ref={register}
          name={`taxInfo.residencies[${index}].taxId`}
          key={`taxInfo.residencies[${index}].taxId`}
          label={t(
            `${translationPrefix}.taxInfo.residencies.taxId.${
              additionalResidency ? "second" : "label"
            }`
          )}
          required
          defaultValue={item.taxId}
          helperText={t(
            `${translationPrefix}.taxInfo.residencies.taxId.tooltip`
          )}
          errorMessage={errors.taxInfo?.residencies?.[index]?.taxId?.message}
        />
        {/* TODO: Fix when #6472 is merged. Remove the div and add onClick to icon directly*/}
        {additionalResidency && (
          <div onClick={onRemove}>
            <TEMPDeleteIcon name="trash" color="petrol.600" />
          </div>
        )}
      </Grid>
    </>
  );
};

export default ResidencyField;
