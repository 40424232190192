import { FinviaTheme } from "@finvia/component-library";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { FundFragment_attributes_legalTraits } from "../../../../generated/FundFragment";
import { Heading, Text } from "@finvia/money-ui";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "22px",
    },
  },
  list: {
    listStyle: "none",
    padding: 0,
    display: "grid",
    gap: "30px",
    [theme.breakpoints.down("sm")]: {
      gap: "15px",
    },
  },
  listItem: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "initial",
      gap: "10px",
    },
  },
  content: {
    color: FinviaTheme.Palette.black[300],
  },
  greyText: {
    // we don't support this color yet on Money-UI Text component
    "& p": { color: FinviaTheme.Palette.grey[900] },
  },
}));

interface Props {
  legalTraits: FundFragment_attributes_legalTraits[];
}

const Legal: React.FC<Props> = ({ legalTraits = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <header className={classes.header}>
        <Heading as="h2" size={{ sm: 5, md: 5, lg: 6, xl: 6, xxl: 6 }}>
          {t("components.alternativeInvestmentFund.legal.title")}
        </Heading>
      </header>
      <ul className={classes.list}>
        {legalTraits.map((item) => {
          return (
            <li key={item.id} className={classes.listItem}>
              <div className={classes.greyText}>
                <Text size={{ sm: 2, md: 3 }}>{item.key}</Text>
              </div>

              <div className={classes.content}>
                <Text size={{ sm: 2, md: 3 }}>{item.value}</Text>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Legal;
