import { Option } from "@finvia/money-ui";
import { WealthOrigin } from "generated/globalTypes";
import { TFunction } from "i18next";
import { FormFields } from "./ExperienceCurrencies.types";

type KeyOfWealthOrigin = keyof typeof WealthOrigin;

export const wealthOptions = (t: TFunction): Option[] => {
  const translationPrefix = "q-ai.opportunities.form.experienceCurrency";

  const wealthValues: Record<WealthOrigin, string> = t(
    `${translationPrefix}.fields.wealthOrigin.values`,
    {
      returnObjects: true,
    }
  );

  return Object.keys(wealthValues).map<Option>((wealth) => ({
    value: wealth,
    text: wealthValues[wealth as KeyOfWealthOrigin],
  }));
};

export const experienceCurrenciesFields = (
  t: TFunction,
  i18nPrefix: string
): FormFields[] => [
  {
    title: t(`${i18nPrefix}.subTitle1`),
    fields: ["foreignCurrencies", "loanBasedForeignCurrencies"],
  },
  {
    title: t(`${i18nPrefix}.subTitle2`),
    fields: [
      "consultingFreeOrderExecution",
      "wealthManagement",
      "investmentConsulting",
    ],
  },
];
