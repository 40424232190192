import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, SelectInput, TextInput, Icon } from "@finvia/money-ui";
import { ExtraFieldWrapper, RemoveIconWrapper } from "./ResidencyField.styled";
import { typedUseSelector } from "store";
import { useParams } from "react-router-dom";
import { ResidencyFieldProps } from "./ResidencyFields.types";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const ResidencyField: React.FC<ResidencyFieldProps> = ({
  index,
  item,
  onRemove,
  formMethods,
  countriesList,
}) => {
  const { t } = useTranslation();
  const { personId } = useParams<{ personId: string }>();
  const { register, errors, watch } = formMethods;

  const personDefaultData = typedUseSelector((store) => {
    const additionalPersons =
      store.alternativeInvestments.regulatoryData?.legalEntityData
        .legalEntityAdditionalPersons || [];

    return additionalPersons?.find(
      (additionalPerson) => additionalPerson.id === personId
    );
  });

  return (
    <Grid
      key={item.id}
      columns={{ sm: 1, md: 2 }}
      columnsGap={{ md: "yotta" }}
      rowsGap="mega"
    >
      {index === 0 ? (
        <Controller
          name={`taxInfo[0].country`}
          defaultValue={personDefaultData?.taxInfo?.[0]?.country ?? undefined}
          as={
            <SelectInput
              required
              searchable
              ref={register}
              name={`taxInfo[0].country`}
              label={t(`${translationPrefix}.fields.taxInfo.country.label`)}
              options={countriesList}
              errorMessage={errors?.taxInfo?.[0]?.country?.message}
            />
          }
        />
      ) : (
        <ExtraFieldWrapper>
          <Controller
            name={`taxInfo[${index}].country`}
            as={
              <SelectInput
                required
                searchable
                ref={register}
                name={`taxInfo[${index}].country`}
                label={t(`${translationPrefix}.fields.taxInfo.country.second`)}
                options={countriesList}
                value={
                  personDefaultData?.taxInfo?.[index]?.country ?? undefined
                }
                errorMessage={errors?.taxInfo?.[index]?.country?.message}
              />
            }
          />
          <RemoveIconWrapper
            onClick={() => {
              onRemove(index);
            }}
            data-testid={`taxInfo[${index}].delete`}
          >
            <Icon name="trash" size="mega" />
          </RemoveIconWrapper>
        </ExtraFieldWrapper>
      )}

      <TextInput crossOrigin
        required
        ref={register}
        label={
          index !== 0
            ? t(`${translationPrefix}.fields.taxInfo.taxId.second`)
            : t(`${translationPrefix}.fields.taxInfo.taxId.label`)
        }
        name={`taxInfo[${index}].taxId`}
        helperText={t(`${translationPrefix}.fields.taxInfo.taxId.tooltip`)}
        defaultValue={personDefaultData?.taxInfo?.[index]?.taxId ?? undefined}
        errorMessage={errors?.taxInfo?.[index]?.taxId?.message}
      />
    </Grid>
  );
};

export default ResidencyField;
