import { useTranslation } from "react-i18next";

import { Card, Grid, Heading, Stack, Table } from "@finvia/money-ui";
import {
  customTableStyles,
  Footer,
  ImageWrapper,
  TableWrapper,
} from "./StandardFund.styled";
import { getHeaders, getRows } from "./utils";
import { StandardFundProps } from "./StandardFund.types";
import { breakLines } from "utils/breakLinesString";

const StandardFund = ({
  standardFundTableData,
  footerText,
  images,
  tableTitle,
  imagesTitle,
  fundCurrency,
}: StandardFundProps): React.ReactElement | null => {
  const { t } = useTranslation();

  if (!standardFundTableData) {
    console.error(
      `@StandardFundTable does not exist in the TrackRecord section`
    );
    return null;
  }

  return (
    <Stack gutter="tera">
      <Stack gutter="giga">
        {imagesTitle && (
          <Heading as="h2" size={{ sm: 5, lg: 6 }}>
            {imagesTitle}
          </Heading>
        )}
        <Grid gap="giga" columns={{ sm: 1, md: 2 }}>
          {images?.data?.map((image) => (
            <Card
              key={image?.attributes?.url}
              borderWidth="bit"
              borderStyle="solid"
              borderColor="secondary"
            >
              <ImageWrapper src={image?.attributes?.url} alt="img" />
            </Card>
          ))}
        </Grid>
      </Stack>
      <Stack gutter="giga">
        {tableTitle && (
          <Heading as="h2" size={{ sm: 5, lg: 6 }}>
            {tableTitle}
          </Heading>
        )}
        <TableWrapper>
          <Table
            headers={getHeaders(t)}
            rows={getRows({
              standardFundTableData,
              fundCurrency,
              t,
            })}
            customStyles={customTableStyles}
          />
        </TableWrapper>
      </Stack>
      {footerText && (
        <Footer>{breakLines({ text: footerText, char: "/" })}</Footer>
      )}
    </Stack>
  );
};

export default StandardFund;
