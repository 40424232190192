import {
  Heading,
  TextInput,
  Grid,
  ChoiceGroup,
  Stack,
  SelectInput,
  Telephone,
  countryCodes,
  Button,
  Box,
  Icon,
} from "@finvia/money-ui";
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import removeLeadingZeroFromDEPhoneNumber from "../../../../../../utils/removeLeadingZeroFromDEPhoneNumber";
import { LegalEntityRegulatoryDataFormStep } from "../../../../formSteps";
import { upsertLegalEntityAdditionalPerson } from "../actions";
import { LegalEntityId } from "../legalEntityValidations";
import { ROUTES } from "../../../../../../routes/routes";
import { IdentityDocuments } from "./BeneficialOwnerData";
import TaxInformation from "./TaxInformation/TaxInformation";

import {
  AdditionalPersonProps,
  validationSchema,
  validationSchemaBeneficialOwner,
} from ".";
import { countries } from "utils/options/countries";
import { buildOptions } from "utils/options/buildObject";
import {
  ExtraFieldWrapper,
  RemoveIconWrapper,
} from "./TaxInformation/ResidencyField.styled";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const AddPersonForm: React.FC = () => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { personId: id } = useParams<{ personId: string }>();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);
  const stepNumber = LegalEntityRegulatoryDataFormStep.AddLegalEntityPersons;

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }} gap="mega">
        <SidebarContentBlock
          iconType="people"
          iconSize="mega"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityRepresentative.title")}
          description={t("sidebar.legalEntityRepresentative.description")}
          textAlign="left"
        />
        <SidebarContentBlock
          iconType="pie-chart"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityBeneficialOwner.title")}
          description={t("sidebar.legalEntityBeneficialOwner.description")}
          textAlign="left"
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const handleBackToTable = useCallback(() => {
    history.push(
      generatePath(
        `${ROUTES.questionnaires.ai.regulatory.legalEntity.root}/${stepNumber}`,
        { id: stepNumber }
      ),
      {
        scrollToTop: true,
      }
    );
  }, [history, stepNumber]);

  const additionalPersons = typedUseSelector((store) => {
    return (
      store.alternativeInvestments.regulatoryData?.legalEntityData
        .legalEntityAdditionalPersons || []
    );
  });

  const person: any = additionalPersons?.find(
    (additionalPerson) => additionalPerson.id === id
  );

  const companyEquityPercentage = typedUseSelector((state) => {
    return parseFloat(
      state.alternativeInvestments.regulatoryData.legalEntityData.companyEquityPercentage?.split(
        " "
      )[0] || "0"
    );
  });

  const legalEntityId = typedUseSelector((state) => {
    return state.alternativeInvestments.regulatoryData.legalEntityData
      .legalEntityId as LegalEntityId; // The type cast is only for now. We need to rework the backend type but this involves more work!
  });

  // filtering the current user so we can edit it's percentage without it being added to the sum of all Additional Persons.
  const additionalPersonsEquityPercentage = additionalPersons
    .filter((additionalPerson) => additionalPerson.id !== id)
    .reduce((prev, curr) => {
      return (
        prev +
        parseFloat(
          curr.beneficialOwner?.companyEquityPercentage?.split(" ")[0] || "0"
        )
      );
    }, 0);

  const totalCompanyEquityPercentage = useMemo(() => {
    return (
      companyEquityPercentage + additionalPersonsEquityPercentage
    ).toString();
  }, [companyEquityPercentage, additionalPersonsEquityPercentage]);

  const defaultData = useMemo(() => {
    return formDefaultsDeep<AdditionalPersonProps>(person, {
      isLegalRepresentative: "",
      beneficialOwner: {
        isBeneficialOwner: "",
        companyEquityPercentage: "",
      },
      identityDocument: {
        number: "",
        expirationDate: "",
        documents: [],
      },
      personalData: {
        salutation: undefined,
        professionalTitle: undefined,
        firstName: "",
        familyName: "",
        birthName: "",
        dateOfBirth: "",
        cityOfBirth: "",
        countryOfBirth: "",
      },
      citizenship: {
        primaryCitizenship: undefined,
        additionalCitizenships: [],
      },
      contactData: {
        phoneNumber: "+49",
        emailAddress: "",
        legalAddress: {
          streetName: "",
          streetNumber: "",
          addition: "",
          postalCode: "",
          city: "",
          country: undefined,
        },
      },
      role: "",
      taxInfo: [
        {
          country: "",
          taxId: "",
        },
      ],
      wealthOrigin: "",
    });
  }, [person]);

  // we need this piece of state so the schema can be updated in real time
  // depending on what the user selects in the beneficial owner field
  const [isBeneficialOwner, setIsBeneficialOwner] = useState<boolean>(
    defaultData.beneficialOwner?.isBeneficialOwner === "yes"
  );

  const schema = isBeneficialOwner
    ? validationSchemaBeneficialOwner(t, totalCompanyEquityPercentage)
    : validationSchema(t, totalCompanyEquityPercentage);

  const onSubmit = useCallback(
    async (formData: AdditionalPersonProps) => {
      const cleanedUpData = {
        ...formData,
        contactData: {
          ...formData.contactData,
          phoneNumber: removeLeadingZeroFromDEPhoneNumber(
            formData.contactData?.phoneNumber
          ),
        },
      } as AdditionalPersonProps;

      dispatch(
        upsertLegalEntityAdditionalPerson({
          ...cleanedUpData,
          id: person?.id,
          legalEntityId,
        })
      )
        .then(unwrapResult)
        .then(() => {
          handleBackToTable();
        })
        .catch((e: SerializedError) => {
          console.log(e);
          setError(e);
        });
    },
    [dispatch, handleBackToTable, person?.id, legalEntityId]
  );

  return (
    <FormControl<AdditionalPersonProps>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={handleBackToTable}
      validationSchema={schema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.18_le_add_person_form"
    >
      {(formMethods) => {
        const { control, watch, register, errors, trigger } = formMethods;

        /* eslint-disable-next-line react-hooks/rules-of-hooks */
        const { fields, append, remove } = useFieldArray({
          control: control,
          name: "citizenship.additionalCitizenships",
        });

        const isBeneficialOwnerWatch = watch(
          "beneficialOwner.isBeneficialOwner"
        );
        const watchCountry = watch("contactData.legalAddress.country");
        const isLegalRepresentativeWatch = watch("isLegalRepresentative");

        if (watchCountry) {
          trigger("contactData.legalAddress.postalCode");
        }

        if (
          isLegalRepresentativeWatch &&
          formMethods.formState.dirtyFields.beneficialOwner?.isBeneficialOwner
        ) {
          trigger("beneficialOwner.isBeneficialOwner");
        }

        // TODO: we have to avoid using setState inside a <form>, we must revisit the yup logic to make it better
        setIsBeneficialOwner(isBeneficialOwnerWatch === "yes");

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.form.subTitle`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
          >
            <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
              {t(`${translationPrefix}.personalDataLabel`)}
            </Heading>

            <Stack gutter="mega">
              <Grid
                columns={{ sm: 1, md: 2 }}
                columnsGap={{ md: "yotta" }}
                rowsGap="mega"
              >
                <Telephone
                  options={countryCodes}
                  ref={register}
                  name="contactData.phoneNumber"
                  required
                  label={t(
                    `${translationPrefix}.fields.contactData.phoneNumber.label`
                  )}
                  errorMessage={errors.contactData?.phoneNumber?.message}
                  value={defaultData.contactData?.phoneNumber}
                />
                <TextInput crossOrigin
                  required
                  ref={register}
                  name="contactData.emailAddress"
                  label={t(
                    `${translationPrefix}.fields.contactData.emailAddress.label`
                  )}
                  errorMessage={errors.contactData?.emailAddress && undefined}
                />
                <SelectInput
                  required
                  ref={register}
                  name="personalData.salutation"
                  label={t(
                    `${translationPrefix}.fields.personalData.salutation.label`
                  )}
                  options={buildOptions({
                    lang: i18n,
                    key: `${translationPrefix}.fields.personalData.salutation.values`,
                  })}
                  value={defaultData.personalData?.salutation}
                  errorMessage={errors.personalData?.salutation?.message}
                />
                <SelectInput
                  ref={register}
                  name="personalData.professionalTitle"
                  label={t(
                    `${translationPrefix}.fields.personalData.professionalTitle.label`
                  )}
                  options={buildOptions({
                    lang: i18n,
                    key: `${translationPrefix}.fields.personalData.professionalTitle.values`,
                  })}
                  value={defaultData.personalData?.professionalTitle}
                  errorMessage={errors.personalData?.professionalTitle?.message}
                  clearSelectionLabel={t("form.clearSelection")}
                  onSelectChange={(option) => {
                    // this prevents to ignore empty value when user clears the selection
                    const newValue =
                      option.value !== "" ? option.value : undefined;

                    formMethods.setValue(
                      "personalData.professionalTitle",
                      newValue
                    );
                  }}
                />
                <TextInput crossOrigin
                  required
                  ref={register}
                  name="personalData.firstName"
                  label={t(
                    `${translationPrefix}.fields.personalData.firstName.label`
                  )}
                  errorMessage={errors.personalData?.firstName?.message}
                />
                <TextInput crossOrigin
                  required
                  ref={register}
                  name="personalData.familyName"
                  label={t(
                    `${translationPrefix}.fields.personalData.familyName.label`
                  )}
                  errorMessage={errors.personalData?.familyName?.message}
                />
                <TextInput crossOrigin
                  ref={register}
                  name="personalData.birthName"
                  label={t(
                    `${translationPrefix}.fields.personalData.birthName.label`
                  )}
                />
                <TextInput crossOrigin
                  required
                  ref={register}
                  name="personalData.dateOfBirth"
                  label={t(
                    `${translationPrefix}.fields.personalData.dateOfBirth.label`
                  )}
                  placeholder={t(
                    "components.alternativeInvestmentFund.input.date.placeholder"
                  )}
                  errorMessage={errors.personalData?.dateOfBirth?.message}
                />
                <TextInput crossOrigin
                  required
                  ref={register}
                  name="personalData.cityOfBirth"
                  label={t(
                    `${translationPrefix}.fields.personalData.cityOfBirth.label`
                  )}
                  errorMessage={errors.personalData?.cityOfBirth?.message}
                />
                <SelectInput
                  required
                  searchable
                  ref={register}
                  name="personalData.countryOfBirth"
                  label={t(
                    `${translationPrefix}.fields.personalData.countryOfBirth.label`
                  )}
                  options={countries(t)}
                  value={defaultData.personalData?.countryOfBirth}
                  errorMessage={errors.personalData?.cityOfBirth?.message}
                />
              </Grid>
              <Grid
                columns={{ sm: 1, md: 2 }}
                columnsGap={{ md: "yotta" }}
                rowsGap="mega"
              >
                <SelectInput
                  required
                  searchable
                  ref={register}
                  name="citizenship.primaryCitizenship"
                  label={t(
                    `${translationPrefix}.fields.citizenship.primaryCitizenship.label`
                  )}
                  options={countries(t)}
                  value={defaultData.citizenship?.primaryCitizenship}
                  errorMessage={errors.citizenship?.primaryCitizenship?.message}
                />
                {fields.map((item, index) => {
                  /**
                   * TODO: filter citizenship list, removing selected countries
                   **/
                  return (
                    <Controller
                      key={`country-${item.id}`}
                      name={`citizenship.additionalCitizenships[${index}].value`}
                      control={control}
                      as={
                        <ExtraFieldWrapper>
                          <SelectInput
                            required
                            searchable
                            ref={register}
                            name={`citizenship.additionalCitizenships[${index}].value`}
                            label={t(
                              `${translationPrefix}.fields.citizenship.additionalCitizenships.label`
                            )}
                            options={countries(t)}
                            errorMessage={
                              errors.citizenship?.additionalCitizenships?.[
                                index
                              ]?.value?.message
                            }
                          />
                          <RemoveIconWrapper
                            onClick={() => {
                              remove(index);
                            }}
                            data-testid={`citizenship.additionalCitizenships[${index}].delete`}
                          >
                            <Icon name="trash" size="mega" />
                          </RemoveIconWrapper>
                        </ExtraFieldWrapper>
                      }
                    />
                  );
                })}
              </Grid>
              <Box width="100%" display="flex" alignItems="start">
                {fields.length < 2 && (
                  <Button
                    variant="link"
                    data-testid="add_additionalCitizenship"
                    onClick={() => {
                      append({ value: "" });
                    }}
                  >
                    {t(
                      `${translationPrefix}.fields.citizenship.add_additionalCitizenship`
                    )}
                  </Button>
                )}
              </Box>
            </Stack>

            <Stack gutter="mega">
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.addressLabel`)}
              </Heading>

              <Grid
                columns={{ sm: 1, md: 2 }}
                columnsGap={{ md: "yotta" }}
                rowsGap="mega"
              >
                <TextInput crossOrigin
                  required
                  ref={register}
                  name="contactData.legalAddress.streetName"
                  label={t(
                    `${translationPrefix}.fields.contactData.street.label`
                  )}
                  errorMessage={
                    errors.contactData?.legalAddress?.streetName?.message
                  }
                />

                <TextInput crossOrigin
                  required
                  ref={register}
                  name="contactData.legalAddress.streetNumber"
                  label={t(
                    `${translationPrefix}.fields.contactData.houseNumber.label`
                  )}
                  errorMessage={
                    errors.contactData?.legalAddress?.streetNumber?.message
                  }
                />

                <TextInput crossOrigin
                  ref={register}
                  name="contactData.legalAddress.addition"
                  label={t(
                    `${translationPrefix}.fields.contactData.additionalAddress.label`
                  )}
                  errorMessage={
                    errors.contactData?.legalAddress?.addition?.message
                  }
                />

                <TextInput crossOrigin
                  required
                  ref={register}
                  name="contactData.legalAddress.postalCode"
                  label={t(
                    `${translationPrefix}.fields.contactData.postalCode.label`
                  )}
                  errorMessage={
                    errors.contactData?.legalAddress?.postalCode?.message
                  }
                />

                <TextInput crossOrigin
                  required
                  ref={register}
                  name="contactData.legalAddress.city"
                  label={t(
                    `${translationPrefix}.fields.contactData.city.label`
                  )}
                  errorMessage={errors.contactData?.legalAddress?.city?.message}
                />

                <SelectInput
                  required
                  ref={register}
                  name="contactData.legalAddress.country"
                  label={t(
                    `${translationPrefix}.fields.contactData.country.label`
                  )}
                  options={countries(t)}
                  searchable
                  onSelectChange={() => {
                    formMethods.trigger("contactData.legalAddress.postalCode");
                  }}
                  value={defaultData.contactData?.legalAddress?.country}
                  errorMessage={
                    errors.contactData?.legalAddress?.country?.message
                  }
                />
              </Grid>
            </Stack>

            <TaxInformation />

            <Stack gutter="mega">
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.companyRoleLabel`)}
              </Heading>

              <Grid
                columns={{ sm: 1, md: 2 }}
                columnsGap={{ md: "yotta" }}
                rowsGap="mega"
              >
                <SelectInput
                  required
                  ref={register}
                  name="role"
                  label={t(`${translationPrefix}.fields.role.label`)}
                  options={buildOptions({
                    lang: i18n,
                    key: `${translationPrefix}.fields.role.values`,
                  })}
                  value={defaultData?.role}
                  errorMessage={errors.role && undefined}
                />
              </Grid>

              <Stack gutter="mega">
                <Grid
                  columns={{ sm: 1, md: 2 }}
                  columnsGap={{ md: "yotta" }}
                  rowsGap="mega"
                >
                  <ChoiceGroup
                    required
                    name="isLegalRepresentative"
                    label={t(
                      `${translationPrefix}.fields.isLegalRepresentative.label`
                    )}
                  >
                    <Grid columns={{ sm: 2, lg: 4 }}>
                      <ChoiceGroup.Radio
                        ref={register}
                        name="isLegalRepresentative"
                        value="yes"
                        label={t("form.yes")}
                      />
                      <ChoiceGroup.Radio
                        ref={register}
                        name="isLegalRepresentative"
                        value="no"
                        label={t("form.no")}
                      />
                    </Grid>
                  </ChoiceGroup>
                </Grid>

                <Grid
                  columns={{ sm: 1, md: 2 }}
                  columnsGap={{ md: "yotta" }}
                  rowsGap="mega"
                >
                  <ChoiceGroup
                    required
                    name="beneficialOwner.isBeneficialOwner"
                    label={t(
                      `${translationPrefix}.fields.beneficialOwner.label`
                    )}
                    errorMessage={
                      errors.beneficialOwner?.isBeneficialOwner?.message
                    }
                  >
                    <Grid columns={{ sm: 2, lg: 4 }}>
                      <ChoiceGroup.Radio
                        ref={register}
                        name="beneficialOwner.isBeneficialOwner"
                        value="yes"
                        label={t("form.yes")}
                      />
                      <ChoiceGroup.Radio
                        ref={register}
                        name="beneficialOwner.isBeneficialOwner"
                        value="no"
                        label={t("form.no")}
                      />
                    </Grid>
                  </ChoiceGroup>

                  {isBeneficialOwner && (
                    <TextInput crossOrigin
                      ref={register}
                      name="beneficialOwner.companyEquityPercentage"
                      required
                      label={t(
                        `${translationPrefix}.fields.beneficialOwner.companyEquityPercentage.label`
                      )}
                      placeholder="0,00"
                      step="0.01"
                      type="number"
                      decorator={{ type: "text", value: "%" }}
                      errorMessage={
                        errors?.beneficialOwner?.companyEquityPercentage
                          ?.message
                      }
                    />
                  )}
                </Grid>
              </Stack>
            </Stack>

            {isBeneficialOwner && (
              <IdentityDocuments
                isLegalRepresentative={isLegalRepresentativeWatch === "yes"}
                defaultData={defaultData as AdditionalPersonProps}
                setError={setError}
              />
            )}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default AddPersonForm;
