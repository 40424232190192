import useCompletedForms from "../../useCompletedForms";
import { ENUM_COMPONENTGENERICTEXTTRACKRECORD_PROFILE } from "generated/globalTypes";
import { Container } from "./TrackRecord.styled";
import { mockedTrackRecord } from "../TrackRecord/utils";
import { TrackRecordProps } from "./TrackRecord.types";
import StandardFund from "./StandardFund/StandardFund";
import SelectionFund from "./SelectionFund/SelectionFund";
import BlockDialog from "../BlockDialog/BlockDialog";

const TrackRecord = ({
  fund: { attributes: fundAttributes },
  funds,
}: TrackRecordProps): React.ReactElement | null => {
  const areFormsCompleted = useCompletedForms();
  const shouldShowBlurredMockedData = areFormsCompleted === false;
  const trackRecord = fundAttributes?.trackRecord;
  const currency = fundAttributes?.currency;

  if (!trackRecord?.profile) {
    console.error(`@Profile does not exist in the TrackRecord section`);
    return null;
  }

  if (!currency) {
    console.error(`@Currency does not exist in the TrackRecord section`);
    return null;
  }

  const data = shouldShowBlurredMockedData ? mockedTrackRecord : trackRecord;

  return (
    <Container>
      {(trackRecord?.profile ===
        ENUM_COMPONENTGENERICTEXTTRACKRECORD_PROFILE.standarFund ||
        shouldShowBlurredMockedData) && (
        <StandardFund
          images={data?.images}
          standardFundTableData={data?.standardFundTable}
          footerText={data?.tableFooter}
          imagesTitle={data?.imagesTitle}
          tableTitle={data?.tableTitle}
          fundCurrency={currency}
        />
      )}

      {trackRecord?.profile ===
        ENUM_COMPONENTGENERICTEXTTRACKRECORD_PROFILE.selectionFund &&
        !shouldShowBlurredMockedData && (
          <SelectionFund
            images={trackRecord?.images}
            selectionFundTableData={trackRecord?.selectionFundTable}
            footerText={trackRecord?.tableFooter}
            imagesTitle={data?.imagesTitle}
            tableTitle={data?.tableTitle}
            funds={funds}
          />
        )}
      {!areFormsCompleted && <BlockDialog />}
    </Container>
  );
};

export default TrackRecord;
