import { fundCurrencyTranslation } from "utils/fundCurrencyTranslation";
import { TFunction } from "i18next";
import { FundFragment } from "generated/FundFragment";
import { ImageWrapper } from "./InvestmentPotential.styled";
import { Content, Headers } from "./InvestmentPotential.types";

export const getRows = ({
  funds,
  t,
}: {
  funds: FundFragment[];
  t: TFunction;
}): Record<string, Content>[] =>
  funds.map((fund) => ({
    column1: {
      content: (
        <ImageWrapper
          src={fund.attributes?.fundManagerImage?.data?.attributes?.url}
          alt="company logo"
        />
      ),
    },
    column2: {
      content: t(
        `components.alternativeInvestmentFund.strategy.${fund.attributes?.strategy}`
      ),
    },
    column3: {
      content: t(fundCurrencyTranslation(fund.attributes?.currency), {
        value: fund.attributes?.targetFundSize,
      }),
    },
    column4: {
      content: t(
        `components.alternativeInvestmentFund.region.${fund.attributes?.region}`
      ),
    },
    column5: {
      content: t(
        `components.alternativeInvestmentFund.profile.secondSubSection.selectionFund.${fund.attributes?.investmentType}`
      ),
    },
  }));

const tPrefix =
  "components.alternativeInvestmentFund.profile.secondSubSection.selectionFund";

export const getHeaders = (t: TFunction): Headers[] => [
  {
    field: "column1",
    content: t(`${tPrefix}.manager`),
  },
  {
    field: "column2",
    content: t(`${tPrefix}.strategy`),
  },
  {
    field: "column3",
    content: t(`${tPrefix}.fundTargetSize`),
  },
  {
    field: "column4",
    content: t(`${tPrefix}.region`),
  },
  {
    field: "column5",
    content: t(`${tPrefix}.investmentType`),
  },
];
