import { Box, Button, Grid } from "@finvia/money-ui";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { EmploymentLegalEntity, Residency } from "../Employment.schema";
import ResidencyField from "./ResidencyField";

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData.fields";

const ResidencyFields: React.FC = () => {
  const { t } = useTranslation();
  const formMethods = useFormContext<EmploymentLegalEntity>();
  const { fields, remove, append } = useFieldArray<Residency>({
    control: formMethods.control,
    name: "taxInfo.residencies",
  });

  // The following useEffect is required because the taxId textfield isn't updating properly in the fieldArray
  const residencies = formMethods.watch("taxInfo.residencies");
  const residenciesValueRef = useRef(residencies);
  useEffect(() => {
    if (
      residenciesValueRef.current !== residencies &&
      formMethods.formState.dirtyFields.taxInfo?.residencies
    ) {
      residenciesValueRef.current = residencies;
      formMethods.trigger("taxInfo.residencies");
    }
  }, [residencies, formMethods]);

  return (
    <Grid
      columns={{ sm: 1, lg: 2 }}
      columnsGap={{ sm: "exa" }}
      rowsGap={{ sm: "giga" }}
    >
      {fields.map((item, index) => (
        <ResidencyField
          key={item.id}
          item={item}
          index={index}
          lastItem={index === fields.length - 1}
          onRemove={() => remove(index)}
        />
      ))}
      {fields.length < 3 && (
        <>
          <Box>
            <Button
              variant="link"
              onClick={() => append({ country: "", taxId: "" })}
              testId="add_secondary_residencies"
            >
              {t(
                `${translationPrefix}.taxInfo.residencies.addAnotherResidency`
              )}
            </Button>
          </Box>
          <span />
        </>
      )}
    </Grid>
  );
};

export default ResidencyFields;
