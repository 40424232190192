import { TFunction } from "i18next";
import * as yup from "yup";

const translationPrefix = "q-ai.opportunities.form.contractConfirmation";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const contractConfirmationValidationSchema = (t: TFunction) => {
  return yup.object({
    hasReceivedAIMandateContractDocuments: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.fields.hasReceivedAIMandateContractDocuments.required`
        )
      ),
    hasConfirmedAIMandateContractSigning: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.fields.hasConfirmedAIMandateContractSigning.required`
        )
      ),
    isRetailClient: yup
      .boolean()
      .required()
      .oneOf([true], t(`${translationPrefix}.fields.isRetailClient.required`)),
  });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      contractConfirmation: contractConfirmationValidationSchema(t),
    })
    .required();
