import { UploadStatus } from "@finvia/component-library";
import { createAction } from "@reduxjs/toolkit";
import { DeepPartial } from "react-hook-form";
import {
  BankAccountType,
  Currency,
  InvestmentExperienceInput,
  MoneyRangeLarge,
  PEPInput,
  PEPStatus,
  PersonalDataInput,
  ProfileInput,
  RegulatoryInfoInput,
} from "../../../../generated/globalTypes";
import {
  RegulatoryData as RegulatoryDataType,
  RegulatoryData_profile,
} from "../../../../generated/RegulatoryData";
import {
  UpdateProfile,
  UpdateProfileVariables,
} from "../../../../generated/UpdateProfile";
import {
  UpsertAlternativeInvestmentsMandateContract as UpsertAlternativeInvestmentsMandateContractMutationType,
  UpsertAlternativeInvestmentsMandateContractVariables,
} from "../../../../generated/UpsertAlternativeInvestmentsMandateContract";
import { UpsertAlternativeInvestmentsMandateContract } from "../../../../graphql/mutations/Contracts";
import { UpdateProfile as updateProfileMutation } from "../../../../graphql/mutations/Profile";
import RegulatoryData from "../../../../graphql/queries/RegulatoryData";
import { DocumentChange } from "../../../../models/documents";
import { typedCreateAsyncThunk } from "../../../../store/typedCreateAsyncThunk";
import {
  mapFormDateToGraphQl,
  mapGraphQlDateToForm,
  packFieldArray,
} from "../../../../utils/formUtils";
import { FormDefaultData } from "../../../../utils/graphQLPartial";
import { convertBooleanToYesNoOrEmptyString } from "../../../../utils/helpers";
import { getMappedAssetClasses } from "./common/ExperienceAssets/ExperienceAssets.utils";
import { ContractTypeForm } from "./contractTypeFormValidations";
import { ContractAndRegulatoryData as ContractAndRegulatoryDataForm } from "./naturalPerson/naturalPersonValidations";

type Profile = RegulatoryData_profile;

export const mapProfileFromGraphQlToForm = (
  profile?: Profile | null
): FormDefaultData<ContractAndRegulatoryDataForm> => {
  const mappedExperienceAssetClasses = getMappedAssetClasses(
    profile?.regulatoryInfo?.experience?.assetClasses
  );

  const bankAccountMain = profile?.bankAccounts?.reference;
  const bankAccountUsd = profile?.bankAccounts?.usdCurrency;

  const { contactAddress, legalAddress } = profile?.contactData || {};

  return {
    id: profile?.id,
    personalData: {
      birthName: profile?.personalData?.birthName,
      cityOfBirth: profile?.personalData?.cityOfBirth,
      familyName: profile?.personalData?.familyName,
      firstName: profile?.personalData?.firstName,
      countryOfBirth: profile?.personalData?.countryOfBirth,
      dateOfBirth: mapGraphQlDateToForm(profile?.personalData?.dateOfBirth),
      professionalTitle: profile?.personalData?.professionalTitle,
      salutation: profile?.personalData?.salutation,
    },
    contractType: profile?.contracts?.aiMandate?.contractType,
    citizenship: {
      primary: profile?.personalData?.primaryCitizenship,
      secondary: packFieldArray<string>(
        profile?.personalData?.additionalCitizenships?.filter(
          (citizenship) => !!citizenship
        ) as string[]
      ),
      thirdWorldNational: convertBooleanToYesNoOrEmptyString(
        profile?.personalData?.isThirdCountryNational
      ),
    },
    contactData: {
      emailAddress: profile?.contactData?.emailAddress,
      phoneNumber: profile?.contactData?.phoneNumber,
    },
    contactAddress: contactAddress
      ? {
          addition: contactAddress?.addition,
          streetName: contactAddress?.streetName,
          streetNumber: contactAddress?.streetNumber,
          postalCode: contactAddress?.postalCode,
          city: contactAddress?.city,
          country: contactAddress?.country,
        }
      : null,
    hasSeparateContactAddress: !!contactAddress,
    legalAddress: {
      addition: legalAddress?.addition,
      streetName: legalAddress?.streetName,
      streetNumber: legalAddress?.streetNumber,
      postalCode: legalAddress?.postalCode,
      city: legalAddress?.city,
      country: legalAddress?.country,
    },
    employment: {
      status: profile?.employment?.status,
      country: profile?.employment?.country,
      sector: profile?.employment?.sector,
      branchOfIndustry: profile?.employment?.branchOfIndustry,
      pep: {
        status: profile?.regulatoryInfo?.pep?.status,
        role: profile?.regulatoryInfo?.pep?.role,
        relationship: profile?.regulatoryInfo?.pep?.relationship,
      },
    },
    taxInfo: {
      facta: {
        status: profile?.regulatoryInfo?.taxInfo?.facta?.status,
        irsDocuments:
          profile?.regulatoryInfo?.taxInfo?.facta?.irsDocuments?.map(
            (document) => {
              return {
                ...document,
                uploadStatus: UploadStatus.DONE,
              };
            }
          ),
      },
      residencies: profile?.regulatoryInfo?.taxInfo?.residencies?.map(
        (residency) => ({
          country: residency?.country,
          taxId: residency?.taxId,
          localTaxOffice: residency?.localTaxOffice,
        })
      ),
    },
    regulatoryInfo: {
      currenciesExperience: {
        consultingFreeOrderExecution:
          profile?.regulatoryInfo?.experience?.consultingFreeOrderExecution ??
          undefined,
        foreignCurrencies:
          profile?.regulatoryInfo?.experience?.currencies?.foreignCurrencies,
        investmentConsulting:
          profile?.regulatoryInfo?.experience?.investmentConsulting,
        loanBasedForeignCurrencies:
          profile?.regulatoryInfo?.experience?.currencies
            ?.loanBasedForeignCurrencies ?? undefined,
        wealthManagement: profile?.regulatoryInfo?.experience?.wealthManagement,
      },
      assetClassesExperience: mappedExperienceAssetClasses,
      netWorth: {
        total: {
          range: profile?.regulatoryInfo?.netWorth?.total?.range,
          value: profile?.regulatoryInfo?.netWorth?.total?.value?.value,
        },
        cash: {
          range: profile?.regulatoryInfo?.netWorth?.cash?.range,
          value: profile?.regulatoryInfo?.netWorth?.cash?.value?.value,
        },
        securities: {
          range: profile?.regulatoryInfo?.netWorth?.securities?.range,
          value: profile?.regulatoryInfo?.netWorth?.securities?.value?.value,
        },
        selfUsedProperties: {
          range:
            profile?.regulatoryInfo?.netWorth?.selfUsedProperties?.range ??
            undefined,
          value:
            profile?.regulatoryInfo?.netWorth?.selfUsedProperties?.value
              ?.value ?? undefined,
        },
        realEstate: {
          range: profile?.regulatoryInfo?.netWorth?.realEstate?.range,
          value: profile?.regulatoryInfo?.netWorth?.realEstate?.value?.value,
        },
        movableProperty: {
          range:
            profile?.regulatoryInfo?.netWorth?.movableProperty?.range ??
            undefined,
          value:
            profile?.regulatoryInfo?.netWorth?.movableProperty?.value?.value ??
            undefined,
        },
        insuranceClaims: {
          range:
            profile?.regulatoryInfo?.netWorth?.insuranceClaims?.range ??
            undefined,
          value:
            profile?.regulatoryInfo?.netWorth?.insuranceClaims?.value?.value ??
            undefined,
        },
        other: {
          range: profile?.regulatoryInfo?.netWorth?.other?.range,
          value: profile?.regulatoryInfo?.netWorth?.other?.value?.value,
        },
        restrictions: {
          type: profile?.regulatoryInfo?.netWorth?.restrictions?.type,
          value: profile?.regulatoryInfo?.netWorth?.restrictions?.value?.value,
        },
      },
      wealthOrigin: profile?.regulatoryInfo?.wealthOrigin,
      wealthOriginDetail: profile?.regulatoryInfo?.wealthOriginDetail,
      liabilities: {
        annualLiabilities: {
          range: profile?.regulatoryInfo?.liabilities?.annualLiabilities?.range,
          value:
            profile?.regulatoryInfo?.liabilities?.annualLiabilities?.value
              ?.value,
        },
        rent: {
          range: profile?.regulatoryInfo?.liabilities?.rent?.range,
          value: profile?.regulatoryInfo?.liabilities?.rent?.value?.value,
        },
        living: {
          range: profile?.regulatoryInfo?.liabilities?.living?.range,
          value: profile?.regulatoryInfo?.liabilities?.living?.value?.value,
        },
        loan: {
          range: profile?.regulatoryInfo?.liabilities?.loan?.range,
          value: profile?.regulatoryInfo?.liabilities?.loan?.value?.value,
        },
        alimony: {
          range: profile?.regulatoryInfo?.liabilities?.alimony?.range,
          value: profile?.regulatoryInfo?.liabilities?.alimony?.value?.value,
        },
        insurance: {
          range: profile?.regulatoryInfo?.liabilities?.insurance?.range,
          value: profile?.regulatoryInfo?.liabilities?.insurance?.value?.value,
        },
      },
      savings: {
        minimumShortTermReserve: {
          exists: convertBooleanToYesNoOrEmptyString(
            profile?.regulatoryInfo?.savings
              ?.areMinimumShortTermReservesRequired
          ),
          value:
            profile?.regulatoryInfo?.savings?.minimumShortTermReserve?.value ??
            undefined,
          date: profile?.regulatoryInfo?.savings?.date ?? undefined,
        },
      },
      income: {
        employment: {
          range: profile?.regulatoryInfo?.income?.employment?.range,
          value: profile?.regulatoryInfo?.income?.employment?.value?.value,
        },
        selfEmployment: {
          range: profile?.regulatoryInfo?.income?.selfEmployment?.range,
          value: profile?.regulatoryInfo?.income?.selfEmployment?.value?.value,
        },
        renting: {
          range: profile?.regulatoryInfo?.income?.renting?.range,
          value: profile?.regulatoryInfo?.income?.renting?.value?.value,
        },
        capitalGains: {
          range: profile?.regulatoryInfo?.income?.capitalGains?.range,
          value: profile?.regulatoryInfo?.income?.capitalGains?.value?.value,
        },
        other: {
          range: profile?.regulatoryInfo?.income?.other?.range,
          value: profile?.regulatoryInfo?.income?.other?.value?.value,
        },
      },
      availableMonthlyLiquidity: {
        range: profile?.regulatoryInfo?.availableMonthlyLiquidity?.range,
        value: profile?.regulatoryInfo?.availableMonthlyLiquidity?.value?.value,
      },
    },
    bankAccounts: {
      accountHolderFirstName: profile?.personalData?.firstName,
      accountHolderFamilyName: profile?.personalData?.familyName,
      iban: bankAccountMain?.iban,
      bic: bankAccountMain?.bic,
      nameOfBank: bankAccountMain?.nameOfBank,
      cityOfBank: bankAccountMain?.cityOfBank,
      usdCheckbox: !!bankAccountUsd,
      usdAccountHolderFirstName: profile?.personalData?.firstName,
      usdAccountHolderFamilyName: profile?.personalData?.familyName,
      usdIban: bankAccountUsd?.iban,
      usdBic: bankAccountUsd?.bic,
      usdNameOfBank: bankAccountUsd?.nameOfBank,
      usdCityOfBank: bankAccountUsd?.cityOfBank,
    },
  };
};

export const getRegulatoryData = typedCreateAsyncThunk(
  "contractAndRegulatoryData/getRegulatoryData",
  async (
    _,
    { extra }
  ): Promise<FormDefaultData<ContractAndRegulatoryDataForm>> => {
    const result = await extra.client.query<RegulatoryDataType>({
      query: RegulatoryData,
    });

    return mapProfileFromGraphQlToForm(result.data?.profile);
  }
);

type UpdateProfilePayload = ContractAndRegulatoryDataForm;

export const mapProfileFromFormToGraphQl = (
  formData?: DeepPartial<ContractAndRegulatoryDataForm> | null
): ProfileInput => {
  const input: ProfileInput = {};
  const {
    bankAccounts,
    citizenship,
    contactAddress,
    contactData,
    employment,
    legalAddress,
    personalData,
    regulatoryInfo,
    taxInfo,
  } = formData || {};

  const personalDataInput: PersonalDataInput = {};
  const regulatoryInfoInput: RegulatoryInfoInput = {};

  if (bankAccounts) {
    input.bankAccounts = {
      reference: {
        type: BankAccountType.REFERENCE,
        accountHolderFirstName: bankAccounts.accountHolderFirstName,
        accountHolderFamilyName: bankAccounts.accountHolderFamilyName,
        iban: bankAccounts.iban,
        bic: bankAccounts.bic,
        nameOfBank: bankAccounts.nameOfBank,
        cityOfBank: bankAccounts.cityOfBank,
      },
    };

    input.bankAccounts.usdCurrency = bankAccounts.usdCheckbox
      ? {
          type: BankAccountType.USD_CURRENCY_ACCOUNT,
          accountHolderFirstName: bankAccounts.usdAccountHolderFirstName,
          accountHolderFamilyName: bankAccounts.usdAccountHolderFamilyName,
          iban: bankAccounts.usdIban,
          bic: bankAccounts.usdBic,
          nameOfBank: bankAccounts.usdNameOfBank,
          cityOfBank: bankAccounts.usdCityOfBank,
        }
      : null;
  }

  if (contactData || contactAddress || legalAddress) {
    input.contactData = {};
    input.contactData.phoneNumber = contactData?.phoneNumber;
    input.contactData.emailAddress = contactData?.emailAddress;
    input.contactData.legalAddress = legalAddress;
    input.contactData.contactAddress = contactAddress;
  }

  if (employment) {
    let pep: PEPInput | null = null;
    if (employment.pep?.status === PEPStatus.YES_ME) {
      pep = {
        relationship: null,
        role: employment.pep?.role,
        status: PEPStatus.YES_ME,
      };
    } else if (employment.pep?.status === PEPStatus.YES_FAMILY_MEMBER) {
      pep = {
        relationship: employment.pep?.relationship,
        role: employment.pep?.role,
        status: PEPStatus.YES_FAMILY_MEMBER,
      };
    } else {
      pep = {
        relationship: null,
        role: null,
        status: PEPStatus.NO,
      };
    }

    input.employment = {
      branchOfIndustry: employment.branchOfIndustry,
      country: employment.country,
      sector: employment.sector,
      status: employment.status,
    };
    regulatoryInfoInput.pep = pep;
  }

  if (personalData) {
    personalDataInput.birthName = personalData?.birthName;
    personalDataInput.cityOfBirth = personalData?.cityOfBirth;
    personalDataInput.familyName = personalData?.familyName;
    personalDataInput.firstName = personalData?.firstName;
    personalDataInput.countryOfBirth = personalData?.countryOfBirth;
    personalDataInput.dateOfBirth = mapFormDateToGraphQl(
      personalData?.dateOfBirth
    );
    personalDataInput.professionalTitle =
      personalData?.professionalTitle || null;
    personalDataInput.salutation = personalData?.salutation;
  }

  if (citizenship) {
    personalDataInput.primaryCitizenship = citizenship.primary;
    personalDataInput.additionalCitizenships =
      (citizenship.secondary?.map((country) => country.value) as string[]) ||
      [];
    personalDataInput.isThirdCountryNational =
      citizenship.thirdWorldNational === "yes";
  }

  if (regulatoryInfo) {
    if (
      regulatoryInfo.assetClassesExperience ||
      regulatoryInfo.currenciesExperience
    ) {
      const experienceInput: InvestmentExperienceInput = {};

      if (regulatoryInfo.assetClassesExperience) {
        const { assetClassesExperience } = regulatoryInfo;
        experienceInput.assetClasses = {
          privateEquity: assetClassesExperience.privateEquity?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.privateEquity.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.privateEquity
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.privateEquity
                    .averageInvestmentVolumePerYear,
              }
            : null,
          privateDebt: assetClassesExperience.privateDebt?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.privateDebt.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.privateDebt
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.privateDebt
                    .averageInvestmentVolumePerYear,
              }
            : null,
          realEstate: assetClassesExperience.realEstate?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.realEstate.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.realEstate
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.realEstate
                    .averageInvestmentVolumePerYear,
              }
            : null,
          infrastructure: assetClassesExperience.infrastructure?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.infrastructure.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.infrastructure
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.infrastructure
                    .averageInvestmentVolumePerYear,
              }
            : null,
          securities: assetClassesExperience.securities?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.securities.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.securities
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.securities
                    .averageInvestmentVolumePerYear,
              }
            : null,
          gold: assetClassesExperience.gold?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.gold.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.gold
                    ?.averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.gold?.averageInvestmentVolumePerYear,
              }
            : null,
          bonds: assetClassesExperience.bonds?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.bonds.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.bonds
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.bonds.averageInvestmentVolumePerYear,
              }
            : null,
          moneyMarket: assetClassesExperience.moneyMarket?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.moneyMarket.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.moneyMarket
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.moneyMarket
                    .averageInvestmentVolumePerYear,
              }
            : null,
          derivatives: assetClassesExperience.derivatives?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.derivatives.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.derivatives
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.derivatives
                    .averageInvestmentVolumePerYear,
              }
            : null,
          currencyFutures: assetClassesExperience.currencyFutures?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.currencyFutures.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.currencyFutures
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.currencyFutures
                    .averageInvestmentVolumePerYear,
              }
            : null,
          hedgeFunds: assetClassesExperience.hedgeFunds?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.hedgeFunds.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.hedgeFunds
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.hedgeFunds
                    .averageInvestmentVolumePerYear,
              }
            : null,
          certificates: assetClassesExperience.certificates?.checkbox
            ? {
                experienceInYears:
                  assetClassesExperience.certificates.experienceInYears,
                averageNumberOfTransactionsPerYear:
                  assetClassesExperience.certificates
                    .averageNumberOfTransactionsPerYear,
                averageInvestmentVolumePerYear:
                  assetClassesExperience.certificates
                    .averageInvestmentVolumePerYear,
              }
            : null,
        };
      }

      if (regulatoryInfo.currenciesExperience) {
        experienceInput.investmentConsulting =
          regulatoryInfo.currenciesExperience.investmentConsulting;
        experienceInput.consultingFreeOrderExecution =
          regulatoryInfo.currenciesExperience.consultingFreeOrderExecution;
        experienceInput.wealthManagement =
          regulatoryInfo.currenciesExperience.wealthManagement;

        experienceInput.currencies = {
          foreignCurrencies:
            regulatoryInfo.currenciesExperience.foreignCurrencies,
          loanBasedForeignCurrencies:
            regulatoryInfo.currenciesExperience.loanBasedForeignCurrencies,
        };
      }

      regulatoryInfoInput.experience = experienceInput;
    }

    if (regulatoryInfo.income) {
      regulatoryInfoInput.income = {
        employment: {
          range: regulatoryInfo.income.employment?.range,
          value: {
            value: regulatoryInfo.income.employment?.value,
            currency: Currency.EUR,
          },
        },
        selfEmployment: {
          range: regulatoryInfo.income.selfEmployment?.range,
          value: {
            value: regulatoryInfo.income.selfEmployment?.value,
            currency: Currency.EUR,
          },
        },
        renting: {
          range: regulatoryInfo.income.renting?.range,
          value: {
            value: regulatoryInfo.income.renting?.value,
            currency: Currency.EUR,
          },
        },
        capitalGains: {
          range: regulatoryInfo.income.capitalGains?.range,
          value: {
            value: regulatoryInfo.income.capitalGains?.value,
            currency: Currency.EUR,
          },
        },
        other: {
          range: regulatoryInfo.income.other?.range,
          value: {
            value: regulatoryInfo.income.other?.value,
            currency: Currency.EUR,
          },
        },
      };
    }

    if (regulatoryInfo.availableMonthlyLiquidity) {
      regulatoryInfoInput.availableMonthlyLiquidity = {
        range: regulatoryInfo.availableMonthlyLiquidity.range,
        value: {
          value: regulatoryInfo.availableMonthlyLiquidity.value,
          currency: Currency.EUR,
        },
      };
    }

    if (regulatoryInfo.liabilities) {
      regulatoryInfoInput.liabilities = {
        annualLiabilities: {
          range: regulatoryInfo.liabilities.annualLiabilities?.range,
          value: {
            value: regulatoryInfo.liabilities.annualLiabilities?.value,
            currency: Currency.EUR,
          },
        },
        rent: regulatoryInfo?.liabilities?.rent?.checkbox
          ? {
              range: regulatoryInfo.liabilities.rent.range,
              value: {
                value: regulatoryInfo.liabilities.rent.value,
                currency: Currency.EUR,
              },
            }
          : null,
        living: regulatoryInfo?.liabilities?.living?.checkbox
          ? {
              range: regulatoryInfo.liabilities.living.range,
              value: {
                value: regulatoryInfo.liabilities.living.value,
                currency: Currency.EUR,
              },
            }
          : null,
        loan: regulatoryInfo?.liabilities?.loan?.checkbox
          ? {
              range: regulatoryInfo.liabilities.loan.range,
              value: {
                value: regulatoryInfo.liabilities.loan.value,
                currency: Currency.EUR,
              },
            }
          : null,
        alimony: regulatoryInfo?.liabilities?.alimony?.checkbox
          ? {
              range: regulatoryInfo.liabilities.alimony.range,
              value: {
                value: regulatoryInfo.liabilities.alimony.value,
                currency: Currency.EUR,
              },
            }
          : null,
        insurance: regulatoryInfo?.liabilities?.insurance?.checkbox
          ? {
              range: regulatoryInfo.liabilities.insurance.range,
              value: {
                value: regulatoryInfo.liabilities.insurance.value,
                currency: Currency.EUR,
              },
            }
          : null,
      };
    }

    if (regulatoryInfo.netWorth) {
      regulatoryInfoInput.netWorth = {
        restrictions: {
          type: regulatoryInfo.netWorth.restrictions?.type,
          value: {
            value: regulatoryInfo.netWorth.restrictions?.value,
            currency: Currency.EUR,
          },
        },
        total: {
          range: regulatoryInfo.netWorth.total?.range,
          value: {
            currency: Currency.EUR,
            value:
              regulatoryInfo.netWorth.total?.range ===
              MoneyRangeLarge.MORE_THAN_1000000
                ? regulatoryInfo.netWorth.total?.value
                : null,
          },
        },
        cash: regulatoryInfo.netWorth.cash?.checkbox
          ? {
              range: regulatoryInfo.netWorth.cash?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.cash.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.cash.value
                    : null,
              },
            }
          : null,
        securities: regulatoryInfo.netWorth.securities?.checkbox
          ? {
              range: regulatoryInfo.netWorth.securities?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.securities.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.securities.value
                    : null,
              },
            }
          : null,
        selfUsedProperties: regulatoryInfo.netWorth.selfUsedProperties?.checkbox
          ? {
              range: regulatoryInfo.netWorth.selfUsedProperties?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.selfUsedProperties.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.selfUsedProperties.value
                    : null,
              },
            }
          : null,
        realEstate: regulatoryInfo.netWorth.realEstate?.checkbox
          ? {
              range: regulatoryInfo.netWorth.realEstate?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.realEstate.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.realEstate.value
                    : null,
              },
            }
          : null,
        movableProperty: regulatoryInfo.netWorth.movableProperty?.checkbox
          ? {
              range: regulatoryInfo.netWorth.movableProperty?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.movableProperty.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.movableProperty.value
                    : null,
              },
            }
          : null,
        insuranceClaims: regulatoryInfo.netWorth.insuranceClaims?.checkbox
          ? {
              range: regulatoryInfo.netWorth.insuranceClaims?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.insuranceClaims.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.insuranceClaims.value
                    : null,
              },
            }
          : null,
        other: regulatoryInfo.netWorth.other?.checkbox
          ? {
              range: regulatoryInfo.netWorth.other?.range,
              value: {
                currency: Currency.EUR,
                value:
                  regulatoryInfo.netWorth.other.range ===
                  MoneyRangeLarge.MORE_THAN_1000000
                    ? regulatoryInfo.netWorth.other.value
                    : null,
              },
            }
          : null,
      };
    }

    if (regulatoryInfo.savings) {
      regulatoryInfoInput.savings = {
        areMinimumShortTermReservesRequired:
          regulatoryInfo?.savings?.minimumShortTermReserve?.exists === "yes",
        minimumShortTermReserve: {
          currency: Currency.EUR,
          value: regulatoryInfo?.savings?.minimumShortTermReserve?.value,
        },
        date: regulatoryInfo?.savings?.minimumShortTermReserve?.date,
      };
    }

    if (regulatoryInfo.wealthOrigin) {
      regulatoryInfoInput.wealthOrigin = regulatoryInfo.wealthOrigin;
      regulatoryInfoInput.wealthOriginDetail =
        regulatoryInfo.wealthOriginDetail;
    }
  }

  if (taxInfo) {
    regulatoryInfoInput.taxInfo = {
      facta: {
        status: taxInfo.facta?.status,
      },
      residencies: taxInfo.residencies?.map((residency) => ({
        country: residency.country,
        localTaxOffice: residency.localTaxOffice,
        taxId: residency.taxId,
      })),
    };
  }

  input.regulatoryInfo = regulatoryInfoInput;
  input.personalData = personalDataInput;

  return input;
};

export const upsertMandateContract = typedCreateAsyncThunk(
  "contractAndRegulatoryData/upsertMandateContract",
  async (payload: ContractTypeForm, { extra }): Promise<ContractTypeForm> => {
    await extra.client.mutate<
      UpsertAlternativeInvestmentsMandateContractMutationType,
      UpsertAlternativeInvestmentsMandateContractVariables
    >({
      mutation: UpsertAlternativeInvestmentsMandateContract,
      variables: {
        input: {
          contractType: payload?.contractType,
        },
      },
    });

    return payload;
  }
);

export const updateProfile = typedCreateAsyncThunk(
  "contractAndRegulatoryData/updateProfile",
  async (
    payload: DeepPartial<UpdateProfilePayload>,
    { extra }
  ): Promise<DeepPartial<UpdateProfilePayload>> => {
    const input = mapProfileFromFormToGraphQl(payload);
    await extra.client.mutate<UpdateProfile, UpdateProfileVariables>({
      mutation: updateProfileMutation,
      variables: { input },
    });

    return payload;
  }
);

export const updateEmploymentAndPersonalIrsDocumentData = createAction<
  DocumentChange[]
>("contractAndRegulatoryData/updateEmploymentAndTaxData");
