import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../../routes/routes";
import { Box, Text, Button, Card, Icon, Stack } from "@finvia/money-ui";
import {
  LockContentWrapper,
  Divider,
  StyledLink,
} from "./FundAmountHeader.styled";

const MandateContractNotSignedHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box width={{ sm: "100%", md: "500px" }}>
      <Card borderStyle="none">
        <Stack
          gutter="giga"
          direction={{ sm: "column", md: "row" }}
          alignItems="center"
          flexWrap="nowrap"
        >
          <Box>
            <Icon name="lock" size="tera" color="petrol.500" />
          </Box>
          <LockContentWrapper>
            <Divider />
            <Stack gutter="mega" alignItems="center">
              <StyledLink to={ROUTES.questionnaires.ai.root}>
                <Button as="span">
                  {t(
                    "components.alternativeInvestmentFund.mandateContractButton"
                  )}
                </Button>{" "}
              </StyledLink>
              <Text align="center" size={{ sm: 2, md: 3 }}>
                {t(
                  "components.alternativeInvestmentFund.mandateContractDescription"
                )}
              </Text>
            </Stack>
          </LockContentWrapper>
        </Stack>
      </Card>
    </Box>
  );
};

export default MandateContractNotSignedHeader;
