import { Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const advisors = () => css`
  width: fit-content;
  height: 54px;
  background: transparent;
  border: none;

  img {
    height: 100%;
  }
`;

const divider = () => css`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.tokens.sizes.spacings.giga};
    border: 0;
    border-top: 1px solid ${theme.tokens.colors.gold[200]};
    margin-block-start: 0;

    ${theme.utils.viewport.xl`
      width: 236px;
      margin-top: ${theme.tokens.sizes.spacings.giga};
    `}
  `};
`;

const backLink = () => css`
  ${({ theme }) => css`
    display: none;

    ${theme.utils.viewport.xl`
      display: block;
      text-decoration: none;
      color: ${theme.tokens.colors.petrol[600]};
      font-size: ${theme.tokens.typography.fontSizes[3]};
      font-family: ${theme.tokens.typography.fontFamily.sans};
      transition: color 0.2s linear;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: -4px;
        left: 0;
        height: 1px;
        background-color: ${theme.tokens.colors.petrol[200]};
        transition: background-color 0.2s linear;
      }

      &:hover {
        color: ${theme.tokens.colors.petrol[500]};

        &::after {
          background-color: ${theme.tokens.colors.petrol[500]};
        }
      }
    `}
  `};
`;

const modal = () => css`
  height: 100vh;
`;

const phoneNumber = () => css`
  ${({ theme }) => css`
    font-family: ${theme.tokens.typography.fontFamily.sans};
    font-size: ${theme.tokens.typography.fontSizes[6]};
    color: ${theme.tokens.colors.neutral[750]};
    font-weight: ${theme.tokens.typography.weights.medium};
    text-decoration: none;
  `};
`;

export const TitleGrid = styled.div<{ iconSize?: string }>`
  display: grid;
  grid-template-columns: ${({ iconSize }) => (iconSize ? iconSize : "24px")} 1fr;
  width: 100%;
  gap: ${({ theme }) => theme.tokens.sizes.spacings.byte};
  align-items: center;

  h3 {
    color: ${({ theme }) => theme.tokens.colors.petrol[600]};
    font-weight: ${({ theme }) => theme.tokens.typography.weights.medium};
  }
`;

const styledLink = () =>
  css`
    width: 100%;
    text-decoration: none;
  `;

const showOnMobileHidden = ({ shouldShow }: StyledLinkProps) =>
  !shouldShow &&
  css`
    display: none;
  `;

const showOnMobile = () =>
  css`
    ${({ theme }) => css`
      display: block;
      width: 100%;
      ${theme.utils.viewport.xl`
      display: none;
      `}
    `};
  `;

const showOnDesktop = () => css`
  ${({ theme }) => css`
    display: none;
    ${theme.utils.viewport.xl`
      display: block;
      `}
  `};
`;

const showOnDesktopHidden = ({ theme, shouldShow }: StyledLinkProps) =>
  !shouldShow &&
  css`
    display: none;
    ${theme.utils.viewport.xl`
      display: none;
      `}
  `;

type StyledLinkProps = {
  shouldShow?: boolean;
  theme: Theme;
};

export const Advisors = styled.div(advisors);
export const Divider = styled.hr(divider);
export const BackLink = styled.a(backLink);
export const ShowOnMobile = styled.div<StyledLinkProps>(
  showOnMobile,
  showOnMobileHidden
);
export const ShowOnDesktop = styled.div<StyledLinkProps>(
  showOnDesktop,
  showOnDesktopHidden
);
export const StyledLink = styled.a<StyledLinkProps>(styledLink);
export const Modal = styled.div(modal);
export const PhoneNumber = styled.a(phoneNumber);
