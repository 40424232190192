/* eslint-disable import/prefer-default-export */
import { GetAIMandateContractDocument as GetAIMandateContractDocumentQueryType } from "../../../../generated/GetAIMandateContractDocument";
import { GetNaturalPersonIdentificationStatus } from "../../../../generated/GetNaturalPersonIdentificationStatus";
import { UpsertAlternativeInvestmentsMandateContractInput } from "../../../../generated/globalTypes";
import {
  LegalEntityMandateContractQuery as LegalEntityMandateContractQueryType,
  LegalEntityMandateContractQuery_legalEntityIdentificationStatus,
} from "../../../../generated/LegalEntityMandateContractQuery";
import { NaturalPersonMandateContractQuery as NaturalPersonMandateContractQueryType } from "../../../../generated/NaturalPersonMandateContractQuery";
import {
  SignAlternativeInvestmentsMandateContract as SignAlternativeInvestmentsMandateContractMutationType,
  SignAlternativeInvestmentsMandateContractVariables,
} from "../../../../generated/SignAlternativeInvestmentsMandateContract";
import {
  StartNaturalPersonIdentificationProcess,
  StartNaturalPersonIdentificationProcess_startNaturalPersonIdentificationProcess,
} from "../../../../generated/StartNaturalPersonIdentificationProcess";
import {
  UpsertAlternativeInvestmentsMandateContract as UpsertAlternativeInvestmentsMandateContractMutationType,
  UpsertAlternativeInvestmentsMandateContractVariables,
} from "../../../../generated/UpsertAlternativeInvestmentsMandateContract";
import {
  SignAlternativeInvestmentsMandateContract,
  UpsertAlternativeInvestmentsMandateContract,
} from "../../../../graphql/mutations/Contracts";
import { START_NATURAL_IDENTIFICATION_PROCESS } from "../../../../graphql/mutations/Identification";
import { GET_NATURAL_PERSON_IDENTIFICATION_STATUS } from "../../../../graphql/queries/Identification";
import {
  LegalEntityMandateContractQuery,
  MandateContractDocumentQuery,
  NaturalPersonMandateContractQuery,
} from "../../../../graphql/queries/MandateContractQuery";
import { typedCreateAsyncThunk } from "../../../../store/typedCreateAsyncThunk";
import { FormDefaultData } from "../../../../utils/graphQLPartial";
import { ContractConfirmationProps } from "./common/ContractConfirmationForm";
import { NaturalPersonMandateContractData } from "./naturalPerson/naturalPersonValidations";

type AcknowledgeSustainabilityClauseVariables = {
  profile: {
    contracts: {
      aiMandate: {
        hasAcknowledgedSustainabilityClause: boolean;
      };
    };
  };
};
type Data = NaturalPersonMandateContractQueryType &
  AcknowledgeSustainabilityClauseVariables;

const mapProfileFromGraphQlToNaturalPersonForm = (
  data: Data
): FormDefaultData<NaturalPersonMandateContractData> => {
  return {
    declaration: {
      hasReceivedBasicInformationOnInvestments:
        data?.profile?.contracts?.aiMandate
          ?.hasReceivedBasicInformationOnInvestments,
      isActingOnOwnBehalf:
        data?.profile?.contracts?.aiMandate?.isActingOnOwnBehalf,
      hasConfirmedInformationCorrectAndComplete:
        data?.profile?.contracts?.aiMandate
          ?.hasConfirmedInformationCorrectAndComplete,
      hasAcknowledgedSustainabilityClause:
        data?.profile?.contracts?.aiMandate
          ?.hasAcknowledgedSustainabilityClause,
    },
    contractConfirmation: {
      hasReceivedAIMandateContractDocuments:
        data?.profile?.contracts?.aiMandate?.hasReceivedContractDraft,
      hasConfirmedAIMandateContractSigning:
        data?.profile?.contracts?.aiMandate?.hasConfirmedContractSigning,
      isRetailClient: data?.profile?.contracts?.aiMandate?.isRetailClient,
    },
    videoIdentStatus: data?.naturalPersonIdentificationStatus,
  };
};

const mapProfileFromGraphQlToLegalEntityForm = (
  data: LegalEntityMandateContractQueryType &
    AcknowledgeSustainabilityClauseVariables
): FormDefaultData<
  LegalEntityMandateContractQuery_legalEntityIdentificationStatus &
    NaturalPersonMandateContractData
> => {
  const { legalEntityIdentificationStatus, ...rest } = data;
  return {
    ...mapProfileFromGraphQlToNaturalPersonForm({
      naturalPersonIdentificationStatus:
        rest?.naturalPersonIdentificationStatus,
      profile: rest?.profile,
    }),
    missingDocuments: legalEntityIdentificationStatus?.missingDocuments,
    accumulatedIdentificationStatus:
      legalEntityIdentificationStatus?.accumulatedIdentificationStatus,
    legalEntityIdentificationStatus:
      legalEntityIdentificationStatus?.legalEntityIdentificationStatus,
    failedIdentificationMessage:
      legalEntityIdentificationStatus?.failedIdentificationMessage,
  };
};

export const getNaturalPersonMandateContractData = typedCreateAsyncThunk(
  "mandateContract/getNaturalPersonMandateContract",
  async (
    _,
    { extra }
  ): Promise<FormDefaultData<NaturalPersonMandateContractData>> => {
    const result = await extra.client.query<Data>({
      query: NaturalPersonMandateContractQuery,
    });

    return mapProfileFromGraphQlToNaturalPersonForm(result.data || {});
  }
);

export const getLegalEntityMandateContractData = typedCreateAsyncThunk(
  "mandateContract/getLegalEntityMandateContract",
  async (
    _,
    { extra }
  ): Promise<
    FormDefaultData<
      LegalEntityMandateContractQuery_legalEntityIdentificationStatus &
        NaturalPersonMandateContractData
    >
  > => {
    const result = await extra.client.query<
      LegalEntityMandateContractQueryType &
        AcknowledgeSustainabilityClauseVariables
    >({
      query: LegalEntityMandateContractQuery,
    });

    return mapProfileFromGraphQlToLegalEntityForm(result.data || {});
  }
);

export const mapFormToGraphQl = (
  formData?: NaturalPersonMandateContractData | null
): UpsertAlternativeInvestmentsMandateContractInput => {
  const input: UpsertAlternativeInvestmentsMandateContractInput = {};
  const { contractConfirmation, declaration } = formData || {};

  if (contractConfirmation) {
    input.hasConfirmedContractSigning =
      contractConfirmation.hasConfirmedAIMandateContractSigning;
    input.hasReceivedContractDraft =
      contractConfirmation.hasReceivedAIMandateContractDocuments;
    input.isRetailClient = contractConfirmation.isRetailClient;
  }

  if (declaration) {
    input.hasReceivedBasicInformationOnInvestments =
      declaration.hasReceivedBasicInformationOnInvestments;
    input.isActingOnOwnBehalf = declaration.isActingOnOwnBehalf;
    input.hasConfirmedInformationCorrectAndComplete =
      declaration.hasConfirmedInformationCorrectAndComplete;
    input.hasAcknowledgedSustainabilityClause =
      declaration.hasAcknowledgedSustainabilityClause;
  }

  return input;
};

export const upsertAlternativeInvestmentsMandateContract =
  typedCreateAsyncThunk(
    "contractAndRegulatoryData/upsertAlternativeInvestmentsMandateContract",
    async (
      payload: NaturalPersonMandateContractData,
      { extra }
    ): Promise<NaturalPersonMandateContractData> => {
      const input = mapFormToGraphQl(payload);
      await extra.client.mutate<
        UpsertAlternativeInvestmentsMandateContractMutationType,
        UpsertAlternativeInvestmentsMandateContractVariables
      >({
        mutation: UpsertAlternativeInvestmentsMandateContract,
        variables: { input },
      });

      return payload;
    }
  );

export const signAlternativeInvestmentsMandateContract = typedCreateAsyncThunk(
  "mandateContract/sign",
  async (payload: ContractConfirmationProps, { extra }) => {
    const input = mapFormToGraphQl(payload);
    await extra.client.mutate<
      UpsertAlternativeInvestmentsMandateContractMutationType,
      UpsertAlternativeInvestmentsMandateContractVariables
    >({
      mutation: UpsertAlternativeInvestmentsMandateContract,
      variables: { input },
    });

    await extra.client.mutate<
      SignAlternativeInvestmentsMandateContractMutationType,
      SignAlternativeInvestmentsMandateContractVariables
    >({
      mutation: SignAlternativeInvestmentsMandateContract,
      variables: {
        input: {
          signatureTimestamp: new Date().toISOString(),
        },
      },
    });

    return payload;
  }
);

export const getAIMandateContractDocument = typedCreateAsyncThunk(
  "mandateContract/getDraft",
  async (_, { extra }) => {
    const result =
      await extra.client.query<GetAIMandateContractDocumentQueryType>({
        query: MandateContractDocumentQuery,
        fetchPolicy: "network-only",
      });

    return result.data.profile?.contracts?.aiMandate?.draft;
  }
);

export const startIdentificationProcess = typedCreateAsyncThunk(
  "mandateContract/startIdentificationProcess",
  async (
    _,
    { extra }
  ): Promise<
    | StartNaturalPersonIdentificationProcess_startNaturalPersonIdentificationProcess
    | undefined
  > => {
    const result =
      await extra.client.mutate<StartNaturalPersonIdentificationProcess>({
        mutation: START_NATURAL_IDENTIFICATION_PROCESS,
      });

    return result.data?.startNaturalPersonIdentificationProcess;
  }
);

export const getNaturalPersonIdentificationStatus = typedCreateAsyncThunk(
  "mandateContract/getNaturalPersonIdentificationStatus",
  async (
    _,
    { extra }
  ): Promise<GetNaturalPersonIdentificationStatus | undefined> => {
    const result =
      await extra.client.query<GetNaturalPersonIdentificationStatus>({
        query: GET_NATURAL_PERSON_IDENTIFICATION_STATUS,
      });

    return result.data;
  }
);
