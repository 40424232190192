import { TFunction } from "i18next";
import * as yup from "yup";
import { LegalForm } from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.legalEntityName";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup.object({
    legalEntityData: yup.object({
      companyName: yup
        .string()
        .required(t(`${translationPrefix}.fields.companyName.required`))
        .label(t(`${translationPrefix}.fields.companyName.label`)),
      registrationType: yup
        .mixed<LegalForm>()
        .oneOf(Object.values(LegalForm))
        .required(t(`${translationPrefix}.fields.registrationType.required`))
        .label(t(`${translationPrefix}.fields.registrationType.label`)),
    }),
  });

export type LegalEntityName = yup.InferType<
  ReturnType<typeof validationSchema>
>;
