import {
  Box,
  Checkbox,
  Grid,
  Heading,
  InfoBox,
  Stack,
  TextInput,
  Tooltip,
} from "@finvia/money-ui";

import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";

import { BankAccountsProps, validationSchema } from ".";
import { IconWrapper } from "./BankAccounts.styles";

const translationPrefix = "q-ai.opportunities.form.bankAccounts";

const BankAccountsForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t, i18n } = useTranslation();

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const defaultData = typedUseSelector((state) => {
    return formDefaultsDeep<BankAccountsProps>(
      {
        bankAccounts: {
          ...state.alternativeInvestments.regulatoryData?.personData
            ?.bankAccounts,
          accountHolderFirstName:
            state.alternativeInvestments.regulatoryData?.personData
              ?.personalData?.firstName,
          accountHolderFamilyName:
            state.alternativeInvestments.regulatoryData?.personData
              ?.personalData?.familyName,
          usdAccountHolderFirstName:
            state.alternativeInvestments.regulatoryData?.personData
              ?.personalData?.firstName,
          usdAccountHolderFamilyName:
            state.alternativeInvestments.regulatoryData?.personData
              ?.personalData?.familyName,
        },
      },
      {
        bankAccounts: {
          accountHolderFirstName: "",
          accountHolderFamilyName: "",
          iban: "",
          bic: "",
          nameOfBank: "",
          cityOfBank: "",
          usdCheckbox: false,
          usdAccountHolderFirstName: "",
          usdAccountHolderFamilyName: "",
          usdIban: "",
          usdBic: "",
          usdNameOfBank: "",
          usdCityOfBank: "",
        },
      }
    );
  });

  const onSubmit = useCallback(
    async (data: BankAccountsProps) => {
      dispatch(updateProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const inputLabel = (name: string) =>
    t(`${translationPrefix}.fields.bankAccounts.${name}.label`);

  return (
    <FormControl<BankAccountsProps>
      dontShowErrors
      onBack={previousStep}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics="2.10_np_bank_accounts"
    >
      {(formMethods) => {
        const { watch, register, errors } = formMethods;

        const watchUsdChecked = watch("bankAccounts.usdCheckbox");

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid columns={{ sm: 1, md: 2 }} rowsGap="mega" gap="peta">
              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.accountHolderFirstName"
                label={inputLabel("accountHolderFirstName")}
                readOnly
              />
              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.accountHolderFamilyName"
                label={inputLabel("accountHolderFamilyName")}
                readOnly
              />
              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.iban"
                label={inputLabel("iban")}
                errorMessage={errors?.bankAccounts?.iban?.message ?? undefined}
                required
              />
              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.bic"
                label={inputLabel("bic")}
                errorMessage={errors?.bankAccounts?.bic?.message ?? undefined}
                required
              />
              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.nameOfBank"
                label={inputLabel("nameOfBank")}
              />
              <TextInput crossOrigin
                ref={register}
                name="bankAccounts.cityOfBank"
                label={inputLabel("cityOfBank")}
              />
            </Grid>

            <InfoBox>{t(`${translationPrefix}.infoBoxText`)}</InfoBox>

            <Box width="100%" display="flex" justifyContent="flex-start">
              <Checkbox
                ref={register}
                name="bankAccounts.usdCheckbox"
                label={inputLabel("usdCheckbox")}
              />
            </Box>

            {watchUsdChecked && (
              <Stack gutter="tera">
                <Stack direction="row" justifyContent="flex-start">
                  <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                    {t(`${translationPrefix}.usdAccount.title`)}
                  </Heading>
                  <Tooltip
                    id="page-title"
                    label={t(`${translationPrefix}.usdAccount.tooltip`)}
                    placement="top"
                    gap={10}
                  >
                    <IconWrapper>i</IconWrapper>
                  </Tooltip>
                </Stack>

                <Grid columns={{ sm: 1, md: 2 }} rowsGap="mega" gap="peta">
                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdAccountHolderFirstName"
                    label={inputLabel("usdAccountHolderFirstName")}
                    readOnly
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdAccountHolderFamilyName"
                    label={inputLabel("usdAccountHolderFamilyName")}
                    readOnly
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdIban"
                    label={inputLabel("usdIban")}
                    errorMessage={
                      errors?.bankAccounts?.usdIban?.message ?? undefined
                    }
                    required
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdBic"
                    label={inputLabel("usdBic")}
                    errorMessage={
                      errors?.bankAccounts?.usdBic?.message ?? undefined
                    }
                    required
                  />

                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdNameOfBank"
                    label={inputLabel("usdNameOfBank")}
                  />
                  <TextInput crossOrigin
                    ref={register}
                    name="bankAccounts.usdCityOfBank"
                    label={inputLabel("usdCityOfBank")}
                  />
                </Grid>
              </Stack>
            )}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default BankAccountsForm;
