import { TFunction } from "i18next";
import * as yup from "yup";
import { DocumentsToValidate } from "./types";
import { wealthOriginValidation } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/common/ExperienceCurrencies";

const translationPrefix = "q-ai.funds.signing";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fundSigningValidationSchema = (
  t: TFunction,
  documentsToValidate: DocumentsToValidate | undefined
) =>
  yup.object({
    confirmsAcquisitionOfShareholding: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.naturalPerson.acquisitionOfShareholding.required`
        )
      ),
    acquisitionOfShareholdingFileClicked:
      documentsToValidate?.acquisitionOfShareholdingFile
        ? yup
            .boolean()
            .oneOf(
              [true],
              t(
                `${translationPrefix}.acquisitionOfShareholding.documentClickRequired`
              )
            )
            .required()
        : yup.boolean().notRequired(),
    confirmsRiskOfAcquisition: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(`${translationPrefix}.naturalPerson.riskOfAcquisition.required`)
      ),
    riskOfAcquisitionFileClicked: documentsToValidate?.riskOfAcquisitionFile
      ? yup
          .boolean()
          .required()
          .oneOf(
            [true],
            t(`${translationPrefix}.riskOfAcquisition.documentClickRequired`)
          )
      : yup.boolean().notRequired(),
    confirmsTrustAndAdministrationContract: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        t(
          `${translationPrefix}.naturalPerson.trustAndAdministrationContract.required`
        )
      ),
    trustAndAdministrationContractFileClicked:
      documentsToValidate?.trustAndAdministrationContractFile
        ? yup
            .boolean()
            .oneOf(
              [true],
              t(
                `${translationPrefix}.trustAndAdministrationContract.documentClickRequired`
              )
            )
        : yup.boolean().notRequired(),
    confirmsStatementsAndDisclosures: yup.boolean().notRequired(),
    confirmsDataProtection: yup.boolean().notRequired(),
    confirmsActingOnOwnBehalf: yup.boolean().notRequired(),
    sourceOfFunds: wealthOriginValidation(t).required(),
    sourceOfFundsDetail: yup.string().min(1).max(1000, "max chars"),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchemaExternalLegalStructures = (
  t: TFunction,
  documentsToValidate?: DocumentsToValidate | undefined
) =>
  yup
    .object({
      signing: fundSigningValidationSchema(t, documentsToValidate),
    })
    .required();

export type AIFundContractSigning = yup.InferType<
  ReturnType<typeof validationSchemaExternalLegalStructures>
>;

export interface AIFundContractSigningForm {
  signing?: AIFundContractSigning["signing"];
}

export function validate(
  fundSigningDeclarations: AIFundContractSigningForm
): Promise<any> {
  const t = (k: any) => k;
  return validationSchemaExternalLegalStructures(t).validate(
    fundSigningDeclarations
  );
}
