import { Fragment, ReactElement } from "react";
import { Text } from "@finvia/money-ui";

const BoldQueryOnString = ({
  string,
  query,
}: {
  string: string;
  query: string;
}): ReactElement => {
  const queryRegex = new RegExp(query, "ig");
  const stringArray = string.split(queryRegex);
  const match = string.match(queryRegex);

  return (
    <Text as="span">
      {stringArray.map((item, index) => (
        <Fragment key={`${index}-${item}`}>
          {item}
          {index !== stringArray.length - 1 && match && (
            <strong>{match[index]}</strong>
          )}
        </Fragment>
      ))}
    </Text>
  );
};

export default BoldQueryOnString;
