import { wealthOriginValidation } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/common/ExperienceCurrencies";
import { TFunction } from "i18next";
import * as yup from "yup";
const translationPrefix = "q-ai.opportunities.form.bankAccountSelection.fields";

export enum AccountSelectionType {
  REFERENCE = "REFERENCE",
  USD_CURRENCY_ACCOUNT = "USD_CURRENCY_ACCOUNT",
  NEW_USD = "NEW_USD",
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction, isLegalEntity?: boolean) =>
  yup.object({
    bankAccountType: yup
      .mixed<AccountSelectionType>()
      .required(t(`${translationPrefix}.bankAccountType.required`))
      .oneOf(Object.values(AccountSelectionType)),
    // Need to add these values in the schema so they show up in the form payload, these values are readonly
    accountHolderCompany: yup.string(),
    usdIban: yup
      .string()
      .when("bankAccountType", {
        is: AccountSelectionType.NEW_USD,
        then: yup
          .string()
          .min(15, t(`${translationPrefix}.usdIban.charLength`))
          .required(t(`${translationPrefix}.usdIban.required`)),
      })
      .label(t(`${translationPrefix}.usdIban.label`)),
    usdBic: yup
      .string()
      .when("bankAccountType", {
        is: AccountSelectionType.NEW_USD,
        then: yup
          .string()
          .test(
            "len",
            t(`${translationPrefix}.usdBic.charLength`),
            (value) =>
              (value?.length && value?.length >= 8 && value?.length <= 11) ||
              false
          )
          .required(t(`${translationPrefix}.usdBic.required`)),
      })
      .label(t(`${translationPrefix}.usdBic.label`)),
    usdNameOfBank: yup
      .string()
      .when("bankAccountType", {
        is: AccountSelectionType.NEW_USD,
        then: yup.string(),
      })
      .label(t(`${translationPrefix}.usdNameOfBank.label`)),
    sourceOfFunds: wealthOriginValidation(t).required(),
    sourceOfFundsDetail: yup.string().max(1000, "max chars").required(),
    taxResidencies: isLegalEntity
      ? yup
          .array(
            yup.object({
              country: yup.string().required(),
              taxId: yup.string().max(1000, "max chars").required(),
              localTaxOffice: yup.string().max(1000, "max chars").required(),
            })
          )
          .max(3)
          .required()
      : yup.string(),
    unlimitedTaxLiability: isLegalEntity
      ? yup.string().required()
      : yup.string(),
    reasonForLimitedTaxLiability: isLegalEntity
      ? yup.string().when("unlimitedTaxLiability", {
          is: (value: string) => value === "no",
          then: (schema) => schema.max(1000, "max chars").required(),
          otherwise: (schema) => schema.notRequired(),
        })
      : yup.string()
  });

export type BankAccountSelectionFormData = yup.InferType<
  ReturnType<typeof validationSchema>
>;
