import { useDispatch } from "react-redux";
import { typedUseSelector } from "store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { Box, Button, Icon, Modal, Stack, Text } from "@finvia/money-ui";

import { LegalEntityRegulatoryDataFormStep } from "features/alternative-investments/formSteps";
import { LegalEntityId } from "../../../legalEntityValidations";
import { deleteLegalEntityAdditionalPerson } from "../../../actions";
import { ROUTES } from "routes/routes";

import { IconWrapper } from "./ManageAdditionalPerson.styled";
import { ManageAdditionalPersonProps } from "./ManageAdditionalPerson.types";
import { useTranslation } from "react-i18next";

const ManageAdditionalPerson = ({
  person,
}: ManageAdditionalPersonProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const stepNumber = LegalEntityRegulatoryDataFormStep.AddLegalEntityPersons;
  const dispatch = useDispatch();
  const legalEntityId = typedUseSelector((state) => {
    return state.alternativeInvestments.regulatoryData.legalEntityData
      .legalEntityId as LegalEntityId;
  });

  const handleDelete = () =>
    dispatch(
      deleteLegalEntityAdditionalPerson({
        personId: person.id,
        legalEntityId,
      })
    ).then(unwrapResult);

  const editLink = generatePath(
    ROUTES.questionnaires.ai.regulatory.legalEntity.editPerson,
    {
      id: stepNumber,
      personId: person.id,
    }
  );

  return (
    <Box>
      <Stack direction="row" gutter="mega">
        <Link aria-label={`edit ${person.name} profile`} to={editLink}>
          <Icon name="edit" />
        </Link>
        <IconWrapper
          onClick={() => setIsOpen(true)}
          aria-label={`delete ${person.name} profile`}
        >
          <Icon name="trash" />
        </IconWrapper>
      </Stack>
      <Modal
        showCloseIcon
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        id="delete-person"
      >
        <Stack gutter="mega">
          <Text align="center">
            Möchten Sie entfernen <strong>{person.name}</strong>?
          </Text>
          <Stack
            direction={{ md: "row" }}
            gutter="mega"
            justifyContent="space-between"
          >
            <Box width={{ sm: "100%", md: "40%" }}>
              <Button stretch variant="ghost" onClick={() => setIsOpen(false)}>
                {t("form.no")}
              </Button>
            </Box>
            <Box width={{ sm: "100%", md: "40%" }}>
              <Button
                stretch
                onClick={() => {
                  setIsOpen(false);
                  handleDelete();
                }}
              >
                {t("form.yes")}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Modal>
    </Box>
  );
};

export default ManageAdditionalPerson;
