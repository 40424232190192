import { Currency } from "generated/globalTypes";
import { Box, Button, Stack } from "@finvia/money-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY } from "../../../../../generated/globalTypes";
import { MonetaryInput } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/MonetaryInput";
import { InvestmentAmountWrapper } from "./FundAmountHeader.styled";

interface Props {
  amount: number;
  currency: ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY;
  setAmount: (value: number) => void;
  onSubmit: () => void;
}

const FormsCompleteHeader: React.FC<Props> = ({
  amount,
  currency,
  setAmount,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const currencyType =
    currency === ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.eur
      ? Currency.EUR
      : Currency.USD;

  return (
    <div>
      <Stack gutter="mega" direction={{ sm: "column", md: "row" }}>
        <Box width={{ sm: "100%", md: "214px" }}>
          <InvestmentAmountWrapper>
            <MonetaryInput
              defaultValue={amount}
              name="amount"
              label="amount"
              hideLabel
              decorator={currencyType}
              onChangeCallback={(newAmount) => setAmount(newAmount)}
            />
          </InvestmentAmountWrapper>
        </Box>
        <Box width={{ sm: "100%", md: "280px" }}>
          <Button
            dataAttributes={{ "data-analytics": "4.1_fund_signing_amount" }}
            onClick={() => onSubmit()}
            stretch
          >
            {t("components.alternativeInvestmentFund.requestSigningAmount")}
          </Button>
        </Box>
      </Stack>
    </div>
  );
};

export default FormsCompleteHeader;
