import { Checkbox, Stack, Text } from "@finvia/money-ui";
import { AdditionalText } from "features/alternative-investments/questionnaires/mandateContract/common/DeclarationForm/Declarations.styled";
import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SubCheckboxWrapper } from "../../FundDeclarations.styled";
import { hasAnyNested } from "../InvestorType/Professional.utils";
import { GroupProps } from "./CheckboxGroup.types";

const CheckboxGroup = <T extends string | number>(
  props: GroupProps<T>
): JSX.Element => {
  if (props.baseFieldName.length <= 0) {
    console.warn("@CheckboxGroup: empty value for prop baseFieldName");
  }

  const { t, i18n } = useTranslation();
  const { watch, errors, setValue, register, trigger } = useFormContext();

  const watchBaseFields: Record<T, boolean> & { isConfirmed: boolean } = watch(
    props.baseFieldName
  );

  const onParentClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.currentTarget.checked === false &&
      hasAnyNested<T>(watchBaseFields).length
    ) {
      props.fields.forEach((field) => {
        setValue(`${props.baseFieldName}.${field}`, false);
      });

      trigger(`${props.baseFieldName}.isConfirmed`);
    }
  };

  const onChildClick = () => {
    if (watchBaseFields.isConfirmed === false) {
      setValue(`${props.baseFieldName}.isConfirmed`, true);
    }

    trigger(`${props.baseFieldName}.isConfirmed`);
  };

  const descriptionKey = `${props.langPrefix}.professionalInvestorClassification.fields.${props.groupName}.description`;

  return (
    <Stack gutter="kilo">
      <Stack>
        <Checkbox
          ref={register}
          name={`${props.baseFieldName}.isConfirmed`}
          label={t(
            `${props.langPrefix}.professionalInvestorClassification.fields.${props.groupName}.label`
          )}
          helperText={
            i18n.exists(descriptionKey) ? t(descriptionKey) : undefined
          }
          onChange={onParentClick}
        />
        <AdditionalText>
          <Stack>
            <Text>
              {t(
                `${props.langPrefix}.professionalInvestorClassification.fields.${props.groupName}.description`
              )}
            </Text>
            {errors.declarations?.professionalInvestor
              ?.professionalInvestorClassification?.[props.groupName]
              ?.isConfirmed && (
              <Text size={1} variant="error">
                {
                  errors.declarations?.professionalInvestor
                    ?.professionalInvestorClassification?.[props.groupName]
                    ?.isConfirmed?.message
                }
              </Text>
            )}
          </Stack>
        </AdditionalText>
      </Stack>

      <SubCheckboxWrapper>
        <Stack gutter="byte">
          {props.fields.map((field) => {
            return (
              <Checkbox
                onChange={onChildClick}
                key={field}
                ref={register}
                name={`${props.baseFieldName}.${field}`}
                label={t(
                  `${props.langPrefix}.professionalInvestorClassification.fields.${props.groupName}.fields.${field}.label`
                )}
              />
            );
          })}
        </Stack>
      </SubCheckboxWrapper>
    </Stack>
  );
};

export default CheckboxGroup;
