import useCompletedForms from "features/alternative-investments/components/useCompletedForms";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../routes/routes";
import { typedUseSelector } from "../../store";
import User from "./User";
import * as Sentry from "@sentry/react";
import { Box, Stack } from "@finvia/money-ui";
import logo from "../../assets/logos/finvia-logo.svg";

type UserInfo = {
  email?: string | null;
  id?: string;
};

const dispatchPostHogUserEvent = ({ id, email }: UserInfo) => {
  if (!window.posthog || !email) {
    return;
  }

  window.posthog.identify(id);
  window.posthog.people.set({ email });
};

const Header: React.FC = () => {
  const completedForms = useCompletedForms();

  const { t } = useTranslation();
  const { username, userId } = typedUseSelector((state) => ({
    userId: state.user.id,
    username:
      state.alternativeInvestments.regulatoryData.personData?.contactData
        ?.emailAddress,
  }));

  useEffect(() => {
    const cfConsentCallback = () => {
      Sentry.setUser({ id: userId });
      dispatchPostHogUserEvent({ id: userId, email: username });
    };

    setTimeout(() => {
      window.addEventListener("cf_consent", cfConsentCallback, false);

      if (window.CookieFirst && window.CookieFirst.hasConsented) {
        cfConsentCallback();
      }
    }, 2000);

    return () =>
      window.removeEventListener("cf_consent", cfConsentCallback, false);
  });

  const backToOverviewURL = completedForms
    ? ROUTES.funds.root
    : ROUTES.questionnaires.ai.root;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <a href={backToOverviewURL} aria-label="Logo">
        <Box width={{ sm: "106px", md: "169px" }}>
          <img src={logo} alt="" />
        </Box>
      </a>
      <User username={username} actionLabel={t("header.logout")} />
    </Stack>
  );
};

export default Header;
