import { UploadStatus } from "@finvia/component-library";
import * as yup from "yup";
import { DocumentCategory } from "../generated/globalTypes";

export const baseOptionalValidation = {
  filename: yup.string(),
  mimetype: yup.string(),
  category: yup.mixed<DocumentCategory>(),
  file: yup.mixed<File>(),
  uploadStatus: yup.mixed<UploadStatus>().oneOf(Object.values(UploadStatus)),
  id: yup.string(),
};

export const baseRequiredValidation = (errorMessage?: string): yup.AnySchema =>
  yup.object({
    ...baseOptionalValidation,
    id: yup.string().required(errorMessage ? errorMessage : undefined),
  });

export const documentSchema = ({
  errorMessage,
  minAmountErrorMessage,
}: {
  errorMessage?: string;
  minAmountErrorMessage?: string;
}): yup.AnySchema => {
  return yup
    .array(baseRequiredValidation(errorMessage).required())
    .required()
    .min(1, minAmountErrorMessage ? minAmountErrorMessage : undefined);
};

export const optionalDocumentSchema = (): yup.AnySchema => {
  return yup.array(
    yup.object({
      ...baseOptionalValidation,
    })
  );
};

export type Document = yup.InferType<ReturnType<typeof documentSchema>>;

export type OptionalDocument = yup.InferType<
  ReturnType<typeof optionalDocumentSchema>
>;
