import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack, Text, Grid, InfoBox, Spinner } from "@finvia/money-ui";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InlineErrorMessage from "./InlineErrorMessage";
import type { Props, FormError } from "./FormControl.types";
import { tracking } from "utils/tracking";

const isInfoBox = ([_, error]: FormError) => error.type === "infoBox";

const getInfoBoxErrors = (errors: FormError[]) => errors.filter(isInfoBox);

export default function FormControl<FormData extends Record<string, any>>({
  dontShowErrors = false,
  backLabel,
  children,
  defaultValues,
  onBack,
  onSubmit,
  submitLabel,
  validationSchema,
  validationMode = "onChange",
  reValidationMode = "onChange",
  dataAnalytics,
  buttonsWithLongTexts,
}: Props<FormData>): JSX.Element {
  const [isSubmitting, setSubmitting] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const formMethods = useForm<FormData>({
    defaultValues: defaultValues as any,
    mode: validationMode,
    reValidateMode: reValidationMode,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    errors,
  } = formMethods;

  const dismissError = () => {
    setNetworkError(false);
  };

  const onFormSubmit = handleSubmit<FormData>((data) => {
    if (data) {
      setNetworkError(false);
      setSubmitting(true);
      // Same here as above, TS2345 and a problem with UnpackNestedValue<FormData>
      onSubmit(data as FormData)
        .catch((_error: any) => {
          // TODO: Introduce serialized error type
          setNetworkError(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setNetworkError(true);
      setSubmitting(false);
    }
  });

  const ValidationErrors = () => {
    const shouldNotShowError = isDirty === false || isValid;

    if (dontShowErrors || shouldNotShowError) {
      return null;
    }

    const errorsEntries = Object.entries(errors);
    const infoBoxErrors = getInfoBoxErrors(errorsEntries);

    if (infoBoxErrors.length > 0) {
      return (
        <InfoBox variant="danger">
          {infoBoxErrors.map(([key, error]: FormError) => (
            <Text size={2} variant="error" key={key}>
              {error.message}
            </Text>
          ))}
        </InfoBox>
      );
    }

    return (
      <>
        {errorsEntries.map(([key, error]: FormError) => (
          <Text size={2} align="center" variant="error" key={key}>
            {error.message}
          </Text>
        ))}
      </>
    );
  };

  const justifyLongTexts = buttonsWithLongTexts ? "center" : "space-between";
  const buttonsJustifyPosition = !backLabel ? "flex-end" : justifyLongTexts;

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={onFormSubmit}
        data-form-invalid={!isValid}
        aria-label="form"
      >
        <Grid gap="peta">
          <Stack gutter="tera" alignItems="center">
            {children(formMethods)}

            {networkError && <InlineErrorMessage onClose={dismissError} />}
            <ValidationErrors />
          </Stack>

          <Stack
            gutter="mega"
            direction={{
              sm: buttonsWithLongTexts ? "column-reverse" : "row",
              md: "row",
            }}
            justifyContent={{
              sm: buttonsJustifyPosition,
              md: !backLabel ? "flex-end" : "space-between",
            }}
            alignItems={{
              sm: buttonsWithLongTexts ? "inherit" : "start",
              md: "start",
            }}
          >
            {backLabel && (
              <Button variant="ghost" onClick={onBack}>
                {backLabel}
              </Button>
            )}
            {submitLabel && (
              <Button
                type="submit"
                disabled={isValid === false}
                onClick={() => {
                  tracking.triggerEvent(dataAnalytics);
                }}
                dataAttributes={{
                  ...(dataAnalytics && {
                    "data-analytics": dataAnalytics,
                  }),
                }}
              >
                <Stack gutter="mega" direction="row" justifyContent="center">
                  {submitLabel}
                  {isSubmitting && <Spinner color="white.500" />}
                </Stack>
              </Button>
            )}
          </Stack>
        </Grid>
      </form>
    </FormProvider>
  );
}
