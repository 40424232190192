import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { ContractType } from "generated/globalTypes";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Heading,
  Checkbox,
  Stack,
  Grid,
  SelectInput,
  Box,
} from "@finvia/money-ui";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import {
  experienceCurrenciesFields,
  wealthOptions,
} from "./ExperienceCurrencies.utils";
import type { ExperienceCurrenciesProps } from "./ExperienceCurrencies.types";
import { validation } from ".";

const translationPrefix = "q-ai.opportunities.form.experienceCurrency";

const ExperienceCurrencies: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);
  const isLegalEntity = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData.personData.contractType ===
      ContractType.LEGAL_ENTITY
  );

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, xl: 1 }}>
        <SidebarContentBlock
          iconType="user"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityContactPerson.title")}
          description={t("sidebar.legalEntityContactPerson.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(
    () => validation(t, isLegalEntity),
    [t, isLegalEntity]
  );
  const dispatch = useDispatch();

  const defaultData = typedUseSelector((state) => {
    return formDefaultsDeep<ExperienceCurrenciesProps>({
      currenciesExperience:
        state.alternativeInvestments.regulatoryData?.personData?.regulatoryInfo
          ?.currenciesExperience,
      wealthOrigin:
        state.alternativeInvestments.regulatoryData.personData.regulatoryInfo
          ?.wealthOrigin,
    });
  });

  const onSubmit = useCallback(
    async (data: ExperienceCurrenciesProps) => {
      dispatch(updateProfile({ regulatoryInfo: data }))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<ExperienceCurrenciesProps>
      dontShowErrors
      onBack={previousStep}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics={`2.${isLegalEntity ? "8_le" : "9_np"}_experience_currency`}
    >
      {({ formState, register }) => {
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={formState.isDirty}
            isSubmitSuccessful={formState.isSubmitSuccessful}
          >
            <Stack gutter="tera">
              {isLegalEntity && (
                <Stack gutter="mega">
                  <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                    {t(`${translationPrefix}.fields.wealthOrigin.subtitle`)}
                  </Heading>
                  <Box width="50%">
                    <SelectInput
                      ref={register}
                      required
                      name="wealthOrigin"
                      options={wealthOptions(t)}
                      value={defaultData.wealthOrigin}
                      label={t(
                        `${translationPrefix}.fields.wealthOrigin.label`
                      )}
                      helperText={t(
                        `${translationPrefix}.fields.wealthOrigin.tooltip`
                      )}
                    />
                  </Box>
                </Stack>
              )}

              {experienceCurrenciesFields(t, translationPrefix).map(
                (expCurrency) => (
                  <Stack gutter="mega" key={expCurrency.title}>
                    <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                      {expCurrency.title}
                    </Heading>
                    <Stack gutter="kilo">
                      {expCurrency.fields.map((field) => (
                        <Checkbox
                          key={field}
                          name={`currenciesExperience.${field}`}
                          label={t(
                            `${translationPrefix}.fields.experience.${field}.label`
                          )}
                          ref={register}
                        />
                      ))}
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default ExperienceCurrencies;
