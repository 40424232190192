import { KeepAliveQuery } from "graphql/queries/KeepAlive";
import { typedCreateAsyncThunk } from "store/typedCreateAsyncThunk";

export const KeepAlive = typedCreateAsyncThunk(
  "keepAlive",
  async (_, { extra }): Promise<boolean> => {
    await extra.client.query({
      query: KeepAliveQuery,
    });

    return true;
  }
);
