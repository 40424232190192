import React from "react";
import {
  Text,
  Stack,
  Heading,
  HeadingProps,
  Icon,
  IconCommonProps,
  theme,
} from "@finvia/money-ui";
import { TitleGrid } from "./SideBar.styled";

interface SidebarContentBlockProps {
  title: string;
  description: string;
  iconType?: IconCommonProps["name"];
  iconSize?: IconCommonProps["size"];
  iconColor?: IconCommonProps["color"];
  textAlign?: HeadingProps["align"];
  children?: React.ReactNode;
}

const SidebarContentBlock: React.FC<SidebarContentBlockProps> = ({
  title,
  description,
  iconType,
  iconSize = "byte",
  iconColor,
  textAlign = "left",
  children,
}) => {
  return (
    <Stack gutter="byte" alignItems={textAlign}>
      {iconType ? (
        <TitleGrid iconSize={theme.tokens.sizes.iconSizes[iconSize]}>
          <Icon name={iconType} color={iconColor} size={iconSize} />
          <Heading as="h3" align={textAlign} textTransform="uppercase" size="2">
            {title}
          </Heading>
        </TitleGrid>
      ) : (
        <Heading as="h3" align={textAlign} textTransform="uppercase" size="2">
          {title}
        </Heading>
      )}
      <Text align={textAlign}>{description}</Text>
      {children}
    </Stack>
  );
};

export default SidebarContentBlock;
