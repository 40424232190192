import { ChoiceGroup, Grid, Box, TextInput } from "@finvia/money-ui";
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import {
  BeneficialOwnerDataForm,
  validationSchema,
} from "./BeneficialOwner.schema";

const translationPrefix = "q-ai.opportunities.form.beneficialOwner";

const BeneficialOwnerForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="pie-chart"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityBeneficialOwner.title")}
          description={t("sidebar.legalEntityBeneficialOwner.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const additionalPersonsEquityPercentage = typedUseSelector((state) => {
    const additionalPersons =
      state.alternativeInvestments.regulatoryData.legalEntityData
        .legalEntityAdditionalPersons || [];

    return additionalPersons.reduce((prev: any, curr: any) => {
      return (
        prev +
        parseFloat(
          curr.beneficialOwner?.companyEquityPercentage?.split(" ")[0] || "0"
        )
      );
    }, 0);
  });

  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<BeneficialOwnerDataForm>(
      {
        isBeneficialOwner:
          state.alternativeInvestments.regulatoryData?.legalEntityData
            .isBeneficialOwner,
        companyEquityPercentage:
          state.alternativeInvestments.regulatoryData?.legalEntityData
            .companyEquityPercentage,
      },
      {
        isBeneficialOwner: undefined,
        companyEquityPercentage: undefined,
      }
    )
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (legalEntityData: BeneficialOwnerDataForm) => {
      dispatch(updateLegalEntityProfile(legalEntityData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<BeneficialOwnerDataForm>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={validationSchema(t, additionalPersonsEquityPercentage)}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      reValidationMode="onChange"
      dataAnalytics="2.3_le_beneficial_owner"
    >
      {(formMethods) => {
        const { watch, errors, register, formState, trigger } = formMethods;
        const isBeneficialOwner = watch("isBeneficialOwner");

        if (formState.dirtyFields.companyEquityPercentage) {
          setTimeout(() => {
            trigger("companyEquityPercentage");
          }, 10);
        }

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formState.isDirty}
            isSubmitSuccessful={formState.isSubmitSuccessful}
          >
            <ChoiceGroup
              name="isBeneficialOwner"
              label={t(`${translationPrefix}.subTitle`)}
              required
              hideLabel
            >
              <Box width="100%" display="flex" justifyContent="center">
                <Box width={{ sm: "100%", md: "50%" }}>
                  <Grid
                    columns={{ sm: 1, md: 2 }}
                    justifyItems="center"
                    gap={{ sm: "mega", md: "peta" }}
                  >
                    <ChoiceGroup.Button
                      label={t(
                        `${translationPrefix}.fields.legalEntityData.isBeneficialOwner.yes`
                      )}
                      value="yes"
                      ref={register}
                    />
                    <ChoiceGroup.Button
                      label={t(
                        `${translationPrefix}.fields.legalEntityData.isBeneficialOwner.no`
                      )}
                      value="no"
                      ref={register}
                    />
                  </Grid>
                </Box>
              </Box>
            </ChoiceGroup>

            {isBeneficialOwner === "yes" && (
              <Box width={{ sm: "100%", md: "50%" }}>
                <TextInput crossOrigin
                  ref={register}
                  name="companyEquityPercentage"
                  required
                  label={t(
                    `${translationPrefix}.fields.legalEntityData.companyEquityPercentage.label`
                  )}
                  placeholder="0,00"
                  step="0.01"
                  type="number"
                  textAlign="center"
                  decorator={{ type: "text", value: "%" }}
                  errorMessage={errors?.companyEquityPercentage?.message}
                />
              </Box>
            )}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default BeneficialOwnerForm;
