import { TFunction } from "i18next";
import * as yup from "yup";
import address, { optionalAddress } from "../GenericValidations/address";

const translationPrefix = "q-ai.opportunities.form.contactData";

const contactData = (t: TFunction) =>
  yup.object({
    emailAddress: yup
      .string()
      .email()
      .label(t(`${translationPrefix}.fields.emailAddress.label`)),
    phoneNumber: yup
      .string()
      .test(
        "more-than-10",
        t(`${translationPrefix}.fields.phoneNumber.validation`),
        (val) => {
          return val ? val.length > 9 : false;
        }
      )
      .required(t(`${translationPrefix}.fields.phoneNumber.validation`))
      .label(t(`${translationPrefix}.fields.phoneNumber.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      contactData: contactData(t),
      legalAddress: address(t),
      hasSeparateContactAddress: yup
        .boolean()
        .label(
          t(`${translationPrefix}.fields.hasSeparateContactAddress.label`)
        ),
      contactAddress: optionalAddress(t).when("hasSeparateContactAddress", {
        is: true,
        then: address(t),
      }),
    })
    .required();

export type ContactDataSchema = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export interface ContactDataForm {
  contactData?: ContactDataSchema["contactData"];
  contactAddress?: ContactDataSchema["contactAddress"];
  legalAddress?: ContactDataSchema["legalAddress"];
  hasSeparateContactAddress?: ContactDataSchema["hasSeparateContactAddress"];
}

export function validate(contactDataSchema: ContactDataForm): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(contactDataSchema);
}
