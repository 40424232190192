import { Option } from "@finvia/money-ui";
import { RegulatoryData_profile_regulatoryInfo_experience_assetClasses } from "generated/RegulatoryData";
import { TFunction } from "i18next";
import { GraphQLPartial } from "utils/graphQLPartial";

import {
  AverageInvestmentVolumePerYear,
  AverageTransactionsPerYear,
  ExperienceYears,
} from "../../../../../../generated/globalTypes";
import {
  ExperienceAssetClasses,
  ExperienceAssetClassesForm,
  experienceAssetsCheckboxNames,
} from "./ExperienceAssets.schema";

const translationPrefix =
  "q-ai.opportunities.form.experienceAssets.fields.experience.assetClasses.options";

export const ExperienceInYearsOptions = (t: TFunction): Option[] =>
  Object.values(ExperienceYears).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.experienceInYears.${v}`),
  }));

export const AverageTransactionsPerYearOptions = (t: TFunction): Option[] =>
  Object.values(AverageTransactionsPerYear).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.averageTransactionsPerYear.${v}`),
  }));

export const AverageInvestmentVolumePerYearOptions = (t: TFunction): Option[] =>
  Object.values(AverageInvestmentVolumePerYear).map((v) => ({
    value: v,
    text: t(`${translationPrefix}.averageInvestmentVolumePerYear.${v}`),
  }));

export const getMappedAssetClasses = (
  assetClasses?: GraphQLPartial<RegulatoryData_profile_regulatoryInfo_experience_assetClasses> | null
): ExperienceAssetClasses["assetClassesExperience"] => {
  return experienceAssetsCheckboxNames.reduce((obj, checkboxName) => {
    if (assetClasses?.[checkboxName]) {
      return {
        ...obj,
        [checkboxName]: {
          checkbox: !!assetClasses?.[checkboxName]?.experienceInYears
            ? checkboxName
            : "",
          experienceInYears:
            assetClasses?.[checkboxName]?.experienceInYears || undefined,
          averageNumberOfTransactionsPerYear:
            assetClasses?.[checkboxName]?.averageNumberOfTransactionsPerYear ||
            undefined,
          averageInvestmentVolumePerYear:
            assetClasses?.[checkboxName]?.averageInvestmentVolumePerYear ||
            undefined,
        },
      };
    }
    return obj;
  }, {} as ExperienceAssetClasses["assetClassesExperience"]);
};

// Empty Checkboxes has the value of "false", this causes the checkboxes to be rehydrated
// as checked since it's a string value. If we refresh the page the correct values are filled,
// but if we go back in the form it will show all checkboxes as checked.
export const cleanFormData = (
  data: ExperienceAssetClassesForm
): ExperienceAssetClassesForm => {
  const dataEntries = Object.entries(data.assetClassesExperience || {});
  const mappedEntries = dataEntries.map(([key, value]) => [
    key,
    value.experienceInYears ? { ...value, checkbox: key } : { checkbox: false },
  ]);
  return { assetClassesExperience: Object.fromEntries(mappedEntries) };
};
