import styled, { css } from "styled-components";

const containerBaseStyles = () => css`
  height: calc(100vh - 344px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const textWrapperBaseStyles = () => css`
  max-width: 744px;
`;

export const Container = styled.div(containerBaseStyles);
export const TextWrapper = styled.div(textWrapperBaseStyles);
