import { TFunction } from "i18next";
import * as yup from "yup";
import { InvestmentGoal } from "../../../../../generated/globalTypes";
import { GoalsProps } from "./Goals.types";

const translationPrefix = "q-ai.opportunities.form.goals";

const invalidGoals = (goals: any) =>
  goals.length !== 0 &&
  goals.every(
    (goal: any) =>
      goal === InvestmentGoal.LIQUIDITY_SUPPLY ||
      goal === InvestmentGoal.SHORT_TERM_PROFIT
  );

export const validationSchema = (t: TFunction): yup.SchemaOf<GoalsProps> =>
  yup.object().shape({
    goals: yup
      .array()
      .min(1, t(`${translationPrefix}.validations.goals_min_one`))
      .test("goals", (value, context) => {
        if (!invalidGoals(value)) {
          return true;
        }

        return context.createError({
          message: t(`${translationPrefix}.wrongInvestmentHorizon`),
          type: "infoBox",
        });
      })
      .required(),
  });
