import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ContractType } from "generated/globalTypes";
import { Checkbox, Stack, Text } from "@finvia/money-ui";

import { ErrorAlert } from "../../../../../../components/ErrorAlert";
import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { FundDocumentsDisplay } from "../../../../funds/components/FundDocumentsDisplay";
import {
  getAIMandateContractDocument,
  signAlternativeInvestmentsMandateContract,
} from "../../actions";

import { ContractConfirmationProps, validationSchema, ContractKeys } from ".";
import { DocumentWrapper } from "./ContractConfirmation.styled";
import {
  CheckboxWrapper,
  AdditionalText,
} from "../DeclarationForm/Declarations.styled";

const translationPrefix = "q-ai.opportunities.form.contractConfirmation";

const ContractConfirmationForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const memoizedSchema = useMemo(() => validationSchema(t), [t]);

  const { mandateContractDocument, contractType } = typedUseSelector(
    (state) => {
      return {
        mandateContractDocument:
          state.alternativeInvestments.mandateContractData?.contractDraft,
        contractType:
          state.alternativeInvestments.regulatoryData.personData.contractType,
      };
    }
  );

  const defaultData = typedUseSelector((state) => {
    return formDefaultsDeep<ContractConfirmationProps>(
      {
        contractConfirmation:
          state.alternativeInvestments.mandateContractData?.data?.naturalPerson
            .contractConfirmation,
      },
      {
        contractConfirmation: {
          hasReceivedAIMandateContractDocuments: false,
          hasConfirmedAIMandateContractSigning: false,
          isRetailClient: false,
        },
      }
    );
  });

  useEffect(() => {
    const pollingInterval = setInterval(() => {
      dispatch(getAIMandateContractDocument())
        .then(unwrapResult)
        .then((res) => {
          const doneLoading = res?.id;
          if (doneLoading) {
            clearInterval(pollingInterval);
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
          clearInterval(pollingInterval);
        });
    }, 3000);
    return () => {
      clearInterval(pollingInterval);
    };
  }, [dispatch]);

  const onSubmit = useCallback(
    async (data: ContractConfirmationProps) => {
      dispatch(signAlternativeInvestmentsMandateContract(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const fields: Array<ContractKeys> = [
    "hasConfirmedAIMandateContractSigning",
    "isRetailClient",
  ];

  return (
    <FormControl<ContractConfirmationProps>
      onBack={previousStep}
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.confirmation")}
      backLabel={t("onboarding.form.back")}
      fullWidthButtons
      validationMode="onChange"
      reValidationMode="onChange"
      dataAnalytics={`3.${
        contractType === ContractType.LEGAL_ENTITY ? "6_le" : "3_np"
      }_contract_confirmation`}
      buttonsWithLongTexts
    >
      {({ formState, errors, register }) => {
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.description`)}
            error={error}
            isDirty={formState.isDirty}
            isSubmitSuccessful={formState.isSubmitSuccessful}
          >
            <Stack gutter="mega">
              {errors.contractConfirmation
                ?.hasReceivedAIMandateContractDocuments && (
                <ErrorAlert
                  customErrorMessage={t("form.pleaseDownloadAllFiles")}
                />
              )}

              <DocumentWrapper>
                <FundDocumentsDisplay
                  documents={[
                    {
                      name: "contractConfirmation.hasReceivedAIMandateContractDocuments",
                      id: mandateContractDocument?.id,
                      displayName: t(
                        `${translationPrefix}.fields.hasReceivedAIMandateContractDocuments.label`
                      ),
                      downloadName: t(
                        `${translationPrefix}.fields.hasReceivedAIMandateContractDocuments.filename`
                      ),
                      asyncDocument: true,
                      mimetype: "application/pdf",
                    },
                  ]}
                />
              </DocumentWrapper>
            </Stack>

            {fields.map((field) => (
              <CheckboxWrapper key={field}>
                <Stack>
                  <Checkbox
                    ref={register}
                    name={`contractConfirmation.${field}`}
                    label={t(`${translationPrefix}.fields.${field}.label`)}
                  />

                  <AdditionalText>
                    <Stack>
                      <Text>
                        {t(`${translationPrefix}.fields.${field}.description`)}
                      </Text>

                      {errors.contractConfirmation?.[field] && (
                        <Text size={1} variant="error">
                          {errors.contractConfirmation?.[field]?.message}
                        </Text>
                      )}
                    </Stack>
                  </AdditionalText>
                </Stack>
              </CheckboxWrapper>
            ))}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default ContractConfirmationForm;
