import { Loading } from "@finvia/component-library";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import QuestionnaireController from "../../../components/QuestionnaireController";
import { NaturalPersonRegulatoryDataFormStep } from "../../../formSteps";
import ExperienceAssetClasses from "../common/ExperienceAssets/ExperienceAssets";
import { ExperienceCurrencies } from "../common/ExperienceCurrencies/";
import PersonalData from "../common/PersonalDataForm/PersonalDataForm";
import ContactData from "../common/PersonContactDataForm/ContactDataForm";
import { ROUTES } from "../../../../../routes/routes";
import BankAccounts from "./BankAccount/BankAccountsForm";
import EmploymentAndTax from "./EmploymentAndTaxForm/EmploymentAndTaxForm";
import Income from "./IncomeForm/IncomeForm";
import Liabilities from "./LiabilitiesForm/LiabilitiesForm";
import { deriveFormAccess } from "./naturalPersonValidations";
import { WealthAssessment } from "./WealthAssessmentForm";

const ContractAndRegulatoryDataQuestionnaire: React.FC = () => {
  const [atMostAccessibleStep, setAtMostAccessibleStep] = useState<
    NaturalPersonRegulatoryDataFormStep | undefined
  >(undefined);

  const history = useHistory();

  const { regulatoryData, isComplete } = useSelector((state) => ({
    regulatoryData: state.alternativeInvestments.regulatoryData.personData,
    isComplete: state.alternativeInvestments.regulatoryData.isComplete,
  }));

  useEffect(() => {
    setAtMostAccessibleStep(
      deriveFormAccess(regulatoryData).atMostAccessibleStep
    );
  }, [regulatoryData]);

  if (!atMostAccessibleStep) {
    return <Loading />;
  }

  return (
    <QuestionnaireController
      atMostAccessibleStep={atMostAccessibleStep}
      onBack={() => history.push(ROUTES.questionnaires.ai.root)}
      onComplete={() => history.push(ROUTES.questionnaires.ai.root)}
      isComplete={isComplete}
      stepGroups={[
        {
          label: "Vertragstyp",
          steps: [
            {
              id: NaturalPersonRegulatoryDataFormStep.ContractType,
              component: () => (
                <Redirect to={ROUTES.questionnaires.ai.regulatory.root} />
              ),
            },
          ],
        },
        {
          label: "Vertragspartner",
          steps: [
            {
              id: NaturalPersonRegulatoryDataFormStep.PersonalData,
              component: PersonalData,
            },
            {
              id: NaturalPersonRegulatoryDataFormStep.ContactData,
              component: ContactData,
            },
            {
              id: NaturalPersonRegulatoryDataFormStep.EmploymentAndTax,
              component: EmploymentAndTax,
            },
          ],
        },
        {
          label: "Regulatorik",
          steps: [
            {
              id: NaturalPersonRegulatoryDataFormStep.WealthAssessment,
              component: WealthAssessment,
            },
            {
              id: NaturalPersonRegulatoryDataFormStep.Income,
              component: Income,
            },
            {
              id: NaturalPersonRegulatoryDataFormStep.Liabilities,
              component: Liabilities,
            },
            {
              id: NaturalPersonRegulatoryDataFormStep.ExperienceAssetClasses,
              component: ExperienceAssetClasses,
            },
            {
              id: NaturalPersonRegulatoryDataFormStep.ExperienceCurrencies,
              component: ExperienceCurrencies,
            },
          ],
        },
        {
          label: "Referenzkonto",
          steps: [
            {
              id: NaturalPersonRegulatoryDataFormStep.BankAccounts,
              component: BankAccounts,
            },
          ],
        },
      ]}
    />
  );
};

export default ContractAndRegulatoryDataQuestionnaire;
