import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { MoneyRangeTransactions } from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import {
  Revenue,
  revenueCheckboxNames,
  validationSchema,
} from "./RevenueForm.schema";
import {
  Heading,
  Text,
  Grid,
  SelectInput,
  TextInput,
  Checkbox,
  Box,
} from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import TooltipInfo from "features/alternative-investments/components/TooltipInfo";
import { MonetaryInput } from "../../MonetaryInput";

const translationPrefix = "q-ai.opportunities.form.financialStatus.revenue";

const RevenueForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) => {
    const revenue =
      state.alternativeInvestments?.regulatoryData?.legalEntityData
        ?.financialStatus?.revenue;

    return formDefaultsDeep<Revenue>(
      {
        financialStatus: {
          revenue,
        },
      },
      {
        financialStatus: {
          revenue: {
            commercialActivities: {
              checkbox: !!revenue?.commercialActivities?.range,
              range: undefined,
              value: "",
            },
            rent: {
              checkbox: !!revenue?.rent?.range,
              range: undefined,
              value: "",
            },
            fixedAssets: {
              checkbox: !!revenue?.fixedAssets?.range,
              range: undefined,
              value: "",
            },
            others: {
              checkbox: !!revenue?.others?.range,
              range: undefined,
              value: "",
            },
          },
        },
      }
    );
  });

  const onSubmit = useCallback(
    async (revenueData: Revenue) => {
      dispatch(updateLegalEntityProfile(revenueData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<Revenue>
      dontShowErrors
      onBack={previousStep}
      defaultValues={defaultData}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics="2.13_le_revenue"
    >
      {(formMethods) => {
        const { watch, errors } = formMethods;
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid columnsGap="yotta" rowsGap="giga" columns={1}>
              <Box alignItems="center" display="flex">
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.label`)}
                  <TooltipInfo
                    label={t(`${translationPrefix}.revenueTooltip`)}
                  />
                </Heading>
              </Box>
              {revenueCheckboxNames.map((checkbox) => {
                const checkboxName = `financialStatus.revenue.${checkbox}.checkbox`;
                const watchRange = watch(
                  `financialStatus.revenue.${checkbox}.range`
                );
                const watchCheckbox = watch(checkboxName);

                return (
                  <Grid rowsGap="mega" columns={1} key={checkbox}>
                    <Grid gap="giga" columns={1}>
                      <Checkbox
                        name={checkboxName}
                        label={t(
                          `${translationPrefix}.fields.${checkbox}.range.label`
                        )}
                        ref={formMethods.register}
                      />
                    </Grid>
                    {watchCheckbox && (
                      <Grid
                        columnsGap="yotta"
                        rowsGap="mega"
                        columns={{ sm: 1, md: 2 }}
                      >
                        <SelectInput
                          name={`financialStatus.revenue.${checkbox}.range`}
                          label={t(
                            `${translationPrefix}.fields.${checkbox}.range.label`
                          )}
                          required
                          options={getSelectOptionsFromTranslation(
                            `${translationPrefix}.fields.${checkbox}.range.values`,
                            t
                          )}
                          value={
                            defaultData.financialStatus?.revenue?.[checkbox]
                              ?.range ?? undefined
                          }
                          ref={formMethods.register}
                          errorMessage={
                            errors?.["financialStatus.revenue"]?.message
                          }
                        />

                        {watchRange ===
                          MoneyRangeTransactions.MORE_THAN_50000 && (
                          <MonetaryInput
                            ref={formMethods.register}
                            required
                            name={`financialStatus.revenue.${checkbox}.value`}
                            label={t(
                              `${translationPrefix}.fields.${checkbox}.value.label`
                            )}
                            errorMessage={
                              errors?.financialStatus?.revenue?.[checkbox]
                                ?.value?.message ?? undefined
                            }
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                );
              })}
              <Text variant="error" size="1">
                {errors && (errors as any).revenueCheckboxGroup?.message}
              </Text>
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default RevenueForm;
