import { ReactElement } from "react";
import { Upload } from "@finvia/money-ui";
import { Controller, FieldValues } from "react-hook-form";
import { UploadFieldProps } from "./UploadField.types";
import { mapDocumentStatusToUpload } from "./utils/utils";

const UploadField = <FormMethods extends FieldValues, DefaultValue>({
  formMethods,
  defaultValue,
  label,
  helperText,
  inputErrorMessage,
  barErrorMessage,
  barSuccessMessage,
  documentsList,
  name,
  onDelete,
  onUpload,
  disabled,
  required,
}: UploadFieldProps<FormMethods, DefaultValue>): ReactElement => {
  const { control, setValue, register } = formMethods;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      as={
        <Upload
          name={name}
          onDelete={(document, documents) => {
            onDelete(document, documents, setValue);
          }}
          onUpload={(documents, file) => {
            onUpload(documents, file, setValue);
          }}
          documents={mapDocumentStatusToUpload(documentsList)}
          label={label}
          helperText={helperText}
          inputErrorMessage={inputErrorMessage}
          barErrorMessage={barErrorMessage}
          barSuccessMessage={barSuccessMessage}
          ref={register}
          disabled={disabled}
          required={required}
        />
      }
    />
  );
};

export default UploadField;
