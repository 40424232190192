import styled, { css } from "styled-components";

const fundSigningContent = () => css`
  ${({ theme }) => css`
    padding: 46px;
    text-align: center;
    background-color: ${theme.tokens.colors.white[500]};

    ${theme.utils.viewport.md` 
      padding: 46px 30px;
    `}
  `};
`;

const processStepsWrapper = () => css`
  padding: 0 45px;
`;

export const FundSigningContent = styled.div(fundSigningContent);
export const ProcessStepsWrapper = styled.div(processStepsWrapper);
