import { Button, Card, Icon, Text } from "@finvia/money-ui";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { useTranslation } from "react-i18next";
import { Backdrop, InfoContainer, Modal } from "./BlockDialog.styled";

const BlockDialog = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Backdrop>
      <Modal>
        <Card borderStyle="box-shadow" padding="tera">
          <InfoContainer>
            <Icon name="lock" size="mega" />
            <Text>
              {t("components.alternativeInvestmentFund.blockDialog.message")}
            </Text>
            <Link to={ROUTES.questionnaires.ai.root}>
              <Button stretch>
                {t("components.alternativeInvestmentFund.blockDialog.action")}
              </Button>
            </Link>
          </InfoContainer>
        </Card>
      </Modal>
    </Backdrop>
  );
};

export default BlockDialog;
