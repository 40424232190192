import React from "react";
import { useTranslation } from "react-i18next";
import { Text, Stack } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const FooterContainer = styled(Stack)`
  margin-bottom: ${({ theme: { tokens } }) => `${tokens.sizes.spacings.mega}`};
`;

const ImpressumContainer = styled(Stack)`
  width: unset;
`;

const LegalTextWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    margin: ${tokens.sizes.spacings.exa} 0 ${tokens.sizes.spacings.mega};
    padding: ${tokens.sizes.spacings.kilo} 0;
    border-top: 1px solid ${tokens.colors.gold[200]};
    border-bottom: 1px solid ${tokens.colors.gold[200]};
    width: 100%;
  `}
`;

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <LegalTextWrapper>
        <Text>{t("footer.legalText")}</Text>
      </LegalTextWrapper>
      <Stack justifyContent="space-between" direction="row">
        <Text align="center">&copy;&nbsp;{t("footer.text")}</Text>
        <ImpressumContainer direction="row" gutter="tera">
          <Text align="center">
            <a href={t("footer.imprint.href")}>{t("footer.imprint.title")}</a>
          </Text>
          <Text align="center">
            <a href={t("footer.privacy.href")}>{t("footer.privacy.title")}</a>
          </Text>
        </ImpressumContainer>
      </Stack>
    </FooterContainer>
  );
};

export default Footer;
