import styled, { css } from "styled-components";

const wrapper = () => css`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.tokens.colors.petrol[100]};
    padding: ${theme.tokens.sizes.spacings.tera};
    color: ${theme.tokens.colors.white[500]};
    border-radius: ${theme.tokens.sizes.radii.bit}
      ${theme.tokens.sizes.radii.bit} 0 0;
  `};
`;

const textWrapper = () => css`
  ${({ theme }) => css`
    width: 100%;

    ${theme.utils.viewport.md`
      width: 80%;
    `};
  `}
`;

const logo = () => css`
  height: 28px;
`;

const assetClass = () => css`
  ${({ theme }) => css`
    text-transform: uppercase;
    color: ${theme.tokens.colors.gold[500]};
    font-size: ${theme.tokens.typography.fontSizes[3]};
    font-weight: ${theme.tokens.typography.weights.medium};
    font-family: ${theme.tokens.typography.fontFamily.sans};
  `}
`;

export const Wrapper = styled.div(wrapper);
export const TextWrapper = styled.div(textWrapper);
export const Logo = styled.img(logo);
export const AssetClass = styled.span(assetClass);
