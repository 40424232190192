type Tracking = {
  triggerEvent: (
    event?: string,
    extraOptions?: Record<string, unknown>
  ) => void;
};

export const tracking: Tracking = {
  triggerEvent: (event, extraOptions = {}): void => {
    if (!window.posthog) {
      return;
    }

    if (event) {
      window.posthog.capture(event, extraOptions);
    }
  },
};
