import React from "react";
import { useTranslation } from "react-i18next";
import { DigitalIdentificationCardProps } from "./types";
import { Heading, Text, Stack, Ribbon, Button } from "@finvia/money-ui";
import * as S from "./DigitalIdentificationCard.styled";

const DigitalIdentificationCard: React.FC<DigitalIdentificationCardProps> = ({
  imageNode,
  image,
  title,
  description,
  disabled,
  children,
  onIdentificationStart,
  dataAnalytics,
}) => {
  const { t } = useTranslation();

  return (
    <S.Container disabled={Boolean(disabled)}>
      {disabled && (
        <Ribbon backgroundColor="petrol.600" labelColor="white.500">
          {t("form.disabled")}
        </Ribbon>
      )}
      <S.ImageWrapper>
        {imageNode}
        {image && <img src={image} alt="identify method" />}
      </S.ImageWrapper>
      <S.ContentWrapper>
        <Stack gutter="mega">
          {title && (
            <Heading as="h3" size={{ sm: 3, lg: 4 }}>
              {title}
            </Heading>
          )}
          {description && <Text>{description}</Text>}
        </Stack>
        {children}
        <Button
          disabled={disabled}
          onClick={onIdentificationStart}
          dataAttributes={{
            ...(dataAnalytics && {
              "data-analytics": dataAnalytics,
            }),
          }}
        >
          {t("form.identify")}
        </Button>
      </S.ContentWrapper>
    </S.Container>
  );
};

export default DigitalIdentificationCard;
