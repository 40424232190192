import { TableProps, Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const headerCustomStyles = css`
  ${({ theme }) => css`
    justify-content: space-between;

    ${theme.utils.viewport.md`
      grid-template-columns: 120px 140px 130px 120px 140px;
    `};

    ${theme.utils.viewport.lg`
      grid-template-columns: 120px 200px 130px 120px 140px;
    `};
  `}
`;

const headCustomStyles = css`
  ${({ theme }) => css`
    font-weight: ${theme.tokens.typography.weights.book};
    color: ${theme.tokens.colors.neutral[750]};
    text-align: start;
    justify-content: start;

    &:nth-of-type(3) {
      text-align: right;
      justify-content: end;
      padding-right: ${theme.tokens.sizes.spacings.mega};
    }

    &:nth-of-type(4) {
      text-align: center;
      justify-content: center;
    }
  `}
`;

const rowCustomStyles = css`
  ${({ theme }) => css`
    grid-template-rows: initial;
    padding: 0 ${theme.tokens.sizes.spacings.kilo};
    background: ${theme.tokens.colors.neutral[300]};
    margin-bottom: ${theme.tokens.sizes.spacings.byte};

    ${theme.utils.viewport.md`
      grid-template-columns: 120px 140px 130px 120px 140px;
      justify-content: space-between;
      padding: 0;
      background: initial;

      &:last-of-type {
        border-bottom: none;
      }
    `};

    ${theme.utils.viewport.lg`
      grid-template-columns: 120px 200px 130px 120px 140px;
    `};
  `}
`;

const cellCustomStyles = css`
  ${({ theme }) => css`
    justify-content: space-between;
    border-bottom: none;

    & > :nth-child(1) {
      max-width: 40%;
      padding-right: ${theme.tokens.sizes.spacings.mega};
      word-break: break-word;
      hyphens: auto;
    }

    & > :nth-child(2) {
      max-width: 60%;
      text-align: end;
      justify-content: end;
      word-break: break-word;
      hyphens: auto;
      font-size: ${theme.tokens.typography.fontSizes[3]};
    }

    ${theme.utils.viewport.md`
      justify-content: start;

      & > :nth-child(1) {
        max-width: unset;
        padding-right: unset;
      }
      
      & > :nth-child(2) {
        text-align: start;
        max-width: unset;
      }

      &:nth-of-type(3) {
        justify-content: end;
        padding-right: ${theme.tokens.sizes.spacings.mega};
      }
    
      &:nth-of-type(4) {
        justify-content: center;
      }
    `};
  `}
`;

export const customTableStyles: TableProps["customStyles"] = {
  header: headerCustomStyles,
  head: headCustomStyles,
  row: rowCustomStyles,
  cell: cellCustomStyles,
};

const imageWrapper = ({ theme }: { theme: Theme }) => css`
  max-width: 120px;
  height: auto;

  ${theme.utils.viewport.md`
    padding-right: ${theme.tokens.sizes.spacings.mega};    
  `};
`;

export const ImageWrapper = styled.img<{ theme: Theme }>(imageWrapper);
