import {
  Box,
  Button,
  ChoiceGroup,
  Grid,
  Heading,
  Icon,
  InfoBox,
  Radio,
  SelectInput,
  Stack,
  TextAreaInput,
  TextInput,
} from "@finvia/money-ui";
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import * as S from "./Atad.styled";
import { countries } from "utils/options/countries";

const translationPrefix =
  "q-ai.opportunities.form.bankAccountSelection.fields.atad";

const Atad: React.FC = () => {
  const { t } = useTranslation();
  const { register, errors, watch, control, getValues } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "taxResidencies",
  });

  const unlimitedTaxLiability = watch("unlimitedTaxLiability");

  const dictonary: Record<number, string> = {
    0: "Erste",
    1: "Zweite",
    2: "Dritte",
  };

  return (
    <Stack gutter="mega">
      <S.FullRow>
        <Heading
          fontWeight="medium"
          as="h5"
          size={{ sm: 3, lg: 4 }}
          font="sans"
        >
          {t(`${translationPrefix}.title`)}
        </Heading>
      </S.FullRow>
      <Box>
        <InfoBox icon="info">{t(`${translationPrefix}.infoBox`)}</InfoBox>
      </Box>

      <Grid columns={{ sm: 1 }} columnsGap="yotta" rowsGap="giga">
        {fields.map((field, index) => (
          <>
            <Grid columns={{ sm: 1, md: 2 }} columnsGap="yotta" rowsGap="giga">
              <Controller
                key={field.id}
                control={control}
                name={`taxResidencies[${index}].country`}
                defaultValue={field.country}
                as={
                  <SelectInput
                    readOnly={index === 0 && Boolean(field.country)}
                    name={`taxResidencies[${index}].country`}
                    label={`${dictonary[index]} ${t(
                      `${translationPrefix}.taxResidence`
                    )}`}
                    options={countries(t)}
                    searchable
                    required
                    testId={`taxResidencies[${index}].country`}
                  />
                }
              />
            </Grid>
            <Grid columns={{ sm: 1, md: 2 }} columnsGap="yotta" rowsGap="giga">
              <TextInput
                crossOrigin
                ref={register}
                name={`taxResidencies[${index}].taxId`}
                label={t(`${translationPrefix}.taxNumber`)}
                required
                value={field.taxId}
                readOnly={index === 0 && Boolean(field.taxId)}
              />
              <TextInput
                crossOrigin
                ref={register}
                name={`taxResidencies[${index}].localTaxOffice`}
                label={t(`${translationPrefix}.localTaxOffice`)}
                required
                value={field.localTaxOffice}
                readOnly={index === 0 && Boolean(field.localTaxOffice)}
              />
            </Grid>
          </>
        ))}
      </Grid>

      <S.FullRow>
        <Button
          variant="link"
          onClick={() => append({ value: "" })}
          disabled={fields.length > 2}
          testId="addTaxResidencyButton"
        >
          + Weitere Steueransässigkeit hinzufügen
        </Button>
      </S.FullRow>

      <Stack gutter="peta">
        <Box
          width={{
            sm: "100%",
            md: "50%",
          }}
        >
          <ChoiceGroup
            label={t(`${translationPrefix}.unlimitedTaxLiability`)}
            name="unlimitedTaxLiability"
            required
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 190px)",
              }}
            >
              <Radio
                ref={register}
                name="unlimitedTaxLiability"
                value="yes"
                label={t("form.yes")}
              />
              <Radio
                ref={register}
                name="unlimitedTaxLiability"
                value="no"
                label={t("form.no")}
              />
            </div>
          </ChoiceGroup>
        </Box>

        {unlimitedTaxLiability === "no" && (
          <TextAreaInput
            crossOrigin
            defaultValue={getValues("reasonForLimitedTaxLiability")}
            ref={register}
            name="reasonForLimitedTaxLiability"
            label={t(`${translationPrefix}.reasonForLimitedTaxLiability`)}
            required
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Atad;
