import { Tooltip } from "@finvia/money-ui";
import styled, { css } from "styled-components";

export const TooltipWrapper = styled.span`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    margin-left: ${theme.tokens.sizes.spacings.bit};
    bottom: 2px;
    position: relative;
    cursor: pointer;
    background-color: ${theme.tokens.colors.gold[500]};
    border-radius: ${theme.tokens.sizes.radii.circle};
    color: ${theme.tokens.colors.white[500]};
    font-size: ${theme.tokens.typography.fontSizes[0]};
  `}
`;

const TooltipInfo = ({ label }: { label: string }): JSX.Element => (
  <Tooltip label={label} id={label}>
    <TooltipWrapper>i</TooltipWrapper>
  </Tooltip>
);

export default TooltipInfo;
