import { FormDefaultData } from "../../../../../utils/graphQLPartial";
import { LegalEntityRegulatoryDataFormStep } from "../../../formSteps";
import { validationSchema as ExperienceAssetClassesValidationSchema } from "../common/ExperienceAssets/ExperienceAssets.schema";
import { validation as ExperienceCurrencyFormValidationSchema } from "../common/ExperienceCurrencies/ExperienceCurrencies.schema";
import {
  PersonalDataForm,
  validationSchema as PersonalDataValidationSchema,
} from "../common/PersonalDataForm/PersonalData.schema";
import { validationSchema as ContactDataValidationSchema } from "../common/PersonContactDataForm/contactData.schema";
import { ContractTypeForm } from "../contractTypeFormValidations";
import { ContractAndRegulatoryData } from "../naturalPerson/naturalPersonValidations";
import {
  AdditionalPersonProps,
  AddPersonsPageProps,
  validationPageSchema as AddLegalEntityPersonSchema,
} from "./AddPersonsPage";
import {
  BankAccountsProps,
  validationSchema as BankAccountsValidationSchema,
} from "./BankAccounts";
import {
  BeneficialOwnerDataForm,
  validationSchema as BeneficialOwnerValidationSchema,
} from "./BeneficialOwner/BeneficialOwner.schema";
import {
  BusinessActivity,
  validationSchema as LegalEntityInformationValidationSchema,
} from "./BusinessActivity/BusinessActivityValidations";
import {
  BusinessIndustry,
  validationSchema as BusinessIndustryValidationSchema,
} from "./BusinessActivity/BusinessIndustryTypeValidations";
import { validationSchema as DocumentUploadValidationSchema } from "./DocumentsUpload/DocumentsUpload.schema";
import { DocumentUploadStore } from "./DocumentsUpload/DocumentsUpload.types";
import {
  EmploymentLegalEntity,
  validationSchema as EmploymentValidationSchema,
} from "./Employment/Employment.schema";
import {
  ContactDataProps,
  validationSchema as LegalEntityAddressValidationSchema,
} from "./ContactData/";

import {
  LegalEntityName,
  validationSchema as LegalEntityNameValidationSchema,
} from "./LegalEntityName/LegalEntityNameValidations";
import {
  LegalRepresentativeDataForm,
  validationSchema as LegalRepresentativeValidationSchema,
} from "./LegalRepresentative";
import {
  LiabilitiesProps,
  validationSchema as LiabilitiesValidationSchema,
} from "./Liabilities";
import {
  Revenue,
  validationSchema as RevenueValidationSchema,
} from "./Revenue/RevenueForm.schema";
import {
  TaxInformation,
  validationSchema as TaxInformationSchema,
} from "./TaxInformation/TaxInformation.schema";
import {
  validationSchema as WealthAssessmentSchema,
  WealthAssessment,
} from "./WealthAssessment/WealthAssessment.schema";

export type LegalEntityId = string;
export type PowerOfAttorney = {
  url: string;
};

export type LegalEntityContractAndRegulatoryData = ContractTypeForm &
  LegalRepresentativeDataForm &
  BeneficialOwnerDataForm &
  PersonalDataForm &
  BusinessActivity &
  BusinessIndustry &
  EmploymentLegalEntity &
  LegalEntityName &
  ContactDataProps &
  WealthAssessment &
  Revenue &
  LiabilitiesProps &
  TaxInformation &
  DocumentUploadStore &
  BankAccountsProps &
  ContractAndRegulatoryData & {
    profile: ContractAndRegulatoryData;
  } & AddPersonsPageProps & {
    legalEntityAdditionalPersons: Array<AdditionalPersonProps>;
  } & {
    legalEntityId: LegalEntityId;
  } & {
    powerOfAttorney: PowerOfAttorney;
  };

export function deriveFormAccess({
  legalEntity,
  naturalPerson,
}: {
  legalEntity: FormDefaultData<LegalEntityContractAndRegulatoryData>;
  naturalPerson: FormDefaultData<ContractAndRegulatoryData>;
}): {
  atMostAccessibleStep: LegalEntityRegulatoryDataFormStep;
  isComplete: boolean;
} {
  const t = (k: any) => k;
  let atMostAccessibleStep =
    LegalEntityRegulatoryDataFormStep.LegalRepresentative;
  let isComplete = false;

  /* NaturalPerson and LegalEntity details are wrapped in the FormDefaultData,
  this sets the properties to optionally be null or undefined as well so
  the early termination is necessary here */

  if (!naturalPerson) {
    return {
      atMostAccessibleStep: LegalEntityRegulatoryDataFormStep.PersonalData,
      isComplete: false,
    };
  }

  if (!legalEntity) {
    return {
      atMostAccessibleStep:
        LegalEntityRegulatoryDataFormStep.LegalRepresentative,
      isComplete: false,
    };
  }

  const {
    personalData,
    citizenship,
    regulatoryInfo,
    contactData,
    contactAddress,
    legalAddress,
  } = naturalPerson;

  const {
    isLegalRepresentative,
    isBeneficialOwner,
    companyEquityPercentage,
    employment,
    profile,
    legalEntityData,
    businessActivity,
    businessIndustry,
    legalEntityContactData,
    wealthAssessment,
    financialStatus,
    taxInformation,
    documents,
    bankAccounts,
    legalEntityAdditionalPersons,
    legalRepresentationType,
    hasReceivedProcurationDocument,
  } = legalEntity;

  try {
    LegalRepresentativeValidationSchema(t).validateSync({
      isLegalRepresentative,
    });

    const additionalPersonsCompanyEquityPercentage =
      legalEntityAdditionalPersons?.reduce((prev, curr) => {
        return (
          prev +
          parseFloat(
            curr.beneficialOwner?.companyEquityPercentage?.split(" ")[0] || "0"
          )
        );
      }, 0) || 0;

    atMostAccessibleStep =
      LegalEntityRegulatoryDataFormStep.UltimateBeneficiary;
    BeneficialOwnerValidationSchema(
      t,
      additionalPersonsCompanyEquityPercentage
    ).validateSync({
      isBeneficialOwner,
      companyEquityPercentage,
    });

    const isLegalEntity = true;

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.PersonalData;
    PersonalDataValidationSchema(t, isLegalEntity).validateSync({
      personalData,
      citizenship,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.ContactData;

    const hasSeparateContactAddress =
      contactAddress === undefined || contactAddress?.streetName === ""
        ? false
        : true;

    ContactDataValidationSchema(t).validateSync({
      contactData,
      contactAddress: hasSeparateContactAddress ? contactAddress : undefined,
      legalAddress,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.Employment;
    EmploymentValidationSchema(t).validateSync({
      employment,
      taxInfo: profile?.taxInfo,
    });

    atMostAccessibleStep =
      LegalEntityRegulatoryDataFormStep.ExperienceAssetClasses;
    ExperienceAssetClassesValidationSchema(t).validateSync(regulatoryInfo);

    atMostAccessibleStep =
      LegalEntityRegulatoryDataFormStep.ExperienceCurrencies;
    ExperienceCurrencyFormValidationSchema(t).validateSync(
      regulatoryInfo?.currenciesExperience
    );

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.LegalEntityName;
    LegalEntityNameValidationSchema(t).validateSync({
      legalEntityData,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.BusinessActivity;
    LegalEntityInformationValidationSchema(t).validateSync({
      businessActivity,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.BusinessIndustry;
    BusinessIndustryValidationSchema(t).validateSync({
      businessIndustry,
    });

    atMostAccessibleStep =
      LegalEntityRegulatoryDataFormStep.LegalEntityContactData;
    LegalEntityAddressValidationSchema(t).validateSync({
      legalEntityContactData,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.WealthAssessment;
    WealthAssessmentSchema(t).validateSync({ wealthAssessment });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.Revenue;
    RevenueValidationSchema(t).validateSync({
      financialStatus: { revenue: financialStatus?.revenue },
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.Liabilities;
    LiabilitiesValidationSchema(t).validateSync({
      financialStatus: {
        monthlyLiabilities: financialStatus?.monthlyLiabilities,
        monthlyLiquidity: financialStatus?.monthlyLiquidity,
        reserve: financialStatus?.reserve,
      },
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.Tax;
    TaxInformationSchema(t).validateSync({ taxInformation });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.Documents;
    DocumentUploadValidationSchema({
      t,
      registrationType: legalEntityData?.registrationType || undefined,
    }).validateSync({
      documents,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.BankAccounts;
    BankAccountsValidationSchema(t).validateSync({
      bankAccounts,
    });

    atMostAccessibleStep =
      LegalEntityRegulatoryDataFormStep.AddLegalEntityPersons;

    const amountOfNaturalPersons = 1;
    const totalAmountOfPersons =
      (legalEntityAdditionalPersons?.length as number) + amountOfNaturalPersons;
    const hasMoreThanOneLR = legalEntityAdditionalPersons?.some(
      (data, index) => index !== 0 && data.isLegalRepresentative
    );

    AddLegalEntityPersonSchema(t, hasMoreThanOneLR).validateSync({
      legalRepresentationType,
      hasReceivedProcurationDocument,
    });

    atMostAccessibleStep = LegalEntityRegulatoryDataFormStep.ContractType;

    isComplete = true;
  } catch (error) {
    // no-op
    console.log("Error", error);
  }

  return {
    atMostAccessibleStep,
    isComplete,
  };
}
