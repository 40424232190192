import { makeStyles } from "@material-ui/core/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { captureException } from "@sentry/react";
import LoadingWrapper from "components/LoadingWrapper/LoadingWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LegalEntityIdentificationStatus } from "../../../../../../generated/globalTypes";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { MandateContractLegalEntityFormStep } from "../../../../formSteps";
import { startLegalEntityIdentificationProcess } from "../actions";
import { ROUTES } from "../../../../../../routes/routes";

const translationPrefix = "q-ai.mandate.form.identificationLoader";

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "60vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const IdentificationLoader: React.FC<StepComponentProps> = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);

  const getLegalEntityIdentificationStatus = useCallback(() => {
    dispatch(startLegalEntityIdentificationProcess())
      .then(unwrapResult)
      .then((identificationStatusData) => {
        // Set timout so the loading show up at least until the animation finishes

        if (!identificationStatusData?.legalRepresentative) {
          setError(true);
        } else {
          setTimeout(() => {
            if (
              identificationStatusData?.legalEntityIdentificationStatus ===
              LegalEntityIdentificationStatus.CREATED
            ) {
              history.push(
                `${ROUTES.questionnaires.ai.mandate.legalEntity}/${MandateContractLegalEntityFormStep.IdentificationList}`
              );
            } else {
              history.push(
                `${ROUTES.questionnaires.ai.mandate.legalEntity}/${MandateContractLegalEntityFormStep.IdentificationStatus}`
              );
            }
          }, 2000);
        }
      })
      .catch((e) => {
        console.error(e);
        setError(true);
        captureException(e);
      });
  }, [dispatch, history]);

  useEffect(() => {
    getLegalEntityIdentificationStatus();
  }, [getLegalEntityIdentificationStatus]);

  return (
    <div className={classes.container}>
      <LoadingWrapper
        isLoading={true}
        error={error}
        title={t(`${translationPrefix}.title`)}
        message={t(`${translationPrefix}.message`)}
      >
        <></>
      </LoadingWrapper>
    </div>
  );
};

export default IdentificationLoader;
