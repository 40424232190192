import { combineReducers } from "@reduxjs/toolkit";
import alternativeInvestmentFunds from "./funds/slice";
import regulatoryData from "./questionnaires/contractAndRegulatoryData/slice";
import investmentOpportunities from "./questionnaires/investorProfile/slice";
import mandateContractData from "./questionnaires/mandateContract/slice";

export default combineReducers({
  investmentOpportunities: investmentOpportunities.reducer,
  regulatoryData: regulatoryData.reducer,
  mandateContractData: mandateContractData.reducer,
  alternativeInvestmentFunds: alternativeInvestmentFunds.reducer,
});
