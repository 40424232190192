// TODO: Is there a better way to prevent that we break the application?
// E.g. what if the file is missing / malformed?
import naceCodes from "../../../../../../../scripts/nace_codes/codes.json";

export const industryTypes = naceCodes;

export interface NACECode {
  section: string;
  division?: string;
  group?: string;
  class?: string;
}

export interface IndustryNode extends NACECode {
  level: number;
  name: string;
  children?: IndustryNode[];
}

const naceCodeRegex = new RegExp(
  "(?<division>[0-9]+)(?:.(?<group>[0-9]{0,1})(?<class>[0-9]{0,1}))?"
);

export function parseNaceCode(code: string): NACECode {
  const [section, rest] = code.split(" ");
  const result: NACECode = {
    section,
    division: undefined,
    group: undefined,
    class: undefined,
  };
  const parsedCode = naceCodeRegex.exec(rest);

  if (parsedCode) {
    const { division, group } = parsedCode.groups as Omit<NACECode, "section">;
    result.division = division || undefined;
    result.group = group ? `${result.division}.${group}` : undefined;

    result.class = parsedCode.groups?.class
      ? `${result.group}${parsedCode.groups.class}`
      : undefined;
  }
  return result;
}

export function naceCodeToString(node: IndustryNode | NACECode): string {
  if (node.class) {
    return `${node.section} ${node.class}`;
  }
  if (node.group) {
    return `${node.section} ${node.group}`;
  }
  if (node.division) {
    return `${node.section} ${node.division}`;
  }
  return node.section;
}

export function getParentsFromCode(code: string): string[] {
  const naceCode = parseNaceCode(code);
  const { section, division, group } = naceCode;
  return [
    section,
    `${section} ${division}`,
    `${section} ${group}`,
    `${section} ${naceCode.class}`,
  ];
}
