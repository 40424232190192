import { Box, Grid } from "@finvia/money-ui";
import React, { ReactNode, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import {
  ContainerWrapper,
  Divider,
  HeaderWrapper,
  ChildrenWrapper,
  SidebarContainer,
  SidebarWrapper,
  MainWrapper,
} from "./index.styled";
import Sidebar from "./Sidebar/Sidebar";
import SidebarOverrideContext from "./Sidebar/SidebarOverrideContext";

const Layout: React.FC<{
  unless?: string[];
  children: React.ReactElement;
}> = ({ unless = [], children }) => {
  const location = useLocation();
  const noLayout = unless.includes(location.pathname);

  const [sidebarOverride, setSidebarOverride] = useState<ReactNode | undefined>(
    undefined
  );

  const memoizedSidebarMethods = useMemo(
    () => ({
      sidebarOverride,
      setSidebarOverride,
    }),
    [sidebarOverride, setSidebarOverride]
  );

  if (noLayout) {
    return <>{children}</>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
    >
      <ContainerWrapper>
        <MainWrapper>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <SidebarOverrideContext.Provider value={memoizedSidebarMethods}>
            <Grid gap={{ md: "giga", xl: "giga" }} columns={{ sm: 1, xl: 2 }}>
              <ChildrenWrapper>{children}</ChildrenWrapper>
              <SidebarContainer>
                <Divider />
                <SidebarWrapper>
                  <Sidebar />
                </SidebarWrapper>
              </SidebarContainer>
            </Grid>
          </SidebarOverrideContext.Provider>
        </MainWrapper>
        <Footer />
      </ContainerWrapper>
    </Box>
  );
};

export default Layout;
