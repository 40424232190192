import { Theme } from "@finvia/money-ui";
import { ENUM_ALTERNATIVEINVESTMENTFUND_STATUS } from "generated/globalTypes";
import styled, { css } from "styled-components";

const documentWrapper = ({ theme }: { theme: Theme }) => css`
  align-self: center;
  width: 100%;

  ${theme.utils.viewport.md`
    max-width: 360px;
  `};
`;

const fundBoxesWrapper = ({ theme }: { theme: Theme }) => css`
  gap: ${theme.tokens.sizes.spacings.giga};
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 0;
    width: 0;
    min-width: 200px;
  }
`;

const fundBoxesWrapperResponsive = ({
  theme,
  numberOfBoxes,
}: {
  theme: Theme;
  numberOfBoxes: number;
}) => {
  const numberOfBoxesFullWidth = numberOfBoxes === 13 ? "3" : "4";
  return css`
    & > div:nth-of-type(n + ${numberOfBoxesFullWidth}) {
      min-width: 45%;
    }

    ${theme.utils.viewport.md`
      & > *, 
      & > div:nth-of-type(1),  
      & > div:nth-of-type(n + ${numberOfBoxesFullWidth}) {
        flex: 1 1 0;
        width: 0;
        min-width: 200px;
        max-width: unset;
        order: unset;
      }

      & > div:nth-last-of-type(-n + 4) {
        ${
          numberOfBoxes === 10 || numberOfBoxes === 13 ? "flex-basis: 48%;" : ""
        }
      }
    `};
  `;
};

const regionAndIndustriesWrapper = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.tokens.sizes.spacings.mega};
  width: 100%;
  flex-wrap: wrap;

  ${theme.utils.viewport.md`
    flex-wrap: nowrap;
  `};
`;

const regionWrapper = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: ${theme.tokens.sizes.spacings.mega};
  box-sizing: border-box;
  background: ${theme.tokens.colors.neutral[300]};
  gap: ${theme.tokens.sizes.spacings.byte};
  width: 100%;

  ${theme.utils.viewport.md`
    flex: 1;
  `};
`;

const region = ({ theme }: { theme: Theme }) => css`
  color: ${theme.tokens.colors.neutral[750]};
  font-size: ${theme.tokens.typography.fontSizes[1]};
  font-family: ${theme.tokens.typography.fontFamily.sans};
`;

const regionName = ({ theme }: { theme: Theme }) => css`
  color: ${theme.tokens.colors.neutral[800]};
  font-size: ${theme.tokens.typography.fontSizes[3]};
  font-family: ${theme.tokens.typography.fontFamily.sans};
  font-weight: ${theme.tokens.typography.weights.medium};
`;

const map = ({ theme }: { theme: Theme }) => css`
  width: calc(100% - ${theme.tokens.sizes.spacings.mega});
`;

const industriesWrapper = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.tokens.sizes.spacings.mega};
  width: 100%;
  min-height: 255px;
  padding: ${theme.tokens.sizes.spacings.mega};
  background: ${theme.tokens.colors.neutral[300]};
  color: ${theme.tokens.colors.neutral[750]};

  ${theme.utils.viewport.md`
    flex: 1;
  `};
`;

const industriesContainer = ({ theme }: { theme: Theme }) => css`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: ${theme.tokens.sizes.spacings.mega};

  ${theme.utils.viewport.md`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, minmax(0px, max-content));
  `};
`;

const industry = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  gap: ${theme.tokens.sizes.spacings.byte};
`;

const industryName = ({ theme }: { theme: Theme }) => css`
  font-size: ${theme.tokens.typography.fontSizes[2]};
`;

const boxTitle = ({ theme }: { theme: Theme }) => css`
  color: ${theme.tokens.colors.neutral[800]};
  font-size: ${theme.tokens.typography.fontSizes[4]};
  font-family: ${theme.tokens.typography.fontFamily.sans};
  font-weight: ${theme.tokens.typography.weights.medium};
  text-align: center;
  margin-bottom: ${theme.tokens.sizes.spacings.byte};
`;

const boxStatusTitle = ({
  theme,
  status,
}: {
  theme: Theme;
  status?: ENUM_ALTERNATIVEINVESTMENTFUND_STATUS;
}) => {
  const fundStatusDictionary = {
    open: theme.tokens.colors.petrol[600],
    planned: theme.tokens.colors.gold[500],
    closed: theme.tokens.colors.red[500],
    hidden: "unset",
  } as const;

  return (
    status &&
    css`
      color: ${fundStatusDictionary[status]};
    `
  );
};

type BoxSubtitleStyleProps = { theme: Theme; maxWidth?: string };

const boxSubtitle = ({ theme }: BoxSubtitleStyleProps) => css`
  color: ${theme.tokens.colors.neutral[750]};
  font-size: ${theme.tokens.typography.fontSizes[2]};
  font-family: ${theme.tokens.typography.fontFamily.sans};
  text-align: center;
  word-break: break-word;
  hyphens: auto;
`;

const boxSubtitleWithMaxWidth = ({ maxWidth }: { maxWidth?: string }) =>
  maxWidth &&
  css`
    max-width: ${maxWidth};
  `;

export const FundBoxesWrapper = styled.div<{
  theme: Theme;
  numberOfBoxes: number;
}>(fundBoxesWrapper, fundBoxesWrapperResponsive);
export const RegionWrapper = styled.div<{ theme: Theme }>(regionWrapper);
export const IndustriesWrapper = styled.div<{ theme: Theme }>(
  industriesWrapper
);
export const IndustriesContainer = styled.div<{ theme: Theme }>(
  industriesContainer
);
export const Region = styled.div<{ theme: Theme }>(region);
export const RegionName = styled.div<{ theme: Theme }>(regionName);
export const RegionAndIndustriesWrapper = styled.div<{ theme: Theme }>(
  regionAndIndustriesWrapper
);
export const MapContainer = styled.img<{ theme: Theme }>(map);
export const Industry = styled.div<{ theme: Theme }>(industry);
export const IndustryName = styled.div<{ theme: Theme }>(industryName);
export const DocumentWrapper = styled.div<{ theme: Theme }>(documentWrapper);
export const Subtitle = styled.div<BoxSubtitleStyleProps>(
  boxSubtitle,
  boxSubtitleWithMaxWidth
);
export const Title = styled.div<{ theme: Theme }>(boxTitle, boxStatusTitle);
