import { isAfter, parse } from "date-fns";
import { TFunction } from "i18next";
import { dateFormat } from "utils/helpers";
import * as yup from "yup";
import countries from "../../../../../../data/countries";
import {
  ProfessionalTitle,
  Salutation,
} from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.personalData.fields";

const personalDataValidationSchema = (t: TFunction) =>
  yup.object({
    salutation: yup
      .mixed<Salutation>()
      .required(t(`${translationPrefix}.personalData.salutation.required`))
      .oneOf(
        Object.values(Salutation),
        t(`${translationPrefix}.personalData.salutation.required`)
      ),
    professionalTitle: yup
      .mixed<ProfessionalTitle>()
      .oneOf([null, ...Object.values(ProfessionalTitle)])
      .transform((s) => (s === "" ? null : s)),
    firstName: yup
      .string()
      .required(t(`${translationPrefix}.personalData.firstName.required`)),
    familyName: yup
      .string()
      .required(t(`${translationPrefix}.personalData.familyName.required`)),
    birthName: yup.string(),
    dateOfBirth: yup
      .string()
      .required(t(`${translationPrefix}.personalData.dateOfBirth.required`))
      .test(
        "is-valid-birthDate",
        t(`${translationPrefix}.personalData.dateOfBirth.required`),
        (value) => {
          // True so it can delegate to required()
          if (!value) return true;
          try {
            const date = parse(value, "d.M.y", new Date());
            const OLDEST_BIRTH_DATE = new Date(1900, 0, 1);
            const matchDateFormat = value.match(dateFormat) !== null;

            return isAfter(date, OLDEST_BIRTH_DATE) && matchDateFormat;
          } catch (_e) {
            return false;
          }
        }
      ),
    cityOfBirth: yup
      .string()
      .required(t(`${translationPrefix}.personalData.cityOfBirth.required`)),
    countryOfBirth: yup
      .string()
      .oneOf(countries)
      .required(t(`${translationPrefix}.personalData.countryOfBirth.required`)),
  });

const citizenshipValidationSchema = (t: TFunction, isLegalEntity?: boolean) =>
  yup.object({
    primary: yup
      .string()
      .oneOf(countries)
      .required(
        t(`${translationPrefix}.citizenship.primary_citizenship.required`)
      ),
    secondary: yup.array(
      yup.object({
        value: yup
          .string()
          .oneOf(
            countries,
            t(`${translationPrefix}.citizenship.secondary_citizenship.required`)
          )
          .required(
            t(`${translationPrefix}.citizenship.secondary_citizenship.required`)
          ),
      })
    ),
    thirdWorldNational: isLegalEntity
      ? yup.string()
      : yup
          .string()
          .required(
            t(`${translationPrefix}.citizenship.thirdWorldNational.required`)
          ),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction, isLegalEntity?: boolean) =>
  yup
    .object({
      personalData: personalDataValidationSchema(t),
      citizenship: citizenshipValidationSchema(t, isLegalEntity),
    })
    .required();

export type PersonalData = yup.InferType<
  ReturnType<typeof personalDataValidationSchema>
>;

export type Citizenship = yup.InferType<
  ReturnType<typeof citizenshipValidationSchema>
>;

export interface PersonalDataForm {
  personalData?: PersonalData;
  citizenship?: Citizenship;
}

export function validate(personalData: PersonalDataForm): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(personalData);
}
