import styled, { css } from "styled-components";

const containerWrapper = () => css`
  ${({ theme }) => css`
    ${theme.utils.viewport.md`
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}

    ${theme.utils.viewport.lg`
      max-width: min-content;
    `}
  `};
`;

const headerWrapper = () => css`
  ${({ theme }) => css`
    margin-top: ${theme.tokens.sizes.spacings.exa};
    margin-bottom: ${theme.tokens.sizes.spacings.zetta};
  `};
`;

const childrenWrapper = () => css`
  ${({ theme }) => css`
    width: 100%;

    ${theme.utils.viewport.lg`
      min-width: 900px;
    `}

    ${theme.utils.viewport.xl`
      max-width: 930px;
    `}
  `};
`;

const sidebarContainer = () => css`
  ${({ theme }) => css`
    position: relative;
    height: inherit;
    width: 100%;

    ${theme.utils.viewport.md`
      display: flex;
      justify-content: center;
    `}

    ${theme.utils.viewport.xl`
      width: max-content;
      max-width: 265px;
    `}
  `};
`;

const sidebarWrapper = () => css`
  ${({ theme }) => css`
    width: 100%;
    margin: 0;

    ${theme.utils.viewport.xl`
      margin-left: ${theme.tokens.sizes.spacings.tera};
    `}
  `};
`;

const divider = () => css`
  ${({ theme }) => css`
    display: none;

    ${theme.utils.viewport.xl` 
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${theme.tokens.sizes.spacings.byte};
      height: 100%;
      border: 0;
      border-left: 1px solid ${theme.tokens.colors.gold[200]};
    `};
  `};
`;

const mainWrapperBaseStyles = () => css`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const MainWrapper = styled.div(mainWrapperBaseStyles);
export const ChildrenWrapper = styled.div(childrenWrapper);
export const ContainerWrapper = styled.div(containerWrapper);
export const HeaderWrapper = styled.div(headerWrapper);
export const SidebarContainer = styled.div(sidebarContainer);
export const SidebarWrapper = styled.div(sidebarWrapper);
export const Divider = styled.hr(divider);
