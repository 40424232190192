import styled from "styled-components";
import { theme } from "@finvia/money-ui";

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  `;
  
  export const Block = styled.div`
  margin-top: 7px;
  min-width: 8px;
  width: 8px;
  height: 8px;
  background: ${theme.tokens.colors.petrol[600]};
  border-radius: 2px;
`;
