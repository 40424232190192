/* eslint-disable filenames/match-exported */
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import idNowLogoEnabled from "../../../../../../assets/logos/idnow-logo-black.svg";
import solarisBankLogoEnabled from "../../../../../../assets/logos/solarisbank-logo-black.svg";
import FormControl from "../../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../../components/FormContent";
import { NaturalPersonIdentificationStatus } from "../../../../../../generated/globalTypes";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import {
  getNaturalPersonIdentificationStatus,
  startIdentificationProcess,
} from "../../actions";
import DigitalIdentificationCard from "../../components/DigitalIdentificationCard";
import DigitalIdentificationFrame from "../../components/DigitalIdentificationFrame";
import {
  IdentificationForm as IdentificationFormType,
  validationSchema,
} from "./identificationFormValidations";
import { Box, Icon, Text } from "@finvia/money-ui";
import * as S from "./Identification.styled";

const translationPrefix = "q-ai.mandate.form.identification";

const Form: React.FC<StepComponentProps> = ({ nextStep, previousStep }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const [digitalIdentificationSelected, setDigitalIdentificationSelected] =
    useState<boolean>(false);
  const [digitalIdentificationURL, setDigitalIdentificationURL] = useState<
    string | undefined
  >(undefined);

  const [
    naturalPersonIdentificationStatus,
    setNaturalPersonIdentificationStatus,
  ] = useState<NaturalPersonIdentificationStatus | undefined>(undefined);

  const identificationStatusTimeout = useRef<NodeJS.Timeout | undefined>(
    undefined
  );

  const defaultData = formDefaultsDeep<IdentificationFormType>({}, {});
  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const getNaturalIdentificationStatus = useCallback(() => {
    dispatch(getNaturalPersonIdentificationStatus())
      .then(unwrapResult)
      .then((identificationStatusData) => {
        switch (identificationStatusData?.naturalPersonIdentificationStatus) {
          case NaturalPersonIdentificationStatus.IDENTIFICATION_SUCCESSFUL:
            nextStep();
            break;
          case NaturalPersonIdentificationStatus.IDENTIFICATION_FAILED:
            setNaturalPersonIdentificationStatus(
              NaturalPersonIdentificationStatus.IDENTIFICATION_FAILED
            );
            break;
          case NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL:
            setNaturalPersonIdentificationStatus(
              NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL
            );
            identificationStatusTimeout.current = setTimeout(
              getNaturalIdentificationStatus,
              5000
            );
            break;
          default:
            identificationStatusTimeout.current = setTimeout(
              getNaturalIdentificationStatus,
              5000
            );
            break;
        }
      })
      .catch((e) => {
        // NOTE: fix to Access Token expiration; probable clock difference between the API and the Load Balancer.
        // After the API call fails, the Access Token is refreshed and the next call to the API will not fail.
        identificationStatusTimeout.current = setTimeout(
          getNaturalIdentificationStatus,
          5000
        );
      });
  }, [dispatch, nextStep]);

  useEffect(() => {
    return () => {
      if (identificationStatusTimeout.current)
        clearTimeout(identificationStatusTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (digitalIdentificationSelected) {
      getNaturalIdentificationStatus();
    }
  }, [digitalIdentificationSelected, getNaturalIdentificationStatus]);

  const onDigitalIdentificationStart = useCallback(async () => {
    try {
      setDigitalIdentificationSelected(true);

      const identificationProcessData = await dispatch(
        startIdentificationProcess()
      ).then(unwrapResult);

      setDigitalIdentificationURL(identificationProcessData?.url);
    } catch (e: any) {
      setError(e);
    }
  }, [setDigitalIdentificationSelected, setDigitalIdentificationURL, dispatch]);

  const onSubmit = useCallback(async () => {
    nextStep();
  }, [nextStep]);

  return (
    <FormControl
      backLabel={t("form.back")}
      defaultValues={defaultData as Partial<IdentificationFormType>}
      onBack={previousStep}
      onSubmit={onSubmit}
      dontShowErrors
      validationSchema={memoizedSchema}
      dataAnalytics="2.2_np_identification"
    >
      {(formMethods) => {
        return digitalIdentificationSelected ? (
          <DigitalIdentificationFrame
            url={digitalIdentificationURL}
            openDialog={
              naturalPersonIdentificationStatus ===
              NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL
            }
          />
        ) : (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
          >
            <DigitalIdentificationCard
              single
              data-testid="identificationType_DIGITAL_IDENTIFICATION"
              title={t(
                `${translationPrefix}.identificationType.DIGITAL_IDENTIFICATION.title`
              )}
              description={t(
                `${translationPrefix}.identificationType.DIGITAL_IDENTIFICATION.description`
              )}
              imageNode={
                <>
                  <Box>
                    <Icon name="video-identification" size="peta" />
                  </Box>
                  <img src={idNowLogoEnabled} alt="identification-icon-idnow" />
                </>
              }
              onIdentificationStart={onDigitalIdentificationStart}
              dataAnalytics="3.3_np_start_idnow"
            >
              <S.List>
                <S.Item>
                  <Text>
                    {t(
                      `${translationPrefix}.identificationType.DIGITAL_IDENTIFICATION.downloadApp`
                    )}
                  </Text>
                </S.Item>
                <S.Item>
                  <Text>
                    {t(
                      `${translationPrefix}.identificationType.DIGITAL_IDENTIFICATION.internetConnection`
                    )}
                  </Text>
                </S.Item>
              </S.List>
            </DigitalIdentificationCard>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default Form;
