import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import {
  CreateDownloadUrl as CreateDownloadUrlResponseType,
  CreateDownloadUrlVariables,
  CreateDownloadUrl_createDownloadUrl,
} from "../generated/CreateDownloadUrl";
import {
  CreateUploadUrls as CreateUploadUrlsResponseType,
  CreateUploadUrlsVariables,
  CreateUploadUrls_createUploadUrls_documents,
} from "../generated/CreateUploadUrls";
import {
  DeleteDocument as DeleteDocumentType,
  DeleteDocumentVariables,
} from "../generated/DeleteDocument";
import {
  CreateUploadUrlInput,
  DocumentCategory,
} from "../generated/globalTypes";
import {
  CreateDownloadUrl,
  CreateUploadUrls,
  DeleteDocument,
} from "../graphql/mutations/Documents";
import { typedCreateAsyncThunk } from "../store/typedCreateAsyncThunk";

export interface DocumentChange {
  type: "delete" | "update";
  documentId: string;
  filename?: string;
  mimetype?: string;
}

export interface StoreDocument {
  documentId: string;
  filename?: string;
  mimetype?: string;
}

function getFileMetaDataForUpload(
  file: File,
  category: DocumentCategory
): CreateUploadUrlInput {
  return {
    filename: file.name,
    mimetype: file.type,
    category,
  };
}

export function uploadFiles(
  uploadUrlsWithFiles: Array<{ url: string; file: File }>,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<any>[]> {
  const fileUploadPromises = uploadUrlsWithFiles?.map(({ url, file }) => {
    return axios.put(url, file, config);
  });
  return Promise.all(fileUploadPromises);
}

// We are using typedCreateAsyncThunk even if we aren't updating the store to allow for apollo client dep injection.
export const createFileUploadUrls = typedCreateAsyncThunk(
  "documents/upload",
  async (
    { files, category }: { files?: File[]; category: DocumentCategory },
    { extra }
  ): Promise<CreateUploadUrls_createUploadUrls_documents[] | undefined> => {
    if (!files) {
      // eslint-disable-next-line no-console
      console.error("No Files Provided");
      return [];
    }
    const urlRequests = files.map((file) =>
      getFileMetaDataForUpload(file, category)
    );
    const result = await extra.client.mutate<
      CreateUploadUrlsResponseType,
      CreateUploadUrlsVariables
    >({
      mutation: CreateUploadUrls,
      variables: { files: { urlRequests } },
    });

    return result.data?.createUploadUrls.documents;
  }
);

export const getFileDownloadUrl = typedCreateAsyncThunk(
  "documents/download",
  async (
    { id }: { id: string },
    { extra }
  ): Promise<CreateDownloadUrl_createDownloadUrl | undefined> => {
    const result = await extra.client.mutate<
      CreateDownloadUrlResponseType,
      CreateDownloadUrlVariables
    >({
      mutation: CreateDownloadUrl,
      variables: { files: { documentId: id } },
    });

    return result.data?.createDownloadUrl;
  }
);

export const deleteDocument = typedCreateAsyncThunk(
  "documents/delete",
  async ({ id }: { id: string }, { extra }): Promise<void> => {
    await extra.client.mutate<DeleteDocumentType, DeleteDocumentVariables>({
      mutation: DeleteDocument,
      variables: { id: { documentId: id } },
    });
  }
);

export function download(
  url: string,
  filename: string,
  mimetype?: string
): void {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, filename, { type: mimetype } as any);
    })
    .catch((error) =>
      console.error("Error fetching or saving the file:", filename, error)
    );
}

export const getDocumentChanges = (
  files: File[],
  uploadUrls: CreateUploadUrls_createUploadUrls_documents[],
  changeType: "update" | "delete"
): DocumentChange[] => {
  if (files.length !== uploadUrls.length) {
    throw new Error("Something Went wrong with the file upload");
  }
  return files.map((file, index) => {
    const documentId = uploadUrls[index].id;
    return {
      type: changeType,
      documentId,
      filename: file.name,
      mimetype: file.type,
    };
  });
};

export const mapDocumentsToStore = (
  files: File[],
  uploadUrls: CreateUploadUrls_createUploadUrls_documents[]
): StoreDocument[] => {
  if (files.length !== uploadUrls.length) {
    throw new Error("Something Went wrong with the file upload");
  }
  return files.map((file, index) => {
    const documentId = uploadUrls[index].id;
    return {
      documentId,
      filename: file.name,
      mimetype: file.type,
    };
  });
};
