import {
  Card,
  Ribbon,
  Stack,
  Heading,
  Text,
  Icon,
  Button,
} from "@finvia/money-ui";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { QuestionnaireCardProps } from "./QuestionnaireCard.types";
import * as S from "./QuestionnaireCard.styled";
import { Colors } from "@finvia/money-ui/dist/esm/types";
import { tracking } from "utils/tracking";

const defineButtonText = ({
  hasStarted,
  isFinished,
  t,
}: {
  hasStarted: boolean;
  isFinished: boolean;
  t: TFunction;
}) => {
  const shouldStartForm = !hasStarted && !isFinished;
  const shouldResumeForm = hasStarted && !isFinished;

  if (shouldStartForm) {
    return t("form.start");
  } else if (shouldResumeForm) {
    return t("form.resume");
  } else {
    return t("form.changeDetails");
  }
};

const QuestionnaireCard: React.FC<QuestionnaireCardProps> = ({
  description,
  footerText,
  icon,
  isEnabled,
  isFinished,
  hasStarted,
  name,
  targetPath,
  testId,
  dataAnalytics,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  let iconColor = isFinished ? "gold.500" : "petrol.600";

  if (!isEnabled) {
    iconColor = "neutral.500";
  }

  return (
    <Card
      data-test-id={testId}
      data-is-enabled={isEnabled}
      data-is-finished={isFinished}
      padding={{ sm: "mega", md: "giga" }}
      borderRadius="bit"
      borderStyle="none"
    >
      <S.CardWrapper>
        {isFinished && <Ribbon>{t("form.finished")}</Ribbon>}
        <Stack gutter="giga" justifyContent="space-between">
          <Stack gutter="giga" direction="column">
            <Icon name={icon} color={iconColor as Colors} size="tera" />
            <Heading textTransform="uppercase" size={4} as="h2">
              {name}
            </Heading>
            <Text>{description}</Text>
          </Stack>
          <Stack gutter="kilo" alignItems="center">
            <Button
              stretch
              testId={testId}
              disabled={!isEnabled}
              variant={isFinished ? "success" : "primary"}
              onClick={() => {
                history.push(targetPath);

                tracking.triggerEvent(dataAnalytics);
              }}
              dataAttributes={{
                ...(dataAnalytics && {
                  "data-analytics": dataAnalytics,
                }),
              }}
            >
              {defineButtonText({ hasStarted, isFinished, t })}
            </Button>
            <Text align="center">{footerText}</Text>
          </Stack>
        </Stack>
      </S.CardWrapper>
    </Card>
  );
};

export default QuestionnaireCard;
