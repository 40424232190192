import { useTranslation } from "react-i18next";
import { Text, Icon, Stack } from "@finvia/money-ui";
import { ErrorAlertProps } from "./ErrorAlert.types";

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  customErrorMessage,
  fullPage,
}) => {
  const { t } = useTranslation();
  const stackGutter = fullPage ? "tera" : "kilo";
  const iconColor = fullPage ? "white.500" : "red.500";
  const iconSize = fullPage ? "peta" : "mega";
  const textVariant = fullPage ? undefined : "error";
  const textSize = fullPage ? 3 : 2;

  return (
    <Stack alignItems="center" gutter={stackGutter}>
      <Icon name="error" color={iconColor} size={iconSize} />
      <Text align="center" variant={textVariant} size={textSize}>
        {customErrorMessage || t("form.genericErrorMessage")}
      </Text>
    </Stack>
  );
};

export default ErrorAlert;
