import { i18n } from "i18next";
import { Option } from "@finvia/money-ui";

export type BuildOptionsParams = {
  lang: i18n;
  key: string;
};

export const buildOptions = <T>({
  lang,
  key,
}: BuildOptionsParams): Option[] => {
  if (lang.exists(key) === false) {
    throw new Error(
      `@Helpers/buildOptions: translation ${key} key does not exists`
    );
  }

  const getValues: Record<keyof T, string> = lang.t(key, {
    returnObjects: true,
  });

  return Object.keys(getValues).map<Option>((value) => ({
    value,
    text: getValues[value as keyof T],
  }));
};
