import { Card, Stepper } from "@finvia/money-ui";
import { useRouteMatch } from "react-router-dom";
import { QuestionnaireLayoutProps } from "./QuestionnaireLayout.types";
import * as S from "./QuestionnaireLayout.styled";

const QuestionnaireLayout: React.FC<QuestionnaireLayoutProps> = ({
  children,
  onSelectStepGroup,
  stepGroups,
  disableLayoutOnSteps,
  hideStepperOnSteps,
}) => {
  const { params } = useRouteMatch<{ id: string }>();
  const stepId = Number(params.id);
  const disableLayout = disableLayoutOnSteps?.includes(stepId);
  const disableStepper = hideStepperOnSteps?.includes(stepId);

  const groupOfActiveStep = stepGroups.findIndex(
    (group) => !!group.steps.find(({ id }) => stepId === id) || group.selected
  );

  const stepperProps = {
    activeStep: groupOfActiveStep + 1,
    onClick: (stepGroupIndex: number) =>
      onSelectStepGroup && onSelectStepGroup(stepGroupIndex - 1),
    steps: stepGroups.map(({ label }) => label),
  };

  return (
    <>
      {!disableStepper && (
        <S.StepperWrapper>
          <Stepper {...stepperProps} />
        </S.StepperWrapper>
      )}
      {disableLayout ? (
        children
      ) : (
        <Card padding={{ sm: "tera", md: "peta" }} borderRadius="bit">
          {children}
        </Card>
      )}
    </>
  );
};

export default QuestionnaireLayout;
