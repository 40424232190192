import { Table } from "@finvia/money-ui";
import { getHeaders, getRows } from "./utils";
import { customTableStyles } from "./IdentificationTable.styled";
import { TFunction } from "react-i18next";
import { ReactElement } from "react";
import { PersonRecord } from "./IdentificationStatusTableCell";

const IdentificationTable = ({
  tableData,
  t,
  nextStep,
}: {
  tableData: PersonRecord[];
  t: TFunction;
  nextStep: () => void;
}): ReactElement => {
  return (
    <Table
      headers={getHeaders(t)}
      rows={getRows({ data: tableData, t, nextStep })}
      customStyles={customTableStyles}
    />
  );
};

export default IdentificationTable;
