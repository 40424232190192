import { TFunction } from "i18next";
import * as yup from "yup";
import {
  AccumulatedIdentificationStatus,
  LegalEntityIdentificationStatus,
} from "../../../../../../generated/globalTypes";
import {
  documentSchema,
  optionalDocumentSchema,
} from "../../../../../../genericValidations/DocumentValidations";

const translationPrefix = "q-ai.opportunities.form.legalEntityDocumentsUpload";

const generateSchema = (
  t: TFunction,
  isMissing: boolean,
  fieldName: string
) => {
  return yup.object({
    isMissing: yup.boolean(),
    documents: isMissing
      ? documentSchema({
          errorMessage: t(`${translationPrefix}.fields.${fieldName}.required`),
        })
      : optionalDocumentSchema(),
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction, documents: any) =>
  yup.object({
    legalEntityIdentificationStatus: yup.object({
      failedIdentificationMessage: yup.string(),
      accumulatedSolarisIdentStatus: yup
        .mixed<AccumulatedIdentificationStatus>()
        .when("legalIdentificationStatus", {
          is: (legalIdentificationStatus: LegalEntityIdentificationStatus) =>
            legalIdentificationStatus ===
            LegalEntityIdentificationStatus.INFORMATION_REQUIRED,

          then: yup
            .mixed<AccumulatedIdentificationStatus>()
            .oneOf([
              AccumulatedIdentificationStatus.CREATED,
              AccumulatedIdentificationStatus.PENDING,
            ]),
          otherwise: yup
            .mixed<AccumulatedIdentificationStatus>()
            .oneOf([AccumulatedIdentificationStatus.SUCCESSFUL]),
        }),
      legalIdentificationStatus: yup
        .mixed<LegalEntityIdentificationStatus>()
        .oneOf([
          LegalEntityIdentificationStatus.IDENTIFICATION_SUCCESSFUL,
          LegalEntityIdentificationStatus.INFORMATION_REQUIRED,
          null,
        ]),
      currentTradeRegister: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(
          t,
          documents.currentTradeRegister?.isMissing,
          "currentTradeRegister"
        ),
      }),
      shareholdersListNonAg: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(
          t,
          documents.shareholdersListNonAg?.isMissing,
          "shareholdersListNonAg"
        ),
      }),
      shareholdersListAg: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(
          t,
          documents.shareholdersListAg?.isMissing,
          "shareholdersListAg"
        ),
      }),
      furtherShareholders: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(
          t,
          documents.furtherShareholders?.isMissing,
          "furtherShareholders"
        ),
      }),
      shareholdersContract: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(
          t,
          documents.shareholdersContract?.isMissing,
          "shareholdersContract"
        ),
      }),
      incorperation: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(
          t,
          documents.incorperation?.isMissing,
          "incorperation"
        ),
      }),
      others: yup.mixed().when("accumulatedSolarisIdentStatus", {
        is: (accumulatedSolarisIdentStatus: AccumulatedIdentificationStatus) =>
          accumulatedSolarisIdentStatus ===
          AccumulatedIdentificationStatus.SUCCESSFUL,
        then: yup.object({
          isMissing: yup.boolean(),
          documents: optionalDocumentSchema(),
        }),
        otherwise: generateSchema(t, documents.others?.isMissing, "others"),
      }),
    }),
  });

export type IdentificationForm = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export interface IdentificationStatusForm {
  legalEntityIdentificationStatus?: IdentificationForm["legalEntityIdentificationStatus"];
}
