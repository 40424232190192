import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import FormControl from "../../../../components/FormControl/FormControl";
import LoadingWrapper from "../../../../components/LoadingWrapper/LoadingWrapper";
import { FormContent } from "../../../../components/FormContent";
import {
  ContractType,
  FundInvestmentEducationAndProfessionalStatus,
  FundInvestmentExperienceAndKnowledge,
  InvestorType as InvestorTypeEnum,
} from "../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../store";
import formDefaultsDeep from "../../../../utils/formDefaultsDeep";
import { FundAmountHeader } from "../../components/FundDetails/FundDetailsHeaders/FundAmountHeader";
import {
  getAIFundContract,
  getFundBySlug,
  upsertAlternativeInvestmentsFundContract,
} from "../actions";
import FundDetailsHeader from "../components/FundDetailsHeader/FundDetailsHeader";
import { fundBySlug } from "../slice";
import { ROUTES } from "../../../../routes/routes";
import {
  FundDeclarationsForm,
  validationSchema,
} from "./fundDeclarationsValidations";
import { Card } from "@finvia/money-ui";

import { NotFound } from "./components/NotFound";
import { InvestorType } from "./components/InvestorType";
import { FundDetailsCard } from "../../components/FundDetails/FundDetails.styled";

const translationPrefix = "q-ai.opportunities.form.fundDeclarations";

const FundDeclarations: React.FC = () => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { slug } = useParams<{ slug: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLegalEntity = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData.personData.contractType ===
      ContractType.LEGAL_ENTITY
  );
  const memoizedSchema = useMemo(
    () => validationSchema(t, isLegalEntity),
    [t, isLegalEntity]
  );

  useEffect(() => {
    dispatch(getFundBySlug({ slug }));
    dispatch(getAIFundContract({ slug }));
  }, [dispatch, slug]);

  useEffect(() => {
    if ((history.location?.state as any)?.scrollToTop) {
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [history.location.state]);

  const previousStep = useCallback(() => {
    history.push(
      generatePath(ROUTES.funds.details.bankAccountSelection, { slug: slug }),
      {
        scrollToTop: true,
      }
    );
  }, [history, slug]);

  const nextStep = useCallback(() => {
    history.push(generatePath(ROUTES.funds.details.signing, { slug: slug }), {
      scrollToTop: true,
    });
  }, [history, slug]);

  const fundDetails = typedUseSelector((state) => {
    return fundBySlug(
      state.alternativeInvestments.alternativeInvestmentFunds.funds,
      slug
    );
  });

  const fundAttributes = fundDetails?.attributes;

  const { defaultData, signingAmount } = typedUseSelector((state) => {
    const { declarations } =
      state.alternativeInvestments.alternativeInvestmentFunds.data;

    return {
      defaultData: formDefaultsDeep<FundDeclarationsForm>(
        { declarations },
        {
          declarations: {
            investorType: InvestorTypeEnum.SEMI_PROFESSIONAL,
            semiProfessionalInvestor: {
              isSemiProfessionalInvestor: false,
              isSufficientExperienceAndKnowledgeConfirmed: false,
              experienceAndKnowledgeType:
                FundInvestmentExperienceAndKnowledge.DEFAULT,
              experienceAndKnowledgeAppendix: "",
              isEducationAndProfessionalStatusConfirmed: false,
              educationAndProfessionalStatusType:
                FundInvestmentEducationAndProfessionalStatus.DEFAULT,
              educationAndProfessionalStatusAppendix: "",
              isAlignedRiskProfileConfirmed: false,
              isAlignedFinancialSituationConfirmed: false,
              isSufficientIncomeConfirmed: false,
              isSufficientFinancialAssetsConfirmed: false,
            },
            professionalInvestor: {
              isProfessionalInvestor: false,
              isSufficientExperienceAndKnowledgeConfirmed: false,
              isRelevantFinancialPortfolioConfirmed: false,
              isRelevantMarketExperienceConfirmed: false,
              isRelevantMarketJobConfirmed: false,
              professionalInvestorClassification: {
                isConfirmed: false,
                financialInstruments: {
                  isConfirmed: false,
                  otherInstitutionalInvestor: false,
                },
                underSupervision: {
                  isConfirmed: false,
                  creditInstitution: false,
                  investingFirm: false,
                  financialInstitution: false,
                  insuranceCompany: false,
                  collectiveInvestmentOrganism: false,
                  pensionFund: false,
                  merchants: false,
                  otherInstitutionalInvestor: false,
                },
                governmentFunction: {
                  isConfirmed: false,
                  companyAsGovernment: false,
                },
                securizationOfLiabilities: {
                  isConfirmed: false,
                  companyAsSecurizationOfLiabilities: false,
                },
                largeCompany: {
                  isConfirmed: false,
                  balanceSheetTotal: false,
                  netSales: false,
                  ownFunds: false,
                },
                extensiveExperience: {
                  isConfirmed: false,
                  tenTransactionsInTheLastFourQuarters: false,
                  tenTransactionsInTheLastFourQuartersExample: undefined,
                  moreThan500k: false,
                  jobInCapitalMarket: false,
                  jobInCapitalMarketDetails: undefined,
                },
              },
            },
          },
        }
      ),
      signingAmount:
        state.alternativeInvestments.alternativeInvestmentFunds.data
          .signingAmount,
    };
  });

  const loading = useSelector((state) => {
    return state.alternativeInvestments.alternativeInvestmentFunds.isLoading;
  });

  const onSubmit = useCallback(
    async (data: FundDeclarationsForm) => {
      dispatch(
        upsertAlternativeInvestmentsFundContract({ ...data, slug: slug })
      )
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep, slug]
  );

  if (!defaultData || !fundAttributes) {
    return <NotFound />;
  }

  return (
    <LoadingWrapper
      title={t("loadingScreen.title")}
      message={t("loadingScreen.message")}
      isLoading={loading}
    >
      <FundDetailsCard>
        <FundDetailsHeader
          fundName={fundAttributes?.name ?? ""}
          fundAssetClass={fundAttributes?.assetClass}
          fundDescription={fundAttributes?.shortDescription}
          fundManagerImageUrl={fundAttributes?.fundManagerImage || undefined}
        >
          <FundAmountHeader
            fundAmount={signingAmount || 0}
            fundCurrency={fundAttributes?.currency}
          />
        </FundDetailsHeader>

        <Card
          paddingTopBottom={{ sm: "giga", md: "peta" }}
          paddingLeftRight={{ sm: "kilo", md: "peta" }}
          borderStyle="none"
        >
          <FormControl<FundDeclarationsForm>
            onBack={previousStep}
            dontShowErrors
            defaultValues={defaultData}
            onSubmit={onSubmit}
            validationMode="onChange"
            reValidationMode="onChange"
            validationSchema={memoizedSchema}
            submitLabel={t("onboarding.form.continue")}
            backLabel={t("onboarding.form.back")}
            dataAnalytics="4.3_fund_declarations_investor_type"
          >
            {(formMethods) => {
              return (
                <FormContent
                  title={t(`${translationPrefix}.title`)}
                  error={error}
                  subTitle={t(`${translationPrefix}.description`)}
                  isDirty={formMethods.formState.isDirty}
                  isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
                >
                  <InvestorType />
                </FormContent>
              );
            }}
          </FormControl>
        </Card>
      </FundDetailsCard>
    </LoadingWrapper>
  );
};

export default FundDeclarations;
