import { gql } from "@apollo/client";

const User = gql`
  query User {
    profile {
      id
      account {
        id
        email
        groups
      }
    }
  }
`;

export default User;
