import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingDialogProps } from "./loadingDialog.types";
import { Box, Icon, Modal, Stack, Text } from "@finvia/money-ui";

const LoadingDialog: React.FC<LoadingDialogProps> = ({ openDialog }) => {
  const { t } = useTranslation();

  return (
    <Modal
      showCloseIcon={false}
      disableBackdropClose
      id="identificationModal"
      isOpen={openDialog}
    >
      <Box width="100%" maxWidth="250px">
        <Stack gutter="giga" alignItems="center" justifyContent="center">
          <Icon name="time" size="peta" />
          <Text>
            {t(
              "q-ai.mandate.form.identification.identificationType.DIGITAL_IDENTIFICATION.frame.dialog.text"
            )}
          </Text>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LoadingDialog;
