import { StyleProps } from "@finvia/money-ui/dist/esm/types";
import styled, { css } from "styled-components";

const unorderedListStyles = ({ theme }: StyleProps) => css`
  color: ${theme.tokens.colors.petrol[600]};
  list-style-type: square;
  list-style-position: "outside";
  margin: ${theme.tokens.sizes.spacings.byte} 0px 0px
    ${theme.tokens.sizes.spacings.giga};
  padding: 0;
  text-align: left;
`;

const listItemStyles = ({ theme }: StyleProps) => css`
  margin-bottom: ${theme.tokens.sizes.spacings.byte};
`;

export const UnorderedList = styled.ul<StyleProps>(unorderedListStyles);
export const ListItem = styled.li<StyleProps>(listItemStyles);
