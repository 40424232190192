import type { UseFormMethods } from "react-hook-form";
import { DocumentChange } from "models/documents";
import { Document } from "features/alternative-investments/components/UploadField";

export enum LegalEntityDocumentCategory {
  LEGAL_ENTITY_CURRENT_TRADE_REGISTER = "currentTradeRegister",
  LEGAL_ENTITY_SHAREHOLDERS_LIST_NON_AG = "shareholdersListNonAg",
  LEGAL_ENTITY_SHAREHOLDERS_LIST_AG = "shareholdersListAg",
  LEGAL_ENTITY_FURTHER_SHAREHOLDERS = "furtherShareholders",
  LEGAL_ENTITY_SHAREHOLDERS_CONTRACT = "shareholdersContract",
  LEGAL_ENTITY_INCORPERATION = "incorperation",
  LEGAL_ENTITY_OTHERS = "others",
}

export type DocumentCategoryNames =
  | "currentTradeRegister"
  | "shareholdersListNonAg"
  | "shareholdersListAg"
  | "furtherShareholders"
  | "shareholdersContract"
  | "incorperation"
  | "others";

export interface DocumentCategory {
  isMissing?: boolean | null | undefined;
  documents?: Document[] | undefined | null;
}

export type DocumentsUpload = Partial<
  Record<DocumentCategoryNames, DocumentCategory | null>
>;

export type DocumentUploadStore = {
  documents?: DocumentsUpload | undefined | null;
};

export type HandleDocumentDelete = (
  document: Document,
  documents: Document[],
  category: DocumentCategoryNames,
  name: string,
  setValueReference: UseFormMethods["setValue"]
) => void;

export type HandleDocumentUpload = (
  documents: Document[],
  file: FileList | null,
  category: DocumentCategoryNames,
  name: string,
  setValueReference: UseFormMethods["setValue"]
) => void;

export type Payload = {
  changedDocuments: DocumentChange[];
  documentCategory: DocumentCategoryNames;
};
