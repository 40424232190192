import styled, { css } from "styled-components";

type WrapperStyleProps = { columns: number };

const wrapperStyles = ({ columns }: WrapperStyleProps) => css`
  width: 100%;

  ${({ theme }) => theme.utils.viewport.md`
    width: calc((100% - ${theme.tokens.sizes.spacings.giga} * ${
    columns - 1
  }) / ${columns});
  `};
`;

export const ButtonWrapper = styled.div<WrapperStyleProps>(wrapperStyles);
