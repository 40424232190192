import { isAfter, parse } from "date-fns";
import { TFunction } from "i18next";
import { dateFormat } from "utils/helpers";
import * as yup from "yup";
import countries from "../../../../../../data/countries";
import {
  LegalEntityFunctionaryRole,
  ProfessionalTitle,
  Salutation,
  WealthOrigin as WealthOriginType,
} from "../../../../../../generated/globalTypes";
import { baseRequiredValidation } from "../../../../../../genericValidations/DocumentValidations";
import address from "../../common/GenericValidations/address";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const contactData = (t: TFunction) =>
  yup.object({
    emailAddress: yup
      .string()
      .email()
      .label(t(`${translationPrefix}.fields.contactData.emailAddress.label`))
      .required(
        t(`${translationPrefix}.fields.contactData.emailAddress.required`)
      ),
    phoneNumber: yup
      .string()
      .test(
        "more-than-10",
        t(`${translationPrefix}.fields.contactData.phoneNumber.validation`),
        (val) => {
          return val ? val.length > 9 : false;
        }
      )
      .required(
        t(`${translationPrefix}.fields.contactData.phoneNumber.validation`)
      )
      .label(t(`${translationPrefix}.fields.contactData.phoneNumber.label`)),
    legalAddress: address(t),
  });

const personalData = (t: TFunction) =>
  yup.object({
    salutation: yup
      .mixed<Salutation>()
      .required(
        t(`${translationPrefix}.fields.personalData.salutation.required`)
      )
      .oneOf(
        Object.values(Salutation),
        t(`${translationPrefix}.fields.personalData.salutation.required`)
      )
      .label(t(`${translationPrefix}.fields.personalData.salutation.label`)),
    professionalTitle: yup
      .mixed<ProfessionalTitle>()
      .oneOf([null, ...Object.values(ProfessionalTitle)])
      .transform((s) => (s === "" ? null : s)),
    firstName: yup
      .string()
      .required(
        t(`${translationPrefix}.fields.personalData.firstName.required`)
      )
      .label(t(`${translationPrefix}.fields.personalData.firstName.label`)),
    familyName: yup
      .string()
      .required(
        t(`${translationPrefix}.fields.personalData.familyName.required`)
      )
      .label(t(`${translationPrefix}.fields.personalData.familyName.label`)),
    birthName: yup
      .string()
      .label(t(`${translationPrefix}.fields.personalData.birthName.label`)),
    dateOfBirth: yup
      .string()
      .required(
        t(`${translationPrefix}.fields.personalData.dateOfBirth.required`)
      )
      .test(
        "is-valid-birthdate",
        t(`${translationPrefix}.fields.personalData.dateOfBirth.required`),
        (value) => {
          // True so it can delegate to required()
          if (!value) return true;
          try {
            const date = parse(value, "d.M.y", new Date());
            const OLDEST_BIRTH_DATE = new Date(1900, 0, 1);
            const matchDateFormat = value.match(dateFormat) !== null;

            return isAfter(date, OLDEST_BIRTH_DATE) && matchDateFormat;
          } catch (_e) {
            return false;
          }
        }
      )
      .label(t(`${translationPrefix}.fields.personalData.dateOfBirth.label`)),
    cityOfBirth: yup
      .string()
      .required(
        t(`${translationPrefix}.fields.personalData.cityOfBirth.required`)
      )
      .label(t(`${translationPrefix}.fields.personalData.cityOfBirth.label`)),
    countryOfBirth: yup
      .string()
      .oneOf(countries)
      .required(
        t(`${translationPrefix}.fields.personalData.countryOfBirth.required`)
      )
      .label(
        t(`${translationPrefix}.fields.personalData.countryOfBirth.label`)
      ),
  });

const citizenship = (t: TFunction) =>
  yup.object({
    primaryCitizenship: yup
      .string()
      .oneOf(countries)
      .required(
        t(`${translationPrefix}.fields.citizenship.primaryCitizenship.required`)
      )
      .label(
        t(`${translationPrefix}.fields.citizenship.primaryCitizenship.label`)
      ),
    additionalCitizenships: yup.array(
      yup
        .object({
          value: yup
            .string()
            .oneOf(
              countries,
              t(
                `${translationPrefix}.fields.citizenship.additionalCitizenships.required`
              )
            )
            .label(
              t(
                `${translationPrefix}.fields.citizenship.additionalCitizenships.label`
              )
            )
            .required(),
        })
        .label(
          t(
            `${translationPrefix}.fields.citizenship.additionalCitizenships.label`
          )
        )
        .required()
    ),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const taxInfo = (t: TFunction) =>
  yup
    .array(
      yup.object({
        country: yup
          .string()
          .oneOf(
            countries,
            t(`${translationPrefix}.fields.taxInfo.country.required`)
          )
          .required()
          .label(t(`${translationPrefix}.fields.taxInfo.country.label`)),
        taxId: yup
          .string()
          .when("country", {
            is: (country: string) => country === "DE",
            then: yup
              .string()
              .matches(
                /(^[0-9]{11}$|^$)/,
                t(`${translationPrefix}.fields.taxInfo.taxId.format`)
              ),
          })
          .required(t(`${translationPrefix}.fields.taxInfo.taxId.required`))
          .label(t(`${translationPrefix}.fields.taxInfo.taxId.label`)),
      })
    )
    .min(1)
    .max(3);

const role = (t: TFunction) =>
  yup
    .mixed<LegalEntityFunctionaryRole>()
    .required(t(`${translationPrefix}.fields.role.required`))
    .oneOf(
      Object.values(LegalEntityFunctionaryRole),
      t(`${translationPrefix}.fields.role.required`)
    )
    .label(t(`${translationPrefix}.fields.role.label`));

const isLegalRepresentative = (t: TFunction) =>
  yup
    .string()
    .required(t(`${translationPrefix}.fields.isLegalRepresentative.required`));

const beneficialOwner = (t: TFunction, totalCompanyEquityPercentage: string) =>
  yup.object({
    isBeneficialOwner: yup
      .string()
      .required(t(`${translationPrefix}.fields.beneficialOwner.required`))
      .label(t(`${translationPrefix}.fields.beneficialOwner.valueLabel`)),
    companyEquityPercentage: yup.string().when("isBeneficialOwner", {
      is: "yes",
      then: yup
        .string()
        .required(
          t(
            `${translationPrefix}.fields.beneficialOwner.companyEquityPercentage.required`
          )
        )
        .test("companyEquityPercentage", (value, testContext) => {
          if (!value) return false;

          const number = parseFloat(value.split(" ")[0]);
          if (
            number + parseFloat(totalCompanyEquityPercentage.split(" ")[0]) >
            100
          ) {
            return testContext.createError({
              message: t(
                `${translationPrefix}.fields.beneficialOwner.companyEquityPercentage.maximumPct`
              ),
              type: "companyEquityPercentage",
              path: "beneficialOwner.companyEquityPercentage",
            });
          }

          if (number < 25) {
            return testContext.createError({
              message: t(
                `${translationPrefix}.fields.beneficialOwner.companyEquityPercentage.minimumPctRequired`
              ),
              type: "companyEquityPercentage",
              path: "beneficialOwner.companyEquityPercentage",
            });
          }

          return true;
        }),
    }),
  });

const IdentitySchema = (t: TFunction) =>
  yup.object({
    number: yup
      .string()
      .required(
        t(`${translationPrefix}.fields.beneficialOwner.identityNumber.required`)
      )
      .label(
        t(`${translationPrefix}.fields.beneficialOwner.identityNumber.label`)
      ),
    expirationDate: yup
      .string()
      .required(
        t(
          `${translationPrefix}.fields.beneficialOwner.identityExpirationDate.required`
        )
      )
      .test(
        "is-valid-identity-expiration-date",
        t(
          `${translationPrefix}.fields.beneficialOwner.identityExpirationDate.noPastDate`
        ),
        (value) => {
          // True so it can delegate to required()
          if (!value) return true;
          try {
            const date = parse(value, "d.M.y", new Date());
            const TODAY = new Date();
            const matchDateFormat = value.match(dateFormat) !== null;

            return isAfter(date, TODAY) && matchDateFormat;
          } catch (_e) {
            return false;
          }
        }
      )
      .label(
        t(
          `${translationPrefix}.fields.beneficialOwner.identityExpirationDate.label`
        )
      ),
    documents: yup
      .array(baseRequiredValidation())
      .min(
        1,
        t(
          `${translationPrefix}.fields.beneficialOwner.identityDocument.required`
        )
      )
      .max(
        3,
        t(
          `${translationPrefix}.fields.beneficialOwner.identityDocument.required`
        )
      )
      .required(
        t(
          `${translationPrefix}.fields.beneficialOwner.identityDocument.required`
        )
      ),
  });

const notRequiredIdentitySchema = (t: TFunction) =>
  yup.object({
    number: yup
      .string()
      .label(
        t(`${translationPrefix}.fields.beneficialOwner.identityNumber.label`)
      ),
    expirationDate: yup
      .string()
      .label(
        t(
          `${translationPrefix}.fields.beneficialOwner.identityExpirationDate.label`
        )
      ),
    documents: yup.array(),
  });

const identity = (t: TFunction, notRequired?: boolean) =>
  notRequired ? notRequiredIdentitySchema(t) : IdentitySchema(t);

const wealthOriginValidationSchemaRequired = (t: TFunction) => {
  return yup
    .mixed<WealthOriginType>()
    .required(t(`${translationPrefix}.fields.wealthOrigin.required`))
    .oneOf(
      Object.values(WealthOriginType),
      t(`${translationPrefix}.fields.wealthOrigin.required`)
    )
    .label(t(`${translationPrefix}.fields.wealthOrigin.label`));
};

const beneficialOwnerTest = (
  t: TFunction,
  isBeneficialOwner: string | undefined,
  testContext: yup.TestContext
) => {
  if (
    isBeneficialOwner === "no" &&
    testContext.parent?.isLegalRepresentative === "no"
  ) {
    return testContext.createError({
      message: t(
        `${translationPrefix}.fields.beneficialOwner.addedPersonIsNeitherLRNorBO`
      ),
      path: "beneficialOwner.isBeneficialOwner",
      type: "beneficialOwner.isBeneficialOwner",
    });
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (
  t: TFunction,
  totalCompanyEquityPercentage: string
) =>
  yup.object({
    personalData: personalData(t),
    contactData: contactData(t),
    citizenship: citizenship(t),
    taxInfo: taxInfo(t),
    role: role(t),
    isLegalRepresentative: isLegalRepresentative(t),
    beneficialOwner: beneficialOwner(t, totalCompanyEquityPercentage).test(
      "isBeneficialOwner",
      (value, testContext) => {
        return beneficialOwnerTest(t, value.isBeneficialOwner, testContext);
      }
    ),
    wealthOrigin: yup.mixed<WealthOriginType>().notRequired(),
    identityDocument: identity(t, true),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchemaBeneficialOwner = (
  t: TFunction,
  totalCompanyEquityPercentage: string
) =>
  yup.object({
    personalData: personalData(t),
    contactData: contactData(t),
    citizenship: citizenship(t),
    taxInfo: taxInfo(t),
    role: role(t),
    isLegalRepresentative: isLegalRepresentative(t),
    beneficialOwner: beneficialOwner(t, totalCompanyEquityPercentage).test(
      "isBeneficialOwner",
      (value, testContext) => {
        return beneficialOwnerTest(t, value.isBeneficialOwner, testContext);
      }
    ),
    wealthOrigin: wealthOriginValidationSchemaRequired(t),
    identityDocument: identity(t, true).when(["isLegalRepresentative"], {
      is: "no",
      then: identity(t),
      otherwise: identity(t, true),
    }),
  });
