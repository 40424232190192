import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const container = () =>
  css`
    position: relative;
    overflow: initial;
    min-height: 350px;
    ${({ theme: { utils } }) => utils.viewport.md`
      min-height: 500px;
    `};
  `;

const link = () =>
  css`
    color: inherit;
    text-decoration: none;
  `;

const dialog = () =>
  css`
    display: grid;
    width: 100%;
    max-width: 495px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: ${({ theme: { tokens } }) => tokens.sizes.spacings.tera};
    justify-items: start;
    padding: ${({ theme: { tokens } }) => tokens.sizes.spacings.mega};
    background: white;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
      0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    ${({ theme: { utils, tokens } }) => utils.viewport.md`
        padding: ${tokens.sizes.spacings.tera};     
        width: 495px;
    `};
  `;

export const Container = styled.div(container);
export const LinkTo = styled(Link)(link);
export const Dialog = styled.div(dialog);
