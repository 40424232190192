import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import numeral from "numeral";
import { initReactI18next } from "react-i18next";
import translationsDE from "../translations/de-DE.json";
import translationsDE_countries from "../translations/de-DE_countries.json";
import deDE, { LANGUAGE_KEY as DE_DE_KEY } from "./deDE";

export default function config(): Promise<typeof i18n> {
  const fallbackLanguage = DE_DE_KEY.split("-")[0];
  const defaultLocale = DE_DE_KEY;
  const availableLanguages = {
    [DE_DE_KEY]: deDE,
  };
  const resources = {
    de: {
      translation: translationsDE,
      countries: translationsDE_countries,
    },
  };

  Object.values(availableLanguages).map((language) =>
    language.apply(undefined, [moment, numeral])
  );

  moment.locale(defaultLocale);
  numeral.locale(defaultLocale);

  i18n.on("languageChanged", function changeLanguage(lng) {
    moment.locale(lng);
    // Not all languages strings are supported and currently this will crash the application if lng is not recognized
    // numeral.locale(lng);
  });

  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      parseMissingKeyHandler: () => null,
      resources,
      fallbackLng: fallbackLanguage,
      interpolation: {
        format: (
          value: any,
          format: string | undefined,
          _lng: string | undefined
        ) => {
          // $ in the format string means insert currency symbol here.
          if (format === "currency") {
            if (value >= 1000000) {
              return numeral(value).format("(0,00.[00] a $)");
            }
            return numeral(value).format("0,0[.]00 $");
          }
          // to avoid over complicating the formatting we append the $ sign here.
          if (format === "currencyUSD") {
            if (value >= 1000000) {
              return `${numeral(value).format("(0,00.[00] a )")} $`;
            }
            return `${numeral(value).format("0,0[.]00")} $`;
          }
          if (format === "years") {
            if (Number(value) === 1) {
              return `${value} ${resources.de.translation.time.year}`;
            }
            return `${value} ${resources.de.translation.time.years}`;
          }
          if (value instanceof Date) return moment(value).format(format);
          return value;
        },
      },
    })
    .then(() => i18n);
}
