import { validationSchemaExternalLegalStructures } from "./validations/externalStructure";
import { validationSchemaFinviaLegalStructure } from "./validations/finviaStructure";
import { validationSchemaLuxembourgLegalStructure } from "./validations/luxembourgStructure";

const generateNTerms = (
  t: (translation: string) => string,
  translation: string,
  number: number
) =>
  [...Array(number)].map((_, index) =>
    t(`${translation}.terms.term${index + 1}`)
  );

interface LegalStructuresData {
  defaultData?: Record<string, any>;
  translationPrefix: string;
  checkTranslationPrefix: string;
  t: (translation: string) => string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildLegalStructuresData = ({
  defaultData,
  translationPrefix,
  checkTranslationPrefix,
  t,
}: LegalStructuresData) => {
  return {
    finvia_luxembourg: {
      validation: validationSchemaLuxembourgLegalStructure,
      checkboxes: [
        {
          name: "confirmsAcquisitionOfShareholding",
          defaultValue: defaultData?.confirmsAcquisitionOfShareholding,
          translationPrefix: `${translationPrefix}.luxembourg.acquisitionOfShareholding`,
        },
        {
          name: "confirmsRiskOfAcquisition",
          defaultValue: defaultData?.confirmsAcquisitionOfShareholding,
          translationPrefix: `${translationPrefix}.luxembourg.riskOfAcquisition`,
          terms: generateNTerms(
            t,
            `${translationPrefix}.luxembourg.riskOfAcquisition`,
            7
          ),
        },
        {
          name: "confirmsStatementsAndDisclosures",
          defaultValue: defaultData?.confirmsStatementsAndDisclosures,
          translationPrefix: `${translationPrefix}.luxembourg.statementsAndDisclosures`,
          terms: generateNTerms(
            t,
            `${translationPrefix}.luxembourg.statementsAndDisclosures`,
            10
          ),
        },
        {
          name: "confirmsDataProtection",
          defaultValue: defaultData?.confirmsDataProtection,
          translationPrefix: `${translationPrefix}.luxembourg.dataProtection`,
        },
        {
          name: "confirmsActingOnOwnBehalf",
          defaultValue: defaultData?.confirmsActingOnOwnBehalf,
          translationPrefix: `${translationPrefix}.luxembourg.actingOnOwnBehalf`,
        },
      ],
    },
    external: {
      validation: validationSchemaExternalLegalStructures,
      checkboxes: [
        {
          name: "confirmsAcquisitionOfShareholding",
          defaultValue: defaultData?.confirmsAcquisitionOfShareholding,
          translationPrefix: `${checkTranslationPrefix}.acquisitionOfShareholding`,
          terms: generateNTerms(
            t,
            `${checkTranslationPrefix}.acquisitionOfShareholding`,
            6
          ),
        },
        {
          name: "confirmsRiskOfAcquisition",
          defaultValue: defaultData?.confirmsRiskOfAcquisition,
          translationPrefix: `${checkTranslationPrefix}.riskOfAcquisition`,
          terms: generateNTerms(
            t,
            `${checkTranslationPrefix}.riskOfAcquisition`,
            7
          ),
        },
        {
          name: "confirmsTrustAndAdministrationContract",
          defaultValue: defaultData?.confirmsTrustAndAdministrationContract,
          translationPrefix: `${checkTranslationPrefix}.trustAndAdministrationContract`,
        },
      ],
    },
    finvia: {
      validation: validationSchemaFinviaLegalStructure,
      checkboxes: [
        {
          name: "confirmsAcquisitionOfShareholding",
          defaultValue: defaultData?.confirmsAcquisitionOfShareholding,
          translationPrefix: `${checkTranslationPrefix}.acquisitionOfShareholding`,
          terms: generateNTerms(
            t,
            `${checkTranslationPrefix}.acquisitionOfShareholding`,
            6
          ),
        },
        {
          name: "confirmsRiskOfAcquisition",
          defaultValue: defaultData?.confirmsAcquisitionOfShareholding,
          translationPrefix: `${checkTranslationPrefix}.riskOfAcquisition`,
          terms: generateNTerms(
            t,
            `${checkTranslationPrefix}.riskOfAcquisition`,
            7
          ),
        },
        {
          name: "confirmsTrustAndAdministrationContract",
          defaultValue: defaultData?.confirmsTrustAndAdministrationContract,
          translationPrefix: `${checkTranslationPrefix}.trustAndAdministrationContract`,
        },
        {
          name: "confirmsActingOnOwnBehalf",
          defaultValue: defaultData?.confirmsActingOnOwnBehalf,
          translationPrefix: `${translationPrefix}.common.actingOnOwnBehalf`,
        },
      ],
    },
  };
};
