import { gql } from "@apollo/client";
import { Document } from "../fragments/Fragments";

export const NaturalPersonMandateContractQuery = gql`
  query NaturalPersonMandateContractQuery {
    naturalPersonIdentificationStatus
    profile {
      contracts {
        aiMandate {
          hasConfirmedContractSigning
          hasConfirmedInformationCorrectAndComplete
          hasReceivedBasicInformationOnInvestments
          hasAcknowledgedSustainabilityClause
          hasReceivedContractDraft
          isActingOnOwnBehalf
          isRetailClient
        }
      }
    }
  }
`;

export const LegalEntityMandateContractQuery = gql`
  query LegalEntityMandateContractQuery($legalEintityId: String) {
    naturalPersonIdentificationStatus
    legalEntityIdentificationStatus(legalEntityId: $legalEintityId) {
      accumulatedIdentificationStatus
      legalEntityIdentificationStatus
      legalRepresentative {
        id
        url
        status
      }
      additionalLegalRepresentatives {
        id
        url
        status
      }
      missingDocuments
      failedIdentificationMessage
    }
    profile {
      contracts {
        aiMandate {
          hasConfirmedContractSigning
          hasConfirmedInformationCorrectAndComplete
          hasReceivedBasicInformationOnInvestments
          hasAcknowledgedSustainabilityClause
          hasReceivedContractDraft
          isActingOnOwnBehalf
          isRetailClient
        }
      }
    }
  }
`;

export const MandateContractDocumentQuery = gql`
  ${Document}
  query GetAIMandateContractDocument {
    profile {
      contracts {
        aiMandate {
          draft {
            ...Document
          }
        }
      }
    }
  }
`;
