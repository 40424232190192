import { gql } from "@apollo/client";

export const Address = gql`
  fragment Address on Address {
    addition
    streetName
    streetNumber
    postalCode
    city
    state
    country
  }
`;
export const AssetClassExperience = gql`
  fragment AssetClassExperience on AssetClassExperience {
    experienceInYears
    averageNumberOfTransactionsPerYear
    averageInvestmentVolumePerYear
  }
`;
export const Document = gql`
  fragment Document on Document {
    id
    filename
    mimetype
    status
    createdAt
    updatedAt
  }
`;

export const BankAccount = gql`
  fragment BankAccount on BankAccount {
    type
    accountHolderFirstName
    accountHolderFamilyName
    iban
    bic
    nameOfBank
    cityOfBank
  }
`;

export const LegalEntityBankAccount = gql`
  fragment LegalEntityBankAccount on LegalEntityBankAccount {
    type
    accountHolderCompany
    iban
    bic
    nameOfBank
    cityOfBank
  }
`;
