import { Loading } from "@finvia/component-library";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import QuestionnaireController from "../../../components/QuestionnaireController";
import { MandateContractNaturalPersonFormStep } from "../../../formSteps";
import ContractConfirmation from "../common/ContractConfirmationForm/ContractConfirmationForm";
import { Declaration } from "../common/DeclarationForm";
import { ROUTES } from "../../../../../routes/routes";
import IdentificationForm from "./Identification/Identification";
import { deriveFormAccess } from "./naturalPersonValidations";

const MandateContractQuestionnaire: React.FC = () => {
  const [atMostAccessibleStep, setAtMostAccessibleStep] = useState<
    MandateContractNaturalPersonFormStep | undefined
  >(undefined);

  const history = useHistory();

  const { mandateContractData, isComplete } = useSelector((state) => ({
    mandateContractData: state.alternativeInvestments.mandateContractData.data,
    isComplete: state.alternativeInvestments.mandateContractData.isComplete,
  }));

  useEffect(() => {
    setAtMostAccessibleStep(
      deriveFormAccess({ ...mandateContractData.naturalPerson })
        .atMostAccessibleStep
    );
  }, [mandateContractData]);

  if (!atMostAccessibleStep) {
    return <Loading />;
  }

  return (
    <QuestionnaireController
      atMostAccessibleStep={atMostAccessibleStep}
      onBack={() => history.push(ROUTES.questionnaires.ai.root)}
      onComplete={() => history.push(ROUTES.funds.root)}
      isComplete={isComplete}
      stepGroups={[
        {
          label: "Erklärungen",
          steps: [
            {
              id: MandateContractNaturalPersonFormStep.Declaration,
              component: Declaration,
            },
          ],
        },
        {
          label: "Identifikation",
          steps: [
            {
              id: MandateContractNaturalPersonFormStep.Identification,
              component: IdentificationForm,
            },
          ],
        },
        {
          label: "Mandatsvertrag",
          steps: [
            {
              id: MandateContractNaturalPersonFormStep.MandateContract,
              component: ContractConfirmation,
            },
          ],
        },
      ]}
    />
  );
};

export default MandateContractQuestionnaire;
