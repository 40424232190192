import { DeepPartial } from "react-hook-form";
import { GraphQLPartial } from "./graphQLPartial";

const typesafeDeepMergeWithUnsetting = <T extends Record<string, unknown>>(
  oldObject: DeepPartial<T>,
  newObject: GraphQLPartial<T>
): DeepPartial<T> => {
  const result: DeepPartial<T> = {} as any;

  const allKeys = new Set<keyof DeepPartial<T>>();

  if (typeof oldObject === "object") {
    Object.keys(oldObject).forEach((k) =>
      allKeys.add(k as keyof DeepPartial<T>)
    );
  }

  if (typeof newObject === "object") {
    Object.keys(newObject).forEach((k) =>
      allKeys.add(k as keyof DeepPartial<T>)
    );
  }

  for (const key of allKeys) {
    result[key] = oldObject?.[key] as any;
    if (Object.getOwnPropertyDescriptor(newObject, key)) {
      const newValue = newObject[key as keyof GraphQLPartial<T>];

      if (newValue === null) {
        result[key] = undefined as any;
      } else if (typeof newValue === "undefined") {
        // NOOP
      } else if (Array.isArray(newValue)) {
        result[key] = newValue as DeepPartial<T>[keyof DeepPartial<T>];
      } else if (typeof newValue === "object") {
        result[key] = typesafeDeepMergeWithUnsetting(
          oldObject?.[key] as any,
          newValue
        );
      } else {
        result[key] = newValue as DeepPartial<T>[keyof DeepPartial<T>];
      }
    }
  }

  return result as DeepPartial<T>;
};

export default typesafeDeepMergeWithUnsetting;
