import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import FullRow from "../../../../../../components/InputGrid/FullRow";
import InputGrid from "../../../../../../components/InputGrid/InputGrid";
import Separator from "../../../../../../components/InputGrid/Separator";
import { FormContent } from "../../../../../../components/FormContent";
import {
  MoneyRangeLiabilities,
  MoneyRangeSmall,
} from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateProfile } from "../../actions";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import {
  liabilityCheckboxNames,
  validationSchema,
} from "./LiabilitiesForm.schema";
import {
  Checkbox,
  ChoiceGroup,
  Heading,
  SelectInput,
  Text,
  Grid,
  TextInput,
} from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { LiabilitiesFormType } from "./LiabilitiesForm.types";
import { MonetaryInput } from "../../MonetaryInput";

const translationPrefix = "q-ai.opportunities.form.liabilities";

const LiabilitiesForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, xl: 1 }}>
        <SidebarContentBlock
          iconType="user"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityContactPerson.title")}
          description={t("sidebar.legalEntityContactPerson.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) => {
    const savings =
      state.alternativeInvestments.regulatoryData?.personData?.regulatoryInfo
        ?.savings;

    const liabilities =
      state.alternativeInvestments.regulatoryData?.personData?.regulatoryInfo
        ?.liabilities;

    return formDefaultsDeep<LiabilitiesFormType>(
      {
        liabilities: liabilities,
        savings: savings,
        availableMonthlyLiquidity:
          state.alternativeInvestments.regulatoryData?.personData
            ?.regulatoryInfo?.availableMonthlyLiquidity,
      },
      {
        liabilities: {
          annualLiabilities: {
            range: undefined,
            value: "",
          },
          rent: {
            checkbox: !!liabilities?.rent?.range,
            range: undefined,
            value: "",
          },
          living: {
            checkbox: !!liabilities?.living?.range,
            range: undefined,
            value: "",
          },
          loan: {
            checkbox: !!liabilities?.loan?.range,
            range: undefined,
            value: "",
          },
          alimony: {
            checkbox: !!liabilities?.alimony?.range,
            range: undefined,
            value: "",
          },
          insurance: {
            checkbox: !!liabilities?.insurance?.range,
            range: undefined,
            value: "",
          },
        },
        savings: {
          minimumShortTermReserve: {
            exists: savings?.minimumShortTermReserve?.exists ? "yes" : "no",
            value: "",
            date: "",
          },
        },
        availableMonthlyLiquidity: {
          range: undefined,
          value: "",
        },
      }
    );
  });

  const onSubmit = useCallback(
    async (data: LiabilitiesFormType) => {
      dispatch(updateProfile({ regulatoryInfo: data }))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<LiabilitiesFormType>
      dontShowErrors
      defaultValues={defaultData}
      onBack={previousStep}
      onSubmit={onSubmit}
      validationSchema={memoizedSchema}
      submitLabel={t("onboarding.form.continue")}
      backLabel={t("onboarding.form.back")}
      dataAnalytics="2.7_np_liabilities"
    >
      {(formMethods) => {
        const { watch, errors, trigger } = formMethods;
        const watchAnnualLiabilities = watch(
          `liabilities.annualLiabilities.range`,
          defaultData?.liabilities?.annualLiabilities?.range
        );
        const watchMinimumShortTermReserve = watch(
          "savings.minimumShortTermReserve.exists",
          defaultData?.savings?.minimumShortTermReserve?.exists ? "yes" : "no"
        );
        const watchMinimumShortTermReserveValue = watch(
          "savings.minimumShortTermReserve.value"
        );

        if (
          watchMinimumShortTermReserve === "yes" &&
          watchMinimumShortTermReserveValue
        ) {
          trigger(`savings.minimumShortTermReserve.value`);
        }

        const watchAvailableMonthlyLiquidity = watch(
          `availableMonthlyLiquidity.range`,
          defaultData?.availableMonthlyLiquidity?.range
        );

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <InputGrid defaultColumnGap>
              <FullRow>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.subTitle`)}
                </Heading>
              </FullRow>

              <div data-test-id="liabilitiesTotalRange">
                <SelectInput
                  name={`liabilities.annualLiabilities.range`}
                  label={t(
                    `${translationPrefix}.fields.liabilities.total.range.label`
                  )}
                  required
                  options={getSelectOptionsFromTranslation(
                    `${translationPrefix}.fields.liabilities.total.range.values`,
                    t
                  )}
                  value={
                    defaultData.liabilities?.annualLiabilities?.range ??
                    undefined
                  }
                  ref={formMethods.register}
                  helperText={t(
                    `${translationPrefix}.fields.liabilities.total.range.tooltip`
                  )}
                />
              </div>
              {watchAnnualLiabilities ===
              MoneyRangeLiabilities.MORE_THAN_500000 ? (
                <MonetaryInput
                  ref={formMethods.register}
                  required
                  name={`liabilities.annualLiabilities.value`}
                  label={t(
                    `${translationPrefix}.fields.liabilities.total.value.label`
                  )}
                  errorMessage={
                    errors?.liabilities?.annualLiabilities?.value?.message ??
                    undefined
                  }
                />
              ) : (
                <Separator singleColumn />
              )}

              <FullRow>
                <Text>{t(`${translationPrefix}.liabilitiesPerMonth`)}</Text>
              </FullRow>
              {liabilityCheckboxNames.map((checkbox) => {
                const checkboxName = `liabilities.${checkbox}.checkbox`;
                const watchRange = watch(
                  `liabilities.${checkbox}.range`,
                  defaultData.liabilities?.[checkbox]?.range
                );
                const watchCheckbox = watch(
                  checkboxName,
                  defaultData.liabilities?.[checkbox]?.checkbox
                );

                return (
                  <FullRow key={checkbox}>
                    <InputGrid gap="15px" defaultColumnGap>
                      <FullRow>
                        <Checkbox
                          data-test-id={checkbox}
                          name={checkboxName}
                          label={t(
                            `${translationPrefix}.fields.liabilities.${checkbox}.range.label`
                          )}
                          ref={formMethods.register}
                        />
                      </FullRow>

                      {watchCheckbox && (
                        <>
                          <SelectInput
                            name={`liabilities.${checkbox}.range`}
                            label={t(
                              `${translationPrefix}.fields.liabilities.${checkbox}.range.label`
                            )}
                            required
                            options={getSelectOptionsFromTranslation(
                              `${translationPrefix}.fields.liabilities.${checkbox}.range.values`,
                              t
                            )}
                            value={
                              defaultData.liabilities?.[checkbox]?.range ??
                              undefined
                            }
                            ref={formMethods.register}
                            errorMessage={
                              errors?.[
                                "liabilities.liabilitiesPerMonthCheckboxGroup"
                              ]?.message
                            }
                          />
                          {watchRange === MoneyRangeSmall.MORE_THAN_5000 && (
                            <MonetaryInput
                              ref={formMethods.register}
                              required
                              name={`liabilities.${checkbox}.value`}
                              label={t(
                                `${translationPrefix}.fields.liabilities.${checkbox}.value.label`
                              )}
                              errorMessage={
                                errors?.liabilities?.[checkbox]?.value
                                  ?.message ?? undefined
                              }
                            />
                          )}
                        </>
                      )}
                    </InputGrid>
                  </FullRow>
                );
              })}
              <Text variant="error" size="1">
                {errors &&
                  (errors as any)[
                    "liabilities.liabilitiesPerMonthCheckboxGroup"
                  ]?.message}
              </Text>
              <FullRow>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.liquidity`)}
                </Heading>
              </FullRow>
              <SelectInput
                name={`availableMonthlyLiquidity.range`}
                label={t(
                  `${translationPrefix}.fields.availableMonthlyLiquidity.range.label`
                )}
                required
                options={getSelectOptionsFromTranslation(
                  `${translationPrefix}.fields.availableMonthlyLiquidity.range.values`,
                  t
                )}
                value={
                  defaultData.availableMonthlyLiquidity?.range ?? undefined
                }
                ref={formMethods.register}
              />
              {watchAvailableMonthlyLiquidity ===
                MoneyRangeSmall.MORE_THAN_5000 && (
                <MonetaryInput
                  ref={formMethods.register}
                  required
                  name={`availableMonthlyLiquidity.value`}
                  label={t(
                    `${translationPrefix}.fields.availableMonthlyLiquidity.value.label`
                  )}
                  errorMessage={
                    errors?.availableMonthlyLiquidity?.value?.message ??
                    undefined
                  }
                />
              )}
              <Separator />
              <FullRow>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(
                    `${translationPrefix}.fields.savings.minimumShortTermReserve.exists.title`
                  )}
                </Heading>
              </FullRow>
              <FullRow>
                <ChoiceGroup
                  required
                  name="savings.minimumShortTermReserve.exists"
                  label={t(
                    `${translationPrefix}.fields.savings.minimumShortTermReserve.exists.description`
                  )}
                  errorMessage={
                    errors &&
                    !watchMinimumShortTermReserve &&
                    (errors as any)["savings.minimumShortTermReserve.exists"]
                      ?.message
                  }
                >
                  <Grid columns={{ sm: 3, lg: 8 }} gap="mega">
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="savings.minimumShortTermReserve.exists"
                      value="yes"
                      label={t("form.yes")}
                    />
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="savings.minimumShortTermReserve.exists"
                      value="no"
                      label={t("form.no")}
                    />
                  </Grid>
                </ChoiceGroup>
              </FullRow>
              {watchMinimumShortTermReserve === "yes" && (
                <>
                  <MonetaryInput
                    ref={formMethods.register}
                    required
                    name={`savings.minimumShortTermReserve.value`}
                    label={t(
                      `${translationPrefix}.fields.savings.minimumShortTermReserve.value.label`
                    )}
                    errorMessage={
                      errors?.savings?.minimumShortTermReserve?.value
                        ?.message ?? undefined
                    }
                    helperText={t(
                      `${translationPrefix}.fields.savings.minimumShortTermReserve.value.hint`
                    )}
                  />

                  <TextInput crossOrigin
                    name={`savings.minimumShortTermReserve.date`}
                    label={t(
                      `${translationPrefix}.fields.savings.minimumShortTermReserve.date.label`
                    )}
                    defaultValue={
                      defaultData?.savings?.minimumShortTermReserve?.date || ""
                    }
                    ref={formMethods.register}
                    errorMessage={
                      errors?.savings?.minimumShortTermReserve?.date?.message ??
                      undefined
                    }
                  />
                </>
              )}
            </InputGrid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default LiabilitiesForm;
