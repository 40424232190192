import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  BKYCDocumentCategories,
  ContractType as ContractTypeEnum,
} from "../../../../generated/globalTypes";
import {
  Document,
  OptionalDocument,
} from "../../../../genericValidations/DocumentValidations";
import { FormDefaultData } from "../../../../utils/graphQLPartial";
import typesafeDeepMergeWithUnsetting from "../../../../utils/typesafeDeepMergeWithUnsetting";
import { getLegalEntityMandateContractData } from "../mandateContract/actions";
import {
  getRegulatoryData,
  updateEmploymentAndPersonalIrsDocumentData,
  updateProfile,
  upsertMandateContract,
} from "./actions";
import { ContractType } from "./contractTypeFormValidations";
import {
  deleteLegalEntityAdditionalPerson,
  getLegalEntityRegulatoryData,
  updateDocumentsData,
  updateIdentityDocumentsData,
  updateLegalEntityProfile,
  updateTaxInformationCrsStatusDocuments,
  updateTaxInformationFatcaStatusDocuments,
  updateTaxInformationResidencyDocuments,
  upsertLegalEntityAdditionalPerson,
} from "./legalEntity/actions";
import { LegalEntityDocumentCategory } from "./legalEntity/DocumentsUpload/DocumentsUpload.types";
import {
  deriveFormAccess as legalEntityDeriveFormAccess,
  LegalEntityContractAndRegulatoryData,
} from "./legalEntity/legalEntityValidations";
import {
  ContractAndRegulatoryData,
  deriveFormAccess,
} from "./naturalPerson/naturalPersonValidations";

interface State {
  isLoading: boolean;
  isComplete: boolean;
  contractType?: ContractType["contractType"];
  personData: FormDefaultData<ContractAndRegulatoryData>;
  legalEntityData: FormDefaultData<LegalEntityContractAndRegulatoryData>;
}

const initialState: State = {
  isLoading: false,
  isComplete: false,
  contractType: undefined,
  personData: {
    bankAccounts: undefined,
    citizenship: undefined,
    contactAddress: undefined,
    contactData: undefined,
    employment: undefined,
    legalAddress: undefined,
    personalData: undefined,
    regulatoryInfo: undefined,
    taxInfo: undefined,
    contractType: undefined,
    id: undefined,
  },
  legalEntityData: {
    legalEntityId: undefined,
    isLegalRepresentative: undefined,
    employment: undefined,
    financialStatus: {
      revenue: undefined,
      monthlyLiabilities: undefined,
      monthlyLiquidity: undefined,
      reserve: undefined,
    },
    isBeneficialOwner: undefined,
    companyEquityPercentage: undefined,
    legalEntityData: undefined,
    businessActivity: undefined,
    taxInformation: undefined,
    documents: {
      currentTradeRegister: {
        documents: [],
      },
      shareholdersListNonAg: {
        documents: [],
      },
      shareholdersListAg: {
        documents: [],
      },
      furtherShareholders: {
        documents: [],
      },
      shareholdersContract: {
        documents: [],
      },
      incorperation: {
        documents: [],
      },
      others: {
        documents: [],
      },
    },
    bankAccounts: undefined,
    legalEntityAdditionalPersons: [],
    profile: undefined,
    powerOfAttorney: {
      url: undefined,
    },
  },
};

const slice = createSlice({
  name: "contractAndRegulatoryData",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getRegulatoryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRegulatoryData.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        state.personData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<ContractAndRegulatoryData>
        >(state.personData, payload);

        // Do not set the isComplete variable from Natural Person data if the user is a Legal Entity
        if (payload.contractType === ContractTypeEnum.NATURAL_PERSON) {
          const isComplete = deriveFormAccess(state.personData).isComplete;
          state.isComplete = isComplete;
        }
      })
      .addCase(upsertMandateContract.fulfilled, (state, { payload }) => {
        state.personData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<ContractAndRegulatoryData>
        >(state.personData, payload);
        if (payload.contractType === ContractTypeEnum.NATURAL_PERSON) {
          const isComplete = deriveFormAccess(state.personData).isComplete;
          state.isComplete = isComplete;
        } else if (payload.contractType === ContractTypeEnum.LEGAL_ENTITY) {
          const isComplete = legalEntityDeriveFormAccess({
            legalEntity: state.legalEntityData,
            naturalPerson: state.personData,
          }).isComplete;
          state.isComplete = isComplete;
        }
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.personData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<ContractAndRegulatoryData>
        >(state.personData, payload);

        // prevents to save deleted secondary Citizenships in the global state
        state.personData.citizenship = payload.citizenship
          ? payload.citizenship
          : state.personData.citizenship;

        if (state.personData.contractType === ContractTypeEnum.NATURAL_PERSON) {
          const isComplete = deriveFormAccess(state.personData).isComplete;
          state.isComplete = isComplete;
        } else if (
          state.personData.contractType === ContractTypeEnum.LEGAL_ENTITY
        ) {
          const isComplete = legalEntityDeriveFormAccess({
            legalEntity: state.legalEntityData,
            naturalPerson: state.personData,
          }).isComplete;
          state.isComplete = isComplete;
        }
      })
      .addCase(
        updateEmploymentAndPersonalIrsDocumentData,
        (state, { payload }) => {
          let newIrsDocuments = [
            ...(state.personData?.taxInfo?.facta?.irsDocuments || []),
          ];

          payload.forEach(({ type, documentId, filename, mimetype }) => {
            if (type === "delete") {
              if (newIrsDocuments) {
                newIrsDocuments = newIrsDocuments.filter(
                  (doc) => doc.id !== documentId
                );
              }
            } else if (type === "update") {
              newIrsDocuments?.push({
                id: documentId,
                filename,
                mimetype,
              });
            }
          });

          state.personData = typesafeDeepMergeWithUnsetting<
            FormDefaultData<ContractAndRegulatoryData>
          >(state.personData, {
            taxInfo: { facta: { irsDocuments: newIrsDocuments } },
          });
          // Do not set the isComplete variable from Natural Person data if the user is a Legal Entity
          if (
            state.personData.contractType === ContractTypeEnum.NATURAL_PERSON
          ) {
            const isComplete = deriveFormAccess(state.personData).isComplete;
            state.isComplete = isComplete;
          }
        }
      )
      // Legal Entity cases
      .addCase(getLegalEntityRegulatoryData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLegalEntityRegulatoryData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { contractType, ...rest } = payload;
        const personData = _.omit(payload, "profile.contractType");

        state.legalEntityData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<LegalEntityContractAndRegulatoryData>
        >(state.legalEntityData, rest);
        state.personData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<ContractAndRegulatoryData>
        >(state.personData, personData as ContractAndRegulatoryData);

        // Do not set the isComplete variable from Legal Entity data if the user is a Natural Person
        if (contractType === ContractTypeEnum.LEGAL_ENTITY) {
          const isComplete = legalEntityDeriveFormAccess({
            legalEntity: state.legalEntityData,
            naturalPerson: state.personData,
          }).isComplete;
          state.isComplete = isComplete;
        }
      })
      .addCase(updateLegalEntityProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLegalEntityProfile.fulfilled, (state, { payload }) => {
        // This newPayload should be removed when we fix the data model because the legal entity
        // should not have profile data inside it
        const payloadKeys = Object.keys(payload) as (keyof typeof payload)[];
        const profileKeys = payloadKeys.filter(
          (payloadKey) =>
            !initialState.legalEntityData.hasOwnProperty(payloadKey)
        ) as (keyof typeof payload)[];

        const profilePayload = profileKeys.reduce((acc, profileKey) => {
          return { ...acc, [profileKey]: payload[profileKey] };
        }, {});

        const newPayload = { ...payload, profile: { ...profilePayload } };

        state.legalEntityData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<LegalEntityContractAndRegulatoryData>
        >(state.legalEntityData, newPayload);

        // Do not set the isComplete variable from Legal Entity data if the user is a Natural Person
        if (state.personData.contractType === ContractTypeEnum.LEGAL_ENTITY) {
          const isComplete = legalEntityDeriveFormAccess({
            legalEntity: state.legalEntityData,
            naturalPerson: state.personData,
          }).isComplete;
          state.isComplete = isComplete;
        }
      })
      .addCase(
        upsertLegalEntityAdditionalPerson.fulfilled,
        (state, { payload }) => {
          if (payload) {
            const existingPersonIndex = (
              state.legalEntityData.legalEntityAdditionalPersons || []
            ).findIndex((person) => person.id === payload.id);

            if (existingPersonIndex === -1) {
              state.legalEntityData.legalEntityAdditionalPersons?.push(payload);
            } else {
              state.legalEntityData.legalEntityAdditionalPersons?.splice(
                existingPersonIndex,
                1,
                payload
              );
            }
          }
        }
      )
      .addCase(
        deleteLegalEntityAdditionalPerson.fulfilled,
        (state, { payload }) => {
          if (payload) {
            const personIndex = (
              state.legalEntityData.legalEntityAdditionalPersons || []
            ).findIndex((person) => person.id === payload.personId);

            if (personIndex !== -1) {
              state.legalEntityData.legalEntityAdditionalPersons?.splice(
                personIndex,
                1
              );
            }
          }
        }
      )
      .addCase(
        updateDocumentsData,
        (state, { payload: { changedDocuments, documentCategory } }) => {
          const newDocuments = state.legalEntityData?.documents;
          changedDocuments.forEach(
            ({ type, documentId, filename, mimetype }) => {
              if (type === "delete") {
                if (newDocuments) {
                  newDocuments[documentCategory] = {
                    isMissing: newDocuments[documentCategory]?.isMissing,
                    documents: newDocuments[
                      documentCategory
                    ]?.documents?.filter(
                      (doc: Document) => doc.id !== documentId
                    ),
                  };
                }
              } else if (type === "update") {
                newDocuments?.[documentCategory]?.documents?.push({
                  id: documentId,
                  filename,
                  mimetype,
                });
              }
            }
          );

          state.legalEntityData = typesafeDeepMergeWithUnsetting<
            FormDefaultData<LegalEntityContractAndRegulatoryData>
          >(state.legalEntityData, { documents: newDocuments });

          // Do not set the isComplete variable from Legal Entity data if the user is a Natural Person
          if (state.personData.contractType === ContractTypeEnum.LEGAL_ENTITY) {
            const isComplete = legalEntityDeriveFormAccess({
              legalEntity: state.legalEntityData,
              naturalPerson: state.personData,
            }).isComplete;
            state.isComplete = isComplete;
          }
        }
      )
      .addCase(
        updateTaxInformationResidencyDocuments,
        (state, { payload: { changedDocuments, residencyIndex } }) => {
          let residencies = state.legalEntityData.taxInformation?.residencies;

          changedDocuments.forEach(
            ({ type, documentId, filename, mimetype }) => {
              if (type === "delete") {
                if (residencies && residencies[residencyIndex]) {
                  residencies[residencyIndex] = {
                    ...residencies[residencyIndex],
                    residencyCertificate: residencies[
                      residencyIndex
                    ].residencyCertificate?.filter(
                      (doc: Document) => doc.id !== documentId
                    ),
                  };
                }
              } else if (type === "update") {
                let tmpResidency = {};

                if (!residencies) {
                  residencies = [];
                }

                if (residencies[residencyIndex]) {
                  tmpResidency = {
                    ...residencies[residencyIndex],
                    residencyCertificate: [
                      ...(residencies[residencyIndex].residencyCertificate ||
                        []),
                      { id: documentId, filename, mimetype },
                    ],
                  };
                  residencies[residencyIndex] = tmpResidency;
                } else {
                  tmpResidency = {
                    residencyCertificate: [
                      { id: documentId, filename, mimetype },
                    ],
                  };
                  residencies[residencyIndex] = tmpResidency;
                }
              }
            }
          );

          state.legalEntityData = typesafeDeepMergeWithUnsetting<
            FormDefaultData<LegalEntityContractAndRegulatoryData>
          >(state.legalEntityData, {
            taxInformation: {
              ...state.legalEntityData.taxInformation,
              residencies,
            },
          });

          // Do not set the isComplete variable from Legal Entity data if the user is a Natural Person
          if (state.personData.contractType === ContractTypeEnum.LEGAL_ENTITY) {
            const isComplete = legalEntityDeriveFormAccess({
              legalEntity: state.legalEntityData,
              naturalPerson: state.personData,
            }).isComplete;
            state.isComplete = isComplete;
          }
        }
      )
      .addCase(
        updateTaxInformationFatcaStatusDocuments,
        (state, { payload }) => {
          let newDocuments = [
            ...(state.legalEntityData.taxInformation?.fatca
              ?.statusCertificate || []),
          ];

          payload.forEach(({ type, documentId, filename, mimetype }) => {
            if (type === "delete") {
              if (newDocuments) {
                newDocuments = newDocuments.filter(
                  (doc) => doc.id !== documentId
                );
              }
            } else if (type === "update") {
              newDocuments?.push({
                id: documentId,
                filename,
                mimetype,
              });
            }
          });

          state.legalEntityData = typesafeDeepMergeWithUnsetting<
            FormDefaultData<LegalEntityContractAndRegulatoryData>
          >(state.legalEntityData, {
            taxInformation: {
              ...state.legalEntityData.taxInformation,
              fatca: {
                ...state.legalEntityData.taxInformation?.fatca,
                statusCertificate: newDocuments,
              },
            },
          });

          // Do not set the isComplete variable from Legal Entity data if the user is a Natural Person
          if (state.personData.contractType === ContractTypeEnum.LEGAL_ENTITY) {
            const isComplete = legalEntityDeriveFormAccess({
              legalEntity: state.legalEntityData,
              naturalPerson: state.personData,
            }).isComplete;
            state.isComplete = isComplete;
          }
        }
      )
      .addCase(updateTaxInformationCrsStatusDocuments, (state, { payload }) => {
        let newDocuments = [
          ...(state.legalEntityData.taxInformation?.crs?.statusCertificate ||
            []),
        ];

        payload.forEach(({ type, documentId, filename, mimetype }) => {
          if (type === "delete") {
            if (newDocuments) {
              newDocuments = newDocuments.filter(
                (doc) => doc.id !== documentId
              );
            }
          } else if (type === "update") {
            newDocuments?.push({
              id: documentId,
              filename,
              mimetype,
            });
          }
        });

        state.legalEntityData = typesafeDeepMergeWithUnsetting<
          FormDefaultData<LegalEntityContractAndRegulatoryData>
        >(state.legalEntityData, {
          taxInformation: {
            ...state.legalEntityData.taxInformation,
            crs: {
              ...state.legalEntityData.taxInformation?.crs,
              statusCertificate: newDocuments,
            },
          },
        });

        // Do not set the isComplete variable from Legal Entity data if the user is a Natural Person
        if (state.personData.contractType === ContractTypeEnum.LEGAL_ENTITY) {
          const isComplete = legalEntityDeriveFormAccess({
            legalEntity: state.legalEntityData,
            naturalPerson: state.personData,
          }).isComplete;
          state.isComplete = isComplete;
        }
      })
      .addCase(
        getLegalEntityMandateContractData.fulfilled,
        (state, { payload }) => {
          const documents: OptionalDocument = payload?.missingDocuments?.reduce(
            (obj, item) => {
              const BKYCType: BKYCDocumentCategories | undefined =
                item || undefined;

              if (BKYCType) {
                const category: LegalEntityDocumentCategory =
                  LegalEntityDocumentCategory[BKYCType];

                return {
                  ...obj,
                  [category]: {
                    documents:
                      state.legalEntityData?.documents?.[category]?.documents,
                  },
                };
              }

              return obj;
            },
            {}
          );

          const failedIdentificationMessage =
            payload?.failedIdentificationMessage;

          state.legalEntityData.documents = {
            ...state.legalEntityData.documents,
            ...documents,
          };

          state.isComplete = legalEntityDeriveFormAccess({
            legalEntity: state.legalEntityData,
            naturalPerson: state.personData,
          }).isComplete;
        }
      ),
});

export default slice;
