/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TFunction } from "i18next";
import * as yup from "yup";
import { LegalRepresentationType } from "generated/globalTypes";
import { AddPersonsPageProps } from "./AddPersonsPage.types";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const generateSchema = (
  t: TFunction,
  hasMoreThanOneLR: boolean | undefined
) => {
  if (!hasMoreThanOneLR || hasMoreThanOneLR === undefined) {
    return yup
      .mixed<LegalRepresentationType>()
      .oneOf([LegalRepresentationType.SOLE]);
  }

  return yup
    .mixed<LegalRepresentationType>()
    .oneOf(Object.values(LegalRepresentationType))
    .required(
      t(`${translationPrefix}.fields.legalRepresentationType.required`)
    );
};

export const validationPageSchema = (
  t: TFunction,
  hasMoreThanOneLR: boolean | undefined
): yup.SchemaOf<AddPersonsPageProps> => {
  return yup
    .object({
      legalRepresentationType: generateSchema(t, hasMoreThanOneLR),
      hasReceivedProcurationDocument: yup
        .boolean()
        .when("legalRepresentationType", {
          is: (value: LegalRepresentationType) =>
            value === LegalRepresentationType.JOINT,
          then: yup
            .boolean()
            .required()
            .oneOf(
              [true],
              t(`${translationPrefix}.fields.procurationDocument.required`)
            ),
        }),
    })
    .required(t(`${translationPrefix}.`));
};
