import { AnyAction, unwrapResult } from "@reduxjs/toolkit";
import { CreateUploadUrls_createUploadUrls_documents } from "generated/CreateUploadUrls";
import {
  createFileUploadUrls,
  getDocumentChanges,
  mapDocumentsToStore,
  StoreDocument,
  uploadFiles,
} from "models/documents";
import {
  AddUrlsToFiles,
  CreateFilesUrls,
  Document,
  HandleDocumentUploadGeneric,
  ResolveDocumentUpload,
  UploadStatus,
} from "../UploadField.types";
import { fromFileListToFile } from "./utils";

export const createFilesUrls = async ({
  dispatch,
  files,
  category,
  categoryName,
}: CreateFilesUrls): Promise<
  CreateUploadUrls_createUploadUrls_documents[] | undefined
> =>
  dispatch(
    createFileUploadUrls({
      files,
      category: categoryName ? categoryName : category,
    })
  ).then(unwrapResult);

export const addUrlsToFiles = async <
  UpdateAction extends (args: any) => AnyAction
>({
  files,
  uploadUrls,
  dispatch,
  updateAction,
  extraProps,
}: AddUrlsToFiles<UpdateAction>): Promise<StoreDocument[] | undefined> => {
  if (!uploadUrls?.length) {
    return;
  }

  await uploadFiles(
    uploadUrls.map((url, index) => ({
      url: url.uploadUrl,
      file: files[index],
    })),
    {
      headers: {
        "content-type": "application/octet-stream",
      },
    }
  );

  const payload = extraProps
    ? {
        changedDocuments: getDocumentChanges(files, uploadUrls, "update"),
        ...extraProps,
      }
    : getDocumentChanges(files, uploadUrls, "update");

  dispatch(updateAction(payload));

  return mapDocumentsToStore(files, uploadUrls);
};

export const resolveDocumentUpload = async <
  UpdateAction extends (args: any) => AnyAction
>({
  dispatch,
  newDocument,
  files,
  updateAction,
  category,
  categoryName,
  extraProps,
}: ResolveDocumentUpload<UpdateAction>): Promise<Document> => {
  const uploadUrls = await createFilesUrls({ dispatch, files, category });
  const documentsWithUrls = addUrlsToFiles({
    files,
    uploadUrls,
    dispatch,
    updateAction,
    extraProps,
  });

  return Promise.resolve(documentsWithUrls)
    .then((value) => {
      return {
        ...newDocument,
        id: value?.[0]?.documentId ?? "",
        progress: 100,
        status: "done" as UploadStatus,
      };
    })
    .catch((_) => {
      return {
        ...newDocument,
        status: "failed",
        progress: 100,
      };
    });
};

export const handleDocumentUploadGeneric = async <
  UpdateAction extends (args: any) => AnyAction
>({
  documents,
  file,
  setValueReference,
  dispatch,
  updateAction,
  payload,
  category,
  categoryName,
  name,
  formName,
  extraProps,
}: HandleDocumentUploadGeneric<UpdateAction>): Promise<void | Document[]> => {
  if (file === null) {
    return console.error(
      `@${formName}.handleDocumentUpload: file is null. Unable to upload the file.`,
      documents
    );
  }

  const files = fromFileListToFile(file);
  const documentsWithoutTheNew = documents.slice(0, -1);
  const newAddedItem = documents.slice(-1)[0];

  const newItemWithAddedProps = await resolveDocumentUpload({
    dispatch,
    newDocument: newAddedItem,
    files,
    updateAction,
    payload,
    category,
    categoryName,
    extraProps,
  });

  const document = [...documentsWithoutTheNew, newItemWithAddedProps];

  setValueReference(name, document, {
    shouldValidate: true,
  });

  if (newItemWithAddedProps) {
    return document;
  }

  return newItemWithAddedProps ? document : [];
};
