import styled, { css } from "styled-components";
import { StyleProps } from "types/types";

type ContainerStyleProps = {
  disabled?: boolean;
} & StyleProps;

const containerBaseStyles = ({ theme, disabled }: ContainerStyleProps) => css`
  border: 2px solid
    ${disabled
      ? theme.tokens.colors.neutral[400]
      : theme.tokens.colors.petrol[300]};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${theme.utils.viewport.md` 
flex-direction: row;
padding: 40px;
gap: 40px;
`}
`;

const imageWrapperBaseStyles = () => css`
  ${({ theme }) => css`
    display: grid;
    grid-template:
      "camera camera"
      "idnow idnow";
    gap: 10px;
    grid-template-columns: 1fr 50%;

    & > div {
      grid-area: camera;
      justify-self: center;
    }

    img[alt="identification-icon-idnow"] {
      grid-area: idnow;
      justify-self: start;
    }

    ${theme.utils.viewport.md`
      grid-template:
        "camera"
        "idnow";
      gap: 15px;
  
      &:first-child {
        max-height: 72px;
      }

      img[alt="identification-icon-idnow"] {
        justify-self: start;
      }
    `}
  `};
`;

const contentWrapperBaseStyles = () => css`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div<ContainerStyleProps>(containerBaseStyles);
export const ImageWrapper = styled.div(imageWrapperBaseStyles);
export const ContentWrapper = styled.div(contentWrapperBaseStyles);
