import { User as UserQueryType, User_profile } from "../generated/User";
import UserQuery from "../graphql/queries/User";
import { typedCreateAsyncThunk } from "./typedCreateAsyncThunk";

export const getUser = typedCreateAsyncThunk(
  "getUser",
  async (_, { extra }): Promise<User_profile | null> => {
    const result = await extra.client.query<UserQueryType>({
      query: UserQuery,
    });

    return result.data.profile;
  }
);
