import { useRef, useState } from "react";
import type { VideoProps } from "./Video.types";
import { StyledVideo, Overlay, PlayButton, Wrapper } from "./Video.styled";

const Video = (props: VideoProps): JSX.Element => {
  const [showControls, toggleControl] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onPlayButtonClick = () => {
    if (videoRef.current === null) {
      return;
    }

    toggleControl((prevState) => !prevState);
    videoRef.current.play();
  };

  return (
    <Wrapper>
      <StyledVideo
        ref={videoRef}
        controls={showControls}
        poster={props.cover?.data?.attributes?.url}
      >
        {props.video?.data?.attributes && (
          <source src={props.video.data.attributes.url} type="video/mp4" />
        )}
      </StyledVideo>
      <Overlay visible={!showControls}>
        <PlayButton onClick={onPlayButtonClick} />
      </Overlay>
    </Wrapper>
  );
};

export default Video;
