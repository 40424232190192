import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, Table, Grid } from "@finvia/money-ui";

import { LegalRepresentationType } from "generated/globalTypes";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";

import { useDefaultData, usePersons } from "./AddPersonsPage.hooks";
import { customTableStyles } from "./AddPersonsPage.styled";
import { tableHeader, tableRows } from "./AddPersonsPage.utils";
import { AddPerson } from "./components/AddPerson";
import { LegalRepresentative } from "./components/LegalRepresentative";

import { AddPersonsPageProps, validationPageSchema } from ".";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const AddPersonsPage: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tableData, hasMoreThanOneLR] = usePersons();
  const defaultData = useDefaultData();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }} gap="mega">
        <SidebarContentBlock
          iconType="people"
          iconSize="mega"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityRepresentative.title")}
          description={t("sidebar.legalEntityRepresentative.description")}
          textAlign="left"
        />
        <SidebarContentBlock
          iconType="pie-chart"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityBeneficialOwner.title")}
          description={t("sidebar.legalEntityBeneficialOwner.description")}
          textAlign="left"
        />
      </Grid>
    );

    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(
    () => validationPageSchema(t, hasMoreThanOneLR),
    [t, hasMoreThanOneLR]
  );

  const onSubmit = useCallback(
    async (legalRepresentationType: AddPersonsPageProps) => {
      dispatch(
        updateLegalEntityProfile({
          legalRepresentationType: !hasMoreThanOneLR
            ? LegalRepresentationType.SOLE
            : legalRepresentationType.legalRepresentationType,
          hasReceivedProcurationDocument:
            legalRepresentationType.hasReceivedProcurationDocument || false,
        })
      )
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep, hasMoreThanOneLR]
  );

  return (
    <FormControl<AddPersonsPageProps>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.18_le_add_persons_page"
    >
      {(formMethods) => {
        const { formState } = formMethods;

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            isDirty={formState.isDirty && tableData.length !== 1}
            isSubmitSuccessful={formState.isSubmitSuccessful}
            error={error}
          >
            <Box width="100%">
              <Table
                customStyles={customTableStyles}
                headers={tableHeader(t)}
                rows={tableRows(tableData, t)}
              />
            </Box>
            <AddPerson />
            {hasMoreThanOneLR && <LegalRepresentative />}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default AddPersonsPage;
