import { SelectInput, TextInput } from "@finvia/money-ui";
import { UploadField } from "features/alternative-investments/components/UploadField";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { countries } from "utils/options/countries";
import Separator from "../../../../../../../components/InputGrid/Separator";
import { FirstResidencyFieldProps } from "../TaxInformation.types";

const GERMANY_COUNTRY_ABBREV = "DE";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

const FirstResidencyField: React.FC<FirstResidencyFieldProps> = ({
  index,
  isLastItem,
  formMethods,
  item,
  country,
  documents,
  onUpload,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Controller
        name={`taxInformation.residencies[0].country`}
        control={formMethods.control}
        defaultValue={item.country ? item.country : GERMANY_COUNTRY_ABBREV}
        as={
          <SelectInput
            ref={formMethods.register}
            name={`taxInformation.residencies[0].country`}
            label={t(
              `${translationPrefix}.fields.taxResidency.fields.country.label`
            )}
            required
            value={item.country ? item.country : GERMANY_COUNTRY_ABBREV}
            options={countries(t)}
            searchable
            errorMessage={
              formMethods.errors.taxInformation?.residencies?.[index]?.country
                ?.message
            }
            disabled
          />
        }
      />
      <Controller
        name={`taxInformation.residencies[0].taxId`}
        control={formMethods.control}
        defaultValue={item.taxId}
        as={
          <TextInput crossOrigin
            ref={formMethods.register}
            name={`taxInformation.residencies[0].taxId`}
            required
            label={t(
              `${translationPrefix}.fields.taxResidency.fields.taxId.label`
            )}
            errorMessage={
              formMethods.errors?.taxInformation?.residencies?.[index]?.taxId
                ?.message
            }
            defaultValue={item.taxId}
            helperText={t(
              `${translationPrefix}.fields.taxResidency.fields.taxId.tooltip`
            )}
          />
        }
      />
      {country === GERMANY_COUNTRY_ABBREV ? (
        <Controller
          name={`taxInformation.residencies[0].localTaxOffice`}
          control={formMethods.control}
          defaultValue={item.localTaxOffice ?? ""}
          as={
            <TextInput crossOrigin
              ref={formMethods.register}
              name={`taxInformation.residencies[0].localTaxOffice`}
              required
              label={t(
                `${translationPrefix}.fields.taxResidency.fields.localTaxOffice.label`
              )}
              errorMessage={
                formMethods.errors?.taxInformation?.residencies?.[index]
                  ?.localTaxOffice?.message
              }
              defaultValue={item.localTaxOffice ?? ""}
            />
          }
        />
      ) : (
        <Controller
          name={`taxInformation.residencies[0].residencyCertificate`}
          control={formMethods.control}
          defaultValue={
            documents?.[`taxInformation.residencies[0].residencyCertificate`] ??
            []
          }
          as={
            <UploadField
              name={`taxInformation.residencies[0].residencyCertificate`}
              onUpload={(documentslist, file) => {
                onUpload(documentslist, file, formMethods.setValue, index);
              }}
              onDelete={(document, documentslist) => {
                onDelete(
                  document,
                  documentslist,
                  `taxInformation.residencies[0].residencyCertificate`,
                  formMethods.setValue,
                  index
                );
              }}
              documentsList={
                documents?.[
                  `taxInformation.residencies[0].residencyCertificate`
                ] ?? []
              }
              label={t(
                `${translationPrefix}.fields.taxResidency.fields.residencyCertificate.label`
              )}
              helperText={t(
                `${translationPrefix}.fields.taxResidency.fields.residencyCertificate.tooltip`
              )}
              inputErrorMessage={
                formMethods.errors?.taxInformation?.residencies?.[index]
                  ?.residencyCertificate?.message
              }
              barErrorMessage={t("form.fileUpload.failed")}
              barSuccessMessage={t("form.fileUpload.succeeded")}
              formMethods={formMethods}
            />
          }
        />
      )}
      {!isLastItem && <Separator singleColumn />}
    </>
  );
};

export default FirstResidencyField;
