import { UploadStatus } from "@finvia/component-library";
import { createAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import {
  UpdateIdentityDocument as UpdateIdentityDocumentType,
  UpdateIdentityDocumentVariables,
} from "generated/UpdateIdentityDocument";
import { UpdateProfile, UpdateProfileVariables } from "generated/UpdateProfile";
import { DeepPartial } from "react-hook-form";
import { mapFormDateToGraphQl, mapGraphQlDateToForm } from "utils/formUtils";
import { Maybe } from "yup/lib/types";
import {
  DeleteLegalEntityAdditionalPerson as DeleteLegalEntityAdditionalPersonMutType,
  DeleteLegalEntityAdditionalPersonVariables,
} from "../../../../../generated/DeleteLegalEntityAdditionalPerson";
import {
  BankAccountSetInput,
  BankAccountType,
  Currency,
  FinancialStatusInput,
  IdentityDocumentInput,
  LegalEntityAdditionalPersonInput,
  LegalEntityBankAccountInput,
  LegalEntityCRSStatus,
  LegalEntityRegistrationInput,
  MoneyRangeLarge,
  MoneyRangeTransactions,
  PEPInput,
  PEPStatus,
  RegulatoryInfoInput,
  TaxResidencyInput,
  TransparencyRegisterObligationOption,
  WealthAssessmentInput,
} from "../../../../../generated/globalTypes";
import {
  LegalEntitiesQuery as LegalEntitiesQueryType,
  LegalEntitiesQuery_alternativeInvestmentMandateContract,
  LegalEntitiesQuery_legalEntity,
  LegalEntitiesQuery_profile,
} from "../../../../../generated/LegalEntitiesQuery";
import {
  UpdateLegalEntityProfile as UpdateLegalEntityProfileType,
  UpdateLegalEntityProfileVariables,
} from "../../../../../generated/UpdateLegalEntityProfile";
import {
  UpsertLegalEntityAdditionalPerson as UpsertLegalEntityAdditionalPersonMutType,
  UpsertLegalEntityAdditionalPersonVariables,
} from "../../../../../generated/UpsertLegalEntityAdditionalPerson";
import {
  DeleteLegalEntityAdditionalPerson,
  UpdateIdentityDocument,
  UpdateLegalEntityProfile,
  UpdateProfile as updateProfileMutation,
  UpsertLegalEntityAdditionalPerson,
} from "../../../../../graphql/mutations/Profile";
import { LegalEntitiesQuery } from "../../../../../graphql/queries/LegalEntity";
import { DocumentChange } from "../../../../../models/documents";
import rootReducer from "../../../../../store/reducers";
import { typedCreateAsyncThunk } from "../../../../../store/typedCreateAsyncThunk";
import { FormDefaultData } from "../../../../../utils/graphQLPartial";
import { convertBooleanToYesNoOrEmptyString } from "../../../../../utils/helpers";
import { mapProfileFromGraphQlToForm } from "../actions";
import { ContractAndRegulatoryData } from "../naturalPerson/naturalPersonValidations";
import { AdditionalPersonProps } from "./AddPersonsPage";
import { DocumentCategoryNames } from "./DocumentsUpload/DocumentsUpload.types";
import {
  LegalEntityContractAndRegulatoryData,
  LegalEntityId,
  PowerOfAttorney,
} from "./legalEntityValidations";

type AppState = ReturnType<typeof rootReducer>;

// Used by the inner model
export enum TransparencyRegisterObligationOptionEnum {
  EXTRACT_ATTACHMENT = "EXTRACT_ATTACHMENT",
  NOT_OBLIGED = "NOT_OBLIGED",
}

// TODO: test properly
const mapRegisterObligationFromGraphqlToFrom = (
  obligation: Maybe<TransparencyRegisterObligationOption>
): [Maybe<boolean>, Maybe<TransparencyRegisterObligationOptionEnum>] => {
  // This ensures the default behavior that the checkbox is not defaulting to false
  // in case we don't have any value in the backend yet!
  if (!obligation) {
    return [undefined, undefined];
  }

  if (
    obligation ===
    TransparencyRegisterObligationOption.INVESTOR_ATTACHES_EXCERPT
  ) {
    return [true, TransparencyRegisterObligationOptionEnum.EXTRACT_ATTACHMENT];
  }

  if (
    obligation === TransparencyRegisterObligationOption.INVESTOR_NOT_OBLIGATED
  ) {
    return [true, TransparencyRegisterObligationOptionEnum.NOT_OBLIGED];
  }

  if (
    obligation === TransparencyRegisterObligationOption.COMPANY_NOT_OBLIGATED
  ) {
    return [false, null];
  }

  return [false, null];
};

const mapRegisterObligationFromFormToGraphql = (
  isObligated: boolean,
  obligationOption: Maybe<TransparencyRegisterObligationOptionEnum>
): Maybe<TransparencyRegisterObligationOption> => {
  if (isObligated === null || isObligated === undefined) {
    return null;
  }

  if (isObligated === false) {
    return TransparencyRegisterObligationOption.COMPANY_NOT_OBLIGATED;
  }

  if (
    obligationOption ===
    TransparencyRegisterObligationOptionEnum.EXTRACT_ATTACHMENT
  ) {
    return TransparencyRegisterObligationOption.INVESTOR_ATTACHES_EXCERPT;
  }

  if (
    obligationOption === TransparencyRegisterObligationOptionEnum.NOT_OBLIGED
  ) {
    return TransparencyRegisterObligationOption.INVESTOR_NOT_OBLIGATED;
  }

  return null;
};

export const convertPercentIntoInt = (
  number: string | undefined | null
): number | undefined =>
  number
    ? Number((parseFloat(number.replace(",", ".")) * 100).toFixed())
    : undefined;

const mapCompanyPercentageToGraphQl = (
  isBeneficialOwner: boolean,
  companyEquityPercentage: string | undefined
): number | null => {
  if (isBeneficialOwner === false) {
    return null;
  }

  return companyEquityPercentage
    ? convertPercentIntoInt(companyEquityPercentage) ?? null
    : null;
};

export const convertIntIntoPercent = (
  number: number | undefined | null
): string | undefined => (number ? (number / 100).toString() : undefined);

/*
Despite the CRS Status being an array (because it could take apparently 2 values; see form Submit method),
we are showing the component as a dropdown with only one single value selection possible.
For the dropdown not to break, we need to pass 1 value.
The NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE value is the main one in this case.
The PASSIVE_NFE CRS status value is added automatically in the submit method, depending on the
value selected on the "other characteristic" field.
*/
const mapCRSStatusFromGraphQLToForm = (
  crsStatus: LegalEntityCRSStatus[] | null | undefined
) => {
  if (!crsStatus || !crsStatus.length) return crsStatus;

  if (
    crsStatus?.length === 2 &&
    crsStatus.includes(
      LegalEntityCRSStatus.NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE
    ) &&
    crsStatus.includes(LegalEntityCRSStatus.PASSIVE_NFE)
  ) {
    return [LegalEntityCRSStatus.NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE];
  }

  return crsStatus;
};

type Profile = LegalEntitiesQuery_profile;

// TODO Remove the Partial when we are done implementing the Legal entity flow
export const mapLegalEntityFromGraphQlToForm = (
  legalEntity?: LegalEntitiesQuery_legalEntity | null,
  profile?: Profile | null,
  alternativeInvestmentMandateContract?: LegalEntitiesQuery_alternativeInvestmentMandateContract | null
): FormDefaultData<
  Partial<
    LegalEntityContractAndRegulatoryData & {
      profile: ContractAndRegulatoryData;
    }
  > & {
    legalEntityId: LegalEntityId;
    powerOfAttorney: PowerOfAttorney;
  }
> => {
  // This contains the mapping which is used in the NaturalPerson flow
  const mappedProfile = mapProfileFromGraphQlToForm(profile);
  const [transparencyRegisterObligation, transparencyRegisterObligationOption] =
    mapRegisterObligationFromGraphqlToFrom(
      legalEntity?.businessActivity?.transparencyRegisterObligation
    );

  return {
    legalEntityId: legalEntity?.id,
    contractType: profile?.contracts?.aiMandate?.contractType,
    isLegalRepresentative: convertBooleanToYesNoOrEmptyString(
      legalEntity?.isLegalRepresentative
    ),
    powerOfAttorney: {
      url: alternativeInvestmentMandateContract?.data?.attributes
        ?.powerOfAttorney?.data?.attributes?.url,
    },
    isBeneficialOwner: convertBooleanToYesNoOrEmptyString(
      legalEntity?.isBeneficialOwner
    ),
    companyEquityPercentage: convertIntIntoPercent(
      legalEntity?.companyEquityPercentage
    ),
    employment: {
      role: legalEntity?.functionaryRole,
      country: legalEntity?.countryOfOperations,
      pep: {
        status: profile?.regulatoryInfo?.pep?.status,
        role: profile?.regulatoryInfo?.pep?.role,
        relationship: profile?.regulatoryInfo?.pep?.relationship,
      },
    },
    // TODO: clarify
    // - why only a subset of the defined properties are used here?
    // in the LegalEntity query type in graphql?
    financialStatus: {
      revenue: {
        commercialActivities: {
          checkbox:
            !!legalEntity?.financialStatus?.revenue?.commercialActivities
              ?.range,
          range:
            legalEntity?.financialStatus?.revenue?.commercialActivities?.range,
          value:
            legalEntity?.financialStatus?.revenue?.commercialActivities?.value
              ?.value,
        },
        rent: {
          checkbox: !!legalEntity?.financialStatus?.revenue?.rent?.range,
          range: legalEntity?.financialStatus?.revenue?.rent?.range,
          value: legalEntity?.financialStatus?.revenue?.rent?.value?.value,
        },
        fixedAssets: {
          checkbox: !!legalEntity?.financialStatus?.revenue?.fixedAssets?.range,
          range: legalEntity?.financialStatus?.revenue?.fixedAssets?.range,
          value:
            legalEntity?.financialStatus?.revenue?.fixedAssets?.value?.value,
        },
        others: {
          checkbox: !!legalEntity?.financialStatus?.revenue?.others?.range,
          range: legalEntity?.financialStatus?.revenue?.others?.range,
          value: legalEntity?.financialStatus?.revenue?.others?.value?.value,
        },
      },
      monthlyLiabilities: {
        staff: {
          checkbox:
            !!legalEntity?.financialStatus?.monthlyLiabilities?.staff?.range,
          range: legalEntity?.financialStatus?.monthlyLiabilities?.staff?.range,
          value:
            legalEntity?.financialStatus?.monthlyLiabilities?.staff?.value
              ?.value,
        },
        rent: {
          checkbox:
            !!legalEntity?.financialStatus?.monthlyLiabilities?.rent?.range,
          range: legalEntity?.financialStatus?.monthlyLiabilities?.rent?.range,
          value:
            legalEntity?.financialStatus?.monthlyLiabilities?.rent?.value
              ?.value,
        },
        materialCosts: {
          checkbox:
            !!legalEntity?.financialStatus?.monthlyLiabilities?.materialCosts
              ?.range,
          range:
            legalEntity?.financialStatus?.monthlyLiabilities?.materialCosts
              ?.range,
          value:
            legalEntity?.financialStatus?.monthlyLiabilities?.materialCosts
              ?.value?.value,
        },
        insurance: {
          checkbox:
            !!legalEntity?.financialStatus?.monthlyLiabilities?.insurance
              ?.range,
          range:
            legalEntity?.financialStatus?.monthlyLiabilities?.insurance?.range,
          value:
            legalEntity?.financialStatus?.monthlyLiabilities?.insurance?.value
              ?.value,
        },
        other: {
          checkbox:
            !!legalEntity?.financialStatus?.monthlyLiabilities?.other?.range,
          range: legalEntity?.financialStatus?.monthlyLiabilities?.other?.range,
          value:
            legalEntity?.financialStatus?.monthlyLiabilities?.other?.value
              ?.value,
        },
      },
      monthlyLiquidity: {
        range: legalEntity?.financialStatus?.monthlyLiquidity?.range,
        value: legalEntity?.financialStatus?.monthlyLiquidity?.value?.value,
      },
      reserve: {
        needsReserve: convertBooleanToYesNoOrEmptyString(
          legalEntity?.financialStatus?.reserve?.needsReserve
        ),
        value: legalEntity?.financialStatus?.reserve?.value?.value,
        date: legalEntity?.financialStatus?.reserve?.date,
      },
    },
    legalEntityData: {
      companyName: legalEntity?.registration?.companyName,
      registrationType: legalEntity?.registration?.legalForm,
    },
    wealthAssessment: {
      netCapital: legalEntity?.wealthAssessment?.netCapital,
      exactNetCapital: legalEntity?.wealthAssessment?.exactNetCapital,
      financialAssets: {
        liquidity: {
          checkbox:
            !!legalEntity?.wealthAssessment?.financialAssets?.liquidity?.range,
          range:
            legalEntity?.wealthAssessment?.financialAssets?.liquidity?.range,
          value:
            legalEntity?.wealthAssessment?.financialAssets?.liquidity?.value
              ?.value,
        },
        securities: {
          checkbox:
            !!legalEntity?.wealthAssessment?.financialAssets?.securities?.range,
          range:
            legalEntity?.wealthAssessment?.financialAssets?.securities?.range,
          value:
            legalEntity?.wealthAssessment?.financialAssets?.securities?.value
              ?.value,
        },
        selfUsedProperties: {
          checkbox:
            !!legalEntity?.wealthAssessment?.financialAssets?.selfUsedProperties
              ?.range,
          range:
            legalEntity?.wealthAssessment?.financialAssets?.selfUsedProperties
              ?.range,
          value:
            legalEntity?.wealthAssessment?.financialAssets?.selfUsedProperties
              ?.value?.value,
        },
        movableProperty: {
          checkbox:
            !!legalEntity?.wealthAssessment?.financialAssets?.movableProperty
              ?.range,
          range:
            legalEntity?.wealthAssessment?.financialAssets?.movableProperty
              ?.range,
          value:
            legalEntity?.wealthAssessment?.financialAssets?.movableProperty
              ?.value?.value,
        },
        other: {
          checkbox:
            !!legalEntity?.wealthAssessment?.financialAssets?.other?.range,
          range: legalEntity?.wealthAssessment?.financialAssets?.other?.range,
          value:
            legalEntity?.wealthAssessment?.financialAssets?.other?.value?.value,
        },
      },
      totalAssets: legalEntity?.wealthAssessment?.totalAssets,
      sales: legalEntity?.wealthAssessment?.sales,
      ownFunds: legalEntity?.wealthAssessment?.ownFunds,
      wealthOrigin: legalEntity?.wealthAssessment?.wealthOrigin,
      wealthOriginDetail: legalEntity?.wealthAssessment?.wealthOriginDetail,
    },
    legalEntityContactData: {
      legalAddress: legalEntity?.legalEntityContactData?.legalAddress,
      hasSeparateContactAddress:
        !!legalEntity?.legalEntityContactData?.contactAddress,
      contactAddress: legalEntity?.legalEntityContactData?.contactAddress,
    },
    businessActivity: {
      registrationNumber: legalEntity?.registration?.registrationNumber,
      authorityOffice: legalEntity?.registration?.registryCourt,
      businessPurpose: legalEntity?.registration?.businessPurpose,
      dateOfFoundation: mapGraphQlDateToForm(
        legalEntity?.registration?.dateOfFoundation
      ),

      cashIntensive: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.isCashIntensive
      ),
      specialTransactions: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.doesSpecialTransactions
      ),
      dutyDisclosure: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.isDutyDisclosure
      ),
      governmentVerified: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.isVerifiedByGovernment
      ),
      publicCompany: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.isPublicCompany
      ),
      privateAssetManagement: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.doesPrivateAssetManagement
      ),
      publiclyTraceable: convertBooleanToYesNoOrEmptyString(
        legalEntity?.businessActivity?.isPubliclyTraceable
      ),

      transparencyRegisterObligation: convertBooleanToYesNoOrEmptyString(
        transparencyRegisterObligation
      ),
      transparencyRegisterObligationOption,
    },
    businessIndustry: {
      naceCode: legalEntity?.businessActivity?.naceCode,
    },
    taxInformation: {
      ...legalEntity?.taxInfo,
      residencies: legalEntity?.taxInfo?.residencies?.map((residency) => {
        residency.residencyCertificate?.map((document) => {
          return {
            ...document,
            uploadStatus: UploadStatus.DONE,
          };
        });
        return residency;
      }),
      fatca: {
        ...legalEntity?.taxInfo?.fatca,
        statusCertificate: legalEntity?.taxInfo?.fatca?.statusCertificate?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
      },
      crs: {
        ...legalEntity?.taxInfo?.crs,
        statusCertificate: legalEntity?.taxInfo?.crs?.statusCertificate?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
        status: mapCRSStatusFromGraphQLToForm(
          legalEntity?.taxInfo?.crs?.status
        ),
      },
    },
    bankAccounts: {
      accountHolderCompany: legalEntity?.registration?.companyName,
      iban: legalEntity?.bankAccounts?.reference?.iban,
      bic: legalEntity?.bankAccounts?.reference?.bic,
      nameOfBank: legalEntity?.bankAccounts?.reference?.nameOfBank,
      cityOfBank: legalEntity?.bankAccounts?.reference?.cityOfBank,
      usdCheckbox: !!legalEntity?.bankAccounts?.usdCurrency,
      usdAccountHolderCompany: legalEntity?.registration?.companyName,
      usdIban: legalEntity?.bankAccounts?.usdCurrency?.iban,
      usdBic: legalEntity?.bankAccounts?.usdCurrency?.bic,
      usdNameOfBank: legalEntity?.bankAccounts?.usdCurrency?.nameOfBank,
      usdCityOfBank: legalEntity?.bankAccounts?.usdCurrency?.cityOfBank,
    },
    legalRepresentationType: legalEntity?.legalRepresentationType,
    legalEntityAdditionalPersons:
      legalEntity?.legalEntityAdditionalPersons?.map((person) => ({
        id: person.id,
        isLegalRepresentative: person.isLegalRepresentative ? "yes" : "no",
        beneficialOwner: {
          isBeneficialOwner: person.isBeneficialOwner ? "yes" : "no",
          companyEquityPercentage: person.isBeneficialOwner
            ? convertIntIntoPercent(person.companyEquityPercentage)
            : null,
        },
        role: person.functionaryRole,
        personalData: {
          salutation: person.personalData?.salutation,
          professionalTitle:
            person.personalData?.professionalTitle || undefined,
          firstName: person.personalData?.firstName,
          familyName: person.personalData?.familyName,
          birthName: person.personalData?.birthName,
          dateOfBirth: mapGraphQlDateToForm(person.personalData?.dateOfBirth),
          cityOfBirth: person.personalData?.cityOfBirth,
          countryOfBirth: person.personalData?.countryOfBirth,
        },
        contactData: person.contactData,
        citizenship: {
          primaryCitizenship: person.personalData?.primaryCitizenship,
          additionalCitizenships:
            person.personalData?.additionalCitizenships?.map((value) => {
              return {
                value,
              };
            }),
        },
        taxInfo: person.taxResidencies,
        identityDocument: {
          number: person.identityDocument?.number,
          expirationDate: person.identityDocument?.expirationDate,
          documents: person.identityDocument?.documents,
        },
        wealthOrigin: person.wealthOrigin,
      })) || [],
    documents: {
      currentTradeRegister: {
        documents: legalEntity?.documents?.currentTradeRegister?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
      },
      shareholdersListNonAg: {
        documents: legalEntity?.documents?.shareholdersListNonAg?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
      },
      shareholdersListAg: {
        documents: legalEntity?.documents?.shareholdersListAg?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
      },
      furtherShareholders: {
        documents: legalEntity?.documents?.furtherShareholders?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
      },
      shareholdersContract: {
        documents: legalEntity?.documents?.shareholdersContract?.map(
          (document) => {
            return {
              ...document,
              uploadStatus: UploadStatus.DONE,
            };
          }
        ),
      },
      incorperation: {
        documents: legalEntity?.documents?.incorperation?.map((document) => {
          return {
            ...document,
            uploadStatus: UploadStatus.DONE,
          };
        }),
      },
      others: {
        documents: legalEntity?.documents?.others?.map((document) => {
          return {
            ...document,
            uploadStatus: UploadStatus.DONE,
          };
        }),
      },
    },
    profile: {
      ...mappedProfile,
      taxInfo: {
        ...profile?.regulatoryInfo?.taxInfo,
        facta: {
          status: profile?.regulatoryInfo?.taxInfo?.facta?.status,
          irsDocuments:
            profile?.regulatoryInfo?.taxInfo?.facta?.irsDocuments?.map(
              (document) => {
                return {
                  ...document,
                  uploadStatus: UploadStatus.DONE,
                };
              }
            ),
        },
      },
    },
  };
};

export const mapLegalEntityFromFormToGraphQl = (
  formData?: DeepPartial<LegalEntityContractAndRegulatoryData> | null
): [UpdateLegalEntityProfileVariables, UpdateProfileVariables] => {
  const legalEntityInput: UpdateLegalEntityProfileVariables["input"] = {};
  const profileInput: UpdateProfileVariables["input"] = {};

  const financialStatusInput: FinancialStatusInput = {};
  const registrationInput: LegalEntityRegistrationInput = {};
  const naturalPersonRegulatoryInfoInput: RegulatoryInfoInput = {};

  if (formData?.isLegalRepresentative) {
    legalEntityInput.isLegalRepresentative =
      formData.isLegalRepresentative === "yes";
  }

  if (formData?.isBeneficialOwner) {
    legalEntityInput.isBeneficialOwner = formData.isBeneficialOwner === "yes";
    legalEntityInput.companyEquityPercentage = mapCompanyPercentageToGraphQl(
      legalEntityInput.isBeneficialOwner,
      formData.companyEquityPercentage
    );
  }

  if (formData?.legalEntityData) {
    registrationInput.companyName = formData?.legalEntityData?.companyName;
    registrationInput.legalForm = formData?.legalEntityData.registrationType;

    legalEntityInput.registration = registrationInput;
  }
  if (formData?.businessActivity) {
    registrationInput.registrationNumber =
      formData?.businessActivity.registrationNumber;
    registrationInput.registryCourt =
      formData?.businessActivity.authorityOffice;
    registrationInput.dateOfFoundation = mapFormDateToGraphQl(
      formData?.businessActivity.dateOfFoundation
    );
    registrationInput.businessPurpose =
      formData.businessActivity.businessPurpose;

    legalEntityInput.registration = registrationInput;

    const transparencyRegisterObligation =
      mapRegisterObligationFromFormToGraphql(
        formData.businessActivity.transparencyRegisterObligation === "yes",
        formData.businessActivity.transparencyRegisterObligationOption
      );

    legalEntityInput.businessActivity = {
      isCashIntensive: formData.businessActivity.cashIntensive === "yes",
      doesSpecialTransactions:
        formData.businessActivity.specialTransactions === "yes",
      isDutyDisclosure: formData.businessActivity.dutyDisclosure === "yes",
      isVerifiedByGovernment:
        formData.businessActivity.governmentVerified === "yes",
      isPublicCompany: formData.businessActivity.publicCompany === "yes",
      doesPrivateAssetManagement:
        formData.businessActivity.privateAssetManagement === "yes",
      isPubliclyTraceable:
        formData.businessActivity.publiclyTraceable === "yes",
      transparencyRegisterObligation,
    };
  }
  if (formData?.businessIndustry) {
    legalEntityInput.businessActivity = {
      naceCode: formData.businessIndustry.naceCode,
    };
  }

  if (formData?.taxInformation) {
    legalEntityInput.taxInfo = {
      residencies: formData.taxInformation.residencies?.map((residency) => ({
        taxId: residency.taxId,
        country: residency.country,
        localTaxOffice: residency.localTaxOffice,
      })),
      fatca: {
        status: formData.taxInformation.fatca?.status,
        relevantCriterion: formData.taxInformation.fatca?.relevantCriterion,
      },
      crs: {
        status: formData.taxInformation.crs?.status,
        otherCharacteristic: formData.taxInformation.crs?.otherCharacteristic,
      },
    };
  }

  if (formData?.wealthAssessment) {
    const wealthAssessmentInput: WealthAssessmentInput = {
      netCapital: formData.wealthAssessment?.netCapital,
      // TODO: why do we need to send it as string?
      exactNetCapital: `${formData.wealthAssessment?.exactNetCapital}`,
      financialAssets: {
        liquidity: formData.wealthAssessment?.financialAssets?.liquidity
          ?.checkbox
          ? {
              range:
                formData.wealthAssessment?.financialAssets?.liquidity?.range,
              value:
                formData.wealthAssessment?.financialAssets?.liquidity?.range ===
                MoneyRangeLarge.MORE_THAN_1000000
                  ? {
                      value:
                        formData.wealthAssessment?.financialAssets?.liquidity
                          ?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        securities: formData.wealthAssessment?.financialAssets?.securities
          ?.checkbox
          ? {
              range:
                formData.wealthAssessment?.financialAssets?.securities?.range,
              value:
                formData.wealthAssessment?.financialAssets?.securities
                  ?.range === MoneyRangeLarge.MORE_THAN_1000000
                  ? {
                      value:
                        formData.wealthAssessment?.financialAssets?.securities
                          ?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        selfUsedProperties: formData.wealthAssessment?.financialAssets
          ?.selfUsedProperties?.checkbox
          ? {
              range:
                formData.wealthAssessment?.financialAssets?.selfUsedProperties
                  ?.range,
              value:
                formData.wealthAssessment?.financialAssets?.selfUsedProperties
                  ?.range === MoneyRangeLarge.MORE_THAN_1000000
                  ? {
                      value:
                        formData.wealthAssessment?.financialAssets
                          ?.selfUsedProperties?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        movableProperty: formData.wealthAssessment?.financialAssets
          ?.movableProperty?.checkbox
          ? {
              range:
                formData.wealthAssessment?.financialAssets?.movableProperty
                  ?.range,
              value:
                formData.wealthAssessment?.financialAssets?.movableProperty
                  ?.range === MoneyRangeLarge.MORE_THAN_1000000
                  ? {
                      value:
                        formData.wealthAssessment?.financialAssets
                          ?.movableProperty?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        other: formData.wealthAssessment?.financialAssets?.other?.checkbox
          ? {
              range: formData.wealthAssessment?.financialAssets?.other?.range,
              value:
                formData.wealthAssessment?.financialAssets?.other?.range ===
                MoneyRangeLarge.MORE_THAN_1000000
                  ? {
                      value:
                        formData.wealthAssessment?.financialAssets?.other
                          ?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
      },
      totalAssets: formData.wealthAssessment?.totalAssets,
      sales: formData.wealthAssessment?.sales,
      ownFunds: formData.wealthAssessment?.ownFunds,
      wealthOrigin: formData.wealthAssessment?.wealthOrigin,
      wealthOriginDetail: formData.wealthAssessment?.wealthOriginDetail,
    };

    legalEntityInput.wealthAssessment = wealthAssessmentInput;
  }

  if (formData?.employment) {
    // This needs to be updated in the users NP profile!
    if (formData.employment.pep) {
      const formPEP = formData.employment.pep;
      const pep: PEPInput = {
        relationship: null,
        role: null,
        status: null,
      };

      switch (formPEP.status) {
        case PEPStatus.YES_ME: {
          pep.role = formPEP.role;
          pep.status = formPEP.status;
          break;
        }
        case PEPStatus.YES_FAMILY_MEMBER: {
          pep.relationship = formPEP.relationship;
          pep.role = formPEP.role;
          pep.status = formPEP.status;
          break;
        }
        case PEPStatus.NO: {
          pep.status = formPEP.status;
        }
      }

      naturalPersonRegulatoryInfoInput.pep = pep;
    }

    profileInput.regulatoryInfo = naturalPersonRegulatoryInfoInput;
    legalEntityInput.countryOfOperations = formData.employment.country;
    legalEntityInput.functionaryRole = formData.employment.role;
  }

  if (formData?.taxInfo && profileInput.regulatoryInfo) {
    profileInput.regulatoryInfo.taxInfo = {
      residencies: formData.taxInfo.residencies?.map(
        (residency: TaxResidencyInput) => ({
          taxId: residency.taxId,
          country: residency.country,
          localTaxOffice: residency.localTaxOffice,
        })
      ),
      facta: {
        status: formData.taxInfo.facta?.status,
      },
    };
  }

  if (formData?.legalEntityContactData) {
    legalEntityInput.legalEntityContactData = {
      legalAddress: formData.legalEntityContactData.legalAddress,
      contactAddress: formData.legalEntityContactData.hasSeparateContactAddress
        ? formData.legalEntityContactData.contactAddress
        : null,
    };
  }

  if (formData?.financialStatus) {
    const { revenue, monthlyLiabilities, monthlyLiquidity, reserve } =
      formData?.financialStatus;

    if (revenue) {
      financialStatusInput.revenue = {
        commercialActivities: revenue.commercialActivities?.checkbox
          ? {
              range: revenue.commercialActivities?.range,
              value:
                revenue.commercialActivities?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: revenue.commercialActivities?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        rent: revenue.rent?.checkbox
          ? {
              range: revenue.rent?.range,
              value:
                revenue.rent?.range === MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: revenue.rent?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        fixedAssets: revenue.fixedAssets?.checkbox
          ? {
              range: revenue.fixedAssets?.range,
              value:
                revenue.fixedAssets?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: revenue.fixedAssets?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        others: revenue.others?.checkbox
          ? {
              range: revenue.others?.range,
              value:
                revenue.others?.range === MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: revenue.others?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
      };
    }

    if (monthlyLiabilities) {
      financialStatusInput.monthlyLiabilities = {
        staff: monthlyLiabilities.staff?.checkbox
          ? {
              range: monthlyLiabilities.staff?.range,
              value:
                monthlyLiabilities.staff?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: monthlyLiabilities.staff?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        rent: monthlyLiabilities.rent?.checkbox
          ? {
              range: monthlyLiabilities.rent?.range,
              value:
                monthlyLiabilities.rent?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: monthlyLiabilities.rent?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        materialCosts: monthlyLiabilities.materialCosts?.checkbox
          ? {
              range: monthlyLiabilities.materialCosts?.range,
              value:
                monthlyLiabilities.materialCosts?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: monthlyLiabilities.materialCosts?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        insurance: monthlyLiabilities.insurance?.checkbox
          ? {
              range: monthlyLiabilities.insurance?.range,
              value:
                monthlyLiabilities.insurance?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: monthlyLiabilities.insurance?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
        other: monthlyLiabilities.other?.checkbox
          ? {
              range: monthlyLiabilities.other?.range,
              value:
                monthlyLiabilities.other?.range ===
                MoneyRangeTransactions.MORE_THAN_50000
                  ? {
                      value: monthlyLiabilities.other?.value,
                      currency: Currency.EUR,
                    }
                  : null,
            }
          : null,
      };
    }

    financialStatusInput.monthlyLiquidity = monthlyLiquidity?.range
      ? {
          range: monthlyLiquidity?.range,
          value:
            monthlyLiquidity?.range === MoneyRangeTransactions.MORE_THAN_50000
              ? {
                  value: monthlyLiquidity?.value,
                  currency: Currency.EUR,
                }
              : null,
        }
      : null;

    if (reserve) {
      financialStatusInput.reserve = {
        needsReserve: reserve?.needsReserve === "yes",
        value:
          reserve?.needsReserve === "yes"
            ? {
                value: reserve?.value,
                currency: Currency.EUR,
              }
            : null,
        date: reserve?.date ? reserve?.date : null,
      };
    }

    legalEntityInput.financialStatus = financialStatusInput;
  }

  if (formData?.bankAccounts) {
    const reference: LegalEntityBankAccountInput = {
      type: BankAccountType.REFERENCE,
      accountHolderCompany: formData.bankAccounts?.accountHolderCompany,
      iban: formData.bankAccounts?.iban,
      bic: formData.bankAccounts?.bic,
      nameOfBank: formData.bankAccounts?.nameOfBank,
      cityOfBank: formData.bankAccounts?.cityOfBank,
    };

    const usdCurrency: LegalEntityBankAccountInput = {
      type: BankAccountType.USD_CURRENCY_ACCOUNT,
      accountHolderCompany: formData.bankAccounts?.usdAccountHolderCompany,
      iban: formData.bankAccounts?.usdIban,
      bic: formData.bankAccounts?.usdBic,
      nameOfBank: formData.bankAccounts?.usdNameOfBank,
      cityOfBank: formData.bankAccounts?.usdCityOfBank,
    };

    const bankAccounts: BankAccountSetInput = {
      reference,
      usdCurrency: formData.bankAccounts.usdCheckbox ? usdCurrency : null,
    };

    legalEntityInput.bankAccounts = bankAccounts;
  }

  if (formData?.legalRepresentationType) {
    legalEntityInput.legalRepresentationType = formData.legalRepresentationType;
  }

  return [{ input: legalEntityInput }, { input: profileInput }];
};

type ProfileRegulatoryData = LegalEntitiesQueryType;

export const getLegalEntityRegulatoryData = typedCreateAsyncThunk(
  "contractAndRegulatoryData/getLegalEntityRegulatoryData",
  async (
    _,
    { extra }
  ): Promise<
    FormDefaultData<
      LegalEntityContractAndRegulatoryData & {
        profile: ContractAndRegulatoryData;
      } & { powerOfAttorney: PowerOfAttorney }
    >
  > => {
    const result = await extra.client.query<ProfileRegulatoryData>({
      query: LegalEntitiesQuery,
    });

    return mapLegalEntityFromGraphQlToForm(
      result.data?.legalEntity,
      result.data?.profile,
      result.data?.alternativeInvestmentMandateContract
    );
  }
);

export const updateLegalEntityProfile = typedCreateAsyncThunk(
  "contractAndRegulatoryData/updateLegalEntityProfile",
  async (
    payload: DeepPartial<LegalEntityContractAndRegulatoryData>,
    { extra, getState }
  ): Promise<DeepPartial<LegalEntityContractAndRegulatoryData>> => {
    const [legalEntityVariables, profileVariables] =
      mapLegalEntityFromFormToGraphQl(payload);

    const updateLegalEntityPromise = extra.client.mutate<
      UpdateLegalEntityProfileType,
      UpdateLegalEntityProfileVariables
    >({
      mutation: UpdateLegalEntityProfile,
      variables: legalEntityVariables,
    });

    const updateProfilePromise = extra.client.mutate<
      UpdateProfile,
      UpdateProfileVariables
    >({
      mutation: updateProfileMutation,
      variables: profileVariables,
    });

    const [legalEntityRes] = await Promise.all([
      updateLegalEntityPromise,
      updateProfilePromise,
    ]);

    const { alternativeInvestments } = getState() as AppState;

    return {
      ...payload,
      legalEntityId:
        legalEntityRes.data?.updateLegalEntityProfile.legalEntityId,
      id: alternativeInvestments.regulatoryData.personData.id || undefined,
    };
  }
);

export const upsertLegalEntityAdditionalPerson = typedCreateAsyncThunk(
  "contractAndRegulatoryData/upsertLegalEntityAdditionalPerson",
  async (
    payload: AdditionalPersonProps & { legalEntityId: LegalEntityId },
    { extra }
  ) => {
    const isBeneficialOwner =
      payload.beneficialOwner?.isBeneficialOwner === "yes";

    const isLegalRepresentative = payload.isLegalRepresentative === "yes";

    const companyEquityPercentage = isBeneficialOwner
      ? payload.beneficialOwner?.companyEquityPercentage
      : undefined;

    const mappedPayload: LegalEntityAdditionalPersonInput = {
      personId: payload?.id, // must be optional because it will be created by the responsible backend service and is initially undefined
      isLegalRepresentative: payload.isLegalRepresentative === "yes",
      isBeneficialOwner,
      companyEquityPercentage: mapCompanyPercentageToGraphQl(
        isBeneficialOwner,
        companyEquityPercentage
      ),
      functionaryRole: payload.role,
      personalData: {
        ...payload.personalData,
        dateOfBirth: mapFormDateToGraphQl(payload.personalData.dateOfBirth),
        primaryCitizenship: payload.citizenship.primaryCitizenship,
        additionalCitizenships:
          (payload.citizenship.additionalCitizenships?.map(
            (country) => country.value
          ) as string[]) || [],
      },
      contactData: payload.contactData,
      // TODO: this needs to be adjusted if we change LS 18
      // so that we can add more than one tax residency!
      taxResidencies: payload.taxInfo ? payload.taxInfo : [],
      wealthOrigin: payload.wealthOrigin,
    };

    const result = await extra.client.mutate<
      UpsertLegalEntityAdditionalPersonMutType,
      UpsertLegalEntityAdditionalPersonVariables
    >({
      mutation: UpsertLegalEntityAdditionalPerson,
      variables: { legalEntityId: payload.legalEntityId, input: mappedPayload },
    });

    const personId = result.data?.upsertLegalEntityAdditionalPerson?.data;

    const actionPayload = {
      ...payload,
      citizenship: {
        primaryCitizenship: payload.citizenship.primaryCitizenship,
        additionalCitizenships: payload.citizenship.additionalCitizenships,
      },
      id: personId,
    };

    if (isLegalRepresentative) {
      return actionPayload;
    }

    const identityDocumentPayload: IdentityDocumentInput = {
      expirationDate: payload.identityDocument.expirationDate || "",
      documentIds:
        payload.identityDocument.documents?.map((document) => document.id) ||
        [],
      number: payload.identityDocument.number || "",
    };

    if (personId) {
      await extra.client.mutate<
        UpdateIdentityDocumentType,
        UpdateIdentityDocumentVariables
      >({
        mutation: UpdateIdentityDocument,
        variables: { personId, identityDocument: identityDocumentPayload },
      });
    }

    return actionPayload;
  }
);

export const deleteLegalEntityAdditionalPerson = typedCreateAsyncThunk(
  "contractAndRegulatoryData/deleteLegalEntityAdditionalPerson",
  async (
    payload: {
      personId: string;
      legalEntityId: string;
    },
    { extra }
  ) => {
    const { personId, legalEntityId } = payload;

    await extra.client.mutate<
      DeleteLegalEntityAdditionalPersonMutType,
      DeleteLegalEntityAdditionalPersonVariables
    >({
      mutation: DeleteLegalEntityAdditionalPerson,
      variables: { legalEntityId, personId },
    });

    return payload;
  }
);

export const updateDocumentsData = createAction<{
  changedDocuments: DocumentChange[];
  documentCategory: DocumentCategoryNames;
}>("contractAndRegulatoryData/updateDocumentsData");

export const updateTaxInformationResidencyDocuments = createAction<{
  changedDocuments: DocumentChange[];
  residencyIndex: number;
}>("contractAndRegulatoryData/updateTaxInformationResidencyDocuments");

export const updateTaxInformationFatcaStatusDocuments = createAction<
  DocumentChange[]
>("contractAndRegulatoryData/updateTaxInformationFatcaStatusDocuments");

export const updateTaxInformationCrsStatusDocuments = createAction<
  DocumentChange[]
>("contractAndRegulatoryData/updateTaxInformationCrsStatusDocuments");

export const updateEmploymentAndPersonalIrsDocumentData = createAction<
  DocumentChange[]
>("contractAndRegulatoryData/updateEmploymentAndTaxData");

export const updateIdentityDocumentsData = createAction<{
  changedDocuments: DocumentChange[];
}>("contractAndRegulatoryData/updateIdentityDocumentsData");
