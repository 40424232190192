import { TFunction } from "i18next";
import * as yup from "yup";
import {
  BalanceSheetRange,
  BalanceSheetRangeLarge,
  BalanceSheetRangeSmall,
  LegalEntityWealthOrigin as WealthOriginType,
  MoneyRangeLarge,
  MoneyRangeNetCapital,
} from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.legalEntityWealthAssessment";

export const wealthAssessmentCheckboxNames: Array<keyof FinancialAssets> = [
  "liquidity",
  "securities",
  "selfUsedProperties",
  "movableProperty",
  "other",
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const largeRangeCheckboxValidationSchema = (
  t: TFunction,
  checkboxName: string
) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeLarge>()
      .oneOf(
        Object.values(MoneyRangeLarge),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup
          .mixed<MoneyRangeLarge>()
          .required(t(`${checkboxName}.range.required`)),
      }),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeLarge) =>
          money === MoneyRangeLarge.MORE_THAN_1000000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(`${checkboxName}.value.minimum`),
            (value) => (value ?? 0) > 1000000
          )
          .required(t(`${checkboxName}.value.required`)),
      }),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const financialAssetsValidationSchema = (t: TFunction) => {
  return yup
    .object({
      liquidity: largeRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.financialAssets.liquidity`
      ),
      securities: largeRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.financialAssets.securities`
      ),
      selfUsedProperties: largeRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.financialAssets.selfUsedProperties`
      ),
      movableProperty: largeRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.financialAssets.movableProperty`
      ),
      other: largeRangeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.financialAssets.other`
      ),
    })
    .test("financialAssets", "", (obj, testContext) => {
      if (
        obj.liquidity.checkbox ||
        obj.securities.checkbox ||
        obj.selfUsedProperties.checkbox ||
        obj.movableProperty.checkbox ||
        obj.other.checkbox
      ) {
        return true;
      }

      return testContext.createError({
        message: t(`${translationPrefix}.fields.financialAssets.error`),
        path: `${testContext.path}`,
      });
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wealthOriginValidationSchema = (t: TFunction) => {
  return yup
    .mixed<WealthOriginType>()
    .required(t(`${translationPrefix}.fields.wealthOrigin.required`))
    .oneOf(
      Object.values(WealthOriginType),
      t(`${translationPrefix}.fields.wealthOrigin.required`)
    )
    .label(t(`${translationPrefix}.fields.wealthOrigin.label`));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup.object({
    wealthAssessment: yup.object({
      netCapital: yup
        .mixed<MoneyRangeNetCapital>()
        .required(t(`${translationPrefix}.fields.netCapital.required`))
        .oneOf(
          Object.values(MoneyRangeNetCapital),
          t(`${translationPrefix}.fields.netCapital.required`)
        )
        .label(t(`${translationPrefix}.fields.netCapital.label`)),
      exactNetCapital: yup
        .mixed()
        .transform((value) =>
          typeof value !== "number"
            ? Number(value.replace(/[.,\s]/g, ""))
            : value
        )
        .when("netCapital", {
          is: (money: MoneyRangeNetCapital) =>
            money === MoneyRangeNetCapital.MORE_THAN_500000,
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .test(
              "minimum",
              t(`${translationPrefix}.fields.exactNetCapital.minimum`),
              (value) => (value ?? 0) > 500000
            )
            .required(
              t(`${translationPrefix}.fields.exactNetCapital.required`)
            ),
        }),
      wealthOrigin: wealthOriginValidationSchema(t),
      wealthOriginDetail: yup.string().when("wealthOrigin", {
        is: (wealthOrigin: WealthOriginType) =>
          wealthOrigin === WealthOriginType.SALE_OF_BUSINESS,
        then: (schema) => schema.max(1000, "max chars").required(),
        otherwise: (schema) => schema.notRequired(),
      }),
      financialAssets: financialAssetsValidationSchema(t),
      totalAssets: yup
        .mixed<BalanceSheetRange>()
        .oneOf(
          Object.values(BalanceSheetRange),
          t(`${translationPrefix}.fields.totalAssets.required`)
        )
        .required()
        .label(t(`${translationPrefix}.fields.totalAssets.label`)),
      sales: yup
        .mixed<BalanceSheetRangeLarge>()
        .oneOf(
          Object.values(BalanceSheetRangeLarge),
          t(`${translationPrefix}.fields.sales.required`)
        )
        .required()
        .label(t(`${translationPrefix}.fields.sales.label`)),
      ownFunds: yup
        .mixed<BalanceSheetRangeSmall>()
        .oneOf(
          Object.values(BalanceSheetRangeSmall),
          t(`${translationPrefix}.fields.ownFunds.required`)
        )
        .required()
        .label(t(`${translationPrefix}.fields.ownFunds.label`)),
    }),
  });

export type FinancialAssets = yup.InferType<
  ReturnType<typeof financialAssetsValidationSchema>
>;

export type WealthAssessment = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export function validate(wealthAssessment: WealthAssessment): any {
  const t = (k: any) => k;
  return validationSchema(t).validateSync(wealthAssessment);
}
