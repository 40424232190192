import { ChangeEvent, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox, Stack, Text, TextAreaInput } from "@finvia/money-ui";
import { typedUseSelector } from "store";
import { ContractType } from "generated/globalTypes";

import {
  CheckboxWrapper,
  AdditionalText,
} from "features/alternative-investments/questionnaires/mandateContract/common/DeclarationForm/Declarations.styled";
import { FundDeclarationsForm } from "../../fundDeclarationsValidations";
import {
  UnionFinancialInstruments,
  UnionUnderSupervision,
  UnionGovernmentFunction,
  UnionSecurizationOfLiabilities,
  UnionLargeCompany,
  UnionExtensiveExperience,
  ExtensiveExperience,
} from "./Professional.types";
import {
  underSupervisionFields,
  financialInstrumentsFields,
  governmentFunctionFields,
  securizationOfLiabilitiesFields,
  largeCompanyFields,
  sufficientExperienceAndKnowledgeConfirmedFields,
  extensiveExperienceFields,
  hasAnyNested,
} from "./Professional.utils";
import { SubCheckboxWrapper } from "../../FundDeclarations.styled";
import { CheckboxGroup } from "../CheckboxGroup";

const translationPrefixNaturalPerson =
  "q-ai.opportunities.form.fundDeclarations.fields.contract.professionalInvestor.naturalPerson";
const translationPrefixLegalEntity =
  "q-ai.opportunities.form.fundDeclarations.fields.contract.professionalInvestor.legalEntity";

const Professional = (): JSX.Element => {
  const { t } = useTranslation();
  const { watch, register, errors, trigger, formState, setValue } =
    useFormContext<FundDeclarationsForm>();
  const isLegalEntity = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData.personData.contractType ===
      ContractType.LEGAL_ENTITY
  );

  const isSufficientExperienceAndKnowledgeConfirmed = watch(
    "declarations.professionalInvestor.isSufficientExperienceAndKnowledgeConfirmed"
  );

  const watchExtensiveExperience: ExtensiveExperience = watch(
    "declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience"
  );

  const baseI18nPrefix = isLegalEntity
    ? translationPrefixLegalEntity
    : translationPrefixNaturalPerson;

  useEffect(() => {
    if (
      formState.dirtyFields.declarations?.professionalInvestor
        ?.professionalInvestorClassification
    ) {
      trigger(
        "declarations.professionalInvestor.professionalInvestorClassification.isConfirmed"
      );
    }
  }, [formState, trigger]);

  const onExtensiveExperienceParentClick = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (
      e.currentTarget.checked === false &&
      hasAnyNested<UnionExtensiveExperience>(watchExtensiveExperience).length
    ) {
      extensiveExperienceFields.forEach((field) => {
        setValue(
          `declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.${field}`,
          false
        );
      });

      trigger(
        "declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.isConfirmed"
      );
    }
  };

  const onExtensiveExperienceChildClick = () => {
    if (watchExtensiveExperience.isConfirmed === false) {
      setValue(
        "declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.isConfirmed",
        true
      );
    }

    trigger(
      "declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.isConfirmed"
    );
  };

  return (
    <>
      <CheckboxWrapper>
        <Stack>
          <Checkbox
            ref={register}
            name="declarations.professionalInvestor.isProfessionalInvestor"
            label={t(`${baseI18nPrefix}.isProfessionalInvestor.label`)}
          />

          <AdditionalText>
            <Stack>
              <Text>
                {t(`${baseI18nPrefix}.isProfessionalInvestor.description`)}
              </Text>

              {errors.declarations?.professionalInvestor
                ?.isProfessionalInvestor && (
                <Text size={1} variant="error">
                  {
                    errors.declarations?.professionalInvestor
                      ?.isProfessionalInvestor?.message
                  }
                </Text>
              )}
            </Stack>
          </AdditionalText>
        </Stack>
      </CheckboxWrapper>

      <CheckboxWrapper>
        <Stack gutter="kilo">
          <Stack>
            <Checkbox
              onChange={() =>
                trigger(sufficientExperienceAndKnowledgeConfirmedFields)
              }
              ref={register}
              name="declarations.professionalInvestor.isSufficientExperienceAndKnowledgeConfirmed"
              label={t(
                `${baseI18nPrefix}.isSufficientExperienceAndKnowledgeConfirmed.label`
              )}
              helperText={t(
                `${baseI18nPrefix}.isSufficientExperienceAndKnowledgeConfirmed.tooltip`
              )}
            />

            <AdditionalText>
              <Stack>
                <Text>
                  {t(
                    `${baseI18nPrefix}.isSufficientExperienceAndKnowledgeConfirmed.description`
                  )}
                </Text>

                {errors.declarations?.professionalInvestor
                  ?.isSufficientExperienceAndKnowledgeConfirmed && (
                  <Text size={1} variant="error">
                    {
                      errors.declarations?.professionalInvestor
                        ?.isSufficientExperienceAndKnowledgeConfirmed?.message
                    }
                  </Text>
                )}
              </Stack>
            </AdditionalText>
          </Stack>

          {isSufficientExperienceAndKnowledgeConfirmed && !isLegalEntity && (
            <SubCheckboxWrapper>
              <Stack gutter="mega">
                {sufficientExperienceAndKnowledgeConfirmedFields.map(
                  (field) => (
                    <Checkbox
                      onChange={() =>
                        trigger(
                          "declarations.professionalInvestor.isSufficientExperienceAndKnowledgeConfirmed"
                        )
                      }
                      key={field}
                      ref={register}
                      name={`declarations.professionalInvestor.${field}`}
                      label={t(
                        `${translationPrefixNaturalPerson}.${field}.label`
                      )}
                    />
                  )
                )}
              </Stack>
            </SubCheckboxWrapper>
          )}
        </Stack>
      </CheckboxWrapper>

      {isLegalEntity && (
        <>
          <CheckboxWrapper>
            <Stack gutter="giga">
              <Stack>
                <Checkbox
                  ref={register}
                  name="declarations.professionalInvestor.professionalInvestorClassification.isConfirmed"
                  label={t(
                    `${translationPrefixLegalEntity}.professionalInvestorClassification.label`
                  )}
                  helperText={t(
                    `${translationPrefixLegalEntity}.professionalInvestorClassification.tooltip`
                  )}
                />

                <AdditionalText>
                  <Stack>
                    <Text>
                      {t(
                        `${translationPrefixLegalEntity}.professionalInvestorClassification.description`
                      )}
                    </Text>

                    {errors.declarations?.professionalInvestor
                      ?.professionalInvestorClassification?.isConfirmed && (
                      <Text size={1} variant="error">
                        {
                          errors.declarations?.professionalInvestor
                            ?.professionalInvestorClassification?.isConfirmed
                            ?.message
                        }
                      </Text>
                    )}
                  </Stack>
                </AdditionalText>
              </Stack>
              <SubCheckboxWrapper>
                <Stack gutter="giga">
                  <CheckboxGroup<UnionFinancialInstruments>
                    fields={financialInstrumentsFields}
                    groupName="financialInstruments"
                    baseFieldName="declarations.professionalInvestor.professionalInvestorClassification.financialInstruments"
                    langPrefix={translationPrefixLegalEntity}
                  />

                  <CheckboxGroup<UnionUnderSupervision>
                    fields={underSupervisionFields}
                    groupName="underSupervision"
                    baseFieldName="declarations.professionalInvestor.professionalInvestorClassification.underSupervision"
                    langPrefix={translationPrefixLegalEntity}
                  />

                  <CheckboxGroup<UnionGovernmentFunction>
                    fields={governmentFunctionFields}
                    groupName="governmentFunction"
                    baseFieldName="declarations.professionalInvestor.professionalInvestorClassification.governmentFunction"
                    langPrefix={translationPrefixLegalEntity}
                  />

                  <CheckboxGroup<UnionSecurizationOfLiabilities>
                    fields={securizationOfLiabilitiesFields}
                    groupName="securizationOfLiabilities"
                    baseFieldName="declarations.professionalInvestor.professionalInvestorClassification.securizationOfLiabilities"
                    langPrefix={translationPrefixLegalEntity}
                  />

                  <CheckboxGroup<UnionLargeCompany>
                    fields={largeCompanyFields}
                    groupName="largeCompany"
                    baseFieldName="declarations.professionalInvestor.professionalInvestorClassification.largeCompany"
                    langPrefix={translationPrefixLegalEntity}
                  />

                  <Stack gutter="kilo">
                    <Stack>
                      <Checkbox
                        onChange={onExtensiveExperienceParentClick}
                        ref={register}
                        name="declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.isConfirmed"
                        label={t(
                          `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.label`
                        )}
                      />
                      <AdditionalText>
                        <Text>
                          {t(
                            `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.description`
                          )}
                        </Text>
                        {errors.declarations?.professionalInvestor
                          ?.professionalInvestorClassification
                          ?.extensiveExperience?.isConfirmed && (
                          <Text size={1} variant="error">
                            {
                              errors.declarations?.professionalInvestor
                                ?.professionalInvestorClassification
                                ?.extensiveExperience?.isConfirmed?.message
                            }
                          </Text>
                        )}
                      </AdditionalText>
                    </Stack>
                    <SubCheckboxWrapper>
                      <Stack gutter="byte">
                        <Checkbox
                          onChange={onExtensiveExperienceChildClick}
                          ref={register}
                          name="declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.tenTransactionsInTheLastFourQuarters"
                          label={t(
                            `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.fields.tenTransactionsInTheLastFourQuarters.label`
                          )}
                          helperText={t(
                            `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.fields.tenTransactionsInTheLastFourQuarters.tooltip`
                          )}
                        />
                        {watchExtensiveExperience.tenTransactionsInTheLastFourQuarters && (
                          <SubCheckboxWrapper>
                            <TextAreaInput
                              crossOrigin
                              ref={register}
                              name="declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.tenTransactionsInTheLastFourQuartersExample"
                              label={t(
                                `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.fields.tenTransactionsInTheLastFourQuarters.example.label`
                              )}
                            />
                          </SubCheckboxWrapper>
                        )}
                        <Checkbox
                          onChange={onExtensiveExperienceChildClick}
                          ref={register}
                          name="declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.moreThan500k"
                          label={t(
                            `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.fields.moreThan500k.label`
                          )}
                        />
                        <Checkbox
                          onChange={onExtensiveExperienceChildClick}
                          ref={register}
                          name="declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.jobInCapitalMarket"
                          label={t(
                            `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.fields.jobInCapitalMarket.label`
                          )}
                        />
                        {Boolean(
                          watchExtensiveExperience.jobInCapitalMarket
                        ) && (
                          <SubCheckboxWrapper>
                            <TextAreaInput
                              crossOrigin
                              ref={register}
                              name="declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.jobInCapitalMarketDetails"
                              label={t(
                                `${translationPrefixLegalEntity}.professionalInvestorClassification.fields.extensiveExperience.fields.jobInCapitalMarket.details.label`
                              )}
                            />
                          </SubCheckboxWrapper>
                        )}
                      </Stack>
                    </SubCheckboxWrapper>
                  </Stack>
                </Stack>
              </SubCheckboxWrapper>
            </Stack>
          </CheckboxWrapper>
        </>
      )}
    </>
  );
};

export default Professional;
