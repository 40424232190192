import { Loading } from "@finvia/component-library";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { typedUseSelector } from "../../../../../store/index";
import QuestionnaireController from "../../../components/QuestionnaireController";
import { LegalEntityRegulatoryDataFormStep } from "../../../formSteps";
import ExperienceAssetClasses from "../common/ExperienceAssets/ExperienceAssets";
import { ExperienceCurrencies } from "../common/ExperienceCurrencies";
import PersonalData from "../common/PersonalDataForm/PersonalDataForm";
import ContactData from "../common/PersonContactDataForm/ContactDataForm";
import { ROUTES } from "../../../../../routes/routes";
import AddPersonsPage from "./AddPersonsPage/AddPersonsPage";
import { BankAccounts } from "./BankAccounts";
import BeneficialOwner from "./BeneficialOwner/BeneficialOwnerForm";
import BusinessActivity from "./BusinessActivity/BusinessActivityForm";
import BusinessIndustryTypeScreen from "./BusinessActivity/BusinessIndustryForm/BusinessIndustryForm";
import DocumentsUploadForm from "./DocumentsUpload/DocumentsUploadForm";
import Employment from "./Employment/Employment";
import LegalEntityContactData from "./ContactData/ContactData";
import LegalEntityName from "./LegalEntityName/LegalEntityNameForm";
import { deriveFormAccess } from "./legalEntityValidations";
import LegalRepresentative from "./LegalRepresentative/LegalRepresentativeForm";
import Liabilities from "./Liabilities/LiabilitiesForm";
import RevenueForm from "./Revenue/RevenueForm";
import TaxInformation from "./TaxInformation/TaxInformationForm";
import WealthAssessmentForm from "./WealthAssessment/WealthAssessmentForm";

const ContractAndRegulatoryDataQuestionnaire: React.FC = () => {
  const [atMostAccessibleStep, setAtMostAccessibleStep] = useState<
    LegalEntityRegulatoryDataFormStep | undefined
  >(undefined);

  const history = useHistory();

  const { legalEntityData, personData, isComplete } = typedUseSelector(
    (state) => state.alternativeInvestments.regulatoryData
  );

  useEffect(() => {
    setAtMostAccessibleStep(
      deriveFormAccess({
        legalEntity: legalEntityData,
        naturalPerson: personData,
      }).atMostAccessibleStep
    );
  }, [legalEntityData, personData]);

  if (!atMostAccessibleStep) {
    return <Loading />;
  }

  return (
    <QuestionnaireController
      atMostAccessibleStep={atMostAccessibleStep}
      onBack={() => history.push(ROUTES.questionnaires.ai.root)}
      onComplete={() => history.push(ROUTES.questionnaires.ai.root)}
      isComplete={isComplete}
      stepGroups={[
        {
          label: "Vertragstyp",
          steps: [
            {
              id: LegalEntityRegulatoryDataFormStep.ContractType,
              component: () => (
                <Redirect to={ROUTES.questionnaires.ai.regulatory.root} />
              ),
            },
          ],
        },
        {
          label: "Ihre Angaben",
          steps: [
            {
              id: LegalEntityRegulatoryDataFormStep.LegalRepresentative,
              component: LegalRepresentative,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.UltimateBeneficiary,
              component: BeneficialOwner,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.PersonalData,
              component: PersonalData,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.ContactData,
              component: ContactData,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.Employment,
              component: Employment,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.ExperienceAssetClasses,
              component: ExperienceAssetClasses,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.ExperienceCurrencies,
              component: ExperienceCurrencies,
            },
          ],
        },
        {
          label: "Ihr Unternehmen",
          steps: [
            {
              id: LegalEntityRegulatoryDataFormStep.LegalEntityName,
              component: LegalEntityName,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.WealthAssessment,
              component: WealthAssessmentForm,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.BusinessActivity,
              component: BusinessActivity,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.BusinessIndustry,
              component: BusinessIndustryTypeScreen,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.LegalEntityContactData,
              component: LegalEntityContactData,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.Revenue,
              component: RevenueForm,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.Liabilities,
              component: Liabilities,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.Tax,
              component: TaxInformation,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.Documents,
              component: DocumentsUploadForm,
            },
            {
              id: LegalEntityRegulatoryDataFormStep.BankAccounts,
              component: BankAccounts,
            },
          ],
        },
        {
          label: "Gesetzliche Vertreter und UBOs",
          steps: [
            {
              id: LegalEntityRegulatoryDataFormStep.AddLegalEntityPersons,
              component: AddPersonsPage,
            },
          ],
        },
      ]}
    />
  );
};

export default ContractAndRegulatoryDataQuestionnaire;
