import { StyleProps } from "@finvia/money-ui/dist/esm/types";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const amountWrapperStyles = () => css`
  ${({ theme: { tokens } }) => css`
    background-color: ${tokens.colors.petrol[600]};
    max-width: 300px;
    padding: ${tokens.sizes.spacings.kilo} ${tokens.sizes.spacings.tera};
  `};
`;

const lockContentWrapperStyles = () => css`
  position: relative;
  height: 100%;
`;

const dividerStyles = ({ theme }: StyleProps) => css`
  position: absolute;
  top: -16px;
  left: 0;
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${theme.tokens.colors.neutral[500]};
  margin-block-start: 0;
  margin-block-end: 0;

  ${theme.utils.viewport.md`
    width: 1px;
    height: 100%;
    top: 0;
    left: -11px;
  `}
`;

const investmentAmountWrapperStyles = () => css`
  input {
    height: 47px;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;

  span {
    text-align: center;
  }
`;

export const InvestmentAmountWrapper = styled.div(
  investmentAmountWrapperStyles
);
export const LockContentWrapper = styled.div(lockContentWrapperStyles);
export const Divider = styled.hr<StyleProps>(dividerStyles);
export const AmountWrapper = styled.div(amountWrapperStyles);
