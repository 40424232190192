import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
});

interface Props {
  message?: string;
  onClose: () => void;
}
const InlineErrorMessage: React.FC<Props> = ({ message, onClose }) => {
  const classes = useStyles();

  return (
    <Alert
      severity="error"
      onClose={onClose}
      className={classes.root}
      data-test="error-message"
    >
      {message}
    </Alert>
  );
};

export default InlineErrorMessage;
