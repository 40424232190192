import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LegalEntityAdditionalPersonFormData } from "../";
import ResidencyField from "./ResidencyField";
import { Button, Box } from "@finvia/money-ui";
import { filterCountries } from "../../../naturalPerson/EmploymentAndTaxForm/helper";
import { countries } from "utils/options/countries";
import { useEffect } from "react";

const translationPrefix = "q-ai.opportunities.form.additionalPersons";

const ResidencyFields = (): JSX.Element => {
  const formMethods = useFormContext<LegalEntityAdditionalPersonFormData>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: "taxInfo",
  });
  const watchCountry: LegalEntityAdditionalPersonFormData["taxInfo"] =
    formMethods.watch("taxInfo");

  const maxAmountOfResidencies = 3;

  return (
    <>
      {fields.map((item, index) => {
        const selectedCountries = !watchCountry
          ? []
          : watchCountry
              .map((field) => field.country)
              .filter((country, _, arr) => country !== arr[index]);

        const countriesList = filterCountries({
          countriesToRemove: selectedCountries,
          countriesList: countries(t),
        });

        if (watchCountry?.[index]?.country === "DE") {
          formMethods.trigger(`taxInfo[${index}].taxId`);
        }

        return (
          <ResidencyField
            key={item.id}
            item={item}
            index={index}
            formMethods={formMethods}
            onRemove={remove}
            countriesList={countriesList}
          />
        );
      })}

      {fields.length < maxAmountOfResidencies && (
        <Box display="flex" alignItems="center">
          <Button
            variant="link"
            data-testid="add-another-taxresidency"
            onClick={() => {
              append({
                country: "",
                taxId: "",
              });
            }}
          >
            {t(`${translationPrefix}.fields.taxInfo.add_additionalTaxInfo`)}
          </Button>
        </Box>
      )}
    </>
  );
};

export default ResidencyFields;
