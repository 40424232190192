import { DocumentStatus } from "generated/globalTypes";
import { Document, DocumentUpload } from "../UploadField.types";
import { UploadStatus } from "@finvia/component-library";

export const fromFileListToFile = (file: FileList): File[] =>
  Array.from(file).map((docfile: File) => docfile);

export const revalidateForm = (
  trigger: (name?: string | string[] | undefined) => Promise<boolean>,
  inputName: string
): Promise<boolean> => trigger(inputName);

export const mapDocumentStatusToUpload = (
  documents: Document[] | undefined | null
): DocumentUpload[] | null =>
  documents?.map((document) => {
    const isPartOfDocumentstatus =
      document.uploadStatus === DocumentStatus.ARCHIVED ||
      document.uploadStatus === DocumentStatus.CREATED ||
      document.uploadStatus === DocumentStatus.DRAFT;

    const progressDictionary = {
      done: 100,
      failed: 100,
      pending: 50,
    } as const;

    const status =
      isPartOfDocumentstatus || document.id ? "done" : document.uploadStatus;

    return {
      ...document,
      status: status,
      progress: document.uploadStatus
        ? progressDictionary[status as UploadStatus]
        : 0,
    } as DocumentUpload;
  }) ?? null;
