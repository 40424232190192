import { ApolloClient } from "@apollo/client";
import { configureStore as reduxConfigureStore } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import logger from "redux-logger";
import rootReducer from "./reducers";

const additionalMiddlewares = [] as any[];

if (process.env.DEBUG) {
  additionalMiddlewares.push(logger);
}

// Ignoring the explicit return type as it is unkown or would lead to cyclic
// dependencies
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function configureStore(client: ApolloClient<unknown>) {
  return reduxConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const middlewares = getDefaultMiddleware({
        thunk: {
          extraArgument: {
            client,
          },
        },
      });
      return middlewares.concat(additionalMiddlewares) as typeof middlewares;
    },
  });
}

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = Pick<
  ReturnType<typeof configureStore>,
  "dispatch"
>["dispatch"];

export function typedUseSelector<R>(query: (state: AppState) => R): R {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSelector<R>((state) => query(JSON.parse(JSON.stringify(state))));
}

export { configureStore };
