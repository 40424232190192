import { SelectInput, TextInput, Icon } from "@finvia/money-ui";
import { useEffect, useRef, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Separator from "../../../../../../components/InputGrid/Separator";
import * as S from "./EmploymentAndTaxForm.styled";
import { ResidencyFieldProps } from "./EmploymentAndTaxForm.types";

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData";

const ResidencyField = ({
  index,
  lastItem,
  formMethods,
  item,
  onRemove,
  countriesList,
}: ResidencyFieldProps): ReactElement => {
  const { t } = useTranslation();

  const { trigger, formState, watch, errors, register } = formMethods;
  const countryFieldWatch = watch(`taxInfo.residencies[${index}].country`);
  const taxIdFieldWatch = watch(`taxInfo.residencies[${index}].taxId`);

  const countryValueRef = useRef(countryFieldWatch);
  const taxIdValueRef = useRef(taxIdFieldWatch);

  const hasCountryValueChanged = countryValueRef.current !== countryFieldWatch;
  const hasTaxIdValueChanged = taxIdValueRef.current !== taxIdFieldWatch;

  const isCountryfieldDirty =
    formState.dirtyFields.taxInfo?.residencies?.[index]?.country;
  const isTaxIdfieldDirty =
    formState.dirtyFields.taxInfo?.residencies?.[index]?.taxId;

  const shouldRevalidateTaxId =
    hasCountryValueChanged &&
    hasTaxIdValueChanged &&
    isCountryfieldDirty &&
    isTaxIdfieldDirty;

  useEffect(() => {
    if (shouldRevalidateTaxId) {
      countryValueRef.current = countryFieldWatch;
      trigger(`taxInfo.residencies[${index}].taxId`);
    }
  }, [shouldRevalidateTaxId, countryFieldWatch, trigger, index]);

  const isFirstElement = index === 0;

  return (
    <>
      <S.ExtraFieldWrapper isFirstSelect={isFirstElement}>
        <SelectInput
          ref={register}
          name={`taxInfo.residencies[${index}].country`}
          label={t(
            `${translationPrefix}.fields.taxInfo.residencies.country.${
              isFirstElement ? "label" : "second"
            }`
          )}
          required
          {...(item.country && {
            value: item.country,
          })}
          options={countriesList}
          searchable
          errorMessage={errors.taxInfo?.residencies?.[index]?.country?.message}
        />
        {!isFirstElement ? (
          <S.RemoveIconWrapper
            data-testid={`taxInfo.residencies[${index}].delete`}
            onClick={onRemove}
          >
            <Icon name="trash" color="petrol.600" />
          </S.RemoveIconWrapper>
        ) : (
          <></>
        )}
      </S.ExtraFieldWrapper>
      <TextInput crossOrigin
        ref={register}
        name={`taxInfo.residencies[${index}].taxId`}
        required
        label={t(
          `${translationPrefix}.fields.taxInfo.residencies.taxId.${
            isFirstElement ? "label" : "second"
          }`
        )}
        helperText={t(
          `${translationPrefix}.fields.taxInfo.residencies.taxId.tooltip`
        )}
        errorMessage={errors?.taxInfo?.residencies?.[index]?.taxId?.message}
        defaultValue={item.taxId}
      />
      <TextInput crossOrigin
        ref={register}
        name={`taxInfo.residencies[${index}].localTaxOffice`}
        required
        label={t(
          `${translationPrefix}.fields.taxInfo.residencies.localTaxOffice.label`
        )}
        errorMessage={
          errors?.taxInfo?.residencies?.[index]?.localTaxOffice?.message
        }
        defaultValue={item.localTaxOffice}
      />
      {(!lastItem || index === 2) && <Separator singleColumn />}
    </>
  );
};
export default ResidencyField;
