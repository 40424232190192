import Africa from "assets/maps/africa.svg";
import Asia from "assets/maps/asia.svg";
import Europe from "assets/maps/europe.svg";
import Germany from "assets/maps/europe_germany.svg";
import Global from "assets/maps/global.svg";
import NorthAmerica from "assets/maps/north_america.svg";
import USA from "assets/maps/north_america_usa.svg";
import AsiaPacificCore from "assets/maps/asia_pacific_core.svg";
import { FundFragment } from "generated/FundFragment";
import { TFunction } from "i18next";
import { fundCurrencyTranslation } from "utils/fundCurrencyTranslation";

export const maps = {
  africa: Africa,
  asia: Asia,
  europe: Europe,
  europe_germany: Germany,
  global: Global,
  north_america: NorthAmerica,
  north_america_usa: USA,
  asia_pacific_core: AsiaPacificCore,
};

export const boxesInfo = ({
  t,
  fund,
}: {
  t: TFunction;
  fund: FundFragment;
}): { title: string; subtitle: string | null | undefined }[] => {
  const langKey = "components.alternativeInvestmentFund";
  const fundAttributes = fund.attributes;
  return [
    {
      title: t(`${langKey}.assetClass.title`),
      subtitle: t(`${langKey}.assetClass.${fundAttributes?.assetClass}`),
    },
    {
      title: t(`${langKey}.strategy.title`),
      subtitle: t(`${langKey}.strategy.${fundAttributes?.strategy}`),
    },
    {
      title: t(`${langKey}.minimumInvestmentAmount`),
      subtitle: t(fundCurrencyTranslation(fundAttributes?.currency), {
        value: fundAttributes?.minimumCommitmentAmount,
      }),
    },
    {
      title: t(`${langKey}.fundType.title`),
      subtitle: t(`${langKey}.fundType.${fundAttributes?.type}`),
    },
    {
      title: t(`${langKey}.targetAmount`),
      subtitle: t(fundCurrencyTranslation(fundAttributes?.currency), {
        value: fundAttributes?.targetFundSize,
      }),
    },
    {
      title: t(`${langKey}.investmentPeriod`),
      subtitle: fundAttributes?.investmentPeriod,
    },
    {
      title: t(`${langKey}.typeOfUse`),
      subtitle: fundAttributes?.typeOfUse,
    },
    {
      title: t(`${langKey}.runningTime`),
      subtitle: fundAttributes?.lifetime,
    },
    {
      title: t(`${langKey}.leverageLimit`),
      subtitle: fundAttributes?.leverageLimit,
    },
    {
      title: t(`${langKey}.estimatedClosing`),
      subtitle: fundAttributes?.estimatedClosing,
    },
    {
      title: t(`${langKey}.targetReturn`),
      subtitle: fundAttributes?.targetReturn,
    },
    {
      title: t(`${langKey}.firstClosingDate`),
      subtitle: fundAttributes?.firstClosing
        ? `${fundAttributes?.firstClosing}`
        : undefined,
    },
    {
      title: t(`${langKey}.status.title`),
      subtitle: t(`${langKey}.status.${fundAttributes?.status}`),
    },
  ];
};
