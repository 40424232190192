import * as Sentry from "@sentry/react";
import { FundFragment } from "../../../types/types";
import { typedCreateAsyncThunk } from "store/typedCreateAsyncThunk";
import { EmptyObject } from "types/types";
import {
  AddWishListFund as AddWishListFundQueryType,
  AddWishListFundVariables,
} from "../../../generated/AddWishListFund";
import { FundsBySlug } from "../../../generated/FundsBySlug";
import { FundsCollection } from "../../../generated/FundsCollection";
import {
  GetAIFundContract as GetAIFundContractQueryType,
  GetAIFundContractVariables,
  GetAIFundContract_alternativeInvestmentsFundContract,
} from "../../../generated/GetAIFundContract";
import {
  GetAIFundContractDocumentSet as GetAIFundContractDocumentSetQueryType,
  GetAIFundContractDocumentSetVariables,
  GetAIFundContractDocumentSet_alternativeInvestmentsFundContract,
} from "../../../generated/GetAIFundContractDocumentSet";
import {
  GetAIFundContractSigningData as GetAIFundContractSigningDataQueryType,
  GetAIFundContractSigningDataVariables,
  GetAIFundContractSigningData_alternativeInvestmentsFundContract,
} from "../../../generated/GetAIFundContractSigningData";
import {
  AtadDataInput,
  BankAccountType,
  Currency,
  ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY,
  InvestorClassificationUnderSupervision,
  InvestorType,
} from "../../../generated/globalTypes";
import {
  SignAlternativeInvestmentsFundContract as SignAlternativeInvestmentsFundContractMutationType,
  SignAlternativeInvestmentsFundContractVariables,
} from "../../../generated/SignAlternativeInvestmentsFundContract";
import {
  UpsertAlternativeInvestmentsFundContract as UpsertAlternativeInvestmentsFundContractType,
  UpsertAlternativeInvestmentsFundContractVariables,
} from "../../../generated/UpsertAlternativeInvestmentsFundContract";
import {
  SignAlternativeInvestmentsFundContract,
  UpsertAlternativeInvestmentsFundContract,
} from "../../../graphql/mutations/Contracts";
import { AddWishListFund } from "../../../graphql/mutations/Profile";
import {
  GetFundBySlug,
  GetFundsCollection,
} from "../../../graphql/queries/AlternativeInvestmentFunds";
import {
  GetAIFundContract,
  GetAIFundContractDocumentSet,
  GetAIFundContractDocumentSubscriptionDocumentDraft,
  GetAIFundContractDocumentTrustAndAdministrationContract,
  GetAIFundContractSigningData,
} from "../../../graphql/queries/FundContracts";
import { GraphQLPartial } from "../../../utils/graphQLPartial";
import { FundDeclarationsForm } from "./FundDeclarations/fundDeclarationsValidations";
import { AIFundContractSigningForm } from "./FundSigning/validations/externalStructure";
import { Contract } from "./fundValidations";
import {
  mapFundDeclarationsData,
  mapFundDetailsData,
  mapFundSigningData,
} from "./mapAIFundsToGraphQL/mapAIFundsToGraphQL";
import {
  FundDetailsData,
  GraphQlFundDetailsData,
} from "./mapAIFundsToGraphQL/mapAIFundsToGraphQLTypes";

type AlternativeInvestmentsFundContract =
  GetAIFundContractSigningData_alternativeInvestmentsFundContract &
    GetAIFundContract_alternativeInvestmentsFundContract &
    GetAIFundContractDocumentSet_alternativeInvestmentsFundContract;

const mapAIFundContractFromGraphQlToForm = (
  contract?: GraphQLPartial<AlternativeInvestmentsFundContract> | null
): GraphQLPartial<Contract> => {
  return {
    signing: {
      confirmsTrustAndAdministrationContract:
        contract?.isTrustAndAdministrationContractConfirmed,
      confirmsRiskOfAcquisition: contract?.isRiskOfAcquisitionConfirmed,
      confirmsAcquisitionOfShareholding:
        contract?.isAcquisitionOfShareholdingConfirmed,
      confirmsActingOnOwnBehalf: contract?.isActingOnOwnBehalf,
      confirmsDataProtection: contract?.isDataProtectionConfirmed,
      confirmsStatementsAndDisclosures:
        contract?.isStatementsAndDisclosuresConfirmed,
    },
    declarations: {
      investorType:
        contract?.investorType === null
          ? InvestorType.SEMI_PROFESSIONAL
          : contract?.investorType,
      semiProfessionalInvestor: {
        isSemiProfessionalInvestor:
          contract?.semiProfessionalInvestor?.isSemiProfessionalInvestor,
        isSufficientExperienceAndKnowledgeConfirmed:
          contract?.semiProfessionalInvestor
            ?.isSufficientExperienceAndKnowledgeConfirmed,
        experienceAndKnowledgeType:
          contract?.semiProfessionalInvestor?.experienceAndKnowledgeType,
        experienceAndKnowledgeAppendix:
          contract?.semiProfessionalInvestor?.experienceAndKnowledgeAppendix,
        isEducationAndProfessionalStatusConfirmed:
          contract?.semiProfessionalInvestor
            ?.isEducationAndProfessionalStatusConfirmed,
        educationAndProfessionalStatusAppendix:
          contract?.semiProfessionalInvestor
            ?.educationAndProfessionalStatusAppendix,
        educationAndProfessionalStatusType:
          contract?.semiProfessionalInvestor
            ?.educationAndProfessionalStatusType,
        isAlignedRiskProfileConfirmed:
          contract?.semiProfessionalInvestor?.isAlignedRiskProfileConfirmed,
        isAlignedFinancialSituationConfirmed:
          contract?.semiProfessionalInvestor
            ?.isAlignedFinancialSituationConfirmed,
        isSufficientIncomeConfirmed:
          contract?.semiProfessionalInvestor?.isSufficientIncomeConfirmed,
        isSufficientFinancialAssetsConfirmed:
          contract?.semiProfessionalInvestor
            ?.isSufficientFinancialAssetsConfirmed,
      },
      professionalInvestor: {
        isProfessionalInvestor:
          contract?.professionalInvestor?.isProfessionalInvestor,
        isSufficientExperienceAndKnowledgeConfirmed:
          contract?.professionalInvestor
            ?.isSufficientExperienceAndKnowledgeConfirmed,
        isRelevantMarketExperienceConfirmed:
          contract?.professionalInvestor?.isRelevantMarketExperienceConfirmed,
        isRelevantFinancialPortfolioConfirmed:
          contract?.professionalInvestor?.isRelevantFinancialPortfolioConfirmed,
        isRelevantMarketJobConfirmed:
          contract?.professionalInvestor?.isRelevantMarketJobConfirmed,
        professionalInvestorClassification: {
          isConfirmed:
            contract?.professionalInvestor
              ?.isConfirmedProfessionalInvestorClassification,
          financialInstruments: {
            isConfirmed:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.isConfirmedFinancialInstruments,
            otherInstitutionalInvestor:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.financialInstruments?.otherInstitutionalInvestor,
          },
          underSupervision: {
            isConfirmed:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.isConfirmedUnderSupervision,
            creditInstitution:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.CREDIT_INSTITUTION
              ),
            investingFirm:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.INVESTING_FIRM
              ),
            financialInstitution:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.FINANCIAL_INSTITUTION
              ),
            insuranceCompany:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.INSURANCE_COMPANY
              ),
            collectiveInvestmentOrganism:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.COLLECTIVE_INVESTMENT_ORGANISM
              ),
            pensionFund:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.PENSION_FUND
              ),
            merchants:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.MERCHANTS
              ),
            otherInstitutionalInvestor:
              contract?.professionalInvestor?.professionalInvestorClassification?.underSupervisionCompanyType?.includes(
                InvestorClassificationUnderSupervision.OTHER_INSTITUTIONAL_INVESTOR
              ),
          },
          governmentFunction: {
            isConfirmed:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.isConfirmedGovernmentFunction,
            companyAsGovernment:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.governmentFunction?.companyAsGovernment,
          },
          securizationOfLiabilities: {
            isConfirmed:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.isConfirmedSecurizationOfLiabilities,
            companyAsSecurizationOfLiabilities:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.securizationOfLiabilities?.companyAsSecurizationOfLiabilities,
          },
          largeCompany: {
            isConfirmed:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.isConfirmedlargeCompany,
            balanceSheetTotal:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.largeCompany?.balanceSheetTotal,
            netSales:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.largeCompany?.netSales,
            ownFunds:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.largeCompany?.ownFunds,
          },
          extensiveExperience: {
            isConfirmed:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.isConfirmedExtensiveExperience,
            tenTransactionsInTheLastFourQuarters:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.extensiveExperience?.tenTransactionsInTheLastFourQuarters,
            tenTransactionsInTheLastFourQuartersExample:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.extensiveExperience
                ?.tenTransactionsInTheLastFourQuartersExample,
            moreThan500k:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.extensiveExperience?.moreThan500k,
            jobInCapitalMarket:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.extensiveExperience?.jobInCapitalMarket,
            jobInCapitalMarketDetails:
              contract?.professionalInvestor?.professionalInvestorClassification
                ?.extensiveExperience?.jobInCapitalMarketDetails,
          },
        },
      },
    },
    slug: contract?.slug,
    signingAmount: contract?.signingAmount?.value,
    signingCurrency:
      contract?.signingAmount?.currency === Currency.USD
        ? ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.usd
        : ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.eur,
    bankAccountType: contract?.bankAccountType,
  };
};

export const getFundsCollection = typedCreateAsyncThunk(
  "funds/alternativeInvestmentFunds",
  async (_, { extra }) => {
    const {
      data: { alternativeInvestmentFunds, profile },
    } = await extra.client.query<FundsCollection>({
      query: GetFundsCollection,
    });

    const isWishlisted = (fund: FundFragment) =>
      profile.wishListFunds?.some(
        (wishlistedFund) => wishlistedFund.slug === fund?.attributes?.slug
      );

    const mappedFunds = alternativeInvestmentFunds?.data?.map((fund) => {
      if (fund && isWishlisted(fund)) {
        return { ...fund, wishlisted: true };
      }

      return fund;
    });

    return mappedFunds;
  }
);

export const getFundBySlug = typedCreateAsyncThunk(
  "funds/alternativeInvestmentFund",
  async (payload: { slug: string }, { extra }) => {
    const result = await extra.client.query<FundsBySlug>({
      query: GetFundBySlug,
      variables: {
        slug: payload.slug,
      },
    });

    return result.data.alternativeInvestmentFunds;
  }
);

export const getFundContractSigningData = typedCreateAsyncThunk(
  "funds/getFundSigningData",
  async (payload: { slug: string }, { extra }) => {
    const result = await extra.client.query<
      GetAIFundContractSigningDataQueryType,
      GetAIFundContractSigningDataVariables
    >({
      query: GetAIFundContractSigningData,
      variables: {
        slug: payload.slug,
      },
    });

    return mapAIFundContractFromGraphQlToForm(
      result.data.alternativeInvestmentsFundContract
    );
  }
);

export const signAIFundContract = typedCreateAsyncThunk(
  "funds/signFund",
  async (payload: { slug: string }, { extra }) => {
    await extra.client.mutate<
      SignAlternativeInvestmentsFundContractMutationType,
      SignAlternativeInvestmentsFundContractVariables
    >({
      mutation: SignAlternativeInvestmentsFundContract,
      variables: {
        input: {
          slug: payload.slug,
          signatureTimestamp: new Date().toISOString(),
        },
      },
    });

    return mapAIFundContractFromGraphQlToForm({});
  }
);

export const getAIFundContract = typedCreateAsyncThunk(
  "funds/getContractBySlug",
  async (payload: { slug: string }, { extra }) => {
    const result = await extra.client.query<
      GetAIFundContractQueryType,
      GetAIFundContractVariables
    >({
      query: GetAIFundContract,
      variables: { slug: payload.slug },
    });

    return mapAIFundContractFromGraphQlToForm(
      result.data.alternativeInvestmentsFundContract
    );
  }
);

export const upsertAlternativeInvestmentsFundContract = typedCreateAsyncThunk(
  "funds/upsertAlternativeInvestmentsFundContract",
  async (
    payload: Partial<FundDeclarationsForm> &
      AIFundContractSigningForm &
      Partial<FundDetailsData> &
      AtadDataInput &
      Partial<FundDetailsData> & {
        slug: string;
        bankAccountType?: BankAccountType;
        taxNumber?: string | null;
        localTaxOffice?: string | null;
      },
    { extra }
  ): Promise<GraphQLPartial<Contract> | undefined> => {
    if (payload) {
      let fundDetailsData: GraphQlFundDetailsData | EmptyObject = {};

      if (
        payload.signingAmount !== undefined &&
        payload.signingCurrency !== undefined
      ) {
        fundDetailsData = mapFundDetailsData({
          signingAmount: payload.signingAmount,
          signingCurrency: payload.signingCurrency,
        });
      }

      const fundDeclarationsData = mapFundDeclarationsData(
        payload.declarations
      );

      const fundSigningData = mapFundSigningData(payload.signing);

      const mappedPayload: UpsertAlternativeInvestmentsFundContractVariables = {
        input: {
          ...fundSigningData,
          ...fundDeclarationsData,
          ...fundDetailsData,
          bankAccountType: payload.bankAccountType,
          slug: payload.slug,
          sourceOfFunds: payload.sourceOfFunds,
          sourceOfFundsDetail: payload.sourceOfFundsDetail,

          atadData: {
            taxResidencies: payload.taxResidencies,
            unlimitedTaxLiability: payload.unlimitedTaxLiability,
            reasonForLimitedTaxLiability: payload.reasonForLimitedTaxLiability,
          },
        },
      };

      await extra.client.mutate<
        UpsertAlternativeInvestmentsFundContractType,
        UpsertAlternativeInvestmentsFundContractVariables
      >({
        mutation: UpsertAlternativeInvestmentsFundContract,
        variables: mappedPayload,
      });
    }

    return payload;
  }
);

export const getAIFundContractDocumentSubscriptionDocumentDraft =
  typedCreateAsyncThunk(
    "funds/getFundContractDocumentSubscriptionDocumentDraft",
    async (payload: { slug: string }, { extra }) => {
      const result = await extra.client.query<
        GetAIFundContractDocumentSetQueryType,
        GetAIFundContractDocumentSetVariables
      >({
        query: GetAIFundContractDocumentSubscriptionDocumentDraft,
        fetchPolicy: "no-cache",
        variables: { slug: payload.slug },
      });

      Sentry.addBreadcrumb({
        category: "fund-signin",
        message: "Action: getFundContractDocumentSubscriptionDocumentDraft",
        level: "log",
        data: {
          payload,
          result: {
            ...result.data,
          },
        },
      });

      return result.data.alternativeInvestmentsFundContract;
    }
  );

export const getAIFundContractDocumentTrustAndAdministrationContract =
  typedCreateAsyncThunk(
    "funds/getFundContractDocumentTrustAndAdministrationContract",
    async (payload: { slug: string }, { extra }) => {
      const result = await extra.client.query<
        GetAIFundContractDocumentSetQueryType,
        GetAIFundContractDocumentSetVariables
      >({
        query: GetAIFundContractDocumentTrustAndAdministrationContract,
        fetchPolicy: "no-cache",
        variables: { slug: payload.slug },
      });

      return result.data.alternativeInvestmentsFundContract;
    }
  );

export const getAIFundContractDocumentSet = typedCreateAsyncThunk(
  "funds/getFundContractDocumentSet",
  async (payload: { slug: string }, { extra }) => {
    const result = await extra.client.query<
      GetAIFundContractDocumentSetQueryType,
      GetAIFundContractDocumentSetVariables
    >({
      query: GetAIFundContractDocumentSet,
      fetchPolicy: "no-cache",
      variables: { slug: payload.slug },
    });

    Sentry.addBreadcrumb({
      category: "fund-signin",
      message: "Action: getAIFundContractDocumentSet",
      level: "log",
      data: {
        payload,
        result: {
          ...result.data,
        },
      },
    });

    return result.data.alternativeInvestmentsFundContract;
  }
);

export const addWishListFund = typedCreateAsyncThunk(
  "funds/addWishListFund",
  async (
    payload: {
      personId: string;
      slug: string;
      potentialCommitment: { value: number; currency: Currency };
    },
    { extra }
  ) => {
    const result = await extra.client.mutate<
      AddWishListFundQueryType,
      AddWishListFundVariables
    >({
      mutation: AddWishListFund,
      variables: {
        personId: payload.personId,
        wishListFund: {
          slug: payload.slug,
          potentialCommitment: {
            value: payload.potentialCommitment.value,
            currency: payload.potentialCommitment.currency,
          },
        },
      },
    });

    return {
      ...result.data,
      slug: payload.slug,
    };
  }
);
