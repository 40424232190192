import { FormDefaultData } from "../../../../utils/graphQLPartial";
import { InvestmentOpportunityFormStep } from "../../formSteps";
import {
  validationSchema as GoalsValidationSchema,
  GoalsProps,
} from "./GoalsForm";
import {
  HorizonForm,
  validationSchema as HorizonValidationSchema,
} from "./HorizonForm/horizonValidations";
import {
  RelevantAssetClassesValidationSchema,
  RelevantAssetClassesOptions,
} from "./RelevantAssetClasses";
import {
  RiskBearingCapacityForm,
  validationSchema as RiskBearingCapacityValidationSchema,
} from "./RiskBearingCapacity/riskBearingCapacityValidations";

export type InvestorProfile = GoalsProps &
  HorizonForm &
  RiskBearingCapacityForm &
  RelevantAssetClassesOptions;

export function deriveFormAccess(
  investorProfile: FormDefaultData<InvestorProfile>
): {
  atMostAccessibleStep: InvestmentOpportunityFormStep;
  isComplete: boolean;
} {
  const t = (k: any) => k;
  let atMostAccessibleStep = InvestmentOpportunityFormStep.InvestmentGoal;
  let isComplete = false;

  try {
    GoalsValidationSchema(t).validateSync({ goals: investorProfile.goals });

    atMostAccessibleStep = InvestmentOpportunityFormStep.InvestmentHorizon;

    HorizonValidationSchema(t).validateSync({
      horizon: investorProfile.horizon,
    });

    atMostAccessibleStep = InvestmentOpportunityFormStep.RiskAssessment;

    RiskBearingCapacityValidationSchema(t).validateSync({
      riskBearingCapacity: investorProfile.riskBearingCapacity,
    });

    atMostAccessibleStep = InvestmentOpportunityFormStep.InvestmentPreferences;

    RelevantAssetClassesValidationSchema(t).validateSync({
      relevantAssetClasses: investorProfile.relevantAssetClasses,
    });

    atMostAccessibleStep = InvestmentOpportunityFormStep.InvestmentGoal;

    isComplete = true;
  } catch {
    // no-op
  }

  return {
    atMostAccessibleStep,
    isComplete,
  };
}
