import { useFormContext } from "react-hook-form";
import { TitleCard } from "../TitleCard";
import { ErrorAlert } from "../ErrorAlert";
import NavigationDisclaimer from "../NavigationDisclaimer";
import { FormContentProps } from "./FormContent.types";
import { Stack } from "@finvia/money-ui";

const FormContent: React.FC<FormContentProps> = ({
  title,
  subTitle,
  children,
  error,
  customErrorMessage,
  isDirty,
  isSubmitSuccessful,
  disableDisclaimer = false,
}) => {
  const { formState } = useFormContext();
  if (error && process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.error(error.message);
  }

  // TODO cleanup usages of WrappedFormContent to rely on the context instead of passing isDirty and isSubmitSuccessful in every form. https://app.asana.com/0/1199535344050284/1201008247682800
  return (
    <Stack gutter="tera" alignItems="center">
      {!disableDisclaimer && (
        <NavigationDisclaimer
          isDirty={isDirty || formState.isDirty}
          isSubmitted={isSubmitSuccessful || formState.isSubmitSuccessful}
        />
      )}
      {title && <TitleCard title={title} subTitle={subTitle} />}
      {error && <ErrorAlert customErrorMessage={customErrorMessage} />}
      {children}
      {error && <ErrorAlert customErrorMessage={customErrorMessage} />}
    </Stack>
  );
};

export default FormContent;
