import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../components/FormContent";
import { RiskCapacity } from "../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../store";
import formDefaultsDeep from "../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../components/QuestionnaireController";
import { updateProfile } from "../actions";
import {
  RiskBearingCapacityForm as RiskBearingCapacityFormType,
  validationSchema,
} from "./riskBearingCapacityValidations";
import { ChoiceGroup, Grid, InfoBox, Stack, Text } from "@finvia/money-ui";
import { Block, ItemWrapper, ItemsWrapper } from "./RiskBearingCapacityForm.styled";

const translationPrefix = "q-ai.opportunities.form.riskBearingCapacity";

const riskCapacities: RiskCapacity[] = [
  RiskCapacity.CONSERVATIVE,
  RiskCapacity.BALANCED,
  RiskCapacity.YIELD,
  RiskCapacity.SPECULATIVE,
];

const invalidRiskCapacities: RiskCapacity[] = [
  RiskCapacity.CONSERVATIVE,
  RiskCapacity.BALANCED,
];

const RiskBearingCapacityForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<RiskBearingCapacityFormType>({
      riskBearingCapacity:
        state.alternativeInvestments.investmentOpportunities.data
          ?.riskBearingCapacity,
    })
  );

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data: RiskBearingCapacityFormType) => {
      dispatch(updateProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<RiskBearingCapacityFormType>
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      dataAnalytics="1.3_risk_affinity">
      {(formMethods: { trigger?: any; formState?: any; watch?: any; register?: any; }) => {
        const { watch, register } = formMethods;
        const selectedCapacity = watch("riskBearingCapacity");

        formMethods.trigger("riskBearingCapacity");
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
            error={error}
          >
            <ChoiceGroup
              label="riskBearingCapacity"
              name="riskBearingCapacity"
              hideLabel
            >
              <Grid gap="mega" columns={{ sm: 1, md: 2, lg: 4 }}>
                {riskCapacities.map((risk) => (
                  <Stack gutter="mega" alignItems="center" key={risk}>
                    <ChoiceGroup.Button
                      ref={register}
                      name="riskBearingCapacity"
                      value={risk}
                      label={t(
                        `${translationPrefix}.riskCapacities.${risk}.label`
                      )}
                    />
                    <Text align="center">
                      {t(
                        `${translationPrefix}.riskCapacities.${risk}.description`
                      )}
                    </Text>
                  </Stack>
                ))}
              </Grid>
            </ChoiceGroup>

            {selectedCapacity &&
              invalidRiskCapacities.includes(selectedCapacity) && (
                <InfoBox icon="error" variant="danger">
                  {t(`${translationPrefix}.validations.invalidOption`)}
                </InfoBox>
              )}

            {selectedCapacity === RiskCapacity.SPECULATIVE && (
              <InfoBox icon="info">
                <ItemsWrapper>
                  {[...Array(3)].map((_, index) =>
                    (<ItemWrapper>
                      <Block />
                      <span>{t(`${translationPrefix}.riskCapacities.SPECULATIVE.values.phrase${index + 1}`)}</span>
                    </ItemWrapper>)
                  )}
                </ItemsWrapper>
              </InfoBox>
            )}

            {selectedCapacity === RiskCapacity.YIELD && (
              <InfoBox icon="info">
                <ItemsWrapper>
                  {[...Array(4)].map((_, index) =>
                    (<ItemWrapper>
                      <Block />
                      <span>{t(`${translationPrefix}.riskCapacities.YIELD.values.phrase${index + 1}`)}</span>
                    </ItemWrapper>)
                  )}
                </ItemsWrapper>
              </InfoBox>
            )}
            
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default RiskBearingCapacityForm;
