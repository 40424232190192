import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import {
  BalanceSheetRange,
  BalanceSheetRangeLarge,
  BalanceSheetRangeSmall,
  LegalEntityWealthOrigin,
  MoneyRangeLarge,
  MoneyRangeNetCapital,
} from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import {
  validationSchema,
  WealthAssessment,
  wealthAssessmentCheckboxNames,
} from "./WealthAssessment.schema";
import {
  Checkbox,
  ChoiceGroup,
  Grid,
  Heading,
  SelectInput,
  Text,
  TextAreaInput,
} from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { HeadingWrapper } from "./WealthAssessment.styled";
import { MonetaryInput } from "../../MonetaryInput";
import { Controller } from "react-hook-form";

const translationPrefix = "q-ai.opportunities.form.legalEntityWealthAssessment";

const WealthAssessmentForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);
  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, xl: 1 }}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) => {
    const wealthAssessment =
      state.alternativeInvestments.regulatoryData?.legalEntityData
        .wealthAssessment;

    return formDefaultsDeep<WealthAssessment>(
      { wealthAssessment },
      {
        wealthAssessment: {
          netCapital: "",
          exactNetCapital: "",
          financialAssets: {
            liquidity: {
              checkbox: false,
              range: undefined,
              value: "",
            },
            securities: {
              checkbox: false,
              range: undefined,
              value: "",
            },
            selfUsedProperties: {
              checkbox: false,
              range: undefined,
              value: "",
            },
            movableProperty: {
              checkbox: false,
              range: undefined,
              value: "",
            },
            other: {
              checkbox: false,
              range: undefined,
              value: "",
            },
          },
          totalAssets: "",
          sales: "",
          ownFunds: "",
          wealthOrigin: "",
        },
      }
    );
  });

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();

  const cleanUpDataFromUnselectedCheckboxes = (
    financialAssets: WealthAssessment["wealthAssessment"]["financialAssets"]
  ) => {
    if (!financialAssets) return;

    const financialAssetsKeys = Object.keys(
      financialAssets
    ) as (keyof typeof financialAssets)[];

    return financialAssetsKeys.reduce(
      (cleanedUpFinancialAssets, currentFieldName) => {
        const field = financialAssets?.[currentFieldName];
        const isChecked = field?.checkbox;

        return {
          ...cleanedUpFinancialAssets,
          [currentFieldName]: {
            checkbox: isChecked,
            range: isChecked ? field?.range : null,
            value:
              isChecked && field?.range === MoneyRangeLarge.MORE_THAN_1000000
                ? field?.value
                : null,
          },
        };
      },
      {}
    );
  };

  const onSubmit = useCallback(
    async (wealthAssessment: WealthAssessment) => {
      const {
        wealthAssessment: { financialAssets },
      } = wealthAssessment;

      const cleanedUpData = {
        wealthAssessment: {
          ...wealthAssessment.wealthAssessment,
          financialAssets: cleanUpDataFromUnselectedCheckboxes(financialAssets),
        },
      };

      dispatch(updateLegalEntityProfile(cleanedUpData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<WealthAssessment>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.12_le_wealth_assessment"
    >
      {(formMethods) => {
        const { watch, errors } = formMethods;
        const watchNetCapital = watch(
          "wealthAssessment.netCapital",
          defaultData?.wealthAssessment?.netCapital
        );
        const watchWealthOrigin = watch(
          "wealthAssessment.wealthOrigin",
          defaultData.wealthAssessment?.wealthOrigin
        );

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid columnsGap="yotta" rowsGap="giga" columns={1}>
              <Grid gap="giga" columns={1}>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.headline`)}
                </Heading>
              </Grid>
              <Grid
                columnsGap="yotta"
                rowsGap="giga"
                columns={{ sm: 1, md: 2 }}
              >
                <SelectInput
                  name={`wealthAssessment.netCapital`}
                  label={t(`${translationPrefix}.fields.netCapital.label`)}
                  required
                  options={getSelectOptionsFromTranslation(
                    `${translationPrefix}.fields.netCapital.values`,
                    t
                  )}
                  value={defaultData.wealthAssessment?.netCapital ?? undefined}
                  ref={formMethods.register}
                />
                {watchNetCapital === MoneyRangeNetCapital.MORE_THAN_500000 && (
                  <MonetaryInput
                    ref={formMethods.register}
                    required
                    name={`wealthAssessment.exactNetCapital`}
                    label={t(
                      `${translationPrefix}.fields.exactNetCapital.label`
                    )}
                    errorMessage={
                      errors.wealthAssessment?.exactNetCapital?.message
                    }
                  />
                )}
              </Grid>
              <Grid
                columnsGap="yotta"
                rowsGap="giga"
                columns={{ sm: 1, md: 2 }}
              >
                <Controller
                  name="wealthAssessment.wealthOrigin"
                  control={formMethods.control}
                  defaultValue={defaultData.wealthAssessment?.wealthOrigin}
                  as={
                    <SelectInput
                      name="wealthAssessment.wealthOrigin"
                      label={t(
                        `${translationPrefix}.fields.wealthOrigin.label`
                      )}
                      options={getSelectOptionsFromTranslation(
                        `${translationPrefix}.fields.wealthOrigin.values`,
                        t
                      )}
                      required
                    />
                  }
                />
              </Grid>
              {watchWealthOrigin ===
                LegalEntityWealthOrigin.SALE_OF_BUSINESS && (
                <Grid gap="giga" columns={1}>
                  <TextAreaInput
                    crossOrigin
                    required
                    ref={formMethods.register}
                    name="wealthAssessment.wealthOriginDetail"
                    label={t(
                      `q-ai.opportunities.form.wealthAssessment.fields.wealthOrigin.wealthOriginDescription.${watchWealthOrigin}.label`
                    )}
                    placeholder={t(
                      `q-ai.opportunities.form.wealthAssessment.fields.wealthOrigin.wealthOriginDescription.${watchWealthOrigin}.example`
                    )}
                    helperText={t(
                      `q-ai.opportunities.form.wealthAssessment.fields.wealthOrigin.wealthOriginDescription.${watchWealthOrigin}.descriptiveText`
                    )}
                  />
                </Grid>
              )}
              <Grid gap="giga" columns={1}>
                <Text>{t(`${translationPrefix}.financialAssets`)}</Text>
              </Grid>
              {wealthAssessmentCheckboxNames.map((checkbox) => {
                const checkboxName = `wealthAssessment.financialAssets.${checkbox}.checkbox`;
                const watchCheckbox = watch(
                  checkboxName,
                  !!defaultData.wealthAssessment?.financialAssets?.[checkbox]
                    ?.range
                );

                const watchRange = watch(
                  `wealthAssessment.financialAssets.${checkbox}.range`,
                  defaultData.wealthAssessment?.financialAssets?.[checkbox]
                    ?.range
                );

                const tooltip = t(
                  `${translationPrefix}.fields.financialAssets.${checkbox}.tooltip`,
                  { defaultValue: undefined }
                );

                return (
                  <Grid
                    columnsGap="yotta"
                    rowsGap="giga"
                    columns={1}
                    key={checkbox}
                  >
                    <Grid gap="giga" rowsGap="mega" columns={1}>
                      <Grid gap="giga" columns={1}>
                        <Checkbox
                          data-test-id={checkboxName}
                          ref={formMethods.register}
                          name={checkboxName}
                          label={t(
                            `${translationPrefix}.fields.financialAssets.${checkbox}.range.label`
                          )}
                          helperText={tooltip}
                        />
                      </Grid>
                      {watchCheckbox && (
                        <Grid
                          columnsGap="yotta"
                          rowsGap="giga"
                          columns={{ sm: 1, md: 2 }}
                        >
                          <SelectInput
                            name={`wealthAssessment.financialAssets.${checkbox}.range`}
                            label={t(
                              `${translationPrefix}.fields.financialAssets.${checkbox}.range.label`
                            )}
                            required
                            options={getSelectOptionsFromTranslation(
                              `${translationPrefix}.fields.financialAssets.${checkbox}.range.values`,
                              t
                            )}
                            value={
                              defaultData.wealthAssessment?.financialAssets?.[
                                checkbox
                              ]?.range ?? undefined
                            }
                            ref={formMethods.register}
                          />
                          {watchRange === MoneyRangeLarge.MORE_THAN_1000000 && (
                            <MonetaryInput
                              ref={formMethods.register}
                              required
                              name={`wealthAssessment.financialAssets.${checkbox}.value`}
                              label={t(
                                `${translationPrefix}.fields.financialAssets.${checkbox}.value.label`
                              )}
                              errorMessage={
                                errors.wealthAssessment?.financialAssets?.[
                                  checkbox
                                ]?.value?.message
                              }
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {(errors as any).wealthAssessment?.financialAssets?.message && (
                <Text variant="error" size="1">
                  {errors &&
                    (errors as any).wealthAssessment?.financialAssets?.message}
                </Text>
              )}
              <Grid columns={1}>
                <HeadingWrapper>
                  <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                    {t(`${translationPrefix}.balanceSheetItemsHeadline`)}
                  </Heading>
                </HeadingWrapper>
              </Grid>
              <Grid
                columnsGap="yotta"
                rowsGap="giga"
                columns={{ sm: 1, md: 2 }}
              >
                <ChoiceGroup
                  required
                  name="wealthAssessment.totalAssets"
                  label={t(`${translationPrefix}.fields.totalAssets.label`)}
                  errorMessage={
                    formMethods.errors.wealthAssessment?.totalAssets?.message
                  }
                >
                  <Grid columns={{ sm: 1, lg: 2 }} gap="mega">
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="wealthAssessment.totalAssets"
                      value={BalanceSheetRange.Less_THAN_20_MIL}
                      label={t(
                        `${translationPrefix}.fields.totalAssets.values.Less_THAN_20_MIL`
                      )}
                    />
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="wealthAssessment.totalAssets"
                      value={BalanceSheetRange.MORE_THAN_20_MIL}
                      label={t(
                        `${translationPrefix}.fields.totalAssets.values.MORE_THAN_20_MIL`
                      )}
                    />
                  </Grid>
                </ChoiceGroup>
                <ChoiceGroup
                  required
                  name="wealthAssessment.sales"
                  label={t(`${translationPrefix}.fields.sales.label`)}
                  errorMessage={
                    formMethods.errors.wealthAssessment?.sales?.message
                  }
                >
                  <Grid columns={{ sm: 1, lg: 2 }} gap="mega">
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="wealthAssessment.sales"
                      value={BalanceSheetRangeLarge.LESS_THAN_40_MIL}
                      label={t(
                        `${translationPrefix}.fields.sales.values.LESS_THAN_40_MIL`
                      )}
                    />
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="wealthAssessment.sales"
                      value={BalanceSheetRangeLarge.MORE_THAN_40_MIL}
                      label={t(
                        `${translationPrefix}.fields.sales.values.MORE_THAN_40_MIL`
                      )}
                    />
                  </Grid>
                </ChoiceGroup>
                <ChoiceGroup
                  required
                  name="wealthAssessment.ownFunds"
                  label={t(`${translationPrefix}.fields.ownFunds.label`)}
                  errorMessage={
                    formMethods.errors.wealthAssessment?.ownFunds?.message
                  }
                >
                  <Grid columns={{ sm: 1, lg: 2 }} gap="mega">
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="wealthAssessment.ownFunds"
                      value={BalanceSheetRangeSmall.LESS_THAN_2_MIL}
                      label={t(
                        `${translationPrefix}.fields.ownFunds.values.LESS_THAN_2_MIL`
                      )}
                    />
                    <ChoiceGroup.Radio
                      ref={formMethods.register}
                      name="wealthAssessment.ownFunds"
                      value={BalanceSheetRangeSmall.MORE_THAN_2_MIL}
                      label={t(
                        `${translationPrefix}.fields.ownFunds.values.MORE_THAN_2_MIL`
                      )}
                    />
                  </Grid>
                </ChoiceGroup>
              </Grid>
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default WealthAssessmentForm;
