import { ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY } from "../generated/globalTypes";

export const fundCurrencyTranslation = (
  currency: ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY | undefined
): string =>
  `format.${
    currency === ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.eur
      ? "price"
      : "priceUSD"
  }`;
