import { Loading } from "@finvia/component-library";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import QuestionnaireController from "../../components/QuestionnaireController";
import { InvestmentOpportunityFormStep } from "../../formSteps";
import { ROUTES } from "../../../../routes/routes";
import { Dashboard } from "../../funds/Dashboard";
import { GoalsForm } from "./GoalsForm";
import HorizonForm from "./HorizonForm/HorizonForm";
import { deriveFormAccess } from "./investorProfileValidations";
import { RelevantAssetClasses } from "./RelevantAssetClasses";
import RiskBearingCapacityForm from "./RiskBearingCapacity/RiskBearingCapacityForm";

const InvestorProfileQuestionnaire: React.FC = () => {
  const history = useHistory();
  const [atMostAccessibleStep, setAtMostAccessibleStep] = useState<
    InvestmentOpportunityFormStep | undefined
  >(undefined);
  const onComplete = useCallback(() => {
    history.push(ROUTES.questionnaires.ai.root);
  }, [history]);

  const { investorProfileData, isComplete } = useSelector((state) => ({
    investorProfileData:
      state.alternativeInvestments.investmentOpportunities.data,
    isComplete: state.alternativeInvestments.investmentOpportunities.isComplete,
  }));

  useEffect(() => {
    setAtMostAccessibleStep(
      deriveFormAccess(investorProfileData).atMostAccessibleStep
    );
  }, [investorProfileData]);

  if (!atMostAccessibleStep) {
    return <Loading />;
  }

  return (
    <QuestionnaireController
      atMostAccessibleStep={atMostAccessibleStep}
      onBack={() => history.push(ROUTES.questionnaires.ai.root)}
      onComplete={onComplete}
      disableLayoutOnSteps={[InvestmentOpportunityFormStep.Opportunities]}
      isComplete={isComplete}
      stepGroups={[
        {
          label: "Ziel",
          steps: [
            {
              id: InvestmentOpportunityFormStep.InvestmentGoal,
              component: GoalsForm,
            },
            {
              id: InvestmentOpportunityFormStep.InvestmentHorizon,
              component: HorizonForm,
            },
            {
              id: InvestmentOpportunityFormStep.RiskAssessment,
              component: RiskBearingCapacityForm,
            },
          ],
        },
        {
          label: "Präferenzen",
          steps: [
            {
              id: InvestmentOpportunityFormStep.InvestmentPreferences,
              component: RelevantAssetClasses,
            },
          ],
        },
        {
          label: "Opportunitäten",
          steps: [
            {
              id: InvestmentOpportunityFormStep.Opportunities,
              component: Dashboard,
            },
          ],
        },
      ]}
    />
  );
};

export default InvestorProfileQuestionnaire;
