import { useTranslation } from "react-i18next";
import { ENUM_ALTERNATIVEINVESTMENTFUND_STATUS } from "../../../../../generated/globalTypes";
import { Grid, Icon } from "@finvia/money-ui";
import { isPlannedFund } from "features/alternative-investments/components/FundCard";
import { isDevEnvironment } from "utils/helpers";
import { FundButton, FundLink } from ".";
import type { FundCardFooterProps } from ".";

const translationPrefix = "components.alternativeInvestmentFund";

const FundCardFooter = ({
  status,
  wishlisted,
  footerLinkUrl,
  showIntentionForm,
  slug,
  isClient,
}: FundCardFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const formattedSlug = slug && slug.replace(/-/g, "_");
  const trackingLabel = `funds_${formattedSlug}_potential_commitment`;

  const FundCardFooterLink: () => JSX.Element = () => {
    if (isPlannedFund(status)) {
      return wishlisted ? (
        <FundButton disabled>
          <Icon name="done" color="neutral.500" size="bit" />
          {t(`${translationPrefix}.wishlist.added`)}
        </FundButton>
      ) : (
        <FundButton
          onClick={() => showIntentionForm?.(true)}
          data-analytics={trackingLabel}
          disabled={!isClient}
        >
          {t(`${translationPrefix}.wishlist.add`)}
        </FundButton>
      );
    }

    if (status === ENUM_ALTERNATIVEINVESTMENTFUND_STATUS.open) {
      return (
        <FundLink to={footerLinkUrl}>
          {t("components.alternativeInvestmentFund.seeDetails")}
        </FundLink>
      );
    }

    return (
      <FundButton disabled>
        {t("components.alternativeInvestmentFund.seeDetails")}
      </FundButton>
    );
  };

  return (
    <Grid justifyItems="center">
      <FundCardFooterLink />
    </Grid>
  );
};

export default FundCardFooter;
