export enum InvestmentOpportunityFormStep {
  InvestmentGoal = 1,
  InvestmentHorizon = 2,
  RiskAssessment = 3,
  InvestmentPreferences = 4,
  Opportunities = 5,
}

export enum NaturalPersonRegulatoryDataFormStep {
  ContractType = 1,
  PersonalData = 2,
  ContactData = 3,
  EmploymentAndTax = 4,
  WealthAssessment = 5,
  Income = 6,
  Liabilities = 7,
  ExperienceAssetClasses = 8,
  ExperienceCurrencies = 9,
  BankAccounts = 10,
}

export enum LegalEntityRegulatoryDataFormStep {
  ContractType = 1,
  LegalRepresentative = 2,
  UltimateBeneficiary = 3,
  PersonalData = 4,
  ContactData = 5,
  Employment = 6,
  ExperienceAssetClasses = 7,
  ExperienceCurrencies = 8,
  LegalEntityName = 9,
  BusinessActivity = 10,
  BusinessIndustry = 10.2,
  LegalEntityContactData = 11,
  WealthAssessment = 12,
  Revenue = 13,
  Liabilities = 14,
  Tax = 15,
  Documents = 16,
  BankAccounts = 17,
  AddLegalEntityPersons = 18,
}

export enum MandateContractLegalEntityFormStep {
  Declaration = 1,
  IdentificationList = 2,
  Identification = 3,
  RepresentativesIdentificationList = 4,
  IdentificationStatus = 5,
  MandateContract = 6,
}

export enum MandateContractNaturalPersonFormStep {
  Declaration = 1,
  Identification = 2,
  MandateContract = 3,
  IdentificationList = 4,
}
