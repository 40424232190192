import { ApolloClient } from "@apollo/client";
import {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
} from "@reduxjs/toolkit";

export type AppExtra = {
  client: ApolloClient<unknown>;
};

export function typedCreateAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<
    Returned,
    ThunkArg,
    { extra: AppExtra }
  >,
  options?: AsyncThunkOptions<ThunkArg, { extra: AppExtra }>
): AsyncThunk<Returned, ThunkArg, Record<string, unknown>> {
  return createAsyncThunk(typePrefix, payloadCreator, options);
}
