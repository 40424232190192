import { TagProps } from "@finvia/money-ui";
import {
  FundFragment_attributes,
  FundFragment_attributes_additionalTagsList,
} from "generated/FundFragment";
import { ENUM_ALTERNATIVEINVESTMENTFUND_STATUS } from "generated/globalTypes";
import { TagsList } from "./FundCard.types";

export const isPlannedFund = (
  status: FundFragment_attributes["status"]
): boolean => status === ENUM_ALTERNATIVEINVESTMENTFUND_STATUS.planned;

export const mapAdditionalTagsList = (
  valueFromCMS: (FundFragment_attributes_additionalTagsList | null)[]
): TagsList =>
  valueFromCMS.map((value) =>
    value
      ? {
          label: value.additionalTag,
        }
      : null
  );

export const mapStatusToTagVariant: Record<
  FundFragment_attributes["status"],
  TagProps["variant"]
> = {
  open: "success",
  planned: "warning",
  closed: "danger",
  hidden: undefined,
} as const;
