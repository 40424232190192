import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  ChoiceGroup,
  Stack,
  Text,
  TextAreaInput,
} from "@finvia/money-ui";

import {
  FundDeclarationsForm,
  semiProfessionalCheckboxNames,
} from "../../fundDeclarationsValidations";

import {
  AdditionalText,
  CheckboxWrapper,
} from "features/alternative-investments/questionnaires/mandateContract/common/DeclarationForm/Declarations.styled";

import { SubCheckboxWrapper } from "../../FundDeclarations.styled";
import {
  KeyOfSemiProfessionalConfig,
  SemiProfessionalFields,
  UnionSemiProfessionalInvestor,
} from "./SemiProfessional.types";
import { semiProfessionalFieldsConfig } from "./SemiProfessional.utils";

const translationPrefix = "q-ai.opportunities.form.fundDeclarations";

const SemiProfessional = (): JSX.Element => {
  const formMethods = useFormContext<FundDeclarationsForm>();
  const { watch, errors, register } = formMethods;
  const { t } = useTranslation();

  const watchSemiProfessionalInvestorFields: Record<
    UnionSemiProfessionalInvestor,
    boolean
  > = watch("declarations.semiProfessionalInvestor");

  return (
    <>
      {semiProfessionalCheckboxNames.map((checkbox) => {
        const checkboxName = `declarations.semiProfessionalInvestor.${checkbox}`;
        return (
          <CheckboxWrapper key={checkbox}>
            <Stack gutter="giga">
              <Stack>
                <Checkbox
                  ref={register}
                  name={checkboxName}
                  label={t(
                    `${translationPrefix}.fields.contract.semiProfessionalInvestor.${checkbox}.label`
                  )}
                />

                <AdditionalText>
                  <Stack>
                    <Text>
                      {t(
                        `${translationPrefix}.fields.contract.semiProfessionalInvestor.${checkbox}.description`
                      )}
                    </Text>

                    {errors.declarations?.semiProfessionalInvestor?.[
                      checkbox
                    ] && (
                      <Text size={1} variant="error">
                        {
                          errors.declarations?.semiProfessionalInvestor?.[
                            checkbox
                          ]?.message
                        }
                      </Text>
                    )}
                  </Stack>
                </AdditionalText>
              </Stack>
              {checkbox in semiProfessionalFieldsConfig &&
                watchSemiProfessionalInvestorFields[
                  checkbox as KeyOfSemiProfessionalConfig
                ] === true && (
                  <SubCheckboxWrapper>
                    <ChoiceGroup
                      name={
                        semiProfessionalFieldsConfig[
                          checkbox as keyof SemiProfessionalFields
                        ].radio.name
                      }
                      label={t(
                        `${translationPrefix}.fields.contract.semiProfessionalInvestor.${checkbox}.radioFields.DEFAULT.label`
                      )}
                      hideLabel
                    >
                      <Stack gutter="mega" direction="row">
                        {semiProfessionalFieldsConfig[
                          checkbox as KeyOfSemiProfessionalConfig
                        ].radio.options.map((radioOption) => {
                          const textAreaLabel = t(
                            `${translationPrefix}.fields.contract.semiProfessionalInvestor.${checkbox}.radioFields.${radioOption}.textareaLabel`
                          );

                          const textArea = semiProfessionalFieldsConfig[
                            checkbox as KeyOfSemiProfessionalConfig
                          ].textarea.name
                            .split(".")
                            .pop();

                          return (
                            <Stack key={radioOption} gutter="kilo">
                              <ChoiceGroup.Radio
                                ref={register}
                                label={t(
                                  `${translationPrefix}.fields.contract.semiProfessionalInvestor.${checkbox}.radioFields.${radioOption}.label`
                                )}
                                name={
                                  semiProfessionalFieldsConfig[
                                    checkbox as KeyOfSemiProfessionalConfig
                                  ].radio.name
                                }
                                value={radioOption}
                              />
                              {watch(
                                semiProfessionalFieldsConfig[
                                  checkbox as KeyOfSemiProfessionalConfig
                                ].radio.name
                              ) === radioOption && (
                                <Box width="100%">
                                  <SubCheckboxWrapper>
                                    <TextAreaInput
                                      crossOrigin
                                      required
                                      hideLabel={textAreaLabel === null}
                                      ref={register}
                                      name={
                                        semiProfessionalFieldsConfig[
                                          checkbox as KeyOfSemiProfessionalConfig
                                        ].textarea.name
                                      }
                                      label={textAreaLabel}
                                      errorMessage={
                                        textArea
                                          ? errors.declarations
                                              ?.semiProfessionalInvestor?.[
                                              textArea as KeyOfSemiProfessionalConfig
                                            ]?.message
                                          : ""
                                      }
                                    />
                                  </SubCheckboxWrapper>
                                </Box>
                              )}
                            </Stack>
                          );
                        })}
                      </Stack>
                    </ChoiceGroup>
                  </SubCheckboxWrapper>
                )}
            </Stack>
          </CheckboxWrapper>
        );
      })}
    </>
  );
};

export default SemiProfessional;
