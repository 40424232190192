import styled, { css } from "styled-components";

const listBaseStyles = () => css`
  ${({ theme: { tokens } }) => css`
    margin: ${tokens.sizes.spacings.mega} 0 0 0;
    padding-left: ${tokens.sizes.spacings.kilo};
  `};
`;

const itemBaseStyles = () => css`
  margin-bottom: ${({ theme: { tokens } }) => tokens.sizes.spacings.mega};
`;

const imageWrapperBaseStyles = () => css`
  display: flex;
  align-items: center;
  gap: ${({ theme: { tokens } }) => tokens.sizes.spacings.kilo};

  &:first-child {
    max-height: 72px;
  }
`;

export const List = styled.ol(listBaseStyles);
export const Item = styled.li(itemBaseStyles);
export const ImageWrapper = styled.div(imageWrapperBaseStyles);
