import { GetAIFundContractDocumentSet_alternativeInvestmentsFundContract } from "../../../../generated/GetAIFundContractDocumentSet";
import {
  getAIFundContractDocumentSet as getAIFundContract,
  getAIFundContractDocumentSet,
  getAIFundContractDocumentSubscriptionDocumentDraft,
} from "../actions";
import config from "../../../../config";
import { FundFragment_attributes_trustAndAdministrationDocument } from "generated/FundFragment";

type Document = {
  id?: string;
  name: string;
  displayName: string;
  downloadName: string;
  url?: string;
  asyncDocument?: boolean;
  mimetype: string;
};

type DocumentSetNames =
  | "trustAndAdministrationContract"
  | "subscriptionDocumentDraft";

type LegalStructuresNames = "finvia_luxembourg" | "finvia" | "external";

type LegalStructures = {
  action: GetAIFundContract;
  documents: DocumentSetNames[];
};

type LegalStructuresDocuments = {
  [key in LegalStructuresNames]: LegalStructures[];
};

type FundDocuments = {
  subscriptionDocumentDraftId?: string | null;
  trustAndAdministrationContractId?: string | null;
  translationPrefix: string;
  t: (translationPrefix: string) => string;
  additionalDocumentUrl?: string;
  trustAndAdministrationDocument?: FundFragment_attributes_trustAndAdministrationDocument | null;
  documentsToValidate: Record<string, boolean | null>;
};

export type GetAIFundContract = typeof getAIFundContract;
interface PollDocuments {
  getDocument: (
    documentAction: GetAIFundContract,
    fundName: string
  ) => Promise<void | GetAIFundContractDocumentSet_alternativeInvestmentsFundContract | null>;
  documentAction: GetAIFundContract;
  documentNames: DocumentSetNames[];
  fundName: string;
}

export const pollingDocuments = ({
  getDocument,
  documentAction,
  documentNames,
  fundName,
}: PollDocuments): void => {
  let attempts = 0;
  const maxAttempts = config.polling.maxAttempts;
  const interval = config.polling.interval;

  const executePolling = async () => {
    const result = await getDocument(documentAction, fundName);
    attempts++;

    const doneLoading = documentNames.every(
      (docname) => result?.documentSet?.[docname]?.id
    );

    if (doneLoading) {
      return true;
    } else if (maxAttempts && attempts === maxAttempts) {
      throw new Error("Exceeded max pollingattempts");
    } else {
      setTimeout(executePolling, interval);
    }
  };

  setTimeout(executePolling, interval);
};

export const legalStructuresDocuments: LegalStructuresDocuments = {
  finvia_luxembourg: [
    {
      action: getAIFundContractDocumentSubscriptionDocumentDraft,
      documents: ["subscriptionDocumentDraft"],
    },
  ],
  finvia: [
    {
      action: getAIFundContractDocumentSet,
      documents: [
        "trustAndAdministrationContract",
        "subscriptionDocumentDraft",
      ],
    },
  ],
  external: [
    {
      action: getAIFundContractDocumentSet,
      documents: [
        "trustAndAdministrationContract",
        "subscriptionDocumentDraft",
      ],
    },
  ],
};

export const fundDocuments = ({
  subscriptionDocumentDraftId,
  trustAndAdministrationContractId,
  translationPrefix,
  t,
  additionalDocumentUrl,
  trustAndAdministrationDocument,
  documentsToValidate,
}: FundDocuments): Document[] =>
  [
    {
      name: "signing.acquisitionOfShareholdingFileClicked",
      id: subscriptionDocumentDraftId || undefined,
      displayName: t(`${translationPrefix}.signingDocuments`),
      downloadName: "FINVIA-Zeichnungsunterlagen.pdf",
      asyncDocument: true,
      mimetype: "application/pdf",
    },
    {
      name: "signing.trustAndAdministrationContractFileClicked",
      ...(trustAndAdministrationDocument?.data
        ? {
            id: trustAndAdministrationDocument.data?.id || undefined,
            url: trustAndAdministrationDocument.data?.attributes?.url,
          }
        : { id: trustAndAdministrationContractId || undefined }),
      displayName: t(`${translationPrefix}.trustDocuments`),
      downloadName: "FINVIA-Treuhand-Verwaltungsvertrag.pdf",
      asyncDocument: true,
      mimetype: "application/pdf",
    },
    {
      name: "signing.riskOfAcquisitionFileClicked",
      url: additionalDocumentUrl,
      displayName: t(`${translationPrefix}.additionalDocuments`),
      downloadName: "FINVIA-Zusatzdokumente.pdf",
      mimetype: "application/pdf",
    },
  ].filter(({ name }) => {
    const document = name.replace(/signing.|Clicked/g, "");

    return documentsToValidate[document];
  });
