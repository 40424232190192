import { useTranslation } from "react-i18next";

import { Heading, Stack, Table } from "@finvia/money-ui";
import { customTableStyles } from "./InvestmentExample.styled";
import { getHeaders, getRows } from "./utils";
import { InvestmentExampleProps } from "./InvestmentExample.types";

const tPrefix =
  "components.alternativeInvestmentFund.profile.secondSubSection.standardFund";

const InvestmentExample = ({
  investmentExamples,
}: InvestmentExampleProps): React.ReactElement | null => {
  const { t } = useTranslation();

  if (!investmentExamples) {
    console.error(`@InvestmentExamples does not exist in the Profile section`);
    return null;
  }

  return (
    <Stack gutter="giga">
      <Heading as="h2" size={{ sm: 5, lg: 6 }}>
        {t(`${tPrefix}.title`)}
      </Heading>
      <Table
        headers={getHeaders(t)}
        rows={getRows({
          investmentExamples,
        })}
        customStyles={customTableStyles}
      />
    </Stack>
  );
};

export default InvestmentExample;
