import {
  Box,
  Button,
  Stack,
  Card,
  Grid,
  Tabs,
  InfoBox,
  Heading,
  Text,
} from "@finvia/money-ui";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useLocation } from "react-router-dom";
import LoadingWrapper from "../../../../components/LoadingWrapper/LoadingWrapper";
import { FundFragment } from "../../../../types/types";
import { FundCard } from "../../components/FundCard";
import { ROUTES } from "../../../../routes/routes";
import { getFundsCollection } from "../actions";
import { FundCardFooter } from "../components/FundCardFooter";
import { StepComponentProps } from "features/alternative-investments/components/QuestionnaireController";
import { ENUM_ALTERNATIVEINVESTMENTFUND_STATUS } from "generated/globalTypes";
import { DesktopText, MobileText } from "./Dashboard.styled";

const translationPrefix = "q-ai.opportunities.form.opportunities";

const MIN_LOADING_SCREEN_DISPLAY_DURATION = 2000;

const Dashboard: React.FC<StepComponentProps> = ({
  previousStep,
  nextStep,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { funds } = useSelector((state) => {
    return {
      funds: state.alternativeInvestments.alternativeInvestmentFunds.funds,
    };
  });
  const location = useLocation();

  useEffect(() => {
    if (funds?.ids.length > 0) {
      setLoading(false);
      return;
    }

    (async () => {
      try {
        await dispatch(getFundsCollection());
        setTimeout(() => {
          setLoading(false);
        }, MIN_LOADING_SCREEN_DISPLAY_DURATION);
      } catch (error) {
        // TODO: Handle error visually, allow for retrying
      }
    })();
  }, [dispatch, funds.ids.length]);

  const tabs: string[] = [
    t(`${translationPrefix}.all`),
    t(`${translationPrefix}.open`),
    t(`${translationPrefix}.closed`),
    t(`${translationPrefix}.planning`),
  ];
  const sections: string[] = ["all", "open", "closed", "planned"];
  const filteredFunds = Object.values(funds?.entities)
    .filter(
      (fund) =>
        fund?.attributes?.status !==
        ENUM_ALTERNATIVEINVESTMENTFUND_STATUS.hidden
    )
    .filter((fund) => {
      if (sections[activeTab] === "all") {
        return true;
      }
      return fund?.attributes?.status === sections[activeTab];
    }) as FundFragment[];

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  return (
    <LoadingWrapper
      title={t("loadingScreen.title")}
      message={t("loadingScreen.message")}
      isLoading={loading}
    >
      <Card
        paddingTopBottom={{ sm: "giga", md: "peta" }}
        paddingLeftRight={{ sm: "kilo", md: "peta" }}
        borderRadius="bit"
      >
        <Stack gutter={{ sm: "giga", md: "tera" }}>
          <Stack gutter="mega" alignItems="center">
            <Heading
              size={{ sm: 6, md: 7 }}
              data-testid="title"
              as="h1"
              align="center"
            >
              <DesktopText>{t(`${translationPrefix}.title`)}</DesktopText>
              <MobileText> {t(`${translationPrefix}.titleMobile`)}</MobileText>
            </Heading>
            <Text align="center">{t(`${translationPrefix}.subtitle`)}</Text>
          </Stack>
          <Box width="100%">
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onClick={(tab) => handleTabClick(tab)}
            />
          </Box>
          {filteredFunds.length ? (
            <Grid columns={{ sm: 1, md: 2 }} gap={{ sm: "kilo", md: "peta" }}>
              {filteredFunds?.map((fund: FundFragment) => (
                <FundCard
                  key={`fund-${fund.id}`}
                  model={fund}
                  fundCardFooter={
                    fund.attributes?.status ? (
                      <FundCardFooter
                        status={fund.attributes?.status}
                        footerLinkUrl={generatePath(ROUTES.funds.details.root, {
                          slug: fund.attributes?.slug || undefined,
                          section: "overview",
                        })}
                        slug={fund.attributes?.slug}
                        wishlisted={fund.wishlisted}
                      />
                    ) : (
                      <></>
                    )
                  }
                />
              ))}
            </Grid>
          ) : (
            <Grid>
              <InfoBox>{t(`${translationPrefix}.noFundsFound`)}</InfoBox>
            </Grid>
          )}

          {location.pathname !== "/funds" && (
            <Stack direction="row" justifyContent="space-between">
              <Button variant="ghost" onClick={previousStep}>
                {t("onboarding.form.back")}
              </Button>

              <Button onClick={nextStep}>
                {t("onboarding.form.continue")}
              </Button>
            </Stack>
          )}
        </Stack>
      </Card>
    </LoadingWrapper>
  );
};

export default Dashboard;
