import { Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const extraFieldWrapper = () => css`
  width: 100%;
`;

const extraFieldWrapperWithDeleteIcon = ({ theme }: { theme: Theme }) =>
  css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr ${theme.tokens.sizes.spacings.giga};
    gap: ${theme.tokens.sizes.spacings.mega};
  `;

export const RemoveIconWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 68px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    padding-bottom: ${theme.tokens.sizes.spacings.bit};
  `}
`;

export const ExtraFieldWrapper = styled.div<{ theme: Theme }>(
  extraFieldWrapper,
  extraFieldWrapperWithDeleteIcon
);
