import styled, { css } from "styled-components";

const wrapperStyles = () => css`
  ${({ theme }) => css`
    width: 100%;
    display: grid;
    align-items: baseline;
    grid-template-columns: 1fr;
    row-gap: ${theme.tokens.sizes.spacings.mega};

    ${theme.utils.viewport.md` 
      grid-template-columns: repeat(2, 1fr);
      column-gap: ${theme.tokens.sizes.spacings.yotta};
    `}
  `};
`;

export const Wrapper = styled.div(wrapperStyles);
