import { useTranslation } from "react-i18next";
import { Text } from "@finvia/money-ui";

import { ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY } from "generated/globalTypes";

import { AmountWrapper } from "./FundAmountHeader.styled";
import { FundAmountHeaderProps } from "./FundAmountHeader.types";

export const FundAmountHeader: (props: FundAmountHeaderProps) => JSX.Element = (
  props
) => {
  const { t } = useTranslation();

  return (
    <AmountWrapper>
      <Text size="3" invertTheme>
        {`${t("components.alternativeInvestmentFund.fundSigning.amount")}: ${
          props.fundCurrency === ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.eur
            ? t("format.price", {
                value: props.fundAmount,
              })
            : t("format.priceUSD", {
                value: props.fundAmount,
              })
        }`}
      </Text>
    </AmountWrapper>
  );
};
