import { Theme } from "@finvia/component-library";
import { GlobalStyle, theme } from "@finvia/money-ui";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import type { ScriptConfig } from "utils/scriptInjection";
import scriptInjection from "utils/scriptInjection";
import App from "./App";
import AnalyticsGate from "./components/AnalyticsGate";
import StoreProvider from "./components/StoreProvider";
import config from "./config";
import configureI18n from "./i18n/config";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";

const run = async () => {
  await configureI18n();

  const scriptsToInject: ScriptConfig = [
    {
      name: "cookiefirst",
      src: "https://consent.cookiefirst.com/banner.js",
      async: true,
      attributes: {
        "data-cookiefirst-key": config.thirdParty.cookiefirst?.apiKey,
      },
    },
  ];

  if (config.featureFlags.cookieFirst) {
    scriptInjection(scriptsToInject);
  }

  window.config = {
    posthog: {
      key: config.analytics.postHog.apiKey,
    },
  };

  if (config.sentry.isEnabled) {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.appEnvironment,
      integrations: [new BrowserTracing()],
      release: config.version,
      tracesSampleRate: 1.0,
      denyUrls: [
        /https?:\/\/((cdn|www)\.)?eu.posthog\.com/,
        /https?:\/\/((cdn|www)\.)?(consent|edge).cookiefirst\.com/gi,
      ],
    });
  }

  ReactDOM.render(
    <Sentry.ErrorBoundary
      fallback={<p>An error has occurred</p>}
      onError={(error) =>
        config.appEnvironment !== "production" && console.error(error)
      }
    >
      <React.StrictMode>
        <Auth0Provider
          domain={config.auth0IssuesBaseUrl}
          clientId={config.auth0ClientId}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: config.apiUri,
          }}
        >
          <BrowserRouter basename={config.basePath}>
            <Theme>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <AnalyticsGate isAnalyticsEnabled>
                  <StoreProvider>
                    <App />
                  </StoreProvider>
                </AnalyticsGate>
              </ThemeProvider>
            </Theme>
          </BrowserRouter>
        </Auth0Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById("root")
  );
};

run();
