import { Loading } from "@finvia/component-library";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import QuestionnaireController from "../../../components/QuestionnaireController";
import { MandateContractLegalEntityFormStep } from "../../../formSteps";
import ContractConfirmation from "../common/ContractConfirmationForm/ContractConfirmationForm";
import { Declaration } from "../common/DeclarationForm";
import { deriveFormAccess } from "./legalEntityValidations";
import { ROUTES } from "../../../../../routes/routes";
import IdentificationForm from "./Identification/Identification";
import IdentificationList from "./IdentificationList/IdentificationList";
import IdentificationStatus from "./IdentificationStatus/IdentificationStatus";
import RepresentativesIdentificationList from "./RepresentativesIdentificationList/RepresentativesIdentificationList";
import { AccumulatedIdentificationStatus } from "generated/globalTypes";

const MandateContractQuestionnaire: React.FC = () => {
  const [atMostAccessibleStep, setAtMostAccessibleStep] = useState<
    MandateContractLegalEntityFormStep | undefined
  >(undefined);

  const history = useHistory();

  const mandateContractData = useSelector(
    (state) => state.alternativeInvestments.mandateContractData.data
  );

  useEffect(() => {
    setAtMostAccessibleStep(
      deriveFormAccess({
        contractConfirmation:
          mandateContractData.naturalPerson.contractConfirmation,
        declaration: mandateContractData.naturalPerson.declaration,
        videoIdentStatus: mandateContractData.naturalPerson.videoIdentStatus,
        accumulatedIdentificationStatus:
          mandateContractData.legalEntity.accumulatedIdentificationStatus,
        legalRepresentative:
          mandateContractData.legalEntity.legalRepresentative,
        additionalLegalRepresentatives:
          mandateContractData.legalEntity.additionalLegalRepresentatives,
      }).atMostAccessibleStep
    );
  }, [mandateContractData]);

  if (!atMostAccessibleStep) {
    return <Loading />;
  }

  const shouldGoToMandateContract =
    mandateContractData.legalEntity.accumulatedIdentificationStatus ===
    AccumulatedIdentificationStatus.SUCCESSFUL
      ? MandateContractLegalEntityFormStep.MandateContract
      : atMostAccessibleStep;

  return (
    <QuestionnaireController
      atMostAccessibleStep={shouldGoToMandateContract}
      onBack={() => history.push(ROUTES.questionnaires.ai.root)}
      onComplete={() => history.push(ROUTES.funds.root)}
      stepGroups={[
        {
          label: "Erklärungen",
          steps: [
            {
              id: MandateContractLegalEntityFormStep.Declaration,
              component: Declaration,
            },
          ],
        },
        {
          label: "Identifikation",
          steps: [
            {
              id: MandateContractLegalEntityFormStep.IdentificationList,
              component: IdentificationList,
            },
            {
              id: MandateContractLegalEntityFormStep.Identification,
              component: IdentificationForm,
              overridePreviousStepValidityCheck: true,
            },
            {
              id: MandateContractLegalEntityFormStep.RepresentativesIdentificationList,
              component: RepresentativesIdentificationList,
            },
            {
              id: MandateContractLegalEntityFormStep.IdentificationStatus,
              component: IdentificationStatus,
            },
          ],
        },
        {
          label: "Mandatsvertrag",
          steps: [
            {
              id: MandateContractLegalEntityFormStep.MandateContract,
              component: ContractConfirmation,
            },
          ],
        },
      ]}
    />
  );
};

export default MandateContractQuestionnaire;
