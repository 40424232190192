import { StyleProps } from "@finvia/money-ui/dist/esm/types";
import styled, { css } from "styled-components";

const fundDetailsCardStyles = ({ theme }: StyleProps) => css`
  box-shadow: 1px 1px 4px 0 rgb(85 116 119 / 31%);
  background-color: ${theme.tokens.colors.white[500]};
  border-radius: ${theme.tokens.sizes.radii.bit};
`;

export const FundDetailsCard = styled.div<StyleProps>(fundDetailsCardStyles);
