import styled, { css } from "styled-components";

const desktopText = () => css`
  ${({ theme }) => css`
    display: none;

    ${theme.utils.viewport.md` 
      display: inline-block;
    `}
  `};
`;

const mobileText = () => css`
  ${({ theme }) => css`
    display: inline-block;

    ${theme.utils.viewport.md` 
      display: none;
    `}
  `};
`;

export const DesktopText = styled.span(desktopText);
export const MobileText = styled.span(mobileText);
