import { useTranslation } from "react-i18next";

import { Heading, Stack, Table } from "@finvia/money-ui";
import { FundFragment } from "generated/FundFragment";
import { customTableStyles } from "./InvestmentPotential.styled";
import { ENUM_ALTERNATIVEINVESTMENTFUND_STATUS } from "generated/globalTypes";
import { getHeaders, getRows } from "./utils";
import { InvestmentPotentialProps } from "./InvestmentPotential.types";

const tPrefix = "components.alternativeInvestmentFund.profile";

const InvestmentPotential = ({
  investmentPotential,
  funds,
}: InvestmentPotentialProps): React.ReactElement | null => {
  const { t } = useTranslation();

  if (!investmentPotential) {
    console.error(`@InvestmentPotential does not exist in the profile section`);
    return null;
  }

  const fundsToShow = investmentPotential.map(
    (fundInvestmentPotential) => fundInvestmentPotential?.fundID
  );

  const filteredFunds = Object.values(funds?.entities)
    ?.filter(
      (fundToFilter) =>
        fundToFilter?.attributes?.status !==
          ENUM_ALTERNATIVEINVESTMENTFUND_STATUS.hidden &&
        fundToFilter !== undefined
    )
    .filter((fundToFilter) =>
      fundsToShow.includes(fundToFilter?.id)
    ) as FundFragment[];

  return (
    <Stack gutter="giga">
      <Heading as="h2" size={{ sm: 5, lg: 6 }}>
        {t(`${tPrefix}.secondSubSection.selectionFund.title`)}
      </Heading>
      <Table
        headers={getHeaders(t)}
        rows={getRows({ funds: filteredFunds, t })}
        customStyles={customTableStyles}
      />
    </Stack>
  );
};

export default InvestmentPotential;
