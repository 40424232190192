import React from "react";
import LoadingDialog from "../LoadingDialog";
import { DigitalIdentificationFrameProps } from "./DigitalIdentificationFrame.types";
import * as S from "./DigitalIdentificationFrame.styled";

const DigitalIdentificationFrame: React.FC<DigitalIdentificationFrameProps> = ({
  url,
  openDialog,
}) => {
  return (
    <S.Wrapper>
      <S.Iframe
        src={url}
        title="ID Now iFrame"
        width="100%"
        sandbox="allow-scripts allow-same-origin"
        allow="camera *; microphone *"
      />
      <LoadingDialog openDialog={openDialog} />
    </S.Wrapper>
  );
};

export default DigitalIdentificationFrame;
