/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const UpsertAlternativeInvestmentsMandateContract = gql`
  mutation UpsertAlternativeInvestmentsMandateContract(
    $input: UpsertAlternativeInvestmentsMandateContractInput!
  ) {
    upsertAlternativeInvestmentsMandateContract(input: $input) {
      code
      success
      message
    }
  }
`;

export const SignAlternativeInvestmentsMandateContract = gql`
  mutation SignAlternativeInvestmentsMandateContract(
    $input: SignAlternativeInvestmentsMandateContractInput!
  ) {
    signAlternativeInvestmentsMandateContract(input: $input) {
      code
      success
      message
    }
  }
`;

export const UpsertAlternativeInvestmentsFundContract = gql`
  mutation UpsertAlternativeInvestmentsFundContract(
    $input: UpsertAlternativeInvestmentsFundContractInput!
  ) {
    upsertAlternativeInvestmentsFundContract(input: $input) {
      code
      success
      message
    }
  }
`;

export const SignAlternativeInvestmentsFundContract = gql`
  mutation SignAlternativeInvestmentsFundContract(
    $input: SignAlternativeInvestmentsFundContractInput!
  ) {
    signAlternativeInvestmentsFundContract(input: $input) {
      code
      success
      message
    }
  }
`;
