import { TFunction } from "i18next";
import * as yup from "yup";
import { NaturalPersonIdentificationStatus } from "../../../../../../generated/globalTypes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) => yup.object({}).required();

export interface IdentificationForm {
  videoIdentStatus?: NaturalPersonIdentificationStatus;
}
