import { TFunction } from "i18next";
import * as yup from "yup";
import { DocumentsToValidate } from "./types";

const translationPrefix = "q-ai.funds.signing.luxembourg";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fundSigningValidationSchema = (
  t: TFunction,
  documentsToValidate: DocumentsToValidate | undefined
) =>
  yup
    .object({
      confirmsAcquisitionOfShareholding: yup
        .boolean()
        .oneOf(
          [true],
          t(`${translationPrefix}.acquisitionOfShareholding.required`)
        ),
      acquisitionOfShareholdingFileClicked:
        documentsToValidate?.acquisitionOfShareholdingFile
          ? yup
              .boolean()
              .oneOf(
                [true],
                t(
                  `${translationPrefix}.acquisitionOfShareholding.documentClickRequired`
                )
              )
          : yup.boolean().notRequired(),
      confirmsRiskOfAcquisition: yup
        .boolean()
        .oneOf([true], t(`${translationPrefix}.riskOfAcquisition.required`)),
      riskOfAcquisitionFileClicked: documentsToValidate?.riskOfAcquisitionFile
        ? yup
            .boolean()
            .oneOf(
              [true],
              t(`${translationPrefix}.riskOfAcquisition.documentClickRequired`)
            )
        : yup.boolean().notRequired(),
      confirmsStatementsAndDisclosures: yup
        .boolean()
        .oneOf(
          [true],
          t(`${translationPrefix}.statementsAndDisclosures.required`)
        ),
      confirmsDataProtection: yup
        .boolean()
        .oneOf([true], t(`${translationPrefix}.dataProtection.required`)),
      confirmsActingOnOwnBehalf: yup
        .boolean()
        .oneOf([true], t(`${translationPrefix}.actingOnOwnBehalf.required`)),
    })
    .required();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchemaLuxembourgLegalStructure = (
  t: TFunction,
  documentsToValidate?: DocumentsToValidate | undefined
) =>
  yup
    .object({
      signing: fundSigningValidationSchema(t, documentsToValidate),
    })
    .required();

export type AIFundContractSigning = yup.InferType<
  ReturnType<typeof validationSchemaLuxembourgLegalStructure>
>;

export interface AIFundContractSigningForm {
  signing?: AIFundContractSigning["signing"];
}

export function validate(
  fundSigningDeclarations: AIFundContractSigningForm
): Promise<any> {
  const t = (k: any) => k;
  return validationSchemaLuxembourgLegalStructure(t).validate(
    fundSigningDeclarations
  );
}
