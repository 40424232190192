import styled, { css } from "styled-components";
import { StyleProps } from "types/types";

const docStyles = ({ theme }: StyleProps) => css`
  margin: 0;
  display: flex;
  position: relative;
  padding-left: ${theme.tokens.sizes.spacings.kilo};

  &:first-of-type {
    padding-left: 0;
    &:before {
      content: none;
    }
  }

  &:before {
    content: " ";
    display: block;
    position: absolute;
    height: 18px;
    width: 1px;
    background-color: ${theme.tokens.colors.neutral[750]};
    left: 5px;
    top: 2px;
  }
`;

export const Document = styled.p<StyleProps>(docStyles);
