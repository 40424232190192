/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccumulatedIdentificationStatus {
  CREATED = "CREATED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  NOT_ATTEMPTED = "NOT_ATTEMPTED",
  PENDING = "PENDING",
  SUCCESSFUL = "SUCCESSFUL",
}

export enum AssetClass {
  ASSETS = "ASSETS",
  HEDGEFUNDS = "HEDGEFUNDS",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  PRIVATE_DEBT = "PRIVATE_DEBT",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
}

export enum AverageInvestmentVolumePerYear {
  LESS_THAN_50000 = "LESS_THAN_50000",
  MORE_THAN_50000 = "MORE_THAN_50000",
}

export enum AverageTransactionsPerYear {
  LESS_THAN_15 = "LESS_THAN_15",
  MORE_THAN_15 = "MORE_THAN_15",
}

export enum BKYCDocumentCategories {
  LEGAL_ENTITY_CURRENT_TRADE_REGISTER = "LEGAL_ENTITY_CURRENT_TRADE_REGISTER",
  LEGAL_ENTITY_FURTHER_SHAREHOLDERS = "LEGAL_ENTITY_FURTHER_SHAREHOLDERS",
  LEGAL_ENTITY_INCORPERATION = "LEGAL_ENTITY_INCORPERATION",
  LEGAL_ENTITY_OTHERS = "LEGAL_ENTITY_OTHERS",
  LEGAL_ENTITY_SHAREHOLDERS_CONTRACT = "LEGAL_ENTITY_SHAREHOLDERS_CONTRACT",
  LEGAL_ENTITY_SHAREHOLDERS_LIST_AG = "LEGAL_ENTITY_SHAREHOLDERS_LIST_AG",
  LEGAL_ENTITY_SHAREHOLDERS_LIST_NON_AG = "LEGAL_ENTITY_SHAREHOLDERS_LIST_NON_AG",
}

export enum BalanceSheetRange {
  Less_THAN_20_MIL = "Less_THAN_20_MIL",
  MORE_THAN_20_MIL = "MORE_THAN_20_MIL",
}

export enum BalanceSheetRangeLarge {
  LESS_THAN_40_MIL = "LESS_THAN_40_MIL",
  MORE_THAN_40_MIL = "MORE_THAN_40_MIL",
}

export enum BalanceSheetRangeSmall {
  LESS_THAN_2_MIL = "LESS_THAN_2_MIL",
  MORE_THAN_2_MIL = "MORE_THAN_2_MIL",
}

export enum BankAccountType {
  REFERENCE = "REFERENCE",
  USD_CURRENCY_ACCOUNT = "USD_CURRENCY_ACCOUNT",
}

export enum BranchOfIndustry {
  AGRICULTURE_FORESTRY = "AGRICULTURE_FORESTRY",
  CONSTRUCTION = "CONSTRUCTION",
  CULTURE_SPORTS_ENTERTAINMENT = "CULTURE_SPORTS_ENTERTAINMENT",
  EDUCATION_TRAINING = "EDUCATION_TRAINING",
  FINANCIAL_SERVICES_INSURANCE = "FINANCIAL_SERVICES_INSURANCE",
  HEALTH_SOCIAL = "HEALTH_SOCIAL",
  HOSPITALITY_TOURISM = "HOSPITALITY_TOURISM",
  LEGAL_TAX_CONSULTANCY = "LEGAL_TAX_CONSULTANCY",
  MANUFACTURING_INDUSTRY = "MANUFACTURING_INDUSTRY",
  MEDIA_PUBLISHING = "MEDIA_PUBLISHING",
  NOT_SPECIFIED = "NOT_SPECIFIED",
  OTHER_SERVICES = "OTHER_SERVICES",
  PUBLIC_SERVICE = "PUBLIC_SERVICE",
  REAL_ESTATE_HOUSING = "REAL_ESTATE_HOUSING",
  RESEARCH_SCIENCES = "RESEARCH_SCIENCES",
  TELECOMMUNICATIONS_IT = "TELECOMMUNICATIONS_IT",
  TRANSPORT_TRAFFIC = "TRANSPORT_TRAFFIC",
  WHOLESALE_RETAIL = "WHOLESALE_RETAIL",
}

export enum ContractType {
  LEGAL_ENTITY = "LEGAL_ENTITY",
  NATURAL_PERSON = "NATURAL_PERSON",
}

export enum Currency {
  EUR = "EUR",
  USD = "USD",
}

export enum DocumentCategory {
  AI_FINVIA_PE_SELECTION_2021_SUBSCRIPTION_DOCUMENT_DRAFT = "AI_FINVIA_PE_SELECTION_2021_SUBSCRIPTION_DOCUMENT_DRAFT",
  AI_FINVIA_PE_SELECTION_2021_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL = "AI_FINVIA_PE_SELECTION_2021_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL",
  AI_FINVIA_PE_SELECTION_2021_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL = "AI_FINVIA_PE_SELECTION_2021_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL",
  AI_FINVIA_TRUST_AND_ADMINISTRATION_AGREEMENT = "AI_FINVIA_TRUST_AND_ADMINISTRATION_AGREEMENT",
  AI_MANDATE_CONTRACT_DRAFT = "AI_MANDATE_CONTRACT_DRAFT",
  AI_MANDATE_CONTRACT_SIGNED = "AI_MANDATE_CONTRACT_SIGNED",
  CAP_VEST_V_SUBSCRIPTION_DOCUMENT_DRAFT = "CAP_VEST_V_SUBSCRIPTION_DOCUMENT_DRAFT",
  CAP_VEST_V_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL = "CAP_VEST_V_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL",
  CAP_VEST_V_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL = "CAP_VEST_V_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL",
  CAP_VEST_V_TRUST_AND_ADMINISTRATION_AGREEMENT = "CAP_VEST_V_TRUST_AND_ADMINISTRATION_AGREEMENT",
  CRS_STATUS_CERTIFICATE = "CRS_STATUS_CERTIFICATE",
  FATCA_STATUS_CERTIFICATE = "FATCA_STATUS_CERTIFICATE",
  GHPVIII_SUBSCRIPTION_DOCUMENT_DRAFT = "GHPVIII_SUBSCRIPTION_DOCUMENT_DRAFT",
  GHPVIII_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL = "GHPVIII_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL",
  GHPVIII_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL = "GHPVIII_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL",
  GHPVIII_TRUST_AND_ADMINISTRATION_AGREEMENT = "GHPVIII_TRUST_AND_ADMINISTRATION_AGREEMENT",
  GLENDOWER_V_SUBSCRIPTION_DOCUMENT_DRAFT = "GLENDOWER_V_SUBSCRIPTION_DOCUMENT_DRAFT",
  GLENDOWER_V_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL = "GLENDOWER_V_SUBSCRIPTION_DOCUMENT_SIGNED_PROFESSIONAL",
  GLENDOWER_V_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL = "GLENDOWER_V_SUBSCRIPTION_DOCUMENT_SIGNED_SEMI_PROFESSIONAL",
  GLENDOWER_V_TRUST_AND_ADMINISTRATION_AGREEMENT = "GLENDOWER_V_TRUST_AND_ADMINISTRATION_AGREEMENT",
  IDENTIFICATION = "IDENTIFICATION",
  IRS = "IRS",
  LEGAL_ENTITY_CURRENT_TRADE_REGISTER = "LEGAL_ENTITY_CURRENT_TRADE_REGISTER",
  LEGAL_ENTITY_FURTHER_SHAREHOLDERS = "LEGAL_ENTITY_FURTHER_SHAREHOLDERS",
  LEGAL_ENTITY_IDENTITY_DOCUMENT = "LEGAL_ENTITY_IDENTITY_DOCUMENT",
  LEGAL_ENTITY_INCORPERATION = "LEGAL_ENTITY_INCORPERATION",
  LEGAL_ENTITY_OTHERS = "LEGAL_ENTITY_OTHERS",
  LEGAL_ENTITY_SHAREHOLDERS_CONTRACT = "LEGAL_ENTITY_SHAREHOLDERS_CONTRACT",
  LEGAL_ENTITY_SHAREHOLDERS_LIST_AG = "LEGAL_ENTITY_SHAREHOLDERS_LIST_AG",
  LEGAL_ENTITY_SHAREHOLDERS_LIST_NON_AG = "LEGAL_ENTITY_SHAREHOLDERS_LIST_NON_AG",
  RESIDENCY_CERTIFICATE = "RESIDENCY_CERTIFICATE",
}

export enum DocumentStatus {
  ARCHIVED = "ARCHIVED",
  CREATED = "CREATED",
  DRAFT = "DRAFT",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_ASSETCLASS {
  hedge_funds = "hedge_funds",
  infrastructure = "infrastructure",
  private_debt = "private_debt",
  private_equity = "private_equity",
  real_estate = "real_estate",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY {
  eur = "eur",
  usd = "usd",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_INVESTMENTTYPE {
  diversified = "diversified",
  primary = "primary",
  secondary = "secondary",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_LEGALSTRUCTURE {
  external = "external",
  finvia = "finvia",
  finvia_luxembourg = "finvia_luxembourg",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_REGION {
  africa = "africa",
  asia = "asia",
  asia_pacific_core = "asia_pacific_core",
  europe = "europe",
  europe_germany = "europe_germany",
  global = "global",
  north_america = "north_america",
  north_america_usa = "north_america_usa",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_STATUS {
  closed = "closed",
  hidden = "hidden",
  open = "open",
  planned = "planned",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_STRATEGY {
  buyout = "buyout",
  buyout_and_growth = "buyout_and_growth",
  buyout_growth_venture = "buyout_growth_venture",
  core = "core",
  core_core_plus = "core_core_plus",
  core_plus = "core_plus",
  core_plus_value_add = "core_plus_value_add",
  distressed = "distressed",
  diversified = "diversified",
  growth = "growth",
  opportunistic = "opportunistic",
  secondary = "secondary",
  value_add = "value_add",
  value_add_opportunistic = "value_add_opportunistic",
  venture_growth = "venture_growth",
}

export enum ENUM_ALTERNATIVEINVESTMENTFUND_TYPE {
  closed = "closed",
  direct = "direct",
  fund_of_funds = "fund_of_funds",
  mezzanine = "mezzanine",
  multi_manager = "multi_manager",
  open = "open",
  secondary_funds = "secondary_funds",
}

export enum ENUM_COMPONENTGENERICTEXTINDUSTRIES_ICON {
  Add = "Add",
  Adjust = "Adjust",
  AlternativeInvestment = "AlternativeInvestment",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  ArrowUp = "ArrowUp",
  AssetManagement = "AssetManagement",
  Attach = "Attach",
  Bank = "Bank",
  BarChart = "BarChart",
  Blog = "Blog",
  Bonds = "Bonds",
  Book = "Book",
  Calculator = "Calculator",
  Calender = "Calender",
  Care = "Care",
  Cart = "Cart",
  Cash = "Cash",
  Centered = "Centered",
  Chat = "Chat",
  Check = "Check",
  Checklist = "Checklist",
  Client = "Client",
  Close = "Close",
  Cloud = "Cloud",
  Company = "Company",
  Computer = "Computer",
  Contract = "Contract",
  Copy = "Copy",
  Deal = "Deal",
  DigitalPropertyManage = "DigitalPropertyManage",
  Done = "Done",
  DonutChart = "DonutChart",
  Download = "Download",
  Edit = "Edit",
  Email = "Email",
  Error = "Error",
  Exit = "Exit",
  ExpectedReturn = "ExpectedReturn",
  ExpectedVolatility = "ExpectedVolatility",
  Expert = "Expert",
  Expert2 = "Expert2",
  Factory = "Factory",
  Failed = "Failed",
  Family = "Family",
  Favorite = "Favorite",
  File = "File",
  Filter = "Filter",
  FinanceCheck = "FinanceCheck",
  Folder = "Folder",
  Goal = "Goal",
  Gold = "Gold",
  Help = "Help",
  Hierarchy = "Hierarchy",
  Home = "Home",
  HouseMarket = "HouseMarket",
  Idea = "Idea",
  Identification = "Identification",
  ImpactInvesting = "ImpactInvesting",
  Info = "Info",
  Infrastructure = "Infrastructure",
  Insurance = "Insurance",
  InvestmentGoal = "InvestmentGoal",
  Key = "Key",
  Library = "Library",
  LineChart = "LineChart",
  LiquidityRatio = "LiquidityRatio",
  Location = "Location",
  Lock = "Lock",
  Meeting = "Meeting",
  Mobile = "Mobile",
  MonitoredData = "MonitoredData",
  Network = "Network",
  NoWifi = "NoWifi",
  NotVisible = "NotVisible",
  Notification = "Notification",
  Notification2 = "Notification2",
  Options = "Options",
  Other = "Other",
  Pension = "Pension",
  People = "People",
  Performance = "Performance",
  PerformanceHigh = "PerformanceHigh",
  PerformanceLow = "PerformanceLow",
  Phone = "Phone",
  Pictures = "Pictures",
  PieChart = "PieChart",
  Podcast = "Podcast",
  Portal = "Portal",
  PrivateDebt = "PrivateDebt",
  PrivateEquity = "PrivateEquity",
  ProbabilityOfLoss = "ProbabilityOfLoss",
  Program = "Program",
  Protect = "Protect",
  Puzzle = "Puzzle",
  RealEstate = "RealEstate",
  RealEstateBonds = "RealEstateBonds",
  Reload = "Reload",
  Report = "Report",
  Report2 = "Report2",
  Routes = "Routes",
  Safe = "Safe",
  Save = "Save",
  Search = "Search",
  Send = "Send",
  Settings = "Settings",
  Settings2 = "Settings2",
  Sign = "Sign",
  SoftwareIntelligence = "SoftwareIntelligence",
  Sports = "Sports",
  Stocks = "Stocks",
  TargetReturn = "TargetReturn",
  Time = "Time",
  Tools = "Tools",
  Trash = "Trash",
  TwentyFourSeven = "TwentyFourSeven",
  Upload = "Upload",
  User = "User",
  VideoChat = "VideoChat",
  VideoIdentification = "VideoIdentification",
  Visible = "Visible",
  Web = "Web",
  Website = "Website",
  Wifi = "Wifi",
}

export enum ENUM_COMPONENTGENERICTEXTTRACKRECORD_PROFILE {
  selectionFund = "selectionFund",
  standarFund = "standarFund",
}

export enum EmploymentStatus {
  DIPLOMAT = "DIPLOMAT",
  EMPLOYEE = "EMPLOYEE",
  MANAGER = "MANAGER",
  PRIVATE_INDIVIDUAL = "PRIVATE_INDIVIDUAL",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  STUDENT = "STUDENT",
}

export enum ExperienceYears {
  LESS_THAN_3 = "LESS_THAN_3",
  MORE_THAN_3 = "MORE_THAN_3",
}

export enum FACTAStatus {
  NO = "NO",
  NO_BUT_US_CITIZENSHIP = "NO_BUT_US_CITIZENSHIP",
  YES = "YES",
}

export enum FundInvestmentEducationAndProfessionalStatus {
  CUSTOM = "CUSTOM",
  DEFAULT = "DEFAULT",
}

export enum FundInvestmentExperienceAndKnowledge {
  CUSTOM = "CUSTOM",
  DEFAULT = "DEFAULT",
}

export enum InvestmentGoal {
  ASSET_ACCUMULATION = "ASSET_ACCUMULATION",
  CONTINUITY = "CONTINUITY",
  FAMILY_PRECAUTION = "FAMILY_PRECAUTION",
  LIQUIDITY_SUPPLY = "LIQUIDITY_SUPPLY",
  OTHERS = "OTHERS",
  PRECAUTION = "PRECAUTION",
  SHORT_TERM_PROFIT = "SHORT_TERM_PROFIT",
}

export enum InvestmentHorizon {
  LONG_TERM = "LONG_TERM",
  MID_TERM = "MID_TERM",
  SHORT_TERM = "SHORT_TERM",
}

export enum InvestorClassificationUnderSupervision {
  COLLECTIVE_INVESTMENT_ORGANISM = "COLLECTIVE_INVESTMENT_ORGANISM",
  CREDIT_INSTITUTION = "CREDIT_INSTITUTION",
  FINANCIAL_INSTITUTION = "FINANCIAL_INSTITUTION",
  INSURANCE_COMPANY = "INSURANCE_COMPANY",
  INVESTING_FIRM = "INVESTING_FIRM",
  MERCHANTS = "MERCHANTS",
  OTHER_INSTITUTIONAL_INVESTOR = "OTHER_INSTITUTIONAL_INVESTOR",
  PENSION_FUND = "PENSION_FUND",
}

export enum InvestorType {
  PROFESSIONAL = "PROFESSIONAL",
  SEMI_PROFESSIONAL = "SEMI_PROFESSIONAL",
}

export enum LegalEntityCRSStatus {
  ACTIVE_NFE = "ACTIVE_NFE",
  NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE = "NON_PARTICIPATING_COUNTRY_FINANCIAL_INSTITUTE",
  NON_REPORTING_FINANCIAL_INSTITUTE = "NON_REPORTING_FINANCIAL_INSTITUTE",
  PASSIVE_NFE = "PASSIVE_NFE",
  REPORTING_FINANCIAL_INSTITUTE = "REPORTING_FINANCIAL_INSTITUTE",
}

export enum LegalEntityCRSStatusOtherCharacteristic {
  ANOTHER_FINANCIAL_INSTITUTION = "ANOTHER_FINANCIAL_INSTITUTION",
  ANOTHER_INVESTMENT_COMPANY = "ANOTHER_INVESTMENT_COMPANY",
  INVESTMENT_COMPANY_BY_ANOTHER_FINANCIAL_INSTITUTION = "INVESTMENT_COMPANY_BY_ANOTHER_FINANCIAL_INSTITUTION",
  PERSON_USA_NO_SPECIFIC = "PERSON_USA_NO_SPECIFIC",
  SPECIFIC_PERSON_USA = "SPECIFIC_PERSON_USA",
}

export enum LegalEntityFATCAStatus {
  ACTIVE_NFFE = "ACTIVE_NFFE",
  EXEMPT_BENEFICIAL_OWNER = "EXEMPT_BENEFICIAL_OWNER",
  FATCA_COMPLIANT_FOREIGN_FINANCIAL_INSTITUTION = "FATCA_COMPLIANT_FOREIGN_FINANCIAL_INSTITUTION",
  GERMAN_FINANCIAL_INSTITUTE = "GERMAN_FINANCIAL_INSTITUTE",
  NON_PARTICIPATING_FINANCIAL_INSTITUTION = "NON_PARTICIPATING_FINANCIAL_INSTITUTION",
  PARTICIPATING_FOREIGN_FINANCIAL_INSTITUTE = "PARTICIPATING_FOREIGN_FINANCIAL_INSTITUTE",
  PASSIVE_NFFE = "PASSIVE_NFFE",
  PERSON_USA_NO_SPECIFIC = "PERSON_USA_NO_SPECIFIC",
  SPECIFIC_PERSON_USA = "SPECIFIC_PERSON_USA",
  TWIN_STATE_FINANCIAL_INSTITUTE = "TWIN_STATE_FINANCIAL_INSTITUTE",
}

export enum LegalEntityFunctionaryRole {
  BOARD_OF_DIRECTORS = "BOARD_OF_DIRECTORS",
  MANAGING_DIRECTOR = "MANAGING_DIRECTOR",
  SHAREHOLDER = "SHAREHOLDER",
}

export enum LegalEntityIdentificationStatus {
  BLOCKED_INTERNALLY = "BLOCKED_INTERNALLY",
  CREATED = "CREATED",
  EXPIRED = "EXPIRED",
  FAILED = "FAILED",
  IDENTIFICATION_SUCCESSFUL = "IDENTIFICATION_SUCCESSFUL",
  INFORMATION_REQUIRED = "INFORMATION_REQUIRED",
  NOT_ATTEMPTED = "NOT_ATTEMPTED",
}

export enum LegalEntityWealthOrigin {
  INCOME = "INCOME",
  INHERITED_OR_GIFTED = "INHERITED_OR_GIFTED",
  OTHER = "OTHER",
  SALE_OF_BUSINESS = "SALE_OF_BUSINESS",
}

export enum LegalForm {
  AG = "AG",
  EG = "EG",
  EK = "EK",
  GBR = "GBR",
  GMBH = "GMBH",
  GMBH_IN_GRUNDUNG = "GMBH_IN_GRUNDUNG",
  KG = "KG",
  OHG = "OHG",
  PART_G = "PART_G",
  UG = "UG",
  UG_IN_GRUNDUNG = "UG_IN_GRUNDUNG",
}

export enum LegalRepresentationType {
  JOINT = "JOINT",
  SOLE = "SOLE",
}

export enum MoneyRangeLarge {
  LESS_THAN_100000 = "LESS_THAN_100000",
  LESS_THAN_1000000 = "LESS_THAN_1000000",
  MORE_THAN_1000000 = "MORE_THAN_1000000",
}

export enum MoneyRangeLiabilities {
  LESS_THAN_100000 = "LESS_THAN_100000",
  LESS_THAN_250000 = "LESS_THAN_250000",
  LESS_THAN_500000 = "LESS_THAN_500000",
  MORE_THAN_500000 = "MORE_THAN_500000",
  NO_LIABILITIES = "NO_LIABILITIES",
}

export enum MoneyRangeNetCapital {
  LESS_THAN_500000 = "LESS_THAN_500000",
  MORE_THAN_500000 = "MORE_THAN_500000",
}

export enum MoneyRangeSmall {
  LESS_THAN_2000 = "LESS_THAN_2000",
  LESS_THAN_5000 = "LESS_THAN_5000",
  MORE_THAN_5000 = "MORE_THAN_5000",
}

export enum MoneyRangeTransactions {
  LESS_THAN_5000 = "LESS_THAN_5000",
  LESS_THAN_50000 = "LESS_THAN_50000",
  MORE_THAN_50000 = "MORE_THAN_50000",
}

export enum NaturalPersonIdentificationStatus {
  IDENTIFICATION_FAILED = "IDENTIFICATION_FAILED",
  IDENTIFICATION_NOT_ATTEMPTED = "IDENTIFICATION_NOT_ATTEMPTED",
  IDENTIFICATION_PENDING = "IDENTIFICATION_PENDING",
  IDENTIFICATION_PENDING_SUCCESSFUL = "IDENTIFICATION_PENDING_SUCCESSFUL",
  IDENTIFICATION_SUCCESSFUL = "IDENTIFICATION_SUCCESSFUL",
}

export enum PEPRelationship {
  BUSINESSPARTNER = "BUSINESSPARTNER",
  CHILD = "CHILD",
  PARENT = "PARENT",
  SPOUSE = "SPOUSE",
  SPOUSE_OF_CHILD = "SPOUSE_OF_CHILD",
}

export enum PEPRole {
  AMBASSADOR = "AMBASSADOR",
  DIRECTOR_OF_INTERGOVERNMENTAL_ORGANISATION = "DIRECTOR_OF_INTERGOVERNMENTAL_ORGANISATION",
  HEAD_OF_AUDIT_OFFICE = "HEAD_OF_AUDIT_OFFICE",
  HEAD_OF_CENTRAL_BANK = "HEAD_OF_CENTRAL_BANK",
  HEAD_OF_PARTY = "HEAD_OF_PARTY",
  HEAD_OF_STATE = "HEAD_OF_STATE",
  HEAD_OF_STATE_OWNED_COMPANY = "HEAD_OF_STATE_OWNED_COMPANY",
  HIGH_RANKING_OFFICIAL = "HIGH_RANKING_OFFICIAL",
  MEMBER_OF_PARLIAMENT = "MEMBER_OF_PARLIAMENT",
  MEMBER_OF_SUPREME_COURT = "MEMBER_OF_SUPREME_COURT",
  PERSON_WITH_SPECIAL_TASKS = "PERSON_WITH_SPECIAL_TASKS",
}

export enum PEPStatus {
  NO = "NO",
  YES_FAMILY_MEMBER = "YES_FAMILY_MEMBER",
  YES_ME = "YES_ME",
}

export enum ProfessionalTitle {
  DIPL_ING = "DIPL_ING",
  DR = "DR",
  DR_DR = "DR_DR",
  PROF = "PROF",
  PROF_DR = "PROF_DR",
  PROF_DR_DR = "PROF_DR_DR",
}

export enum RiskCapacity {
  BALANCED = "BALANCED",
  CONSERVATIVE = "CONSERVATIVE",
  SPECULATIVE = "SPECULATIVE",
  YIELD = "YIELD",
}

export enum Salutation {
  MR = "MR",
  MRS = "MRS",
  NONE = "NONE",
}

export enum Sector {
  CASH_INTENSIVE = "CASH_INTENSIVE",
  CULTURAL_PROPERTY = "CULTURAL_PROPERTY",
  EXCEPTIONAL_SCIENTIFIC_VALUE = "EXCEPTIONAL_SCIENTIFIC_VALUE",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  OIL = "OIL",
  PRECIOUS_METALS = "PRECIOUS_METALS",
  PROTECTED_SPECIES = "PROTECTED_SPECIES",
  TOBACOO_PRODUCTS = "TOBACOO_PRODUCTS",
  WEAPONS = "WEAPONS",
}

export enum TransparencyRegisterObligationOption {
  COMPANY_NOT_OBLIGATED = "COMPANY_NOT_OBLIGATED",
  INVESTOR_ATTACHES_EXCERPT = "INVESTOR_ATTACHES_EXCERPT",
  INVESTOR_NOT_OBLIGATED = "INVESTOR_NOT_OBLIGATED",
}

export enum WealthOrigin {
  INHERITED = "INHERITED",
  OTHERS = "OTHERS",
  SALARY = "SALARY",
  SALE_OF_BUSINESS = "SALE_OF_BUSINESS",
}

export enum WealthRestrictionType {
  ACCESS = "ACCESS",
  CONDITIONS = "CONDITIONS",
  DEADLINES = "DEADLINES",
  LOAN = "LOAN",
  NO_RESTRICTIONS = "NO_RESTRICTIONS",
  PLEDGE = "PLEDGE",
  SUBSIDIES = "SUBSIDIES",
}

export interface AddWishListFundInput {
  potentialCommitment: MonetaryValueInput;
  slug: string;
}

export interface AddressInput {
  addition?: string | null;
  city?: string | null;
  country?: string | null;
  hasConfirmedInformationCorrectAndComplete?: boolean | null;
  postalCode?: string | null;
  state?: string | null;
  streetName?: string | null;
  streetNumber?: string | null;
}

export interface AssetClassExperienceInput {
  averageInvestmentVolumePerYear?: AverageInvestmentVolumePerYear | null;
  averageNumberOfTransactionsPerYear?: AverageTransactionsPerYear | null;
  experienceInYears?: ExperienceYears | null;
}

export interface AssetClassesExperienceInput {
  bonds?: AssetClassExperienceInput | null;
  certificates?: AssetClassExperienceInput | null;
  currencyFutures?: AssetClassExperienceInput | null;
  derivatives?: AssetClassExperienceInput | null;
  gold?: AssetClassExperienceInput | null;
  hedgeFunds?: AssetClassExperienceInput | null;
  infrastructure?: AssetClassExperienceInput | null;
  moneyMarket?: AssetClassExperienceInput | null;
  other?: AssetClassExperienceInput | null;
  privateDebt?: AssetClassExperienceInput | null;
  privateEquity?: AssetClassExperienceInput | null;
  realEstate?: AssetClassExperienceInput | null;
  securities?: AssetClassExperienceInput | null;
}

export interface AtadDataInput {
  reasonForLimitedTaxLiability?: string | null;
  taxResidencies?: string[] | null;
  unlimitedTaxLiability?: boolean | null;
}

export interface BalanceSheetInput {
  assets?: BalanceSheetRange | null;
  sales?: BalanceSheetRange | null;
  sum?: BalanceSheetRange | null;
}

export interface BankAccountInput {
  accountHolderFamilyName?: string | null;
  accountHolderFirstName?: string | null;
  bic?: string | null;
  cityOfBank?: string | null;
  iban?: string | null;
  nameOfBank?: string | null;
  type?: BankAccountType | null;
}

export interface BankAccountSetInput {
  reference?: BankAccountInput | null;
  usdCurrency?: BankAccountInput | null;
}

export interface BusinessActivityInput {
  doesPrivateAssetManagement?: boolean | null;
  doesSpecialTransactions?: boolean | null;
  isCashIntensive?: boolean | null;
  isDutyDisclosure?: boolean | null;
  isPublicCompany?: boolean | null;
  isPubliclyTraceable?: boolean | null;
  isVerifiedByGovernment?: boolean | null;
  naceCode?: string | null;
  transparencyRegisterObligation?: TransparencyRegisterObligationOption | null;
}

export interface ContactDataInput {
  contactAddress?: AddressInput | null;
  emailAddress?: string | null;
  legalAddress?: AddressInput | null;
  phoneNumber?: string | null;
}

export interface CreateDownloadUrlInput {
  documentId: string;
}

export interface CreateUploadUrlInput {
  category: DocumentCategory;
  filename: string;
  mimetype: string;
}

export interface CreateUploadUrlsInput {
  urlRequests: CreateUploadUrlInput[];
}

export interface CurrenciesExperienceInput {
  foreignCurrencies?: boolean | null;
  loanBasedForeignCurrencies?: boolean | null;
}

export interface DeleteDocumentInput {
  documentId: string;
}

export interface EmploymentInput {
  branchOfIndustry?: BranchOfIndustry | null;
  country?: string | null;
  sector?: Sector | null;
  status?: EmploymentStatus | null;
}

export interface FatcaInput {
  status?: FACTAStatus | null;
}

export interface FinancialAssetsInput {
  liquidity?: MoneyRangeLargeWithValueInput | null;
  movableProperty?: MoneyRangeLargeWithValueInput | null;
  other?: MoneyRangeLargeWithValueInput | null;
  securities?: MoneyRangeLargeWithValueInput | null;
  selfUsedProperties?: MoneyRangeLargeWithValueInput | null;
}

export interface FinancialStatusInput {
  assets?: LegalEntityAssetsInput | null;
  balanceSheet?: BalanceSheetInput | null;
  monthlyLiabilities?: MonthlyLiabilitiesInput | null;
  monthlyLiquidity?: TransactionsValueRangeInput | null;
  netAssets?: MoneyRangeWithValueInput | null;
  reserve?: LegalEntityReserveInput | null;
  restrictions?: LegalEntityWealthRestrictionsInput | null;
  revenue?: RevenueInput | null;
}

export interface IdentityDocumentInput {
  documentIds: string[];
  expirationDate: string;
  number: string;
}

export interface IncomeInput {
  capitalGains?: MoneyRangeSmallWithValueInput | null;
  employment?: MoneyRangeSmallWithValueInput | null;
  other?: MoneyRangeSmallWithValueInput | null;
  renting?: MoneyRangeSmallWithValueInput | null;
  selfEmployment?: MoneyRangeSmallWithValueInput | null;
}

export interface InvestmentExperienceInput {
  assetClasses?: AssetClassesExperienceInput | null;
  consultingFreeOrderExecution?: boolean | null;
  currencies?: CurrenciesExperienceInput | null;
  investmentConsulting?: boolean | null;
  wealthManagement?: boolean | null;
}

export interface InvestorClassificationExtensiveExperienceInput {
  jobInCapitalMarket?: boolean | null;
  jobInCapitalMarketDetails?: string | null;
  moreThan500k?: boolean | null;
  tenTransactionsInTheLastFourQuarters?: boolean | null;
  tenTransactionsInTheLastFourQuartersExample?: string | null;
}

export interface InvestorClassificationFinancialInstrumentsInput {
  otherInstitutionalInvestor?: boolean | null;
}

export interface InvestorClassificationGovernmentFunctionInput {
  companyAsGovernment?: boolean | null;
}

export interface InvestorClassificationLargeCompanyInput {
  balanceSheetTotal?: boolean | null;
  netSales?: boolean | null;
  ownFunds?: boolean | null;
}

export interface InvestorClassificationSecurizationOfLiabilitiesInput {
  companyAsSecurizationOfLiabilities?: boolean | null;
}

export interface InvestorProfileInput {
  goals?: InvestmentGoal[] | null;
  horizon?: InvestmentHorizon | null;
  relevantAssetClasses?: AssetClass[] | null;
  riskBearingCapacity?: RiskCapacity | null;
}

export interface LegalEntityAdditionalPersonInput {
  companyEquityPercentage?: number | null;
  contactData: ContactDataInput;
  functionaryRole: LegalEntityFunctionaryRole;
  isBeneficialOwner: boolean;
  isLegalRepresentative: boolean;
  personId?: string | null;
  personalData: PersonalDataInput;
  taxResidencies: TaxResidencyInput[];
  wealthOrigin?: WealthOrigin | null;
  wealthOriginDetail?: string | null;
}

export interface LegalEntityAssetsInput {
  liquidity?: MoneyRangeWithValueInput | null;
  movableAssets?: MoneyRangeWithValueInput | null;
  other?: MoneyRangeWithValueInput | null;
  realEstate?: MoneyRangeWithValueInput | null;
  stock?: MoneyRangeWithValueInput | null;
}

export interface LegalEntityBankAccountInput {
  accountHolderCompany?: string | null;
  bic?: string | null;
  cityOfBank?: string | null;
  iban?: string | null;
  nameOfBank?: string | null;
  type?: BankAccountType | null;
}

export interface LegalEntityBankAccountSetInput {
  reference?: LegalEntityBankAccountInput | null;
  usdCurrency?: LegalEntityBankAccountInput | null;
}

export interface LegalEntityCRSInput {
  otherCharacteristic?: LegalEntityCRSStatusOtherCharacteristic | null;
  status?: LegalEntityCRSStatus[] | null;
}

export interface LegalEntityContactAddressInput {
  contactAddress?: AddressInput | null;
  legalAddress?: AddressInput | null;
}

export interface LegalEntityFATCAInput {
  relevantCriterion?: string | null;
  status?: LegalEntityFATCAStatus | null;
}

export interface LegalEntityInput {
  bankAccounts?: LegalEntityBankAccountSetInput | null;
  businessActivity?: BusinessActivityInput | null;
  companyEquityPercentage?: number | null;
  countryOfOperations?: string | null;
  financialStatus?: FinancialStatusInput | null;
  functionaryRole?: LegalEntityFunctionaryRole | null;
  id?: string | null;
  isBeneficialOwner?: boolean | null;
  isLegalRepresentative?: boolean | null;
  legalEntityContactData?: LegalEntityContactAddressInput | null;
  legalRepresentationType?: LegalRepresentationType | null;
  registration?: LegalEntityRegistrationInput | null;
  taxInfo?: LegalEntityTaxInfoInput | null;
  wealthAssessment?: WealthAssessmentInput | null;
}

export interface LegalEntityRegistrationInput {
  businessPurpose?: string | null;
  companyName?: string | null;
  dateOfFoundation?: string | null;
  legalForm?: LegalForm | null;
  registrationNumber?: string | null;
  registryCourt?: string | null;
}

export interface LegalEntityReserveInput {
  date?: string | null;
  needsReserve?: boolean | null;
  value?: MonetaryValueInput | null;
}

export interface LegalEntityTaxInfoInput {
  crs?: LegalEntityCRSInput | null;
  fatca?: LegalEntityFATCAInput | null;
  residencies?: TaxResidencyInput[] | null;
}

export interface LegalEntityWealthRestrictionsInput {
  type?: WealthRestrictionType | null;
  value?: MonetaryValueInput | null;
}

export interface LiabilitiesInput {
  alimony?: MoneyRangeSmallWithValueInput | null;
  annualLiabilities?: MoneyRangeLiabilitiesWithValueInput | null;
  insurance?: MoneyRangeSmallWithValueInput | null;
  living?: MoneyRangeSmallWithValueInput | null;
  loan?: MoneyRangeSmallWithValueInput | null;
  rent?: MoneyRangeSmallWithValueInput | null;
}

export interface MonetaryValueInput {
  currency: Currency;
  value?: number | null;
}

export interface MoneyRangeLargeWithValueInput {
  range?: MoneyRangeLarge | null;
  value?: MonetaryValueInput | null;
}

export interface MoneyRangeLiabilitiesWithValueInput {
  range?: MoneyRangeLiabilities | null;
  value?: MonetaryValueInput | null;
}

export interface MoneyRangeSmallWithValueInput {
  range?: MoneyRangeSmall | null;
  value?: MonetaryValueInput | null;
}

export interface MoneyRangeWithValueInput {
  range?: MoneyRangeSmall | null;
  value?: MonetaryValueInput | null;
}

export interface MonthlyLiabilitiesInput {
  insurance?: TransactionsValueRangeInput | null;
  materialCosts?: TransactionsValueRangeInput | null;
  other?: TransactionsValueRangeInput | null;
  rent?: TransactionsValueRangeInput | null;
  staff?: TransactionsValueRangeInput | null;
}

export interface NetWorthInput {
  cash?: MoneyRangeLargeWithValueInput | null;
  insuranceClaims?: MoneyRangeLargeWithValueInput | null;
  movableProperty?: MoneyRangeLargeWithValueInput | null;
  other?: MoneyRangeLargeWithValueInput | null;
  realEstate?: MoneyRangeLargeWithValueInput | null;
  restrictions?: WealthRestrictionsInput | null;
  securities?: MoneyRangeLargeWithValueInput | null;
  selfUsedProperties?: MoneyRangeLargeWithValueInput | null;
  total?: MoneyRangeLargeWithValueInput | null;
}

export interface PEPInput {
  relationship?: PEPRelationship | null;
  role?: PEPRole | null;
  status?: PEPStatus | null;
}

export interface PersonalDataInput {
  additionalCitizenships?: string[] | null;
  birthName?: string | null;
  cityOfBirth?: string | null;
  countryOfBirth?: string | null;
  dateOfBirth?: string | null;
  familyName?: string | null;
  firstName?: string | null;
  isThirdCountryNational?: boolean | null;
  primaryCitizenship?: string | null;
  professionalTitle?: ProfessionalTitle | null;
  salutation?: Salutation | null;
}

export interface ProfessionalInvestorChecksInput {
  isConfirmedProfessionalInvestorClassification?: boolean | null;
  isProfessionalInvestor?: boolean | null;
  isRelevantFinancialPortfolioConfirmed?: boolean | null;
  isRelevantMarketExperienceConfirmed?: boolean | null;
  isRelevantMarketJobConfirmed?: boolean | null;
  isSufficientExperienceAndKnowledgeConfirmed?: boolean | null;
  professionalInvestorClassification?: ProfessionalInvestorClassificationInput | null;
}

export interface ProfessionalInvestorClassificationInput {
  extensiveExperience?: InvestorClassificationExtensiveExperienceInput | null;
  financialInstruments?: InvestorClassificationFinancialInstrumentsInput | null;
  governmentFunction?: InvestorClassificationGovernmentFunctionInput | null;
  isConfirmedExtensiveExperience?: boolean | null;
  isConfirmedFinancialInstruments?: boolean | null;
  isConfirmedGovernmentFunction?: boolean | null;
  isConfirmedSecurizationOfLiabilities?: boolean | null;
  isConfirmedUnderSupervision?: boolean | null;
  isConfirmedlargeCompany?: boolean | null;
  largeCompany?: InvestorClassificationLargeCompanyInput | null;
  securizationOfLiabilities?: InvestorClassificationSecurizationOfLiabilitiesInput | null;
  underSupervisionCompanyType?:
    | (InvestorClassificationUnderSupervision | null)[]
    | null;
}

export interface ProfileInput {
  bankAccounts?: BankAccountSetInput | null;
  contactData?: ContactDataInput | null;
  employment?: EmploymentInput | null;
  id?: string | null;
  investorProfile?: InvestorProfileInput | null;
  personalData?: PersonalDataInput | null;
  regulatoryInfo?: RegulatoryInfoInput | null;
}

export interface RegulatoryInfoInput {
  availableMonthlyLiquidity?: MoneyRangeSmallWithValueInput | null;
  experience?: InvestmentExperienceInput | null;
  income?: IncomeInput | null;
  liabilities?: LiabilitiesInput | null;
  netWorth?: NetWorthInput | null;
  pep?: PEPInput | null;
  savings?: SavingsInput | null;
  taxInfo?: TaxInfoInput | null;
  wealthOrigin?: WealthOrigin | null;
  wealthOriginDetail?: string | null;
}

export interface RevenueInput {
  commercialActivities?: TransactionsValueRangeInput | null;
  fixedAssets?: TransactionsValueRangeInput | null;
  others?: TransactionsValueRangeInput | null;
  rent?: TransactionsValueRangeInput | null;
}

export interface SavingsInput {
  areMinimumShortTermReservesRequired?: boolean | null;
  date?: string | null;
  minimumShortTermReserve?: MonetaryValueInput | null;
}

export interface SemiProfessionalInvestorChecksInput {
  educationAndProfessionalStatusAppendix?: string | null;
  educationAndProfessionalStatusType?: FundInvestmentEducationAndProfessionalStatus | null;
  experienceAndKnowledgeAppendix?: string | null;
  experienceAndKnowledgeType?: FundInvestmentExperienceAndKnowledge | null;
  isAlignedFinancialSituationConfirmed?: boolean | null;
  isAlignedRiskProfileConfirmed?: boolean | null;
  isEducationAndProfessionalStatusConfirmed?: boolean | null;
  isSemiProfessionalInvestor?: boolean | null;
  isSufficientExperienceAndKnowledgeConfirmed?: boolean | null;
  isSufficientFinancialAssetsConfirmed?: boolean | null;
  isSufficientIncomeConfirmed?: boolean | null;
}

export interface SignAlternativeInvestmentsFundContractInput {
  signatureTimestamp: string;
  slug: string;
}

export interface SignAlternativeInvestmentsMandateContractInput {
  signatureTimestamp: string;
}

export interface TaxInfoInput {
  facta?: FatcaInput | null;
  residencies?: TaxResidencyInput[] | null;
}

export interface TaxResidencyInput {
  country?: string | null;
  localTaxOffice?: string | null;
  taxId?: string | null;
}

export interface TransactionsValueRangeInput {
  range?: MoneyRangeTransactions | null;
  value?: MonetaryValueInput | null;
}

export interface UpsertAlternativeInvestmentsFundContractInput {
  atadData?: AtadDataInput | null;
  bankAccountType?: BankAccountType | null;
  investorType?: InvestorType | null;
  isAcquisitionOfShareholdingConfirmed?: boolean | null;
  isActingOnOwnBehalf?: boolean | null;
  isDataProtectionConfirmed?: boolean | null;
  isRiskOfAcquisitionConfirmed?: boolean | null;
  isStatementsAndDisclosuresConfirmed?: boolean | null;
  isTrustAndAdministrationContractConfirmed?: boolean | null;
  professionalInvestor?: ProfessionalInvestorChecksInput | null;
  semiProfessionalInvestor?: SemiProfessionalInvestorChecksInput | null;
  signedAt?: string | null;
  signingAmount?: MonetaryValueInput | null;
  slug: string;
  sourceOfFunds?: WealthOrigin | null;
  sourceOfFundsDetail?: string | null;
}

export interface UpsertAlternativeInvestmentsMandateContractInput {
  contractType?: ContractType | null;
  hasAcknowledgedSustainabilityClause?: boolean | null;
  hasConfirmedContractSigning?: boolean | null;
  hasConfirmedInformationCorrectAndComplete?: boolean | null;
  hasReceivedBasicInformationOnInvestments?: boolean | null;
  hasReceivedContractDraft?: boolean | null;
  isActingOnOwnBehalf?: boolean | null;
  isRetailClient?: boolean | null;
}

export interface WealthAssessmentInput {
  exactNetCapital?: string | null;
  financialAssets?: FinancialAssetsInput | null;
  netCapital?: MoneyRangeNetCapital | null;
  ownFunds?: BalanceSheetRangeSmall | null;
  sales?: BalanceSheetRangeLarge | null;
  totalAssets?: BalanceSheetRange | null;
  wealthOrigin?: LegalEntityWealthOrigin | null;
  wealthOriginDetail?: string | null;
}

export interface WealthRestrictionsInput {
  type?: WealthRestrictionType | null;
  value?: MonetaryValueInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
