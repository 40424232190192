import { TFunction } from "i18next";
import { Icon, Stack } from "@finvia/money-ui";
import IdNowLogo from "../../../../../../assets/logos/idnow-logo-black.svg";
import {
  IdentificationStatusTableCell,
  PersonRecord,
} from "./IdentificationStatusTableCell";
import { Content, Headers } from "./IdentificationTable.types";
import { IdNowLogoStyle } from "./IdentificationTable.styled";

const translationPrefix = "components.AddPersonTable";

export const getRows = ({
  data,
  t,
  nextStep,
}: {
  data: PersonRecord[];
  t: TFunction;
  nextStep: () => void;
}): Record<string, Content>[] =>
  data.map((person) => ({
    column1: {
      content: person.name,
    },
    column2: {
      content: person.isLegalRepresentative ? t("form.yes") : t("form.no"),
    },
    column3: {
      content: person.isBeneficialOwner ? t("form.yes") : t("form.no"),
    },
    column4: {
      content: (
        <IdentificationStatusTableCell value={person} nextStep={nextStep} />
      ),
    },
  }));

export const getHeaders = (t: TFunction): Headers[] => [
  {
    field: "column1",
    content: (
      <Stack direction="row" gutter="bit" alignItems="center">
        <Icon name="user" size="bit" />
        {t(`${translationPrefix}.name`)}
      </Stack>
    ),
  },
  {
    field: "column2",
    content: (
      <Stack direction="row" gutter="bit" alignItems="center">
        <Icon name="people" size="byte" />
        {t(`${translationPrefix}.isLegalRepresentative`)}
      </Stack>
    ),
  },
  {
    field: "column3",
    content: (
      <Stack direction="row" gutter="bit">
        <Icon name="pie-chart" size="bit" />
        {t(`${translationPrefix}.isBeneficialOwner`)}
      </Stack>
    ),
  },
  {
    field: "column4",
    content: <IdNowLogoStyle src={IdNowLogo} alt="IdNow-logo" />,
  },
];
