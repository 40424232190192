import { TFunction } from "i18next";
import * as yup from "yup";
import { InvestmentHorizon } from "../../../../../generated/globalTypes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      horizon: yup
        .mixed<InvestmentHorizon>()
        .required()
        .oneOf([InvestmentHorizon.LONG_TERM], ""),
    })
    .required();

export type HorizonSchema = yup.InferType<ReturnType<typeof validationSchema>>;

export interface HorizonForm {
  horizon?: HorizonSchema["horizon"];
}

export function validate(horizon: HorizonForm): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(horizon);
}
