import React from "react";
import { SelectInput } from "@finvia/money-ui";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ObjectSchema } from "yup";
import Separator from "../../../../../../../components/InputGrid/Separator";
import { PEPStatus } from "../../../../../../../generated/globalTypes";
import { PEPRelationshipOptions, PEPRoleOptions } from "../Employment.utils";
import { EmploymentLegalEntity } from "../Employment.schema";
import { FormInput } from "utils/formInputTypes";

interface Props<FormTypes extends FieldValues> {
  pep: PEPStatus;
  formMethods: UseFormMethods<FormTypes>;
  schema: ObjectSchema<any>;
  translationPrefix: string;
  defaultData: FormInput<EmploymentLegalEntity>;
}

const PEPFields = ({
  pep,
  formMethods,
  translationPrefix,
  defaultData,
}: Props<EmploymentLegalEntity>): JSX.Element => {
  const { t } = useTranslation();
  const { register, errors } = formMethods;

  switch (pep) {
    case PEPStatus.YES_ME:
      return (
        <SelectInput
          required
          ref={register}
          label={t(`${translationPrefix}.fields.employment.pep.role.label`)}
          value={defaultData?.employment?.pep?.role}
          name="employment.pep.role"
          options={PEPRoleOptions(t)}
          errorMessage={errors.employment?.pep?.role?.message}
        />
      );
    case PEPStatus.YES_FAMILY_MEMBER:
      return (
        <>
          <SelectInput
            required
            ref={register}
            label={t(
              `${translationPrefix}.fields.employment.pep.relationship.label`
            )}
            value={defaultData?.employment?.pep?.relationship}
            name="employment.pep.relationship"
            options={PEPRelationshipOptions(t)}
            errorMessage={errors.employment?.pep?.relationship?.message}
          />
          <Separator singleColumn />
          <SelectInput
            required
            ref={register}
            label={t(`${translationPrefix}.fields.employment.pep.role.label`)}
            value={defaultData?.employment?.pep?.role}
            name="employment.pep.role"
            options={PEPRoleOptions(t)}
            errorMessage={errors.employment?.pep?.role?.message}
          />
        </>
      );
    default:
      return <></>;
  }
};

export default PEPFields;
