import { TFunction } from "i18next";
import * as yup from "yup";
import { RiskCapacity } from "../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.riskBearingCapacity";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      riskBearingCapacity: yup
        .mixed<RiskCapacity>()
        .required(
          t(`${translationPrefix}.validations.riskBearingCapacityRequired`)
        )
        .oneOf(
          Object.values(RiskCapacity),
          t(`${translationPrefix}.validations.riskBearingCapacityRequired`)
        )
        .test("riskBearingCapacity", (value, testContext) => {
          if (
            value === RiskCapacity.BALANCED ||
            value === RiskCapacity.CONSERVATIVE
          ) {
            return testContext.createError({
              message: " ",
              type: "riskBearingCapacity",
              path: "riskBearingCapacity",
            });
          }
          return true;
        }),
    })
    .required();

export type RiskBearingCapacitySchema = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export interface RiskBearingCapacityForm {
  riskBearingCapacity?: RiskBearingCapacitySchema["riskBearingCapacity"];
}
export function validate(
  riskBearingCapacity: RiskBearingCapacityForm
): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(riskBearingCapacity);
}
