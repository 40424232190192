import { Box, Button } from "@finvia/money-ui";
import { ReactElement } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { countries } from "utils/options/countries";
import { EmploymentAndTax, Residency } from "./employmentAndTaxDataValidations";
import {
  Residencies,
  ResidencyFieldsProps,
} from "./EmploymentAndTaxForm.types";
import { filterCountries } from "./helper";
import ResidencyField from "./ResidencyField";

const translationPrefix = "q-ai.opportunities.form.employmentAndTaxData";

const ResidencyFields = ({
  validationSchema,
}: ResidencyFieldsProps): ReactElement => {
  const formMethods = useFormContext<EmploymentAndTax>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<Residency>({
    control: formMethods.control,
    name: "taxInfo.residencies",
  });

  const maxAmountOfResidencies = 3;

  const watchResidencies: Residencies[] = formMethods.watch(
    "taxInfo.residencies"
  );

  return (
    <>
      {fields.map((item, index) => {
        const selectedCountries = watchResidencies
          .map((field) => field.country)
          .filter((country, idx, arr) => country !== arr[index]);

        const countriesList = filterCountries({
          countriesToRemove: selectedCountries,
          countriesList: countries(t),
        });

        return (
          <ResidencyField
            key={item.id}
            item={item}
            index={index}
            lastItem={index === fields.length - 1}
            formMethods={formMethods}
            schema={validationSchema}
            onRemove={() => remove(index)}
            countriesList={countriesList}
          />
        );
      })}
      {fields.length < maxAmountOfResidencies && (
        <Box display="flex" alignItems="center">
          <Button
            data-testid="add-another-taxresidency"
            onClick={() =>
              append({
                country: "",
                taxId: "",
                localTaxOffice: "",
              })
            }
            variant="link"
          >
            {t(
              `${translationPrefix}.fields.taxInfo.residencies.addAnotherResidency`
            )}
          </Button>
        </Box>
      )}
    </>
  );
};
export default ResidencyFields;
