import styled, { css } from "styled-components";

const iconWrapper = () => css`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: ${theme.tokens.typography.fontSizes[1]};
    color: ${theme.tokens.colors.neutral[500]};
    background-color: ${theme.tokens.colors.gold[500]};
    border-radius: ${theme.tokens.sizes.radii.circle};

    &:hover {
      cursor: pointer;
    }
  `};
`;

export const IconWrapper = styled.span(iconWrapper);
