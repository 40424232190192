import { NaturalPersonIdentificationStatus } from "../../../../../generated/globalTypes";
import { FormDefaultData } from "../../../../../utils/graphQLPartial";
import { MandateContractNaturalPersonFormStep } from "../../../formSteps";
import {
  ContractConfirmationProps,
  validationSchema as ContractConfirmationValidationSchema,
} from "../common/ContractConfirmationForm";
import {
  DeclarationProps,
  validationSchema as DeclarationValidationSchema,
} from "../common/DeclarationForm";
import { IdentificationForm } from "./Identification/identificationFormValidations";

// we need an OR undefined to match the empty state of the reducers (unless we can think of sensable defaults).
export type NaturalPersonMandateContractData = ContractConfirmationProps &
  DeclarationProps &
  IdentificationForm;

export function deriveFormAccess(
  mandateContractData: FormDefaultData<NaturalPersonMandateContractData>
): {
  atMostAccessibleStep: MandateContractNaturalPersonFormStep;
  isComplete: boolean;
} {
  const t = (k: any) => k;
  let atMostAccessibleStep = MandateContractNaturalPersonFormStep.Declaration;
  let isComplete = false;

  try {
    DeclarationValidationSchema(t).validateSync({
      declaration: mandateContractData.declaration,
    });
    atMostAccessibleStep = MandateContractNaturalPersonFormStep.Identification;

    // TODO check status of legal entity validation or natural person identification depending on type

    const isVideoIdentified =
      mandateContractData.videoIdentStatus ===
      NaturalPersonIdentificationStatus.IDENTIFICATION_SUCCESSFUL;
    if (!isVideoIdentified) {
      throw new Error("Video identification not yet completed.");
    }
    atMostAccessibleStep = MandateContractNaturalPersonFormStep.MandateContract;

    ContractConfirmationValidationSchema(t).validateSync({
      contractConfirmation: mandateContractData.contractConfirmation,
    });
    atMostAccessibleStep = MandateContractNaturalPersonFormStep.Declaration;
    isComplete = true;
  } catch {
    // no-op
  }

  return {
    atMostAccessibleStep,
    isComplete,
  };
}
