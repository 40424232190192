import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import {
  LegalEntityName,
  validationSchema,
} from "./LegalEntityNameValidations";
import { Grid, SelectInput, TextInput } from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { LegalForm } from "generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.legalEntityName";

const LegalEntityNameForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={{ sm: 1, md: 2, lg: 1 }}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<LegalEntityName>(
      {
        legalEntityData: {
          companyName:
            state.alternativeInvestments.regulatoryData?.legalEntityData
              .legalEntityData?.companyName,
          registrationType:
            state.alternativeInvestments.regulatoryData?.legalEntityData
              .legalEntityData?.registrationType,
        },
      },
      {
        legalEntityData: {
          companyName: undefined,
          registrationType: undefined,
        },
      }
    )
  );

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (legalEntityData: LegalEntityName) => {
      dispatch(updateLegalEntityProfile(legalEntityData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<LegalEntityName>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.9_le_company_name"
    >
      {(formMethods) => {
        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid
              gap={{ sm: "giga", md: "yotta" }}
              columns={{ sm: 1, md: "1fr 1fr" }}
            >
              <TextInput crossOrigin
                name={`legalEntityData.companyName`}
                label={t(`${translationPrefix}.fields.companyName.label`)}
                defaultValue={defaultData?.legalEntityData?.companyName || ""}
                ref={formMethods.register}
                errorMessage={
                  formMethods.errors?.legalEntityData?.companyName?.message ??
                  undefined
                }
                required
              />
              <SelectInput
                name={`legalEntityData.registrationType`}
                label={t(`${translationPrefix}.fields.registrationType.label`)}
                required
                options={getSelectOptionsFromTranslation(
                  `${translationPrefix}.fields.registrationType.values`,
                  t
                ).filter(
                  (type) =>
                    type.value !== LegalForm.EG &&
                    type.value !== LegalForm.PART_G &&
                    type.value !== LegalForm.GMBH_IN_GRUNDUNG &&
                    type.value !== LegalForm.UG_IN_GRUNDUNG
                )}
                value={
                  defaultData.legalEntityData?.registrationType ?? undefined
                }
                ref={formMethods.register}
                helperText={t(
                  `${translationPrefix}.fields.registrationType.tooltip`
                )}
              />
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default LegalEntityNameForm;
