import { Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const managerDataWrapper = () => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const managerData = ({ theme }: { theme: Theme }) => css`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  text-align: end;

  &:not(:nth-last-child(-n + 1)) {
    margin-bottom: ${theme.tokens.sizes.spacings.mega};
  }

  ${theme.utils.viewport.md`
    width: calc(50% - ${theme.tokens.sizes.spacings.mega});

    &:not(:nth-last-child(-n + 2))::after {
      position: absolute;
      left: 0;
      bottom: -${theme.tokens.sizes.spacings.kilo};
      content: " ";
      width: 100%;
      height: 1px;
      border-bottom: ${theme.tokens.sizes.borderWidth.bit} solid ${theme.tokens.colors.neutral[500]};
    }

    &:nth-last-child(2)::after {
      border-bottom: none;
    }

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    &:not(:nth-last-child(-n + 2)) {
      margin-bottom: ${theme.tokens.sizes.spacings.giga};
    }
  `};
`;

const managerValue = ({ theme }: { theme: Theme }) => css`
  color: ${theme.tokens.colors.neutral[800]};
  font-weight: ${theme.tokens.typography.weights.medium};
  margin-left: ${theme.tokens.sizes.spacings.mega};
  font-size: ${theme.tokens.typography.fontSizes[3]};
`;

export const ManagerDataWrapper = styled.div(managerDataWrapper);
export const ManagerData = styled.div<{ theme: Theme }>(managerData);
export const ManagerValue = styled.div<{ theme: Theme }>(managerValue);
