import React from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../../../routes/routes";
import { InfoBox, Button, Stack, Box } from "@finvia/money-ui";
import { StyledLink } from "./FundAmountHeader.styled";

const FundNotAvailableHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box width={{ sm: "100%", md: "500px" }}>
      <InfoBox icon="info">
        <Stack gutter="byte" direction="row">
          {t("components.alternativeInvestmentFund.fundNotAvailable")}
          <StyledLink to={ROUTES.funds.root}>
            <Button as="span" variant="link">
              {t("components.alternativeInvestmentFund.backButton")}
            </Button>
          </StyledLink>
        </Stack>
      </InfoBox>
    </Box>
  );
};

export default FundNotAvailableHeader;
