import { TFunction } from "i18next";
import * as yup from "yup";
import countries from "../../../../../../data/countries";
import { validatePostalCodeGermany } from "./helpers";

const translationPrefix = "q-ai.opportunities.form.contactData";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const address = (t: TFunction) =>
  yup.object({
    streetName: yup
      .string()
      .required(t(`${translationPrefix}.fields.street.required`))
      .label(t(`${translationPrefix}.fields.street.label`)),
    streetNumber: yup
      .string()
      .required(t(`${translationPrefix}.fields.houseNumber.required`))
      .label(t(`${translationPrefix}.fields.houseNumber.label`)),
    addition: yup
      .string()
      .label(t(`${translationPrefix}.fields.additionalAddress.label`)),
    postalCode: yup
      .string()
      .when("country", {
        is: "DE",
        then: yup
          .string()
          .test(
            "len",
            t(`${translationPrefix}.fields.postalCode.length`),
            validatePostalCodeGermany
          ),
      })
      .required(t(`${translationPrefix}.fields.postalCode.required`))
      .label(t(`${translationPrefix}.fields.postalCode.label`)),
    city: yup
      .string()
      .required(t(`${translationPrefix}.fields.city.required`))
      .label(t(`${translationPrefix}.fields.city.label`)),
    country: yup
      .string()
      .oneOf(countries)
      .required(t(`${translationPrefix}.fields.country.required`))
      .label(t(`${translationPrefix}.fields.country.label`)),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const optionalAddress = (t: TFunction) =>
  yup.object({
    streetName: yup
      .string()
      .label(t(`${translationPrefix}.fields.street.label`)),
    streetNumber: yup
      .string()
      .label(t(`${translationPrefix}.fields.houseNumber.label`)),
    addition: yup
      .string()
      .label(t(`${translationPrefix}.fields.additionalAddress.label`)),
    postalCode: yup
      .string()
      .when("country", {
        is: "DE",
        then: yup
          .string()
          .test(
            "len",
            t(`${translationPrefix}.fields.postalCode.length`),
            validatePostalCodeGermany
          ),
      })
      .label(t(`${translationPrefix}.fields.postalCode.label`)),
    city: yup.string().label(t(`${translationPrefix}.fields.city.label`)),
    country: yup
      .string()
      .oneOf(countries)
      .label(t(`${translationPrefix}.fields.country.label`)),
  });

export type Address = yup.InferType<ReturnType<typeof address>>;

export const defaultAddress: Address = {
  streetName: "",
  streetNumber: "",
  addition: "",
  postalCode: "",
  city: "",
  country: "DE",
};

export default address;
