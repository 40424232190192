import { createSlice } from "@reduxjs/toolkit";
import { FormDefaultData } from "../../../../utils/graphQLPartial";
import typesafeDeepMergeWithUnsetting from "../../../../utils/typesafeDeepMergeWithUnsetting";
import { getInvestorProfile, updateProfile } from "./actions";
import {
  deriveFormAccess,
  InvestorProfile,
} from "./investorProfileValidations";

interface State {
  isLoading: boolean;
  isComplete: boolean;
  data: FormDefaultData<InvestorProfile>;
}

const initialState: State = {
  isLoading: false,
  isComplete: false,
  data: {
    goals: undefined,
    horizon: undefined,
    relevantAssetClasses: undefined,
    riskBearingCapacity: undefined,
  },
};

const slice = createSlice({
  name: "investmentOpportunities",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getInvestorProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInvestorProfile.fulfilled, (state, { payload }) => {
        state.data = typesafeDeepMergeWithUnsetting<
          FormDefaultData<InvestorProfile>
        >(state.data, payload);
        state.isComplete = deriveFormAccess(state.data).isComplete;
        state.isLoading = false;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.data = typesafeDeepMergeWithUnsetting<
          FormDefaultData<InvestorProfile>
        >(state.data, payload);
        state.isComplete = deriveFormAccess(state.data).isComplete;
      }),
});

export default slice;
