import { SelectInput, Stack } from "@finvia/money-ui";
import { Box } from "@material-ui/core";
import { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { PEPStatus } from "../../../../../../generated/globalTypes";
import { PEPProps } from "./EmploymentAndTaxForm.types";

const PEPFields = <FormTypes extends FieldValues>({
  pep,
  formMethods,
  translationPrefix,
  defaultData,
}: PEPProps<FormTypes>): JSX.Element => {
  const { t } = useTranslation();
  const { register, errors } = formMethods;

  switch (pep) {
    case PEPStatus.YES_ME:
      return (
        <Box display="flex" alignItems="flex-end">
          <SelectInput
            options={getSelectOptionsFromTranslation(
              `${translationPrefix}.fields.employment.pep.role.values`,
              t
            )}
            name="employment.pep.role"
            label={t(`${translationPrefix}.fields.employment.pep.role.label`)}
            required
            ref={register}
            value={defaultData.employment?.sector}
            {...(defaultData.employment?.sector && {
              value: defaultData.employment?.sector,
            })}
            errorMessage={errors?.employment?.pep?.relationship?.message}
          />
        </Box>
      );
    case PEPStatus.YES_FAMILY_MEMBER:
      return (
        <Stack gutter="giga">
          <Box width="100%" display="flex" alignItems="flex-end">
            <SelectInput
              options={getSelectOptionsFromTranslation(
                `${translationPrefix}.fields.employment.pep.relationship.values`,
                t
              )}
              name="employment.pep.relationship"
              label={t(
                `${translationPrefix}.fields.employment.pep.relationship.label`
              )}
              required
              ref={register}
              {...(defaultData.employment?.pep?.relationship && {
                value: defaultData.employment?.pep?.relationship,
              })}
              errorMessage={errors?.employment?.pep?.relationship?.message}
            />
          </Box>
          <SelectInput
            options={getSelectOptionsFromTranslation(
              `${translationPrefix}.fields.employment.pep.role.values`,
              t
            )}
            name="employment.pep.role"
            label={t(`${translationPrefix}.fields.employment.pep.role.label`)}
            required
            ref={register}
            {...(defaultData.employment?.pep?.role && {
              value: defaultData.employment?.pep?.role,
            })}
            errorMessage={errors?.employment?.pep?.role?.message}
          />
        </Stack>
      );
    default:
      return <></>;
  }
};

export default PEPFields;
