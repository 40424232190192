import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../../../routes/routes";
import { typedUseSelector } from "../../../../store";
import { FundAmountHeader } from "../../components/FundDetails/FundDetailsHeaders/FundAmountHeader";
import { TitleCard } from "../../../../components/TitleCard";
import { getFundBySlug, getFundContractSigningData } from "../actions";
import FundDetailsHeader from "../components/FundDetailsHeader/FundDetailsHeader";
import ProcessSteps from "../FundDeclarations/ProcessSteps/ProcessSteps";
import { fundBySlug } from "../slice";
import { Box, Button, Card, InfoBox, Stack } from "@finvia/money-ui";
import * as S from "./FundSigningNotDigital.styled";
import { FundDetailsCard } from "../../components/FundDetails/FundDetails.styled";

const translationPrefix = "q-ai.funds.external";

const FundSigningNotDigital: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();

  const dispatch = useDispatch();

  function handleBackButton(): void {
    history.push(generatePath(ROUTES.funds.details.overview, { slug: slug }), {
      scrollToTop: true,
    });
  }

  function handleNextButton(): void {
    history.push(
      generatePath(ROUTES.funds.details.externalSigningSuccessful, {
        slug: slug,
      }),
      {
        scrollToTop: true,
      }
    );
  }

  const fundDetails = typedUseSelector((state) => {
    return fundBySlug(
      state.alternativeInvestments.alternativeInvestmentFunds.funds,
      slug
    );
  });

  useEffect(() => {
    dispatch(getFundBySlug({ slug }));
    dispatch(getFundContractSigningData({ slug }));
  }, [dispatch, slug]);

  const signingAmount = typedUseSelector(
    (state) =>
      state.alternativeInvestments.alternativeInvestmentFunds.data.signingAmount
  );

  const fundAttributes = fundDetails?.attributes;

  if (!fundAttributes) {
    console.error("Fund attributes not found");
    return null;
  }

  return (
    <FundDetailsCard>
      <Stack>
        <FundDetailsHeader
          fundName={fundAttributes.name ?? ""}
          fundAssetClass={fundAttributes.assetClass}
          fundDescription={fundAttributes.shortDescription}
          fundManagerImageUrl={fundAttributes.fundManagerImage || undefined}
        >
          <FundAmountHeader
            fundAmount={signingAmount || 0}
            fundCurrency={fundAttributes.currency}
          />
        </FundDetailsHeader>
        <Card
          paddingTopBottom={{ sm: "giga", md: "peta" }}
          paddingLeftRight={{ sm: "kilo", md: "peta" }}
          borderStyle="none"
        >
          <Stack gutter="peta">
            <Box display="flex" justifyContent="center" width="100%">
              <TitleCard
                title={`${t(`${translationPrefix}.title`)}`}
                subTitle={`${t(`${translationPrefix}.subTitle`)}`}
              />
            </Box>
            <S.ProcessStepsWrapper>
              <ProcessSteps />
            </S.ProcessStepsWrapper>
            <InfoBox icon="info">{t(`${translationPrefix}.infoBox`)}</InfoBox>
            <Stack justifyContent="space-between" direction="row">
              <Button
                variant="ghost"
                onClick={handleBackButton}
                data-testid="return-link"
              >
                {t("onboarding.form.back")}
              </Button>
              <Button onClick={handleNextButton}>
                {t(`${translationPrefix}.continue`)}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </FundDetailsCard>
  );
};

export default FundSigningNotDigital;
