import { SerializedError } from "@reduxjs/toolkit";
import { useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  DocumentCategory,
  LegalEntityFATCAStatus,
} from "../../../../../../generated/globalTypes";
import { updateTaxInformationFatcaStatusDocuments } from "../actions";
import { Grid, Heading, SelectInput, TextInput } from "@finvia/money-ui";
import { getSelectOptionsFromTranslation } from "utils/options/getSelectOptionsFromTranslation";
import { HandleDocumentUpload, Document } from "./";
import { ActionDocChangePayload } from "types/types";
import {
  UploadField,
  handleDocumentUploadGeneric,
  handleDeleteDocumentGeneric,
} from "../../../../components/UploadField";
import { FATCAFieldsProps, HandleDocumentDelete } from "./TaxInformation.types";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

export default function FATCAFields({
  formMethods,
  defaultData,
}: FATCAFieldsProps): JSX.Element {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fatcaStatus = useWatch({
    control: formMethods.control,
    name: `taxInformation.fatca.status`,
  }) as LegalEntityFATCAStatus;

  const statusCertificate = useWatch<Document[]>({
    control: formMethods.control,
    name: `taxInformation.fatca.statusCertificate`,
  });

  let showRelevantCriterion = false;
  let showStatusCertificate = false;

  switch (fatcaStatus) {
    case LegalEntityFATCAStatus.SPECIFIC_PERSON_USA:
    case LegalEntityFATCAStatus.GERMAN_FINANCIAL_INSTITUTE:
    case LegalEntityFATCAStatus.TWIN_STATE_FINANCIAL_INSTITUTE:
    case LegalEntityFATCAStatus.PARTICIPATING_FOREIGN_FINANCIAL_INSTITUTE:
    case LegalEntityFATCAStatus.EXEMPT_BENEFICIAL_OWNER:
    case LegalEntityFATCAStatus.PASSIVE_NFFE:
    case LegalEntityFATCAStatus.NON_PARTICIPATING_FINANCIAL_INSTITUTION:
      showStatusCertificate = true;
      break;
    case LegalEntityFATCAStatus.PERSON_USA_NO_SPECIFIC:
    case LegalEntityFATCAStatus.FATCA_COMPLIANT_FOREIGN_FINANCIAL_INSTITUTION:
    case LegalEntityFATCAStatus.ACTIVE_NFFE:
      showRelevantCriterion = true;
      showStatusCertificate = true;
      break;
    default:
      break;
  }

  const [currentDocumentsList, setCurrentDocumentsList] = useState<Document[]>(
    defaultData?.statusCertificate ?? []
  );

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    setValueReference
  ) => {
    const uploadedDocuments =
      await handleDocumentUploadGeneric<ActionDocChangePayload>({
        documents,
        file,
        setValueReference,
        dispatch,
        updateAction: updateTaxInformationFatcaStatusDocuments,
        category: DocumentCategory.FATCA_STATUS_CERTIFICATE,
        setError,
        name: "taxInformation.fatca.statusCertificate",
        formName: "TaxInformationForm",
      });

    if (uploadedDocuments && Array.isArray(uploadedDocuments)) {
      setCurrentDocumentsList([...currentDocumentsList, ...uploadedDocuments]);
    }
  };

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    name,
    setValueReference
  ) => {
    const filteredDocuments =
      await handleDeleteDocumentGeneric<ActionDocChangePayload>({
        document,
        documents,
        setValueReference,
        dispatch,
        updateAction: updateTaxInformationFatcaStatusDocuments,
        setError,
        name,
        formName: "TaxInformationForm",
      });

    if (filteredDocuments) {
      setCurrentDocumentsList([...filteredDocuments]);
    }
  };

  return (
    <>
      <Grid columnsGap="yotta" rowsGap="giga" columns={1}>
        <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
          {t(`${translationPrefix}.fields.fatca.title`)}
        </Heading>
      </Grid>
      <Grid columnsGap="yotta" rowsGap="giga" columns={{ sm: 1, md: 2 }}>
        <SelectInput
          ref={formMethods.register}
          name="taxInformation.fatca.status"
          label={t(`${translationPrefix}.fields.fatca.fields.status.label`)}
          required
          {...(defaultData?.status && {
            value: defaultData?.status,
          })}
          options={getSelectOptionsFromTranslation(
            `${translationPrefix}.fields.fatca.fields.status.values`,
            t
          )}
          errorMessage={
            formMethods.errors.taxInformation?.fatca?.status?.message
          }
          helperText={t(
            `${translationPrefix}.fields.fatca.fields.status.tooltip`
          )}
        />
        {showRelevantCriterion && (
          <TextInput crossOrigin
            ref={formMethods.register}
            name="taxInformation.fatca.relevantCriterion"
            required
            label={t(
              `${translationPrefix}.fields.fatca.fields.relevantCriterion.label`
            )}
            errorMessage={
              formMethods.errors?.taxInformation?.fatca?.relevantCriterion
                ?.message
            }
            defaultValue={defaultData?.relevantCriterion}
          />
        )}
        {showStatusCertificate && (
          <>
            <UploadField
              name="taxInformation.fatca.statusCertificate"
              onUpload={(documentslist, file) => {
                handleDocumentUpload(documentslist, file, formMethods.setValue);
              }}
              onDelete={(document, documentslist) => {
                handleDeleteDocument(
                  document,
                  documentslist,
                  "taxInformation.fatca.statusCertificate",
                  formMethods.setValue
                );
              }}
              required
              disabled={statusCertificate?.length === 1}
              documentsList={currentDocumentsList}
              label={
                fatcaStatus === LegalEntityFATCAStatus.PASSIVE_NFFE
                  ? t(
                      `${translationPrefix}.fields.fatca.fields.statusCertificate.PASSIVE_NFFE_label`
                    )
                  : t(
                      `${translationPrefix}.fields.fatca.fields.statusCertificate.label`
                    )
              }
              helperText={t(
                `${translationPrefix}.fields.fatca.fields.statusCertificate.tooltip`
              )}
              inputErrorMessage={undefined}
              barErrorMessage={t("form.fileUpload.failed")}
              barSuccessMessage={t("form.fileUpload.succeeded")}
              formMethods={formMethods}
            />
          </>
        )}
      </Grid>
    </>
  );
}
