import { makeStyles } from "@material-ui/core";
import React from "react";

interface Props {
  singleColumn?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    margin: 0,
    border: "none",
    display: props.singleColumn ? "none" : "initial",
    [theme.breakpoints.up("sm")]: {
      gridColumn: props.singleColumn ? "initial" : "1 / -1",
      display: "initial",
    },
  }),
}));

const Separator: React.FC<Props> = ({ singleColumn }) => {
  const classes = useStyles({ singleColumn });

  return <hr className={classes.root} />;
};

export default Separator;
