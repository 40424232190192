import styled, { css } from "styled-components";

const wrapperStyles = () => css`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.tokens.sizes.spacings.giga}
      ${theme.tokens.sizes.spacings.mega};
    border: ${theme.tokens.sizes.borderWidth.byte} solid
      ${theme.tokens.colors.petrol[600]};
    transition: border-color 0.3s ease-in-out;

    svg {
      transition: fill 0.3s ease-in-out;
    }

    &:hover {
      border-color: ${theme.tokens.colors.petrol[400]};
      cursor: pointer;

      svg {
        fill: ${theme.tokens.colors.petrol[400]};
      }
    }
  `}
`;

export const Wrapper = styled.div(wrapperStyles);
