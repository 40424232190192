import { FormDefaultData } from "../../../../../utils/graphQLPartial";
import { NaturalPersonRegulatoryDataFormStep } from "../../../formSteps";
import { ContractConfirmationProps } from "../../mandateContract/common/ContractConfirmationForm";
import {
  ExperienceAssetClassesForm,
  validationSchema as ExperienceAssetClassesFormValidationSchema,
} from "../common/ExperienceAssets/ExperienceAssets.schema";
import {
  ExperienceCurrenciesProps,
  validation as ExperienceCurrenciesValidationSchema,
} from "../common/ExperienceCurrencies";
import {
  PersonalDataForm,
  validationSchema as PersonalDataValidationSchema,
} from "../common/PersonalDataForm/PersonalData.schema";
import {
  ContactDataForm,
  validationSchema as ContactDataValidationSchema,
} from "../common/PersonContactDataForm/contactData.schema";
import {
  ContractTypeForm,
  validationSchema as ContractTypeValidationSchema,
} from "../contractTypeFormValidations";
import {
  BankAccountsProps,
  validationSchema as BankAccountsFormValidationSchema,
} from "./BankAccount";
import {
  EmploymentAndTax,
  validationSchema as EmploymentAndTaxValidationSchema,
} from "./EmploymentAndTaxForm/employmentAndTaxDataValidations";
import { IncomeFormType } from "./IncomeForm/IncomeForm.types";
import { validationSchema as IncomeFormValidationSchema } from "./IncomeForm/IncomeForm.schema";
import { validationSchema as LiabilitiesValidationSchema } from "./LiabilitiesForm/LiabilitiesForm.schema";
import { LiabilitiesFormType } from "./LiabilitiesForm/LiabilitiesForm.types";
import {
  WealthAssessmentProps,
  validationSchema as WealthAssessmentValidationSchema,
} from "./WealthAssessmentForm";

type PersonId = string;

// we need an OR undefined to match the empty state of the reducers (unless we can think of sensable defaults).
export type ContractAndRegulatoryData = { id: PersonId } & ContractTypeForm &
  PersonalDataForm &
  ContactDataForm &
  EmploymentAndTax & {
    regulatoryInfo?: ExperienceCurrenciesProps &
      ExperienceAssetClassesForm &
      WealthAssessmentProps &
      IncomeFormType &
      LiabilitiesFormType;
  } & BankAccountsProps &
  ContractConfirmationProps;

export function deriveFormAccess(
  contractAndRegulatoryData: FormDefaultData<ContractAndRegulatoryData>
): {
  atMostAccessibleStep: NaturalPersonRegulatoryDataFormStep;
  isComplete: boolean;
} {
  const t = (k: any) => k;
  let atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.ContractType;
  let isComplete = false;

  const {
    contractType,
    personalData,
    citizenship,
    contactData,
    contactAddress,
    legalAddress,
    employment,
    taxInfo,
    regulatoryInfo,
    bankAccounts,
  } = contractAndRegulatoryData;
  try {
    // 1_ContactType
    ContractTypeValidationSchema(t).validateSync({
      contractType,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.PersonalData;

    // 2_PersonalData
    PersonalDataValidationSchema(t).validateSync({
      personalData,
      citizenship,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.ContactData;

    // 3_ContactData
    const hasSeparateContactAddress =
      contactAddress === undefined || contactAddress?.streetName === ""
        ? false
        : true;

    ContactDataValidationSchema(t).validateSync({
      contactData,
      contactAddress: hasSeparateContactAddress ? contactAddress : undefined,
      legalAddress,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.EmploymentAndTax;

    // 4_Employment
    EmploymentAndTaxValidationSchema(t).validateSync({
      employment,
      taxInfo,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.WealthAssessment;

    // 5_WealthAssessment
    WealthAssessmentValidationSchema(t).validateSync({
      netWorth: regulatoryInfo?.netWorth,
      wealthOrigin: regulatoryInfo?.wealthOrigin,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.Income;

    // 6_Income
    IncomeFormValidationSchema(t).validateSync({
      income: regulatoryInfo?.income,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.Liabilities;

    // 7_Liabilities
    LiabilitiesValidationSchema(t).validateSync({
      liabilities: regulatoryInfo?.liabilities,
      savings: regulatoryInfo?.savings,
      availableMonthlyLiquidity: regulatoryInfo?.availableMonthlyLiquidity,
    });
    atMostAccessibleStep =
      NaturalPersonRegulatoryDataFormStep.ExperienceAssetClasses;

    // 8_ExperienceAssets
    ExperienceAssetClassesFormValidationSchema(t).validateSync(regulatoryInfo);
    atMostAccessibleStep =
      NaturalPersonRegulatoryDataFormStep.ExperienceCurrencies;

    // 9_ExperienceCurrencies
    ExperienceCurrenciesValidationSchema(t).validateSync(regulatoryInfo);
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.BankAccounts;

    // 10_BankAccounts
    BankAccountsFormValidationSchema(t).validateSync({
      bankAccounts,
    });
    atMostAccessibleStep = NaturalPersonRegulatoryDataFormStep.ContractType;

    isComplete = true;
  } catch (error) {
    // no-op
    console.log("Error", error);
  }

  return {
    atMostAccessibleStep,
    isComplete,
  };
}
