import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Heading, Icon, InfoBox, Stack, Text } from "@finvia/money-ui";
import * as S from "./FundSigningSuccess.styled";
import { useDispatch, useSelector } from "react-redux";
import { fundBySlug } from "../slice";
import { ROUTES } from "routes/routes";
import { getFundBySlug } from "../actions";

const FundSigningSuccess: React.FC = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  const { fund } = useSelector((state) => {
    return {
      fund: fundBySlug(
        state.alternativeInvestments.alternativeInvestmentFunds.funds,
        slug
      ),
    };
  });

  useEffect(() => {
    if (fund) {
      return;
    }

    dispatch(getFundBySlug({ slug }));
  }, [dispatch, fund, slug]);

  useEffect(() => {
    if ((history.location?.state as { scrollToTop: number })?.scrollToTop) {
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [history.location.state]);

  return (
    <S.Container>
      <Stack gutter="giga" alignItems="center">
        <Icon name="time" size="peta" />
        <S.TextWrapper>
          <Stack gutter="giga" alignItems="center">
            <Heading
              textTransform="uppercase"
              as="h1"
              align="center"
              size={{ sm: 4, md: 7 }}
            >
              {t("q-ai.funds.success.title")}
            </Heading>
            <Text size={{ sm: 3, lg: 4 }} align="center">
              {t("q-ai.funds.success.message")}
            </Text>
            {fund?.attributes?.successMessage && (
              <InfoBox>{fund.attributes?.successMessage}</InfoBox>
            )}
            <Button as="a" variant="ghost" href={ROUTES.funds.root}>
              {t("q-ai.funds.success.backButton")}
            </Button>
          </Stack>
        </S.TextWrapper>
      </Stack>
    </S.Container>
  );
};

export default FundSigningSuccess;
