import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import FormControl from "../../../../components/FormControl/FormControl";
import LoadingWrapper from "../../../../components/LoadingWrapper/LoadingWrapper";
import { FormContent } from "../../../../components/FormContent";
import {
  BankAccountType,
  ContractType,
  ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY,
} from "../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../store";
import { FundAmountHeader } from "../../components/FundDetails/FundDetailsHeaders/FundAmountHeader";
import {
  getAIFundContract,
  getFundBySlug,
  upsertAlternativeInvestmentsFundContract,
} from "../actions";
import FundDetailsHeader from "../components/FundDetailsHeader/FundDetailsHeader";
import { fundBySlug } from "../slice";
import { ROUTES } from "../../../../routes/routes";
import {
  validationSchema,
  AccountSelectionType,
  BankAccountSelectionFormData,
} from "./BankAccountSelection.schema";
import {
  Card,
  Grid,
  InfoBox,
  SelectInput,
  Stack,
  TextInput,
  Box,
  TextAreaInput,
  Text,
  Heading,
} from "@finvia/money-ui";
import { updateLegalEntityProfile } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/legalEntity/actions";
import { updateProfile } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/actions";
import { BankAccountsProps } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/naturalPerson/BankAccount";
import { FundDetailsCard } from "../../components/FundDetails/FundDetails.styled";
import { buildOptions } from "utils/options/buildObject";
import { WealthOrigin } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/naturalPerson/WealthAssessmentForm/WealthAssessment.types";
import Atad from "./components/Atad";

const translationPrefix = "q-ai.opportunities.form.bankAccountSelection";
const wealthOriginPrefix = "q-ai.opportunities.form.experienceCurrency";

const BankAccountSelection: React.FC = () => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { slug } = useParams<{ slug: string }>();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const isLegalEntity = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData.personData.contractType ===
      ContractType.LEGAL_ENTITY
  );

  const memoizedSchema = useMemo(
    () => validationSchema(t, isLegalEntity),
    [t, isLegalEntity]
  );

  useEffect(() => {
    dispatch(getFundBySlug({ slug }));
    dispatch(getAIFundContract({ slug }));
  }, [dispatch, slug]);

  useEffect(() => {
    if ((history.location?.state as any)?.scrollToTop) {
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [history.location.state]);

  const previousStep = useCallback(() => {
    history.push(generatePath(ROUTES.funds.details.overview, { slug: slug }), {
      scrollToTop: true,
    });
  }, [history, slug]);

  const nextStep = useCallback(() => {
    history.push(
      generatePath(ROUTES.funds.details.declarations, { slug: slug }),
      {
        scrollToTop: true,
      }
    );
  }, [history, slug]);

  const { defaultData, bankAccount, signingAmount, fundDetails } =
    typedUseSelector((state) => {
      const fund = fundBySlug(
        state.alternativeInvestments.alternativeInvestmentFunds.funds,
        slug
      );

      const {
        signingAmount: fundSigningAmount,
        bankAccountType: rawBankAccountType,
        sourceOfFunds,
        sourceOfFundsDetail,
      } = state.alternativeInvestments.alternativeInvestmentFunds.data;

      const LEBankAccounts =
        state.alternativeInvestments.regulatoryData.legalEntityData
          .bankAccounts;
      const NPBankAccounts =
        state.alternativeInvestments.regulatoryData.personData.bankAccounts;
      const { taxInformation: taxInformationFromStore } =
        state.alternativeInvestments.regulatoryData?.legalEntityData;

      const relevantBankAccount: typeof LEBankAccounts & typeof NPBankAccounts =
        isLegalEntity ? LEBankAccounts : NPBankAccounts;

      let defaultBankAccountType: AccountSelectionType =
        AccountSelectionType.REFERENCE;

      if (
        fund?.attributes?.currency ===
        ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY.usd
      ) {
        if (relevantBankAccount?.usdCheckbox) {
          defaultBankAccountType = AccountSelectionType.USD_CURRENCY_ACCOUNT;
        } else {
          defaultBankAccountType = AccountSelectionType.NEW_USD;
        }
      }

      // Conversion is needed between the 2 enum types for TS reasons (we can't extend Enums in ts and copying isn't straight forward)
      const bankAccountType =
        rawBankAccountType === BankAccountType.REFERENCE
          ? AccountSelectionType.REFERENCE
          : AccountSelectionType.USD_CURRENCY_ACCOUNT;

      const accountName = isLegalEntity
        ? relevantBankAccount?.accountHolderCompany
        : `${relevantBankAccount?.accountHolderFirstName} ${relevantBankAccount?.accountHolderFamilyName}`;

      const taxInformationDefault = taxInformationFromStore?.residencies?.length
        ? [...taxInformationFromStore?.residencies]
        : [
            {
              country: "",
              taxId: "",
              localTaxOffice: "",
            },
          ];

      return {
        defaultData: {
          bankAccountType: rawBankAccountType
            ? bankAccountType
            : defaultBankAccountType,
          accountHolderCompany: accountName ?? "",
          usdIban: "",
          usdBic: "",
          usdNameOfBank: "",
          sourceOfFunds,
          sourceOfFundsDetail,
          taxResidencies: [...taxInformationDefault],
        },
        bankAccount: relevantBankAccount,
        signingAmount: fundSigningAmount,
        fundDetails: fund,
      };
    });

  const loading = useSelector((state) => {
    return state.alternativeInvestments.alternativeInvestmentFunds.isLoading;
  });

  const onSubmit = useCallback(
    async (data: BankAccountSelectionFormData) => {
      try {
        const bankAccountType = data.bankAccountType;
        const bankAccountPayload = {
          ...bankAccount,
          usdCheckbox: true,
          usdIban: data.usdIban,
          usdBic: data.usdBic,
          usdNameOfBank: data.usdNameOfBank,
        } as BankAccountsProps["bankAccounts"];
        if (bankAccountType === AccountSelectionType.NEW_USD) {
          await dispatch(
            isLegalEntity
              ? updateLegalEntityProfile({
                  bankAccounts: bankAccountPayload,
                })
              : updateProfile({ bankAccounts: bankAccountPayload })
          ).then(unwrapResult);
        }

        const taxRes =
          data.taxResidencies?.length && typeof data.taxResidencies !== "string"
            ? data.taxResidencies.map((res) => res.country)
            : null;

        await dispatch(
          upsertAlternativeInvestmentsFundContract({
            bankAccountType:
              bankAccountType === AccountSelectionType.REFERENCE
                ? BankAccountType.REFERENCE
                : BankAccountType.USD_CURRENCY_ACCOUNT,
            slug: slug,
            sourceOfFunds: data.sourceOfFunds,
            sourceOfFundsDetail: data.sourceOfFundsDetail,
            taxResidencies: taxRes,
            unlimitedTaxLiability:
              data.unlimitedTaxLiability === "yes" ? true : false,
            reasonForLimitedTaxLiability: data.reasonForLimitedTaxLiability,
          })
        ).then(unwrapResult);

        if (data.taxResidencies && typeof data.taxResidencies !== "string") {
          await dispatch(
            updateLegalEntityProfile({
              taxInformation: {
                residencies: [...data.taxResidencies],
              },
            })
          ).then(unwrapResult);
        }

        nextStep();
      } catch (e) {
        setError(e as SerializedError);
      }
    },
    [bankAccount, dispatch, isLegalEntity, nextStep, slug]
  );

  const fundAttributes = fundDetails?.attributes;
  const fundCurrency = fundAttributes?.currency;
  const hasUSDAccount = !!bankAccount?.usdCheckbox;

  const bankAccountSelectionOptions = [
    {
      value: "REFERENCE",
      text: t(`${translationPrefix}.fields.bankAccountType.eurAccount`, {
        iban: bankAccount?.iban,
      }),
    },
    hasUSDAccount
      ? {
          value: "USD_CURRENCY_ACCOUNT",
          text: t(`${translationPrefix}.fields.bankAccountType.usdAccount`, {
            iban: bankAccount.usdIban,
          }),
        }
      : {
          value: "NEW_USD",
          text: t(`${translationPrefix}.fields.bankAccountType.newUSDAccount`),
        },
  ];

  if (!fundAttributes) {
    console.error("Fund attributes are missing");
    return null;
  }

  const sourceOfWealthDictionary: Record<
    string,
    { label: string; placeholder?: string; descriptiveText: string }
  > = {
    INHERITED: {
      label: t(
        `${translationPrefix}.fields.wealthOrigin.description.INHERITED.label`
      ),
      placeholder: t(
        `${translationPrefix}.fields.wealthOrigin.description.INHERITED.placeholder`
      ),
      descriptiveText: t(
        `${translationPrefix}.fields.wealthOrigin.description.INHERITED.descriptiveText`
      ),
    },
    SALARY: {
      label: t(
        `${translationPrefix}.fields.wealthOrigin.description.SALARY.label`
      ),
      descriptiveText: t(
        `${translationPrefix}.fields.wealthOrigin.description.SALARY.label`
      ),
    },
    SALE_OF_BUSINESS: {
      label: t(
        `${translationPrefix}.fields.wealthOrigin.description.SALE_OF_BUSINESS.label`
      ),
      descriptiveText: t(
        `${translationPrefix}.fields.wealthOrigin.description.SALE_OF_BUSINESS.label`
      ),
    },
    OTHERS: {
      label: t(
        `${translationPrefix}.fields.wealthOrigin.description.OTHERS.label`
      ),
      placeholder: t(
        `${translationPrefix}.fields.wealthOrigin.description.OTHERS.label`
      ),
      descriptiveText: t(
        `${translationPrefix}.fields.wealthOrigin.description.OTHERS.label`
      ),
    },
  };

  return (
    <LoadingWrapper
      title={t("loadingScreen.title")}
      message={t("loadingScreen.message")}
      isLoading={loading}
    >
      <FundDetailsCard>
        <FundDetailsHeader
          fundName={fundAttributes.name ?? ""}
          fundAssetClass={fundAttributes.assetClass}
          fundDescription={fundAttributes.shortDescription}
          fundManagerImageUrl={fundAttributes.fundManagerImage ?? undefined}
        >
          <FundAmountHeader
            fundAmount={signingAmount ?? 0}
            fundCurrency={fundCurrency}
          />
        </FundDetailsHeader>
        <Card
          padding={{ sm: "tera", md: "peta" }}
          borderRadius={{ sm: "byte", md: "kilo" }}
          borderStyle="none"
        >
          <FormControl<BankAccountSelectionFormData>
            onBack={previousStep}
            dontShowErrors
            // @ts-expect-error missing prop
            defaultValues={defaultData}
            onSubmit={onSubmit}
            validationMode="onChange"
            reValidationMode="onChange"
            validationSchema={memoizedSchema}
            submitLabel={t("onboarding.form.continue")}
            backLabel={t("onboarding.form.back")}
            dataAnalytics="4.2_fund_declarations_bank_account_selection"
          >
            {(formMethods) => {
              const { register, errors, watch } = formMethods;
              const newUSDAccount =
                watch("bankAccountType") === AccountSelectionType.NEW_USD;
              // force yup to check for validation
              formMethods.watch("usdIban");
              formMethods.watch("usdBic");
              formMethods.watch("usdNameOfBank");

              const watchWealthOrigin = watch("sourceOfFunds");

              return (
                <FormContent
                  title={t(`${translationPrefix}.title`)}
                  error={error}
                  subTitle={t(`${translationPrefix}.subTitle`)}
                  isDirty={formMethods.formState.isDirty}
                  isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
                >
                  <Stack gutter="peta" alignItems="center">
                    <Box>
                      <InfoBox icon="info">
                        {t(`${translationPrefix}.infoBox`, {
                          Currency: fundCurrency?.toUpperCase(),
                        })}
                      </InfoBox>
                    </Box>
                    <Grid
                      columns={{ sm: 1, md: 2 }}
                      columnsGap="yotta"
                      rowsGap="giga"
                    >
                      <Heading
                        fontWeight="medium"
                        as="h5"
                        size={{ sm: 3, lg: 4 }}
                        font="sans"
                      >
                        {t(`${translationPrefix}.sections.account`)}
                      </Heading>
                      <span />
                      <SelectInput
                        ref={register}
                        name="bankAccountType"
                        value={defaultData.bankAccountType}
                        label={t(
                          `${translationPrefix}.fields.bankAccountType.label`
                        )}
                        options={bankAccountSelectionOptions}
                        errorMessage={errors.bankAccountType?.message}
                      />
                      <span />
                      {newUSDAccount && (
                        <>
                          <TextInput
                            crossOrigin
                            ref={register}
                            name="accountHolderCompany"
                            label={t(
                              `${translationPrefix}.fields.accountHolderCompany.label`
                            )}
                            disabled
                            required
                          />
                          <TextInput
                            crossOrigin
                            ref={register}
                            name="usdIban"
                            label={t(
                              `${translationPrefix}.fields.usdIban.label`
                            )}
                            required
                            errorMessage={errors?.usdIban?.message}
                          />
                          <TextInput
                            crossOrigin
                            ref={register}
                            name="usdBic"
                            label={t(
                              `${translationPrefix}.fields.usdBic.label`
                            )}
                            required
                            errorMessage={errors?.usdBic?.message}
                          />
                          <TextInput
                            crossOrigin
                            ref={register}
                            name="usdNameOfBank"
                            label={t(
                              `${translationPrefix}.fields.usdNameOfBank.label`
                            )}
                            errorMessage={errors?.usdNameOfBank?.message}
                          />
                        </>
                      )}
                    </Grid>
                    <Stack gutter="mega">
                      <Heading
                        fontWeight="medium"
                        as="h5"
                        size={{ sm: 3, lg: 4 }}
                        font="sans"
                      >
                        {t(`${translationPrefix}.sections.sourceOfFunds`)}
                      </Heading>
                      
                      <Box width={{ sm: "100%", md: "45%" }}>
                        <SelectInput
                          ref={register}
                          options={buildOptions<WealthOrigin>({
                            lang: i18n,
                            key: `${translationPrefix}.fields.wealthOrigin.values`,
                          })}
                          name="sourceOfFunds"
                          label={t(
                            `${translationPrefix}.fields.wealthOrigin.label`
                          )}
                          value={defaultData?.sourceOfFunds ?? undefined}
                          helperText={t(`${wealthOriginPrefix}.tooltip`)}
                          required
                        />
                      </Box>
                      {watchWealthOrigin && (
                        <Grid gap="giga" columns={1}>
                          <Stack gutter="bit">
                            <TextAreaInput
                              crossOrigin
                              ref={formMethods.register}
                              defaultValue={
                                defaultData?.sourceOfFundsDetail ?? undefined
                              }
                              name="sourceOfFundsDetail"
                              label={
                                sourceOfWealthDictionary[watchWealthOrigin]
                                  .label
                              }
                              placeholder={
                                sourceOfWealthDictionary[watchWealthOrigin]
                                  ?.placeholder
                              }
                            />
                            <Text as="span" size={2} color="neutral.600">
                              {
                                sourceOfWealthDictionary[watchWealthOrigin]
                                  .label
                              }
                            </Text>
                          </Stack>
                        </Grid>
                      )}
                    </Stack>
                  </Stack>
                  {isLegalEntity && <Atad />}
                </FormContent>
              );
            }}
          </FormControl>
        </Card>
      </FundDetailsCard>
    </LoadingWrapper>
  );
};

export default BankAccountSelection;
