import { TFunction } from "i18next";
import * as yup from "yup";
import { MoneyRangeSmall } from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.income";

export const incomeCheckboxNames = [
  "employment",
  "selfEmployment",
  "renting",
  "capitalGains",
  "other",
] as const;

const incomeCheckboxValidationSchema = (t: TFunction, checkboxName: string) =>
  yup.object({
    checkbox: yup.boolean(),
    range: yup
      .mixed<MoneyRangeSmall>()
      .oneOf(
        Object.values(MoneyRangeSmall),
        t(`${checkboxName}.range.required`)
      )
      .when("checkbox", {
        is: true,
        then: yup
          .mixed<MoneyRangeSmall>()
          .required(t(`${checkboxName}.range.required`)),
      })
      .label(t(`${checkboxName}.range.label`))
      .nullable(true),
    value: yup
      .mixed()
      .transform((value) =>
        typeof value !== "number" ? Number(value.replace(/[.,\s]/g, "")) : value
      )
      .when("range", {
        is: (money: MoneyRangeSmall) =>
          money === MoneyRangeSmall.MORE_THAN_5000,
        then: yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .test(
            "minimum",
            t(`${checkboxName}.value.minimum`),
            (value) => (value ?? 0) > 5000
          )
          .required(t(`${checkboxName}.value.required`)),
      })
      .nullable(true),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const incomeValidationSchema = (t: TFunction) => {
  return yup
    .object({
      employment: incomeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.income.employment`
      ),
      selfEmployment: incomeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.income.selfEmployment`
      ),
      renting: incomeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.income.renting`
      ),
      capitalGains: incomeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.income.capitalGains`
      ),
      other: incomeCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.income.other`
      ),
    })
    .test("incomeCheckboxGroup", "", (obj, testContext) => {
      if (
        obj.employment.range ||
        obj.selfEmployment.range ||
        obj.renting.range ||
        obj.capitalGains.range ||
        obj.other.range
      ) {
        return true;
      }

      return testContext.createError({
        message: t(`${translationPrefix}.incomeCheckboxGroup`),
        path: `${testContext.path}.incomeCheckboxGroup`,
      });
    });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      income: incomeValidationSchema(t),
    })
    .required();
