import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import config from "../config";
import { configureStore } from "../store";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";

function store(link: ApolloLink) {
  const client = new ApolloClient({
    connectToDevTools: config.featureFlags.apolloDevTools,
    cache: new InMemoryCache({ resultCaching: false }),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

  return configureStore(client);
}

const StoreProvider: React.FC = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createHttpLink({
    uri: config.apiUri,
  });

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const link = authLink.concat(httpLink);

  return <ReduxProvider store={store(link)}>{children}</ReduxProvider>;
};

export default StoreProvider;
