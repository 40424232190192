const appEnvironment = process.env.REACT_APP_ENVIRONMENT ?? "sandbox";

const isLiveEnvironment = ["staging", "production"].includes(appEnvironment);
const isDevelopmentEnvironment = ["development", "sandbox"].includes(
  appEnvironment
);

type ThirdParty = {
  cookiefirst?: {
    apiKey: string;
  };
};

const thirdParty: ThirdParty = {
  cookiefirst: {
    apiKey: process.env.REACT_APP_COOKIE_FIRST_API_KEY ?? "",
  },
};

type Polling = { maxAttempts?: number; interval?: number };

const polling: Polling = { maxAttempts: 5, interval: 5000 };

type KeepAlive = { interval?: number };

const keepAlive: KeepAlive = {
  interval: 2.7 * 60 * 1000,
};

const config = {
  analytics: {
    segmentApiKey: process.env.SEGMENT_API_KEY,
    postHog: {
      apiKey: process.env.REACT_APP_POSTHOG_API_KEY ?? "",
      apiHost: process.env.REACT_APP_POSTHOG_API_HOST ?? "",
    },
  },
  appEnvironment,
  apiUri:
    process.env.REACT_APP_API_URI ?? "https://onboarding.finvia.localhost/api",
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
  auth0IssuesBaseUrl: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL ?? "",
  basePath: process.env.REACT_APP_PUBLIC_URL ?? "/",
  version: process.env.REACT_APP_VERSION,
  sentry: {
    isEnabled: isLiveEnvironment,
    dsn: process.env.REACT_APP_SENTRY_DSN ?? "",
  },
  isDevelopmentEnvironment,
  sandbox: {
    isEnabled: isDevelopmentEnvironment,
  },
  accessControl: {
    clientGroupsAuthorizedToSign: [
      "AlternativeInvestmentsMandator",
      "FamilyOfficeClient",
    ],
    userGroupForProductionBetaTesting: "access-production-beta",
  },
  featureFlags: {
    cookieFirst: isLiveEnvironment,
    stepperFreelyAccessible: isDevelopmentEnvironment,
    postHog: ["development", "sandbox", "staging"].includes(appEnvironment),
    apolloDevTools: true,
  },
  thirdParty,
  polling,
  keepAlive,
};

export default config;
