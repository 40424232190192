import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import useCompletedForms from "../../../features/alternative-investments/components/useCompletedForms";
import CalendlyButton from "../CalendlyButton/CalendlyButton";
import { ROUTES } from "../../../routes/routes";
import SidebarContentBlock from "./SidebarContentBlock";
import SidebarOverrideContext from "./SidebarOverrideContext";
import team from "../../../assets/images/advisors-help@2x.png";
import {
  Text,
  Heading,
  Button,
  Stack,
  Box,
  Card,
  Icon,
} from "@finvia/money-ui";
import {
  Advisors,
  BackLink,
  Divider,
  ShowOnMobile,
  PhoneNumber,
  StyledLink,
  ShowOnDesktop,
} from "./SideBar.styled";

const SidebarDefault: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack gutter="giga" alignItems="center">
      <Stack gutter="mega" direction="column" alignItems="center">
        <Advisors>
          <img width={134} height={54} src={team} alt="" />
        </Advisors>
        <SidebarContentBlock
          title={t("sidebar.questions.title")}
          description={t("sidebar.questions.description")}
          textAlign="center"
        >
          <CalendlyButton />
        </SidebarContentBlock>
      </Stack>

      <Route path={ROUTES.funds.details.signingSuccessful}>
        <Box>
          <SidebarContentBlock
            title={t("sidebar.portal.title")}
            description={t("sidebar.portal.description")}
            textAlign="center"
          >
            <Button
              as="a"
              href={ROUTES.external.finviaApp}
              target="_blank"
              variant="secondary"
            >
              {t("sidebar.portal.buttonText")}
            </Button>
          </SidebarContentBlock>
        </Box>
      </Route>

      <Box>
        <Text align="center">{t("sidebar.contact.title")}</Text>
        <PhoneNumber
          href={`tel:+49${t("sidebar.contact.phoneNumber").replace(/\s/g, "")}`}
        >
          {t("sidebar.contact.phoneNumber")}
        </PhoneNumber>
      </Box>
    </Stack>
  );
};

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const { sidebarOverride } = useContext(SidebarOverrideContext);
  const completedForms = useCompletedForms();

  const backToOverviewButtonURL = completedForms
    ? ROUTES.funds.root
    : ROUTES.questionnaires.ai.root;

  return (
    <Box width="100%" display="flex" alignItems="center" flexDirection="column">
      <ShowOnDesktop shouldShow>
        <Stack gutter="byte" alignItems="center">
          <Heading textTransform="uppercase" as="h3" size="4" align="center">
            {t("sidebar.title")}
          </Heading>
          <BackLink href={backToOverviewButtonURL}>
            {t("sidebar.backToOverview")}
          </BackLink>
          <Divider />
        </Stack>
      </ShowOnDesktop>
      <ShowOnMobile shouldShow={completedForms}>
        <Box alignItems="center" width="100%">
          <StyledLink href="https://apps.apple.com/ch/app/finvia/id1560004199">
            <Divider />
            <Card>
              <Stack gutter="mega" alignItems="center">
                <Icon name="portal" size="mega" />
                <Text size={3}>{t("sidebar.toCockpitMobile")}</Text>
              </Stack>
            </Card>
          </StyledLink>
        </Box>
        <Divider />
      </ShowOnMobile>
      <ShowOnDesktop shouldShow={completedForms}>
        <StyledLink href="https://app.finvia.fo">
          <Card>
            <Stack gutter="mega" alignItems="center">
              <Icon name="portal" size="mega" />
              <Text size={3}>{t("sidebar.toCockpit")}</Text>
            </Stack>
          </Card>
          <Divider />
        </StyledLink>
      </ShowOnDesktop>
      {sidebarOverride ?? <SidebarDefault />}
    </Box>
  );
};

export default Sidebar;
