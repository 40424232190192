import { FinviaTheme } from "@finvia/component-library";
import { makeStyles } from "@material-ui/core";
import { Button, Grid, Tabs, Text, Stack, Card } from "@finvia/money-ui";
import { compact } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, Route, useHistory, useParams } from "react-router-dom";
import errorIcon from "../../../../assets/icons/error-red.svg";
import {
  ENUM_ALTERNATIVEINVESTMENTFUND_LEGALSTRUCTURE,
  ENUM_ALTERNATIVEINVESTMENTFUND_STATUS,
} from "../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../store";
import { upsertAlternativeInvestmentsFundContract } from "../../funds/actions";
import FundDetailsHeader from "../../funds/components/FundDetailsHeader/FundDetailsHeader";
import { FundDetailsHeaderContent } from "../../funds/components/FundDetailsHeaderContent";
import useCompletedForms from "../useCompletedForms";
import { ROUTES } from "../../../../routes/routes";
import Documents from "./Documents/Documents";
import Highlights from "./Highlights";
import Legal from "./Legal";
import Overview from "./Overview/Overview";
import { fundCurrencyTranslation } from "../../../../utils/fundCurrencyTranslation";
import {
  FundFragment_attributes_documents,
  FundFragment,
} from "../../../../generated/FundFragment";
import * as S from "./FundDetails.styled";
import Profile from "./Profile/Profile";
import { EntityState } from "@reduxjs/toolkit";
import TrackRecord from "./TrackRecord/TrackRecord";

const useStyles = makeStyles((theme) => ({
  tabSection: {
    paddingTop: "40px",
    paddingBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20px",
      paddingBottom: "30px",
    },
  },
  backButton: {
    width: "auto",
    border: `2px solid ${FinviaTheme.Palette.grey[850]}`,
  },
  errorBox: {
    backgroundColor: "white",
    paddingTop: "20px",
  },
}));

const Status = {
  loading: "loading",
  available: "available",
  downloaded: "downloaded",
  failed: "failed",
} as const;

export type TStatus = keyof typeof Status;

export interface Document extends FundFragment_attributes_documents {
  status: TStatus;
}

interface FundDetailsProps {
  fund: FundFragment;
  funds: EntityState<FundFragment>;
  returnLink: string;
}

const FundDetails: React.FC<FundDetailsProps> = ({ fund, funds }) => {
  const { t } = useTranslation();
  const { slug: routeId, section } = useParams<{
    slug: string;
    section: string;
  }>();
  const history = useHistory();
  const currentPath = `${ROUTES.funds.root}/${routeId}`;

  const completedForms = useCompletedForms();

  function setActiveSection(sect: string): void {
    history.push(`${currentPath}/${sect}`);
  }

  // TODO: fix this https://github.com/FINVIA/mono/issues/7870
  // eslint-disable-next-line
  const fundAttributes = fund.attributes!;
  const isFundClosed =
    fundAttributes.status === ENUM_ALTERNATIVEINVESTMENTFUND_STATUS.closed;

  const showProfile = fundAttributes.profile !== null;
  const showTrackRecord = fundAttributes.trackRecord !== null;

  const tabs: string[] = [
    t("components.alternativeInvestmentFund.tabs.overview"),
    t("components.alternativeInvestmentFund.tabs.highlights"),
    ...(showProfile
      ? [t("components.alternativeInvestmentFund.tabs.profile")]
      : []),
    ...(showTrackRecord
      ? [t("components.alternativeInvestmentFund.tabs.trackRecord")]
      : []),
    ...(!isFundClosed
      ? [
          t("components.alternativeInvestmentFund.tabs.legal"),
          t("components.alternativeInvestmentFund.tabs.documents"),
        ]
      : []),
  ];

  const sections: string[] = ["overview", "highlights", "legal", "documents"];

  if (showProfile) {
    sections.splice(2, 0, "profile");
  }

  if (showTrackRecord) {
    let trackRecordPosition = 2;
    if (showProfile) {
      trackRecordPosition = 3;
    }
    sections.splice(trackRecordPosition, 0, "track-record");
  }

  const [activeTab, setActiveTab] = useState(
    sections.indexOf(section) > 0 ? sections.indexOf(section) : 0
  );

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
    setActiveSection(sections[tab]);
  };

  const classes = useStyles();

  function handleBackButton(): void {
    const url = completedForms
      ? ROUTES.funds.root
      : `${ROUTES.questionnaires.ai.opportunities}/5`;

    history.push(url);
  }

  const { currentSigningAmount, currentFundSlug } = typedUseSelector(
    (state) => ({
      currentFundSlug:
        state.alternativeInvestments.alternativeInvestmentFunds.data.slug,
      currentSigningAmount:
        state.alternativeInvestments.alternativeInvestmentFunds.data
          .signingAmount,
    })
  );

  const [amount, setAmount] = useState(
    currentSigningAmount && fundAttributes.slug === currentFundSlug
      ? currentSigningAmount
      : fundAttributes.minimumCommitmentAmount
  );
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  async function onSubmit(): Promise<void> {
    if (amount < fundAttributes.minimumCommitmentAmount) {
      setError(true);
      return;
    }
    await dispatch(
      upsertAlternativeInvestmentsFundContract({
        signingAmount: amount,
        signingCurrency: fundAttributes.currency,
        slug: fundAttributes.slug || "",
      })
    );

    const isDigitalFund =
      fundAttributes.legalStructure !==
      ENUM_ALTERNATIVEINVESTMENTFUND_LEGALSTRUCTURE.external;

    const fundRoute = isDigitalFund
      ? ROUTES.funds.details.bankAccountSelection
      : ROUTES.funds.details.signingNotDigital;

    history.push(generatePath(fundRoute, { slug: routeId }), {
      scrollToTop: true,
    });
  }

  const documents = compact(fundAttributes.documents);
  const documentsListAddedStatus: Record<string, Document> = documents.reduce(
    (acc, doc) => {
      const isUrlAvailable = doc.link ?? doc.file?.data?.attributes?.url;
      const documentStatus: TStatus = isUrlAvailable
        ? Status.available
        : Status.loading;
      const docName = doc.name ?? "";

      return {
        ...acc,
        [docName]: { ...doc, status: documentStatus as TStatus },
      };
    },
    {}
  );

  const [documentsList, setDocumentsList] = useState<Record<string, Document>>(
    documentsListAddedStatus
  );

  const handleDocumentClick = (document: Document) => {
    const newDoc: Document = { ...document, status: Status.downloaded };
    const docName = document.name ?? "";
    setDocumentsList({
      ...documentsList,
      [docName]: newDoc,
    });
  };

  return (
    <S.FundDetailsCard>
      <FundDetailsHeader
        fundName={fundAttributes.name ?? ""}
        fundAssetClass={fundAttributes.assetClass}
        fundManagerImageUrl={fundAttributes?.fundManagerImage || undefined}
        fundDescription={fundAttributes.shortDescription}
      >
        <FundDetailsHeaderContent
          isFundClosed={isFundClosed}
          completedForms={completedForms}
          amount={amount}
          currency={fundAttributes?.currency}
          setAmount={setAmount}
          onSubmit={onSubmit}
        />
      </FundDetailsHeader>

      {error && (
        <div className={classes.errorBox}>
          <Stack gutter="mega" alignItems="center">
            <img src={errorIcon} alt="error" />
            <Text size={{ sm: 0, lg: 1 }} variant="error" align="center">
              {t("components.alternativeInvestmentFund.minimumSigningAmount")}
              &nbsp;
              {t(fundCurrencyTranslation(fundAttributes.currency), {
                value: fundAttributes.minimumCommitmentAmount,
              })}
            </Text>
          </Stack>
        </div>
      )}

      <Card
        paddingTopBottom={{ sm: "giga", md: "peta" }}
        paddingLeftRight={{ sm: "kilo", md: "peta" }}
        borderStyle="none"
      >
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onClick={(tab) => handleTabClick(tab)}
        />
        <div className={classes.tabSection}>
          <Route path={`${currentPath}/overview`}>
            <Overview fund={fund} />
          </Route>
          <Route path={`${currentPath}/highlights`}>
            <Highlights
              highlights={compact(fundAttributes.highlights)}
              highlightVideo={fundAttributes.highlightVideo}
            />
          </Route>
          <Route path={`${currentPath}/profile`}>
            <Profile fund={fund} funds={funds} />
          </Route>
          <Route path={`${currentPath}/track-record`}>
            <TrackRecord fund={fund} funds={funds} />
          </Route>
          <Route path={`${currentPath}/legal`}>
            <Legal legalTraits={compact(fundAttributes.legalTraits)} />
          </Route>
          <Route path={`${currentPath}/documents`}>
            <Documents
              documents={documentsList}
              onClick={handleDocumentClick}
              displayDialog={!completedForms}
            />
          </Route>
        </div>
        <Grid justifyItems="center">
          <Button
            variant="ghost"
            onClick={handleBackButton}
            data-testid="return-link"
          >
            {t("components.alternativeInvestmentFund.backButton")}
          </Button>
        </Grid>
      </Card>
    </S.FundDetailsCard>
  );
};

export default FundDetails;
