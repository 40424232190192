import styled, { css } from "styled-components";

const TRANSITION_PERIOD = 300;

const containerBaseStyles = () => css`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    padding-bottom: 80px;
    transition: opacity ${TRANSITION_PERIOD}ms ease-in;

    ${theme.utils.viewport.md` 
      padding-top: 57px;
    `}
  `};
`;

const showBaseStyles = ({ showLoading }: { showLoading: boolean }) =>
  showLoading &&
  css`
    opacity: 1;
  `;

const textWrapperBaseStyles = () => css`
  ${({ theme }) => css`
    margin-top: ${theme.tokens.sizes.spacings.mega};
    max-width: 500px;
  `};
`;

export const Container = styled.div<{ showLoading: boolean }>(
  containerBaseStyles,
  showBaseStyles
);
export const TextWrapper = styled.div(textWrapperBaseStyles);

const pageContainerBaseStyles = () => css`
  min-height: calc(100vh - 19px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageContainer = styled.div(pageContainerBaseStyles);
