import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { defaultAddress } from "../../common/GenericValidations/address";
import { updateLegalEntityProfile } from "../actions";
import {
  Heading,
  Stack,
  Grid,
  Checkbox,
  TextInput,
  SelectInput,
} from "@finvia/money-ui";

import TooltipInfo from "features/alternative-investments/components/TooltipInfo";
import { countries } from "utils/options/countries";

import { ContactDataProps, validationSchema } from ".";
import { formFields, isRequired, getInputLabel } from "./ContactData.utils";

const translationPrefix = "q-ai.opportunities.form.legalEntityContactData";
const i18nFormFields = "q-ai.opportunities.form.contactData.fields";

const ContactData: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns={1}>
        <SidebarContentBlock
          iconType="asset-management"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.LegalEntityDetails.title")}
          description={t("sidebar.LegalEntityDetails.description")}
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<ContactDataProps>(
      {
        legalEntityContactData: {
          legalAddress:
            state.alternativeInvestments.regulatoryData.legalEntityData
              .legalEntityContactData?.legalAddress,
          hasSeparateContactAddress:
            state.alternativeInvestments.regulatoryData.legalEntityData
              .legalEntityContactData?.hasSeparateContactAddress,
          contactAddress:
            state.alternativeInvestments.regulatoryData.legalEntityData
              .legalEntityContactData?.contactAddress,
        },
      },
      {
        legalEntityContactData: {
          legalAddress: defaultAddress,
          hasSeparateContactAddress: false,
          contactAddress: defaultAddress,
        },
      }
    )
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (legalEntityData: ContactDataProps) => {
      dispatch(updateLegalEntityProfile(legalEntityData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<ContactDataProps>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={validationSchema(t)}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      dataAnalytics="2.11_le_contact_data"
    >
      {(formMethods) => {
        const { watch, register, formState, errors, trigger } = formMethods;

        const watchDifferentAddress = watch(
          "legalEntityContactData.hasSeparateContactAddress",
          false
        );

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formState.isDirty}
            isSubmitSuccessful={formState.isSubmitSuccessful}
            disableDisclaimer
          >
            <Stack gutter="bit" direction="row" alignItems="center">
              <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                {t(`${translationPrefix}.fields.legalAddress.title`)}
              </Heading>
              <TooltipInfo
                label={t(`${translationPrefix}.fields.legalAddress.tooltip`)}
              />
            </Stack>

            <Grid columns={{ sm: 1, md: 2 }} rowsGap="giga" columnsGap="yotta">
              {formFields.map((field) => {
                if (
                  formState.touched?.legalEntityContactData?.legalAddress?.[
                    field
                  ]
                ) {
                  trigger(`legalEntityContactData.legalAddress.${field}`);
                }

                return (
                  <TextInput crossOrigin
                    key={field}
                    ref={register}
                    required={isRequired(field, i18n)}
                    label={getInputLabel(field, t)}
                    name={`legalEntityContactData.legalAddress.${field}`}
                    defaultValue={
                      defaultData.legalEntityContactData?.legalAddress?.[field]
                    }
                    errorMessage={
                      errors?.legalEntityContactData?.legalAddress?.[field]
                        ?.message
                    }
                  />
                );
              })}
              <SelectInput
                ref={register}
                name="legalEntityContactData.legalAddress.country"
                label={t(`${i18nFormFields}.country.label`)}
                required
                value={
                  defaultData.legalEntityContactData?.legalAddress?.country
                }
                options={countries(t)}
                errorMessage={
                  errors?.legalEntityContactData?.legalAddress?.country?.message
                }
                searchable
              />
            </Grid>

            <Checkbox
              label={t(
                `${translationPrefix}.fields.hasSeparateContactAddress.label`
              )}
              name="legalEntityContactData.hasSeparateContactAddress"
              ref={register}
            />

            {watchDifferentAddress && (
              <Stack gutter="tera">
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.fields.contactAddress.title`)}
                </Heading>

                <Grid
                  columns={{ sm: 1, md: 2 }}
                  rowsGap="tera"
                  columnsGap="yotta"
                >
                  {formFields.map((field) => {
                    if (
                      formState.touched?.legalEntityContactData
                        ?.contactAddress?.[field]
                    ) {
                      trigger(`legalEntityContactData.contactAddress.${field}`);
                    }

                    return (
                      <TextInput crossOrigin
                        key={field}
                        ref={register}
                        required={isRequired(field, i18n)}
                        label={getInputLabel(field, t)}
                        name={`legalEntityContactData.contactAddress.${field}`}
                        defaultValue={
                          defaultData.legalEntityContactData?.contactAddress?.[
                            field
                          ]
                        }
                        errorMessage={
                          formMethods.errors?.legalEntityContactData
                            ?.contactAddress?.[field]?.message ?? undefined
                        }
                      />
                    );
                  })}
                  <SelectInput
                    ref={register}
                    name="legalEntityContactData.contactAddress.country"
                    label={t(`${i18nFormFields}.country.label`)}
                    required
                    value={
                      defaultData.legalEntityContactData?.contactAddress
                        ?.country
                    }
                    options={countries(t)}
                    errorMessage={
                      errors?.legalEntityContactData?.contactAddress?.country
                        ?.message
                    }
                    searchable
                  />
                </Grid>
              </Stack>
            )}
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default ContactData;
