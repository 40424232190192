import { theme } from "@finvia/money-ui";
import {
  Accordion as BaseAccordion,
  AccordionDetails as BaseAccordionDetails,
  AccordionSummary as BaseAccordionSummary,
  Button,
  withStyles,
} from "@material-ui/core";

const treeDescendantLine = {
  position: "absolute",
  content: "''",
  top: "50%",
  left: "-29px",
  width: "28px",
  height: "1px",
  borderBottom: `1px solid ${theme.tokens.colors.petrol[600]}`,
};

export const Accordion = withStyles({
  root: {
    boxShadow: "none",
    borderLeft: `1px solid ${theme.tokens.colors.petrol[600]}`,
    marginBottom: "16px",
    "&::before": {
      content: "none",
    },
    "&.Mui-expanded:not(:last-child)": {
      margin: "0 0 16px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "5px 0",
    },
    "& .MuiAccordion-root:last-child": {
      marginBottom: "0",
    },
  },
})(BaseAccordion);

export const AccordionSummary = withStyles({
  root: {
    border: `1px solid ${theme.tokens.colors.petrol[600]}`,
    borderLeft: "none",
    minHeight: "0",
    margin: "0",
    "&.Mui-expanded": {
      margin: "0 !important",
      minHeight: "0",
    },
  },
})(BaseAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    display: "block",
    width: "100%",
    margin: "15px 0 0",
    padding: "0 0 0 28px",
    "&:not(:last-child)": {
      marginBottom: "15px",
    },
    "& .MuiAccordionSummary-root": {
      "&::before": treeDescendantLine,
    },
    "&::after": {
      position: "absolute",
      content: "''",
      bottom: "0px",
      left: "-1px",
      width: "1px",
      height: "14px",
      borderLeft: "1px solid white",
    },
  },
})(BaseAccordionDetails);

export const IndustryButton = withStyles({
  root: {
    border: `1px solid ${theme.tokens.colors.petrol[600]}`,
    display: "flex",
    justifyContent: "flex-start",
    "&:not(:last-child)": {
      marginBottom: "15px",
    },
    "&::before": treeDescendantLine,
  },
})(Button);
