import { Fragment } from "react";

export const breakLines = ({
  text,
  char,
}: {
  text: string;
  char: string;
}): JSX.Element[] =>
  text.split(`${char}`).map((line, idx, arr) =>
    arr.length - 1 === idx ? (
      <Fragment key={`${idx}-${line}`}>{line}</Fragment>
    ) : (
      <Fragment key={`${idx}-${line}`}>
        {line}
        <br />
      </Fragment>
    )
  );
