import React, { useEffect } from "react";
import Router from "./routes/Router";
import { useAuth0 } from "@auth0/auth0-react";
import PersonDataGate from "./components/PersonDataGate";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "./components/LoadingWrapper/LoadingWrapper";
import { useLocation } from "react-router-dom";

const App: React.FC = () => {
  const { search } = useLocation();

  const { t } = useTranslation();
  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    if (!isAuth0Loading && !isAuthenticated) {
      const searchParams = new URLSearchParams(search);

      if (searchParams.get("action") === "signup") {
        loginWithRedirect({
          authorizationParams: {
            screen_hint: "signup",
          },
        });
      } else {
        loginWithRedirect();
      }
    }
  }, [isAuth0Loading, isAuthenticated, loginWithRedirect, search]);

  return (
    <>
      {isAuthenticated ? (
        <PersonDataGate>
          <Router />
        </PersonDataGate>
      ) : (
        <LoadingWrapper
          isLoading
          fullScreenSize
          title={t("components.dataGate.title")}
          message={t("components.dataGate.message")}
        >
          <></>
        </LoadingWrapper>
      )}
    </>
  );
};

export default App;
