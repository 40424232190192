import {
  UnionFinancialInstruments,
  UnionUnderSupervision,
  UnionGovernmentFunction,
  UnionSecurizationOfLiabilities,
  UnionLargeCompany,
  UnionExtensiveExperience,
  UnionSufficientExperienceAndKnowledgeConfirmed,
} from "./Professional.types";

export const underSupervisionFields: Array<UnionUnderSupervision> = [
  "creditInstitution",
  "investingFirm",
  "financialInstitution",
  "insuranceCompany",
  "collectiveInvestmentOrganism",
  "pensionFund",
  "merchants",
  "otherInstitutionalInvestor",
];

export const financialInstrumentsFields: Array<UnionFinancialInstruments> = [
  "otherInstitutionalInvestor",
];

export const governmentFunctionFields: Array<UnionGovernmentFunction> = [
  "companyAsGovernment",
];

export const securizationOfLiabilitiesFields: Array<UnionSecurizationOfLiabilities> =
  ["companyAsSecurizationOfLiabilities"];

export const largeCompanyFields: Array<UnionLargeCompany> = [
  "balanceSheetTotal",
  "netSales",
  "ownFunds",
];

export const extensiveExperienceFields: Array<UnionExtensiveExperience> = [
  "tenTransactionsInTheLastFourQuarters",
  "moreThan500k",
  "jobInCapitalMarket",
];

export const sufficientExperienceAndKnowledgeConfirmedFields: Array<UnionSufficientExperienceAndKnowledgeConfirmed> =
  [
    "isRelevantMarketExperienceConfirmed",
    "isRelevantFinancialPortfolioConfirmed",
    "isRelevantMarketJobConfirmed",
  ];

export const hasAnyNested = <T extends string | number>(
  fields: Record<T, boolean>
): string[] =>
  Object.keys(fields).filter(
    (field) => field !== "isConfirmed" && fields[field as T] === true
  );
