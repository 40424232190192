import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResidencyField from "./ResidencyField";
import { useState } from "react";
import { HandleDocumentUpload, TaxInformation } from "../";
import { updateTaxInformationResidencyDocuments } from "../../actions";
import { DocumentCategory } from "generated/globalTypes";
import { useDispatch } from "react-redux";
import { AnyAction, SerializedError } from "@reduxjs/toolkit";
import { Box, Button, Grid } from "@finvia/money-ui";
import {
  handleDeleteDocumentGeneric,
  handleDocumentUploadGeneric,
} from "features/alternative-investments/components/UploadField";
import { countries } from "utils/options/countries";
import { filterCountries } from "../../../naturalPerson/EmploymentAndTaxForm/helper";
import {
  HandleDocumentDelete,
  Payload,
  Residency,
  ResidencyDocuments,
  ResidencyFieldsProps,
} from "../TaxInformation.types";
import FirstResidencyField from "./FirstResidencyField";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

const ResidencyFields: React.FC<ResidencyFieldsProps> = ({ residencies }) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const formMethods = useFormContext<TaxInformation>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<Residency>({
    control: formMethods.control,
    name: "taxInformation.residencies",
  });
  const dispatch = useDispatch();

  const maxAmountOfResidencies = 3;

  let documentStore = {};

  if (residencies) {
    documentStore = residencies?.reduce((acc, residency, index) => {
      return residency
        ? {
            ...acc,
            [`taxInformation.residencies[${index}].residencyCertificate`]:
              residency.residencyCertificate,
          }
        : {};
    }, {});
  }

  const [currentDocumentsList, setCurrentDocumentsList] =
    useState<ResidencyDocuments>({
      documents: documentStore,
    });

  const handleDocumentUpload: HandleDocumentUpload = async (
    documents,
    file,
    setValueReference,
    index
  ) => {
    const uploadedDocuments = await handleDocumentUploadGeneric<
      (payload: Payload) => AnyAction
    >({
      documents,
      file,
      setValueReference,
      dispatch,
      updateAction: updateTaxInformationResidencyDocuments,
      category: DocumentCategory.RESIDENCY_CERTIFICATE,
      setError,
      name: `taxInformation.residencies[${index}].residencyCertificate`,
      formName: "TaxInformationForm",
      extraProps: { residencyIndex: index },
    });

    if (uploadedDocuments && Array.isArray(uploadedDocuments)) {
      setCurrentDocumentsList({
        documents: {
          ...currentDocumentsList?.documents,
          [`taxInformation.residencies[${index}].residencyCertificate`]:
            uploadedDocuments,
        },
      });
    }
  };

  const handleDeleteDocument: HandleDocumentDelete = async (
    document,
    documents,
    name,
    setValueReference,
    index
  ) => {
    const filteredDocuments = await handleDeleteDocumentGeneric<
      (payload: Payload) => AnyAction
    >({
      document,
      documents,
      setValueReference,
      dispatch,
      updateAction: updateTaxInformationResidencyDocuments,
      setError,
      name,
      formName: "TaxInformationForm",
      extraProps: { residencyIndex: index },
    });

    if (filteredDocuments) {
      setCurrentDocumentsList({
        documents: {
          ...currentDocumentsList?.documents,
          [name]: filteredDocuments,
        },
      });
    }
  };

  const watchResidencies: Residency[] = formMethods.watch(
    "taxInformation.residencies"
  );

  const countryWatch: any[] | undefined = useWatch({
    control: formMethods.control,
    name: `taxInformation.residencies`,
  });

  return (
    <>
      <Grid columnsGap="yotta" rowsGap="giga" columns={{ sm: 1, md: 2 }}>
        {fields.map((item, index) => {
          const selectedCountries = watchResidencies
            .map((field) => field.country)
            .filter((country, idx, arr) => country !== arr[index]);

          const countriesList = filterCountries({
            countriesToRemove: selectedCountries,
            countriesList: countries(t),
          });

          const isFirstResidency = index === 0;

          if (isFirstResidency) {
            return (
              <FirstResidencyField
                key={item.id}
                index={index}
                isLastItem={index === fields.length - 1}
                formMethods={formMethods}
                item={item}
                country={countryWatch ? countryWatch[index].country : undefined}
                documents={currentDocumentsList.documents}
                onUpload={handleDocumentUpload}
                onDelete={handleDeleteDocument}
              />
            );
          }

          return (
            <ResidencyField
              key={item.id}
              item={item}
              index={index}
              formMethods={formMethods}
              onRemove={() => remove(index)}
              documents={currentDocumentsList.documents}
              onUpload={handleDocumentUpload}
              onDelete={handleDeleteDocument}
              countriesList={countriesList}
            />
          );
        })}
      </Grid>
      {fields.length < maxAmountOfResidencies && (
        <Box display="flex" alignItems="start">
          <Button
            variant="link"
            onClick={() =>
              append({
                country: "",
                taxId: "",
                localTaxOffice: "",
              })
            }
            data-testid="add-another-taxresidency"
          >
            {t(
              `${translationPrefix}.fields.taxResidency.fields.addAnotherResidency`
            )}
          </Button>
        </Box>
      )}
    </>
  );
};

export default ResidencyFields;
