import { TFunction } from "i18next";
import * as yup from "yup";
import {
  FundInvestmentEducationAndProfessionalStatus,
  FundInvestmentExperienceAndKnowledge,
  InvestorType,
} from "../../../../generated/globalTypes";

export const semiProfessionalCheckboxNames: Array<
  keyof Omit<
    FundDeclarationsForm["declarations"]["semiProfessionalInvestor"],
    | "experienceAndKnowledgeType"
    | "experienceAndKnowledgeAppendix"
    | "educationAndProfessionalStatusType"
    | "educationAndProfessionalStatusAppendix"
  >
> = [
  "isSemiProfessionalInvestor",
  "isSufficientExperienceAndKnowledgeConfirmed",
  "isEducationAndProfessionalStatusConfirmed",
  "isAlignedRiskProfileConfirmed",
  "isAlignedFinancialSituationConfirmed",
  "isSufficientIncomeConfirmed",
  "isSufficientFinancialAssetsConfirmed",
];

const translationPrefix = "q-ai.opportunities.form.fundDeclarations";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const semiProfessionalValidationSchema = (t: TFunction) => {
  return yup.object({
    isSemiProfessionalInvestor: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
    isSufficientExperienceAndKnowledgeConfirmed: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
    experienceAndKnowledgeType: yup
      .mixed<FundInvestmentExperienceAndKnowledge>()
      .when("isSufficientExperienceAndKnowledgeConfirmed", {
        is: true,
        then: yup
          .mixed<FundInvestmentExperienceAndKnowledge>()
          .oneOf(
            Object.values(FundInvestmentExperienceAndKnowledge),
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isSufficientExperienceAndKnowledgeConfirmed.required`
            )
          )
          .required(
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isSufficientExperienceAndKnowledgeConfirmed.radioFields.required`
            )
          ),
      }),
    experienceAndKnowledgeAppendix: yup
      .string()
      .when("isSufficientExperienceAndKnowledgeConfirmed", {
        is: true,
        then: yup
          .string()
          .max(
            250,
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isEducationAndProfessionalStatusConfirmed.radioFields.charLength`
            )
          )
          .required(
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isSufficientExperienceAndKnowledgeConfirmed.radioFields.required`
            )
          ),
        otherwise: yup.string(),
      }),
    isEducationAndProfessionalStatusConfirmed: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
    educationAndProfessionalStatusType: yup
      .mixed<FundInvestmentEducationAndProfessionalStatus>()
      .oneOf(
        Object.values(FundInvestmentEducationAndProfessionalStatus),
        t(
          `${translationPrefix}.fields.contract.isEducationAndProfessionalStatusConfirmed.required`
        )
      )
      .when("isEducationAndProfessionalStatusConfirmed", {
        is: true,
        then: yup
          .mixed<FundInvestmentEducationAndProfessionalStatus>()
          .required(
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isEducationAndProfessionalStatusConfirmed.radioFields.required`
            )
          ),
      }),
    educationAndProfessionalStatusAppendix: yup
      .string()
      .when("isEducationAndProfessionalStatusConfirmed", {
        is: true,
        then: yup
          .string()
          .max(
            250,
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isEducationAndProfessionalStatusConfirmed.radioFields.charLength`
            )
          )
          .required(
            t(
              `${translationPrefix}.fields.contract.semiProfessionalInvestor.isEducationAndProfessionalStatusConfirmed.radioFields.required`
            )
          ),
        otherwise: yup.string(),
      }),
    isAlignedRiskProfileConfirmed: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
    isAlignedFinancialSituationConfirmed: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
    isSufficientIncomeConfirmed: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
    isSufficientFinancialAssetsConfirmed: yup
      .boolean()
      .oneOf([true], t(`${translationPrefix}.required`)),
  });
};

const professionalInvestorClassificationUnderSupervision = (t: TFunction) =>
  yup
    .object({
      isConfirmed: yup.boolean(),
      creditInstitution: yup.boolean(),
      investingFirm: yup.boolean(),
      financialInstitution: yup.boolean(),
      insuranceCompany: yup.boolean(),
      collectiveInvestmentOrganism: yup.boolean(),
      pensionFund: yup.boolean(),
      merchants: yup.boolean(),
      otherInstitutionalInvestor: yup.boolean(),
    })
    .test("underSupervision", (value, testContext) => {
      const atLeastOneSelection =
        value.creditInstitution ||
        value.investingFirm ||
        value.financialInstitution ||
        value.insuranceCompany ||
        value.collectiveInvestmentOrganism ||
        value.pensionFund ||
        value.merchants ||
        value.otherInstitutionalInvestor;

      if (
        (!value.isConfirmed && !atLeastOneSelection) ||
        (value.isConfirmed && atLeastOneSelection)
      )
        return true;

      return testContext.createError({
        message: t(
          `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.underSupervision.required`
        ),
        type: "underSupervisionConfirmation",
        path: "declarations.professionalInvestor.professionalInvestorClassification.underSupervision.isConfirmed",
      });
    });

export type ProfessionalInvestorClassificationUnderSupervision = yup.InferType<
  ReturnType<typeof professionalInvestorClassificationUnderSupervision>
>;

const professionalInvestorClassificationLargeCompany = (t: TFunction) =>
  yup
    .object({
      isConfirmed: yup.boolean(),
      balanceSheetTotal: yup.boolean(),
      netSales: yup.boolean(),
      ownFunds: yup.boolean(),
    })
    .test("largeCompany", (value, testContext) => {
      const atLeastOneSelection =
        value.balanceSheetTotal || value.netSales || value.ownFunds;

      const atLeastTwoSelections =
        [value.balanceSheetTotal, value.netSales, value.ownFunds].filter(
          (selection) => selection
        ).length >= 2;

      if (
        (!value.isConfirmed && !atLeastOneSelection) ||
        (value.isConfirmed && atLeastTwoSelections)
      )
        return true;

      return testContext.createError({
        message: t(
          `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.largeCompany.required`
        ),
        type: "largeCompany",
        path: "declarations.professionalInvestor.professionalInvestorClassification.largeCompany.isConfirmed",
      });
    });

export type ProfessionalInvestorClassificationLargeCompany = yup.InferType<
  ReturnType<typeof professionalInvestorClassificationLargeCompany>
>;

const professionalInvestorClassificationExtensiveExperience = (t: TFunction) =>
  yup
    .object({
      isConfirmed: yup.boolean(),
      tenTransactionsInTheLastFourQuarters: yup.boolean(),
      tenTransactionsInTheLastFourQuartersExample: yup
        .string()
        .nullable()
        .when("tenTransactionsInTheLastFourQuarters", {
          is: true,
          then: yup
            .string()
            .test(
              "tenTransactionsInTheLastFourQuartersExample empty check",
              t(
                `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.extensiveExperience.fields.tenTransactionsInTheLastFourQuarters.example.required`
              ),
              (example) => !!example?.length
            ),
        }),
      moreThan500k: yup.boolean(),
      jobInCapitalMarket: yup.boolean(),
      jobInCapitalMarketDetails: yup
        .string()
        .nullable()
        .when("jobInCapitalMarket", {
          is: true,
          then: yup
            .string()
            .test(
              "jobInCapitalMarketDetails empty check",
              "",
              (details) => !!details?.length
            ),
        }),
    })
    .test("extensiveExperience", (value, testContext) => {
      const atLeastOneSelection =
        value.jobInCapitalMarket ||
        value.moreThan500k ||
        value.tenTransactionsInTheLastFourQuarters;

      const atLeastTwoSelections =
        [
          value.jobInCapitalMarket,
          value.moreThan500k,
          value.tenTransactionsInTheLastFourQuarters,
        ].filter((selection) => selection).length >= 2;

      if (
        (!value.isConfirmed && !atLeastOneSelection) ||
        (value.isConfirmed && atLeastTwoSelections)
      )
        return true;

      return testContext.createError({
        message: t(
          `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.extensiveExperience.required`
        ),
        type: "extensiveExperience",
        path: "declarations.professionalInvestor.professionalInvestorClassification.extensiveExperience.isConfirmed",
      });
    });

export type ProfessionalInvestorClassificationExtensiveExperience =
  yup.InferType<
    ReturnType<typeof professionalInvestorClassificationExtensiveExperience>
  >;

const professionalInvestorClassification = (
  t: TFunction,
  investorType: InvestorType
) =>
  yup
    .object({
      isConfirmed: yup.boolean(),
      financialInstruments: yup
        .object({
          isConfirmed: yup.boolean(),
          otherInstitutionalInvestor: yup.boolean(),
        })
        .test("financialInstruments", (value, testContext) => {
          if (
            (!value.isConfirmed && !value.otherInstitutionalInvestor) ||
            (value.isConfirmed && value.otherInstitutionalInvestor)
          )
            return true;

          return testContext.createError({
            message: t(
              `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.financialInstruments.required`
            ),
            type: "financialInstrumentConfirmation",
            path: "declarations.professionalInvestor.professionalInvestorClassification.financialInstruments.isConfirmed",
          });
        }),
      underSupervision: professionalInvestorClassificationUnderSupervision(t),
      governmentFunction: yup
        .object({
          isConfirmed: yup.boolean(),
          companyAsGovernment: yup.boolean(),
        })
        .test("governmentFunction", (value, testContext) => {
          if (
            (!value.isConfirmed && !value.companyAsGovernment) ||
            (value.isConfirmed && value.companyAsGovernment)
          )
            return true;

          return testContext.createError({
            message: t(
              `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.governmentFunction.required`
            ),
            type: "governmentFunctionConfirmation",
            path: "declarations.professionalInvestor.professionalInvestorClassification.governmentFunction.isConfirmed",
          });
        }),
      securizationOfLiabilities: yup
        .object({
          isConfirmed: yup.boolean(),
          companyAsSecurizationOfLiabilities: yup.boolean(),
        })
        .test("securizationOfLiabilities", (value, testContext) => {
          if (
            (!value.isConfirmed && !value.companyAsSecurizationOfLiabilities) ||
            (value.isConfirmed && value.companyAsSecurizationOfLiabilities)
          )
            return true;

          return testContext.createError({
            message: t(
              `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.fields.securizationOfLiabilities.required`
            ),
            type: "governmentFunctionConfirmation",
            path: "declarations.professionalInvestor.professionalInvestorClassification.securizationOfLiabilities.isConfirmed",
          });
        }),
      largeCompany: professionalInvestorClassificationLargeCompany(t),
      extensiveExperience:
        professionalInvestorClassificationExtensiveExperience(t),
    })
    .test("professionalInvestorClassification", (value, testContext) => {
      if (investorType === InvestorType.SEMI_PROFESSIONAL) {
        return true;
      }

      const atLeastOneSelection =
        value.financialInstruments.isConfirmed ||
        value.underSupervision.isConfirmed ||
        value.governmentFunction.isConfirmed ||
        value.securizationOfLiabilities.isConfirmed ||
        value.largeCompany.isConfirmed ||
        value.extensiveExperience.isConfirmed;

      if (value.isConfirmed && atLeastOneSelection) return true;

      return testContext.createError({
        message: t(
          `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.professionalInvestorClassification.required`
        ),
        type: "professionalInvestorClassification",
        path: "declarations.professionalInvestor.professionalInvestorClassification.isConfirmed",
      });
    });

export type ProfessionalInvestorClassification = yup.InferType<
  ReturnType<typeof professionalInvestorClassification>
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const professionalValidationSchema = (
  t: TFunction,
  investorType: InvestorType,
  isLegalEntity?: boolean
) => {
  return yup
    .object({
      //only for Legal Entity
      professionalInvestorClassification: isLegalEntity
        ? professionalInvestorClassification(t, investorType)
        : yup.mixed().nullable(),
      // for both, Natural Person and Legal Entity
      isProfessionalInvestor: yup
        .boolean()
        .oneOf(
          [true],
          t(
            `${translationPrefix}.fields.contract.professionalInvestor.${
              isLegalEntity ? "legalEntity" : "naturalPerson"
            }.isProfessionalInvestor.required`
          )
        ),
      isSufficientExperienceAndKnowledgeConfirmed: yup
        .boolean()
        .oneOf(
          [true],
          t(
            `${translationPrefix}.fields.contract.professionalInvestor.${
              isLegalEntity ? "legalEntity" : "naturalPerson"
            }.isSufficientExperienceAndKnowledgeConfirmed.required`
          )
        ),
      // only for natural person
      isRelevantMarketExperienceConfirmed: yup.boolean(),
      // only for natural person
      isRelevantFinancialPortfolioConfirmed: yup.boolean(),
      // only for natural person
      isRelevantMarketJobConfirmed: yup.boolean(),
    })
    .test(
      "isSufficientExperienceAndKnowledgeConfirmed",
      (value, testContext) => {
        if (
          !value.isSufficientExperienceAndKnowledgeConfirmed &&
          investorType === InvestorType.PROFESSIONAL &&
          isLegalEntity
        ) {
          return testContext.createError({
            message: t(
              `${translationPrefix}.fields.contract.professionalInvestor.legalEntity.isSufficientExperienceAndKnowledgeConfirmed.required`
            ),
            type: "notQualified",
            path: `declarations.professionalInvestor.isSufficientExperienceAndKnowledgeConfirmed`,
          });
        }

        if (!value.isSufficientExperienceAndKnowledgeConfirmed) {
          return true;
        }

        if (
          [
            value.isRelevantFinancialPortfolioConfirmed,
            value.isRelevantMarketExperienceConfirmed,
            value.isRelevantMarketJobConfirmed,
          ].filter(Boolean).length < 2 &&
          !isLegalEntity
        ) {
          return testContext.createError({
            message: t(
              `${translationPrefix}.fields.contract.professionalInvestor.naturalPerson.isSufficientExperienceAndKnowledgeConfirmed.minTwoError`
            ),
            type: "notQualified",
            path: `declarations.professionalInvestor.isSufficientExperienceAndKnowledgeConfirmed`,
          });
        }

        return true;
      }
    );
};

type ProfessionalInvestor = yup.InferType<
  ReturnType<typeof professionalValidationSchema>
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction, isLegalEntity?: boolean) =>
  yup.object({
    declarations: yup
      .object({
        investorType: yup
          .mixed<InvestorType>()
          .oneOf(Object.values(InvestorType))
          .required("Select an investor type"),
        semiProfessionalInvestor: semiProfessionalValidationSchema(t).when(
          "investorType",
          {
            is: InvestorType.SEMI_PROFESSIONAL,
            then: semiProfessionalValidationSchema(t),
            otherwise: semiProfessionalValidationSchema(t).nullable(true),
          }
        ),
        professionalInvestor: yup
          .mixed<ProfessionalInvestor>()
          .when("investorType", {
            is: InvestorType.PROFESSIONAL,
            then: professionalValidationSchema(
              t,
              InvestorType.PROFESSIONAL,
              isLegalEntity
            ),
            otherwise: professionalValidationSchema(
              t,
              InvestorType.SEMI_PROFESSIONAL,
              isLegalEntity
            ).nullable(),
          }),
      })
      .required(),
  });

export type FundDeclarationsForm = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export function validate(fundDeclarations: FundDeclarationsForm): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(fundDeclarations);
}
