import { TFunction } from "i18next";
import * as yup from "yup";
import {
  MoneyRangeLarge,
  WealthOrigin,
  WealthRestrictionType,
} from "../../../../../../generated/globalTypes";
import { largeRangeCheckboxMonetaryInputValidationSchema } from "../../common/GenericValidations/helpers";
import { NetWorth } from "./WealthAssessment.types";

const translationPrefix = "q-ai.opportunities.form.wealthAssessment";

export const wealthAssessmentCheckboxNames: Array<
  keyof Omit<NetWorth, "restrictions">
> = [
  "cash",
  "securities",
  "selfUsedProperties",
  "realEstate",
  "movableProperty",
  "insuranceClaims",
  "other",
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const netWorthValidation = (t: TFunction) => {
  return yup
    .object({
      total: yup.object({
        range: yup
          .mixed<MoneyRangeLarge>()
          .required(
            t(`${translationPrefix}.fields.netWorth.total.range.required`)
          )
          .oneOf(
            Object.values(MoneyRangeLarge),
            t(`${translationPrefix}.fields.netWorth.total.range.required`)
          )
          .label(t(`${translationPrefix}.fields.netWorth.total.range.label`)),
        value: yup
          .mixed()
          .transform((value) =>
            typeof value === "number"
              ? value
              : Number(value.replace(/[.,\s]/g, ""))
          )
          .when("range", {
            is: (money: MoneyRangeLarge) =>
              money === MoneyRangeLarge.MORE_THAN_1000000,
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .test(
                "minimum",
                t(`${translationPrefix}.fields.netWorth.total.value.minimum`),
                (value) => (value ?? 0) > 1000000
              )
              .required(
                t(`${translationPrefix}.fields.netWorth.total.value.required`)
              ),
          })
          .label(t(`${translationPrefix}.fields.netWorth.total.value.label`)),
      }),
      restrictions: yup.object({
        type: yup
          .mixed<WealthRestrictionType>()
          .required(
            t(`${translationPrefix}.fields.netWorth.restrictions.type.required`)
          )
          .oneOf(
            Object.values(WealthRestrictionType),
            t(`${translationPrefix}.fields.netWorth.restrictions.type.required`)
          )
          .label(
            t(`${translationPrefix}.fields.netWorth.restrictions.type.label`)
          ),
        value: yup
          .mixed()
          .transform((value) =>
            typeof value === "number"
              ? value
              : Number(value.replace(/[.,\s]/g, ""))
          )
          .when("type", {
            is: (type: WealthRestrictionType) =>
              type !== WealthRestrictionType.NO_RESTRICTIONS,
            then: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required(
                t(
                  `${translationPrefix}.fields.netWorth.restrictions.value.required`
                )
              ),
          })
          .label(
            t(`${translationPrefix}.fields.netWorth.restrictions.value.label`)
          ),
      }),
      cash: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.cash`
      ),
      securities: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.securities`
      ),
      selfUsedProperties: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.selfUsedProperties`
      ),
      realEstate: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.realEstate`
      ),
      movableProperty: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.movableProperty`
      ),
      insuranceClaims: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.insuranceClaims`
      ),
      other: largeRangeCheckboxMonetaryInputValidationSchema(
        t,
        `${translationPrefix}.fields.netWorth.other`
      ),
    })
    .test("networthCheckboxGroup", "", (obj, testContext) => {
      if (
        obj.cash.range ||
        obj.securities.range ||
        obj.selfUsedProperties.range ||
        obj.realEstate.range ||
        obj.movableProperty.range ||
        obj.insuranceClaims.range ||
        obj.other.range
      ) {
        return true;
      }

      return testContext.createError({
        message: t(
          `${translationPrefix}.fields.netWorth.networthCheckboxGroup`
        ),
        path: `${testContext.path}.networthCheckboxGroup`,
      });
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wealthOriginValidation = (t: TFunction) => {
  return yup
    .mixed<WealthOrigin>()
    .required(t(`${translationPrefix}.fields.wealthOrigin.required`))
    .oneOf(
      Object.values(WealthOrigin),
      t(`${translationPrefix}.fields.wealthOrigin.required`)
    )
    .label(t(`${translationPrefix}.fields.wealthOrigin.label`));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      netWorth: netWorthValidation(t),
      wealthOrigin: wealthOriginValidation(t),
      wealthOriginDetail: yup.string().min(1).max(1000, "max chars"),
    })
    .required();
