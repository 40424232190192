import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isDefined } from "utils/helpers";
import { QuestionnaireCard } from "../../components/QuestionnaireCard";
import {
  ContractType,
  LegalRepresentationType as LegalRepresentationEnum,
} from "../../generated/globalTypes";
import { typedUseSelector } from "../../store/index";
import { ROUTES } from "../../routes/routes";
import { updateLegalEntityProfile } from "./questionnaires/contractAndRegulatoryData/legalEntity/actions";
import { Grid, Stack, Heading, Text } from "@finvia/money-ui";

const AlternativeInvestments: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    investorProfileDone,
    regulatoryDataDone,
    mandateContractDone,
    contractType,
    legalEntityAdditionalPersonsLength,
    legalRepresentationType,
    hasReceivedProcurationDocument,
  } = typedUseSelector(
    ({
      alternativeInvestments: {
        mandateContractData,
        regulatoryData,
        investmentOpportunities,
      },
    }) => {
      const legalEntityData = regulatoryData?.legalEntityData ?? {};
      const {
        data: { legalEntity },
      } = mandateContractData;
      return {
        investorProfileDone: investmentOpportunities.isComplete,
        regulatoryDataDone: regulatoryData.isComplete,
        mandateContractDone: mandateContractData.isComplete,
        contractType: regulatoryData.personData.contractType,
        legalEntityAdditionalPersonsLength:
          legalEntityData.legalEntityAdditionalPersons?.length,
        legalRepresentationType: legalEntityData.legalRepresentationType,
        hasReceivedProcurationDocument:
          legalEntityData.hasReceivedProcurationDocument,
      };
    }
  );

  const history = useHistory();
  if (investorProfileDone && regulatoryDataDone && mandateContractDone) {
    history.push("/funds");
  }

  useEffect(() => {
    const dispatchUpdateLEProfile = async () => {
      const isAdditionalPersonsEmpty = legalEntityAdditionalPersonsLength === 0;

      if (regulatoryDataDone && contractType === ContractType.LEGAL_ENTITY) {
        dispatch(
          updateLegalEntityProfile({
            legalRepresentationType: isAdditionalPersonsEmpty
              ? LegalRepresentationEnum.SOLE
              : legalRepresentationType || undefined,
            hasReceivedProcurationDocument:
              hasReceivedProcurationDocument || false,
          })
        );
      }
    };

    dispatchUpdateLEProfile();
  }, [
    legalEntityAdditionalPersonsLength,
    dispatch,
    hasReceivedProcurationDocument,
    legalRepresentationType,
    regulatoryDataDone,
    contractType,
  ]);

  const contractAndRegulatoryDataRoute = useMemo(() => {
    if (contractType) {
      return contractType === ContractType.NATURAL_PERSON
        ? ROUTES.questionnaires.ai.regulatory.naturalPerson
        : ROUTES.questionnaires.ai.regulatory.legalEntity.root;
    }

    return ROUTES.questionnaires.ai.regulatory.root;
  }, [contractType]);

  const [hasRegulatoryFormStarted, setHasRegulatoryFormStarted] =
    useState<boolean>(false);
  const [
    hasInvestmentOpportunitiesFormStarted,
    setHasInvestmentOpportunitiesFormStarted,
  ] = useState<boolean>(false);
  const [hasMandateContractFormStarted, setHasMandateContractFormStarted] =
    useState<boolean>(false);

  const {
    isRegulatoryFormTouched,
    isInvestmentOpportunitiesFormTouched,
    isMandateContractFormTouched,
  } = useSelector((state) => {
    const { investmentOpportunities, regulatoryData, mandateContractData } =
      state.alternativeInvestments;
    return {
      isInvestmentOpportunitiesFormTouched: isDefined(
        investmentOpportunities.data.goals
      ),
      isRegulatoryFormTouched: isDefined(
        regulatoryData.personData.contractType
      ),
      isMandateContractFormTouched:
        mandateContractData.data.naturalPerson.declaration
          ?.isActingOnOwnBehalf === true,
    };
  });

  useEffect(() => {
    setHasInvestmentOpportunitiesFormStarted(
      isInvestmentOpportunitiesFormTouched
    );
    setHasRegulatoryFormStarted(isRegulatoryFormTouched);
    setHasMandateContractFormStarted(isMandateContractFormTouched);
  }, [
    isRegulatoryFormTouched,
    isInvestmentOpportunitiesFormTouched,
    isMandateContractFormTouched,
  ]);

  return (
    <Stack gutter="tera">
      <Stack gutter="mega">
        <Heading textTransform="uppercase" as="h1">
          {t("q-ai.title")}
        </Heading>
        <Text>{t("q-ai.subtitle")}</Text>
      </Stack>

      <Grid
        columns={{ sm: 1, md: 3 }}
        gap={{ sm: "tera", md: "mega", lg: "tera" }}
      >
        <QuestionnaireCard
          description={t("q-ai.opportunities.description")}
          footerText={t("q-ai.opportunities.duration")}
          icon="donut-chart"
          isEnabled
          isFinished={investorProfileDone}
          hasStarted={hasInvestmentOpportunitiesFormStarted}
          name={t("q-ai.opportunities.title")}
          targetPath={ROUTES.questionnaires.ai.opportunities}
          testId="investorprofile"
          dataAnalytics="1_start_first_questionnaire"
        />
        <QuestionnaireCard
          description={t("q-ai.regulatory.description")}
          footerText={t("q-ai.regulatory.duration")}
          icon="client"
          isEnabled={investorProfileDone}
          isFinished={regulatoryDataDone}
          hasStarted={hasRegulatoryFormStarted}
          name={t("q-ai.regulatory.title")}
          targetPath={contractAndRegulatoryDataRoute}
          testId="regulatorydata"
          dataAnalytics="2_start_second_questionnaire"
        />
        <QuestionnaireCard
          description={t("q-ai.mandate.description")}
          footerText={t("q-ai.mandate.duration")}
          icon="contract"
          isEnabled={investorProfileDone && regulatoryDataDone}
          isFinished={
            investorProfileDone && regulatoryDataDone && mandateContractDone
          }
          hasStarted={hasMandateContractFormStarted}
          name={t("q-ai.mandate.title")}
          targetPath={
            contractType === ContractType.NATURAL_PERSON
              ? ROUTES.questionnaires.ai.mandate.naturalPerson
              : ROUTES.questionnaires.ai.mandate.legalEntity
          }
          testId="mandatecontract"
          dataAnalytics="3_start_third_questionnaire"
        />
      </Grid>
    </Stack>
  );
};

export default AlternativeInvestments;
