import { useTranslation } from "react-i18next";

import { Card, Grid, Heading, Stack, Table } from "@finvia/money-ui";
import {
  customTableStyles,
  Footer,
  ImageWrapper,
} from "./SelectionFund.styled";
import { getHeaders, getRows } from "./utils";
import { SelectionFundProps } from "./SelectionFund.types";
import { FundFragment } from "generated/FundFragment";
import { ENUM_ALTERNATIVEINVESTMENTFUND_STATUS } from "generated/globalTypes";

const SelectionFund = ({
  selectionFundTableData,
  footerText,
  images,
  tableTitle,
  imagesTitle,
  funds,
}: SelectionFundProps): React.ReactElement | null => {
  const { t } = useTranslation();

  if (!selectionFundTableData) {
    console.error(
      `@SelectionFundTable does not exist in the TrackRecord section`
    );
    return null;
  }

  const fundsToShow = selectionFundTableData.map(
    (selectionFund) => selectionFund?.fundID
  );

  const filteredFunds = Object.values(funds?.entities)
    ?.filter(
      (fundToFilter) =>
        fundToFilter?.attributes?.status !==
        ENUM_ALTERNATIVEINVESTMENTFUND_STATUS.hidden
    )
    .filter((fundToFilter) => {
      if (fundsToShow.includes(fundToFilter?.id)) {
        return true;
      }

      return false;
    }) as FundFragment[];

  return (
    <Stack gutter="tera">
      <Stack gutter="giga">
        {imagesTitle && (
          <Heading as="h2" size={{ sm: 5, lg: 6 }}>
            {imagesTitle}
          </Heading>
        )}
        <Card borderWidth="bit" borderStyle="solid" borderColor="secondary">
          <Grid
            gap="giga"
            columns={{ sm: 1, md: 2, lg: images?.data?.length }}
            justifyItems={"center"}
          >
            {images?.data?.map((image) => (
              <ImageWrapper
                key={image?.attributes?.url}
                src={image?.attributes?.url}
                alt="img"
              />
            ))}
          </Grid>
        </Card>
      </Stack>
      <Stack gutter="giga">
        {tableTitle && (
          <Heading as="h2" size={{ sm: 5, lg: 6 }}>
            {tableTitle}
          </Heading>
        )}
        <Table
          headers={getHeaders(t)}
          rows={getRows({
            funds: filteredFunds,
            t,
          })}
          customStyles={customTableStyles}
        />
        <Footer>{footerText}</Footer>
      </Stack>
    </Stack>
  );
};

export default SelectionFund;
