import { TFunction } from "react-i18next";
import { i18n } from "i18next";
import { LegalAddressFields } from "./ContactData.types";

export const formFields: Array<LegalAddressFields> = [
  "streetName",
  "streetNumber",
  "addition",
  "postalCode",
  "city",
];

const labeDictionary = {
  streetName: "street",
  streetNumber: "houseNumber",
  addition: "additionalAddress",
};

export const getInputLabel = (field: string, t: TFunction): string => {
  const i18nPrefix = "q-ai.opportunities.form.contactData.fields";
  switch (field) {
    case "streetName":
    case "streetNumber":
    case "addition":
      return t(`${i18nPrefix}.${labeDictionary[field]}.label`);

    default:
      return t(`${i18nPrefix}.${field}.label`);
  }
};

export const isRequired = (field: string, lang: i18n): boolean => {
  const i18nPrefix = "q-ai.opportunities.form.contactData.fields";

  switch (field) {
    case "streetName":
    case "streetNumber":
    case "addition":
      return lang.exists(`${i18nPrefix}.${labeDictionary[field]}.required`);

    default:
      return lang.exists(`${i18nPrefix}.${field}.required`);
  }
};
