import { Exists } from "features/alternative-investments/questionnaires/contractAndRegulatoryData/naturalPerson/LiabilitiesForm/LiabilitiesForm.types";
import { Currency } from "generated/globalTypes";
import config from "../config";

export const convertBooleanToYesNoOrEmptyString: (
  value: boolean | null | undefined
) => Exists = (value) => {
  if (value === true) {
    return "yes";
  } else if (value === false) {
    return "no";
  }
  return "";
};

export const copyTextToClipboard = async (
  text: string
): Promise<void | boolean> => {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

export const isDefined = <T>(x: Maybe<T>): x is T =>
  x !== undefined && x !== null;

export const currencySymbolsDictionary: Record<Currency, string> = {
  [Currency.EUR]: "€",
  [Currency.USD]: "$",
};

const allowedCurrencyDictionary: Record<Currency, string> = {
  EUR: "en-DE",
  USD: "en-US",
};

export const currencyFormatter = (
  currency: keyof typeof Currency,
  value: number
): string => {
  const twoDecimalsValue = Number(Number(value).toFixed(2));
  const currencyFormat = allowedCurrencyDictionary[currency];

  return new Intl.NumberFormat(currencyFormat).format(twoDecimalsValue);
};

export const isDevEnvironment = ["development", "sandbox"].includes(
  config.appEnvironment
);

// DD.MM.YYYY
export const dateFormat =
  /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/i;

export const isUserFinviaAd = (userId?: string): boolean =>
  userId ? userId.includes("oauth2|finvia-ad|") : false;
