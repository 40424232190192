import * as Sentry from "@sentry/react";
import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { ErrorAlert } from "../../../../components/ErrorAlert";
import FormControl from "../../../../components/FormControl/FormControl";
import LoadingWrapper from "../../../../components/LoadingWrapper/LoadingWrapper";
import { FormContent } from "../../../../components/FormContent";
import { ContractType } from "../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../store";
import formDefaultsDeep from "../../../../utils/formDefaultsDeep";
import { FundAmountHeader } from "../../components/FundDetails/FundDetailsHeaders/FundAmountHeader";
import { TitleCard } from "../../../../components/TitleCard";
import {
  getFundBySlug,
  getFundContractSigningData,
  signAIFundContract,
  upsertAlternativeInvestmentsFundContract,
} from "../actions";
import FundDetailsHeader from "../components/FundDetailsHeader/FundDetailsHeader";
import { FundDocumentsDisplay } from "../components/FundDocumentsDisplay";
import { fundBySlug } from "../slice";
import { ROUTES } from "../../../../routes/routes";
import { buildLegalStructuresData } from "./buildLegalStructuresData";
import FundCheckboxes from "./FundCheckboxes/FundCheckboxes";
import {
  fundDocuments,
  GetAIFundContract,
  legalStructuresDocuments,
  pollingDocuments,
} from "./fundSigningDocumentHelpers";
import { AIFundContractSigningForm } from "./validations/externalStructure";
import { Box, Card, Stack } from "@finvia/money-ui";
import { FundDetailsCard } from "../../components/FundDetails/FundDetails.styled";

const UnClickedFilesAlert = () => {
  const { t } = useTranslation();
  const { errors } = useFormContext();

  const showError =
    errors.signing?.acquisitionOfShareholdingFileClicked ||
    errors.signing?.riskOfAcquisitionFileClicked ||
    errors.signing?.trustAndAdministrationContractFileClicked;

  if (!showError) {
    return <></>;
  }

  return <ErrorAlert customErrorMessage={t("form.pleaseDownloadAllFiles")} />;
};

const translationPrefixNaturalPerson = "q-ai.funds.signing.naturalPerson";
const translationPrefix = "q-ai.funds.signing";
const translationPrefixLegalEntity = "q-ai.funds.signing.legalEntity";

const FundSigning: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const [error, setError] = useState<SerializedError>();

  const dispatch = useDispatch();

  const previousStep = useCallback(() => {
    history.push(
      generatePath(ROUTES.funds.details.declarations, { slug: slug }),
      {
        scrollToTop: true,
      }
    );
  }, [history, slug]);

  useEffect(() => {
    if ((history.location?.state as any)?.scrollToTop) {
      window?.scrollTo({ top: 0, behavior: "auto" });
    }
  }, [history.location.state]);

  const nextStep = useCallback(() => {
    history.push(
      generatePath(ROUTES.funds.details.signingSuccessful, { slug: slug }),
      {
        scrollToTop: true,
      }
    );
  }, [history, slug]);

  const contractDocumentSet = typedUseSelector(
    (state) =>
      state.alternativeInvestments.alternativeInvestmentFunds.data.documentSet
  );

  useEffect(() => {
    dispatch(getFundBySlug({ slug }));
    dispatch(getFundContractSigningData({ slug }));
  }, [dispatch, slug]);

  const fundDetails = typedUseSelector((state) => {
    const fund = fundBySlug(
      state.alternativeInvestments.alternativeInvestmentFunds.funds,
      slug
    );

    Sentry.addBreadcrumb({
      category: "fund-signin",
      message: "Getting fund details from store",
      level: "log",
      data: {
        payload: { slug, fund },
      },
    });

    return fund;
  });

  const legalStructure = fundDetails?.attributes?.legalStructure;

  useEffect(() => {
    const getDocument = (documentAction: GetAIFundContract, fundName: string) =>
      dispatch(documentAction({ slug: fundName }))
        .then(unwrapResult)
        .then((res) => res)
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e);
        });

    if (legalStructure) {
      Object.values(legalStructuresDocuments[legalStructure]).forEach(
        (legalStructureDocuments) => {
          try {
            Sentry.addBreadcrumb({
              category: "fund-signin",
              message: "Start polling documents",
              level: "log",
              data: {
                payload: { slug },
              },
            });

            pollingDocuments({
              getDocument,
              documentAction: legalStructureDocuments.action,
              documentNames: legalStructureDocuments.documents,
              fundName: slug,
            });
          } catch (e) {
            console.error(e);
          }
        }
      );
    }
  }, [dispatch, slug, legalStructure]);

  const { defaultData, signingAmount } = typedUseSelector((state) => {
    const { signing } =
      state.alternativeInvestments.alternativeInvestmentFunds.data;

    return {
      defaultData: formDefaultsDeep<AIFundContractSigningForm>(
        {
          signing: {
            confirmsAcquisitionOfShareholding:
              signing?.confirmsAcquisitionOfShareholding ?? undefined,
            acquisitionOfShareholdingFileClicked:
              signing?.confirmsAcquisitionOfShareholding ?? undefined,
            confirmsRiskOfAcquisition:
              signing?.confirmsRiskOfAcquisition ?? undefined,
            riskOfAcquisitionFileClicked:
              signing?.confirmsRiskOfAcquisition ?? undefined,
            confirmsTrustAndAdministrationContract:
              signing?.confirmsTrustAndAdministrationContract ?? undefined,
            trustAndAdministrationContractFileClicked:
              signing?.confirmsTrustAndAdministrationContract ?? undefined,
            confirmsActingOnOwnBehalf:
              signing?.confirmsActingOnOwnBehalf ?? undefined,
            confirmsStatementsAndDisclosures:
              signing?.confirmsStatementsAndDisclosures ?? undefined,
            confirmsDataProtection:
              signing?.confirmsDataProtection ?? undefined,
          },
        },
        {
          signing: {
            confirmsAcquisitionOfShareholding: false,
            acquisitionOfShareholdingFileClicked: false,
            confirmsRiskOfAcquisition: false,
            riskOfAcquisitionFileClicked: false,
            confirmsTrustAndAdministrationContract: false,
            trustAndAdministrationContractFileClicked: false,
            confirmsActingOnOwnBehalf: false,
            confirmsDataProtection: false,
            confirmsStatementsAndDisclosures: false,
          },
        }
      ),
      signingAmount:
        state.alternativeInvestments.alternativeInvestmentFunds.data
          .signingAmount,
    };
  });

  const onSubmit = useCallback(
    async (data: AIFundContractSigningForm) => {
      dispatch(
        upsertAlternativeInvestmentsFundContract({
          slug,
          signing: {
            confirmsRiskOfAcquisition:
              data.signing?.confirmsRiskOfAcquisition ?? false,
            confirmsAcquisitionOfShareholding:
              data.signing?.confirmsAcquisitionOfShareholding ?? false,
            confirmsTrustAndAdministrationContract:
              data.signing?.confirmsTrustAndAdministrationContract ?? false,
            riskOfAcquisitionFileClicked:
              data.signing?.riskOfAcquisitionFileClicked ?? false,
            acquisitionOfShareholdingFileClicked:
              data.signing?.acquisitionOfShareholdingFileClicked ?? false,
            trustAndAdministrationContractFileClicked:
              data.signing?.trustAndAdministrationContractFileClicked,
            confirmsActingOnOwnBehalf: data.signing?.confirmsActingOnOwnBehalf,
            confirmsDataProtection: data.signing?.confirmsDataProtection,
            confirmsStatementsAndDisclosures:
              data.signing?.confirmsStatementsAndDisclosures,
            sourceOfFunds: data.signing?.sourceOfFunds,
            sourceOfFundsDetail: data.signing?.sourceOfFundsDetail,
          },
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(signAIFundContract({ slug }))
            .then(unwrapResult)
            .then(() => {
              nextStep();
            })
            .catch((e: SerializedError) => {
              setError(e);
            });
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep, slug]
  );

  const isLegalEntity = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData.personData.contractType ===
      ContractType.LEGAL_ENTITY
  );

  const checkTranslationPrefix = isLegalEntity
    ? translationPrefixLegalEntity
    : translationPrefixNaturalPerson;

  const legalStructuresData = buildLegalStructuresData({
    defaultData: defaultData.signing,
    translationPrefix,
    checkTranslationPrefix,
    t,
  });

  const fundAttributes = fundDetails?.attributes;

  if (!fundAttributes) {
    // We could not wrap the Box with the loadingWrapper because then the
    // validation schema would be initialized with undefined
    return (
      <LoadingWrapper isLoading>
        <></>
      </LoadingWrapper>
    );
  }

  const validationSchema =
    legalStructuresData[fundAttributes?.legalStructure].validation;

  const documentsToValidate = {
    acquisitionOfShareholdingFile:
      fundAttributes.showAcquisitionOfShareholdingFile,
    riskOfAcquisitionFile: fundAttributes.showRiskOfAcquisitionFile,
    trustAndAdministrationContractFile:
      fundAttributes.showTrustAndAdministrationContractFile,
  };

  const documents = fundDocuments({
    subscriptionDocumentDraftId:
      contractDocumentSet?.subscriptionDocumentDraft?.id,
    trustAndAdministrationContractId:
      contractDocumentSet?.trustAndAdministrationContract?.id,
    translationPrefix,
    t,
    additionalDocumentUrl:
      fundAttributes?.additionalDocument?.data?.attributes?.url,
    trustAndAdministrationDocument:
      fundAttributes?.trustAndAdministrationDocument,
    documentsToValidate,
  });

  return (
    <Box display="flex" flexDirection="column">
      <FundDetailsCard>
        <FundDetailsHeader
          fundName={fundAttributes?.name ?? ""}
          fundAssetClass={fundAttributes?.assetClass}
          fundDescription={fundAttributes?.shortDescription}
          fundManagerImageUrl={fundAttributes?.fundManagerImage ?? undefined}
        >
          <FundAmountHeader
            fundAmount={signingAmount ?? 0}
            fundCurrency={fundAttributes?.currency}
          />
        </FundDetailsHeader>
        <Card
          paddingTopBottom={{ sm: "giga", md: "peta" }}
          paddingLeftRight={{ sm: "kilo", md: "peta" }}
          borderStyle="none"
        >
          <Stack gutter="tera">
            <TitleCard
              title={t(`${checkTranslationPrefix}.title`)}
              subTitle={t(`${checkTranslationPrefix}.subTitle`)}
            />

            <FormControl<AIFundContractSigningForm>
              onBack={previousStep}
              dontShowErrors
              defaultValues={defaultData}
              onSubmit={onSubmit}
              validationSchema={validationSchema(t, documentsToValidate)}
              submitLabel={t(`${checkTranslationPrefix}.submitButton`)}
              backLabel={t("onboarding.form.back")}
              validationMode="onChange"
              reValidationMode="onChange"
              dataAnalytics="4.3_fund_signing_contract"
              buttonsWithLongTexts
            >
              {({ formState }) => {
                return (
                  <FormContent
                    isDirty={formState.isDirty}
                    isSubmitSuccessful={formState.isSubmitSuccessful}
                    error={error}
                  >
                    <UnClickedFilesAlert />
                    <FundDocumentsDisplay documents={documents} />
                    <FundCheckboxes
                      checkboxes={
                        legalStructuresData[fundAttributes.legalStructure]
                          .checkboxes
                      }
                    />
                    <UnClickedFilesAlert />
                  </FormContent>
                );
              }}
            </FormControl>
          </Stack>
        </Card>
      </FundDetailsCard>
    </Box>
  );
};

export default FundSigning;
