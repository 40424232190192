import styled, { css } from "styled-components";

const form = () => css`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.tokens.sizes.spacings.giga};
    display: flex;
    flex-direction: row-reverse;
  `}
`;

export const Form = styled.form(form);
