import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Checkbox, Grid } from "@finvia/money-ui";
import FormControl from "../../../../../components/FormControl/FormControl";
import { FormContent } from "../../../../../components/FormContent";
import { AssetClass } from "../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../store";
import formDefaultsDeep from "../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../components/QuestionnaireController";
import { updateProfile } from "../actions";
import {
  RelevantAssetClassesOptions,
  RelevantAssetClassesValidationSchema,
} from ".";

const translationPrefix = "q-ai.opportunities.form.relevantAssetClasses";

const assetClasses: AssetClass[] = [
  AssetClass.PRIVATE_EQUITY,
  AssetClass.PRIVATE_DEBT,
  AssetClass.HEDGEFUNDS,
  AssetClass.ASSETS,
  AssetClass.INFRASTRUCTURE,
];

const RelevantAssetClasses: React.FC<StepComponentProps> = ({
  previousStep,
  nextStep,
}) => {
  const { t } = useTranslation();

  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const defaultData = typedUseSelector((state) =>
    formDefaultsDeep<RelevantAssetClassesOptions>({
      relevantAssetClasses:
        state.alternativeInvestments.investmentOpportunities.data
          ?.relevantAssetClasses,
    })
  );

  const dispatch = useDispatch();
  const onSubmit = useCallback(
    async (data: RelevantAssetClassesOptions) => {
      dispatch(updateProfile(data))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  return (
    <FormControl<RelevantAssetClassesOptions>
      backLabel={t("form.back")}
      defaultValues={defaultData}
      onBack={previousStep}
      onSubmit={onSubmit}
      submitLabel={t("form.continue")}
      validationSchema={RelevantAssetClassesValidationSchema(t)}
      validationMode="onChange"
      dataAnalytics="1.4_relevant_asset_classes"
    >
      {(formMethods) => (
        <FormContent
          title={t(`${translationPrefix}.title`)}
          subTitle={t(`${translationPrefix}.subTitle`)}
          isDirty={formMethods.formState.isDirty}
          isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          error={error}
        >
          <Grid
            columns={{ sm: 1, md: 3, lg: 5 }}
            rowsGap="mega"
            columnsGap="byte"
          >
            {assetClasses.map((asset) => (
              <Checkbox
                key={asset}
                value={asset}
                ref={formMethods.register}
                name="relevantAssetClasses"
                label={t(`${translationPrefix}.assetClasses.${asset}.label`)}
                helperText={t(
                  `${translationPrefix}.assetClasses.${asset}.tooltip`
                )}
              />
            ))}
          </Grid>
        </FormContent>
      )}
    </FormControl>
  );
};

export default RelevantAssetClasses;
