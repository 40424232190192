import { FundFragment_attributes_trackRecord_standardFundTable } from "generated/FundFragment";
import { TFunction } from "i18next";
import { Content, Headers } from "./StandardFund.types";
import { fundCurrencyTranslation } from "utils/fundCurrencyTranslation";
import { ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY } from "generated/globalTypes";
import { containsOnlyNumbers } from "utils/containsOnlyNumbers";

export const getRows = ({
  standardFundTableData,
  fundCurrency,
  t,
}: {
  standardFundTableData: (FundFragment_attributes_trackRecord_standardFundTable | null)[];
  fundCurrency: ENUM_ALTERNATIVEINVESTMENTFUND_CURRENCY;
  t: TFunction;
}): Record<string, Content>[] => {
  return standardFundTableData?.map((example) => {
    const formatValueIfOnlyNumbers = (value: string | undefined | null) =>
      containsOnlyNumbers(value)
        ? t(fundCurrencyTranslation(fundCurrency), {
            value: value,
          })
        : value;

    return {
      column1: { content: example?.fundGeneration },
      column2: { content: example?.vintageYear },
      column3: {
        content: formatValueIfOnlyNumbers(example?.fundVolume),
      },
      column4: { content: formatValueIfOnlyNumbers(example?.invested) },
      column5: { content: formatValueIfOnlyNumbers(example?.investedRealized) },
      column6: { content: formatValueIfOnlyNumbers(example?.totalValue) },
    };
  });
};

const tPrefix = "components.alternativeInvestmentFund.trackRecord.standardFund";

export const getHeaders = (t: TFunction): Headers[] => [
  {
    field: "column1",
    content: t(`${tPrefix}.fund`),
  },
  {
    field: "column2",
    content: t(`${tPrefix}.vintageYear`),
  },
  {
    field: "column3",
    content: t(`${tPrefix}.fundVolume`),
  },
  {
    field: "column4",
    content: t(`${tPrefix}.invested`),
  },
  {
    field: "column5",
    content: t(`${tPrefix}.investedRealized`),
  },
  {
    field: "column6",
    content: t(`${tPrefix}.totalValue`),
  },
];
