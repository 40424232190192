import React, { ReactElement, useEffect, useState } from "react";
import { ErrorAlert } from "../ErrorAlert";
import { Heading, Stack, Text, Icon } from "@finvia/money-ui";
import * as S from "./LoadingWrapper.styled";

const TRANSITION_PERIOD = 300;

interface LoadingScreenProps {
  title?: string;
  message?: string;
  isLoading: boolean;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  isLoading = false,
  title,
  message,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLoading) {
        setShowLoading(true);
      } else {
        setShowLoading(false);
      }
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <S.Container showLoading={showLoading}>
      <S.TextWrapper>
        <Stack gutter="mega" alignItems="center">
          <Icon name="time" size="peta" />
          <Heading
            textTransform="uppercase"
            as="h1"
            align="center"
            size={{ sm: 5, md: 7 }}
          >
            {title}
          </Heading>
          <Text size={{ sm: 3, lg: 4 }} align="center">
            {message}
          </Text>
        </Stack>
      </S.TextWrapper>
    </S.Container>
  );
};

interface LoadingScreenWrapperProps extends LoadingScreenProps {
  children: ReactElement;
  error?: boolean;
  fullScreenSize?: boolean;
}

const FullScreenWrapper: React.FC = ({ children }) => {
  return <S.PageContainer>{children}</S.PageContainer>;
};

const LoadingWrapper: React.FC<LoadingScreenWrapperProps> = ({
  children,
  isLoading,
  error,
  fullScreenSize,
  ...loadingScreenProps
}) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLoading) {
        setShowContent(false);
      } else {
        setShowContent(true);
      }
    }, TRANSITION_PERIOD);
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  if (showContent) {
    return children;
  }

  const content = error ? (
    <ErrorAlert />
  ) : (
    <LoadingScreen isLoading={isLoading} {...loadingScreenProps} />
  );

  if (fullScreenSize) {
    return <FullScreenWrapper>{content}</FullScreenWrapper>;
  }

  return content;
};

export default LoadingWrapper;
