import { gql } from "@apollo/client";
import { Document } from "../fragments/Fragments";

export const GetAIFundContract = gql`
  query GetAIFundContract($slug: String!) {
    alternativeInvestmentsFundContract(slug: $slug) {
      slug
      investorType
      bankAccountType
      semiProfessionalInvestor {
        isSemiProfessionalInvestor
        isSufficientExperienceAndKnowledgeConfirmed
        experienceAndKnowledgeType
        experienceAndKnowledgeAppendix
        isEducationAndProfessionalStatusConfirmed
        educationAndProfessionalStatusAppendix
        educationAndProfessionalStatusType
        isAlignedRiskProfileConfirmed
        isAlignedFinancialSituationConfirmed
        isSufficientIncomeConfirmed
        isSufficientFinancialAssetsConfirmed
      }
      professionalInvestor {
        isProfessionalInvestor
        isSufficientExperienceAndKnowledgeConfirmed
        isRelevantFinancialPortfolioConfirmed
        isRelevantMarketExperienceConfirmed
        isRelevantMarketJobConfirmed
        isConfirmedProfessionalInvestorClassification
        professionalInvestorClassification {
          isConfirmedFinancialInstruments
          financialInstruments {
            otherInstitutionalInvestor
          }
          isConfirmedUnderSupervision
          underSupervisionCompanyType
          isConfirmedGovernmentFunction
          governmentFunction {
            companyAsGovernment
          }
          isConfirmedSecurizationOfLiabilities
          securizationOfLiabilities {
            companyAsSecurizationOfLiabilities
          }
          isConfirmedlargeCompany
          largeCompany {
            balanceSheetTotal
            netSales
            ownFunds
          }
          isConfirmedExtensiveExperience
          extensiveExperience {
            tenTransactionsInTheLastFourQuarters
            tenTransactionsInTheLastFourQuartersExample
            moreThan500k
            jobInCapitalMarket
            jobInCapitalMarketDetails
          }
        }
      }
      isAcquisitionOfShareholdingConfirmed
      isRiskOfAcquisitionConfirmed
      isTrustAndAdministrationContractConfirmed
      isActingOnOwnBehalf
      isStatementsAndDisclosuresConfirmed
      isDataProtectionConfirmed
      signingAmount {
        currency
        value
      }
      signedAt
      sourceOfFunds
      sourceOfFundsDetail
    }
  }
`;

export const GetAIFundContractSigningData = gql`
  ${Document}
  query GetAIFundContractSigningData($slug: String!) {
    alternativeInvestmentsFundContract(slug: $slug) {
      slug
      isAcquisitionOfShareholdingConfirmed
      isRiskOfAcquisitionConfirmed
      isTrustAndAdministrationContractConfirmed
      isActingOnOwnBehalf
      isStatementsAndDisclosuresConfirmed
      isDataProtectionConfirmed
      documentSet {
        subscriptionDocumentDraft {
          ...Document
        }
        trustAndAdministrationContract {
          ...Document
        }
      }
      signingAmount {
        currency
        value
      }
      sourceOfFunds
      sourceOfFundsDetail
    }
  }
`;

export const GetAIFundContractDocumentSubscriptionDocumentDraft = gql`
  ${Document}
  query GetAIFundContractDocumentSubscriptionDocumentDraft($slug: String!) {
    alternativeInvestmentsFundContract(slug: $slug) {
      slug
      documentSet {
        subscriptionDocumentDraft {
          ...Document
        }
      }
    }
  }
`;

export const GetAIFundContractDocumentTrustAndAdministrationContract = gql`
  ${Document}
  query GetAIFundContractDocumentTrustAndAdministrationContract(
    $slug: String!
  ) {
    alternativeInvestmentsFundContract(slug: $slug) {
      slug
      documentSet {
        trustAndAdministrationContract {
          ...Document
        }
      }
    }
  }
`;

export const GetAIFundContractDocumentSet = gql`
  ${Document}
  query GetAIFundContractDocumentSet($slug: String!) {
    alternativeInvestmentsFundContract(slug: $slug) {
      slug
      documentSet {
        subscriptionDocumentDraft {
          ...Document
        }
        trustAndAdministrationContract {
          ...Document
        }
      }
    }
  }
`;
