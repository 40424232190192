import { createContext, ReactNode } from "react";

interface SidebarContext {
  sidebarOverride?: ReactNode;
  setSidebarOverride: (value: ReactNode) => void;
}

const SidebarOverrideContext = createContext<SidebarContext>({
  setSidebarOverride: () => {},
});

export default SidebarOverrideContext;
