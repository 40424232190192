import { TableProps, Theme } from "@finvia/money-ui";
import styled, { css } from "styled-components";

const headerCustomStyles = css`
  grid-template-columns: 120px 140px 200px 1fr;
`;

const headCustomStyles = css`
  ${({ theme }) => css`
    font-weight: ${theme.tokens.typography.weights.book};
    color: ${theme.tokens.colors.neutral[750]};
    justify-content: start;

    &:last-of-type {
      text-align: left;
      justify-content: start;
    }
  `}
`;

const rowCustomStyles = css`
  ${({ theme }) => css`
    grid-template-rows: initial;
    padding: 0 ${theme.tokens.sizes.spacings.kilo};
    background: ${theme.tokens.colors.neutral[300]};
    margin-bottom: ${theme.tokens.sizes.spacings.byte};

    ${theme.utils.viewport.md`
      grid-template-columns: 120px 140px 200px 1fr;
      justify-content: start;
      padding: 0;
      background: initial;

      &:last-of-type {
        border-bottom: none;
      }
    `};
  `}
`;

const cellCustomStyles = css`
  ${({ theme }) => css`
    text-align: left;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: none;

    & > :nth-child(1) {
      word-break: break-word;
      hyphens: auto;
      max-width: 40%;
      padding-right: ${theme.tokens.sizes.spacings.mega};
    }

    & > :nth-child(2) {
      max-width: 60%;
      text-align: right;
      justify-content: end;
      word-break: break-word;
      hyphens: auto;
      font-size: ${theme.tokens.typography.fontSizes[3]};
    }

    ${theme.utils.viewport.md`
      & > :nth-child(1),
      & > :nth-child(2) {
        max-width: unset;
        padding-right: unset;
        text-align: left;
      }

      &:not(:last-of-type) {
        padding-right: ${theme.tokens.sizes.spacings.mega};
        box-sizing: border-box;
      }

      align-items: baseline;
      text-align: left;
      justify-content: start;

      & > :nth-child(2) {
        max-width: 100%;
        text-align: left;
        justify-content: start;
      }

      &:last-of-type {
        text-align: left;
        justify-content: start;
      }
    `};
  `}
`;

export const customTableStyles: TableProps["customStyles"] = {
  header: headerCustomStyles,
  head: headCustomStyles,
  row: rowCustomStyles,
  cell: cellCustomStyles,
};

const imageWrapper = ({ theme }: { theme: Theme }) => css`
  max-width: 120px;
  height: auto;

  ${theme.utils.viewport.md`
    padding-right: ${theme.tokens.sizes.spacings.mega};    
  `};
`;

export const ImageWrapper = styled.img<{ theme: Theme }>(imageWrapper);
