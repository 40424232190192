import { FundFragment_attributes_profile_investmentExamples } from "generated/FundFragment";
import { TFunction } from "i18next";
import { ImageWrapper } from "./InvestmentExample.styled";
import { Content, Headers } from "./InvestmentExample.types";
import { Fragment } from "react";

export const getRows = ({
  investmentExamples,
}: {
  investmentExamples: (FundFragment_attributes_profile_investmentExamples | null)[];
}): Record<string, Content>[] =>
  investmentExamples?.map((example) => {
    const breakLinesMutipleGross = example?.mutipleGross
      ?.split(";")
      .map((line, idx, arr) =>
        arr.length - 1 === idx ? (
          <Fragment key={`${idx}-${line}`}>{line}</Fragment>
        ) : (
          <Fragment key={`${idx}-${line}`}>
            {line}
            <br />
          </Fragment>
        )
      );

    return {
      column1: {
        content: (
          <ImageWrapper
            src={example?.company?.data?.attributes?.url}
            alt="company logo"
          />
        ),
      },
      column2: { content: <>{breakLinesMutipleGross}</> },
      column3: { content: example?.companyOverview },
      column4: { content: example?.valueAdd },
    };
  });

const tPrefix =
  "components.alternativeInvestmentFund.profile.secondSubSection.standardFund";

export const getHeaders = (t: TFunction): Headers[] => [
  {
    field: "column1",
    content: t(`${tPrefix}.company`),
  },
  {
    field: "column2",
    content: t(`${tPrefix}.mutipleGross`),
  },
  {
    field: "column3",
    content: t(`${tPrefix}.companyOverview`),
  },
  {
    field: "column4",
    content: t(`${tPrefix}.valueAdd`),
  },
];
