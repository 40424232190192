import styled, { css } from "styled-components";

export const Intention = styled.div<{ visible?: boolean }>`
  ${({ theme, visible }) => css`
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 195px;
    width: calc(100% + 1px);
    padding: ${theme.tokens.sizes.spacings.mega};
    transition: transform 0.3s ease-in-out;
    transform: translateY(${visible ? "0" : "100%"});
  `}
`;

export const CloseIntention = styled.span`
  ${({ theme }) => css`
    position: absolute;
    right: 15px;
    top: 10px;
    color: ${theme.tokens.colors.petrol[600]};
    font-family: ${theme.tokens.typography.fontFamily.sans};
    font-size: ${theme.tokens.typography.fontSizes[2]};

    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const Hr = styled.hr`
  ${({ theme }) => css`
    height: 1px;
    width: 100%;
    margin: 0;
    border: none;
    background-color: ${theme.tokens.colors.gold[200]};
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 ${theme.tokens.sizes.spacings.mega};
  `}
`;

export const TagsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: ${theme.tokens.sizes.spacings.mega}
      ${theme.tokens.sizes.spacings.giga} 0;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.tokens.sizes.radii.bit};
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  `}
`;

export const TopSection = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.petrol[100]};
    padding: ${theme.tokens.sizes.spacings.giga};
    padding-bottom: ${theme.tokens.sizes.spacings.mega};
  `}
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 30px;
`;

export const AssetClass = styled.div`
  ${({ theme }) => css`
    margin-left: auto;
    color: ${theme.tokens.colors.gold[500]};
    text-transform: uppercase;
    font-size: ${theme.tokens.typography.fontSizes[3]};
    font-weight: ${theme.tokens.typography.weights.medium};
    font-family: ${theme.tokens.typography.fontFamily.sans};
  `}
`;

export const Body = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.white[500]};
    padding: ${theme.tokens.sizes.spacings.giga};
    padding-top: ${theme.tokens.sizes.spacings.mega};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    p {
      color: ${theme.tokens.colors.neutral[800]};
      font-weight: ${theme.tokens.typography.weights.medium};
      word-break: break-word;
    }
  `}
`;

export const InformationList = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    margin: 0px;
    gap: ${theme.tokens.sizes.spacings.byte};
    padding-bottom: ${theme.tokens.sizes.spacings.giga};
    align-items: baseline;

    ${theme.utils.viewport.md`
      flex-wrap: nowrap;
    `};
  `}
`;

export const InformationItem = styled.li`
  ${({ theme }) => css`
    gap: ${theme.tokens.sizes.spacings.bit};

    ${theme.utils.viewport.md`
      max-width: 40%;
      gap: ${theme.tokens.sizes.spacings.byte};
    `};
  `}
`;
