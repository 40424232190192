import { createSlice } from "@reduxjs/toolkit";
import {
  LegalEntityIdentificationStatus,
  NaturalPersonIdentificationStatus,
} from "../../../../generated/globalTypes";
import { FormDefaultData } from "../../../../utils/graphQLPartial";
import typesafeDeepMergeWithUnsetting from "../../../../utils/typesafeDeepMergeWithUnsetting";
import {
  getAIMandateContractDocument,
  getLegalEntityMandateContractData,
  getNaturalPersonIdentificationStatus,
  getNaturalPersonMandateContractData,
  signAlternativeInvestmentsMandateContract,
  upsertAlternativeInvestmentsMandateContract,
} from "./actions";
import {
  getLegalEntityIdentificationStatus,
  startLegalEntityIdentificationProcess,
} from "./legalEntity/actions";
import {
  deriveFormAccess as legalEntityDeriveFormAccess,
  LegalEntityMandateContractData,
} from "./legalEntity/legalEntityValidations";
import {
  deriveFormAccess as naturalPersonDeriveFormAccess,
  NaturalPersonMandateContractData,
} from "./naturalPerson/naturalPersonValidations";

interface State {
  isLoading: boolean;
  isComplete: boolean;
  contractDraft?: {
    filename?: string;
    id?: string;
    mimetype?: string;
  };
  data: {
    naturalPerson: FormDefaultData<NaturalPersonMandateContractData>;
    legalEntity: FormDefaultData<LegalEntityMandateContractData>;
  };
}

const initialState: State = {
  isLoading: false,
  isComplete: false,
  contractDraft: undefined,
  data: {
    naturalPerson: {
      contractConfirmation: undefined,
      declaration: undefined,
      videoIdentStatus:
        NaturalPersonIdentificationStatus.IDENTIFICATION_NOT_ATTEMPTED,
    },
    legalEntity: {
      accumulatedIdentificationStatus: undefined,
      legalEntityIdentificationStatus:
        LegalEntityIdentificationStatus.NOT_ATTEMPTED,
      missingDocuments: undefined,
      legalRepresentative: undefined,
      failedIdentificationMessage: undefined,
    },
  },
};

const slice = createSlice({
  name: "mandateContractData",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getNaturalPersonMandateContractData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getNaturalPersonMandateContractData.fulfilled,
        (state, { payload }) => {
          state.isLoading = false;
          state.data.naturalPerson = typesafeDeepMergeWithUnsetting<
            FormDefaultData<NaturalPersonMandateContractData>
          >(state.data.naturalPerson, payload);
          state.isComplete = naturalPersonDeriveFormAccess(
            state.data.naturalPerson
          ).isComplete;
        }
      )
      .addCase(getAIMandateContractDocument.fulfilled, (state, { payload }) => {
        state.contractDraft = payload || undefined;
      })
      .addCase(
        upsertAlternativeInvestmentsMandateContract.fulfilled,
        (state, { payload }) => {
          const mandateContractData = typesafeDeepMergeWithUnsetting<
            FormDefaultData<NaturalPersonMandateContractData>
          >(state.data.naturalPerson, payload);

          state.data.naturalPerson = mandateContractData;
          state.isComplete = naturalPersonDeriveFormAccess(
            state.data.naturalPerson
          ).isComplete;
        }
      )
      .addCase(
        signAlternativeInvestmentsMandateContract.fulfilled,
        (state, { payload }) => {
          state.data.naturalPerson.contractConfirmation =
            payload.contractConfirmation;
          state.isComplete = naturalPersonDeriveFormAccess(
            state.data.naturalPerson
          ).isComplete;
        }
      )
      .addCase(
        getNaturalPersonIdentificationStatus.fulfilled,
        (state, { payload }) => {
          state.data.naturalPerson.videoIdentStatus =
            payload?.naturalPersonIdentificationStatus;
          state.isComplete = naturalPersonDeriveFormAccess(
            state.data.naturalPerson
          ).isComplete;
        }
      )
      .addCase(
        getLegalEntityMandateContractData.fulfilled,
        (state, { payload }) => {
          state.data.naturalPerson = typesafeDeepMergeWithUnsetting<
            FormDefaultData<
              LegalEntityMandateContractData & NaturalPersonMandateContractData
            >
          >(state.data.naturalPerson, {
            contractConfirmation: payload?.contractConfirmation,
            declaration: payload?.declaration,
            videoIdentStatus: payload?.videoIdentStatus,
          });

          state.data.legalEntity.accumulatedIdentificationStatus =
            payload?.accumulatedIdentificationStatus;
          state.data.legalEntity.legalEntityIdentificationStatus =
            payload?.legalEntityIdentificationStatus;

          state.data.legalEntity.legalEntityIdentificationStatus =
            payload?.legalEntityIdentificationStatus;

          state.data.legalEntity.failedIdentificationMessage =
            payload?.failedIdentificationMessage;

          state.isComplete = legalEntityDeriveFormAccess({
            ...state.data.legalEntity,
            ...state.data.naturalPerson,
          }).isComplete;
        }
      )
      .addCase(
        startLegalEntityIdentificationProcess.fulfilled,
        (state, { payload }) => {
          if (payload) {
            state.data.legalEntity = payload;
          }
        }
      )
      .addCase(
        getLegalEntityIdentificationStatus.fulfilled,
        (state, { payload }) => {
          if (payload) {
            state.data.legalEntity.legalRepresentative =
              payload.legalRepresentative;
            state.data.legalEntity.additionalLegalRepresentatives =
              payload.additionalLegalRepresentatives;
          }
          state.isComplete = legalEntityDeriveFormAccess({
            ...state.data.legalEntity,
            ...state.data.naturalPerson,
          }).isComplete;
        }
      ),
});

export default slice;
