import { SerializedError, unwrapResult } from "@reduxjs/toolkit";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormControl from "../../../../../../components/FormControl/FormControl";
import SidebarContentBlock from "../../../../../../components/Layout/Sidebar/SidebarContentBlock";
import SidebarOverrideContext from "../../../../../../components/Layout/Sidebar/SidebarOverrideContext";
import { FormContent } from "../../../../../../components/FormContent";
import {
  LegalEntityCRSStatus,
  LegalEntityCRSStatusOtherCharacteristic,
} from "../../../../../../generated/globalTypes";
import { typedUseSelector } from "../../../../../../store";
import formDefaultsDeep from "../../../../../../utils/formDefaultsDeep";
import { StepComponentProps } from "../../../../components/QuestionnaireController";
import { updateLegalEntityProfile } from "../actions";
import CRSFields from "./CRSFields";
import FATCAFields from "./FATCAFields";
import ResidencyFields from "./ResidencyFields/ResidencyFields";
import { Grid, Heading } from "@finvia/money-ui";
import { Residency, TaxInformation, validationSchema } from "./";

const translationPrefix = "q-ai.opportunities.form.taxInformation";

const TaxInformationForm: React.FC<StepComponentProps> = ({
  nextStep,
  previousStep,
}) => {
  const [error, setError] = useState<SerializedError | undefined>(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setSidebarOverride } = useContext(SidebarOverrideContext);

  useEffect(() => {
    setSidebarOverride(
      <Grid columns="1" gap="mega">
        <SidebarContentBlock
          iconType="contract"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityTaxInformation.fatca.title")}
          description={t("sidebar.legalEntityTaxInformation.fatca.description")}
          textAlign="left"
        />
        <SidebarContentBlock
          iconType="contract"
          iconSize="byte"
          iconColor="petrol.600"
          title={t("sidebar.legalEntityTaxInformation.crs.title")}
          description={t("sidebar.legalEntityTaxInformation.crs.description")}
          textAlign="left"
        />
      </Grid>
    );
    return () => {
      setSidebarOverride(undefined);
    };
  }, [setSidebarOverride, t]);

  const memoizedSchema = useMemo(() => validationSchema(t), [t]);
  const onSubmit = useCallback(
    async (taxInformationData: TaxInformation) => {
      let crsStatuses: LegalEntityCRSStatus[] = [];

      if (Array.isArray(taxInformationData.taxInformation.crs.status)) {
        crsStatuses = [...taxInformationData.taxInformation.crs.status];
      } else {
        crsStatuses = [taxInformationData.taxInformation.crs.status];
      }

      if (
        taxInformationData.taxInformation.crs.otherCharacteristic ===
        LegalEntityCRSStatusOtherCharacteristic.INVESTMENT_COMPANY_BY_ANOTHER_FINANCIAL_INSTITUTION
      ) {
        crsStatuses.push(LegalEntityCRSStatus.PASSIVE_NFE);
      }

      const newTaxInformationData = {
        ...taxInformationData,
        taxInformation: {
          ...taxInformationData.taxInformation,
          crs: {
            ...taxInformationData.taxInformation.crs,
            status: crsStatuses,
          },
        },
      };

      dispatch(updateLegalEntityProfile(newTaxInformationData))
        .then(unwrapResult)
        .then(() => {
          nextStep();
        })
        .catch((e: SerializedError) => {
          setError(e);
        });
    },
    [dispatch, nextStep]
  );

  const defaultData = typedUseSelector((store) => {
    const { taxInformation } =
      store.alternativeInvestments.regulatoryData?.legalEntityData || {};

    return formDefaultsDeep<TaxInformation>(
      {
        taxInformation,
      },
      {
        taxInformation: {
          residencies: [{ country: "", taxId: "", localTaxOffice: "" }],
          fatca: {
            status: undefined,
            statusCertificate: undefined,
            relevantCriterion: undefined,
          },
          crs: {
            status: undefined,
            statusCertificate: undefined,
            otherCharacteristic: undefined,
          },
        },
      }
    );
  });

  const residenciesState = typedUseSelector(
    (store) =>
      store.alternativeInvestments.regulatoryData?.legalEntityData
        .taxInformation?.residencies
  );

  return (
    <FormControl<TaxInformation>
      dontShowErrors
      defaultValues={defaultData}
      onSubmit={onSubmit}
      onBack={previousStep}
      validationSchema={memoizedSchema}
      submitLabel={t("form.continue")}
      backLabel={t("form.back")}
      validationMode="onChange"
      reValidationMode="onChange"
      dataAnalytics="2.15_le_tax_information"
    >
      {(formMethods) => {
        const watchResidencies: Residency[] = formMethods.watch(
          "taxInformation.residencies"
        );

        formMethods.trigger("taxInformation.residencies");

        const residencies = residenciesState?.map((residency, index) => {
          const taxId =
            watchResidencies?.[index]?.taxId !== ""
              ? watchResidencies[index]?.taxId
              : residency.taxId;
          const country =
            watchResidencies?.[index]?.country !== ""
              ? watchResidencies[index]?.country
              : residency.country;
          const localTaxOffice =
            watchResidencies?.[index]?.localTaxOffice !== ""
              ? watchResidencies?.[index]?.localTaxOffice
              : residency.localTaxOffice;

          return { ...residency, taxId, country, localTaxOffice };
        });

        return (
          <FormContent
            title={t(`${translationPrefix}.title`)}
            subTitle={t(`${translationPrefix}.subTitle`)}
            error={error}
            isDirty={formMethods.formState.isDirty}
            isSubmitSuccessful={formMethods.formState.isSubmitSuccessful}
          >
            <Grid columnsGap="yotta" rowsGap="giga" columns={1}>
              <Grid gap="giga" columns={1}>
                <Heading as="h5" size={{ sm: 3, lg: 4 }} font="sans">
                  {t(`${translationPrefix}.fields.taxResidency.title`)}
                </Heading>
              </Grid>
              <ResidencyFields residencies={residencies} />
              <FATCAFields
                formMethods={formMethods}
                defaultData={defaultData.taxInformation?.fatca}
              />
              <CRSFields
                formMethods={formMethods}
                defaultData={defaultData.taxInformation?.crs}
              />
            </Grid>
          </FormContent>
        );
      }}
    </FormControl>
  );
};

export default TaxInformationForm;
