import { TFunction } from "i18next";
import * as yup from "yup";
import {
  AverageInvestmentVolumePerYear,
  AverageTransactionsPerYear,
  ExperienceYears,
} from "../../../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.experienceAssets";

export const experienceAssetsCheckboxNames: Array<
  keyof ExperienceAssetClasses["assetClassesExperience"]
> = [
  "securities",
  "bonds",
  "derivatives",
  "currencyFutures",
  "moneyMarket",
  "gold",
  "hedgeFunds",
  "realEstate",
  "infrastructure",
  "privateDebt",
  "privateEquity",
  "certificates",
];

const experienceCheckboxValidationSchema = (
  t: TFunction,
  checkboxName: string
) =>
  yup.object({
    checkbox: yup.string(),
    experienceInYears: yup
      .mixed<ExperienceYears>()
      .oneOf(
        Object.values(ExperienceYears),
        t(`${checkboxName}.experienceInYears.required`)
      )
      .when("checkbox", {
        is: checkboxName,
        then: yup.mixed<ExperienceYears>().required(),
      }),
    averageNumberOfTransactionsPerYear: yup
      .mixed<AverageTransactionsPerYear>()
      .oneOf(
        Object.values(AverageTransactionsPerYear),
        t(`${checkboxName}.averageNumberOfTransactionsPerYear.required`)
      )
      .when("checkbox", {
        is: checkboxName,
        then: yup.mixed<AverageTransactionsPerYear>().required(),
      }),
    averageInvestmentVolumePerYear: yup
      .mixed<AverageInvestmentVolumePerYear>()
      .oneOf(
        Object.values(AverageInvestmentVolumePerYear),
        t(`${checkboxName}.averageInvestmentVolumePerYear.required`)
      )
      .when("checkbox", {
        is: checkboxName,
        then: yup.mixed<AverageInvestmentVolumePerYear>().required(),
      }),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const experienceAssetsValidationSchema = (t: TFunction) => {
  return yup
    .object({
      privateEquity: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.privateEquity`
      ),
      privateDebt: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.privateDebt`
      ),
      realEstate: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.realEstate`
      ),
      infrastructure: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.infrastructure`
      ),
      securities: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.securities`
      ),
      bonds: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.bonds`
      ),
      gold: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.gold`
      ),
      moneyMarket: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.moneyMarket`
      ),
      derivatives: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.derivatives`
      ),
      currencyFutures: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.currencyFutures`
      ),
      hedgeFunds: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.hedgeFunds`
      ),
      certificates: experienceCheckboxValidationSchema(
        t,
        `${translationPrefix}.fields.experience.assetClasses.certificates`
      ),
    })
    .test("experienceFormCheckBoxGroup", "", (obj, testContext) => {
      if (
        obj.privateDebt.experienceInYears ||
        obj.privateEquity.experienceInYears ||
        obj.realEstate.experienceInYears ||
        obj.infrastructure.experienceInYears ||
        obj.securities.experienceInYears ||
        obj.bonds.experienceInYears ||
        obj.gold.experienceInYears ||
        obj.moneyMarket.experienceInYears ||
        obj.derivatives.experienceInYears ||
        obj.currencyFutures.experienceInYears ||
        obj.hedgeFunds.experienceInYears ||
        obj.certificates.experienceInYears
      ) {
        return true;
      }
      return testContext.createError({
        message: t(
          `${translationPrefix}.fields.experience.experienceFormCheckBoxGroup`
        ),
        path: `${testContext.path}.experienceFormCheckBoxGroup`,
      });
    });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      assetClassesExperience: experienceAssetsValidationSchema(t),
    })
    .required();

export type ExperienceAssetClasses = yup.InferType<
  ReturnType<typeof validationSchema>
>;

export interface ExperienceAssetClassesForm {
  assetClassesExperience?: ExperienceAssetClasses["assetClassesExperience"];
}

export function validate(
  assetClassesExperience: ExperienceAssetClassesForm
): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(assetClassesExperience);
}
