import { Option } from "@finvia/money-ui";
import { TFunction } from "i18next";

export const professionalTitles = (t: TFunction): Option[] => [
  { value: "DR", text: t(`options.professionalTitles.DR`) },
  { value: "DR_DR", text: t(`options.professionalTitles.DR_DR`) },
  { value: "PROF", text: t(`options.professionalTitles.PROF`) },
  { value: "PROF_DR", text: t(`options.professionalTitles.PROF_DR`) },
  { value: "PROF_DR_DR", text: t(`options.professionalTitles.PROF_DR_DR`) },
  { value: "DIPL_ING", text: t(`options.professionalTitles.DIPL_ING`) },
];
