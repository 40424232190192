import { unwrapResult } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import config from "config";
import { getLegalEntityIdentificationStatus } from "features/alternative-investments/questionnaires/mandateContract/legalEntity/actions";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { KeepAlive } from "utils/keepAliveAction";
import { getRegulatoryData } from "../features/alternative-investments/questionnaires/contractAndRegulatoryData/actions";
import { getLegalEntityRegulatoryData } from "../features/alternative-investments/questionnaires/contractAndRegulatoryData/legalEntity/actions";
import { getInvestorProfile } from "../features/alternative-investments/questionnaires/investorProfile/actions";
import {
  getLegalEntityMandateContractData,
  getNaturalPersonMandateContractData,
} from "../features/alternative-investments/questionnaires/mandateContract/actions";
import { ContractType } from "../generated/globalTypes";
import { typedUseSelector } from "../store/index";
import { getUser } from "../store/userActions";
import LoadingWrapper from "./LoadingWrapper/LoadingWrapper";
const PersonDataGate: React.FC<{ children: ReactElement<any, any> }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [isLoadingBaseProfile, setIsLoadingBaseProfile] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const contractType = typedUseSelector(
    (state) =>
      state.alternativeInvestments.regulatoryData.personData.contractType
  );

  useEffect(() => {
    const keepAlive = async () => {
      await dispatch(KeepAlive());
    };
    const pollingInterval = setInterval(keepAlive, config.keepAlive.interval);
    return () => {
      clearInterval(pollingInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    const getPersonData = async () => {
      try {
        const result = await Promise.all([
          dispatch(getInvestorProfile()),
          dispatch(getRegulatoryData()),
          dispatch(getUser()),
        ]);

        if (contractType === ContractType.LEGAL_ENTITY) {
          const legalEntityResult = await dispatch(
            getLegalEntityRegulatoryData()
          );

          unwrapResult(legalEntityResult);
        }

        result.map((response) => unwrapResult(response));
      } catch (e) {
        setError(true);
        // eslint-disable-next-line no-console
        console.error(e);
        Sentry.captureException(e);
      } finally {
        setIsLoadingBaseProfile(false);
      }
    };
    getPersonData();
  }, [dispatch, contractType]);

  useEffect(() => {
    const getMandateContractData = async () => {
      try {
        if (contractType === ContractType.NATURAL_PERSON) {
          const result = await dispatch(getNaturalPersonMandateContractData());
          unwrapResult(result);
          setLoading(false);
        } else if (contractType === ContractType.LEGAL_ENTITY) {
          const result = await dispatch(getLegalEntityMandateContractData());
          unwrapResult(result);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        if (!isLoadingBaseProfile && !contractType) {
          setLoading(false);
        }
      }
    };

    getMandateContractData();
  }, [contractType, dispatch, isLoadingBaseProfile]);

  return (
    <LoadingWrapper
      isLoading={loading}
      fullScreenSize
      error={error}
      title={t("components.dataGate.title")}
      message={t("components.dataGate.message")}
    >
      {children}
    </LoadingWrapper>
  );
};

export default PersonDataGate;
