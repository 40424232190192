import { FundFragment } from "generated/FundFragment";

export interface OverviewProps {
  fund: FundFragment;
}

export const Status = {
  loading: "loading",
  available: "available",
  downloaded: "downloaded",
  failed: "failed",
} as const;

export type TStatus = keyof typeof Status;
