import { TFunction } from "i18next";
import * as yup from "yup";
import { RelevantAssetClassesOptions } from "./RelevantAssetClasses.types";

const translationPrefix = "q-ai.opportunities.form.relevantAssetClasses";

export const RelevantAssetClassesValidationSchema = (
  t: TFunction
): yup.SchemaOf<RelevantAssetClassesOptions> =>
  yup.object().shape({
    relevantAssetClasses: yup
      .array()
      .min(
        1,
        t(`${translationPrefix}.validations.relevantAssetClasses_min_one`)
      )
      .required(),
  });
