import { TFunction } from "i18next";
import * as yup from "yup";
import { ContractType as GqlContractType } from "../../../../generated/globalTypes";

const translationPrefix = "q-ai.opportunities.form.contractType";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (t: TFunction) =>
  yup
    .object({
      contractType: yup
        .mixed<GqlContractType>()
        .required(t(`${translationPrefix}.validations.contractType_required`))
        .oneOf(
          Object.values(GqlContractType),
          t(`${translationPrefix}.validations.contractType_required`)
        )
        .label(t(`${translationPrefix}.label`)),
    })
    .required();

export type ContractType = yup.InferType<ReturnType<typeof validationSchema>>;

export interface ContractTypeForm {
  contractType?: ContractType["contractType"];
}

export function validate(contractType: ContractTypeForm): Promise<any> {
  const t = (k: any) => k;
  return validationSchema(t).validate(contractType);
}
