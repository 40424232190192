export const ROUTES = {
  funds: {
    root: "/funds",
    details: {
      overview: "/funds/:slug/overview",
      bankAccountSelection: "/funds/:slug/bank-account-selection",
      declarations: "/funds/:slug/declarations",
      root: "/funds/:slug/:section",
      signing: "/funds/:slug/signing",
      signingNotDigital: "/funds/:slug/signing-not-digital",
      signingSuccessful: "/funds/:slug/success",
      externalSigningSuccessful: "/funds/:fund/external-success",
    },
  },
  questionnaires: {
    root: "/questionnaires",
    ai: {
      root: "/questionnaires/ai",
      opportunities: "/questionnaires/ai/opportunities",
      regulatory: {
        root: "/questionnaires/ai/regulatory",
        naturalPerson: "/questionnaires/ai/regulatory/naturalPerson",
        legalEntity: {
          root: "/questionnaires/ai/regulatory/legalEntity",
          addPerson: "/questionnaires/ai/regulatory/legalEntity/:id/add",
          editPerson:
            "/questionnaires/ai/regulatory/legalEntity/:id/edit/:personId",
        },
      },
      mandate: {
        naturalPerson: "/questionnaires/ai/mandate/naturalPerson",
        legalEntity: "/questionnaires/ai/mandate/legalEntity",
        identificationLoader:
          "/questionnaires/ai/mandate/legalEntity/identification/loading",
      },
    },
  },
  external: {
    finviaAppointments: "https://calendly.com/finvia-family-office",
    finviaApp: "https://app.finvia.fo",
  },
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  ROUTES,
};
