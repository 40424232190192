import { intersection } from "lodash";
import config from "../../../config";
import { typedUseSelector } from "../../../store";
import { isUserFinviaAd } from "../../../utils/helpers";

const useCompletedForms = (): boolean => {
  const {
    investorProfileDone,
    regulatoryDataDone,
    mandateContractDone,
    userGroups,
    userId,
  } = typedUseSelector((state: any) => ({
    userId: state.user.id,
    investorProfileDone:
      state.alternativeInvestments.investmentOpportunities.isComplete,
    regulatoryDataDone: state.alternativeInvestments.regulatoryData.isComplete,
    mandateContractDone:
      state.alternativeInvestments.mandateContractData.isComplete,
    userGroups: state.user.groups,
  }));

  const completedForms: boolean =
    isUserFinviaAd(userId) ||
    (investorProfileDone && regulatoryDataDone && mandateContractDone) ||
    intersection(
      userGroups || [],
      config.accessControl.clientGroupsAuthorizedToSign
    ).length > 0;

  return completedForms;
};

export default useCompletedForms;
