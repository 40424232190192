import React, { ReactNode } from "react";
import { useFormContext, UseFormMethods } from "react-hook-form";
import UnorderedList from "../../../components/UnorderedList/UnorderedList";
import { Text, Stack, Checkbox } from "@finvia/money-ui";
import {
  AdditionalText,
  CheckboxWrapper,
} from "../questionnaires/mandateContract/common/DeclarationForm/Declarations.styled";
import { queryForError } from "../../../utils/queryForError";
interface Props {
  name: string;
  label: string;
  description?: string;
  tooltip?: string;
  errorMessageOverride?: string;
  children?: ReactNode;
  terms?: string[];
  defaultValue?: boolean;
  className?: string;
  formMethods?: UseFormMethods;
  testId?: string;
  withBorderStyle?: boolean;
}

const CheckboxPanel: React.FC<Props> = ({
  name,
  label,
  description,
  tooltip,
  terms,
  children,
  testId,
}) => {
  const { errors, register } = useFormContext();
  const error = queryForError(errors, name)?.[0];

  return (
    <CheckboxWrapper data-test-id={testId}>
      <Checkbox ref={register} name={name} label={label} helperText={tooltip} />
      <AdditionalText>
        <Stack>
          <Text>{description}</Text>
          {error && (
            <Text size={1} variant="error">
              {error.message}
            </Text>
          )}
          {terms && <UnorderedList listItems={terms} />}
          {children}
        </Stack>
      </AdditionalText>
    </CheckboxWrapper>
  );
};

export default CheckboxPanel;
