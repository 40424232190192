import { makeStyles } from "@material-ui/core";
import { ReactElement } from "react";
import BoldQueryOnString from "../../../../../components/BoldQueryOnString";
import { IndustryButton } from "./StyledAccordion";
import { Text, theme } from "@finvia/money-ui";

const useStyles = makeStyles(() => ({
  selectedCategory: {
    backgroundColor: theme.tokens.colors.petrol[600],
    "&:hover": {
      backgroundColor: theme.tokens.colors.petrol[600],
    },
  },
}));

interface LastNodeProps {
  name: string;
  naceCode: string;
  onSelect: (code: string) => void;
  isActiveSearch: boolean;
  isActiveOnSearch: boolean;
  isSelected: boolean;
  searchQuery: string;
}

const LastNode = ({
  name,
  naceCode,
  onSelect,
  isActiveSearch,
  isActiveOnSearch,
  isSelected,
  searchQuery,
}: LastNodeProps): ReactElement => {
  const classes = useStyles();

  return (
    <IndustryButton
      type="button"
      onClick={() => onSelect(naceCode)}
      className={isSelected ? classes.selectedCategory : undefined}
    >
      <Text as="span" invertTheme={isSelected}>
        {isActiveOnSearch && isActiveSearch
          ? BoldQueryOnString({ string: name, query: searchQuery })
          : name}
      </Text>
    </IndustryButton>
  );
};

export default LastNode;
