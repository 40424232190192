import {
  AccumulatedIdentificationStatus,
  BKYCDocumentCategories,
  LegalEntityIdentificationStatus,
  NaturalPersonIdentificationStatus,
} from "../../../../../generated/globalTypes";
import { FormDefaultData } from "../../../../../utils/graphQLPartial";
import { MandateContractLegalEntityFormStep } from "../../../formSteps";
import { validationSchema as ContractConfirmationValidationSchema } from "../common/ContractConfirmationForm";
import { validationSchema as DeclarationValidationSchema } from "../common/DeclarationForm";
import { NaturalPersonMandateContractData } from "../naturalPerson/naturalPersonValidations";

interface LegalRepresentativeIdentification {
  id: string;
  status: NaturalPersonIdentificationStatus;
  url: string;
}
export interface LegalEntityMandateContractData {
  accumulatedIdentificationStatus?: AccumulatedIdentificationStatus;
  legalEntityIdentificationStatus?: LegalEntityIdentificationStatus;
  legalRepresentative?: LegalRepresentativeIdentification;
  additionalLegalRepresentatives?: LegalRepresentativeIdentification[];
  missingDocuments?: BKYCDocumentCategories[];
  failedIdentificationMessage?: string;
}

export function deriveFormAccess(
  legalEntityMandateContractData: FormDefaultData<
    LegalEntityMandateContractData & NaturalPersonMandateContractData
  >
): {
  atMostAccessibleStep: MandateContractLegalEntityFormStep;
  isComplete: boolean;
} {
  const t = (k: any) => k;
  let atMostAccessibleStep = MandateContractLegalEntityFormStep.Declaration;
  let isComplete = false;

  try {
    DeclarationValidationSchema(t).validateSync({
      declaration: legalEntityMandateContractData.declaration,
    });
    atMostAccessibleStep =
      MandateContractLegalEntityFormStep.IdentificationList;

    const isVideoIdentified =
      legalEntityMandateContractData.legalRepresentative?.status ===
        NaturalPersonIdentificationStatus.IDENTIFICATION_SUCCESSFUL ||
      legalEntityMandateContractData.legalRepresentative?.status ===
        NaturalPersonIdentificationStatus.IDENTIFICATION_PENDING_SUCCESSFUL;

    if (!isVideoIdentified) {
      throw new Error("Video identification not yet completed.");
    }

    atMostAccessibleStep =
      MandateContractLegalEntityFormStep.RepresentativesIdentificationList;

    const isAccumulatedIdentificationStatusSuccessful =
      legalEntityMandateContractData.accumulatedIdentificationStatus ===
      AccumulatedIdentificationStatus.SUCCESSFUL;

    if (!isAccumulatedIdentificationStatusSuccessful) {
      atMostAccessibleStep =
        MandateContractLegalEntityFormStep.IdentificationStatus;

      throw new Error("Legal identification not yet completed.");
    }

    atMostAccessibleStep = MandateContractLegalEntityFormStep.MandateContract;

    ContractConfirmationValidationSchema(t).validateSync({
      contractConfirmation: legalEntityMandateContractData.contractConfirmation,
    });

    atMostAccessibleStep = MandateContractLegalEntityFormStep.Declaration;

    isComplete = true;
  } catch {
    // no-op
  }

  return {
    atMostAccessibleStep,
    isComplete,
  };
}
